import React, { useState, useEffect } from 'react';
import classes from './ManageStocks.module.css';
import "../../assets/datatables/dataTables.bootstrap4.min.css";
// import RegLogo from '../../Images/RegistrationLogo.svg'
import { Spinner, Badge, Button, Modal, Form, Tabs, Tab } from 'react-bootstrap';
// import Folder from '../../Images/folder-2.svg';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
// import ArrowLogo from '../../Images/arrow-left.svg';
// import LoanImage from '../../Images/loan bg.svg';
import MainDashoard from '../Main Dashboard/MainDashoard';
// import Ready from '../../Images/nothing.svg'
// import Ready1 from '../../Images/review.svg';
import { Link, useNavigate, useParams } from 'react-router-dom'
import Table from 'react-bootstrap/Table';
import { BASE_URL } from '../api/api';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import CoopDashboard from '../Cooperative Dashboard/CoopDashboard';
import StockDashboard from '../Stock Dashboard/StockDashboard';
import InventoryDash from '../Inventory Dashboard/InventoryDash';
import LatestDash from '../LatestDashboard/LatestDash';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { green } from '@mui/material/colors';


export default function ManageProducts() {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const handleClose = () => setShow(false);
  const handleClose1 = () => setShow1(false);
  const handleShow = () => setShow(true);
  const handleShow1 = () => setShow1(true);
  const [bearer, setBearer] = useState('');
  const [user, setUser] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [roleLoading, setRoleLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [newLoading, setNewLoading] = useState(false);
  const [eyeClicked, setEyeClicked] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [archiveData, setArchivedData] = useState([]);
  const [stat, setStat] = useState([]);
  const [tableData1, setTableData1] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [searchedResult, setSearchedResult] = useState([]);
  const [trashClicked, setTrashClicked] = useState(false);
  const [entriesPerPage, setEntriesPerPage] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [admin, setAdmin] = useState("");
  const [selectedRole, setSelectedRole] = useState("");
  const [unit, setUnit] = useState("");
  const [unit1, setUnit1] = useState("");

  const [roless, setRoless] = useState([]);
  const [address, setAddress] = useState("");
  const [office_address, setOfficeAddress] = useState("");
  const [selectedId, setSelectedId] = useState('');
  const [selectedUnit, setSelectedUnit] = useState(null);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [name1, setName1] = useState("");
  const [description1, setDescription1] = useState('');
  const [tableData2, setTableData2] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [price, setPrice] = useState("");
  const [price1, setPrice1] = useState("");
  const [tableData1212, setTableData1212] = useState([]);

  const [glCode, setglCode] = useState("");
  const [stockBalance, setStockBalance] = useState("");
  const [stockBalance1, setStockBalance1] = useState("");
  const [reOderLevel, setReOrderLevel] = useState("");
  const [glCode1, setglCode1] = useState("");
  const [reOderLevel1, setReOrderLevel1] = useState("");

  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem('userToken');
      const value1 = await AsyncStorage.getItem('tobi');
      const value2 = await AsyncStorage.getItem('permissions');
      const value3 = await AsyncStorage.getItem('admin');

      if (value !== null) {
        setBearer(value);
      }
      if (value1 !== null) {
        setUser(value1);
      }
      if (value2 !== null) {
        setPermissions(value2);
      }
      if (value3 !== null) {
        setAdmin(value3);
      }
    } catch (e) {
      alert('Failed to fetch the input from storage');
    }
  };

  useEffect(() => {
    readData();
  }, []);

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${bearer}`
  };



  const fetchData = async () => {
    setRoleLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/items/fetch-all`, { headers });
      const results = response.data?.data;
      // console.log(results);
      setTableData(results);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
        const errorStatus = error.response?.data?.message;
        console.log(errorStatus);
        setTableData([]);
      }
    } finally {
      setRoleLoading(false);
    }
  };
  const fetchArchivedData = async () => {
    setRoleLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/items/fetch-softdelete`, { headers });
      const results = response.data?.data;
      // console.log(results);
      setArchivedData(results);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
        const errorStatus = error.response?.data?.message;
        console.log(errorStatus);
        setArchivedData([]);
      }
    } finally {
      setRoleLoading(false);
    }
  };

  const fetchData8 = async () => {
    setNewLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/items/item-count`, { headers });
      const sta = response.data?.data;
      console.log(sta);
      setStat(sta);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
        const errorStatus = error.response?.data?.message;
        console.log(errorStatus);
        setStat([]);
      }
    } finally {
      setNewLoading(false);
    }
  };




  const fetchGl = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/account`, { headers });
      const results = response.data?.data;

      setTableData2(results);
      // console.log(results);
    } catch (error) {
      const errorStatus = error.response?.data?.message;
      console.log(errorStatus);
      setTableData2([]);
    } finally {
      setIsLoading(false);
    }
  };



  useEffect(() => {
    if (bearer) {
      fetchData();
      fetchArchivedData();
      fetchGl();
      fetchData8();
    }
  }, [bearer]);

  const createUser = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${BASE_URL}/items/add-new-item`,
        {
          name: name,
          description: description,
          unit: unit,
          gl_code: glCode,
          re_order_level: reOderLevel,
          price: price,
          quantity: stockBalance


        },
        { headers }
      );
      console.log(response)
      fetchData();
      fetchData8();
      fetchArchivedData();
      handleClose();

      // return
      toast.success(response.data.message);
      console.log(response.data);

    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        toast.error(JSON.stringify(error.response.data.data));
        console.log(error);
      }
    } finally {
      setLoading(false);
    }
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const formattedDate = `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())} ${padZero(date.getHours())}:${padZero(date.getMinutes())} ${date.getHours() >= 12 ? 'PM' : 'AM'}`;
    return formattedDate;
  }

  function padZero(num) {
    return num < 10 ? `0${num}` : num;
  }


  const filteredData = tableData.filter(item => item.name.toLowerCase().includes(searchTerm.toLowerCase()));

  const totalPages = Math.ceil(filteredData.length / entriesPerPage);

  const handlePrevPage = () => {
    setCurrentPage(Math.max(currentPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage(Math.min(currentPage + 1, totalPages));
  };

  const totalEntries = filteredData.length;
  const startIndexx = (currentPage - 1) * entriesPerPage + 1;
  const endIndexx = Math.min(startIndexx + entriesPerPage - 1, totalEntries);
  const displayedData = filteredData.slice(startIndexx - 1, endIndexx);


  const handleEyeClick = (id) => {
    const foundItems = tableData.find(item => item.id === id);
    const itemId = foundItems.id;
    setSelectedItem(itemId);
    navigate('/account/edit_services', { state: { selectedProducts: foundItems } })



    setEyeClicked(true);
  };
  const handleEyeClick1 = (id) => {
    const foundItems = archiveData.find(item => item.id === id);
    const itemId = foundItems.id;
    setSelectedItem(itemId);
    navigate('/account/edit_services', { state: { selectedProducts: foundItems } })



    setEyeClicked(true);
  };

  // UseEffect to log changes to selectedItem
  useEffect(() => {
    // console.log(selectedItem, "selectedItem changed");
  }, [selectedItem]);




  const editUser = async (id) => {
    setUpdateLoading(true);

    try {
      const response = await axios.post(
        `${BASE_URL}/items/update-item`,
        {
          name: name1,
          description: description1,
          unit: unit1,
          gl_code: glCode1,
          re_order_level: reOderLevel1,
          id: selectedItem,
          price: price1,
          quantity: stockBalance1,

        },
        { headers }
      );

      fetchData();
      fetchData8();
      fetchArchivedData();
      handleClose1();
      toast.success(response.data.message);
      // console.log(response.data);
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        toast.error(errorMessage);
        console.log(error);
      }
    } finally {
      setUpdateLoading(false);
    }
  };


  const handleTrashClick = async (id) => {
    const confirmed = await Swal.fire({
      title: 'Are you sure?',
      text: 'You are about to move this product/service to the archive.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, move it!',
      cancelButtonText: 'No, cancel',
    });

    if (!confirmed.isConfirmed) {
      return; // User canceled, do nothing
    }

    try {
      const response = await axios.get(`${BASE_URL}/items/delete-item?id=${id}`, { headers });
      toast.success(response.data.message);
      fetchData();
      fetchArchivedData();
      fetchData8();
      setTrashClicked(true);
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        toast.error(errorMessage)
        console.log(errorMessage);
      }
    }
  };

  const handleTrashClick1 = async (id) => {
    const confirmed = await Swal.fire({
      title: 'Are you sure?',
      text: 'You are about to delete this product/service ',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel',
    });

    if (!confirmed.isConfirmed) {
      return; // User canceled, do nothing
    }

    try {
      const response = await axios.get(`${BASE_URL}/items/delete-single-softdelete?id=${id}`, { headers });
      toast.success(response.data.message);
      fetchData();
      fetchArchivedData();
      fetchData8();
      setTrashClicked(true);
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        toast.error(errorMessage)
        console.log(errorMessage);
      }
    }
  };
  const handleTrashClick2 = async (id) => {
    const confirmed = await Swal.fire({
      title: 'Are you sure?',
      text: 'You are about to restore this product/service ',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, restore it!',
      cancelButtonText: 'No, cancel',
    });

    if (!confirmed.isConfirmed) {
      return; // User canceled, do nothing
    }

    try {
      const response = await axios.get(`${BASE_URL}/items/restore-single-softdelete?id=${id}`, { headers });
      toast.success(response.data.message);
      fetchData();
      fetchArchivedData();
      fetchData8();
      setTrashClicked(true);
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        toast.error(errorMessage)
        console.log(errorMessage);
      }
    }
  };

  const handleUnitChange = (event) => {
    setUnit(event.target.value)
  }
  const handleGlChange = (event) => {
    setglCode(event.target.value)
  }

  const handleUnitChange1 = (event) => {
    setUnit1(event.target.value)
  }
  const handleGlChange1 = (event) => {
    setglCode1(event.target.value)
  }



  return (
    <div>
      <LatestDash />

      <div className={classes.finishedbodyCont}>
        {/* <h3 className={classes.productText1}>Products/List Items</h3> */}
        <div style={{display: "flex", flexDirection: 'row', justifyContent: "space-between", alignItems: "center", marginBottom: 30}}>
        <div style={{display: "flex", flexDirection: 'column'}}>
        <h3 style={{ fontSize: 30, fontWeight: 700, margin: " 10px 10px 0  10px" }}>Services List</h3>
        <p style={{ margin: " 0 10px 20px  10px" }}>Upload, View, Edit, and Delete Services</p>
        </div>
        <div className={classes.laondetheadTopCards}>

<div className={classes.card}>
  <p>Product/Services Total Value</p>
  <h4>0.00</h4>
</div>

<div className={classes.card}>
  <p>Total Number of Products/Services</p>
  <h4>{newLoading ? <Spinner size='sm' /> : stat?.total_number_of_products}</h4>
</div>

</div>
</div>
        <div className={classes.mainform}>
          {/* <div style={{ backgroundColor:'white'}}> */}
          <div className={classes.loandgrantcards}>

            <div className={classes.formLabel}>
            </div>
            <div className={classes.loandethead1}>
             
              <div className={classes.formIntBtn}>
                <OverlayTrigger
                  placement="top"
                  className={classes.btndf1}
                  overlay={<Tooltip id="button-tooltip" className={classes.btndf1}>Click here to upload your stock</Tooltip>}
                >
                  <Button variant="success" className={classes.btn1}>
                    Upload Stock
                  </Button>
                </OverlayTrigger>
                {/* <Button variant="success" onClick={handleShow} className={classes.btn2}> Add New Stock</Button> */}
                {/* <Button variant="success"  className={classes.btn1}> Upload Stock</Button> */}
              </div>
            </div>
            {/* <Modal show={show} onHide={handleClose} animation={false}>
              <Modal.Header closeButton>
                <Modal.Title>Add Items</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form style={{ marginTop: 20 }}>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Stock Name"
                      // autoFocus
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                    <div style={{ marginTop: 10 }} />
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Description"
                      // autoFocus
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    />

                    <div style={{ marginTop: 10 }} />
                    <Form.Label>Unit of Measurement</Form.Label>
                    <Form.Select
                      type="text"
                      placeholder="Enter Description"
                      // autoFocus
                      value={unit}
                      onChange={handleUnitChange}
                    >
                      <option value="" disabled>Select Unit of Measurement</option>
                      {roless.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </Form.Select>

                    <div style={{ marginTop: 10 }} />
                    <Form.Label>Unit Price</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter unit price"
                      // autoFocus
                      value={price}
                      onChange={(e) => setPrice(e.target.value)}
                    />

                    <div style={{ marginTop: 10 }} />
                    <Form.Label>Opening Stock Balance</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Opening Stock Balance"
                      // autoFocus
                      value={stockBalance}
                      onChange={(e) => setStockBalance(e.target.value)}
                    />

                    <div style={{ marginTop: 10 }} />
                    <Form.Label>Re-Order Level</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Input Re-Order Level"
                      // autoFocus
                      value={reOderLevel}
                      onChange={(e) => setReOrderLevel(e.target.value)}
                    />

                    <div style={{ marginTop: 10 }} />
                    <Form.Label>GL</Form.Label>
                    <Form.Select
                      type="text"
                      placeholder="Enter Price"
                      value={glCode}
                      onChange={handleGlChange}
                    >
                      <option value="" disabled>Select GL</option>
                      {tableData2.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.gl_name}
                        </option>
                      ))}


                    </Form.Select>

                    <div style={{ marginTop: 10 }} />

                  </Form.Group>
                </Form>
              </Modal.Body>


              <Modal.Footer>
                <Button variant="danger" onClick={handleClose}>
                  Go back
                </Button>
                <Button variant="success" onClick={createUser}>
                  {loading ? (
                    <>
                      <Spinner size='sm' />
                      <span style={{ marginLeft: '5px' }}>Creating Item, Please wait...</span>
                    </>
                  ) : (
                    "Create Stock"
                  )}
                </Button>
              </Modal.Footer>
            </Modal> */}

            <Modal show={show1} onHide={handleClose1} animation={false}>
              <Modal.Header closeButton>
                <Modal.Title> Edit Items</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form style={{ marginTop: 20 }}>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Stock Name"
                      // autoFocus
                      value={name1}
                      onChange={(e) => setName1(e.target.value)}
                    />
                    <div style={{ marginTop: 10 }} />
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Description"
                      // autoFocus
                      value={description1}
                      onChange={(e) => setDescription1(e.target.value)}
                    />
                    <div style={{ marginTop: 10 }} />
                    <Form.Label>Unit of Measurement</Form.Label>
                    <Form.Select
                      type="text"
                      placeholder="Enter Description"
                      // autoFocus
                      value={unit1}
                      onChange={handleUnitChange1}
                    >
                      <option value="" disabled>Select Unit of Measurement</option>
                      {roless.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </Form.Select>

                    <div style={{ marginTop: 10 }} />
                    <Form.Label>Unit Price</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter unit price"
                      // autoFocus
                      value={price1}
                      onChange={(e) => setPrice1(e.target.value)}
                    />

                    <div style={{ marginTop: 10 }} />
                    <Form.Label>Opening Stock Balance</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Opening Stock Balance"
                      // autoFocus
                      value={stockBalance1}
                      onChange={(e) => setStockBalance1(e.target.value)}
                    />

                    <div style={{ marginTop: 10 }} />
                    <Form.Label>Re-Order Level</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Input Re-Order Level"
                      // autoFocus
                      value={reOderLevel1}
                      onChange={(e) => setReOrderLevel1(e.target.value)}
                    />

                    <div style={{ marginTop: 10 }} />
                    <Form.Label>GL</Form.Label>
                    <Form.Select
                      type="text"
                      placeholder="Enter Price"
                      value={glCode1}
                      onChange={handleGlChange1}
                    >
                      <option value="" disabled>Select GL</option>
                      {tableData2.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.gl_name}
                        </option>
                      ))}


                    </Form.Select>

                    <div style={{ marginTop: 10 }} />

                  </Form.Group>
                </Form>
              </Modal.Body>

              <Modal.Footer>
                <Button variant="danger" onClick={handleClose1}>
                  Go back
                </Button>

                <Button variant="success" onClick={editUser} >
                  {updateLoading ? (<> <Spinner size='sm' />
                    <span style={{ marginLeft: '5px' }}>
                      Updating item, Please wait...
                    </span>
                  </>) : ("Save Changes")}
                </Button>
              </Modal.Footer>
            </Modal>


<Tabs
defaultActiveKey="Products"
id="uncontrolled-tab-example"
className="mb-3 complete-tabs"
variant="underline"
color="#2D995F"
>
<Tab  eventKey="Products" title="Products/Services">
<div className={`d-flex justify-content-between align-items-center ${classes.btnStyle}`}>
              <div className={`${classes.greenbtn} ${classes.btndivStyle}`}>
                <div className={classes.actionsBtns}>
                  <Button variant='success'>Copy</Button>
                  <Button variant='success'>Excel</Button>
                  <Button variant='success'>PDF</Button>
                  <Button variant='success'>Column visibility</Button>
                </div>
                <div className={classes.show}>
                  <label className="d-flex justify-content-start align-items-center">
                    Show
                    <select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" className="custom-select custom-select-sm form-control form-control-sm"
                    //  value={entriesPerPage}
                    //     onChange={(e) => {
                    //     setEntriesPerPage(parseInt(e.target.value));
                    //     setCurrentPage(1);
                    //     }}
                    >
                      <option value={10}>10</option>
                      <option value={25}>25</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                    </select>
                    entries
                  </label>
                </div>
              </div>
              <div className="text-right modal-effect ">
                <div id="DataTables_Table_0_filter" className="dataTables_filter">
                  <div className="d-flex justify-content-start align-items-center">
                    <div className="mr-2">Search:</div>
                    <input
                      type="search"
                      value={searchTerm}
                      className="form-control form-control-sm"
                      placeholder=""
                      aria-controls="DataTables_Table_0"
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    {/* <Button style={{marginLeft: 10}} variant="success" onClick= {() => fetchSearch(searchTerm)}>
                    {searchLoading ? (
                        <>
                        <Spinner  size='sm' /> 
                      
      </>
    ) : (
                  "Search"
                )}
                    </Button> */}
                  </div>


                </div>
              </div>
            </div>
            <div className={classes.mainTable}>
              {roleLoading ? (
                <p>Fetching products...</p>
              ) : (
                <div className="table-responsive">
                  <table className="table display table-bordered table-striped table-hover bg-white m-0 card-table">

                    <thead style={{ whiteSpace: 'nowrap' }}>
                      <tr>
                        <th style={{ whiteSpace: 'wrap', width: 40}}>S/N</th>
                        <th style={{ whiteSpace: 'wrap', width: 200}}>Name</th>
                        <th style={{ whiteSpace: 'wrap', width: 200}}>Description</th>
                        <th>Unit Price</th>
                        <th style={{ whiteSpace: 'wrap', width: 100}}>Unit of Measurement</th>
                        <th style={{ whiteSpace: 'wrap', width: 100}}>Quantity in Stock</th>
                        <th style={{ whiteSpace: 'wrap', width: 100}}>Re-Order Level</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody style={{ whiteSpace: 'nowrap', }}>

                      {displayedData.map((item, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td style={{ whiteSpace: 'wrap', }}>{item.name}</td>
                          <td style={{ textAlign: "left", whiteSpace: 'wrap', }}>{item.description}</td>
                          <td style={{ textAlign: "right" }}>{item.price === null ? "" : parseFloat(item.price).toLocaleString('en-US', {
                            minimumIntegerDigits: 1,
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          })}</td>
                          <td style={{ textAlign: "left" }}>{item?.measurement?.name}</td>
                          <td>{item.stock?.quantity}</td>
                          <td>{item.re_order_level}</td>
                          <td>
                          <OverlayTrigger
                  placement="top"
                  className={classes.btndf1}
                  overlay={<Tooltip id="button-tooltip" className={classes.btndf1}>Click here to edit/update your product/service</Tooltip>}
                >
                            <div onClick={() => handleEyeClick(item.id)} style={{ padding: "2px 10px" }} className="btn btn-success-soft btn-sm mr-1">
                            <i class="fas fa-pencil-alt" style={{ color: "#008a4b", backgroundColor: "#28a7451a", borderColor: "#28a7454d", borderRadius: 5, fontSize: 12 }}></i>
                            </div>
                            </OverlayTrigger>

                            <OverlayTrigger
                  placement="top"
                  className={classes.btndf1}
                  overlay={<Tooltip id="button-tooltip" className={classes.btndf1}>Click here to move your product/service to archive</Tooltip>}
                >
                            <div onClick={() => handleTrashClick(item.id)}  style={{ padding: "0", marginRight: 10 }} className="btn btn-danger-soft btn-sm">
                            <i class="fa fa-archive" style={{ color: "#000", backgroundColor: "#dc35451a", borderColor: "#dc35454d", borderRadius: 5, fontSize: 12 }}></i>
                            </div>
                            </OverlayTrigger>

                            <OverlayTrigger
                  placement="top"
                  className={classes.btndf1}
                  overlay={<Tooltip id="button-tooltip" className={classes.btndf1}>Click here to permanently delete your product/service</Tooltip>}
                >
                            <div onClick={() => handleTrashClick1(item.id)} style={{ padding: "0" }} className="btn btn-danger-soft btn-sm">
                              <i className="far fa-trash-alt" style={{ color: "#dc3545", backgroundColor: "#dc35451a", borderColor: "#dc35454d", borderRadius: 5, fontSize: 12 }}></i>
                            </div>
                            </OverlayTrigger>

                           
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>

            <div className={classes.endded}>
              <p>
                Showing {startIndexx} to {endIndexx} of {totalEntries} entries
              </p>
              <div style={{ display: 'flex' }}>
                <button
                  style={{ border: 'none', backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'nunito', color: '#000', marginRight: 10, cursor: "pointer" }}
                  onClick={handlePrevPage}
                  disabled={currentPage === 1}
                >
                  Previous
                </button>
                {[...Array(totalPages)].map((_, page) => {
                  // Show only 5 pages or less if available
                  if (page < 5 || page === currentPage - 1 || page === totalPages - 1) {
                    return (
                      <button
                        key={page + 1}
                        style={{
                          marginLeft: '0.4rem',
                          marginRight: '0.4rem',
                          fontSize: '14px',
                          fontFamily: 'nunito',
                          fontWeight: 400,
                          color: page + 1 === currentPage ? '#ffffff' : '#000',
                          backgroundColor: page + 1 === currentPage ? '#28a745' : 'gainsboro',
                          height: '2.5rem',
                          borderRadius: '89px',
                          padding: '0.5rem',
                          border: 'none',
                          width: '40px',
                          cursor: "pointer"
                        }}
                        onClick={() => setCurrentPage(page + 1)}
                      >
                        {page + 1}
                      </button>
                    );
                  }
                  return null;
                })}
                <button
                  style={{ cursor: "pointer", border: 'none', backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'nunito', color: '#000', marginLeft: 10 }}
                  onClick={handleNextPage}
                  disabled={currentPage === totalPages}
                >
                  Next
                </button>
              </div>
            </div>
</Tab>
<Tab  eventKey="ArchivedProducts" title="Deleted or Archived">
<div className={`d-flex justify-content-between align-items-center ${classes.btnStyle}`}>
              <div className={`${classes.greenbtn} ${classes.btndivStyle}`}>
                <div className={classes.actionsBtns}>
                  <Button variant='success'>Copy</Button>
                  <Button variant='success'>Excel</Button>
                  <Button variant='success'>PDF</Button>
                  <Button variant='success'>Column visibility</Button>
                </div>
                <div className={classes.show}>
                  <label className="d-flex justify-content-start align-items-center">
                    Show
                    <select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" className="custom-select custom-select-sm form-control form-control-sm"
                    //  value={entriesPerPage}
                    //     onChange={(e) => {
                    //     setEntriesPerPage(parseInt(e.target.value));
                    //     setCurrentPage(1);
                    //     }}
                    >
                      <option value={10}>10</option>
                      <option value={25}>25</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                    </select>
                    entries
                  </label>
                </div>
              </div>
              <div className="text-right modal-effect ">
                <div id="DataTables_Table_0_filter" className="dataTables_filter">
                  <div className="d-flex justify-content-start align-items-center">
                    <div className="mr-2">Search:</div>
                    <input
                      type="search"
                      value={searchTerm}
                      className="form-control form-control-sm"
                      placeholder=""
                      aria-controls="DataTables_Table_0"
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    {/* <Button style={{marginLeft: 10}} variant="success" onClick= {() => fetchSearch(searchTerm)}>
                    {searchLoading ? (
                        <>
                        <Spinner  size='sm' /> 
                      
      </>
    ) : (
                  "Search"
                )}
                    </Button> */}
                  </div>


                </div>
              </div>
            </div>
            <div className={classes.mainTable}>
              {roleLoading ? (
                <p>Fetching products...</p>
              ) : (
                <div className="table-responsive">
                  <table className="table display table-bordered table-striped table-hover bg-white m-0 card-table">

                  <thead style={{ whiteSpace: 'nowrap' }}>
                      <tr>
                        <th style={{ whiteSpace: 'wrap', width: 40}}>S/N</th>
                        <th style={{ whiteSpace: 'wrap', width: 200}}>Name</th>
                        <th style={{ whiteSpace: 'wrap', width: 200}}>Description</th>
                        <th>Unit Price</th>
                        <th style={{ whiteSpace: 'wrap', width: 100}}>Unit of Measurement</th>
                        <th style={{ whiteSpace: 'wrap', width: 100}}>Quantity in Stock</th>
                        <th style={{ whiteSpace: 'wrap', width: 100}}>Re-Order Level</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody style={{ whiteSpace: 'nowrap', }}>

                      {archiveData.map((item, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td style={{ whiteSpace: 'wrap', }}>{item.name}</td>
                          <td style={{ textAlign: "left", whiteSpace: 'wrap', }}>{item.description}</td>
                          <td style={{ textAlign: "right" }}>{parseFloat(item.price).toLocaleString('en-US', {
                            minimumIntegerDigits: 1,
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          })}</td>
                          <td style={{ textAlign: "left" }}>{item?.measurement?.name}</td>
                          <td>{item.stock?.quantity}</td>
                          <td>{item.re_order_level}</td>
                          <td>
                          <OverlayTrigger
                  placement="top"
                  className={classes.btndf1}
                  overlay={<Tooltip id="button-tooltip" className={classes.btndf1}>Click here to edit/update your archived product/service</Tooltip>}
                >
                            <div onClick={() => handleEyeClick1(item.id)} style={{ padding: "2px 10px" }} className="btn btn-success-soft btn-sm mr-1">
                            <i class='fas fa-pencil-alt' style={{ color: "#008a4b", backgroundColor: "#28a7451a", borderColor: "#28a7454d", borderRadius: 5, fontSize: 14 }}></i>
                            </div>
                            </OverlayTrigger>

                          <OverlayTrigger
                  placement="top"
                  className={classes.btndf1}
                  overlay={<Tooltip id="button-tooltip" className={classes.btndf1}>Click here to restore your archived product/service</Tooltip>}
                >
                            <div onClick={() => handleTrashClick2(item.id)} style={{ padding: "2px 10px" }} className="btn btn-success-soft btn-sm mr-1">
                            <i class='fas fa-trash-restore-alt' style={{ color: "#008a4b", backgroundColor: "#28a7451a", borderColor: "#28a7454d", borderRadius: 5, fontSize: 14 }}></i>
                            </div>
                            </OverlayTrigger>

                            <OverlayTrigger
                  placement="top"
                  className={classes.btndf1}
                  overlay={<Tooltip id="button-tooltip" className={classes.btndf1}>Click here to permanently delete your product/service</Tooltip>}
                >
                            <div onClick={() => handleTrashClick1(item.id)} style={{ padding: "0" }} className="btn btn-danger-soft btn-sm">
                              <i className="far fa-trash-alt" style={{ color: "#dc3545", backgroundColor: "#dc35451a", borderColor: "#dc35454d", borderRadius: 5, fontSize: 12 }}></i>
                            </div>
                            </OverlayTrigger>
                            {/* <div onClick={() => handleTrashClick(item.id)} style={{ padding: "0" }} className="btn btn-danger-soft btn-sm">
                              <i className="far fa-trash-alt" style={{ color: "#dc3545", backgroundColor: "#dc35451a", borderColor: "#dc35454d", borderRadius: 5, fontSize: 12 }}></i>
                            </div> */}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>

            <div className={classes.endded}>
              <p>
                Showing {startIndexx} to {endIndexx} of {totalEntries} entries
              </p>
              <div style={{ display: 'flex' }}>
                <button
                  style={{ border: 'none', backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'nunito', color: '#000', marginRight: 10, cursor: "pointer" }}
                  onClick={handlePrevPage}
                  disabled={currentPage === 1}
                >
                  Previous
                </button>
                {[...Array(totalPages)].map((_, page) => {
                  // Show only 5 pages or less if available
                  if (page < 5 || page === currentPage - 1 || page === totalPages - 1) {
                    return (
                      <button
                        key={page + 1}
                        style={{
                          marginLeft: '0.4rem',
                          marginRight: '0.4rem',
                          fontSize: '14px',
                          fontFamily: 'nunito',
                          fontWeight: 400,
                          color: page + 1 === currentPage ? '#ffffff' : '#000',
                          backgroundColor: page + 1 === currentPage ? '#28a745' : 'gainsboro',
                          height: '2.5rem',
                          borderRadius: '89px',
                          padding: '0.5rem',
                          border: 'none',
                          width: '40px',
                          cursor: "pointer"
                        }}
                        onClick={() => setCurrentPage(page + 1)}
                      >
                        {page + 1}
                      </button>
                    );
                  }
                  return null;
                })}
                <button
                  style={{ cursor: "pointer", border: 'none', backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'nunito', color: '#000', marginLeft: 10 }}
                  onClick={handleNextPage}
                  disabled={currentPage === totalPages}
                >
                  Next
                </button>
              </div>
            </div>
</Tab>

</Tabs>
            

          </div>

          {/* </div> */}
        </div>

      </div>






    </div >
  )
}