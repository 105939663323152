import React, { useState, useEffect } from 'react';
import classes from './Pending.module.css';
// import RegLogo from '../../Images/RegistrationLogo.svg'
import { Spinner, Badge, Button, Modal, Form } from 'react-bootstrap';
// import Folder from '../../Images/folder-2.svg';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
// import ArrowLogo from '../../Images/arrow-left.svg';
// import LoanImage from '../../Images/loan bg.svg';
import MainDashoard from '../Main Dashboard/MainDashoard';
// import Ready from '../../Images/nothing.svg'
// import Ready1 from '../../Images/review.svg';
// import favicon from '../../Images/faviconn.png'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Table from 'react-bootstrap/Table';
import { BASE_URL } from '../api/api';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import styled from 'styled-components';
import CashDash from '../Cash Dash/CashDash';
import Select from 'react-select';
import CurrencyInput from 'react-currency-input-field';
import LatestDash from '../LatestDashboard/LatestDash';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';



export default function Pending() {
  const [entriesPerPage, setEntriesPerPage] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [load, setLoad] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingss, setIsLoadingss] = useState(false);
  const [ledgLoading, setLedgLoading] = useState(false);
  const [loadings, setLoadings] = useState(false);
  const [lodgeLoading, setLodgeLoading] = useState(false);
  const [pendingLoading, setPendingLoading] = useState(false);
  const [bearer, setBearer] = useState('');
  const navigate = useNavigate();
  const [selectedEndDate, setSelectedEndDate] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [accounts, setAccounts] = useState([]);
  const [inputss, setInputss] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [allAccounts, setAllAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState('');
  const [totalDebit, setTotalDebit] = useState('');
  const [totalCredit, setTotalCredit] = useState('');
  const [user, setUser] = useState('');
  const [ledgTableData, setLedgTableData] = useState([]);
  const [totalEntries, setTotalEntries] = useState("");
  const [selectedBank, setSelectedBank] = useState("");
  const [totalAmount, setTotalAmount] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [selectedRows, setSelectedRows] = useState([]);
  const [bankkk, setBankkk] = useState([]);
  const [banks, setBanks] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const [sICode, setSICode] = useState('');
  const [userType, setUserType] = useState('');
  const [tellerNumber, setTellerNumber] = useState('');


  const [userTypes, setUserTypes] = useState('');
  const [region, setRegion] = useState('');
  const [province, setProvince] = useState('');
  const [continent, setContinent] = useState('');
  const [admin, setAdmin] = useState('');


  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem('userToken');
      const value1 = await AsyncStorage.getItem('tobi');
      const value2 = await AsyncStorage.getItem('admin');
      const admins = await AsyncStorage.getItem('admin');
      const continentName = await AsyncStorage.getItem('continent');
      const regionName = await AsyncStorage.getItem('region');
      const provinceName = await AsyncStorage.getItem('province');



      if (value !== null) {
        setBearer(value);
      }
      if (value1 !== null) {
        setUser(value1);
      }
      if (value2 !== null) {
        setAdmin(value2);
      }

      if (admins !== null) {
        setUserTypes(admins);
      }
      if (continentName !== null) {
        setContinent(continentName);
      }
      if (regionName !== null) {
        setRegion(regionName);
      }
      if (provinceName !== null) {
        setProvince(provinceName);
      }
      if (continentName !== null) {
        setContinent(continentName);
      }
      if (regionName !== null) {
        setRegion(regionName);
      }
      if (provinceName !== null) {
        setProvince(provinceName);
      }
    } catch (e) {
      alert('Failed to fetch the input from storage');
    }
  };

  useEffect(() => {
    readData();
  }, []);

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${bearer}`
  };

  const fetchReceipt = async () => {
    setPendingLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/fetch-all-pending-reciepts?page=${currentPage}`, { headers });
      const results = response.data?.data?.data;
      const resultx = response.data?.data?.total;
      setTotalEntries(resultx);
      setLedgTableData(results);
      const total = response.data?.data?.last_page || 1;
      setTotalPages(total);
      console.log("response:", results);
      // toast.success(response.data.message);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate('/login');
      } else {
        let errorMessage = 'An error occurred. Please try again.';
        if (error.response && error.response.data && error.response.data.message) {
          if (typeof error.response.data.message === 'string') {
            errorMessage = error.response.data.message;
          } else if (Array.isArray(error.response.data.message)) {
            errorMessage = error.response.data.message.join('; ');
          } else if (typeof error.response.data.message === 'object') {
            toast.error(errorMessage)
            console.log(errorMessage);
          }
        }
        setLedgTableData([]);
      }
    } finally {
      setPendingLoading(false);
    }
  };



  useEffect(() => {
    if (bearer) {
      fetchReceipt();

    }
  }, [bearer, currentPage]);


  //filter function
  // const filteredData = tableData.filter(item => item.name.toLowerCase().includes(searchTerm.toLowerCase()));

  // const totalPages = Math.ceil(filteredData.length / entriesPerPage);

  const handlePrevPage = () => {
    setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages));
  };

  // const filteredData = ledgTableData.filter(item => item.name.includes(searchTerm));
  const startIndexx = (currentPage - 1) * entriesPerPage + 1;
  const endIndexx = Math.min(startIndexx + entriesPerPage - 1, totalEntries);
  // const displayedData = filteredData.slice(currentPage - 1, totalEntries);


  const handleDateChange1 = (event) => {
    setSelectedEndDate(event.target.value);
  };

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };





  // const handlePrevPage = () => {
  //     setCurrentPage(Math.max(currentPage - 1, 1));
  // };

  // const handleNextPage = () => {
  //     setCurrentPage(Math.min(currentPage + 1, totalPages));
  // };

  // const totalEntries = filteredData.length;
  // const startIndexx = (currentPage - 1) * entriesPerPage + 1;
  // const endIndexx = Math.min(startIndexx + entriesPerPage - 1, totalEntries);
  // const displayedData = filteredData.slice(startIndexx - 1, endIndexx);





  const fetchAccounts = async () => {
    setLoad(true);

    try {
      const response = await axios.get(`${BASE_URL}/reports/general-ledger-filter`,
        {
          params: {
            gl_code: selectedAccount,
            start_date: selectedDate,
            end_date: selectedEndDate
          },
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearer}`
          }
        }
      );

      const resultsss = response.data?.data?.journal;
      setAccounts(resultsss);

      const resultssx = response.data?.data?.input;
      setInputss(resultssx);

      // console.log(resultssx, 'NI');
      toast.success(response.data.message);
      setSelectedAccount("");
      setSelectedDate("");
      setSelectedEndDate("");
    } catch (error) {
      if (error.response && error.response.status === 401) {

        navigate('/login');
      } else {
        let errorMessage = 'An error occurred. Please try again.';
        if (error.response && error.response.data && error.response.data.message) {
          if (typeof error.response.data.message === 'string') {
            errorMessage = error.response.data.message;
          } else if (Array.isArray(error.response.data.message)) {
            errorMessage = error.response.data.message.join('; ');
          } else if (typeof error.response.data.message === 'object') {
            errorMessage = JSON.stringify(error.response.data.message);
          }
          toast.error(errorMessage)
          console.log(errorMessage);
        }
      }

    } finally {
      setLoad(false);
    }
  };

  const fetchCharts = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/account`, { headers });
      const results = response.data?.data;

      setTableData(results);
      // console.log(results);
    } catch (error) {
      const errorStatus = error.response?.data?.message;
      console.log(errorStatus);
      setTableData([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (bearer) {
      fetchCharts();
    }
  }, [bearer]);

  const handleAccountChange = (event) => {
    setSelectedAccount(event.target.value);
    // Set the start date to the first date of the current month
    const currentDate = new Date();
    const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    setSelectedDate(firstDayOfMonth.toISOString().split('T')[0]);
    // Set the end date to the current date
    setSelectedEndDate(currentDate.toISOString().split('T')[0]);
    // Fetch accounts
    fetchAccounts();
  };



  useEffect(() => {
    if (accounts) {
      const debitTotal = accounts.reduce((total, item) => total + parseFloat(item.debit), 0);
      const creditTotal = accounts.reduce((total, item) => total + parseFloat(item.credit), 0);

      // Format the numbers with commas and two decimal places
      const formattedDebitTotal = debitTotal.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
      const formattedCreditTotal = creditTotal.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });

      setTotalDebit(formattedDebitTotal);
      setTotalCredit(formattedCreditTotal);
    }
  }, [accounts]);


  function formatDate(dateString) {
    const date = new Date(dateString);
    const formattedDate = `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())} ${padZero(date.getHours())}:${padZero(date.getMinutes())} ${date.getHours() >= 12 ? 'PM' : 'AM'}`;
    return formattedDate;
  }

  function padZero(num) {
    return num < 10 ? `0${num}` : num;
  }

  // const filteredData = ledgTableData.filter(item => {
  //   const searchFields = [item.narration, item.transaction_date, formatDate(item.created_at)];
  //   return searchFields.some(field => field.toLowerCase().includes(searchTerm.toLowerCase()));
  // });

  const formattedTotalEntries = totalEntries.toLocaleString();

  // const handleCreate = ()=>{
  //     navigate('/accounting/income_and_expenditure/income/add_new_income')
  // }

  const isSelected = (id) => {
    return selectedRows.includes(id);
  };

  // const handleCheckboxChange = (row) => {
  //   const selectedIndex = selectedRows.findIndex(item => item.id === row.id);
  //   let updatedSelectedRows = [];

  //   if (selectedIndex === -1) {
  //     updatedSelectedRows = [...selectedRows, row];
  //   } else {
  //     updatedSelectedRows = selectedRows.filter(item => item.id !== row.id);
  //   }

  //   setSelectedRows(updatedSelectedRows);
  //   setSelectAll(updatedSelectedRows.length === tableData.length);
  //   // console.log(updatedSelectedRows);
  // };

  const handleSelectAllChange = (event) => {
    const isChecked = event.target.checked;
    setSelectAll(isChecked);
    setSelectedRows(isChecked ? [...ledgTableData] : []);
    // console.log(isChecked ? [...tableData] : []);
  };

  const handleCheckboxChange = (row) => {
    const selectedIndex = selectedRows.findIndex(item => item.id === row.id);
    let updatedSelectedRows = [];

    if (selectedIndex === -1) {
      updatedSelectedRows = [...selectedRows, row];
    } else {
      updatedSelectedRows = selectedRows.filter(item => item.id !== row.id);
    }

    setSelectedRows(updatedSelectedRows);
    setSelectAll(updatedSelectedRows.length === ledgTableData.length);
    // console.log(updatedSelectedRows);
  };

  const calculateTotalAmount = (rows) => {
    const total = rows.reduce((total, row) => {
      const amount = parseFloat(row.currency_amount);
      console.log(amount);
      return total + (isNaN(amount) ? 0 : amount);
    }, 0);
    return total.toFixed(2);
  };



  const handleCreate = () => {
    if (selectedRows.length === 0) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please select at least a row to generate lodgement !',
      });
      return;
    }
    setShow(true);
    setTotalAmount(calculateTotalAmount(selectedRows));
  }

  console.log(selectedRows);

  const fetchInvoiceCode = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${BASE_URL}/generate-sales-invoice-code`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearer}`
          }
        }
      );
      const resultss = response.data?.data;
      //   console.log(resultss);
      setSICode(resultss);
      // console.log(invoiceData)
      //   console.log(results, "NI");
    } catch (error) {
      const errorStatus = error.response.data.message;
      console.error(errorStatus);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (bearer) {
      fetchInvoiceCode();
    }
  }, [bearer]);


  const fetchAccountss = async () => {
    setLoadings(true);

    try {
      const response = await axios.get(`${BASE_URL}/account/no-pagination`, { headers });

      const allAccts = response.data?.data;
      setAllAccounts(allAccts);
      const options1 = allAccts.map((item) => ({
        label: item.gl_name,
        value: item.id,
      }));
      setBanks(options1);
      console.log(allAccts, "All Accounts");
    } catch (error) {
      const errorStatus = error.response.data.message;
      console.error(errorStatus);
    } finally {
      setLoadings(false);
    }
  };

  useEffect(() => {
    fetchAccountss();
  }, [bearer]);


  const createLodgement = async () => {
    setLodgeLoading(true);
    try {
      const selectedRowIds = selectedRows.map(row => row.id);
      console.log("Selected Options:", selectedRowIds, selectedBank, sICode)
      const response = await axios.post(`${BASE_URL}/lodge-receipt`,
        {
          receipt_id: selectedRowIds,
          account_id: selectedBank,
          teller_number: sICode,

        },

        { headers }
      );
      console.log(response.data.message);
      handleClose();
      fetchReceipt();
      setSelectedRows([]);
      setTellerNumber('');
      setSelectedBank('');
      toast.success(response.data.message);
    } catch (error) {
      console.log(error);
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data) {
        console.log("Error Response Data:", error.response.data);
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        }
      }
      toast.error(errorMessage);
      console.log(errorMessage)
    } finally {
      setLodgeLoading(false);
    }
  };

  const handleBankChange = (selectedOption) => {
    setSelectedBank(selectedOption.value);
  }

  const handleValueChange = (value, name, values) => {
    setTotalAmount(value);
  };




  return (
    <div>
      <LatestDash />

      <div className={classes.finishedbodyCont}>
        <h3 style={{ fontSize: '30px', fontWeight: 700, margin: " 0px 10px 0  10px" }}>Bank Lodgement</h3>
        <p style={{ margin: " 0 10px 20px 10px" }}>Add and View Bank Lodgement</p>

        {/* <h3 className={classes.productText}>Bank Lodgement</h3> */}



        <div className={classes.mainform}>
          <div className={classes.topPadding}>
            <div className={`${classes.formSecCont}`}>
              <div className="card-body" style={{ border: 'none' }}>


                {/* <div className={`row ${classes.awrow}`} style={{}}> */}
                          <div className={`row ${classes.lblrow}`} style={{}}>
                            <div className="col-md-4">
                              <div className="form-group row">
                                <label htmlFor="example-text-input" className={`col-sm-5 col-form-label font-weight-400 ${classes.lblrow}`}>Start Date:</label>
                                <div className={`col-sm-9 ${classes.lblrow}`}>
                                  <input className="form-control" required="" type="date" onChange={handleDateChange} name="start" value={selectedDate} />
                                </div>
                              </div>
                            </div>
                            <div className={`col-md-4 ${classes.margintoleft} `}>
                              <div className="form-group row">
                                <label htmlFor="example-text-input" className={`col-sm-5 col-form-label font-weight-400 ${classes.lblrow}`}>End Date:</label>
                                <div className="col-sm-9">
                                  <input className="form-control" required="" type="date" onChange={handleDateChange1} name="end" value={selectedEndDate} />
                                </div>
                              </div>
                            </div>
                          <div class={`modal-footer ${classes.marginLeftalign}`} >

                            <OverlayTrigger
                              placement="top"
                              className={classes.btndf1}
                              overlay={<Tooltip id="button-tooltip" className={classes.btndf1}>Click here to process bank lodgement between selected dates</Tooltip>}
                            >
                              <Button

                                className={classes.overlayBtnbtn} variant='success' onClick={fetchAccounts}>
                                {load ? (
                                  <>
                                    <Spinner size='sm' />
                                    {/* <span style={{ marginLeft: '5px' }}>Processing, Please wait...</span> */}
                                  </>
                                ) : (
                                  "Process"
                                )}
                              </Button>
                            </OverlayTrigger>



                          </div>
                          </div>
                {/* </div> */}
               
              </div>
              <div className={classes.overlaytoughBtn}>
                    <OverlayTrigger
                      placement="top"
                      className={classes.btndf1}
                      overlay={<Tooltip id="button-tooltip" className={classes.btndf1}>Click here to add new bank lodgement</Tooltip>}
                    >
                      <Button
                        style={{ borderRadius: 0 }} variant='primary' onClick={handleCreate}>
                        Add Lodgement
                      </Button>
                    </OverlayTrigger>
                  </div>
            </div>

            <div className={classes.loandgrantcards}>
              <div className={`d-flex justify-content-between align-items-center ${classes.btnStyle}`}>
                <div className={`${classes.greebtn} ${classes.btndivStyle}`}>
                  {/* <div className={`${classes.greenbtn} ${classes.btndivStyle}`}> */}
                  {/* <div className={classes.actionsBtns}>
        <Button variant='success'>Copy</Button>
        <Button variant='success'>Excel</Button>
        <Button variant='success'>PDF</Button>
        <Button variant='success'>Column visibility</Button>
      </div> */}
                  

                  <div className={classes.show}>
                    <label className="d-flex justify-content-start align-items-center">
                      Show
                      <select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" className="custom-select custom-select-sm form-control form-control-sm"
                      //  value={entriesPerPage}
                      //     onChange={(e) => {
                      //     setEntriesPerPage(parseInt(e.target.value));
                      //     setCurrentPage(1);
                      //     }}
                      >
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </select>
                      entries
                    </label>
                  </div>
                </div>
                <div className="text-right modal-effect ">
                  <div id="DataTables_Table_0_filter" className="dataTables_filter">
                    <div className="d-flex justify-content-start align-items-center">
                      <div className="mr-2">Search:</div>
                      <input
                        type="search"
                        value={searchTerm}
                        className="form-control form-control-sm"
                        placeholder=""
                        aria-controls="DataTables_Table_0"
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                      {/* <Button style={{marginLeft: 10}} variant="success" onClick= {() => fetchSearch(searchTerm)}>
        {searchLoading ? (
            <>
            <Spinner  size='sm' /> 
           
</>
) : (
      "Search"
    )}
        </Button> */}
                    </div>


                  </div>
                </div>
              </div>
              <div className={classes.mainTable}>
                {pendingLoading ? (
                  <p>Fetching account...</p>
                ) : (
                  <div className="table-responsive">
                    <table className="table m-0 bg-white display table-bordered table-striped table-hover card-table">

                      <thead style={{ whiteSpace: 'nowrap' }}>
                        <tr>
                          <th>
                            <input
                              type="checkbox"
                              checked={selectAll}
                              onChange={handleSelectAllChange}
                            />
                          </th>
                          <td>S/N</td>
                          <th>Transaction Date</th>
                          <th>Particulars</th>
                          <th>Description</th>
                          {/* <th>Receipt Number</th> */}
                          <th>Amount</th>
                          <th>Payment Mode</th>
                          <th>Teller Number</th>
                          {/* <th>Received By</th> */}
                          {/* <th>Action</th> */}
                        </tr>
                      </thead>
                      <tbody style={{ whiteSpace: 'nowrap' }}>
                        {ledgTableData.map((item, index) => (
                          <tr key={item.id}>
                            <td>
                              <input
                                type="checkbox"
                                onChange={() => handleCheckboxChange(item)}
                                checked={isSelected(item)}
                              />
                            </td>
                            <td>{index + 1}</td>
                            <td>{item.transaction_date}</td>
                            <td>{item.particular}</td>
                            <td>{item.description}</td>
                            <td style={{ textAlign: "right" }}>{parseFloat(item.currency_amount).toLocaleString('en-US', {
                              minimumIntegerDigits: 1,
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2
                            })}
                            </td>
                            <td>{item?.mode?.name}</td>
                            <td>{item.teller_number}</td>
                            {/* <td>{item.invoice_number }</td> */}



                          </tr>
                        ))}

                      </tbody>
                    </table>
                  </div>
                )}

              </div>

              <div className={classes.endded}>
                <p>
                  Showing {startIndexx} to {endIndexx} of {totalEntries} entries
                </p>
                <div style={{ display: 'flex' }}>
                  <button
                    style={{ border: 'none', backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'nunito', color: '#000', marginRight: 10, cursor: "pointer" }}
                    onClick={handlePrevPage}
                    disabled={currentPage === 1}
                  >
                    Previous
                  </button>
                  {[...Array(totalPages)].map((_, page) => {
                    // Show only 5 pages or less if available
                    if (page < 5 || page === currentPage - 1 || page === totalPages - 1) {
                      return (
                        <button
                          key={page + 1}
                          style={{
                            marginLeft: '0.4rem',
                            marginRight: '0.4rem',
                            fontSize: '14px',
                            fontFamily: 'nunito',
                            fontWeight: 400,
                            color: page + 1 === currentPage ? '#ffffff' : '#000',
                            backgroundColor: page + 1 === currentPage ? '#28a745' : 'gainsboro',
                            height: '2.5rem',
                            borderRadius: '89px',
                            padding: '0.5rem',
                            border: 'none',
                            width: '40px',
                            cursor: "pointer"
                          }}
                          onClick={() => setCurrentPage(page + 1)}
                        >
                          {page + 1}
                        </button>
                      );
                    }
                    return null;
                  })}
                  <button
                    style={{ cursor: "pointer", border: 'none', backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'nunito', color: '#000', marginLeft: 10 }}
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                  >
                    Next
                  </button>
                </div>
              </div>

            </div>
          </div>

          <Modal

            show={show}
            onHide={handleClose}
            animation={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>Add Lodgement</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group className="mb-3" >
                  <Form.Label>Teller Number</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Teller Number"

                    value={tellerNumber}
                    onChange={(e) => setTellerNumber(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <div style={{ marginTop: 10 }} />
                  <Form.Label>Bank Lodged </Form.Label>
                  <Select
                    // value={selectedBank}
                    onChange={(selectedOption) => handleBankChange(selectedOption)}
                    options={banks}
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      menu: (provided) => ({
                        ...provided,
                        // maxHeight: '200px',
                        // overflowY: 'auto',
                      }),
                    }}
                  />




                </Form.Group>
                <Form.Group className="mb-3" >
                  <Form.Label>Total Amount Selected</Form.Label>
                  <CurrencyInput

                    name="Total Amount"
                    disabled
                    decimalsLimit={2}
                    className="form-control"
                    value={totalAmount}
                    onValueChange={handleValueChange}
                    style={{ textAlign: "left", border: "1px solid #e4e4e4", backgroundColor: "none" }}
                  />
                </Form.Group>
              </Form>
            </Modal.Body>

            <Modal.Footer>
              <Button variant="danger" onClick={handleClose}>
                Cancel
              </Button>
              <Button variant="success" onClick={createLodgement}>
                {lodgeLoading ? (
                  <>
                    <Spinner size='sm' />
                    <span style={{ marginLeft: '5px' }}>Generating Lodgement, Please wait...</span>
                  </>
                ) : (
                  "Create Lodgement"
                )}
              </Button>
            </Modal.Footer>
          </Modal>





        </div>

      </div>

    </div>
  )
}