import React, { useState, useEffect, useRef } from 'react';
// import "../../../assetss/assets/plugins/bootstrap/css/bootstrap.min.css";
// import "../../../assetss/assets/plugins/metisMenu/metisMenu.min.css";
// import "../../../assetss/assets/plugins/fontawesome/css/all.min.css";
// import "../../../assetss/assets/plugins/typicons/src/typicons.min.css";
// import "../../../assetss/assets/plugins/themify-icons/themify-icons.min.css";
// import "../../../assetss/assets/plugins/datatables/dataTables.bootstrap4.min.css";
// import { AdminHeaderNav } from '../AdminHeaderNav';
// import Footer from '../../Pages/Footer/Footer';
// import { InfoFooter } from '../../InfoFooter';
import Select from 'react-select';
import { toast, ToastContainer } from 'react-toastify';
import classes from "../Manage Loan/Report/LoandDis.module.css"
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Swal from 'sweetalert2';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import { Button, Spinner, Accordion, Badge, Form } from 'react-bootstrap';
// import favicon from '../../Im/ages/faviconn.png'
// import TableToPrint from './TableToPrint';
import { useReactToPrint } from 'react-to-print';
import { BASE_URL } from '../../api/api';
import MainDashboard from '../../Main Dashboard/MainDashoard';
import CurrencyInput from 'react-currency-input-field';
import CoopDashboard from '../../Cooperative Dashboard/CoopDashboard';
import ReceivablesDash from '../../Receivables Dashboard/ReceivablesDash';
import LatestCoopDash from '../../LatestDashboard/LatestCoopDash';

function DisburseLoan() {

  const [selectedCustomer, setSelectedCustomer] = useState('');
  const [selectedBank, setSelectedBank] = useState('');
  const [duration, setDuration] = useState('');
  const [selectedLoan, setSelectedLoan] = useState('');
  const [principalAmount, setPrincipalAmount] = useState('');
  const [loanInterest, setLoanInterest] = useState(0.00);
  const [interest, setInterest] = useState(0.00);
  const [totalRepayment, setTotalRepayment] = useState(0.00);
  const [monthlyDeduction, setMonthlyDeduction] = useState('');
  const [cheque, setCheque] = useState('');
  const [selectedCredit, setSelectedCredit] = useState('');
  const [customers, setCustomers] = useState([]);
  const [loans, setLoans] = useState([]);
  const [banks, setBanks] = useState([]);
  const [bankkk, setBankkk] = useState([]);
  const [loading, setLoading] = useState(false);
  const [createLoading, setCreateLoading] = useState(false);
  const [loanLoading, setLoanLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedDate, setSelectedDate] = useState('');
  const [selectOptions, setSelectOptions] = useState([]);
  const [bearer, setBearer] = useState('');
  const [user, setUser] = useState('');
  const [sICode, setSICode] = useState('');
  const [description, setDescription] = useState('');
  const navigate = useNavigate();
  const [formData, setFormData] = useState([{ loanType: '', interest: '', principalAmount: '', interestAmount: '', duration: '', totalAmount: '', repayment: '' }]);
  const [totalDebit, setTotalDebit] = useState(0);
    const [totalCredit, setTotalCredit] = useState(0);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [tableData, setTableData] = useState([]);
    const [selectedAccount, setSelectedAccount] = useState('');

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };





  // const handleReportChange = (e) => {
  //   setSelectedReport(e.target.value);
  // };



  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem('userToken');
      const value1 = await AsyncStorage.getItem('tobi');

      if (value !== null) {
        setBearer(value);
      }
      if (value1 !== null) {
        setUser(value1);
      }
    } catch (e) {
      alert('Failed to fetch the input from storage');
    }
  };

  useEffect(() => {
    readData();
  }, []);

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${bearer}`
  };

  const goBack = () => {
    navigate(-1);
  }

  const addRow = () => {
    const newRow = {
       loanType: '', interest: '', principalAmount: '', interestAmount: '', duration: '', totalAmount: '', repayment: ''
    };
    setFormData([...formData, newRow]);
    // setSelectedPayment('');
};

const deleteRow = (index) => {
    const updatedData = formData.filter((_, i) => i !== index);
    setFormData(updatedData);
};

const handleFormChange = (index, field, value) => {
    const updatedFormData = [...formData];
    const numericValue = parseFloat(value.replace(/,/g, '')) || 0;
    const formattedValue = numericValue.toFixed(2);

    updatedFormData[index][field] = formattedValue;
    setFormData(updatedFormData);
};

const handleAccountChange = (index, event) => {
  const selectedAccount = event.target.value;
  const intselectedId = parseInt(selectedAccount);
  const selectedInterestRate = parseFloat(bankkk.find((item) => item.id === intselectedId)?.interest) || 0;

  const updatedFormData = [...formData];
  const principalAmount = parseFloat(updatedFormData[index].principalAmount) || 0;
  const duration = parseInt(updatedFormData[index].duration) || 0;

  // Recalculate interestAmount, totalAmount, and repayment based on the new interest rate
  const interestAmount = (principalAmount * selectedInterestRate) / 100;
  const totalAmount = principalAmount + interestAmount;
  const repayment = duration > 0 ? totalAmount / duration : 0;

  updatedFormData[index] = {
    ...updatedFormData[index],
    loanType: selectedAccount,
    interest: selectedInterestRate,
    interestAmount: interestAmount.toFixed(2), // optional: format to 2 decimal places
    totalAmount: totalAmount.toFixed(2), // optional: format to 2 decimal places
    repayment: repayment.toFixed(2), // optional: format to 2 decimal places
  };

  setFormData(updatedFormData);
};


// const handleValueChange = (value) => {
//   setIncomeAmount(value);
// };

const handleValueChange1 = (value, index) => {
  const updatedFormData = [...formData];
  updatedFormData[index] = {
      ...updatedFormData[index],
      principalAmount: value,
  };
  setFormData(updatedFormData);
  // calculateTotalCredit(); 
};


const calculateTotalCredit = () => {
  const total = formData.reduce((acc, item) => acc + parseFloat(item.totalAmount || 0), 0);
  const formattedTotal = total.toFixed(2);
  setTotalCredit(formattedTotal);
};


useEffect(() => {

  calculateTotalCredit();
}, [formData]);



  const fetchSupplierss = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/customer/no-pagination`, { headers });
      const results = response.data?.data;

      const options = results.map((item) => ({
        label: item.name,
        value: item.id,
      }));
      setCustomers(results);
      setSelectOptions(options);
    } catch (error) {
      const errorStatus = error.response?.data?.message;
      console.log(errorStatus);
      setCustomers([]);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchLoans = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/payable-types`, { headers });
      const results = response.data?.data;

      const options1 = results.map((item) => ({
        label: item.description,
        value: item.id,
        // interest: item.interest
      }));
      setLoans(options1);
      // setSelectOptions(options);
    } catch (error) {
      const errorStatus = error.response?.data?.message;
      console.log(errorStatus);
      setCustomers([]);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchCharts = async () => {
    setIsLoading(true);
    try {
        const response = await axios.get(`${BASE_URL}/get-account-by-sub-category-id?sub_category_id=${10}`, { headers });
        const results = response.data?.data;

        const opt1 = results.map((item) => ({
          label: item.gl_name,
          value: item.id,
        }));

        setTableData(opt1);
        
    } catch (error) {
        const errorStatus = error.response?.data?.message;
        console.log(errorStatus);
        setTableData([]);
    } finally {
        setIsLoading(false);
    }
};

useEffect(() => {
  if (bearer) {
      fetchCharts();

  }
}, [bearer]);


  const fetchBanks = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/account/fetch-loans`, { headers });
      const results = response.data?.data;
      setBankkk(results);
      // console.log("results", results)
      const options1 = results.map((item) => ({
        label: item?.description,
        value: item.id,
      }));
      setBanks(options1);
      // console.log(result
      // setSelectOptions(options);
    } catch (error) {
      const errorStatus = error.response?.data?.message;
      console.log(errorStatus);
      setBanks([]);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (bearer) {
      fetchSupplierss();
      fetchLoans();
      fetchBanks();
    }
  }, [bearer]);


  const handleSupplierChange = (selectedOption) => {
    setSelectedCustomer(selectedOption.value);
  }

  const handleLoanTypeChange = (selectedOption) => {
    setSelectedLoan(selectedOption);
    const selectedLoanInterest = selectedOption.interest;
    setLoanInterest(selectedLoanInterest === null ? "0.00" : selectedLoanInterest);
  }

  const handleBankChange = (selectedOption) => {
    setSelectedBank(selectedOption.value);
  }

  const handleCreditChange = (selectedOption) => {
    setSelectedCredit(selectedOption);
  }

  const handleValueChange = (value, name, values) => {
    setPrincipalAmount(value);
  };

  const handleValueChange4 = (value, name, values) => {
    setLoanInterest(value);
  };

  const handleValueChange2 = (value, name, values) => {
    setInterest(value);
  };

  const handleValueChange3 = (value, name, values) => {
    setTotalRepayment(value);
  };

  const handleValueChange9 = (value, name, values) => {
    setMonthlyDeduction(value);
  };

  const handleAccountsChange = (selectedOption) => {
    setSelectedAccount(selectedOption.value);
};



  useEffect(() => {
    const calculateInterestRate = () => {
      const calculatedInterestRate = (parseFloat(loanInterest) / 100) * parseFloat(principalAmount);
      const formattedInterestRate = isNaN(calculatedInterestRate) ? "0.00" : calculatedInterestRate.toFixed(2);

      setInterest(formattedInterestRate);
    };

    calculateInterestRate();
  }, [loanInterest, principalAmount]);


  const handleValueChange22 = (value, index) => {
    const updatedFormData = [...formData];
    updatedFormData[index] = {
        ...updatedFormData[index],
        interestAmount: value,
    };
    setFormData(updatedFormData);
    // calculateTotalCredit(); 
  };


  useEffect(() => {
    const calculateTotalRepayment = () => {
      const calculatedTotalRepayment = parseFloat(principalAmount) + parseFloat(interest);
      const formattedTotalRepayment = isNaN(calculatedTotalRepayment) ? "0.00" : calculatedTotalRepayment.toFixed(2);

      setTotalRepayment(formattedTotalRepayment);
    };

    calculateTotalRepayment();
  }, [interest, principalAmount]);

  useEffect(() => {
    const calculateReturn = () => {
      const calculatedReturn = parseFloat(totalRepayment) / parseFloat(duration);
      const formattedReturn = isNaN(calculatedReturn) ? "0.00" : calculatedReturn.toFixed(2);

      setMonthlyDeduction(formattedReturn);
    };

    calculateReturn();
  }, [totalRepayment, duration]);

  
  const fetchInvoiceCode = async () => {
    setLoading(true);


    try {
      const response = await axios.get(
        `${BASE_URL}/generate-sales-invoice-code`,
        {
          headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearer}`
          }
        }
      );
      const resultss = response.data?.data;
    //   console.log(resultss);
      setSICode(resultss);
        // console.log(invoiceData)
    //   console.log(results, "NI");
    } catch (error) {
      const errorStatus = error.response.data.message;
      console.error(errorStatus);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (bearer) {
        fetchInvoiceCode();
    }
  }, [bearer]);



  const createLoan = async () => {
    setLoanLoading(true);

    try {
        const loanTypes = formData.map((row) => row.loanType).filter((name) => name !== undefined);
        const interests = formData.map((row) => row.interest).filter((name) => name !== undefined);
        const principals = formData.map((row) => row.principalAmount).filter((name) => name !== undefined);
        const interestAmounts = formData.map((row) => row.interestAmount).filter((name) => name !== undefined);
        const totalAmounts = formData.map((row) => row.totalAmount).filter((name) => name !== undefined);
        const monthly = formData.map((row) => row.repayment).filter((name) => name !== undefined);
        const durations = formData.map((row) => row.duration).filter((name) => name !== undefined);
        // const amount1 = incomeAmount.replace(/,/g, '').replace(/\.00$/, '');

        
// return;
    // console.log("params:", selectedAccount, accountNames, amounts, selectedBank, description, principalAmount, selectedDate, sICode, selectedCustomer);
        const response = await axios.post(`${BASE_URL}/account/new-staff-loan`,
            {
                loan_name : loanTypes,
                loan_interest : interests,
                principal_amount: principals,
                interest_amount : interestAmounts,
                total_repayment : totalAmounts,
                monthly_deduction : monthly,
                duration : durations,
                transaction_date: selectedDate,
                member_name: selectedCustomer,
            },
            { headers }
        );

        console.log(response.data, "heeee");
       
        navigate(-1);

        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: response.data.message,
        });
    } catch (error) {
        let errorMessage = 'An error occurred. Please try again.';
        if (error.response && error.response.data && error.response.data.message) {
            if (typeof error.response.data.message === 'string') {
                errorMessage = error.response.data.message;
            } else if (Array.isArray(error.response.data.message)) {
                errorMessage = error.response.data.message.join('; ');
            } else if (typeof error.response.data.message === 'object') {
              // toast.error(errorMessage)
              console.log(errorMessage);
            }
            Swal.fire({
              icon: 'error',
              title: 'Failed',
              text: JSON.stringify(error.response.data.data),
            });
        }
    } finally {
        setLoanLoading(false);
    }
};

// const formGroups = {
//   display: 'grid',
//   gridTemplateColumns: '1fr 2fr',
//   alignItems: 'center'
// };
// const formGroupsLabel = {
//   textAlign: 'right',
//   marginRight: 5,
// };
// const inputs = {
//   width:100,
// };

const handlePrincipalAmountChange = (value, index) => {
  const updatedFormData = [...formData];
  const principalAmount = parseFloat(value) || 0;
  const interestRate = parseFloat(updatedFormData[index].interest) || 0;
  const interestAmount = (principalAmount * interestRate) / 100;
  const totalAmount = principalAmount + interestAmount;

  updatedFormData[index] = {
    ...updatedFormData[index],
    principalAmount,
    interestAmount,
    totalAmount,
  };

  setFormData(updatedFormData);
};

const handleDurationChange = (index, value) => {
  const updatedFormData = [...formData];
  const duration = parseInt(value) || 0;
  const totalAmount = parseFloat(updatedFormData[index].totalAmount) || 0;
  const repayment = duration > 0 ? totalAmount / duration : 0;

  updatedFormData[index] = {
    ...updatedFormData[index],
    duration,
    repayment,
  };

  setFormData(updatedFormData);
};


  return (
    <div>
      <ToastContainer/>
      <LatestCoopDash />
      <div className='newBody'>
        <div className={classes.newWidth}>
          <div className={classes.topPadding}>
            
                <h3 style={{ fontSize: 30, fontWeight: 700, margin: " 10px 10px 0  0" }}>Create New Loan</h3>
             
          </div>
          <div className="wrapper">



            {/* <!-- Page Content  --> */}
            <div className="content-wrapper">



              <div className="main-content">


                <div className="content-header row align-items-center m-0">

                  <div className="col-sm-12 header-title p-0">
                    <div className={classes.actionBtns}>
                      {/* <div className="header-icon text-success mr-3"><i className=""><img src={favicon} style={{ height: 30, width: 30 }} alt="favicon" /></i></div> */}
                      {/* <div className="media-body" style={{ display: 'flex', justifyContent: "space-between", alignItems: "center" }}>
                                                <div>
                                                    <h4 className="font-weight-bold">Create Savings Account </h4>
                                                    <small>Complete the respective fields ....</small>
                                                </div>
                                                <div style={{ marginBottom: 30 }}>
                                                    <Button variant='success' onClick={goBack}><i className="fa-solid fa-arrow-left"></i> Go Back</Button>
                                                </div>
                                            </div> */}

                    </div>

                  </div>
                </div>
              </div>

              <div className="body-content">



                <div className="col-lg-12">
                  <div className="card" style={{ border: 'none' }}>
                    <div className={classes.contentCont} style={{ padding: '20px' }}>
                      {/* <div>
                                                <h5 style={{marginLeft: 20}}>Personal Details</h5>
                                            </div> */}
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="card" style={{ borderLeft: 'none', borderRight: 'none', borderBottom: 'none', borderRadius: '0' }}>
                          <div className="card-body" >
                            <div className="card-body" >

                              {/* <div className={classes.formSec}> */}
                                <div className="row">
                                  <div className="col-md-6">
                                  <div className={classes.formGroups}>
                                      <label for="example-text-input" >Transaction Date:</label>
                                      <div className="col-sm-9" style={{width:'100%'}}>
                                        <input className="form-control" required="" type="date" onChange={handleDateChange} name="date" value={selectedDate}  />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className={classes.formGroups}>
                                      <label for="example-text-input">Member:</label>
                                      <div className="col-sm-9" style={{width:'100%'}}>
                                        <Select
                                          // value={selectedCustomer}
                                          // style={inputs}
                                          className={classes.select}
                                          onChange={(selectedOption) => handleSupplierChange(selectedOption)}
                                          options={selectOptions}
                                          menuPortalTarget={document.body}
                                          styles={{
                                            width:'350px',
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            menu: (provided) => ({
                                              ...provided,
                                              maxHeight: '300px',
                                              overflowY: 'auto',
                                            }),
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div style={{marginTop:8}}/>
                                  {/* <div className="col-md-6">
                                      <div className={classes.formGroups}>
                                      <label for="example-text-input" style={{textAlign: "right"}}>Loan Type</label>
                                      <div style={{width:'100%'}}>
                                      <Select
                                        value={selectedLoan}
                                        onChange={(selectedOption) => handleLoanTypeChange(selectedOption)}
                                        options={loans}
                                        menuPortalTarget={document.body}
                                        styles={{
                                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                          menu: (provided) => ({
                                            ...provided,
                                            maxHeight: '200px',
                                            overflowY: 'auto',
                                          }),
                                        }}
                                      />
                                    </div>
                                      </div>
                                  </div> */}
                                  {/* <div className="col-md-6"> */}
                                    {/* <div className={classes.formGroups}>
                                      <label for="example-text-input" >Receivables Type:</label>
                                      <div className="col-sm-9" style={{width:'100%'}}>
                                        <Select
                                       
                                          onChange={(selectedOption) => handleLoanTypeChange(selectedOption)}
                                          options={loans}
                                          menuPortalTarget={document.body}
                                          styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            menu: (provided) => ({
                                              ...provided,
                                              maxHeight: '200px',
                                              overflowY: 'auto',
                                            }),
                                          }}
                                        />
                                      </div>
                                    </div> */}
                                  {/* </div> */}
                                  {/* <div className="col-md-6">
                                    <div className={classes.formGroups}>
                                    <label for="example-text-input"  style={{textAlign: "right"}}>Principal Amount</label>
                                      <div className="col-sm-9" style={{width:'100%'}}>
                                      
                                        <CurrencyInput
                                        
                                          name="principal amount"
                                          decimalsLimit={2}
                                          className="form-control"
                                          value={principalAmount}
                                          onValueChange={handleValueChange}
                                          style={{ textAlign: "right", border: "1px solid #e4e4e4", backgroundColor: "none",  }}
                                        />
                                      </div>

                                    </div>
                                  </div> */}
                                  {/* <div className="col-md-6">
                                    <div className={classes.formGroups}>
                                      <label for="example-text-input" >% Interest</label>
                                      <div className="col-sm-9" style={{width:'100%'}}>
                                        <CurrencyInput

                                          name="loan interest"
                                          decimalsLimit={2}
                                          className="form-control"
                                          value={loanInterest}
                                          disabled
                                          onValueChange={handleValueChange9}
                                          style={{ textAlign: "right", border: "1px solid #e4e4e4", backgroundColor: "none" }}
                                        />
                                      </div>
                                    </div>
                                  </div> */}
                                  {/* <div className="col-md-6">
                                    <div className={classes.formGroups}>
                                      <label for="example-text-input" >Interest</label>
                                      <div className="col-sm-9" style={{width:'100%'}}>
                                      <CurrencyInput

name="loan interest"
disabled
decimalsLimit={2}
className="form-control"
value={interest} 
onValueChange={handleValueChange2}
style={{ textAlign: "right", border: "1px solid #e4e4e4", backgroundColor: "none" }}
/>
                                      </div>
                                    </div>
                                  </div> */}
                                  <div style={{marginTop:8}}/>
                                  {/* <div className="col-md-6"> */}
                                    {/* <div className={classes.formGroups}>
                                      <label for="example-text-input" >Total Repayment</label>
                                      <div className="col-sm-9" style={{width:'100%'}}>
                                      <CurrencyInput

name="loan interest"
disabled
decimalsLimit={2}
className="form-control"
value={totalRepayment} 
onValueChange={handleValueChange3}
style={{ textAlign: "right", border: "1px solid #e4e4e4", backgroundColor: "none" }}
/>
                                      </div>
                                    </div> */}
                                  {/* </div> */}
                                  {/* <div className="col-md-6">
                                    <div className={classes.formGroups}>
                                      <label for="example-text-input" >Duration (Months)</label>
                                      <div className="col-sm-9" style={{width:'100%'}}>
                                      <input className="form-control" required="" type="text" onChange={(e) => setDuration(e.target.value)} name="duration" value={duration} />
                                      </div>
                                    </div>
                                  </div> */}
                                  {/* <div className="col-md-6">
                                    <div className={classes.formGroups}>
                                      <label for="example-text-input" >Monthly Deduction</label>
                                      <div className="col-sm-9" style={{width:'100%'}}>
                                      <CurrencyInput

name="loan interest"
disabled
decimalsLimit={2}
className="form-control"
value={monthlyDeduction} 
onValueChange={handleValueChange4}
style={{ textAlign: "right", border: "1px solid #e4e4e4", backgroundColor: "none" }}
/>
                                      </div>
                                    </div>
                                  </div> */}
                                  {/* <div className="col-md-6">
                                    <div className={classes.formGroups}>
                                    <label for="example-text-input" style={{textAlign: "right"}}>Loan Interest</label>
                                    <div style={{width:'100%'}}>
                                      
                                      <CurrencyInput

                                        name="loan interest"
                                        decimalsLimit={2}
                                        className="form-control"
                                        value={loanInterest} 
                                        disabled
                                        onValueChange={handleValueChange1}
                                        style={{ textAlign: "right", border: "1px solid #e4e4e4", backgroundColor: "none" }}
                                      />
                                     

                                    </div>
                                    </div>
                                  </div> */}
                                  {/* <div className="col-md-6">
                            <div className={classes.formGroups}>
                            <label for="example-text-input" style={{textAlign: "right"}}>Interest</label>
                                    <div style={{width:'100%'}}>
                                      
                                      <CurrencyInput

                                        name="loan interest"
                                        disabled
                                        decimalsLimit={2}
                                        className="form-control"
                                        value={interest} 
                                        onValueChange={handleValueChange2}
                                        style={{ textAlign: "right", border: "1px solid #e4e4e4", backgroundColor: "none" }}
                                      />
                                     

                                    </div>
                            </div>
                        </div> */}
                                  {/* <div className="col-md-6">
                            <div className={classes.formGroups}>
                            <label for="example-text-input" style={{textAlign: "right"}}>Total Repayment</label>
                                    <div style={{width:'100%'}}>
                                      
                                      <CurrencyInput

                                        name="loan interest"
                                        disabled
                                        decimalsLimit={2}
                                        className="form-control"
                                        value={totalRepayment} 
                                        onValueChange={handleValueChange3}
                                        style={{ textAlign: "right", border: "1px solid #e4e4e4", backgroundColor: "none" }}
                                      />
                                     

                                    </div>
                            </div>
                        </div> */}
                                  {/* <div className="col-md-6">
                            <div className={classes.formGroups}>
                            <label for="example-text-input" style={{textAlign: "right"}}>Duration (Months)</label>
                                    <div style={{width:'100%'}}>
                                      <input className="form-control" required="" type="text" onChange={(e) => setDuration(e.target.value)} name="duration" value={duration} />
                                    </div>
                            </div>
                        </div> */}
                                  {/* <div className="col-md-6">
                            <div className={classes.formGroups}>
                            <label for="example-text-input" style={{textAlign: "right"}} >Monthly Deduction</label>
                            <div style={{width:'100%'}}>
                                      
                                      <CurrencyInput

                                        name="loan interest"
                                        disabled
                                        decimalsLimit={2}
                                        className="form-control"
                                        value={monthlyDeduction} 
                                        onValueChange={handleValueChange4}
                                        style={{ textAlign: "right", border: "1px solid #e4e4e4", backgroundColor: "none" }}
                                      />
                                     

                                    </div>

                            </div>
                        </div> */}

                        
                              
                                  {/* <div className="col-md-6">
                                    <div className="form-group row">
                                      <label for="example-text-input" className="col-sm-3 col-form-label font-weight-400">Cheque No.</label>
                                      <div className="col-sm-9">
                                      <input className="form-control" required="" type="text" onChange={(e) => setCheque(e.target.value)} name="cheque no" value={cheque} />
                                      </div>
                                    </div>
                                  </div> */}
                                </div>

                              {/* </div> */} <div style={{marginTop: 20}}/>
                    {/* <h5 style={{textAlign: "center"}}>Add Bank Accounts</h5> */}

                    <div className="row">
                            {/* <div className="col-md-6"> */}
                            <div className="table-responsive">
                                <table className="table display table-bordered table-striped table-hover bg-white m-0 card-table">

                                    <thead style={{ whiteSpace: "nowrap", textAlign: "center", alignItems: "center" }}>
                                        <tr>
                                            {/* <th>#</th> */}
                                            <th style={{width: 70, whiteSpace: "wrap"}}>Loan Type</th>
                                            <th style={{width: 70, whiteSpace: "wrap"}}>Interest</th>
                                            <th style={{width: 70, whiteSpace: "wrap"}}>Principal Amount</th>
                                            <th style={{width: 70, whiteSpace: "wrap"}}>Duration (Months)</th>
                                            <th style={{width: 70, whiteSpace: "wrap"}}>Interest Amount</th>
                                            <th style={{width: 70, whiteSpace: "wrap"}}>Monthly Repayment</th>
                                            <th style={{width: 70, whiteSpace: "wrap"}}>Total Amount</th>
                                            <th ><Button style={{borderRadius: 90}} variant="primary" onClick={() => addRow()}>
                                                <i className="fas fa-plus"></i>

                                            </Button></th>
                                        </tr>
                                    </thead>
                                    <tbody style={{ whiteSpace: "nowrap", textAlign: "center", alignItems: "center" }}>
                                    {formData.map((row, index) => (
  <tr key={index}>
    <td style={{ width: 300 }}>
      <Form.Select
        className="form-control"
        value={row.loanType}
        onChange={(e) => handleAccountChange(index, e)}
      >
        <option value="">Select Account</option>
        {bankkk.map((item) => (
          <option key={item.id} value={item.id}>
            {item.description}
          </option>
        ))}
      </Form.Select>
    </td>
    <td>
      <input
        type="text"
        className="form-control"
        value={row.interest}
        readOnly
        disabled
        style={{ width: 65 }}
      />
    </td>
    <td>
      <CurrencyInput
        name={`rowAmount ${index}`} // Provide a unique name for each CurrencyInput
        value={row.principalAmount}
        className="form-control"
        onValueChange={(value) => handlePrincipalAmountChange(value, index)}
        style={{ textAlign: "right", border: "none", width: 130 }}
      />
    </td>
    <td>
      <input
        type="text"
        className="form-control"
        value={row.duration}
        onChange={(e) => handleDurationChange(index, e.target.value)}
        style={{ textAlign: "left", width: 65 }}
      />
    </td>
    <td>
      <CurrencyInput
        name={`rowInterestAmount ${index}`} // Provide a unique name for each CurrencyInput
        value={row.interestAmount}
        className="form-control"
        disabled
        style={{ textAlign: "right", border: "none", width: 130 }}
      />
    </td>
    <td>
      <CurrencyInput
        name={`rowMonthlyRepayment ${index}`} // Provide a unique name for each CurrencyInput
        value={row.repayment}
        disabled
        className="form-control"
        style={{ textAlign: "right", border: "none", width: 130 }}
      />
    </td>
    <td>
      <CurrencyInput
        name={`rowTotalAmount ${index}`} // Provide a unique name for each CurrencyInput
        value={row.totalAmount}
        disabled
        className="form-control"
        style={{ textAlign: "right", border: "none", width: 130 }}
      />
    </td>
   
    <td>
      <Button style={{borderRadius: 90}} variant="danger" onClick={() => deleteRow(index)}>
        <i className="far fa-trash-alt"></i>
      </Button>
    </td>
  </tr>
))}

                                    </tbody>
                                </table>
                                <div style={{ marginTop: 30 }} />
                                <div style={{display:'flex', justifyContent:'flex-end', alignItems:"center", gap:'20px'}}>
                                    {/* <Form.Group className="mb-3">
                                        <Form.Label>Total Debit:</Form.Label>
                                        <CurrencyInput
                                            //   
                                            name="amount"
                                            decimalsLimit={2}
                                            className="form-control"
                                            value={incomeAmount} // Set the value to the balance state
                                            disabled
                                            style={{ textAlign: "right", border: "1px solid #e4e4e4", backgroundColor: "none"}}
                                        />
                                    </Form.Group> */}
                                    <Form.Group className="mb-3">
                                        <Form.Label>Total Amount:</Form.Label>
                                        <CurrencyInput
                                            //   
                                            name="amount"
                                            decimalsLimit={2}
                                            className="form-control"
                                            value={totalCredit} // Set the value to the balance state
                                            disabled
                                            style={{ textAlign: "right", border: "1px solid #e4e4e4", backgroundColor: "none"}}
                                        />
                                    </Form.Group>
                                </div>

                            </div>
                        </div>

                              <div style={{ marginTop: 20 }} />
                              <div className={`${classes.formIntBtn} ${classes.formIntBtn2}`}>
                                <Button variant="light" className={classes.btn1} onClick={goBack} style={{marginRight:'5px'}}> Cancel</Button>
                                <Button  style={{ borderRadius: 5 }} variant="success" onClick={createLoan}>
                                {loanLoading ? (
                                    <>
                                      <Spinner size='sm' />
                                      <span style={{ marginLeft: '5px' }}>Disbursing, Please wait...</span>
                                    </>
                                  ) : (
                                    "Create New Loan"
                                  )}
                                </Button>

                              </div>



                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      {/* <InfoFooter /> */}
    </div>
  )
}

export default DisburseLoan;