import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button, Modal, Form, Spinner } from 'react-bootstrap';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Swal from 'sweetalert2';
import classes from './Requisition.module.css';
import { BASE_URL } from '../../api/api';
import { toast } from 'react-toastify';
import LatestInventoryDash from '../LatestInventoryDash';

function ViewRequisition() {
    const [user, setUser] = useState("");
    const [loading, setLoading] = useState(false);
    const [updateLoading, setUpdateLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [tableData1, setTableData1] = useState([]);
    const [createLoading, setCreateLoading] = useState(false);
    const [totalQuantity, setTotalQuantity] = useState(0);
    const [buttonShow, setButtonShow] = useState(false);
    const [description, setDescription] = useState(null);
    const [releasedLoading, setReleaseLoading] = useState(false);
    const [bearer, setBearer] = useState('');
    
    const navigate = useNavigate();
    const location = useLocation();
    const { selectedRequisition } = location.state || {};  // Add fallback to avoid undefined errors
    const [requestId, setRequestId] = useState(null);  // Initialize as null

    useEffect(() => {
        if (selectedRequisition && selectedRequisition.length > 0) {
            setRequestId(selectedRequisition[0].request_id);  // Set request ID safely
        }
    }, [selectedRequisition]);

    // Define stocksArray, reasons, and quantityArray safely
    const stocksArray = selectedRequisition ? selectedRequisition.map(item => item.stocks) : [];
    const reasons = selectedRequisition ? selectedRequisition.map(item => item.description.length > 0 ? item.description[0].description : null) : [];
    const quantityArray = selectedRequisition ? selectedRequisition.map(item => item.quantity) : [];

    // Safely create formData
    const formData = stocksArray.map((item, index) => ({
        itemsDescription: item?.name || '',
        qty: quantityArray[index] || 0
    }));

    // Calculate button visibility based on approval status
    useEffect(() => {
        if (selectedRequisition && selectedRequisition.length > 0) {
            const approvalStatus = selectedRequisition[0].approval_status;
            setButtonShow(approvalStatus !== "0" && approvalStatus !== "2");
        }
    }, [selectedRequisition]);

    // Calculate total quantity
    useEffect(() => {
        const calcTotQty = () => {
            if (selectedRequisition) {
                const quantities = selectedRequisition.map(item => item.quantity);
                const totalQuant = quantities.reduce((total, qty) => total + parseFloat(qty) || 0, 0);
                setTotalQuantity(totalQuant.toString());
            } else {
                setTotalQuantity('');
            }
        };
        calcTotQty();
    }, [selectedRequisition]);

    // Fetch token and set headers
    const readData = async () => {
        try {
            const value = await AsyncStorage.getItem('userToken');
            const value1 = await AsyncStorage.getItem('tobi');
            if (value) setBearer(value);
            if (value1) setUser(value1);
        } catch (e) {
            alert('Failed to fetch the input from storage');
        }
    };

    useEffect(() => {
        readData();
    }, []);

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${bearer}`
    };

    const goBack = () => navigate(-1);

    const fetchItems = async () => {
        setIsLoading(true); 
        try {
            const response = await axios.get(`${BASE_URL}/items/fetch-all`, { headers });
            const items = response?.data?.data || [];
            setTableData1(items);
        } catch (error) {
            const errorStatus = error.response?.data?.message;
            console.log(errorStatus);
            setTableData1([]);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (bearer) fetchItems();
    }, [bearer]);

    // Calculate total quantity dynamically based on formData
    const calculateTotalQuantity = () => {
        const total = formData.reduce((total, row) => total + parseFloat(row.qty) || 0, 0);
        setTotalQuantity(total.toString());
    };

    useEffect(() => {
        calculateTotalQuantity();
    }, [formData]);

    const ApproveRequest = async () => {
        setCreateLoading(true);
        try {
            const response = await axios.get(`${BASE_URL}/stocks/approve-requisition?request_id=${requestId}`, { headers });
            toast.success(response.data.message);
            navigate(-1);
        } catch (error) {
            const errorMessage = error.response?.data?.message || 'An error occurred. Please try again.';
            toast.error(errorMessage);
        } finally {
            setCreateLoading(false);
        }
    };

    const handleDisApprove = async () => {
        setIsLoading(true);
        try {
            const response = await axios.get(`${BASE_URL}/stocks/disapprove-requisition?request_id=${requestId}&description=${description}`, { headers });
            toast.success(response.data.message);
            navigate(-1);
        } catch (error) {
            const errorMessage = error.response?.data?.message || 'An error occurred. Please try again.';
            toast.error(errorMessage);
        } finally {
            setIsLoading(false);
        }
    };

    const releaseRequistion = async () => {
        setReleaseLoading(true);
        try {
            const response = await axios.post(`${BASE_URL}/stocks/release-requisition`, { request_id: requestId }, { headers });
            toast.success(response.data.message);
            navigate(-1);
        } catch (error) {
            const errorMessage = error.response?.data?.message || 'An error occurred. Please try again.';
            toast.error(errorMessage);
        } finally {
            setReleaseLoading(false);
        }
    };

    return (
        <div>
            <div className="wrapper">
                <div className="content-wrapper">
                    <div className="main-content">
                        <LatestInventoryDash />
                        <div className='newBody'>
                            <div className={classes.newWidsdth}>
                                <div className={classes.topPadding}>
                                    <div className={`${classes.formSecCont}`}>
                                        <div className={classes.formSectionHeader}>
                                            <h3>Create Requisition</h3>
                                        </div>
                                      
                                    </div>
                                </div>
                                <div className={classes.topPadding}>
                                    <div className={`${classes.formSecCont}`}>
                                        <div className="card-body" style={{border:'none'}}>
                                            <Modal show={show} onHide={() => setShow(false)} animation={false}>
                                                <Modal.Header closeButton>
                                                    <Modal.Title>Reason for Disapproving</Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    <Form>
                                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                            <Form.Label>Description</Form.Label>
                                                            <textarea
                                                                className="form-control"
                                                                rows="3"
                                                                cols="50"
                                                                placeholder="Enter reason here..."
                                                                value={description}
                                                                onChange={(e) => setDescription(e.target.value)}
                                                            />
                                                        </Form.Group>
                                                    </Form>
                                                </Modal.Body>
                                                <Modal.Footer>
                                                    <Button variant="danger" onClick={() => setShow(false)}>
                                                        Cancel
                                                    </Button>
                                                    <Button variant="success" onClick={handleDisApprove}>
                                                        {isLoading ? (
                                                            <>
                                                                <Spinner size='sm' />
                                                                <span style={{ marginLeft: '5px' }}>Processing, Please wait...</span>
                                                            </>
                                                        ) : (
                                                            "Disapprove Requisition"
                                                        )}
                                                    </Button>
                                                </Modal.Footer>
                                            </Modal>
                                            <div className="row">
                                                <div className="table-responsive">
                                                    <table className="table display table-bordered table-striped table-hover bg-white m-0 card-table">
                                                        <thead style={{ whiteSpace: "nowrap", textAlign: "center", alignItems: "center" }}>
                                                            <tr>
                                                                <th>Items Description</th>
                                                                <th style={{width:'40%',}}>Quantity</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody style={{ whiteSpace: "nowrap", textAlign: "center", alignItems: "center" }}>
                                                            {formData.map((row, index) => (
                                                                <tr key={index}>
                                                                    <td>
                                                                        <input
                                                                            style={{ textAlign: "left" }}
                                                                            className="form-control"
                                                                            type="text"
                                                                            value={row.itemsDescription}
                                                                            onChange={() => {}}  // Add functionality if needed
                                                                            disabled
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        <input
                                                                            style={{ textAlign: "right" }}
                                                                            className="form-control"
                                                                            type="number"
                                                                            value={row.qty}
                                                                            onChange={() => {}}  // Add functionality if needed
                                                                            disabled
                                                                        />
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6"></div>
                                                <div className="col-md-6">
                                                    <div className={classes.totalAm}>
                                                        <h3>Total Quantity: <span>{totalQuantity}</span></h3>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='d-flex justify-content-end mt-4'>
                                                <Button onClick={releaseRequistion}>
                                                    {releasedLoading ? (
                                                        <>
                                                            <Spinner size='sm' />
                                                            <span style={{ marginLeft: '5px' }}>Releasing...</span>
                                                        </>
                                                    ) : (
                                                        'Release Requisition'
                                                    )}
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex justify-content-start mt-4'>
                                <Button onClick={goBack}>Back</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ViewRequisition;
