import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Navbar, Nav, NavDropdown, Button, Modal, Form, Spinner, Badge } from 'react-bootstrap';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Swal from 'sweetalert2';
import classes from './LoanLedger.module.css';
import { BASE_URL } from '../../api/api';
import { toast } from 'react-toastify';
import CurrencyInput from 'react-currency-input-field';
import Select from 'react-select';
import Arrow from '../../../assets/promix/dArrow-down.svg'
import CoopDashboard from '../../Cooperative Dashboard/CoopDashboard';
import LatestCoopDash from '../../LatestDashboard/LatestCoopDash';



function LoanLedger() {
  const [entriesPerPage, setEntriesPerPage] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [load, setLoad] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [ledgerLoading, setLedgerLoading] = useState(false);
  const [savingsLoading, setSavingsLoading] = useState(false);
  const [bearer, setBearer] = useState('');
  const navigate = useNavigate();
  const [paidBooking, setPaidBooking] = useState([]);
  const [savingss, setSavingss] = useState([]);
  const [user, setUser] = useState("");
  const [selectOptions, setSelectOptions] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState('');
  const [selectedCustomerName, setSelectedCustomerName] = useState([]);
  const [selectedSavingsType, setSelectedSavingsType] = useState([]);
  const [selectedSavingsName, setSelectedSavingsName] = useState('');


  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem('userToken');
      const value2 = await AsyncStorage.getItem('companyId');
      const value1 = await AsyncStorage.getItem('tobi');

      if (value !== null) {
        setBearer(value);
      }
      if (value1 !== null) {
        setUser(value1);
      }

    } catch (e) {
      alert('Failed to fetch the input from storage');
    }
  };

  useEffect(() => {
    readData();
  }, []);

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${bearer}`
  };

  const goBack = () => {
    navigate(-1);
  }

  const filteredData = paidBooking.filter(item => item.description.toLowerCase().includes(searchTerm.toLowerCase()));

  const totalPages = Math.ceil(filteredData.length / entriesPerPage);

  const handlePrevPage = () => {
    setCurrentPage(Math.max(currentPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage(Math.min(currentPage + 1, totalPages));
  };



  const totalEntries = filteredData.length;
  const startIndexx = (currentPage - 1) * entriesPerPage + 1;
  const endIndexx = Math.min(startIndexx + entriesPerPage - 1, totalEntries);
  const displayedData = filteredData.slice(startIndexx - 1, endIndexx);




  const fetchPaidBookings = async () => {
    setLedgerLoading(true);

    try {
      const response = await axios.get(
        `${BASE_URL}/account/fetch-loan-transactions?member_id=${selectedCustomer}&account_id=${selectedSavingsType}`,
        // `${BASE_URL}/account/fetch-member-ledger-by-account?member_id=${selectedCustomer}&account_id=${selectedSavingsType}`,
        
        {

          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearer}`
          }
        }
      );
      const resultssx = response.data?.data;
      setPaidBooking(resultssx);

      console.log(resultssx, "NI");
    } catch (error) {
      const errorStatus = error.response.data.message;
      console.error(errorStatus);
    } finally {
      setLedgerLoading(false);
    }
  };













  const fetchSupplierss = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get('https://api-sme.promixaccounting.com/api/v1/customer/no-pagination', { headers });
      const results = response.data?.data;

      const options = results.map((item) => ({
        label: item.name,
        value: item.id,
      }));
      setCustomers(results);
      setSelectOptions(options);
    } catch (error) {
      const errorStatus = error.response?.data?.message;
      console.log(errorStatus);
      setCustomers([]);
    } finally {
      setIsLoading(false);
    }
  };



  const handleSupplierChange = (selectedOption) => {
    setSelectedCustomer(selectedOption.value);
    setSelectedCustomerName(selectedOption);
    setSelectedSavingsType(null);
    fetchCustomerSavings(selectedOption.value);
    setPaidBooking([]);
  };



  const fetchCustomerSavings = async (selectedCustomer) => {

    setSavingsLoading(true);
    // console.log()
    try {
      const response = await axios.get(
        `${BASE_URL}/account/fetch-member-loans?member_id=${selectedCustomer}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${bearer}`,
          },
        }
      );

      const loanss = response.data?.data || [];
      console.log(loanss, "meeeee");
      const savingTypes = loanss.map(item => item.loan);




      setSavingss(savingTypes);



    } catch (error) {
      const errorStatus = error.response.data.message;
      console.error(errorStatus);
    } finally {
      setSavingsLoading(false);
    }
  };

  useEffect(() => {
    if (bearer) {
      fetchSupplierss();
    }
  }, [bearer]);

  const cusSavings = savingss.map((item) => ({
    label: item?.description,
    value: item?.id,
  }));

  const handleSavingsChange = (selectedOption) => {
    setSelectedSavingsType(selectedOption.value);
    setSelectedSavingsName(selectedOption);
  }





  return (

    <div>

      <div className="wrapper">
        {/* <!-- Sidebar  --> */}


        {/* <!-- Page Content  --> */}
        <div className="content-wrapper">
          <div className="main-content">

            <LatestCoopDash />
            <div className={classes.finishedbodyCont} >
              <div className={classes.newWidth}>

              <div style={{display: "flex", flexDirection: 'column'}}>
              <h3 style={{ fontSize: 30, fontWeight: 700, margin: " 10px 10px 0  0" }}>Individual Loan Ledger</h3>
              <p style={{ margin: "0 10px 20px 0", whiteSpace: "nowrap" }}>View Individual's loan ledger</p>
         </div>



                <div className={classes.topPadding}>
                  <div className={`${classes.formSecCont}`}>
                    <div className="card-body" style={{ border: 'none' }}>


                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label for="example-text-input" className="col-sm-3 col-form-label font-weight-400">Member:</label>
                            <div className="col-sm-9">
                              <Select
                                onChange={(selectedOption) => handleSupplierChange(selectedOption)}
                                options={selectOptions}
                                menuPortalTarget={document.body}
                                styles={{
                                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                  menu: (provided) => ({
                                    ...provided,
                                    maxHeight: '300px',
                                    overflowY: 'auto',
                                  }),
                                }}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group row">
                            <label for="example-text-input" className="col-sm-3 col-form-label font-weight-400">Loans:</label>
                            <div className="col-sm-9">
                              <Select

                                onChange={handleSavingsChange}
                                options={cusSavings}
                                menuPortalTarget={document.body}
                                styles={{
                                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                  menu: (provided) => ({
                                    ...provided,
                                    maxHeight: '200px',
                                    overflowY: 'auto',
                                  }),
                                }}
                              />
                            </div>
                          </div>
                        </div>


















                      </div>

                      <div style={{ marginTop: 20 }} />


                      <div class="modal-footer" style={{ display: 'flex', justifyContent: 'flex-start' }}>
                        <Button
                          disabled={!selectedSavingsType}
                          style={{ borderRadius: 0 }} variant='success' onClick={fetchPaidBookings}>
                          {ledgerLoading ? (
                            <>
                              <Spinner size='sm' />
                              <span style={{ marginLeft: '5px' }}>Processing, Please wait...</span>
                            </>
                          ) : (
                            "Process"
                          )}
                        </Button>

                      </div>

                    </div>
                  </div>
                </div>












                <div style={{ backgroundColor: 'white', padding: '10px 20px' }}>
                  {/* <!--Content Header (Page header)--> */}
                  <div className="content-header row align-items-center m-0">
                    {/* {(isAdmin || permittedHeaders.includes('create-savings-account')) && ( */}
                    {/* <nav aria-label="breadcrumb" className="col-sm-4 order-sm-last mb-3 mb-sm-0 p-0 ">
                  <div
                    style={{
                      marginTop: 20,
                      marginBottom: 20,
                      justifyContent: "flex-end",
                      display: "flex",
                      marginLeft: "auto",
                    }}
                    className={classes.actionBtns}
                  >
                    <Button variant="success" onClick={handleCreate}>
                      Create New Accounts
                    </Button>
                  </div>

                </nav> */}
                    {/* )} */}

                    <div className="col-sm-8 header-title p-0">
                      <div className="media">
                        {/* <div className="header-icon text-success mr-3"><i className=""><img src={favicon} className={classes.favshi} alt="favicon" /></i></div> */}
                        <div className="media-body">
                          {/* <h4 className="font-weight-bold">Savings Account</h4> */}
                          {/* <small>Create and view your Savings Accounts...</small> */}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <!--/.Content Header (Page header)--> */}
                  <div className="body-content">
                    <div className="row">

                      <div className="col-lg-12 col-xl-6">
                        <div className="row">

                          <div className="col-md-6 col-lg-6">

                            {/* <!--Feedback--> */}

                          </div>
                          <div className="col-md-6 col-lg-6">

                            {/* <!--Balance indicator--> */}

                          </div>
                          <div className="col-md-6 col-lg-6">

                            {/* <!--Time on site indicator--> */}

                          </div>
                          <div className="col-md-6 col-lg-6">

                            {/* <!--Top Referrals--> */}

                          </div>
                          <div className="col-md-6 col-lg-6">

                            {/* <!--Sessions by device--> */}

                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      {/* <div className="col-md-12 col-lg-12 col-xl-3 mb-4">
                    <div className="card">


                    </div>
                  </div> */}




                      <div>
                        {paidBooking.length > 0 && (
                          <button onClick={() => navigate('/cooperative/report_ledger', { state: { document: paidBooking, customer: selectedCustomerName, saving: selectedSavingsName, type: "LOANS" } })} style={{ height: 30, width: 130, borderRadius: 0, backgroundColor: "#198754", color: "#fff", marginBottom: 10, marginTop: 10 }}>Print Report</button>
                        )}
                      </div>

                      <div className="col-lg-12">
                        <div className="card">
                          <div className="card-body">
                            <div className="table-resposive">
                              <div className={`d-flex justify-content-between align-items-center ${classes.btnStyle}`}>
                                <div className={`${classes.greenbtn} ${classes.btndivStyle}`}>
                                  <div className={classes.actionsBtns}>
                                    <button>Copy</button>
                                    <button>Excel</button>
                                    <button>PDF</button>
                                    <button className={classes.diffbtn}>Column visibility</button>
                                  </div>
                                  <div className={classes.show}>
                                    <label className="d-flex justify-content-start align-items-center">
                                      Show
                                      <select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" className="custom-select custom-select-sm form-control form-control-sm" value={entriesPerPage}
                                        onChange={(e) => {
                                          setEntriesPerPage(parseInt(e.target.value));
                                          setCurrentPage(1);
                                        }}>
                                        <option value={10}>10</option>
                                        <option value={25}>25</option>
                                        <option value={50}>50</option>
                                        <option value={100}>100</option>
                                      </select>
                                      entries
                                    </label>
                                  </div>
                                </div>
                                <div className="text-right modal-effect ">
                                  <div id="DataTables_Table_0_filter" className="dataTables_filter">
                                    <div className="d-flex justify-content-start align-items-center">
                                      <div className="mr-2">Search:</div>
                                      <input
                                        type="search"
                                        value={searchTerm}
                                        className="form-control form-control-sm"
                                        placeholder=""
                                        aria-controls="DataTables_Table_0"
                                        onChange={(e) => {
                                          setSearchTerm(e.target.value);
                                          // setCurrentPage(1);
                                        }}
                                      />
                                    </div>

                                  </div>
                                </div>
                              </div>


                              {loading ? (
                                <p>Fetching ledger...</p>
                              ) : (
                                <div className="table-responsive">
                                  <table className="table display table-bordered table-striped table-hover bg-white m-0 card-table">

                                    <thead style={{ whiteSpace: 'nowrap' }}>
                                      <tr>
                                        <th>S/N</th>
                                        <th>Transaction Date</th>
                                        <th>Description</th>
                                        <th>Debit</th>
                                        <th>Credit</th>
                                        <th>Balance</th>
                                      </tr>
                                    </thead>
                                    <tbody style={{ whiteSpace: 'nowrap' }}>
                                      {displayedData.length > 0 ? (
                                        displayedData.map((item, index) => (
                                          <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{item?.loandata?.transaction_date}</td>
                                            <td>{item?.description}</td>
                                            <td style={{ textAlign: "right" }}>{parseFloat(item.debit).toLocaleString('en-US', {
                                              minimumIntegerDigits: 1,
                                              minimumFractionDigits: 2,
                                              maximumFractionDigits: 2
                                            })}
                                            </td>
                                            <td style={{ textAlign: "right" }}>{parseFloat(item.credit).toLocaleString('en-US', {
                                              minimumIntegerDigits: 1,
                                              minimumFractionDigits: 2,
                                              maximumFractionDigits: 2
                                            })}
                                            </td>
                                            <td style={{ textAlign: "right" }}>{parseFloat(item.balance).toLocaleString('en-US', {
                                              minimumIntegerDigits: 1,
                                              minimumFractionDigits: 2,
                                              maximumFractionDigits: 2
                                            })}
                                            </td>


                                          </tr>
                                        ))
                                      ) : (
                                        <tr>
                                          <td colSpan="6" style={{ textAlign: 'center' }}>No data available</td>
                                        </tr>
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              )}
                              <div className={classes.endded}>
                                <p>
                                  Showing {startIndexx} to {endIndexx} of {totalEntries} entries
                                </p>
                                <div style={{ display: 'flex' }}>
                                  <button
                                    style={{ border: 'none', backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'nunito', color: '#000', marginRight: 10, cursor: "pointer" }}
                                    onClick={handlePrevPage}
                                    disabled={currentPage === 1}
                                  >
                                    Previous
                                  </button>
                                  {[...Array(totalPages)].map((_, page) => {
                                    // Show only 5 pages or less if available
                                    if (page < 5 || page === currentPage - 1 || page === totalPages - 1) {
                                      return (
                                        <button
                                          key={page + 1}
                                          style={{
                                            marginLeft: '0.4rem',
                                            marginRight: '0.4rem',
                                            fontSize: '14px',
                                            fontFamily: 'nunito',
                                            fontWeight: 400,
                                            color: page + 1 === currentPage ? '#ffffff' : '#000',
                                            backgroundColor: page + 1 === currentPage ? '#28a745' : 'gainsboro',
                                            height: '2.5rem',
                                            borderRadius: '89px',
                                            padding: '0.5rem',
                                            border: 'none',
                                            width: '40px',
                                            cursor: "pointer"
                                          }}
                                          onClick={() => setCurrentPage(page + 1)}
                                        >
                                          {page + 1}
                                        </button>
                                      );
                                    }
                                    return null;
                                  })}
                                  <button
                                    style={{ cursor: "pointer", border: 'none', backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'nunito', color: '#000', marginLeft: 10 }}
                                    onClick={handleNextPage}
                                    disabled={currentPage === totalPages}
                                  >
                                    Next
                                  </button>
                                </div>
                              </div>



                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <!--/.body content--> */}
                </div>
              </div>
              {/* <!--/.main content--> */}
            </div>
          </div>
          {/* <InfoFooter /> */}
          {/* <!--/.footer content--> */}
          <div className="overlay"></div>
        </div>
        {/* <!--/.wrapper--> */}


      </div>
    </div>

  );
}

export default LoanLedger;