import React, { useState, useEffect } from "react";
import classes from "./SetupChartAcct.module.css";
import SideImg from "../../assets/TeamMemberImg/TeamMemberSideImg.svg";
import TopImage from "./TopImage.svg";
import Bullets from "./Bullets.svg";
import Profilessss from "./Profile.svg";
import Fullbullets from "./chartlink.svg";
import Backarrow from "./Arrwbck.svg";
import { Button, ProgressBar, Form, Spinner } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { BASE_URL } from "../api/api";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Select from 'react-select'
import Back from './Back.svg'


export default function SetupChartAcct() {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [permissions, setPermissions] = useState([]);
  const [toggleStates, setToggleStates] = useState({});
  const [permId, setPermId] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [bearer, setBearer] = useState("");
  const [user, setUser] = useState("");
  const navigate = useNavigate();

  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem("userToken");
      const value1 = await AsyncStorage.getItem("tobi");

      if (value !== null) {
        setBearer(value);
      }
      if (value1 !== null) {
        setUser(value1);
      }
    } catch (e) {
      alert("Failed to fetch the input from storage");
    }
  };

  useEffect(() => {
    readData();
  }, []);

  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${bearer}`,
  };

  const fetchPermission = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/role/permissions`, {
        headers,
      });
      const data = response.data?.data;
      const permissionId = data.map((item) => item.id);
      setPermId(permissionId);
      setPermissions(data);
      // Initialize toggleStates with fetched permissions
      const initialToggleStates = Object.fromEntries(
        permissionId.map((id) => [id, false])
      );
      setToggleStates(initialToggleStates);
    } catch (error) {
      const errorStatus = error.response?.data?.message;
      console.error(errorStatus);
      setPermissions([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (bearer) {
      fetchPermission();
    }
  }, [bearer]);

  const groupPermissions = (permissions, itemsPerRow) => {
    let groupedPermissions = [];
    for (let i = 0; i < permissions.length; i += itemsPerRow) {
      groupedPermissions.push(permissions.slice(i, i + itemsPerRow));
    }
    return groupedPermissions;
  };

  const groupedPermissions = groupPermissions(permissions, 5);

  const handleSelectAll = () => {
    const checkAllValue = !selectAll;
    setSelectAll(checkAllValue);

    // Set all toggle states to the determined value
    const updatedToggleStates = Object.fromEntries(
      permId.map((id) => [id, checkAllValue])
    );
    setToggleStates(updatedToggleStates);
  };

  const handleIndividualSelect = (itemId) => {
    setToggleStates((prevToggleStates) => {
      const newToggleState = {
        ...prevToggleStates,
        [itemId]: !prevToggleStates[itemId],
      };
      const allSelected = Object.values(newToggleState).every((value) => value);
      setSelectAll(allSelected);
      return newToggleState;
    });
  };

  const addTeam = async () => {
    setAddLoading(true);
    try {
      const selectedToggle = permissions
        .filter((item) => toggleStates[item.id])
        .map((item) => item.name);

      console.log(fullName, email, phone, selectedToggle);

      const response = await axios.post(
        `${BASE_URL}/users/create-with-module`,
        {
          name: fullName,
          email: email,
          phone_no: phone,
          module: selectedToggle,
        },
        { headers }
      );
      console.log(response);

      navigate(-1);
      setFullName("");
      setPhone("");
      setEmail("");

      Swal.fire({
        icon: "success",
        title: "Success",
        text: response.data.message,
      });
    } catch (error) {
      let errorMessage = "An error occurred. Please try again.";
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        if (typeof error.response.data.message === "string") {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join("; ");
        } else if (typeof error.response.data.message === "object") {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        Swal.fire({
          icon: "error",
          title: "Failed",
          text: JSON.stringify(error.response.data.data),
        });
        //   toast.error(errorMessage);
        console.log(error);
      }
    } finally {
      setAddLoading(false);
    }
  };

  const options = [
    { value: 'fintech', label: 'Fintech' },
  ]
  

  return (
    <div className={classes.maincont1234}>
      <div className={classes.formSection}>
      <div className={classes.backDiv}>
       <Link to ={"/confirmChart"}><img src={Back} alt="Back" className={classes.back}/></Link> 
        </div>
        <div className={classes.textMain}>
          <p>Complete your account setup</p>
        </div>
        <div className={classes.normdfalp}>
          <img
            src={Fullbullets}
            alt="icon"
            className={classes.Imageshowda213}
          />

          <div className={classes.container}>
            <div className={classes.card}>
              <p className={classes.skips}>Skip</p>
              <h1 className={classes.title}>Set up Chart of Accounts</h1>
              <p className={classes.subtitle}>Add your chart of account</p>
              <label htmlFor="company-category" className={classes.label}>
                Select Company Category
              </label>
              <Select options={options} className={classes.labelSelect}/>
              <Link to={'/upload_tri_balance'} ><button className={classes.continueButton}>Continue</button></Link>
              <Link to={'/confirmChart'} ><button className={classes.backButton}>Go Back</button></Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
