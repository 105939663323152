import React, { useState, useEffect } from 'react';
import classes from './PrintActivity.module.css';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useLocation } from 'react-router-dom';

export default function PrintTrialSummary() {
    const [bearer, setBearer] = useState('');
    const [company, setCompany] = useState('');
    const location = useLocation();
    const [currentDateTime, setCurrentDateTime] = useState('');
    const [totalDebit, setTotalDebit] = useState('');
    const [totalCredit, setTotalCredit] = useState('');
    const [continent, setContinent] = useState('');
  const [region, setRegion] = useState('');
  const [province, setProvince] = useState('');
  const [userType, setUserType] = useState('');
  const [totalClosing, setTotalClosing] = useState('');
    const [totalOpening, setTotalOpening] = useState('');

    useEffect(() => {
        const addLeadingZero = (number) => (number < 10 ? `0${number}` : number);
    
        const currentDate = new Date();
        const day = currentDate.getDate();
        const month = addLeadingZero(currentDate.getMonth() + 1);
        const year = currentDate.getFullYear();
        const minutes = addLeadingZero(currentDate.getMinutes());
        const hours = addLeadingZero(currentDate.getHours() % 12 || 12);
        const seconds = addLeadingZero(currentDate.getSeconds());
        const ampm = currentDate.getHours() >= 12 ? 'PM' : 'AM';
    
        const formattedDateTime = `${day}/${month}/${year} `;
    
        setCurrentDateTime(formattedDateTime);
      }, []);

    const readData = async () => {
        try {
          const value = await AsyncStorage.getItem('userToken');
          const value1 = await AsyncStorage.getItem('companyName');
          const continentName = await AsyncStorage.getItem('continent');
          const regionName = await AsyncStorage.getItem('region');
          const provinceName = await AsyncStorage.getItem('province');
         const admins = await AsyncStorage.getItem('admin');
    
          if (value !== null) {
            setBearer(value);
            // setAuthenticated(true);
          }
          if (value1 !== null) {
            setCompany(value1);
         
          }
          if (continentName !== null) {
            setContinent(continentName);
        }
        if (regionName !== null) {
            setRegion(regionName);
        }
        if (provinceName !== null) {
            setProvince(provinceName);
        }
        if (admins !== null) {
          setUserType(admins);
      }
        } catch (e) {
          alert('Failed to fetch the input from storage');
        }
      };

      useEffect(() => {
        readData();

    }, []);

    const { accounts, inputss } = location.state || {};
    const startDate = new Date(accounts.start_date);
    const endDate = new Date(accounts.end_date);
console.log(inputss, accounts);

    const getOrdinalSuffix = (number) => {
        const suffixes = ['th', 'st', 'nd', 'rd'];
        const v = number % 100;
        return v >= 11 && v <= 13
          ? 'th'
          : suffixes[v % 10] || 'th';
      };

      const formatDate = (date) => {
        const day = date.getDate();
        const month = date.toLocaleString('default', { month: 'long' }).toUpperCase();
        const year = date.getFullYear();
      
        return `${day}${getOrdinalSuffix(day).toUpperCase()} ${month}, ${year}`;
      };
   


      
      


      useEffect(() => {
        if (inputss) {
            const creditTotal = inputss
                .filter((item) => !isNaN(item.nCredit) && item.nCredit !== "")
                .reduce((total, item) => total + parseFloat(item.nCredit), 0);
    
            const debitTotal = inputss
                .filter((item) => !isNaN(item.nDebit) && item.nDebit !== "")
                .reduce((total, item) => total + parseFloat(item.nDebit), 0);
    
                const openingTotal = inputss
                .filter((item) => !isNaN(item.opening_balance) && item.opening_balance !== "")
                .reduce((total, item) => total + parseFloat(item.opening_balance), 0);

            const closingTotal = inputss
                .filter((item) => !isNaN(item.closing_balance) && item.closing_balance !== "")
                .reduce((total, item) => total + parseFloat(item.closing_balance), 0);
            // Format the numbers with commas and two decimal places
            const formattedDebitTotal = debitTotal.toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });
    
            const formattedCreditTotal = creditTotal.toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });

            const formattedOpeningTotal = openingTotal.toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });
            const formattedClosingTotal = closingTotal.toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });
    
            setTotalDebit(formattedDebitTotal);
            setTotalCredit(formattedCreditTotal);
            setTotalOpening(formattedOpeningTotal);
            setTotalClosing(formattedClosingTotal);
        }
    }, [inputss]);
    
      

  return (
    <div className={classes.a4}>
        <div className={classes.report}>
            <h2 className={classes.headerTop}>{company}</h2>
            <h4 className={classes.headerTwoo}>{accounts?.name}</h4>
            <h4 className={classes.headerTwo}>TRIALBALANCE - SUMMARY REPORT FROM {formatDate(startDate)}  TO {formatDate(endDate)}</h4>

            <table className={classes.tableDivF}>
                <thead>
                    <tr className={classes.tableDivTr}>
                        <th style={{ backgroundColor: '#181717', color: 'white', fontSize: '14px', textAlign: 'left', width: '300px', padding: '10px' }}>ACCOUNT</th>
                        <th style={{ backgroundColor: '#181717', color: 'white', fontSize: '14px', textAlign: 'left', width: '300px', padding: '10px' }}></th>
                        <th style={{ backgroundColor: '#181717', color: 'white', fontSize: '14px', textAlign: 'left', width: '300px', padding: '10px' }}></th>
                        <th style={{ backgroundColor: '#181717', color: 'white', fontSize: '14px', textAlign: 'left', width: '300px', padding: '10px' }}></th>
                        <th style={{ backgroundColor: '#757373', color: 'white', fontSize: '14px', textAlign: 'right', width: '100px', padding: '10px' }}>DEBIT</th>
                        <th style={{ backgroundColor: '#535252', color: 'white', fontSize: '14px', textAlign: 'right', width: '100px', padding: '10px' }}>CREDIT</th>
                    </tr>
                </thead>
                <tbody className={classes.tableBodyFirst}>
                    {inputss && inputss.map((item, index) => (
                        <tr key={index} className={classes.tableBodyTR}>
                            <td style={{ whiteSpace: "nowrap", fontSize: 14 }}>{item.name}</td>
                            {/* <td style={{ whiteSpace: "nowrap", fontSize: 14, textAlign: 'center' }}>{item.type}</td> */}
                            <td></td>
        <td></td>

                            <td style={{ textAlign: "right", fontSize: 14}}></td>

                            <td style={{ textAlign: "right",fontSize: 14 }}>{item.nDebit === "" ? '0.00' :
                                (isNaN(item.nDebit)
                                    ? '0.00'
                                    : parseFloat(item.nDebit).toLocaleString('en-US', {
                                        minimumIntegerDigits: 1,
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    }))}</td>
                            <td style={{ textAlign: "right",fontSize: 14 }}>{item.nCredit === "" ? '0.00' :
                                (isNaN(item.nCredit)
                                    ? '0.00'
                                    : parseFloat(item.nCredit).toLocaleString('en-US', {
                                        minimumIntegerDigits: 1,
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    }))}</td>
                        </tr>
                    ))}

                </tbody>
                <thead className={classes.bottomHeader}>
                    <tr>
                        <td style={{ color: 'black', fontSize: '16px', textAlign: 'left', padding: '2px', fontWeight: 900 }}>TOTALS</td>
                        <td style={{ border: '0' }}></td>
                        <td style={{  color: 'white', fontSize: '16px', textAlign: 'right', width: '100px', padding: '2px' }}></td>
                        <td style={{  color: 'white', fontSize: '16px', textAlign: 'right', width: '90px', padding: '2px' }}></td>
                        <td style={{ backgroundColor: '#757373', color: 'white', fontSize: '16px', textAlign: 'right', width: '100px', padding: '2px' }}>{totalDebit}</td>
                        <td style={{ backgroundColor: '#535252', color: 'white', fontSize: '16px', textAlign: 'right', width: '100px', padding: '2px' }}>{totalCredit}</td>
                    </tr>
                </thead>
            </table>

        </div>
        </div>
  )
}