import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Navbar, Nav, NavDropdown, Row, Button, Modal, Form, Spinner, Badge } from 'react-bootstrap';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Swal from 'sweetalert2';
import classes from '../../../Manage Cooperatives/Manage Members/ManageMember.module.css'
import { BASE_URL } from '../../../api/api';
import { toast, ToastContainer } from 'react-toastify';
import CurrencyInput from 'react-currency-input-field';
import Select from 'react-select';

import MainDashboard from '../../../Main Dashboard/MainDashoard';
import LatestDash from '../../../LatestDashboard/LatestDash';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';



function SOFP() {
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [load, setLoad] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [inputss, setInputss] = useState([]);
  const [tableData45, setTableData45] = useState([]);
  const [GenLedger, setGenLedger] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [ledgerLoading, setLedgerLoading] = useState(false);
  const [savingsLoading, setSavingsLoading] = useState(false);
  const [generalLoading, setGeneralLoading] = useState(false);
  const [fetchingLoading, setFetchingLoading] = useState(false);
  const [bearer, setBearer] = useState('');
  const navigate = useNavigate();
  const [paidBooking, setPaidBooking] = useState([]);
  const [savingss, setSavingss] = useState([]);
  const [ressult, setRessult] = useState([]);
  const [provinceOptions, setProvinceOptions] = useState([]);
  const [user, setUser] = useState("");
  const [selectOptions, setSelectOptions] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState('');
  const [selectedCustomerName, setSelectedCustomerName] = useState([]);
  const [selectedSavingsType, setSelectedSavingsType] = useState([]);
  const [selectedSavingsName, setSelectedSavingsName] = useState('');
  const [selectedContinent, setSelectedContinent] = useState('');
  const [tableData3, setTableData3] = useState([]);
  const [tableData4, setTableData4] = useState([]);
  const [filteredDatas, setFilteredDatas] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState('');
  const [admin, setAdmin] = useState('');
  const [selectedProvince, setSelectedProvince] = useState('');
  const [selectedAccount, setSelectedAccount] = useState('');
  const [tableData, setTableData] = useState([]);
  const [selectedEndDate, setSelectedEndDate] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [entriesPerPage, setEntriesPerPage] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);
  const [consolidatedLoading, setConsolidatedLoading] = useState(false);
  const [continent, setContinent] = useState('');
  const [region, setRegion] = useState('');
  const [province, setProvince] = useState('');
  const [totalPages, setTotalPages] = useState('');
  const [totalEntries, setTotalEntries] = useState('');


  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  const handleDateChange1 = (event) => {
    setSelectedEndDate(event.target.value);


  };



  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem('userToken');
      const value2 = await AsyncStorage.getItem('companyId');
      const value1 = await AsyncStorage.getItem('tobi');
      const value4 = await AsyncStorage.getItem('admin');
      const continentName = await AsyncStorage.getItem('continent');
      const regionName = await AsyncStorage.getItem('region');
      const provinceName = await AsyncStorage.getItem('province');

      if (value !== null) {
        setBearer(value);
      }
      if (value1 !== null) {
        setUser(value1);
      }
      if (value4 !== null) {
        setAdmin(value4);
      }
      if (continentName !== null) {
        setContinent(continentName);
      }
      if (regionName !== null) {
        setRegion(regionName);
      }
      if (provinceName !== null) {
        setProvince(provinceName);
      }

    } catch (e) {
      alert('Failed to fetch the input from storage');
    }
  };

  useEffect(() => {
    readData();
  }, []);

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${bearer}`
  };

  const goBack = () => {
    navigate(-1);
  }

  const handleAccountChange = (selectedOption) => {
    setSelectedAccount(selectedOption.value);
  };

  const fetchAccounts = async () => {
    setFetchingLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/reports/financial-position-summary`, {
        params: {
          // gl_code: selectedAccount,
          start_date: selectedDate,
          end_date: selectedEndDate,

        },
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${bearer}`
        }
      });
      // const resultsss = response.data?.data?.income || [];
      const ress = response.data?.data?.records || [];
      const ress1 = response.data?.data || [];
      // setRessult(ress);
      setAccounts(ress);
      setInputss(ress1);

      // const resultssx = response.data?.data?.expenses || [];
      // setInputss(resultssx);
      console.log(ress, ress1);

    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
        const errorStatus = error.response?.data?.message;
        console.log(errorStatus);

      }
    } finally {
      setFetchingLoading(false);
    }
  };

  const fetchCharts = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/account`, { headers });
      const results = response.data?.data;

      const opt1 = results.map((item) => ({
        label: item.gl_name,
        value: item.id,
      }));

      setTableData(opt1);

    } catch (error) {
      const errorStatus = error.response?.data?.message;
      console.log(errorStatus);
      setTableData([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (bearer) {
      fetchCharts();

    }
  }, [bearer]);



  // const filteredData = accounts.filter(item => item.description.toLowerCase().includes(searchTerm.toLowerCase()));

  // const totalPages = Math.ceil(filteredData.length / entriesPerPage);


  // const totalPages = Math.ceil(filteredData.length / entriesPerPage);

  const handlePrevPage = () => {
    setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages));
  };



  // const totalEntries = filteredData.length;
  const startIndexx = (currentPage - 1) * entriesPerPage + 1;
  const endIndexx = Math.min(startIndexx + entriesPerPage - 1, totalEntries);
  // const displayedData = filteredData.slice(startIndexx - 1, endIndexx);



  const fetchPaidBookings = async () => {
    setLedgerLoading(true);

    try {
      const response = await axios.get(
        `${BASE_URL}/reports/general-ledger`,
        {

          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearer}`
          }
        }
      );
      const genLed = response.data?.data;
      setGenLedger(genLed);
      //     const genTotal = response.data?.data?.total;
      // setTotalEntries(genTotal);
      // const genLast = response.data?.data?.last_page || 1;
      // setTotalPages(genLast);

      console.log(genLed, "NI");
    } catch (error) {
      const errorStatus = error.response.data.message;
      console.error(errorStatus);
    } finally {
      setLedgerLoading(false);
    }
  };

  useEffect(() => {
    if (bearer) {
      fetchPaidBookings();

    }
  }, [bearer]);

  const fetchGeneralLedger = async () => {
    setGeneralLoading(true);

    try {
      const response = await axios.get(
        `${BASE_URL}/account/fetch-member-ledger-by-account?member_id=${selectedCustomer}&account_id=${selectedSavingsType}`,
        {

          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearer}`
          }
        }
      );
      const resultssx = response.data?.data;
      setPaidBooking(resultssx);

      console.log(resultssx, "NI");
    } catch (error) {
      const errorStatus = error.response.data.message;
      console.error(errorStatus);
    } finally {
      setGeneralLoading(false);
    }
  };



  const fetchConsolidated = async () => {
    setConsolidatedLoading(true);

    try {

      const response = await axios.get(
        `${BASE_URL}/reports/consolidated-summary-income-report`, {
        params: {
          // gl_code: selectedAccount,
          start_date: selectedDate,
          end_date: selectedEndDate,
          continent_id: selectedContinent || "",
          region_id: selectedRegion || "",
          province_id: selectedProvince || ""

        },
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${bearer}`
        }
      });
      const filtData1 = response.data?.data?.income || [];
      const filtData3 = response.data?.data || [];
      const filtData2 = response.data?.data?.expenses || [];

      if (filtData1.length === 0 || filtData2.length === 0) {
        toast.error('No data found for the selected account.');
      } else {
        // setInputss(filtData1);
        setAccounts(filtData1);
        navigate('/accounting/print_income_statement_consolidated', { state: { document: filtData1, inputs: filtData2, filtData3 } });

      }
      console.log(response, "consolidated");
      // const genLed = response.data?.data;
      // setGenLedger(genLed);
      //     const genTotal = response.data?.data?.total;
      // setTotalEntries(genTotal);
      // const genLast = response.data?.data?.last_page || 1;
      // setTotalPages(genLast);

      // console.log(genLed, "NI");
    } catch (error) {
      const errorStatus = error.response.data.message;
      console.error(errorStatus);
    } finally {
      setConsolidatedLoading(false);
    }
  };



  function formatDate(dateString) {
    const date = new Date(dateString);
    const formattedDate = `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())} ${padZero(date.getHours())}:${padZero(date.getMinutes())} ${date.getHours() >= 12 ? 'PM' : 'AM'}`;
    return formattedDate;
  }

  function padZero(num) {
    return num < 10 ? `0${num}` : num;
  }

  const totalCombined = [accounts[1], accounts[2]].reduce((sum, item) => {
    return sum + item?.categories.reduce((catSum, category) => catSum + parseFloat(category.total), 0);
  }, 0);

  return (

    <div>

    <div className="wrapper">
      {/* <!-- Sidebar  --> */}


      {/* <!-- Page Content  --> */}
      <div className="content-wrapper">
        <div className="main-content">
          <ToastContainer />
          {/* <MainDashboard /> */}
          <div className='newBody'>
            <div className={classes.newWidth}>

              <div>
                <LatestDash />

              </div>

              <div className={classes.finishedbodyCont}>
              <h3 style={{fontSize: '30px', fontWeight: 700, margin:" 10px 10px 0  20px" }}>Balance Sheet - Summary</h3>
              <p style={{margin:" 0 10px 20px 20px" }}>View and Print Balance Sheet</p>
                {/* <h3 style={{ fontSize: 20, fontWeight: 700, marginLeft: "2rem" }}>Income Statement</h3> */}
                {/* <h3 style={{marginLeft:'2rem'}}>Cash Book</h3> */}
                <div className={classes.topSpace} style={{ backgroundColor: 'white' }}>
                  <div className={classes.topPadding}>
                    <div className={`${classes.formSecCont}`}>
                      <div className="card-body" style={{ border: 'none' }}>


                        <div className={`row ${classes.lblrow}`}>

                          <div className={`row ${classes.lblrow}`} style={{}}>
                            <div className="col-md-4" >
                              <div className="form-group row">
                                <label htmlFor="example-text-input" className={`col-sm-5 col-form-label font-weight-400 ${classes.lblrow}`}>Start Date:</label>
                                <div className={`col-sm-9 ${classes.lblrow}`}>
                                  <input className="form-control" required="" type="date" onChange={handleDateChange} name="start" value={selectedDate} />
                                </div>
                              </div>
                            </div>
                            <div className={`col-md-4 `} style={{marginLeft: -105}}>
                              <div className="form-group row">
                                <label htmlFor="example-text-input" className={`col-sm-5 col-form-label font-weight-400 ${classes.lblrow}`}>End Date:</label>
                                <div className="col-sm-9">
                                  <input className="form-control" required="" type="date" onChange={handleDateChange1} name="end" value={selectedEndDate} />
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* )} */}<div className={`modal-footer ${classes.overlayBtnrows101}`}>
                          <OverlayTrigger
                              placement="top"
                              className={classes.btndf1}
                              overlay={<Tooltip id="button-tooltip"  className={classes.btndf1}>Click here to process income statement</Tooltip>}
                            >
                            {/* <NavLink className={classes.adjustWidth} to ="/accounting/reconciliation_report"> */}
                              <Button
                              style={{ width: 100, height: "30px", padding: "0", borderRadius: 5 }}
                                disabled={!selectedEndDate || !selectedDate}
                                className={classes.overlayBtns110} variant='success' onClick={fetchAccounts}>
                                {fetchingLoading ? (
                                  <>
                                    <Spinner size='sm' />
                                    {/* <span style={{ marginLeft: '5px' }}>Processing, Please wait...</span> */}
                                  </>
                                ) : (
                                  "Process"
                                )}
                              </Button>
                              {/* </NavLink> */}
                            </OverlayTrigger>




                    </div>
                        </div>
                      </div>
                    </div>
                    {/* <div style={{ marginTop: 20 }} /> */}


                    
                  </div>















                  <div style={{ backgroundColor: 'white', padding: '0px 20px' }}>
                    {/* <!--Content Header (Page header)--> */}
                    <div className="content-header row align-items-center m-0">
                      {/* {(isAdmin || permittedHeaders.includes('create-savings-account')) && ( */}
                      {/* <nav aria-label="breadcrumb" className="col-sm-4 order-sm-last mb-3 mb-sm-0 p-0 ">
                <div
                  style={{
                    marginTop: 20,
                    marginBottom: 20,
                    justifyContent: "flex-end",
                    display: "flex",
                    marginLeft: "auto",
                  }}
                  className={classes.actionBtns}
                >
                  <Button variant="success" onClick={handleCreate}>
                    Create New Accounts
                  </Button>
                </div>

              </nav> */}
                      {/* )} */}

                      <div className="col-sm-8 header-title p-0">
                        <div className="media">
                          {/* <div className="header-icon text-success mr-3"><i className=""><img src={favicon} className={classes.favshi} alt="favicon" /></i></div> */}
                          <div className="media-body">
                            {/* <h4 className="font-weight-bold">Savings Account</h4> */}
                            {/* <small>Create and view your Savings Accounts...</small> */}
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <!--/.Content Header (Page header)--> */}
                    <div className="body-content">
                      <div className="row">

                        <div className="col-lg-12 col-xl-6">
                          <div className="row">

                            <div className="col-md-6 col-lg-6">

                              {/* <!--Feedback--> */}

                            </div>
                            <div className="col-md-6 col-lg-6">

                              {/* <!--Balance indicator--> */}

                            </div>
                            <div className="col-md-6 col-lg-6">

                              {/* <!--Time on site indicator--> */}

                            </div>
                            <div className="col-md-6 col-lg-6">

                              {/* <!--Top Referrals--> */}

                            </div>
                            <div className="col-md-6 col-lg-6">

                              {/* <!--Sessions by device--> */}

                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        {/* <div className="col-md-12 col-lg-12 col-xl-3 mb-4">
                  <div className="card">


                  </div>
                </div> */}






                        <div className="col-lg-12">
                          <div>
                            <div>
                              {(inputss.length > 0 || accounts.length > 0) && (
                                <Button
                                  variant='success'
                                  onClick={() => navigate('/print_statement_of_financial_position', { state: { accounts, selectedEndDate, selectedDate } })}
                                  style={{ height: 30, width: 150, borderRadius: 0, marginTop: 10, marginLeft: 20, justifyContent: "center", alignItems: "center", display: 'flex' }}
                                >
                                  Print Report
                                </Button>
                              )}

                            </div>
                            <div className="card-body">
                              <div className="table-resposive">
                                <div className={`d-flex justify-content-between align-items-center ${classes.btnStyle}`} style={{ padding: '20px 0 0 0', marginBottom: 20 }}>
                                  <div className={`${classes.greenbtn} ${classes.btndivStyle}`}>
                                    <div className={classes.actionsBtns}>
                                      <button>Copy</button>
                                      <button>Excel</button>
                                      <button>PDF</button>
                                      <button className={classes.diffbtn}>Column visibility</button>
                                    </div>
                                    <div className={classes.show}>
                                      <label className="d-flex justify-content-start align-items-center">
                                        Show
                                        <select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" className="custom-select custom-select-sm form-control form-control-sm" value={entriesPerPage}
                                          onChange={(e) => {
                                            setEntriesPerPage(parseInt(e.target.value));
                                            setCurrentPage(1);
                                          }}>
                                          <option value={10}>10</option>
                                          <option value={25}>25</option>
                                          <option value={50}>50</option>
                                          <option value={100}>100</option>
                                        </select>
                                        entries
                                      </label>
                                    </div>
                                  </div>
                                  <div className="text-right modal-effect ">
                                    <div id="DataTables_Table_0_filter" className="dataTables_filter">
                                      <div className="d-flex justify-content-start align-items-center">
                                        <div className="mr-2">Search:</div>
                                        <input
                                          type="search"
                                          value={searchTerm}
                                          className="form-control form-control-sm"
                                          placeholder=""
                                          aria-controls="DataTables_Table_0"
                                          onChange={(e) => {
                                            setSearchTerm(e.target.value);
                                            // setCurrentPage(1);
                                          }}
                                        />
                                      </div>

                                    </div>
                                  </div>
                                </div>


                                {fetchingLoading ? (
                                    <p>Fetching accounts...</p>
                                  ) : (
                                    <div className="table-responsive">
                                      <table className="table display table-bordered table-striped table-hover bg-white m-0 card-table">
                                        <thead style={{ whiteSpace: 'nowrap' }}>
                                          <tr>
                                            {/* <th>S/N</th> */}
                                            <th>Description</th>
                                            <th>Amount</th>
                                            {/* <th>Type</th> */}
                                          </tr>
                                        </thead>
                                        <tbody style={{ whiteSpace: 'nowrap' }}>
                                          {accounts.map((item, index) => (
                                            <React.Fragment key={index}>
                                              <tr>
                                                <th colSpan={4}>{item.description.toUpperCase()}</th>
                                              </tr>
                                              {item.categories?.map((category, catIndex) => (
                                                <React.Fragment key={catIndex}>
                                                  <tr>
                                                    <th colSpan={4} >{category.description}</th>
                                                  </tr>
                                                  {category.postings.map((posting, postIndex) => (
                                                    <tr key={postIndex}>
                                                      <td>{posting.name}</td>
                                                      <td style={{ textAlign: "right" }}>
                                                        {parseFloat(posting.amount).toLocaleString('en-US', {
                                                          minimumIntegerDigits: 1,
                                                          minimumFractionDigits: 2,
                                                          maximumFractionDigits: 2
                                                        })}
                                                      </td>
                                                    </tr>
                                                  ))}
                                                  <tr>
                                                    <td colSpan={1} style={{ fontWeight: 'bold' }}>Total </td>
                                                    <td style={{ textAlign: "right", fontWeight: 'bold' }}>
                                                      {parseFloat(category.total).toLocaleString('en-US', {
                                                        minimumIntegerDigits: 1,
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2
                                                      })}
                                                    </td>
                                                  </tr>
                                                  <tr><td colSpan={4}>&nbsp;</td></tr>
                                                </React.Fragment>
                                              ))}
                                            </React.Fragment>
                                          ))}
{accounts?.length > 0 && (
                                          <tr>
                                            <td style={{ fontWeight: 'bold' }} >
                                              TOTAL {accounts[1]?.description.toUpperCase()} & {accounts[2]?.description.toUpperCase()}
                                            </td>
                                            <td style={{ textAlign: "right", fontWeight: 'bold' }}>
                                              {totalCombined.toLocaleString('en-US', {
                                                minimumIntegerDigits: 1,
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2
                                              })}
                                            </td>
                                          </tr>
)}
                                        </tbody>
                                      </table>
                                    </div>
                                  )}
                                <div className={classes.endded}>
                                  <p>
                                    Showing {startIndexx} to {endIndexx} of {totalEntries} entries
                                  </p>
                                  <div style={{ display: 'flex' }}>
                                    <button
                                      style={{ border: 'none', backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'nunito', color: '#000', marginRight: 10, cursor: "pointer" }}
                                      onClick={handlePrevPage}
                                      disabled={currentPage === 1}
                                    >
                                      Previous
                                    </button>
                                    {[...Array(totalPages)].map((_, page) => {
                                      // Show only 5 pages or less if available
                                      if (page < 5 || page === currentPage - 1 || page === totalPages - 1) {
                                        return (
                                          <button
                                            key={page + 1}
                                            style={{
                                              marginLeft: '0.4rem',
                                              marginRight: '0.4rem',
                                              fontSize: '14px',
                                              fontFamily: 'nunito',
                                              fontWeight: 400,
                                              color: page + 1 === currentPage ? '#ffffff' : '#000',
                                              backgroundColor: page + 1 === currentPage ? '#28a745' : 'gainsboro',
                                              height: '2.5rem',
                                              borderRadius: '89px',
                                              padding: '0.5rem',
                                              border: 'none',
                                              width: '40px',
                                              cursor: "pointer"
                                            }}
                                            onClick={() => setCurrentPage(page + 1)}
                                          >
                                            {page + 1}
                                          </button>
                                        );
                                      }
                                      return null;
                                    })}
                                    <button
                                      style={{ cursor: "pointer", border: 'none', backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'nunito', color: '#000', marginLeft: 10 }}
                                      onClick={handleNextPage}
                                      disabled={currentPage === totalPages}
                                    >
                                      Next
                                    </button>
                                  </div>
                                </div>



                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <!--/.body content--> */}
                  </div>
                </div>
              </div>
              {/* <!--/.main content--> */}
            </div>
          </div>
          {/* <InfoFooter /> */}
          {/* <!--/.footer content--> */}
          <div className="overlay"></div>
        </div>
        {/* <!--/.wrapper--> */}

      </div>
    </div>
  </div>

  );
}

export default SOFP;