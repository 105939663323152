import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Navbar, Nav, NavDropdown, Row, Button, Modal, Form, Spinner, Badge } from 'react-bootstrap';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Swal from 'sweetalert2';
import classes from '../../../Manage Cooperatives/Manage Members/ManageMember.module.css'
import { BASE_URL } from '../../../api/api';
import { toast, ToastContainer } from 'react-toastify';
import CurrencyInput from 'react-currency-input-field';
import Select from 'react-select';

import MainDashboard from '../../../Main Dashboard/MainDashoard';
import LatestDash from '../../../LatestDashboard/LatestDash';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import jsPDF from 'jspdf';
import 'jspdf-autotable';




function TrialBalancesNew() {
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [load, setLoad] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [inputss, setInputss] = useState([]);
  const [tableData45, setTableData45] = useState([]);
  const [GenLedger, setGenLedger] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [ledgerLoading, setLedgerLoading] = useState(false);
  const [savingsLoading, setSavingsLoading] = useState(false);
  const [generalLoading, setGeneralLoading] = useState(false);
  const [fetchingLoading, setFetchingLoading] = useState(false);
  const [bearer, setBearer] = useState('');
  const navigate = useNavigate();
  const [paidBooking, setPaidBooking] = useState([]);
  const [savingss, setSavingss] = useState([]);
  const [provinceOptions, setProvinceOptions] = useState([]);
  const [user, setUser] = useState("");
  const [selectOptions, setSelectOptions] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState('');
  const [selectedCustomerName, setSelectedCustomerName] = useState([]);
  const [selectedSavingsType, setSelectedSavingsType] = useState([]);
  const [selectedSavingsName, setSelectedSavingsName] = useState('');
  const [selectedContinent, setSelectedContinent] = useState('');
  const [tableData3, setTableData3] = useState([]);
  const [tableData4, setTableData4] = useState([]);
  const [filteredDatas, setFilteredDatas] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState('');
  const [admin, setAdmin] = useState('');
  const [selectedProvince, setSelectedProvince] = useState('');
  const [selectedAccount, setSelectedAccount] = useState('');
  const [tableData, setTableData] = useState([]);
  const [selectedEndDate, setSelectedEndDate] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [entriesPerPage, setEntriesPerPage] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalDebit, setTotalDebit] = useState('');
  const [totalCredit, setTotalCredit] = useState('');
  const [userType, setUserType] = useState('');
  const [consolidatedLoading, setConsolidatedLoading] = useState(false);
  const [continent, setContinent] = useState('');
  const [region, setRegion] = useState('');
  const [province, setProvince] = useState('');


  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  const handleDateChange1 = (event) => {
    setSelectedEndDate(event.target.value);


  };



  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem('userToken');
      const value2 = await AsyncStorage.getItem('companyId');
      const value1 = await AsyncStorage.getItem('tobi');
      const value4 = await AsyncStorage.getItem('admin');
      const continentName = await AsyncStorage.getItem('continent');
      const regionName = await AsyncStorage.getItem('region');
      const provinceName = await AsyncStorage.getItem('province');

      if (value !== null) {
        setBearer(value);
      }
      if (value1 !== null) {
        setUser(value1);
      }
      if (value4 !== null) {
        setAdmin(value4);
      }
      if (continentName !== null) {
        setContinent(continentName);
      }
      if (regionName !== null) {
        setRegion(regionName);
      }
      if (provinceName !== null) {
        setProvince(provinceName);
      }

    } catch (e) {
      alert('Failed to fetch the input from storage');
    }
  };

  useEffect(() => {
    readData();
  }, []);

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${bearer}`
  };

  const goBack = () => {
    navigate(-1);
  }

  const handleAccountChange = (selectedOption) => {
    setSelectedAccount(selectedOption.value);
  };

  const fetchAccounts = async () => {
    setSavingsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/reports/get-trial-balance`, {
        params: {
          // gl_code: selectedAccount,
          start_date: selectedDate,
          end_date: selectedEndDate,
        },
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${bearer}`
        }
      });
      const resultsss = response.data?.data;
      setAccounts(resultsss);

      const resultssx = response.data?.data?.values;
      setInputss(resultssx);
      console.log(response);

    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
        const errorStatus = error.response?.data?.message;
        console.log(errorStatus);

      }
    } finally {
      setSavingsLoading(false);
    }
  };


  const fetchCharts = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/account`, { headers });
      const results = response.data?.data;

      const opt1 = results.map((item) => ({
        label: item.gl_name,
        value: item.id,
      }));

      setTableData(opt1);

    } catch (error) {
      const errorStatus = error.response?.data?.message;
      console.log(errorStatus);
      setTableData([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (bearer) {
      fetchCharts();

    }
  }, [bearer]);



  // const filteredData = inputss.filter(item => item.name.toLowerCase().includes(searchTerm.toLowerCase()));

  const filteredData = inputss.filter((item) => {
    // Convert all fields to lowercase to make the search case-insensitive
    const lowerCaseSearchTerm = searchTerm.toLowerCase();
    
    // Check if the search term is present in any of the fields
    return (
      item.code.toLowerCase().includes(lowerCaseSearchTerm) ||
      item.name.toLowerCase().includes(lowerCaseSearchTerm) ||
      // formatDate(item.created_at).toLowerCase().includes(lowerCaseSearchTerm) ||
      // item.second.toLowerCase().includes(lowerCaseSearchTerm) ||
      item.nDebit.toString().includes(lowerCaseSearchTerm) ||
      item.nCredit.toString().includes(lowerCaseSearchTerm) 
    );
  });  
  // const totalPages = Math.ceil(filteredData.length / entriesPerPage);

  const totalPages = Math.ceil(filteredData.length / entriesPerPage);

  const handlePrevPage = () => {
    setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages));
  };



  const totalEntries = filteredData.length;
  const startIndexx = (currentPage - 1) * entriesPerPage + 1;
  const endIndexx = Math.min(startIndexx + entriesPerPage - 1, totalEntries);
  const displayedData = filteredData.slice(startIndexx - 1, endIndexx);


  const fetchConsolidated = async () => {
    setConsolidatedLoading(true);

    try {

      const response = await axios.get(
        `${BASE_URL}/reports/get-trial-balance`, {
        params: {
          // gl_code: selectedAccount,
          start_date: selectedDate,
          end_date: selectedEndDate,
          continent_id: selectedContinent || "",
          region_id: selectedRegion || "",
          province_id: selectedProvince || ""

        },
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${bearer}`
        }
      });

      const filtData1 = response.data?.data || [];
      const filtData2 = response.data?.data?.values || [];

      if (filtData2.length === 0) {
        toast.error('No data found for the selected account.');
      } else {
        // setInputss(filtData1);
        setAccounts(filtData1);
        navigate('/accounting/print_trial_balance_consolidated', { state: { document: filtData2, inputs: filtData1 } });

      }
      console.log(response, "consolidated");
      // const genLed = response.data?.data;
      // setGenLedger(genLed);
      //     const genTotal = response.data?.data?.total;
      // setTotalEntries(genTotal);
      // const genLast = response.data?.data?.last_page || 1;
      // setTotalPages(genLast);

      // console.log(genLed, "NI");
    } catch (error) {
      const errorStatus = error.response.data.message;
      console.error(errorStatus);
    } finally {
      setConsolidatedLoading(false);
    }
  };
















 



 
  

 



  function formatDate(dateString) {
    const date = new Date(dateString);
    const formattedDate = `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())} ${padZero(date.getHours())}:${padZero(date.getMinutes())} ${date.getHours() >= 12 ? 'PM' : 'AM'}`;
    return formattedDate;
  }

  function padZero(num) {
    return num < 10 ? `0${num}` : num;
  }

  useEffect(() => {
    if (inputss) {
      const creditTotal = inputss
        .filter((item) => !isNaN(item.nCredit) && item.nCredit !== "")
        .reduce((total, item) => total + parseFloat(item.nCredit), 0);

      const debitTotal = inputss
        .filter((item) => !isNaN(item.nDebit) && item.nDebit !== "")
        .reduce((total, item) => total + parseFloat(item.nDebit), 0);

      // Format the numbers with commas and two decimal places
      const formattedDebitTotal = debitTotal.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });

      const formattedCreditTotal = creditTotal.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });

      setTotalDebit(formattedDebitTotal);
      setTotalCredit(formattedCreditTotal);
    }
  }, [inputss]);

  const handleCopyToClipboard = () => {
    // Define headers
    const headers = ['S/N', 'Account Number', 'Account Name', 'Debit', 'Credit'];
  
    // Convert headers array to a string with tab-separated values
    let tableString = headers.join('\t') + '\n';
  
    // Convert table data to a string with tab-separated values
    inputss.forEach((item, index) => {
      const row = [
        index + 1,
      item.code,
      item.name,
  
        parseFloat(item.nDebit).toLocaleString('en-US', {
          minimumIntegerDigits: 1,
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
        parseFloat(item.nCredit).toLocaleString('en-US', {
          minimumIntegerDigits: 1,
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      ];
      tableString += row.join('\t') + '\n';
    });
  
    // Copy the string to the clipboard
    navigator.clipboard.writeText(tableString).then(() => {
      alert('Trial Balance result copied to clipboard!');
    }).catch((err) => {
      console.error('Failed to copy: ', err);
    });
  };
  
  const handleExcelExport = async () => {
    // Create a workbook and a worksheet
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Data Sheet');

    // Define columns (headers)
    worksheet.columns = [
      { header: 'S/N', key: 'index', width: 10 },
      { header: 'Account Number', key: 'account_number', width: 50 },
      { header: 'Account Name', key: 'account_name', width: 100 },
      { header: 'Debit', key: 'debit', width: 70 },
      { header: 'Credit', key: 'credit', width: 70 }
    ];

    // Add data to worksheet
    inputss.forEach((item, index) => {
      worksheet.addRow({
       index:  index + 1,
        account_number: item.code,
        account_name: item.name,
       debit: parseFloat(item.nDebit).toLocaleString('en-US', {
          minimumIntegerDigits: 1,
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
        credit: parseFloat(item.nCredit).toLocaleString('en-US', {
          minimumIntegerDigits: 1,
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      });
    });

    // Set up file saving
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    saveAs(blob, 'Trial Balance.xlsx');
  };

  const handlePdfExport = () => {
    const pdf = new jsPDF('p', 'mm', 'a4');
  
    // Set the font size and style
    pdf.setFontSize(10);
  
    // Define the starting point for the content
    let startY = 10;
  
    // Add a title to the document
    pdf.text('Trial Balance', 10, startY);
  
    // Add some space after the title
    startY += 10;
  
    // Define the table headers
    const headers = ['S/N', 'Account Number', 'Account Name', 'Debit', 'Credit'];
  
    // Convert your data to the format required by jsPDF for autoTable
    const data = inputss.map((item, index) => [
      index + 1,
      item.code,
      item.name,
  
        parseFloat(item.nDebit).toLocaleString('en-US', {
          minimumIntegerDigits: 1,
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
        parseFloat(item.nCredit).toLocaleString('en-US', {
          minimumIntegerDigits: 1,
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
    ]);
  
    // Add the table to the PDF using autoTable plugin
    pdf.autoTable({
      startY: startY,
      head: [headers],
      body: data,
      theme: 'striped',
      styles: { fontSize: 10 },
      headStyles: { fillColor: [22, 160, 133] }, // Customize the header color (optional)
    });
  
    // Save the PDF with the name "Users List.pdf"
    pdf.save('Trial Balance.pdf');
  };

  return (

    <div>

      <div className="wrapper">
        {/* <!-- Sidebar  --> */}


        {/* <!-- Page Content  --> */}
        <div className="content-wrapper">
          <div className="main-content">
            <ToastContainer />
            {/* <MainDashboard /> */}
            <div className='newBody'>
              <div className={classes.newWidth}>

                <div>
                  <LatestDash />

                </div>

                <div className={classes.finishedbodyCont}>
                <h3 style={{fontSize: '30px', fontWeight: 700, margin:" 10px 10px 0  15px" }}>Trial Balance</h3>
                <p style={{margin:" 0 10px 20px 18px" }}>Process trial balance details by selecting the below required field</p>
                  {/* <h3 style={{ fontSize: 20, fontWeight: 700, marginLeft: "2rem" }}>Trial Balance</h3> */}
                  <div className={classes.topSpace} style={{ backgroundColor: 'white' }}>
                    <div className={classes.topPadding}>
                      <div className={`${classes.formSecCont}  ${classes.awrow}` }>
                        <div  className={`card-body ${classes.awrow}`} style={{ border: 'none' }}>


                          <div className={`row ${classes.awrow}`}>

                            <div className={`row ${classes.lblrow}`} style={{ marginTop: 0 }}>

                              <div className="col-md-4" >
                                <div className="form-group row">
                                  <label htmlFor="example-text-input" className={`col-sm-8 col-form-label font-weight-400 ${classes.lblrow}`}>Start Date:</label>
                                  <div className={`col-sm-8 ${classes.lblrow}`}>
                                    <input className="form-control" required="" type="date" onChange={handleDateChange} name="start" value={selectedDate} />
                                  </div>
                                </div>
                              </div>
                              <div className={`col-md-4 ${classes.marginLeftalign}`}>
                                <div className="form-group row">
                                  <label htmlFor="example-text-input" className={`col-sm-8 col-form-label font-weight-400 ${classes.lblrow}`}>End Date:</label>
                                  <div className="col-sm-8">
                                    <input className="form-control" required="" type="date" onChange={handleDateChange1} name="end" value={selectedEndDate} />
                                  </div>
                                </div>
                              </div>
                              <div className={`modal-footer ${classes.overlayBtnrows12}`}>
                              <OverlayTrigger
                                placement="top"
                                className={classes.btndf1}
                                overlay={<Tooltip id="button-tooltip"  className={classes.btndf1}>Click here to process trial balance</Tooltip>}
                              >
                                <Button
                                 style={{ width: 100, height: "30px", padding: "0", borderRadius: 5 }}
                                  disabled={!selectedEndDate || !selectedDate}
                                  className={classes.overlayBtns1} variant='success' onClick={fetchAccounts}>
                                  {savingsLoading ? (
                                    <>
                                      <Spinner size='sm' />
                                      <span style={{ marginLeft: '5px' }}>Processing, Please wait...</span>
                                    </>
                                  ) : (
                                    "Process"
                                  )}
                                </Button>
                              </OverlayTrigger>
                              {/* </div> */}
                              </div>
                            </div>
                            {/* )} */}
                          </div>
                        </div>
                      </div>
                      {/* <div style={{ marginTop: 20 }} /> */}



                    </div>



                    <div style={{ backgroundColor: 'white', padding: '0 20px' }}>
                      {/* <!--Content Header (Page header)--> */}
                      <div className="content-header row align-items-center m-0">
                        {/* {(isAdmin || permittedHeaders.includes('create-savings-account')) && ( */}
                        {/* <nav aria-label="breadcrumb" className="col-sm-4 order-sm-last mb-3 mb-sm-0 p-0 ">
                    <div
                      style={{
                        marginTop: 20,
                        marginBottom: 20,
                        justifyContent: "flex-end",
                        display: "flex",
                        marginLeft: "auto",
                      }}
                      className={classes.actionBtns}
                    >
                      <Button variant="success" onClick={handleCreate}>
                        Create New Accounts
                      </Button>
                    </div>

                  </nav> */}
                        {/* )} */}

                        <div className="col-sm-8 header-title p-0">
                          <div className="media">
                            {/* <div className="header-icon text-success mr-3"><i className=""><img src={favicon} className={classes.favshi} alt="favicon" /></i></div> */}
                            <div className="media-body">
                              {/* <h4 className="font-weight-bold">Savings Account</h4> */}
                              {/* <small>Create and view your Savings Accounts...</small> */}
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* <!--/.Content Header (Page header)--> */}
                      <div className="body-content">
                        <div className="row">

                          <div className="col-lg-12 col-xl-6">
                            <div className="row">

                              <div className="col-md-6 col-lg-6">

                                {/* <!--Feedback--> */}

                              </div>
                              <div className="col-md-6 col-lg-6">

                                {/* <!--Balance indicator--> */}

                              </div>
                              <div className="col-md-6 col-lg-6">

                                {/* <!--Time on site indicator--> */}

                              </div>
                              <div className="col-md-6 col-lg-6">

                                {/* <!--Top Referrals--> */}

                              </div>
                              <div className="col-md-6 col-lg-6">

                                {/* <!--Sessions by device--> */}

                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          {/* <div className="col-md-12 col-lg-12 col-xl-3 mb-4">
                      <div className="card">


                      </div>
                    </div> */}




                          <div>
                            {inputss.length > 0 && (
                              <button onClick={() => navigate('/accounting/print_trial_balance', { state: { accounts, inputss } })} style={{ height: 30, width: 130, borderRadius: 0, backgroundColor: "#198754", color: "#fff", marginBottom: 10, marginTop: 10 }}>Print Report</button>
                            )}
                          </div>

                          <div className="col-lg-12">
                            <div className="card">
                              <div className="card-body">
                                <div className="table-resposive">
                                  <div className={`d-flex justify-content-between align-items-center ${classes.btnStyle}`} style={{ padding: '20px 0 0 0', marginBottom: 10 }}>
                                    <div className={`${classes.greenbtn} ${classes.btndivStyle}`}>
                                      <div className={classes.actionsBtns}>
                                      <button disabled={inputss.length === 0} onClick={handleCopyToClipboard}>Copy</button>
                                        <button disabled={inputss.length === 0} onClick={handleExcelExport}>Excel</button>
                                        <button disabled={inputss.length === 0} onClick={handlePdfExport}>PDF</button>
                                        <button className={classes.diffbtn}>Column visibility</button>
                                      </div>
                                      <div className={classes.show}>
                                        <label className="d-flex justify-content-start align-items-center">
                                          Show
                                          <select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" className="custom-select custom-select-sm form-control form-control-sm" value={entriesPerPage}
                                            onChange={(e) => {
                                              setEntriesPerPage(parseInt(e.target.value));
                                              setCurrentPage(1);
                                            }}>
                                            <option value={10}>10</option>
                                            <option value={25}>25</option>
                                            <option value={50}>50</option>
                                            <option value={100}>100</option>
                                          </select>
                                          entries
                                        </label>
                                      </div>
                                    </div>
                                    <div className="text-right modal-effect ">
                                      <div id="DataTables_Table_0_filter" className="dataTables_filter">
                                        <div className="d-flex justify-content-start align-items-center">
                                          <div className="mr-2">Search:</div>
                                          <input
                                            type="search"
                                            value={searchTerm}
                                            className="form-control form-control-sm"
                                            placeholder=""
                                            aria-controls="DataTables_Table_0"
                                            onChange={(e) => {
                                              setSearchTerm(e.target.value);
                                              // setCurrentPage(1);
                                            }}
                                          />
                                        </div>

                                      </div>
                                    </div>
                                  </div>


                                  {savingsLoading ? (
                                    <p>Fetching accounts...</p>
                                  ) : (
                                    <div className="table-responsive" id="table-to-pdf">
                                      <table className="table display table-bordered table-striped table-hover bg-white m-0 card-table">

                                        <thead style={{ whiteSpace: 'nowrap' }}>
                                          <tr>

                                            <th>Account Number</th>
                                            <th>Account Name</th>
                                            <th>Debit</th>
                                            <th>Credit</th>


                                          </tr>
                                        </thead>
                                        <tbody style={{ whiteSpace: 'nowrap' }}>
                                          {displayedData.length > 0 ? (
                                            <>
                                              {displayedData.map((item, index) => (
                                                <tr key={index}>
                                                  <td>{item.code}</td>
                                                  <td>{item.name}</td>
                                                  <td style={{ textAlign: "right" }}>{item.nDebit === "" ? '0.00' :
                                                    (isNaN(item.nDebit)
                                                      ? '0.00'
                                                      : parseFloat(item.nDebit).toLocaleString('en-US', {
                                                        minimumIntegerDigits: 1,
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2
                                                      }))}</td>
                                                  <td style={{ textAlign: "right" }}>{item.nCredit === "" ? '0.00' :
                                                    (isNaN(item.nCredit)
                                                      ? '0.00'
                                                      : parseFloat(item.nCredit).toLocaleString('en-US', {
                                                        minimumIntegerDigits: 1,
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2
                                                      }))}</td>


                                                </tr>
                                              ))}
                                              {inputss.length > 0 && (
                                                <tr>
                                                  <td colSpan={2}>Total</td>
                                                  <td style={{ textAlign: 'right', fontWeight: "bold" }}>{totalDebit}</td>
                                                  <td style={{ textAlign: 'right', fontWeight: "bold" }}>{totalCredit}</td>
                                                </tr>
                                              )}
                                            </>

                                          ) : (
                                            <tr>
                                              <td colSpan="6" style={{ textAlign: 'center' }}>No data available</td>
                                            </tr>
                                          )}
                                        </tbody>
                                      </table>
                                    </div>
                                  )}

                                  <div className={classes.endded}>
                                    <p>
                                      Showing {startIndexx} to {endIndexx} of {totalEntries} entries
                                    </p>
                                    <div style={{ display: 'flex' }}>
                                      <button
                                        style={{ border: 'none', backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'nunito', color: '#000', marginRight: 10, cursor: "pointer" }}
                                        onClick={handlePrevPage}
                                        disabled={currentPage === 1}
                                      >
                                        Previous
                                      </button>
                                      {[...Array(totalPages)].map((_, page) => {
                                        // Show only 5 pages or less if available
                                        if (page < 5 || page === currentPage - 1 || page === totalPages - 1) {
                                          return (
                                            <button
                                              key={page + 1}
                                              style={{
                                                marginLeft: '0.4rem',
                                                marginRight: '0.4rem',
                                                fontSize: '14px',
                                                fontFamily: 'nunito',
                                                fontWeight: 400,
                                                color: page + 1 === currentPage ? '#ffffff' : '#000',
                                                backgroundColor: page + 1 === currentPage ? '#28a745' : 'gainsboro',
                                                height: '2.5rem',
                                                borderRadius: '89px',
                                                padding: '0.5rem',
                                                border: 'none',
                                                width: '40px',
                                                cursor: "pointer"
                                              }}
                                              onClick={() => setCurrentPage(page + 1)}
                                            >
                                              {page + 1}
                                            </button>
                                          );
                                        }
                                        return null;
                                      })}
                                      <button
                                        style={{ cursor: "pointer", border: 'none', backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'nunito', color: '#000', marginLeft: 10 }}
                                        onClick={handleNextPage}
                                        disabled={currentPage === totalPages}
                                      >
                                        Next
                                      </button>
                                    </div>
                                  </div>



                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <!--/.body content--> */}
                    </div>
                  </div>
                </div>
                {/* <!--/.main content--> */}
              </div>
            </div>
            {/* <InfoFooter /> */}
            {/* <!--/.footer content--> */}
            <div className="overlay"></div>
          </div>
          {/* <!--/.wrapper--> */}

        </div>
      </div>
    </div>

  );
}

export default TrialBalancesNew;