import React, { createContext, useState, useContext } from 'react';
const ModalContext = createContext();

// ModalProvider component to provide context to the whole app
export const ModalProvider = ({ children }) => {
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState({ title: '', body: '' });

  const handleShow = (content) => {
    setModalContent(content); // Set the content for the modal
    setShowModal(true); // Show the modal
  };

  const handleClose = () => setShowModal(false);

  return (
    <ModalContext.Provider value={{ showModal, handleShow, handleClose, modalContent }}>
      {children}
    </ModalContext.Provider>
  );
};

// Hook to use the modal context
export const useModal = () => {
  return useContext(ModalContext);
};
