import React, { useState, useEffect } from 'react';
import classes from '../../Manage Payables/Manage General Ledger/Reports Others/PrintActivity.module.css';
import AsyncStorage from '@react-native-async-storage/async-storage';

import { useLocation } from 'react-router-dom';
// import { format } from 'date-fns';

export default function ScheduleOfPayPrintActivity() {
    const [bearer, setBearer] = useState('');
    const [company, setCompany] = useState('');
    const location = useLocation();
    const [currentDateTime, setCurrentDateTime] = useState('');
    const [totalDebit, setTotalDebit] = useState('');
    const [totalCredit, setTotalCredit] = useState('');
    const [continent, setContinent] = useState('');
    const [region, setRegion] = useState('');
    const [province, setProvince] = useState('');
    const [userType, setUserType] = useState('');
    

    useEffect(() => {
        const addLeadingZero = (number) => (number < 10 ? `0${number}` : number);
    
        const currentDate = new Date();
        const day = currentDate.getDate();
        const month = addLeadingZero(currentDate.getMonth() + 1);
        const year = currentDate.getFullYear();
        const minutes = addLeadingZero(currentDate.getMinutes());
        const hours = addLeadingZero(currentDate.getHours() % 12 || 12);
        const seconds = addLeadingZero(currentDate.getSeconds());
        const ampm = currentDate.getHours() >= 12 ? 'PM' : 'AM';
    
        const formattedDateTime = `${day}/${month}/${year} `;
    
        setCurrentDateTime(formattedDateTime);
      }, []);

    const readData = async () => {
        try {
          const value = await AsyncStorage.getItem('userToken');
          const value1 = await AsyncStorage.getItem('companyName');
          const continentName = await AsyncStorage.getItem('continent');
            const regionName = await AsyncStorage.getItem('region');
            const provinceName = await AsyncStorage.getItem('province');
           const admins = await AsyncStorage.getItem('admin');
    
          if (value !== null) {
            setBearer(value);
            // setAuthenticated(true);
          }
          if (value1 !== null) {
            setCompany(value1);
         
          }
          if (continentName !== null) {
            setContinent(continentName);
        }
        if (regionName !== null) {
            setRegion(regionName);
        }
        if (provinceName !== null) {
            setProvince(provinceName);
        }
        if (admins !== null) {
          setUserType(admins);
      }
        } catch (e) {
          alert('Failed to fetch the input from storage');
        }
      };

      useEffect(() => {
        readData();

    }, []);

    const { accounts,  selectedDate, selectedEndDate } = location.state || {};
    console.log(accounts);

    // const startDate = new Date(accounts.created_at);
    // const endDate = new Date(accounts.updated_at);
    
    // console.log("Dates:", selectedDate, selectedEndDate)


    const getOrdinalSuffix = (number) => {
      const suffixes = ['th', 'st', 'nd', 'rd'];
      const v = number % 100;
      return v >= 11 && v <= 13
        ? 'th'
        : suffixes[v % 10] || 'th';
    };

    const formatDate = (date) => {
      const day = date.getDate();
      const month = date.toLocaleString('default', { month: 'long' });
      const year = date.getFullYear();
  
      return `${day}${getOrdinalSuffix(day)} ${month}, ${year}`;
    };


    const calculateTotals = (transactions) => {
      let totalAmount = 0;
      let totalCredit = 0;
      transactions.forEach((transaction) => {
          totalAmount += parseFloat(transaction.amount) || 0;
          totalCredit += parseFloat(transaction.credit) || 0;
      });
      return { totalAmount, totalCredit };
  };

//   const formatCurrency = (amount) => {
//     return amount.toLocaleString('en-US', {
//         minimumIntegerDigits: 1,
//         minimumFractionDigits: 2,
//         maximumFractionDigits: 2
//     });

// };
const calculateTotalAmount = (accounts) => {
  return accounts.reduce((total, account) => {
    const accountTotal = account.transactions
      ? account.transactions.reduce((acc, transaction) => acc + parseFloat(transaction.amount), 0)
      : 0;
    return total + accountTotal;
  }, 0);
};
// const totalAmount = calculateTotalAmount(accounts);
      

  return (
    <div className={classes.generalbd}>
      <div className={classes.a4}>
        <div className={classes.header}>
          <h1>THE REDEEMED CHRISTIAN CHURCH OF GOD</h1>
        <p style={{ width: "100%", fontSize: 18 }}>{userType === "Continental Accountant" ? continent :
          userType === "Regional Accountant" ? region :
          userType === "Super Admin" ? "Super Admin" :
          userType === "Provincial Accountant" ? province : null}
          </p>
          <h6 style={{fontWeight: 900}}>SCHEDULE OF PAYABLE REPORT BETWEEN {selectedDate} AND {selectedEndDate}</h6>
        </div>
        <p><span style={{fontWeight: 700, color: "#000"}}>DATE PRINTED:</span> {currentDateTime} </p>
        <table className={classes.table}>
  <thead>
    <tr>
      <th style={{ textAlign: 'left', width: '30px' }}>Transaction Date</th>
      <th style={{ textAlign: 'left', width: '10px' }}>Description</th>
      <th style={{ textAlign: 'left', width: '2px' }}>Particulars</th>
      <th style={{ textAlign: 'left', width: '2px' }}>Invoice Number</th>
      <th style={{ textAlign: 'left', width: '2px' }}>Age</th>
      <th style={{ textAlign: 'left', width: '2px', textAlign:'right' }}>Amount</th>
    </tr>
  </thead>
  <tbody>
                {accounts.map((item, index) => {
                   const totalAmount = item.transactions.reduce((sum, item) => sum + parseFloat(item.amount), 0);
                    const closingBalance = totalAmount - item.opening_balance;
                    return (
                        <React.Fragment key={index}>
                            <tr>
                                <td style={{ whiteSpace: "nowrap", fontWeight: "bold" }}>{item?.gl_name}</td>
                                <td colSpan="4" style={{ fontWeight: "bold", textAlign: "right" }}>Beginning Balance</td>
                                <td  style={{ fontWeight: "bold", textAlign: "right" }}>{parseFloat(item.opening_balance).toLocaleString('en-US', {
                                      minimumIntegerDigits: 1,
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2
                                    })}</td>
                                {/* <td style={{ fontWeight: "bold", textAlign: "right" }}>{item.opening_balance}</td> */}
                                
                            </tr>
                            {item.transactions && item.transactions.map((transaction, transactionIndex) => (
                                <tr key={transactionIndex}>
                                    <td>{transaction.transaction_date}</td>
                                    <td>{transaction.description}</td>
                                    <td>{transaction?.to?.name}</td>
                                    <td>{transaction.invoice_number}</td>
                                    <td>{transaction.age_report}</td>
                                    <td style={{textAlign: 'right'}}>{parseFloat(transaction.amount).toLocaleString('en-US', {
                                      minimumIntegerDigits: 1,
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2
                                    })}</td>
                                    {/* <td style={{textAlign:'right'}}>{transaction.amount}</td> */}
                                </tr>
                            ))}
                            <tr>
                                <td colSpan="5  " style={{ fontWeight: "bold", textAlign: "right" }}>Total</td>
                               <td style={{ fontWeight: 'bold', textAlign: 'right' }}>
                                  {totalAmount.toLocaleString('en-US', {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                  })}
                                </td>
                                {/* <td style={{ fontWeight: 'bold', textAlign: 'right' }}>
                                  {totalAmount.toLocaleString('en-US', {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                  })}
                                </td> */}
                            </tr>
                            <tr>
                                <td colSpan="5" style={{ fontWeight: "bold", textAlign: "right" }}>Closing Balance</td>
                                <td style={{ fontWeight: "bold", textAlign: "right" }}>{totalAmount.toLocaleString('en-US', {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                  })}</td>
                            </tr>
                            <tr>
                                
                            </tr>
                            <div style={{ marginTop: 30 }} />
                        </React.Fragment>
                    );
                })}
  </tbody>

</table>



      </div>
    </div>
  )
}