import React from 'react'
import classes from './SalesTable.module.css'

const SalesTable = () => {
  return (
    <div className={classes.tableContainer}>
    <table>
      <thead>
        <tr className={classes.headerRow}>
          <th className={classes.headerCell}>Date</th>
          <th className={classes.headerCell}>Payee</th>
          <th className={classes.headerCell}>Description</th>
          <th className={classes.headerCell}>Type</th>
          <th className={classes.headerCell}>Selection</th>
          <th className={classes.headerCell}>Reference</th>
          <th className={classes.headerCell}>VAT</th>
          <th className={`${classes.headerCell} ${classes.alignRight}`}>Spent</th>
          <th className={`${classes.headerCell} ${classes.alignRight}`}>Received</th>
          <th className={`${classes.headerCell} ${classes.alignCenter}`}>Rec.</th>
          <th className={`${classes.headerCell} ${classes.alignCenter}`}>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr className={classes.bodyRow}>
          <td className={classes.bodyCell}>24/07/2024</td>
          <td className={classes.bodyCell}>RRR</td>
          <td className={classes.bodyCell}>Etap</td>
          <td className={classes.bodyCell}>Customer</td>
          <td className={classes.bodyCell}>Alpha Cycling Supp</td>
          <td className={classes.bodyCell}>INV258468</td>
          <td className={classes.bodyCell}>No VAT</td>
          <td className={`${classes.bodyCell} ${classes.alignRight}`}>₦10,000</td>
          <td className={`${classes.bodyCell} ${classes.alignRight}`}>₦10,000</td>
          <td className={`${classes.bodyCell} ${classes.alignCenter}`}>
            <input type="checkbox" />
          </td>
          <td className={`${classes.bodyCell} ${classes.alignCenter}`}>
            <button className={classes.iconButton}>🖉</button> {/* Edit icon */}
            <button className={classes.iconButton}>🗑️</button> {/* Delete icon */}
          </td>
        </tr>
        {/* Additional rows can go here */}
      </tbody>
    </table>
  </div>
  )
}

export default SalesTable