import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Navbar, Nav, NavDropdown, Row, Button, Modal, Form, Spinner, Badge } from 'react-bootstrap';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Swal from 'sweetalert2';
import classes from '../../Manage Cooperatives/Manage Members/ManageMember.module.css'
import { BASE_URL } from '../../api/api';
import { toast, ToastContainer } from 'react-toastify';
import CurrencyInput from 'react-currency-input-field';
import Select from 'react-select';
import MainDashboard from '../../Main Dashboard/MainDashoard';
import GeneralLedger from '../../General Ledger/GeneralLedger';
import LatestDash from '../../LatestDashboard/LatestDash';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import jsPDF from 'jspdf';
import 'jspdf-autotable';



function ManageGeneralLedger() {
  const [searchTerm, setSearchTerm] = useState("");
  // const [totalPages, setTotalPages] = useState("");
  // const [totalEntries, setTotalEntries] = useState("");
  const [loading, setLoading] = useState(false);
  const [load, setLoad] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [inputss, setInputss] = useState([]);
  const [tableData45, setTableData45] = useState([]);
  const [GenLedger, setGenLedger] = useState([]);
  const [glAccount, setGlAccount] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [ledgerLoading, setLedgerLoading] = useState(false);
  const [savingsLoading, setSavingsLoading] = useState(false);
  const [generalLoading, setGeneralLoading] = useState(false);
  const [consolidatedLoading, setConsolidatedLoading] = useState(false);
  const [fetchingLoading, setFetchingLoading] = useState(false);
  const [bearer, setBearer] = useState('');
  const navigate = useNavigate();
  const [glopening, setGlopening] = useState([]);
  const [glclosing, setGlclosing] = useState([]);
  const [paidBooking, setPaidBooking] = useState([]);
  const [savingss, setSavingss] = useState([]);
  const [provinceOptions, setProvinceOptions] = useState([]);
  const [user, setUser] = useState("");
  const [selectOptions, setSelectOptions] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState('');
  const [selectedCustomerName, setSelectedCustomerName] = useState([]);
  const [selectedSavingsType, setSelectedSavingsType] = useState([]);
  const [selectedSavingsName, setSelectedSavingsName] = useState('');
  const [selectedContinent, setSelectedContinent] = useState('');
  const [tableData3, setTableData3] = useState([]);
  const [tableData4, setTableData4] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState('');
  const [admin, setAdmin] = useState('');
  const [selectedProvince, setSelectedProvince] = useState('');
  const [selectedAccount, setSelectedAccount] = useState('');
  const [tableData, setTableData] = useState([]);
  const [selectedEndDate, setSelectedEndDate] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [entriesPerPage, setEntriesPerPage] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);
  const [userType, setUserType] = useState('');
  const [continent, setContinent] = useState('');
  const [region, setRegion] = useState('');
  const [province, setProvince] = useState('');
  const [totalDebit, setTotalDebit] = useState('');
  const [totalCredit, setTotalCredit] = useState('');

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  const handleDateChange1 = (event) => {
    setSelectedEndDate(event.target.value);


  };



  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem('userToken');
      const value2 = await AsyncStorage.getItem('companyId');
      const value1 = await AsyncStorage.getItem('tobi');
      const value4 = await AsyncStorage.getItem('admin');
      const admins = await AsyncStorage.getItem('admin');
      const continentName = await AsyncStorage.getItem('continent');
      const regionName = await AsyncStorage.getItem('region');
      const provinceName = await AsyncStorage.getItem('province');

      if (value !== null) {
        setBearer(value);
      }
      if (value1 !== null) {
        setUser(value1);
      }
      if (value4 !== null) {
        setAdmin(value4);
      }
      if (admins !== null) {
        setUserType(admins);
      }
      if (continentName !== null) {
        setContinent(continentName);
      }
      if (regionName !== null) {
        setRegion(regionName);
      }
      if (provinceName !== null) {
        setProvince(provinceName);
      }

    } catch (e) {
      alert('Failed to fetch the input from storage');
    }
  };

  useEffect(() => {
    readData();
  }, []);

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${bearer}`
  };

  const goBack = () => {
    navigate(-1);
  }

  const handleAccountChange = (selectedOption) => {
    setSelectedAccount(selectedOption.value);
  };

  const fetchPaidBookings = async () => {
    
    setLedgerLoading(true);

    try {
      const response = await axios.get(
        `${BASE_URL}/reports/general-ledger`,
        {

          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearer}`
          }
        }
      );
      const genLed = response.data?.data?.ledgers;
      // const ledgr = response.data?.data?.accounts;
      setGenLedger(genLed);
      //     const genTotal = response.data?.data?.total;
      // setTotalEntries(genTotal);
      // const genLast = response.data?.data?.last_page || 1;
      // setTotalPages(genLast);

      console.log(response, "NI");
    } catch (error) {
      const errorStatus = error.response.data.message;
      console.error(errorStatus);
    } finally {
      setLedgerLoading(false);
    }
  };

  // useEffect(() => {
  //   if (bearer) {
  //     fetchPaidBookings();

  //   }
  // }, [bearer]);

  const fetchAccounts = async () => {
    setGenLedger([]);
    setFetchingLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/reports/general-ledger-filter`, {
        params: {
          gl_code: selectedAccount,
          start_date: selectedDate,
          end_date: selectedEndDate,

        },
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${bearer}`
        }
      });
      const resultsss = response.data?.data?.journal[0].journals;
      const resultssx = response.data?.data?.input;

      const glaccountssss = response.data?.data?.journal[0]?.gl_name.toUpperCase();
      setGlAccount(glaccountssss);

      setGenLedger(resultsss);
      setInputss(resultssx);

      const glaccountOpening = response.data?.data?.journal[0]?.opening_balance;
       setGlopening(glaccountOpening);
       const glaccountClosing = response.data?.data?.journal[0]?.closing_balance;
       setGlclosing(glaccountClosing);
      console.log(response);

    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
        const errorStatus = error.response?.data?.message;
        console.log(errorStatus);

      }
    } finally {
      setFetchingLoading(false);
    }
  };

  const fetchCharts = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/account`, { headers });
      const results = response.data?.data;

      const opt1 = results.map((item) => ({
        label: item.gl_name,
        value: item.id,
      }));
      // console.log(response);
      setTableData(opt1);

    } catch (error) {
      const errorStatus = error.response?.data?.message;
      console.log(errorStatus);
      setTableData([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (bearer) {
      fetchCharts();

    }
  }, [bearer]);



  useEffect(() => {
    if (GenLedger) {
      const debitTotal = GenLedger.reduce((total, item) => total + parseFloat(item.debit), 0);
      const creditTotal = GenLedger.reduce((total, item) => total + parseFloat(item.credit), 0);

      // Format the numbers with commas and two decimal places
      const formattedDebitTotal = debitTotal.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
      const formattedCreditTotal = creditTotal.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });

      setTotalDebit(formattedDebitTotal);
      setTotalCredit(formattedCreditTotal);
    }
  }, [GenLedger]);



 

 
  // const filteredData = GenLedger?.filter(item => item?.details.toLowerCase().includes(searchTerm.toLowerCase()));

  const filteredData = GenLedger.filter((item) => {
    // Convert all fields to lowercase to make the search case-insensitive
    const lowerCaseSearchTerm = searchTerm.toLowerCase();
    
    // Check if the search term is present in any of the fields
    return (
      item.transaction_date.toLowerCase().includes(lowerCaseSearchTerm) ||
      item.details.toLowerCase().includes(lowerCaseSearchTerm) ||
      formatDate(item.created_at).toLowerCase().includes(lowerCaseSearchTerm) ||
      item.second.toLowerCase().includes(lowerCaseSearchTerm) ||
      item.debit.toString().includes(lowerCaseSearchTerm) ||
      item.credit.toString().includes(lowerCaseSearchTerm) ||
      item.balance.toString().includes(lowerCaseSearchTerm)
    );
  });  
  
  const totalPages = Math.ceil(filteredData.length / entriesPerPage);

  // const totalPages = Math.ceil(filteredData.length / entriesPerPage);

  const handlePrevPage = () => {
    setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages));
  };



  const totalEntries = filteredData.length;
  const startIndexx = (currentPage - 1) * entriesPerPage + 1;
  const endIndexx = Math.min(startIndexx + entriesPerPage - 1, totalEntries);
  const displayedData = filteredData.slice(startIndexx - 1, endIndexx);

 
  


  const fetchGeneralLedger = async () => {
    setGeneralLoading(true);

    try {
      const response = await axios.get(
        `${BASE_URL}/account/fetch-member-ledger-by-account?member_id=${selectedCustomer}&account_id=${selectedSavingsType}`,
        {

          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearer}`
          }
        }
      );
      const resultssx = response.data?.data;
      setPaidBooking(resultssx);

      console.log(resultssx, "NI");
    } catch (error) {
      const errorStatus = error.response.data.message;
      console.error(errorStatus);
    } finally {
      setGeneralLoading(false);
    }
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const formattedDate = `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())} ${padZero(date.getHours())}:${padZero(date.getMinutes())} ${date.getHours() >= 12 ? 'PM' : 'AM'}`;
    return formattedDate;
  }

  function padZero(num) {
    return num < 10 ? `0${num}` : num;
  }

  const handleCopyToClipboard = () => {
    // Define headers
    const headers = ['S/N', 'Post Date', 'Value Date', 'Description', 'Debit', 'Credit'];
  
    // Convert headers array to a string with tab-separated values
    let tableString = headers.join('\t') + '\n';
  
    // Convert table data to a string with tab-separated values
    GenLedger.forEach((item, index) => {
      const row = [
        index + 1,
        formatDate(item.created_at),
        item.transaction_date,
        item.details,
        parseFloat(item.debit).toLocaleString('en-US', {
          minimumIntegerDigits: 1,
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
        parseFloat(item.credit).toLocaleString('en-US', {
          minimumIntegerDigits: 1,
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      ];
      tableString += row.join('\t') + '\n';
    });
  
    // Copy the string to the clipboard
    navigator.clipboard.writeText(tableString).then(() => {
      alert('General Ledger result copied to clipboard!');
    }).catch((err) => {
      console.error('Failed to copy: ', err);
    });
  };
  
  const handleExcelExport = async () => {
    // Create a workbook and a worksheet
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Data Sheet');

    // Define columns (headers)
    worksheet.columns = [
      { header: 'S/N', key: 'index', width: 10 },
      { header: 'Post Date', key: 'post_date', width: 60 },
      { header: 'Value Date', key: 'value_date', width: 60 },
      { header: 'Description', key: 'description', width: 100 },
      { header: 'Debit', key: 'debit', width: 70 },
      { header: 'Credit', key: 'credit', width: 70 }
    ];

    // Add data to worksheet
    GenLedger.forEach((item, index) => {
      worksheet.addRow({
       index:  index + 1,
        post_date: formatDate(item.created_at),
        value_date: item.transaction_date,
       descriptions:  item.details,
       debit: parseFloat(item.debit).toLocaleString('en-US', {
          minimumIntegerDigits: 1,
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
        credit: parseFloat(item.credit).toLocaleString('en-US', {
          minimumIntegerDigits: 1,
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      });
    });

    // Set up file saving
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    saveAs(blob, 'General Ledger.xlsx');
  };

  const handlePdfExport = () => {
    const pdf = new jsPDF('p', 'mm', 'a4');
  
    // Set the font size and style
    pdf.setFontSize(10);
  
    // Define the starting point for the content
    let startY = 10;
  
    // Add a title to the document
    pdf.text('General Ledger', 10, startY);
  
    // Add some space after the title
    startY += 10;
  
    // Define the table headers
    const headers = ['S/N', 'Post Date', 'Value Date', 'Description', 'Debit', 'Credit'];
  
    // Convert your data to the format required by jsPDF for autoTable
    const data = GenLedger.map((item, index) => [
      index + 1,
      formatDate(item.created_at),
      item.transaction_date,
      item.details,
      parseFloat(item.debit).toLocaleString('en-US', {
        minimumIntegerDigits: 1,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
      parseFloat(item.credit).toLocaleString('en-US', {
        minimumIntegerDigits: 1,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    ]);
  
    // Add the table to the PDF using autoTable plugin
    pdf.autoTable({
      startY: startY,
      head: [headers],
      body: data,
      theme: 'striped',
      styles: { fontSize: 10 },
      headStyles: { fillColor: [22, 160, 133] }, // Customize the header color (optional)
    });
  
    // Save the PDF with the name "Users List.pdf"
    pdf.save('General Ledger.pdf');
  };

  return (

    <div>

      <div className="wrapper">
        {/* <!-- Sidebar  --> */}


        {/* <!-- Page Content  --> */}
        <div className="content-wrapper">
          <div className="main-content">
            <ToastContainer />
            {/* <MainDashboard /> */}
            <div className='newBody'>
              <div className={classes.newWidth}>

                <div>
                  <LatestDash />
                  {/* <div className={`${classes.formSecCont}`}>
                        <div className={classes.formSectionHeader}>
                        <p style={{textTransform: "capitalize", fontWeight: "bold"}}>
  {admin === "Continental Accountant" ? continent :
      admin === "Regional Accountant" ? region :
      admin === "Super Admin" ? "Super Admin" :
      admin === "Provincial Accountant" ? province : ''}
</p>
                            <h3>General Ledgers</h3>
                            <small>Create and view your loan GenLedger...</small>
                        </div>
                        <div className={classes.formSectionHeader}>
                            <h3 style={{color:'#2D995F'}}>{user.toLocaleUpperCase()}</h3>
                            <p style={{marginTop: -10}}>{admin}</p>
                            <p style={{textTransform: "capitalize", fontWeight: "bold"}}>
  {`(${admin === "Continental Accountant" ? continent :
      admin === "Regional Accountant" ? region :
      admin === "Super Admin" ? "Super Admin" :
      admin === "Provincial Accountant" ? province : ''})`}
</p>

                        </div>
                    </div> */}
                </div>

                <div className={classes.finishedbodyCont}>
                <h3 style={{fontSize: '30px', fontWeight: 700, margin:" 10px 10px 0  22px" }}>General Ledger</h3>
                <p style={{margin:" 0 10px 20px 22px" }}>Process general ledger details by selecting the below required field</p>
                  {/* <h3 style={{ fontSize: 20, fontWeight: 700, marginLeft: "2rem" }}>General Ledger</h3> */}
                  <div className={classes.topSpace} style={{ backgroundColor: 'white' }}>
                    <div className={classes.topPadding}>
                      <div className={`${classes.formSecCont}`}>
                        <div className="card-body" style={{ border: 'none' }}>


                          <div className="row">
                            {/* Super Admin sees everything */}
                            {/* {admin === 'Super Admin' && (
      <>
        <div className="col-md-4">
          <div className="form-group row">
            <label htmlFor="example-text-input" className="col-sm-3 col-form-label font-weight-400">Continent:</label>
            <div className="col-sm-9">
              <Select
                onChange={(selectedOption) => handleContinentChange(selectedOption)}
                options={selectOptions}
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  menu: (provided) => ({
                    ...provided,
                    maxHeight: '300px',
                    maxWidth: '300px',
                    overflowY: 'auto',
                  }),
                }}
              />
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group row">
            <label htmlFor="example-text-input" className="col-sm-3 col-form-label font-weight-400">Region:</label>
            <div className="col-sm-9">
              <Select
                onChange={handleRegionChange}
                options={tableData3}
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  menu: (provided) => ({
                    ...provided,
                    maxHeight: '300px',
                    maxWidth: '300px',
                    overflowY: 'auto',
                  }),
                }}
              />
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group row">
            <label htmlFor="example-text-input" className="col-sm-3 col-form-label font-weight-400">Province:</label>
            <div className="col-sm-9">
              <Select
                onChange={(selectedOption) => handleProvinceChange(selectedOption)}
                options={tableData45}
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  menu: (provided) => ({
                    ...provided,
                    maxHeight: '300px',
                    overflowY: 'auto',
                  }),
                }}
              />
            </div>
          </div>
        </div>
      </>
    )} */}

                            {/* Continental Accountant sees region and province */}
                            {/* {admin === 'Continental Accountant' && (
      <>
        <div className="col-md-4">
          <div className="form-group row">
            <label htmlFor="example-text-input" className="col-sm-3 col-form-label font-weight-400">Region:</label>
            <div className="col-sm-9">
              <Select
                onChange={handleRegionChange}
                options={tableData3}
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  menu: (provided) => ({
                    ...provided,
                    maxHeight: '300px',
                    maxWidth: '300px',
                    overflowY: 'auto',
                  }),
                }}
              />
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group row">
            <label htmlFor="example-text-input" className="col-sm-3 col-form-label font-weight-400">Province:</label>
            <div className="col-sm-9">
              <Select
                onChange={(selectedOption) => handleProvinceChange(selectedOption)}
                options={tableData4}
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  menu: (provided) => ({
                    ...provided,
                    maxHeight: '300px',
                    overflowY: 'auto',
                  }),
                }}
              />
            </div>
          </div>
        </div>
      </>
    )} */}

                            {/* Regional Accountant sees only province */}
                            {/* {admin === 'Regional Accountant' && (
      <div className="col-md-4">
        <div className="form-group row">
          <label htmlFor="example-text-input" className="col-sm-3 col-form-label font-weight-400">Province:</label>
          <div className="col-sm-9">
            <Select
              onChange={(selectedOption) => handleProvinceChange(selectedOption)}
              options={tableData45}
              menuPortalTarget={document.body}
              styles={{
                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                menu: (provided) => ({
                  ...provided,
                  maxHeight: '300px',
                  overflowY: 'auto',
                }),
              }}
            />
          </div>
        </div>
      </div>
    )} */}

                            {/* Provincial Accountant sees only GenLedger and date fields */}
                            {/* {(admin === 'Super Admin' || admin === 'Continental Accountant' || admin === 'Regional Accountant' || admin === 'Provincial Accountant') && ( */}
                            <div className={`row ${classes.awrow}`} style={{ marginTop: 10, }}>
                              <div className="col-md-4">
                                <div className="form-group row">
                                  <label htmlFor="example-text-input" className="col-sm-5 col-form-label font-weight-400">Accounts:</label>
                                  <div className={`col-sm-9  ${classes.lblrow}`}>
                                    <Select
                                      onChange={(selectedOption) => handleAccountChange(selectedOption)}
                                      options={tableData}
                                      menuPortalTarget={document.body}
                                      styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        menu: (provided) => ({
                                          ...provided,
                                          maxHeight: '300px',
                                          maxWidth: '300px',
                                          overflowY: 'auto',
                                        }),
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className={`col-md-4 `} style={{marginLeft: -105}}>
                                <div className="form-group row">
                                  <label htmlFor="example-text-input" className={`col-sm-5 col-form-label font-weight-400 ${classes.lblrow} ${classes.lblrow2}`}>Start Date:</label>
                                  <div className="col-sm-8">
                                    <input className={`form-control  ${classes.lblroww}`} required="" type="date" onChange={handleDateChange} name="start" value={selectedDate} />
                                  </div>
                                </div>
                              </div>
                              <div className={`col-md-4 ${classes.marginLeftalign1}`}>
                                <div className="form-group row">
                                  <label htmlFor="example-text-input" className={`col-sm-8 col-form-label font-weight-400 ${classes.lblrow} ${classes.lblrow2}`}>End Date:</label>
                                  <div  className={`col-sm-8  ${classes.lblrow}`}>
                                    <input className={`form-control  ${classes.lblroww}`} required="" type="date" onChange={handleDateChange1} name="end" value={selectedEndDate} />
                                  </div>
                                </div>
                              </div>
                        <div className={`modal-footer ${classes.overlayBtnrows11}`}>
                        <OverlayTrigger
                                placement="top"
                                className={classes.btndf1}
                                overlay={<Tooltip id="button-tooltip"  className={classes.btndf1}>Click here to process general ledger between the selected dates</Tooltip>}
                              >
                                <Button
                                style={{ width: 100, height: "30px", padding: "0", borderRadius: 5 }}
                                  disabled={!selectedEndDate || !selectedAccount || !selectedDate}
                                  className={classes.overlayBtns11} variant='success' onClick={fetchAccounts}>
                                  {fetchingLoading ? (
                                    <>
                                      <Spinner size='sm' />
                                      <span style={{ marginLeft: '5px' }}>Processing, Please wait...</span>
                                    </>
                                  ) : (
                                    "Process"
                                  )}
                                </Button>
                              </OverlayTrigger>

                            </div>
                            </div>
                            {/* )} */}
                          </div>
                        </div>
                      </div>
                      {/* <div style={{ marginTop: 20 }} /> */}


                      

                        {/* {(admin === 'Super Admin' || admin === 'Continental Accountant' || admin === 'Regional Accountant') && (
    <>
        <Button style={{ borderRadius: 0 }} variant='primary' onClick={fetchConsolidated}
        disabled={!selectedEndDate || !selectedAccount || !selectedDate}
        >

            {consolidatedLoading ? (
                <>
                    <Spinner size='sm' />
                    <span style={{ marginLeft: '5px' }}>Processing, Please wait...</span>
                </>
            ) : (
                "Print Consolidated Report"
            )}
        </Button>
    </>
)} */}

                      
                    </div>















                    <div style={{ backgroundColor: 'white', padding: '0 20px' }}>
                      {/* <!--Content Header (Page header)--> */}
                      <div className="m-0 content-header row align-items-center">
                        {/* {(isAdmin || permittedHeaders.includes('create-savings-account')) && ( */}
                        {/* <nav aria-label="breadcrumb" className="p-0 mb-3 col-sm-4 order-sm-last mb-sm-0 ">
                  <div
                    style={{
                      marginTop: 20,
                      marginBottom: 20,
                      justifyContent: "flex-end",
                      display: "flex",
                      marginLeft: "auto",
                    }}
                    className={classes.actionBtns}
                  >
                    <Button variant="success" onClick={handleCreate}>
                      Create New Accounts
                    </Button>
                  </div>

                </nav> */}
                        {/* )} */}

                        <div className="p-0 col-sm-8 header-title">
                          <div className="media">
                            {/* <div className="mr-3 header-icon text-success"><i className=""><img src={favicon} className={classes.favshi} alt="favicon" /></i></div> */}
                            <div className="media-body">
                              {/* <h4 className="font-weight-bold">Savings Account</h4> */}
                              {/* <small>Create and view your Savings Accounts...</small> */}
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* <!--/.Content Header (Page header)--> */}
                      <div className="body-content">
                        <div className="row">

                          <div className="col-lg-12 col-xl-6">
                            <div className="row">

                              <div className="col-md-6 col-lg-6">

                                {/* <!--Feedback--> */}

                              </div>
                              <div className="col-md-6 col-lg-6">

                                {/* <!--Balance indicator--> */}

                              </div>
                              <div className="col-md-6 col-lg-6">

                                {/* <!--Time on site indicator--> */}

                              </div>
                              <div className="col-md-6 col-lg-6">

                                {/* <!--Top Referrals--> */}

                              </div>
                              <div className="col-md-6 col-lg-6">

                                {/* <!--Sessions by device--> */}

                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          {/* <div className="mb-4 col-md-12 col-lg-12 col-xl-3">
                    <div className="card">


                    </div>
                  </div> */}





                          <div className="col-lg-12">
                            <div className="card">
                              <div style={{ marginLeft: 10 }}>
                                {GenLedger.length > 0 && (
                                  <button onClick={() => navigate('/accounting/general_ledger', { state: { document: GenLedger, inputs: inputss, glname: glAccount, glopening, glclosing } })} style={{ height: 30, width: 130, borderRadius: 0, backgroundColor: "#198754", color: "#fff", marginBottom: 10, marginTop: 10, }}>Print Report</button>
                                )}
                              </div>

                              <div className="card-body">
                                <div className="table-resposive">
                                  <div className={`d-flex justify-content-between align-items-center ${classes.btnStyle}`} style={{ padding: '0px 0 0 0', marginBottom: 0 }}>
                                    <div className={`${classes.greenbtn} ${classes.btndivStyle}`} >
                                      <div className={classes.actionsBtns}>
                                      <button disabled={GenLedger.length === 0} onClick={handleCopyToClipboard}>Copy</button>
                                        <button disabled={GenLedger.length === 0} onClick={handleExcelExport}>Excel</button>
                                        <button disabled={GenLedger.length === 0} onClick={handlePdfExport}>PDF</button>
                                        <button className={classes.diffbtn}>Column visibility</button>
                                      </div>
                                      <div className={classes.show}>
                                        <label className="d-flex justify-content-start align-items-center">
                                          Show
                                          <select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" className="custom-select custom-select-sm form-control form-control-sm" value={entriesPerPage}
                                            onChange={(e) => {
                                              setEntriesPerPage(parseInt(e.target.value));
                                              setCurrentPage(1);
                                            }}>
                                            <option value={10}>10</option>
                                            <option value={25}>25</option>
                                            <option value={50}>50</option>
                                            <option value={100}>100</option>
                                          </select>
                                          entries
                                        </label>
                                      </div>
                                    </div>
                                    <div className="text-right modal-effect ">
                                      <div id="DataTables_Table_0_filter" className="dataTables_filter">
                                        <div className="d-flex justify-content-start align-items-center">
                                          <div className="mr-2">Search:</div>
                                          <input
                                            type="search"
                                            value={searchTerm}
                                            className="form-control form-control-sm"
                                            placeholder=""
                                            aria-controls="DataTables_Table_0"
                                            onChange={(e) => {
                                              setSearchTerm(e.target.value);
                                              // setCurrentPage(1);
                                            }}
                                          />
                                        </div>

                                      </div>
                                    </div>
                                  </div>


                                  {ledgerLoading || fetchingLoading ? (
                                    <p>Fetching ledger...</p>
                                  ) : (
                                    <div className="table-responsive" id="table-to-pdf">
                                      <table className="table m-0 bg-white display table-bordered table-striped table-hover card-table">

                                        <thead style={{ whiteSpace: 'nowrap' }}>
                                          <tr>
                                            <th>S/N</th>
                                            <th>Post Date</th>
                                            <th>Value Date</th>
                                            <th>Description</th>
                                            <th>Contra GL</th>
                                            <th>Debit</th>
                                            <th>Credit</th>
                                            <th>Balance</th>
                                          </tr>
                                        </thead>
                                        <tbody style={{ whiteSpace: 'nowrap' }}>
                                          {displayedData.length > 0 ? (
                                             <>
                                            {displayedData.map((item, index) => (
                                              <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{formatDate(item.created_at)}</td>
                                                <td>{item.transaction_date}</td>
                                                <td style={{width: 200, whiteSpace: "wrap"}}>{item?.details}</td>
                                                <td style={{width: 200, whiteSpace: "wrap"}}>{item?.second}</td>
                                                <td style={{ textAlign: "right" }}>{parseFloat(item.debit).toLocaleString('en-US', {
                                                  minimumIntegerDigits: 1,
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2
                                                })}
                                                </td>
                                                <td style={{ textAlign: "right" }}>{parseFloat(item.credit).toLocaleString('en-US', {
                                                  minimumIntegerDigits: 1,
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2
                                                })}
                                                </td>
                                                <td style={{ textAlign: "right" }}>
                                                    {parseFloat(item.balance).toLocaleString('en-US', {
                                                      minimumIntegerDigits: 1,
                                                      minimumFractionDigits: 2,
                                                      maximumFractionDigits: 2,
                                                    })}
                                                  </td>
                                              </tr>
                                            ))}
                                            {GenLedger.length > 0 && (
                                              <tr>
                                                <td colSpan={5}>Total</td>
                                                <td style={{ textAlign: 'right', fontWeight: "bold" }}>{totalDebit}</td>
                                                <td style={{ textAlign: 'right', fontWeight: "bold" }}>{totalCredit}</td>
                                              </tr>
                                            )}
                                            {GenLedger.length > 0 && (
                                              <tr>
                                                <td colSpan={7}>Closing Balance</td>
                                                <td style={{ textAlign: 'right', fontWeight: "bold" }}>{parseFloat(glclosing).toLocaleString('en-US', {
                                                    minimumIntegerDigits: 1,
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2,
                                                  })}</td>
                                              </tr>
                                            )}
                                            </>
                                          ) : (
                                            <tr>
                                              <td colSpan="8" style={{ textAlign: 'center' }}>No data available</td>
                                            </tr>
                                          )}
                                        </tbody>
                                      </table>
                                    </div>
                                  )}
                                  <div className={classes.endded}>
                                    <p>
                                      Showing {startIndexx} to {endIndexx} of {totalEntries} entries
                                    </p>
                                    <div style={{ display: 'flex' }}>
                                      <button
                                        style={{ border: 'none', backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'nunito', color: '#000', marginRight: 10, cursor: "pointer" }}
                                        onClick={handlePrevPage}
                                        disabled={currentPage === 1}
                                      >
                                        Previous
                                      </button>
                                      {[...Array(totalPages)].map((_, page) => {
                                        // Show only 5 pages or less if available
                                        if (page < 5 || page === currentPage - 1 || page === totalPages - 1) {
                                          return (
                                            <button
                                              key={page + 1}
                                              style={{
                                                marginLeft: '0.4rem',
                                                marginRight: '0.4rem',
                                                fontSize: '14px',
                                                fontFamily: 'nunito',
                                                fontWeight: 400,
                                                color: page + 1 === currentPage ? '#ffffff' : '#000',
                                                backgroundColor: page + 1 === currentPage ? '#28a745' : 'gainsboro',
                                                height: '2.5rem',
                                                borderRadius: '89px',
                                                padding: '0.5rem',
                                                border: 'none',
                                                width: '40px',
                                                cursor: "pointer"
                                              }}
                                              onClick={() => setCurrentPage(page + 1)}
                                            >
                                              {page + 1}
                                            </button>
                                          );
                                        }
                                        return null;
                                      })}
                                      <button
                                        style={{ cursor: "pointer", border: 'none', backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'nunito', color: '#000', marginLeft: 10 }}
                                        onClick={handleNextPage}
                                        disabled={currentPage === totalPages}
                                      >
                                        Next
                                      </button>
                                    </div>
                                  </div>



                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <!--/.body content--> */}
                    </div>
                  </div>
                </div>
                {/* <!--/.main content--> */}
              </div>
            </div>
            {/* <InfoFooter /> */}
            {/* <!--/.footer content--> */}
            <div className="overlay"></div>
          </div>
          {/* <!--/.wrapper--> */}

        </div>
      </div>
    </div>

  );
}

export default ManageGeneralLedger;