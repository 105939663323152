import React, { useState, useEffect } from "react";
import classes from "./Salesorders.module.css";
import {
  Spinner,
  Badge,
  Button,
  Modal,
  Form,
  Tab,
  Tabs,
} from "react-bootstrap";
import axios from "axios";
import Logo from "../../../assets/promix/logoGreen.png";
import Setting from "../../../assets/promix/setting.svg";
import Report from "../../../assets/promix/report.svg";
import Contact from "../../../assets/promix/contact.svg";
import Area from "../../../assets/promix/areas.svg";
import Region from "../../../assets/promix/region.svg";
import AReport from "../../../assets/promix/areport.svg";
import DReport from "../../../assets/promix/dreport.svg";
import Arrow from "../../../assets/promix/dArrow-down.svg";
import Schedule from "../../../assets/promix/schedule.svg";
import AsyncStorage from "@react-native-async-storage/async-storage";
import CurrencyInput from "react-currency-input-field";
import { Link, useNavigate, useParams } from "react-router-dom";
import Table from "react-bootstrap/Table";
import { BASE_URL } from "../../api/api";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import Select from "react-select";
import MainDashoard from "../../Main Dashboard/MainDashoard";
import LatestDash from "../../LatestDashboard/LatestDash";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import flakes from "../../../assets/promix/flakes.png";
import nascoflakes from "../../../assets/promix/nascoflakes.png";
import nestle from "../../../assets/promix/Nestle.png";
import nestlemilo from "../../../assets/promix/nestlemilo.png";
import trash from "../../../assets/promix/trash.png";
import { CiSearch } from "react-icons/ci";
import OrderNav from "../../../Component/OrderNav/OrderNav";

export default function SalesOrderss() {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShow(false);
  const handleClose1 = () => setShow1(false);
  const handleShow = () => setShow(true);
  const handleShow1 = () => setShow1(true);
  const [bearer, setBearer] = useState("");
  const [user, setUser] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [roleLoading, setRoleLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [stat, setStat] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [createLoading,setCreateLoading] = useState(false)
  const [entriesPerPage, setEntriesPerPage] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [admin, setAdmin] = useState("");
 
  const [amountToPay, setAmountToPay] = useState("");
  const [selectedDebitAccount, setSelectedDebitAccount] = useState("");
  const [opt, setOpt] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [approveLoading,setApproveLoading] = useState(false)
  const [products, setProducts] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [sICode, setSICode] = useState('');

  

  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem("userToken");
      const value1 = await AsyncStorage.getItem("tobi");
      const value2 = await AsyncStorage.getItem("permissions");
      const value3 = await AsyncStorage.getItem("admin");

      if (value !== null) {
        setBearer(value);
      }
      if (value1 !== null) {
        setUser(value1);
      }
      if (value2 !== null) {
        setPermissions(value2);
      }
      if (value3 !== null) {
        setAdmin(value3);
      }
    } catch (e) {
      alert("Failed to fetch the input from storage");
    }
  };

  useEffect(() => {
    readData();
  }, []);

  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${bearer}`,
  };

  const fetchData1 = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/items/fetch-stocks`, {
        headers,
      });
      const roleList = response.data?.data;
      console.log(roleList);
      setOpt(roleList);
      // setRoless(roleList);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate("/login");
      } else {
        const errorStatus = error.response?.data?.message;
        console.log(errorStatus);
        setTableData([]);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const fetchData = async () => {
    setRoleLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/sales-orders`, { headers });
      const results = response.data?.data;
      // console.log(results);
      setTableData(results);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate("/login");
      } else {
        const errorStatus = error.response?.data?.message;
        // console.log(errorStatus);
        setTableData([]);
      }
    } finally {
      setRoleLoading(false);
    }
  };

  const fetchInvoiceCode = async () => {
    setLoading(true);
   
    try {
      const response = await axios.get(
        `${BASE_URL}/generate-sales-invoice-code`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearer}`
          }
        }
      );
      const resultss = response.data?.data;
      //   console.log(resultss);
      setSICode(resultss);
      // console.log(invoiceData)
      //   console.log(results, "NI");
    } catch (error) {
      const errorStatus = error.response.data.message;
      console.error(errorStatus);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (bearer) {
      fetchInvoiceCode();
    }
  }, [bearer]);




  useEffect(() => {
    if (bearer) {
      fetchData();
    }
  }, [bearer]);

  const filteredData = tableData.filter((item) =>
    item.customer?.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const totalPages = Math.ceil(filteredData.length / entriesPerPage);



  const totalEntries = filteredData.length;
  const startIndexx = (currentPage - 1) * entriesPerPage + 1;
  const endIndexx = Math.min(startIndexx + entriesPerPage - 1, totalEntries);
  const displayedData = filteredData.slice(startIndexx - 1, endIndexx);


  const options = opt.map((item) => {
    // Check if the product is out of stock
    const isOutOfStock = item?.quantity <= 0;
  
    return {
      value: item.id,
      label: isOutOfStock ? `${item.name} (Out of Stock)` : item.name, // Add "(Out of Stock)" to label if quantity is 0 or less
      data: item,
      isDisabled: isOutOfStock, // Disable the option if the product is out of stock
    };
  });
  

  const handleSelectChange = (selectedOption) => {
    console.log(selectedOption)
    const selectedItem = selectedOption.data;
    setProducts((prevProducts) => {
      // Check if item is already in the list
      const isAlreadyInList = prevProducts.some(
        (product) => product.id === selectedItem.id
      );
      if (isAlreadyInList) return prevProducts; // Prevent duplicates
      return [...prevProducts, { ...selectedItem, selectedQuantity: 1 }];
    });
  };

  const handleQuantityChange = (id, increment) => {
    setProducts((prevProducts) =>
      prevProducts.map((product) =>
        product.id === id
          ? {
              ...product,
              selectedQuantity: Math.min(
                product.selectedQuantity + increment,
                product.quantity // Limit to available stock
              ),
            }
          : product
      )
    );
  };
  const handleRemoveProduct = (id) => {
    setProducts((prevProducts) =>
      prevProducts.filter((product) => product.id !== id)
    );
  };

  const calculateTotalAmount = () => {
    return products.reduce(
      (total, product) =>
        total + product.selectedQuantity * parseFloat(product.price),
      0
    );
  };



  useEffect(() => {
    if (bearer) {
      // fetchData();
      fetchData1();
    }
  }, [bearer]);

  const handleClearItems = () => {
    setProducts([]);
  };

  const createProduct = async () => {
    setCreateLoading(true);
    // console.log(products)

    try {
      const productIds = products.map(item => item.id);
    const quantities = products.map(item => item.selectedQuantity);
    const amounts = products.map(item => parseFloat(item.price * item.selectedQuantity)); 
    const totalAmount = products.reduce(
      (acc, item) => acc + item.selectedQuantity * parseFloat(item.price),
      0
    );


      const payload = {
        transaction_number: sICode, 
        product_id: productIds,
        quantity: quantities,
        amount: amounts,
        total_amount: totalAmount,
        status: "1"
      };

    
      const response = await axios.post(
        `${BASE_URL}/sale_inventory/create`, 
        payload,
        { headers }
      );
      // console.log(response)
      fetchInvoiceCode()
      toast.success(response.data.message);
    
    } catch (error) {
      console.log(error?.response.data?.message)
      toast.error(error?.response.data?.message)
      let errorMessage = "An error occurred. Please try again.";
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        if (typeof error.response.data.message === "string") {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join("; ");
        } else if (typeof error.response.data.message === "object") {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        toast.error(errorMessage);
        // console.log(errorMessage);
      }
    } finally {
      setCreateLoading(false);
      
      setProducts([])
    }

  }
  const approveProduct = async () => {
    setApproveLoading(true)


    try {
      const productIds = products.map(item => item.id);
    const quantities = products.map(item => item.selectedQuantity);
    const amounts = products.map(item => parseFloat(item.price * item.selectedQuantity)); 
    const totalAmount = products.reduce(
      (acc, item) => acc + item.selectedQuantity * parseFloat(item.price),
      0
    );


      const payload = {
        transaction_number: sICode, 
        product_id: productIds,
        quantity: quantities,
        amount: amounts,
        total_amount: totalAmount,
        status: "2"
      };

    
      const response = await axios.post(
        `${BASE_URL}/sale_inventory/create`, 
        payload,
        { headers }
      );
      // console.log(response)
      fetchInvoiceCode()
      toast.success(response.data.message);
      navigate("/print_receipts", { state: { products,sICode} });
    
    } catch (error) {
      console.log(error?.response.data?.message)
      toast.error(error?.response.data?.message)
      let errorMessage = "An error occurred. Please try again.";
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        if (typeof error.response.data.message === "string") {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join("; ");
        } else if (typeof error.response.data.message === "object") {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        toast.error(errorMessage);
        // console.log(errorMessage);
      }
    } finally {
      setApproveLoading(false)
      
      setProducts([])
    }

  }

  // const calculateVAT = (total) => total * 0.075;

  const handlePrintReceipt = () => {
    navigate("/print_receipts", { state: { products,sICode} });
  };

  const calculateTotal = () => {
    return products.reduce((total, product) => 
      total + (product.selectedQuantity * parseFloat(product.price)), 0
    );
  };
  
  
  return (
    <div style={{ backgroundColor: "white" }}>
      {/* <LatestDash /> */}

      <div>
        <OrderNav />

        {/* Tab Content */}
        <div className="tab-content">
          <div className={classes.finishedbodyCont}>
            <div className={classes.formSection}>
              <div className={classes.formSectionHeader}>
                <div className={classes.serchsection}>
                  {/* <h5 className={classes.payText}>Sales Receipt</h5> */}
                  {/* <div variant='light' className={classes.search}><i class='bx bx-search'></i>Tea</div> */}
                  <div style={{ width: "910px", marginLeft: 1 }}>
                    {" "}
                    <Select
                      loading={isLoading}
                      dropdownHeight="100px"
                      options={options}
                      onChange={handleSelectChange}
                      style={{ borderRadius: 5 }}
                      placeholder="Select Product"
                    />
                  </div>
                  <button
                    className={classes.clearBtn}
                    onClick={createProduct}
                  >
                   { createLoading ? 'Processing...':'Suspend Transactions'}
                  </button>
                </div>
              </div>
            
              <div className={classes.tableCont}>
                <div className={classes.table}>
                  <Table striped hover>
                    <thead className={classes.tableHead}>
                      <tr>
                       
                        <th className={classes.tableHeadh}>Name</th>
                        <th className={classes.tableHeadh} style={{textAlign:'left',paddingLeft:30}}>Quantity</th>
                        <th className={classes.tableHeadh} style={{textAlign:'right'}}>Amount</th>
                        <th className={classes.tableHeadh} style={{textAlign:'right'}}>Total Amount</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody className={classes.bodyH}>
                      {products.length === 0 ? (
                        <p className={classes.addText}>Add New Product</p>
                      ) : (
                        products.map((product) => (
                          <tr key={product.id}>
                            
                            <td>{product.name}</td>
                            <td style={{textAlign:'right'}}>
                              <span className={classes.btnSpan}>
                                <button
                                  className={classes.minusBtn}
                                  onClick={() =>
                                    handleQuantityChange(product.id, -1)
                                  }
                                  disabled={product.selectedQuantity <= 1}
                                >
                                  -
                                </button>
                                <span> {product.selectedQuantity}</span>
                                <button
                                  className={classes.plusBtn}
                                  onClick={() =>
                                    handleQuantityChange(product.id, 1)
                                  }
                                  disabled={
                                    product.selectedQuantity >= product.quantity
                                  }
                                >
                                  +
                                </button>
                              </span>
                            </td>
                            {/* <td className={classes.incrmntbtns}><button className={classes.dcrmnt}>-</button>2<button className={classes.incrmnt} >+</button></td> */}
                            <td style={{textAlign:'right'}}>
                              {parseFloat(product.price).toLocaleString('en-US', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
})}
                            </td>
                            <td style={{textAlign:'right'}}>
                              {(
                                product.selectedQuantity *
                                parseFloat(product.price)
                              ).toLocaleString('en-US', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
})}
                            </td>
                            <td>
                              <button
                                onClick={() => handleRemoveProduct(product.id)}
                                style={{textAlign:'right'}}
                              >
                                <img
                                  src={trash}
                                  alt="product1"
                                  className={classes.trash}
                                />
                              </button>
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>

                    {/* </div> */}
                  </Table>
                </div>

                <div className={classes.sub}>
                  <div className={classes.trsDiv}>
                  <h3 className={classes.sTal}>Transaction No</h3>
                  <p>{sICode}</p>
                  </div>
                  
                  <div
                    style={{
                      height: "2px",
                      border: "1px solid #E9ECEF",
                      marginLeft: -20,
                      marginRight: -20,
                    }}
                  ></div>
                   <div className={classes.ites}>
                    <span className={classes.name}>Sub Total</span>
                    <sapn className={classes.name}>{(calculateTotal()).toLocaleString('en-US', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
})}</sapn>
                  </div>
                
                  <div className={classes.ites}>
                    <span className={classes.name}>VAT</span>
                    <sapn className={classes.name}>{(calculateTotal() * 0.075).toLocaleString('en-US', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
})}</sapn>
                  </div>
                 

                  <div
                    style={{
                      height: "2px",
                      border: "1px solid #E9ECEF",
                      marginLeft: -20,
                      marginRight: -20,
                      marginTop: 100,
                    }}
                  ></div>
                  <div className={classes.itess}>
                    <span className={classes.ttl}>Total</span>
                    <sapn className={classes.ttl}>
                      {(
                        calculateTotalAmount() +
                        calculateTotalAmount() * 0.075
                      ).toLocaleString('en-US', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
})}
                    </sapn>
                  </div>
                </div>
              </div>
              <div className={classes.btnBtn}>
                <button
                  className={classes.printBtn}
                  onClick={approveProduct}
                  disabled={products.length <= 0}
                >
                  {
                    approveLoading ? 'Transaction in Progress...' :  'Complete Transaction'
                  }
               
                
                </button>
                <button className={classes.clearBtn} onClick={handleClearItems}>
                  Clear Items
                </button>
              </div>
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
