import React, { useState, useEffect } from 'react';
import classes from './CreateUser.module.css';
// import RegLogo from '../../Images/RegistrationLogo.svg'
import { Spinner, Badge, Button, Modal, Form, Row, Col } from 'react-bootstrap';
// import Folder from '../../Images/folder-2.svg';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
// import ArrowLogo from '../../Images/arrow-left.svg';
// import LoanImage from '../../Images/loan bg.svg';
import MainDashoard from '../Main Dashboard/MainDashoard';
// import Ready from '../../Images/nothing.svg'
// import Ready1 from '../../Images/review.svg';
import { Link, useNavigate, useParams } from 'react-router-dom'
import Table from 'react-bootstrap/Table';
import { BASE_URL } from '../api/api';
import Swal from 'sweetalert2';
import ToggleSlider from './ToggleSlider';
import CoopDashboard from '../Cooperative Dashboard/CoopDashboard';
import ReportDashboard from '../ReportDashboard/ReportDashboard';
import { toast, ToastContainer } from 'react-toastify';
import LatestDash from '../LatestDashboard/LatestDash';


export default function ChangePassword() {
  const [currentPassword, setcurrentPassword] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const navigate = useNavigate();
  const [permissions, setPermissions] = useState([]);
  const [toggleStates, setToggleStates] = useState({});
  const [bearer, setBearer] = useState('');
  const [createLoading, setCreateLoading] = useState(false);
  const [selectType, setSelectType] = useState('');
  const [admin, setAdmin] = useState("");
  const [userType, setUserType] = useState([]);
  const [user, setUser] = useState('');
  const [userTypes, setUserTypes] = useState('');


  const [message, setMessage] = useState('');

  const handleTypeChange = (e) => setSelectType(e.target.value);

  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem('userToken');
      const value1 = await AsyncStorage.getItem('tobi');
      const value2 = await AsyncStorage.getItem('permissions');
      const value3 = await AsyncStorage.getItem('admin');
      const admins = await AsyncStorage.getItem('admin');

      if (value !== null) {
        setBearer(value);
      }
      if (value1 !== null) {
        setUser(value1);
      }
      if (value2 !== null) {
        setPermissions(value2);
      }
      if (value3 !== null) {
        setAdmin(value3);
      }
      if (admins !== null) {
        setUserTypes(admins);
    }
    } catch (e) {
      alert('Failed to fetch the input from storage');
    }
  };

  useEffect(() => {
    readData();
  }, []);

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${bearer}`
  };



  const goBack = () => {
    navigate(-1);
  }



const changePassword122 = async () => {
  setCreateLoading(true);
  try {
      console.log(currentPassword, password, passwordConfirmation);

    const response = await axios.post(
      `${BASE_URL}/reset-password`,
      {
        current_password: currentPassword,
        new_password: password,
        password_confirmation: passwordConfirmation,      
      },
      { headers }
     
    );
    console.log(response)
    
  
    setcurrentPassword('');
    setPassword('');
    setPasswordConfirmation('');
    // setSelectType('');
    // return
    Swal.fire({
      icon: 'success',
      title: 'Success',
      text: response.data.message,
    });
    // toast.success(response.data.message);
    console.log(response.data);

  } catch (error) {
    let errorMessage = 'An error occurred. Please try again.';
    if (error.response && error.response.data && error.response.data.message) {
      if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
      } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
      } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
         
      }
      Swal.fire({
        icon: 'error',
        title: 'Failed',
        text: errorMessage,
      });
      // toast.error(JSON.stringify(error.response.data.data));
      console.log(error);
  }
  } finally {
    setCreateLoading(false);
  }
};


  return (
    <div>
      <ToastContainer />
    <LatestDash />
    <div className={classes.finishedbodyCont}>
      
    <h3 className={classes.productText}>Change Password</h3>
      <div className={classes.mainform}>
        <div className={classes.loandgrantcards}>
          <div className={classes.loanContainer}>
            <div className={classes.loanResponsive}>
              <div className={classes.mainForm}>
                <Form>
                  <Row className="mb-3">
                    <Col md={6}>
                      <Form.Group controlId="formName">
                        <Form.Label>Current Password</Form.Label>
                        <Form.Control
                          type="text"
                          value={currentPassword}
                          onChange={(e) => setcurrentPassword(e.target.value)}
                          placeholder="Please enter your old password"
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group controlId="formPhoneNumber">
                        <Form.Label>New Password</Form.Label>
                        <Form.Control
                          type="text"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          placeholder="Please enter your new password"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col md={6}>
                      <Form.Group controlId="formEmail">
                        <Form.Label>Confirm Password</Form.Label>
                        <Form.Control
                          type="email"
                          value={passwordConfirmation}
                          onChange={(e) => setPasswordConfirmation(e.target.value)}
                          placeholder="Please Confirm your new password"
                        />
                      </Form.Group>
                    </Col>
                    {/* {admin === "Super Admin" && (
     <>
                    <Col md={6}>
                      <Form.Group controlId="formSelectType">
                        <Form.Label>Select Type</Form.Label>
                        <Form.Select
                          value={selectType}
                          onChange={handleTypeChange}
                        >
                          <option value="">Select Type</option>
                          {userType.map((item) => (
                                  <option key={item.id} value={item.id}>
                                    {item.type}
                                  </option>
                                ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    </>
                    )} */}
                  </Row>
                  
                  {/* {selectType !== '1' && selectType && (
        <>
                 
  <Row className="mb-3">
    <Col md={6}>
      <Form.Group controlId="formContinent">
        <Form.Label>Select Continent</Form.Label>
        <Form.Select
          className="form-control"
          value={selectedContinent}
          onChange={handleContinentChange}
        >
          <option value="" disabled>
            Select Continent
          </option>
          {continents.map((item) => (
            <option key={item.id} value={item.id}>
              {item.description}
            </option>
          ))}
        </Form.Select>
      </Form.Group>
    </Col>

 
      <Col md={6}>
        <Form.Group controlId="formRegion">
          <Form.Label>Select Region</Form.Label>
          <Form.Select
            className="form-control"
            value={selectedRegion}
            onChange={handleRegionChange}
          >
            <option value="" disabled>
              Select Region
            </option>
            {tableData4.map((item) => (
              <option key={item.id} value={item.id}>
                {item.description}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      </Col>
 
  </Row>


  <Row className="mb-3">
    <Col md={6}>
      <Form.Group controlId="formProvince">
        <Form.Label>Select Province</Form.Label>
        <Form.Select
          className="form-control"
          value={selectedProvince}
          onChange={handleProvinceChange}
        >
          <option value="" disabled>
            Select Province
          </option>
          {tableData5.map((item) => (
            <option key={item.id} value={item.id}>
              {item.description}
            </option>
          ))}
        </Form.Select>
      </Form.Group>
    </Col>
  </Row>
  </>
                  )} */}

                
                </Form>

                <div className='modal-footer' style={{ marginTop: 40 }} />
                
              </div>
            </div>
            <div className={`${classes.formIntBtn} ${classes.formIntBtn2}`}>
              {/* <Button variant="light" className={classes.btn1} onClick={goBack}>Cancel</Button> */}
              <Button variant="success" className={classes.btn2} onClick={changePassword122}>
                {createLoading ? (
                  <>
                    <Spinner size='sm' />
                    <span style={{ marginLeft: '5px' }}>Processing, Please wait...</span>
                  </>
                ) : (
                  "Change Password"
                )}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}