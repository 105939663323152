import React, { useState, useEffect } from 'react';
import classes from './SupplierInvoice.module.css';
import logo from '../../assets/nw/logo.png';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import AsyncStorage from '@react-native-async-storage/async-storage';


function SupplierInvoice() {
    const [user, setUser] = useState("");
  const [bearer, setBearer] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const [company, setCompany] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');
  const [email, setEmail] = useState('');
  const [currentDateTime, setCurrentDateTime] = useState('');

  const readData = async () => {
      try {
        const value = await AsyncStorage.getItem('userToken');
        const value2 = await AsyncStorage.getItem('companyId');
        const value1 = await AsyncStorage.getItem('tobi');
        const value3 = await AsyncStorage.getItem('companyName');
        const value4 = await AsyncStorage.getItem('companyEmail');
        const value5 = await AsyncStorage.getItem('companyPhone');
  
        if (value !== null) {
          setBearer(value);
          // setAuthenticated(true);
        }
        if (value1 !== null) {
            setUser(value1);
          }
        if (value3 !== null) {
          setCompany(value3);
        }
        if (value4 !== null) {
          setEmail(value4);
        }
        if (value5 !== null) {
          setPhone(value5);
        }
  
      } catch (e) {
        alert('Failed to fetch the input from storage');
      }
    };
  
    useEffect(() => {
      readData();
    }, []);

    useEffect(() => {
      const addLeadingZero = (number) => (number < 10 ? `0${number}` : number);
    
      const currentDate = new Date();
      const day = currentDate.getDate();
      const month = addLeadingZero(currentDate.getMonth() + 1);
      const year = currentDate.getFullYear();
      const minutes = addLeadingZero(currentDate.getMinutes());
      const hours = addLeadingZero(currentDate.getHours() % 12 || 12);
      const seconds = addLeadingZero(currentDate.getSeconds());
      const ampm = currentDate.getHours() >= 12 ? 'PM' : 'AM';
    
      const formattedDateTime = `${day}/${month}/${year} ${hours}:${minutes}:${seconds} ${ampm}`;
    
      setCurrentDateTime(formattedDateTime);
    }, []);
    
    const { selectedInvoice } = location.state || {};
   
    console.log(selectedInvoice);
    const handlePrint = () => {
      window.print();
    };


    const getSubtotal = (items) => {
        if (!Array.isArray(items) || items.length === 0) {
          return 0;
        }
      
        return items.reduce((total, item) => {
          const quantity = item.item?.quantity || 0;
          const price = parseFloat(item.item?.price) || 0;
          return total + (quantity * price);
        }, 0).toLocaleString('en-US', {
          minimumIntegerDigits: 1,
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      };
      
     
      const subtotal = getSubtotal(selectedInvoice?.items);

    return (
        <div className={classes.a4size}>
            <div className={classes.wholeCtDiv}>
                <div className={classes.flexTopContnt}>
                    <div className={classes.numberParty}>
                        <h4>PURCHASE INVOICE</h4>
                        <div className={classes.tinyflexTop}>
                            <p>INVOICE NO:</p>
                            <p>{selectedInvoice?.invoice_number}</p>
                        </div>
                        {/* <div className={classes.tinyflexTop}>
                            <p>REFERENCE:</p>
                            <p className={classes.truncatsade}>{selectedInvoice?.description}</p>
                        </div> */}
                        <div className={classes.tinyflexTop}>
                            <p>DATE:</p>
                            <p>{selectedInvoice?.transaction_date}</p>
                        </div>
                        <div className={classes.tinyflexTop}>
                            {/* <p>DUE DATE:</p> */}
                            {/* <p>{selectedInvoice?.invoice_number}</p> */}
                        </div>
                        {/* <div className={classes.tinyflexTop}>
                            <p>NUMBER:</p>
                            <p>INV0000358</p>
                        </div> */}
                        {/* <div className={classes.tinyflexTop}>
                        <p>SALES REP:</p>
                        <p></p>
                    </div> */}
                        <div className={classes.tinyflexTop}>
                            {/* <p>OVERALL DISCOUNT%:</p> */}
                            {/* <p>0.00%</p> */}
                        </div>
                        {/* <div className={classes.tinyflexTop}>
                        <p>PAGE:</p>
                        <p>1/1</p>
                    </div> */}
                    </div>
                    <div className={classes.imgDivFlex}>
                        <img src={logo} style={{ height: 70, width: 70 }} />
                        {/* <h2>{company}</h2> */}
                    </div>
                </div>

                <div className={classes.ToFromFlex}>
                    <div className={classes.middleCnts}>
                        <div className={classes.fromCntpart} >
                            <h6>
                                FROM
                            </h6>
                            <h2 className={classes.truncate}>
                               {company}
                            </h2>
                        </div>
                        <div className={classes.VatNoParty}>
                            {/* <h6>
                                VAT NO: 3948583-99
                            </h6> */}
                            <div className={classes.VatNoPartyFlex}>
                                <div className={classes.PostalParty}>
                                    <h6>
                                        POSTAL ADDRESS:
                                    </h6>
                                    <p>{address}</p>
                                    
                                </div>
                                <div className={classes.PostalParty}>
                                    <h6>
                                        PHYSICAL ADDRESS:
                                    </h6>
                                    <p>{address}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={classes.middleCnts}>
                        <div className={classes.fromCntpart} >
                            <h6>
                                TO
                            </h6>
                            <h2 className={classes.truncate}>
                               {selectedInvoice?.supplier?.name}
                            </h2>
                        </div>
                        <div className={classes.VatNoParty}>
                            {/* <h6>
                                VAT NO: 3948583-99
                            </h6> */}
                            <div className={classes.VatNoPartyFlex}>
                                <div className={classes.PostalParty}>
                                    <h6>
                                        POSTAL ADDRESS:
                                    </h6>
                                    <p> {selectedInvoice?.supplier?.address}</p>
                                    
                                </div>
                                <div className={classes.PostalParty}>
                                    <h6>
                                        PHYSICAL ADDRESS:
                                    </h6>
                                    <p> {selectedInvoice?.supplier?.address}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className={classes.middleCnts}>
                        <div className={classes.fromCntpart}>
                            <h6>
                                TO
                            </h6>
                            <h2 className={classes.truncate}>
                              {selectedInvoice?.supplier?.name.toLocaleUpperCase()}
                            </h2>
                        </div>
                        <div className={classes.VatNoParty}>
                            <h6>
                                CUSTOMER VAT NO: 3948583-99
                            </h6>

                            <div className={classes.VatNoPartyFlex}>
                                <div className={classes.PostalParty}>
                                    <h6>
                                        POSTAL ADDRESS:
                                    </h6>
                                    <p>{selectedInvoice?.supplier?.address}</p>
                                </div>
                                <div className={classes.PostalParty}>
                                    <h6>
                                        PHYSICAL ADDRESS:
                                    </h6>
                                    <p>{selectedInvoice?.supplier?.address}</p>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
                <div>
                    <table className={classes.thTable}>
                        <thead>
                            <tr className={classes.tableHeadRow}>
                                <th>Descrption</th>
                                <th>Quantity</th>
                                <th>Unit Price</th>
                                <th>Discount</th>
                                <th>VAT</th>
                                <th style={{textAlign: "right"}}>Total</th>
                                
                                {/* <th>Incl. Total</th> */}
                            </tr>
                        </thead>
                        <tbody>
                        {Array.isArray(selectedInvoice?.items) && selectedInvoice?.items?.length > 0 ? (
  selectedInvoice?.items?.map((item, index) => (
    <tr key={index} className={classes.tablebodyCnt}>
      <td>{item.item?.name}</td>
      <td>{item.item?.quantity}</td>
      <td>{parseFloat(item.item?.price).toLocaleString('en-US', {
                                            minimumIntegerDigits: 1,
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                          })}</td>
      <td>{parseFloat(item?.discount).toLocaleString('en-US', {
                                            minimumIntegerDigits: 1,
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                          })}</td>
      <td>{item.tax?.rate}</td>
   
      <td className={classes.boldtable}>{parseFloat(item.amount).toLocaleString('en-US', {
                                            minimumIntegerDigits: 1,
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                          })}</td>
    </tr>
  ))
) : (
  <tr>
    <td colSpan="6">No items found.</td>
  </tr>
)}

                        </tbody>


                    </table>


                </div>
                <div className={classes.footertypeParty}>
                    <div className={classes.footerfirstdiv}>
                        <p> Thank you</p>
                        {/* <p>Banking Details: </p>
                        <p>ABSA, Sandon</p>
                        <p>Branch Code 29938</p> */}
                    </div>
                    <div className={classes.footerfirstdiv}>
                        <div className={classes.spantxt}><p>Total VAT:</p> <p> {parseFloat(selectedInvoice?.total_vat).toLocaleString('en-US', {
                                            minimumIntegerDigits: 1,
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                          })}</p></div>
                        <div className={classes.spantxt}><p>Total Discount:</p> <p> {parseFloat(selectedInvoice?.total_discount).toLocaleString('en-US', {
                                            minimumIntegerDigits: 1,
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                          })}</p></div>
                        {/* <div className={classes.spantxt}><p>Total Exclusive:</p> <p> R105,000.00</p></div> */}
                        <div className={classes.spantxt}><p>Sub Total:</p> <p> {subtotal}</p></div>
                        <div className={classes.spantxt1}><p>Grand Total:</p> <p> {parseFloat(selectedInvoice?.amount).toLocaleString('en-US', {
                                            minimumIntegerDigits: 1,
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                          })}</p></div>
                        <p className={classes.balanceTxt}>BALANCE DUE</p>
                        <p className={classes.balanceTxtNum}>{parseFloat(selectedInvoice?.balance).toLocaleString('en-US', {
                                            minimumIntegerDigits: 1,
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                          })}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default SupplierInvoice;