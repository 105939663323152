import React, { useState, useEffect } from 'react';
import classes from './ReceivableTypes.module.css';
// import "../../assets/datatables/dataTables.bootstrap4.min.css";
// import RegLogo from '../../Images/RegistrationLogo.svg'
import { Spinner, Badge, Button, Modal, Form } from 'react-bootstrap';
// import Folder from '../../Images/folder-2.svg';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
// import ArrowLogo from '../../Images/arrow-left.svg';
// import LoanImage from '../../Images/loan bg.svg';
import MainDashoard from '../Main Dashboard/MainDashoard';
// import Ready from '../../Images/nothing.svg'
// import Ready1 from '../../Images/review.svg';
import { Link, useNavigate, useParams } from 'react-router-dom'
import Table from 'react-bootstrap/Table';
import { BASE_URL } from '../api/api';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import CoopDashboard from '../Cooperative Dashboard/CoopDashboard';
import ReportDashboard from '../ReportDashboard/ReportDashboard';
import ReceivablesDash from '../Receivables Dashboard/ReceivablesDash';
import Select from 'react-select';


export default function ReceivableTypes() {
  const navigate = useNavigate();
  const [bearer, setBearer] = useState('');
  const [user, setUser] = useState('');
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [regionLoading, setRegionLoading] = useState(false);
  const [provinceLoading, setProvinceLoading] = useState(false);
  const [createRegionLoading, setCreateRegionLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [tableData1, setTableData1] = useState([]);
  const [loading, setLoading] = useState(false);
  const [load, setLoad] = useState(false);
  const handleClose = () => setShow(false);
  const handleClose1 = () => setShow1(false);
  const handleShow = () => setShow(true);
  const handleShow1 = () => setShow1(true);
  const [selectedContinent, setSelectedContinent] = useState('');
  const [eyeClicked, setEyeClicked] = useState(false);
  const [trashClicked, setTrashClicked] = useState(false);
  const [eyeClicked1, setEyeClicked1] = useState(false);
  const [trashClicked1, setTrashClicked1] = useState(false);
  const [eyeClicked2, setEyeClicked2] = useState(false);
  const [trashClicked2, setTrashClicked2] = useState(false);
  const [entriesPerPage, setEntriesPerPage] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [entriesPerPage1, setEntriesPerPage1] = useState(100);
  const [currentPage1, setCurrentPage1] = useState(1);
  const [searchTerm1, setSearchTerm1] = useState("");
  const [entriesPerPage2, setEntriesPerPage2] = useState(100);
  const [currentPage2, setCurrentPage2] = useState(1);
  const [searchTerm2, setSearchTerm2] = useState("");
  const [description, setDescription] = useState('')
  const [description1, setDescription1] = useState('')
  const [accountantName, setAccountantName] = useState("");
  const [accountantName1, setAccountantName1] = useState("");
  const [accountantPhone, setAccountantPhone] = useState("");
  const [accountantPhone1, setAccountantPhone1] = useState("");
  const [accountantEmail, setAccountantEmail] = useState("");
  const [accountantEmail1, setAccountantEmail1] = useState("");
  const [selectedContinent1, setSelectedContinent1] = useState('');
  const [selectedRegion, setSelectedRegion] = useState('');
  const [tableData2, setTableData2] = useState([]);
  const [tableData3, setTableData3] = useState([]);
  const [userType, setUserType] = useState('');
  const [selectOptions, setSelectOptions] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState('');



  const [selectedRegion1, setSelectedRegion1] = useState("");
  const [selectedProvince, setSelectedProvince] = useState("");

  const readData = async () => {
    try {
      const detail = await AsyncStorage.getItem('tobi');
      const details = await AsyncStorage.getItem('userToken');
      const detailss = await AsyncStorage.getItem('companyName');
      const admins = await AsyncStorage.getItem('admin');
      const continentName = await AsyncStorage.getItem('continent');
      const regionName = await AsyncStorage.getItem('region');
      const provinceName = await AsyncStorage.getItem('province');


      if (detail !== null) {
        // const firstName = detail.split(' ')[0];
        setUser(detail);

      }

      if (admins !== null) {
        setUserType(admins);
      }
      // if (continentName !== null) {
      //     setContinent(continentName);
      // }
      // if (regionName !== null) {
      //     setRegion(regionName);
      // }
      // if (provinceName !== null) {
      //     setProvince(provinceName);
      // }


      if (details !== null) {
        setBearer(details);
      }
      // if (detailss !== null) {
      //     setCompany(detailss);
      // }
    } catch (e) {
      alert('Failed to fetch the input from storage');
    }
  };

  useEffect(() => {
    readData();
  }, []);

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${bearer}`
  };



  const fetchContinents = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/account`, { headers });

      // console.log(response);
      const results = response.data?.data;
      setTableData(results);

      const options = results.map((item) => ({
        label: item.gl_name,
        value: item.id,
      }));
      setSelectOptions(options);
      console.log("lljioof", results);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
        let errorMessage = 'An error occurred. Please try again.';
        if (error.response && error.response.data && error.response.data.message) {
          if (typeof error.response.data.message === 'string') {
            errorMessage = error.response.data.message;
          } else if (Array.isArray(error.response.data.message)) {
            errorMessage = error.response.data.message.join('; ');
          } else if (typeof error.response.data.message === 'object') {
            errorMessage = JSON.stringify(error.response.data.message);
          }
        }
        setTableData([]);
      }
    } finally {
      setIsLoading(false);
    }
  };
  const fetchRegions = async () => {
    setRegionLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/receivable-types`, { headers });

      // console.log(response);
      const reg = response.data?.data;
      setTableData1(reg);
      // console.log(results);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
        let errorMessage = 'An error occurred. Please try again.';
        if (error.response && error.response.data && error.response.data.message) {
          if (typeof error.response.data.message === 'string') {
            errorMessage = error.response.data.message;
          } else if (Array.isArray(error.response.data.message)) {
            errorMessage = error.response.data.message.join('; ');
          } else if (typeof error.response.data.message === 'object') {
            errorMessage = JSON.stringify(error.response.data.message);
          }
        }
        setTableData1([]);
      }
    } finally {
      setRegionLoading(false);
    }
  };


  // const fetchSubContinents = async (selectedContinent) => {
  //   setLoad(true);
  //   try {
  //     const response = await axios.get(`${BASE_URL}/continents/getcontinentregions?continent_id=${selectedContinent}`, { headers });

  //     // console.log(response);
  //     const subCont = response.data?.data;
  //     setTableData3(subCont);
  //     // console.log(results);
  //   } catch (error) {
  //     if (error.response && error.response.status === 401) {
  //       // Redirect to login page if unauthorized
  //       navigate('/login');
  //     }else{
  //       let errorMessage = 'An error occurred. Please try again.';
  //       if (error.response && error.response.data && error.response.data.message) {
  //           if (typeof error.response.data.message === 'string') {
  //               errorMessage = error.response.data.message;
  //           } else if (Array.isArray(error.response.data.message)) {
  //               errorMessage = error.response.data.message.join('; ');
  //           } else if (typeof error.response.data.message === 'object') {
  //               errorMessage = JSON.stringify(error.response.data.message);
  //           }
  //       }
  //     setTableData3([]);
  //     }
  //   } finally {
  //     setLoad(false);
  //   }
  // };

  // useEffect(() => {
  //   if (bearer && selectedContinent) {
  //     fetchSubContinents(selectedContinent);
  //   }
  // }, [bearer, selectedContinent]);



  useEffect(() => {
    if (bearer) {
      fetchContinents();
      fetchRegions();


    }
  }, [bearer]);


  const createRegion = async () => {
    setCreateRegionLoading(true);
    console.log(description, selectedContinent);
    try {
      const response = await axios.post(
        `${BASE_URL}/receivable-types/create`,
        {
          description: description,
          gl_code: selectedContinent,

        },
        { headers }
      );
      console.log(response)
      fetchRegions();
      handleClose();

      toast.success(response.data.message);
      console.log(response.data);

    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        toast.error(errorMessage);
        console.log(errorMessage);
      }
    } finally {
      setCreateRegionLoading(false);
    }
  };



  function formatDate(dateString) {
    const date = new Date(dateString);
    const formattedDate = `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())} ${padZero(date.getHours())}:${padZero(date.getMinutes())} ${date.getHours() >= 12 ? 'PM' : 'AM'}`;
    return formattedDate;
  }

  function padZero(num) {
    return num < 10 ? `0${num}` : num;
  }

  // const handleContinentChange = async (event) => {
  //   setSelectedContinent(event.target.value);
  //  setSelectedRegion("");
  // }
  const handleContinentChange = (selectedOption) => {
    setSelectedContinent(selectedOption.value);
  }


  const handleRegionChange = (event) => {
    setSelectedRegion(event.target.value)
  }

  const handleContinentChange1 = (event) => {
    setSelectedContinent1(event.target.value)
  }
  const handleRegionChange1 = (event) => {
    setSelectedRegion1(event.target.value)
  }






  const handlePrevPage = () => {
    setCurrentPage(Math.max(currentPage - 1, 1));
  };

  // const handleNextPage = () => {
  //   setCurrentPage(Math.min(currentPage + 1, totalPages));
  // };

  // const totalEntries = filteredData.length;
  const startIndexx = (currentPage - 1) * entriesPerPage + 1;
  // const endIndexx = Math.min(startIndexx + entriesPerPage - 1, totalEntries);
  // const displayedData = filteredData.slice(startIndexx - 1, endIndexx);

  const filteredData1 = tableData1.filter(item => item.description.toLowerCase().includes(searchTerm1.toLowerCase()));

  const totalPages1 = Math.ceil(filteredData1.length / entriesPerPage1);

  const handlePrevPage1 = () => {
    setCurrentPage1(Math.max(currentPage1 - 1, 1));
  };

  const handleNextPage1 = () => {
    setCurrentPage1(Math.min(currentPage1 + 1, totalPages1));
  };

  const totalEntries1 = filteredData1.length;
  const startIndexx1 = (currentPage1 - 1) * entriesPerPage1 + 1;
  const endIndexx1 = Math.min(startIndexx1 + entriesPerPage1 - 1, totalEntries1);
  const displayedData1 = filteredData1.slice(startIndexx1 - 1, endIndexx1);

  const filteredData2 = tableData2.filter(item => item.description.toLowerCase().includes(searchTerm2.toLowerCase()));

  const totalPages2 = Math.ceil(filteredData2.length / entriesPerPage2);

  const handlePrevPage2 = () => {
    setCurrentPage2(Math.max(currentPage2 - 1, 1));
  };

  const handleNextPage2 = () => {
    setCurrentPage2(Math.min(currentPage2 + 1, totalPages2));
  };

  const totalEntries2 = filteredData2.length;
  const startIndexx2 = (currentPage2 - 1) * entriesPerPage2 + 1;
  const endIndexx2 = Math.min(startIndexx2 + entriesPerPage2 - 1, totalEntries2);
  const displayedData2 = filteredData2.slice(startIndexx2 - 1, endIndexx2);



  const handleEyeClick = (id) => {
    const foundContinent = tableData.find(item => item.id === id);
    setSelectedContinent(foundContinent);
    setDescription1(foundContinent?.description || '');
    setShow1(true);
    setEyeClicked(true);
  };


  const handleEyeClick1 = (id) => {
    const foundRegion = tableData1.find(item => item.id === id);

    if (foundRegion) {
      const { description, account } = foundRegion;
      setSelectedRegion(id);
      setDescription1(description || '');
      setSelectedContinent1(account?.id || '');
      // Check if roles is not undefined and has at least one element

      setShow1(true);
      setEyeClicked(true);
    } else {
      console.error(`User with id ${id} not found`);
    }
  };

  // const handleEyeClick1 = (id) => {
  //   // Find the selected grade from tableData using the id
  //   const foundRegion = tableData.find(item => item.id === id);
  //   setSelectedRegion(foundRegion);
  //   setDescription1(foundRegion?.description || '');
  //   setSelectedContinent1(foundRegion?.continent_id || '');
  //   setShow1(true);
  //   setEyeClicked(true);
  // };

  const handleEyeClick2 = (id) => {
    const foundProvince = tableData2.find(item => item.id === id);
    setSelectedProvince(foundProvince);
    setDescription1(foundProvince?.description || '');
    setShow1(true);
    setEyeClicked(true);
  };

  const handleTrashClick = async (id) => {
    const confirmResult = await Swal.fire({
      title: 'Are you sure?',
      text: 'You are about to delete this continent. This action cannot be undone.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    });

    if (confirmResult.isConfirmed) {
      try {
        const response = await axios.get(`${BASE_URL}/continents/delete_continent?id=${id}`, { headers });
        fetchContinents();
        toast.success(response.data.message);
        setTrashClicked(true);
      } catch (error) {
        let errorMessage = 'An error occurred. Please try again.';
        if (error.response && error.response.data && error.response.data.message) {
          if (typeof error.response.data.message === 'string') {
            errorMessage = error.response.data.message;
          } else if (Array.isArray(error.response.data.message)) {
            errorMessage = error.response.data.message.join('; ');
          } else if (typeof error.response.data.message === 'object') {
            errorMessage = JSON.stringify(error.response.data.message);
          }
          toast.error(errorMessage);
        }
      }
    } else {

      Swal.fire({
        icon: 'info',
        title: 'Cancelled',
        text: 'The deletion was cancelled.',
      });
    }
  };

  const handleTrashClick1 = async (id) => {
    const confirmResult = await Swal.fire({
      title: 'Are you sure?',
      text: 'You are about to delete this type. This action cannot be undone.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    });

    if (confirmResult.isConfirmed) {
      try {
        const response = await axios.get(`${BASE_URL}/receivable-types/delete?id=${id}`, { headers });
        fetchRegions();
        toast.success(response.data.message);
        setTrashClicked(true);
      } catch (error) {
        let errorMessage = 'An error occurred. Please try again.';
        if (error.response && error.response.data && error.response.data.message) {
          if (typeof error.response.data.message === 'string') {
            errorMessage = error.response.data.message;
          } else if (Array.isArray(error.response.data.message)) {
            errorMessage = error.response.data.message.join('; ');
          } else if (typeof error.response.data.message === 'object') {
            errorMessage = JSON.stringify(error.response.data.message);
          }
          toast.error(errorMessage);
        }
      }
    } else {

      Swal.fire({
        icon: 'info',
        title: 'Cancelled',
        text: 'The deletion was cancelled.',
      });
    }
  };

  const handleTrashClick2 = async (id) => {
    const confirmResult = await Swal.fire({
      title: 'Are you sure?',
      text: 'You are about to delete this province. This action cannot be undone.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    });

    if (confirmResult.isConfirmed) {
      try {
        const response = await axios.get(`${BASE_URL}/provinces/delete_province?id=${id}`, { headers });

        toast.success(response.data.message);
        setTrashClicked(true);
      } catch (error) {
        let errorMessage = 'An error occurred. Please try again.';
        if (error.response && error.response.data && error.response.data.message) {
          if (typeof error.response.data.message === 'string') {
            errorMessage = error.response.data.message;
          } else if (Array.isArray(error.response.data.message)) {
            errorMessage = error.response.data.message.join('; ');
          } else if (typeof error.response.data.message === 'object') {
            errorMessage = JSON.stringify(error.response.data.message);
          }
          toast.error(errorMessage);
        }
      }
    } else {

      Swal.fire({
        icon: 'info',
        title: 'Cancelled',
        text: 'The deletion was cancelled.',
      });
    }
  };



  const editRegion = async () => {
    setLoading(true);
    console.log(description1, selectedContinent1, selectedRegion)
    try {
      const response = await axios.post(
        `${BASE_URL}/receivable-types/update`,
        {
          description: description1,
          gl_code: selectedContinent1,
          id: selectedRegion,
          // accountant_name: accountantName1,
          // accountant_email: accountantEmail1,
          // accountant_phone_number: accountantPhone1

        },
        { headers }
      );

      fetchRegions();
      handleClose1();
      toast.success(response.data.message);

      // console.log(response.data);
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        toast.error(errorMessage);
      }
    } finally {
      setLoading(false);
    }
  };



  return (
    <div>
      <ReceivablesDash />

      <div className={classes.finishedbodyCont}>
        <div className={classes.topPadding}>
          <div className={`${classes.formSecCont}`}>
            <div className={classes.formSectionHeader}>
              <h3>Receivable Types</h3>
            </div>
            <div className={classes.formSectionHeader}>
              <h3 style={{ color: '#2D995F' }}>{user.toLocaleUpperCase()}</h3>
              {/* <p>{userType}</p> */}
            </div>
          </div>
        </div>


        <div className={classes.mainform}>

          <div className={classes.loandgrantcards}>

            <div className={classes.loandethead}>
              <div className={classes.formLabel}>
              </div>
              <div className={classes.formIntBtn}>
                <Button variant="success" onClick={handleShow} className={classes.btn2}> Add New</Button>
              </div>
            </div>
            <Modal show={show} onHide={handleClose} animation={false}>
              <Modal.Header closeButton>
                <Modal.Title>Add New</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form style={{ marginTop: 20 }}>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">

                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Description"

                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                    <div style={{ marginTop: 20 }} />
                    <Form.Label>GL Account</Form.Label>
                    <Select
                      // value={selectedContinent}
                      className={classes.select}
                      onChange={(selectedOption) => handleContinentChange(selectedOption)}
                      options={selectOptions}
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        menu: (provided) => ({
                          ...provided,
                          maxHeight: '300px',
                          overflowY: 'auto',
                        }),
                      }}
                    />

                    {/* <Form.Select
                                className="form-control"
                                value={selectedContinent}
                                onChange={handleContinentChange}
                              >
                                <option value="" disabled>Select Account</option>
                                {tableData.map((item) => (
                                  <option key={item.id} value={item.id}>
                                    {item.gl_name}
                                  </option>
                                ))}
                              </Form.Select> */}


                    {/* <div style={{ marginTop: 10 }} />
                            <Form.Label>Accountant Name</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter Accountant Name"
                              
                              value={accountantName}
                              onChange={(e) => setAccountantName(e.target.value)}
                            /> */}
                    {/* <div style={{ marginTop: 10 }} />
                            <Form.Label>Accountant Phone Number</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter Accountant Phone Number"
                              
                              value={accountantPhone}
                              onChange={(e) => setAccountantPhone(e.target.value)}
                            /> */}
                    {/* <div style={{ marginTop: 10 }} />
                            <Form.Label>Accountant Email Address</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter Accountant Email Address"
                              
                              value={accountantEmail}
                              onChange={(e) => setAccountantEmail(e.target.value)}
                            /> */}

                  </Form.Group>
                </Form>
              </Modal.Body>



              <Modal.Footer>
                <Button variant="danger" onClick={handleClose}>
                  Go back
                </Button>
                <Button variant="success" onClick={createRegion}>
                  {createRegionLoading ? (
                    <>
                      <Spinner size='sm' />
                      <span style={{ marginLeft: '5px' }}>Creating, Please wait...</span>
                    </>
                  ) : (
                    "Create"
                  )}
                </Button>
              </Modal.Footer>
            </Modal>
            <Modal show={show1} onHide={handleClose1} animation={false}>
              <Modal.Header closeButton>
                <Modal.Title>Update Type</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form style={{ marginTop: 20 }}>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Description"

                      value={description1}
                      onChange={(e) => setDescription1(e.target.value)}
                    />
                    <div style={{ marginTop: 10 }} />
                    <Form.Label>GL Account</Form.Label>

                    <Form.Select
                      className="form-control"
                      value={selectedContinent1}
                      onChange={handleContinentChange1}
                    >
                      <option value="" disabled>Select Account</option>
                      {tableData.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.gl_name}
                        </option>
                      ))}
                    </Form.Select>



                    {/* <div style={{ marginTop: 10 }} />
                            <Form.Label>Accountant Name</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter Accountant Name"
                              
                              value={accountantName1}
                              onChange={(e) => setAccountantName1(e.target.value)}
                            /> */}
                    {/* <div style={{ marginTop: 10 }} />
                            <Form.Label>Accountant Phone Number</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter Accountant Phone Number"
                              
                              value={accountantPhone1}
                              onChange={(e) => setAccountantPhone1(e.target.value)}
                            /> */}
                    {/* <div style={{ marginTop: 10 }} />
                            <Form.Label>Accountant Email Address</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter Accountant Email Address"
                              
                              value={accountantEmail1}
                              onChange={(e) => setAccountantEmail1(e.target.value)}
                            />          */}

                  </Form.Group>
                </Form>
              </Modal.Body>






              <Modal.Footer>
                <Button variant="danger" onClick={handleClose1}>
                  Go back
                </Button>

                <Button variant="success" onClick={editRegion} >
                  {loading ? (<> <Spinner size='sm' />
                    <span style={{ marginLeft: '5px' }}>
                      Updating, Please wait...
                    </span>
                  </>) : ("Save Changes")}
                </Button>
              </Modal.Footer>
            </Modal>




            <div className={`d-flex justify-content-between align-items-center ${classes.btnStyle}`}>
              <div className={`${classes.greenbtn} ${classes.btndivStyle}`}>
                <div className={classes.actionsBtns}>
                  <Button variant='success'>Copy</Button>
                  <Button variant='success'>Excel</Button>
                  <Button variant='success'>PDF</Button>
                  <Button variant='success'>Column visibility</Button>
                </div>
                <div className={classes.show}>
                  <label className="d-flex justify-content-start align-items-center">
                    Show
                    <select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" className="custom-select custom-select-sm form-control form-control-sm"
                    //  value={entriesPerPage}
                    //     onChange={(e) => {
                    //     setEntriesPerPage(parseInt(e.target.value));
                    //     setCurrentPage(1);
                    //     }}
                    >
                      <option value={10}>10</option>
                      <option value={25}>25</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                    </select>
                    entries
                  </label>
                </div>
              </div>

              <div className="text-right modal-effect">
                <div id="DataTables_Table_0_filter" className="dataTables_filter">
                  <div className="d-flex justify-content-start align-items-center">
                    <div className="mr-2">Search:</div>
                    <input
                      type="search"
                      value={searchTerm1}
                      className="form-control form-control-sm"
                      placeholder=""
                      aria-controls="DataTables_Table_0"
                      onChange={(e) => setSearchTerm1(e.target.value)}
                    />
                    {/* <Button style={{marginLeft: 10}} variant="success" onClick= {() => fetchSearch(searchTerm)}>
                 {searchLoading ? (
                     <>
                     <Spinner  size='sm' /> 
                    
   </>
 ) : (
               "Search"
             )}
                 </Button> */}
                  </div>


                </div>
              </div>
            </div>
            <div className={classes.mainTable}>
              {regionLoading ? (
                <p>Fetching data...</p>
              ) : (
                <div className="table-responsive">
                  <table className="table display table-bordered table-striped table-hover bg-white m-0 card-table">

                    <thead style={{ whiteSpace: 'nowrap' }}>
                      <tr>
                        <th>ID</th>
                        <th>Description</th>
                        <th>GL Account</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody style={{ whiteSpace: 'nowrap', }}>

                      {displayedData1.map((item, index) => (
                        <tr key={index}>
                          <td style={{ paddingLeft: 10 }}>{index + 1}</td>
                          <td style={{ paddingLeft: 10 }}>{item.description}</td>
                          <td style={{ paddingLeft: 10 }}>{item.account?.gl_name}</td>
                          <td>
                            <div onClick={() => handleEyeClick1(item.id)} className="btn btn-success-soft btn-sm mr-1">
                              <i className="far fa-eye" style={{ color: "#008a4b", backgroundColor: "#28a7451a", padding: 2, borderColor: "#28a7454d", borderRadius: 5, fontSize: 12 }}></i>
                            </div>
                            <div onClick={() => handleTrashClick1(item.id)} className="btn btn-danger-soft btn-sm">
                              <i className="far fa-trash-alt" style={{ color: "#dc3545", backgroundColor: "#dc35451a", padding: 2, borderColor: "#dc35454d", borderRadius: 5, fontSize: 12 }}></i>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>

            <div className={classes.endded}>
              <p>
                Showing {startIndexx1} to {endIndexx1} of {totalEntries1} entries
              </p>
              <div style={{ display: 'flex' }}>
                <button
                  style={{ border: 'none', backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'nunito', color: '#000', marginRight: 10, cursor: "pointer" }}
                  onClick={handlePrevPage1}
                  disabled={currentPage1 === 1}
                >
                  Previous
                </button>
                {[...Array(totalPages1)].map((_, page) => {
                  // Show only 5 pages or less if available
                  if (page < 5 || page === currentPage1 - 1 || page === totalPages1 - 1) {
                    return (
                      <button
                        key={page + 1}
                        style={{
                          marginLeft: '0.4rem',
                          marginRight: '0.4rem',
                          fontSize: '14px',
                          fontFamily: 'nunito',
                          fontWeight: 400,
                          color: page + 1 === currentPage1 ? '#ffffff' : '#000',
                          backgroundColor: page + 1 === currentPage1 ? '#28a745' : 'gainsboro',
                          height: '2.5rem',
                          borderRadius: '89px',
                          padding: '0.5rem',
                          border: 'none',
                          width: '40px',
                          cursor: "pointer"
                        }}
                        onClick={() => setCurrentPage1(page + 1)}
                      >
                        {page + 1}
                      </button>
                    );
                  }
                  return null;
                })}
                <button
                  style={{ cursor: "pointer", border: 'none', backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'nunito', color: '#000', marginLeft: 10 }}
                  onClick={handleNextPage1}
                  disabled={currentPage1 === totalPages1}
                >
                  Next
                </button>
              </div>
            </div>

          </div>
        </div>

      </div>






    </div >
  )
}