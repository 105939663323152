import React, { useState, useEffect } from 'react';
import classes from './CreateUser.module.css';
// import RegLogo from '../../Images/RegistrationLogo.svg'
import { Spinner, Badge, Button, Modal, Form, Row, Col } from 'react-bootstrap';
// import Folder from '../../Images/folder-2.svg';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
// import ArrowLogo from '../../Images/arrow-left.svg';
// import LoanImage from '../../Images/loan bg.svg';
import MainDashoard from '../Main Dashboard/MainDashoard';
// import Ready from '../../Images/nothing.svg'
// import Ready1 from '../../Images/review.svg';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import Table from 'react-bootstrap/Table';
import { BASE_URL } from '../api/api';
import { toast, ToastContainer } from 'react-toastify';
import Swal from 'sweetalert2';
import ToggleSlider from './ToggleSlider';
import CoopDashboard from '../Cooperative Dashboard/CoopDashboard';
import ReportDashboard from '../ReportDashboard/ReportDashboard';


export default function EditUser() {
  const location = useLocation();
const { userData } = location.state || {};

  const [loading, setLoading] = useState(false);
  const [createLoading, setCreateLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [bearer, setBearer] = useState('');
  const [user, setUser] = useState('');
  const navigate = useNavigate();
  const [permissions, setPermissions] = useState([]);
  const [toggleStates, setToggleStates] = useState({});
  const [toggleStates1, setToggleStates1] = useState({});
  const [perm, setPerm] = useState([]);
  const [permId, setPermId] = useState([]);
  const [checkAll, setCheckAll] = useState(false);
  const [roleLoading, setRoleLoading] = useState(false);
  const [role1, setRole1] = useState("");
  const [eyeClicked, setEyeClicked] = useState(false);
  const [selectedRoleId, setSelectedRoleId] = useState(null);
  // const { selectedPermission, setSelectedPermission} = useState([userData?.permissions || []]);
  const [role, setRole] = useState('');
  const [fullName, setFullName] = useState(userData?.name || "");
  const [phoneNumber, setPhoneNumber] = useState(userData?.phone_number || "");
  const [email, setEmail] = useState(userData?.email  || "");
  const [selectType, setSelectType] = useState(userData?.type || "");
  const [userType, setUserType] = useState([]);
  const [continents, setContinents] = useState([]);
  const [tableData4, setTableData4] = useState([]);
  const [tableData5, setTableData5] = useState([]);
  const [selectedContinent, setSelectedContinent] = useState(userData?.continent_id || "");
  const [permLoading, setPermLoading] = useState(false);
  const [selectedProvince, setSelectedProvince] = useState(userData?.province_id || "");
  const [selectedRegion, setSelectedRegion] = useState(userData?.region_id || "");
  const [contLoading, setContLoading] = useState(false);
  const [subContloading, setSubContLoading] = useState(false);
  const [subRegloading, setSubRegLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [tableData, setTableData] = useState([]);

  const userPerm = userData?.permissions || [];

const selectedPermission = userPerm.map(permission => permission.id);



  // console.log(userData);

  const handleTypeChange = (e) => setSelectType(e.target.value);

  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem('userToken');
      const value1 = await AsyncStorage.getItem('tobi');

      if (value !== null) {
        setBearer(value);
      }
      if (value1 !== null) {
        setUser(value1);
      }
    } catch (e) {
      alert('Failed to fetch the input from storage');
    }
  };

  useEffect(() => {
    readData();
  }, []);

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${bearer}`
    };

    const fetchPermission = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${BASE_URL}/role/permissions`, { headers });
        const data = response.data?.data;
        // console.log(selectedPermission, data);
        const permissionId = data.map(item => item.id);
        setPermId(permissionId);
      
        // Move the logic for setting initial toggle states here
        const initialToggleStates = Object.fromEntries(
          data.map((permission) => [
            permission.id,
            selectedPermission?.includes(permission.id),
          ])
        );
        // console.log(initialToggleStates, "neww");
        setPermissions(data);
        setToggleStates(initialToggleStates);
      } catch (error) {
        const errorStatus = error.response?.data?.message;
        console.error(errorStatus);
        setPermissions([]);
      } finally {
        setIsLoading(false);
      }
    };
    
    // console.log(toggleStates, "ffff");
    useEffect(() => {
      if (bearer) {
        fetchPermission();
      }
    }, [bearer]);
    
   
      const handleToggleChange = (itemId) => {
        setToggleStates(prevToggleStates => ({
          ...prevToggleStates,
          [itemId]: !prevToggleStates[itemId],
        }));
      };
    
      const handleCheckAllToggle = () => {
        const checkAllValue = !checkAll;
        setCheckAll(checkAllValue);
    
        // Set all toggle states to the determined value
        const updatedToggleStates = Object.fromEntries(permId.map(id => [id, checkAllValue]));
        setToggleStates(updatedToggleStates);
      };
    
      const handleToggleChange1 = (itemId) => {
        setToggleStates1((prevToggleStates) => ({
          ...prevToggleStates,
          [itemId]: !prevToggleStates[itemId],
        }));
      };
    
    
     
     
    
      const goBack = () => {
        navigate(-1);
      }
    
      const fetchData1 = async () => {
        setIsLoading(true);
        try {
          const response = await axios.get(`${BASE_URL}/all-usertype`, { headers });
          const typeList = response.data?.data;
          console.log(response);
          setUserType(typeList);
        } catch (error) {
          if (error.response && error.response.status === 401) {
            // Redirect to login page if unauthorized
            navigate('/login');
          } else {
            const errorStatus = error.response?.data?.message;
            console.log(errorStatus);
            setUserType([]);
          }
        } finally {
          setIsLoading(false);
        }
      };
    
      useEffect(() => {
        if (bearer) {
          fetchData1();
        }
      }, [bearer]);
    
    
     
    
      const fetchContinents = async () => {
        setContLoading(true);
        try {
          const response = await axios.get(`${BASE_URL}/continents`, { headers });
          const contList = response.data?.data;
          // console.log("continents:", contList);
          setContinents(contList);
        } catch (error) {
          if (error.response && error.response.status === 401) {
            // Redirect to login page if unauthorized
            navigate('/login');
          } else {
            const errorStatus = error.response?.data?.message;
            console.log(errorStatus);
            setTableData([]);
          }
        } finally {
          setContLoading(false);
        }
      };
    
      const fetchSubContinents = async (selectedContinent) => {
        setSubContLoading(true);
        try {
          const response = await axios.get(`${BASE_URL}/continents/getcontinentregions?continent_id=${selectedContinent}`,{ headers });
          // console.log(response);
          const subCont = response.data?.data;
          setTableData4(subCont);
          console.log(subCont, "heeeer");
        } catch (error) {
          let errorMessage = 'An error occurred. Please try again.';
                  if (error.response && error.response.data && error.response.data.message) {
                      if (typeof error.response.data.message === 'string') {
                          errorMessage = error.response.data.message;
                      } else if (Array.isArray(error.response.data.message)) {
                          errorMessage = error.response.data.message.join('; ');
                      } else if (typeof error.response.data.message === 'object') {
                          errorMessage = JSON.stringify(error.response.data.message);
                      }
                  }
          setTableData4([]);
        } finally {
          setSubContLoading(false);
        }
      };
    
      useEffect(() => {
        if (bearer && selectedContinent) {
          fetchSubContinents(selectedContinent);
        }
      }, [bearer, selectedContinent]);
    
      const fetchSubRegion = async (selectedRegion) => {
        setSubRegLoading(true);
        try {
          const response = await axios.get(`${BASE_URL}/regions/getprovincesregion?region_id=${selectedRegion}`,{ headers });
          // console.log(response);
          const subReg = response.data?.data;
          setTableData5(subReg);
          console.log(subReg, "heeeer");
        } catch (error) {
          let errorMessage = 'An error occurred. Please try again.';
                  if (error.response && error.response.data && error.response.data.message) {
                      if (typeof error.response.data.message === 'string') {
                          errorMessage = error.response.data.message;
                      } else if (Array.isArray(error.response.data.message)) {
                          errorMessage = error.response.data.message.join('; ');
                      } else if (typeof error.response.data.message === 'object') {
                          errorMessage = JSON.stringify(error.response.data.message);
                      }
                  }
          setTableData5([]);
        } finally {
          setSubRegLoading(false);
        }
      };
    
      useEffect(() => {
        if (bearer && selectedRegion) {
          fetchSubRegion(selectedRegion);
        }
      }, [bearer, selectedRegion]);
    
    
    
      useEffect(() => {
        if (bearer) {
          fetchContinents();
        }
      }, [bearer]);
    
    
      const handleContinentChange = (event) => {
        setSelectedContinent(event.target.value);
    };
    
    const handleRegionChange = (event) => {
      setSelectedRegion(event.target.value);
    };
    
    const handleProvinceChange = (event) => {
      setSelectedProvince(event.target.value);
    };
    
    
    const updateUser = async () => {
      setCreateLoading(true);
      try {
        const selectedToggle = permissions
          .filter((item) => toggleStates[item.id])
          .map((item) => item.name);
    
          console.log(fullName, email, phoneNumber, selectType, selectedToggle);
    
        const response = await axios.post(
          `${BASE_URL}/users/update-user`,
          {
            name: fullName,
            email: email,
            phone_no: phoneNumber,
            type: selectType,
            module: selectedToggle,
            user_id: userData?.id
          },
          { headers }
        );
        console.log(response)
        
        navigate(-1);
        setFullName('');
        setPhoneNumber('');
        setEmail('');
        setSelectType('');
        // return
      toast.success(response.data.message);
        console.log(response.data);
    
      } catch (error) {
        let errorMessage = 'An error occurred. Please try again.';
        if (error.response && error.response.data && error.response.data.message) {
          if (typeof error.response.data.message === 'string') {
              errorMessage = error.response.data.message;
          } else if (Array.isArray(error.response.data.message)) {
              errorMessage = error.response.data.message.join('; ');
          } else if (typeof error.response.data.message === 'object') {
              errorMessage = JSON.stringify(error.response.data.message);
          }
          toast.error(errorMessage);
          console.log(error);
      }
      } finally {
        setCreateLoading(false);
      }
    };
    

    return (
        <div>
          <ToastContainer />
        <ReportDashboard />

        <div className={classes.finishedbodyCont}>
            <div className={classes.topPadding}>
                <div className={`${classes.formSecCont}`}>
                    <div className={classes.formSectionHeader}>
                        <h3>Update Role</h3>
                    </div>
                    <div className={classes.formSectionHeader}>
                        <h3 style={{color:'#2D995F'}}>{user.toLocaleUpperCase()}</h3>
                    </div>
                </div>
            </div>
            

            <div className={classes.mainform}>
        <div className={classes.loandgrantcards}>
          <div className={classes.loanContainer}>
            <div className={classes.loanResponsive}>
              <div className={classes.mainForm}>
                <Form>
                  <Row className="mb-3">
                    <Col md={6}>
                      <Form.Group controlId="formName">
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                          type="text"
                          value={fullName}
                          onChange={(e) => setFullName(e.target.value)}
                          placeholder="Enter Full name"
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group controlId="formPhoneNumber">
                        <Form.Label>Phone Number</Form.Label>
                        <Form.Control
                          type="text"
                          value={phoneNumber}
                          onChange={(e) => setPhoneNumber(e.target.value)}
                          placeholder="Enter Phone Number"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col md={6}>
                      <Form.Group controlId="formEmail">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          type="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          placeholder="Enter Email"
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group controlId="formSelectType">
                        <Form.Label>Select Type</Form.Label>
                        <Form.Select
                          value={selectType}
                          onChange={handleTypeChange}
                        >
                          <option value="">Select Type</option>
                          {userType.map((item) => (
                                  <option key={item.id} value={item.id}>
                                    {item.type}
                                  </option>
                                ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  </Row>
                  
                  {selectType >= 2 && (
  <Row className="mb-3">
    <Col md={6}>
      <Form.Group controlId="formContinent">
        <Form.Label>Select Continent</Form.Label>
        <Form.Select
          className="form-control"
          value={selectedContinent}
          onChange={handleContinentChange}
        >
          <option value="" disabled>
            Select Continent
          </option>
          {continents.map((item) => (
            <option key={item.id} value={item.id}>
              {item.description}
            </option>
          ))}
        </Form.Select>
      </Form.Group>
    </Col>

    {selectType >= 3 && (
      <Col md={6}>
        <Form.Group controlId="formRegion">
          <Form.Label>Select Region</Form.Label>
          <Form.Select
            className="form-control"
            value={selectedRegion}
            onChange={handleRegionChange}
          >
            <option value="" disabled>
              Select Region
            </option>
            {tableData4.map((item) => (
              <option key={item.id} value={item.id}>
                {item.description}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      </Col>
    )}
  </Row>
)}

{selectType >= 4 && (
  <Row className="mb-3">
    <Col md={6}>
      <Form.Group controlId="formProvince">
        <Form.Label>Select Province</Form.Label>
        <Form.Select
          className="form-control"
          value={selectedProvince}
          onChange={handleProvinceChange}
        >
          <option value="" disabled>
            Select Province
          </option>
          {tableData5.map((item) => (
            <option key={item.id} value={item.id}>
              {item.description}
            </option>
          ))}
        </Form.Select>
      </Form.Group>
    </Col>
  </Row>
)}
                
                </Form>

                <div className='modal-footer' style={{ marginTop: 40 }} />
                <div style={{ display: "flex", gap: 5 }}>
                  <ToggleSlider checked={checkAll} onChange={handleCheckAllToggle} />
                  <p>Check All</p>
                </div>
                <div className='modal-footer' style={{ marginTop: 20 }} />
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: 5, alignItems: "center", justifyContent: "center" }}>
                  {permLoading ? (
                    <Spinner size='sm' animation="border" role="status" />
                  ) : (
                    permissions.map((item, index) => (
                      <div
                        key={index}
                        style={{
                          width: '120px',
                          height: '120px',
                          margin: '5px',
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: '8px',
                          backgroundColor: '#fff',
                          border: '1px solid rgba(0, 0, 0, 0.2)',
                          boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.2)',
                          boxSizing: 'border-box',
                          marginBottom: 20,
                        }}
                      >
                        <p style={{ fontSize: 13.5, margin: '5px 0', textAlign: "center" }}>{item.name}</p>
                        <ToggleSlider
                          checked={toggleStates[item.id]}
                          onChange={() => handleToggleChange(item.id)}
                        />
                      </div>
                    ))
                  )}
                </div>
              </div>
            </div>
            <div className={`${classes.formIntBtn} ${classes.formIntBtn2}`}>
              <Button variant="light" className={classes.btn1} onClick={goBack}>Cancel</Button>
              <Button variant="success" className={classes.btn2} onClick={updateUser}>
                {createLoading ? (
                  <>
                    <Spinner size='sm' />
                    <span style={{ marginLeft: '5px' }}>Updating user, Please wait...</span>
                  </>
                ) : (
                  "Update User"
                )}
              </Button>
            </div>
          </div>
        </div>
      </div>


        </div>

    </div>
)
}