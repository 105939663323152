import React, { useState, useEffect } from 'react';
import classes from '../Process-General-ledger/ProcessGeneral.module.css';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useLocation } from 'react-router-dom';

export default function PrintSales() {
  const [bearer, setBearer] = useState('');
  const [company, setCompany] = useState('');
  const location = useLocation();
  const [currentDateTime, setCurrentDateTime] = useState('');
  const [totalDebit, setTotalDebit] = useState('');
  const [totalCredit, setTotalCredit] = useState('');
  const [continent, setContinent] = useState('');
  const [region, setRegion] = useState('');
  const [province, setProvince] = useState('');
  const [userType, setUserType] = useState('');

  useEffect(() => {
    const addLeadingZero = (number) => (number < 10 ? `0${number}` : number);

    const currentDate = new Date();
    const day = currentDate.getDate();
    const month = addLeadingZero(currentDate.getMonth() + 1);
    const year = currentDate.getFullYear();
    const minutes = addLeadingZero(currentDate.getMinutes());
    const hours = addLeadingZero(currentDate.getHours() % 12 || 12);
    const seconds = addLeadingZero(currentDate.getSeconds());
    const ampm = currentDate.getHours() >= 12 ? 'PM' : 'AM';

    const formattedDateTime = `${day}/${month}/${year} `;

    setCurrentDateTime(formattedDateTime);
  }, []);

  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem('userToken');
      const value1 = await AsyncStorage.getItem('companyName');
      const admins = await AsyncStorage.getItem('admin');
      const continentName = await AsyncStorage.getItem('continent');
            const regionName = await AsyncStorage.getItem('region');
            const provinceName = await AsyncStorage.getItem('province');

      if (value !== null) {
        setBearer(value);
      }
      if (value1 !== null) {
        setCompany(value1);
        console.log(company);
      }
      if (admins !== null) {
        setUserType(admins);
      }
      if (continentName !== null) {
        setContinent(continentName);
      }
      if (regionName !== null) {
        setRegion(regionName);
      }
      if (provinceName !== null) {
        setProvince(provinceName);
      }
    } catch (e) {
      alert('Failed to fetch the input from storage');
    }
  };

  useEffect(() => {
    readData();
  }, []);

  const { document, selectedDate, selectedEndDate } = location.state || {};
  console.log(selectedDate, selectedEndDate, document, "printing");
  // const startDate = new Date(inputs.start_date);
  // const endDate = new Date(inputs.end_date);

  const getOrdinalSuffix = (number) => {
    const suffixes = ['th', 'st', 'nd', 'rd'];
    const v = number % 100;
    return v >= 11 && v <= 13
      ? 'th'
      : suffixes[v % 10] || 'th';
  };

  function formattedDates(dateString) {
    const date = new Date(dateString);
    const formattedDate = `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())}`;
    return formattedDate;
  }

  function padZero(num) {
    return num < 10 ? `0${num}` : num;
  }

  useEffect(() => {
    if (document) {
      const debitTotal = document.reduce((total, item) => total + parseFloat(item.amount), 0);
      // const creditTotal = document.reduce((total, item) => total + parseFloat(item.credit), 0);

      const formattedDebitTotal = debitTotal.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
      // const formattedCreditTotal = creditTotal.toLocaleString('en-US', {
      //   minimumFractionDigits: 2,
      //   maximumFractionDigits: 2
      // });

      setTotalDebit(formattedDebitTotal);
      // setTotalCredit(formattedCreditTotal);
    }
  }, [document]);

  function formatDate(dateString) {
    const date = new Date(dateString);
    const formattedDate = `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())} `;
    return formattedDate;
  }

  const allNamesSame = document.every(doc => doc.account?.gl_name === document[0].account?.gl_name);
  const displayName = allNamesSame ? document[0].account?.gl_name : "All Accounts";

  const title = selectedDate && selectedEndDate 
    ? `SALES REPORT BETWEEN ${selectedDate} AND ${selectedEndDate}` 
    : `SALES REPORT AS AT ${currentDateTime}`;

  return (
    <div className={classes.a4}>
    <div className={classes.report}>
        <h2 className={classes.headerTop}>{company.toLocaleUpperCase()}</h2>
        {/* <h4 className={classes.headerTwoo}>{accounts?.name}</h4> */}
        <h4 className={classes.headerTwo}> {title}</h4>

        <table className={classes.tableDivF}>
            <thead>
                <tr className={classes.tableDivTr}>
                    <th style={{ backgroundColor: '#181717', color: 'white', fontSize: '14px', textAlign: 'left', padding: '10px' }}>TRANSACTION DATE</th>
                    <th style={{ backgroundColor: '#333232', color: 'white', fontSize: '14px', textAlign: 'left', padding: '10px' }}>INVOICE NUMBER</th>
                    <th style={{ backgroundColor: '#333232', color: 'white', fontSize: '14px', textAlign: 'left', padding: '10px', width: '50px', }}>CUSTOMER</th>
                    <th style={{ backgroundColor: '#333232', color: 'white', fontSize: '14px', textAlign: 'left', padding: '10px', width: '50px',  }}>DESCRIPTION</th>
                    <th style={{ backgroundColor: '#333232', color: 'white', fontSize: '14px', textAlign: 'left', padding: '10px' }}>DISCOUNT</th>
                    <th style={{ backgroundColor: '#757373', color: 'white', fontSize: '14px', textAlign: 'right',  padding: '10px'}}>AMOUNT</th>
                </tr>
            </thead>
            <tbody className={classes.tableBodyFirst}>
            {document && document.map((item, index) => (
                    <tr key={index}>
                      <td>{item.transaction_date}</td>
                      <td style={{width: '100px'}}>{item.invoice_number}</td>
                      <td style={{width: '200px'}}>{item.customer?.name}</td>
                      <td style={{width: '200px'}}>{item.description}</td>
                      <td >{item?.discount === null ? "" : item?.discount}</td>
                      <td style={{ textAlign: "right",  }}>{parseFloat(item.amount).toLocaleString('en-US', {
                        minimumIntegerDigits: 1,
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })}</td>
                      
                    </tr>
                  ))}

            </tbody>
            <thead className={classes.bottomHeader}>
                <tr>
                    <td style={{ border: '0' }}></td>
                    <td style={{ border: '0' }}></td>
                    <td style={{ border: '0' }}></td>
                    <td style={{ border: '0' }}></td>
                    <td style={{ color: 'black', fontSize: '16px', textAlign: 'right', padding: '2px', fontWeight: 900 }}>TOTAL</td>
                    <td style={{ backgroundColor: '#535252', color: 'white', fontSize: '16px', textAlign: 'right', width: '100px', padding: '2px' }}>{totalDebit}</td>
                </tr>
            </thead>
        </table>

    </div>
    </div>
  );
}
