import React, { useState, useEffect } from "react";
import classes from "./CompletedOrder.module.css";
import {
  Spinner,
  Badge,
  Button,
  Modal,
  Form,
  Alert,
  Tab,
  Tabs,
} from "react-bootstrap";
import axios from "axios";
import Arrow from "../../../assets/promix/dArrow-down.svg";
import AsyncStorage from "@react-native-async-storage/async-storage";
import CurrencyInput from "react-currency-input-field";
import { Link, useNavigate, useParams } from "react-router-dom";
import Table from "react-bootstrap/Table";
import { BASE_URL } from "../../api/api";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import Select from "react-select";
import { MdDirectionsBike } from "react-icons/md";
// import MainDashoard from '../../Main Dashboard/MainDashoard';
import LatestDash from "../../LatestDashboard/LatestDash";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import OrderNav from "../../../Component/OrderNav/OrderNav";

export default function CompletedOrder() {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const handleClose = () => setShow(false);
  const handleClose1 = () => setShow1(false);
  const [stat, setStat] = useState([]);
  const handleShow = () => setShow(true);
  const handleShow1 = () => setShow1(true);
  const [bearer, setBearer] = useState("");
  const [user, setUser] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [roleLoading, setRoleLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [eyeClicked, setEyeClicked] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [tableData12323, setTableData12323] = useState([]);
  const [tableData5, setTableData5] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [trashClicked, setTrashClicked] = useState(false);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [entriesPerPage, setEntriesPerPage] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [admin, setAdmin] = useState("");
  const [archiveData, setArchivedData] = useState([]);
  const [tellerNumber, setTellerNumber] = useState("");
  const [roless, setRoless] = useState([]);
  const [totalAmountPaid, setTotalAmountPaid] = useState(0);
  const [totalAmountOuts, setTotalAmountOuts] = useState(0);
  const [outstanding, setOutstanding] = useState("");
  const [totalAmount, setTotalAmount] = useState("");
  const [amountToPay, setAmountToPay] = useState("");
  const [selectedDebitAccount, setSelectedDebitAccount] = useState("");
  const [selectedInvoice, setSelectedInvoice] = useState("");
  const [description, setDescription] = useState("");
  const [amount, setAmount] = useState("");
  const [tableData2, setTableData2] = useState([]);
  const [paymentMode, setPaymentMode] = useState([]);
  const [newData, setNewData] = useState([]);

  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem("userToken");
      const value1 = await AsyncStorage.getItem("tobi");
      const value2 = await AsyncStorage.getItem("permissions");
      const value3 = await AsyncStorage.getItem("admin");

      if (value !== null) {
        setBearer(value);
      }
      if (value1 !== null) {
        setUser(value1);
      }
      if (value2 !== null) {
        setPermissions(value2);
      }
      if (value3 !== null) {
        setAdmin(value3);
      }
    } catch (e) {
      alert("Failed to fetch the input from storage");
    }
  };

  useEffect(() => {
    readData();
  }, []);

  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${bearer}`,
  };

  console.log(bearer);

  const fetchData = async () => {
    setRoleLoading(true);
    try {
      const response = await axios.get(
        `${BASE_URL}/sale_inventory/get-all-approved`,
        { headers }
      );
      const results = response.data?.data;
      console.log(results);
      setTableData(results);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate("/login");
      } else {
        const errorStatus = error.response?.data?.message;
        console.log(errorStatus);
        setTableData([]);
      }
    } finally {
      setRoleLoading(false);
    }
  };

  const handleDebitChange = (event) => {
    setSelectedDebitAccount(event.target.value);
  };
  useEffect(() => {
    if (bearer) {
      fetchData();
    }
  }, [bearer]);

  const filteredData = tableData?.filter((item) =>
    item.customer?.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const totalPages = Math.ceil(filteredData.length / entriesPerPage);

  const handlePrevPage = () => {
    setCurrentPage(Math.max(currentPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage(Math.min(currentPage + 1, totalPages));
  };

  const totalEntries = filteredData.length;
  const startIndexx = (currentPage - 1) * entriesPerPage + 1;
  const endIndexx = Math.min(startIndexx + entriesPerPage - 1, totalEntries);
  const displayedData = filteredData.slice(startIndexx - 1, endIndexx);

  const handlePrintInvoice = (orderKey, products) => {
    console.log(products);
    navigate("/print_receipts2", { state: { products, sICode: orderKey } });
  };

  const handleSalesChange = (selectedOption) => {
    const selectedId = selectedOption.value;

    setSelectedInvoice(selectedOption.value);

    const intselectedId = parseInt(selectedId);
    const selectedInvoice = newData.find((item) => item.id == intselectedId);
    setDescription(selectedInvoice?.description || "");
    setAmount(selectedInvoice?.amount || "");
    setOutstanding(selectedInvoice?.balance || "");
  };

  const formattedAmount = isNaN(parseFloat(amount))
    ? "0.00"
    : parseFloat(amount).toLocaleString("en-US", {
        minimumIntegerDigits: 1,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });

  const formattedOutstanding = isNaN(parseFloat(outstanding))
    ? "0.00"
    : parseFloat(outstanding).toLocaleString("en-US", {
        minimumIntegerDigits: 1,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });

  useEffect(() => {
    // Calculate the total amount paid
    const total = displayedData.reduce(
      (sum, item) => sum + parseFloat(item.amount_paid || 0),
      0
    );
    setTotalAmountPaid(total);
  }, [displayedData]);

  useEffect(() => {
    // Calculate the total amount paid
    const total12 = displayedData.reduce(
      (sum, item) => sum + parseFloat(item.balance || 0),
      0
    );
    setTotalAmountOuts(total12);
  }, [displayedData]);

  const handleValueChange = (value, name, values) => {
    setAmountToPay(value);
  };

  const createPayment = async () => {
    setPaymentLoading(true);

    try {
      let requestData = {
        id: selectedInvoice,
        debit_gl_code: selectedDebitAccount,
        // credit: selectedCreditAccount,
        amount: amountToPay || "",
      };
      console.log(selectedInvoice, requestData);
      const response = await axios.post(
        `${BASE_URL}/pay-sales-invoice`,
        requestData,
        { headers }
      );

      toast.success(response.data.message);
      fetchData();

      //  fetchArchivedData();

      handleClose();

      setSelectedInvoice("");
      setAmountToPay("");
      setSelectedDebitAccount("");
      setDescription("");
      setAmount("");
      setTotalAmount("");
      setOutstanding("");
    } catch (error) {
      let errorMessage = "An error occurred. Please try again.";
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        if (typeof error.response.data.message === "string") {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join("; ");
        } else if (typeof error.response.data.message === "object") {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        toast.error(errorMessage);
        console.log(errorMessage);
      }
    } finally {
      setPaymentLoading(false);
    }
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const formattedDate = `${date.getFullYear()}-${padZero(
      date.getMonth() + 1
    )}-${padZero(date.getDate())} ${padZero(date.getHours())}:${padZero(
      date.getMinutes()
    )} ${date.getHours() >= 12 ? "PM" : "AM"}`;
    return formattedDate;
  }

  function padZero(num) {
    return num < 10 ? `0${num}` : num;
  }

  return (
    <div>
      <OrderNav />

      <div
        className={classes.finishedbodyCont}
        style={{ backgroundColor: "white" }}
      >
        <div className={classes.mainform}>
          {/* <h3 className={classes.productText1}>Sales Invoice</h3> */}
          <div className={classes.laondetheadTopadfCards}>
            <div className={classes.laondetheadTopCards121}>
              <h3
                style={{
                  fontSize: "30px",
                  fontWeight: 700,
                  margin: " -35px 10px 0  5px",
                }}
              >
                Completed Transaction
              </h3>
              <p style={{ margin: " 0 10px 20px 7px" }}>View and Print Transaction</p>
            </div>
          </div>
          {/* <h3 style={{fontSize: 30, fontWeight: 700, margin:" 10px 10px 20px  2rem" }}>Sales Invoice</h3> */}
          <div style={{ backgroundColor: "#F8FFFB" }}>
            <div className={classes.loandgrantcards}>
              <div className={classes.loandethead}>
                {/* <div className={classes.formLabel}>
                </div> */}
                <div className={classes.laondetheadTopCards}></div>
              </div>

              <Modal
                show={show}
                onHide={handleClose}
                animation={false}
                size="lg"
              >
                <Modal.Header closeButton>
                  <Modal.Title>Sales Invoice Payment</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form style={{ marginTop: 20 }}>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Sales Invoice ID:</Form.Label>
                      <Select
                        options={roless}
                        placeholder="Select Invoice"
                        onChange={handleSalesChange}
                        menuPortalTarget={document.body}
                        styles={{
                          width: "100%",
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          menu: (provided) => ({
                            ...provided,
                            maxHeight: "300px",
                            overflowY: "auto",
                          }),
                        }}
                      />

                      <Form.Label style={{ marginTop: 20 }}>
                        Description
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        disabled
                        placeholder="Enter Description"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                      />

                      <div className="row" style={{ marginTop: 20 }}>
                        <div className="col-md-6">
                          <Form.Label>Total Amount</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Total Amount"
                            disabled
                            value={formattedAmount}
                            onChange={(e) => setAmount(e.target.value)}
                          />
                        </div>
                        <div className="col-md-6">
                          <Form.Label>Outstanding</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Outstanding"
                            disabled
                            value={formattedOutstanding}
                            onChange={(e) => setOutstanding(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="row" style={{ marginTop: 20 }}>
                        <div className="col-md-6">
                          <Form.Label>Payment Mode</Form.Label>
                          <Form.Select
                            value={paymentMode}
                            onChange={(e) => setPaymentMode(e.target.value)}
                          >
                            <option value="" disabled>
                              Select Payment Mode
                            </option>
                            {tableData5.map((item) => (
                              <option key={item.id} value={item.id}>
                                {item.name}
                              </option>
                            ))}
                          </Form.Select>
                        </div>

                        <div className="col-md-6">
                          <Form.Label>Teller Number</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Teller Number"
                            value={tellerNumber}
                            onChange={(e) => setTellerNumber(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="row" style={{ marginTop: 20 }}>
                        <div className="col-md-6">
                          <Form.Label>Amount</Form.Label>
                          <CurrencyInput
                            name="amount-to-pay"
                            decimalsLimit={2}
                            className="form-control"
                            value={amountToPay}
                            onValueChange={handleValueChange}
                            style={{
                              textAlign: "right",
                              border: "1px solid #e4e4e4",
                              backgroundColor: "none",
                            }}
                          />
                        </div>
                        <div className="col-md-6">
                          <Form.Label>Debit Account</Form.Label>
                          <Form.Select
                            value={selectedDebitAccount}
                            onChange={handleDebitChange}
                          >
                            <option value="" disabled>
                              Select Debit Account
                            </option>
                            {tableData2.map((item) => (
                              <option key={item.id} value={item.id}>
                                {item.gl_name}
                              </option>
                            ))}
                          </Form.Select>
                        </div>
                      </div>
                    </Form.Group>
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="danger" onClick={handleClose}>
                    Go back
                  </Button>
                  <Button variant="success" onClick={createPayment}>
                    {paymentLoading ? (
                      <>
                        <Spinner size="sm" />
                        <span style={{ marginLeft: "5px" }}>
                          Processing, Please wait...
                        </span>
                      </>
                    ) : (
                      "Make Payment"
                    )}
                  </Button>
                </Modal.Footer>
              </Modal>

              <div eventKey="Print" title="Print">
                <div
                  className={`d-flex justify-content-between align-items-center ${classes.btnStyle}`}
                >
                  {/* <div className={`${classes.greenbtn} ${classes.btndivStyle}`}>
                    <div className={classes.actionsBtns}>
                      <Button variant="success">Copy</Button>
                      <Button variant="success">Excel</Button>
                      <Button variant="success">PDF</Button>
                      <Button variant="success">Column visibility</Button>
                    </div>
                    <div className={classes.show}>
                      <label className="d-flex justify-content-start align-items-center">
                        Show
                        <select
                          name="DataTables_Table_0_length"
                          aria-controls="DataTables_Table_0"
                          className="custom-select custom-select-sm form-control form-control-sm"
                          
                        >
                          <option value={10}>10</option>
                          <option value={25}>25</option>
                          <option value={50}>50</option>
                          <option value={100}>100</option>
                        </select>
                        entries
                      </label>
                    </div>
                  </div> */}
                  {/* <div className="text-right modal-effect ">
                    <div
                      id="DataTables_Table_0_filter"
                      className="dataTables_filter"
                    >
                      <div className="d-flex justify-content-end align-items-end">
                        <div className="mr-2">Search:</div>
                        <input
                          type="search"
                          value={searchTerm}
                          className="form-control form-control-sm"
                          placeholder=""
                          aria-controls="DataTables_Table_0"
                          onChange={(e) => setSearchTerm(e.target.value)}
                        />
                      </div>
                    </div>
                  </div> */}
                </div>
                <div className={classes.mainTable}>
                  {roleLoading ? (
                    <p>Fetching orders...</p>
                  ) : (
                    <div className="table-responsive">
                      <table className="table display table-bordered table-striped table-hover bg-white m-0 card-table">
                        <thead style={{ whiteSpace: "nowrap" }}>
                          <tr>
                            <th>S/N</th>
                            <th>Transaction No</th>
                            <th style={{ textAlign: "right", paddingLeft: 4 }}>
                              Total Amount
                            </th>
                            <th style={{ textAlign: "right", paddingLeft: 4 }}>
                              Total Quantity
                            </th>
                            <th style={{ textAlign: "right", paddingLeft: 4 }}>
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody style={{ whiteSpace: "nowrap" }}>
                          {tableData.map((item, index) => {
                            // Get the order key (Transaction No)
                            const orderKey = Object.keys(item)[0];
                            const orderDetails = item[orderKey];

                            // Extract relevant data for the table
                            // {parseFloat(orderDetails.total_amount).toLocaleString()}
                            const totalAmount =
                              parseFloat(
                                orderDetails.total_amount
                              ).toLocaleString() || "0.00";
                            const totalQuantity =
                              orderDetails.product_details?.length || 0;
                            const paymentStatus =
                              orderDetails.payment_status || "N/A";
                            const status = orderDetails.status || "N/A";

                            return (
                              <tr key={index}>
                                {/* Serial Number */}
                                <td>{index + 1}</td>

                                {/* Transaction No (Order ID) */}
                                <td>{orderKey}</td>

                                {/* Total Amount */}
                                <td
                                  style={{ textAlign: "right", paddingLeft: 4 }}
                                >
                                  {totalAmount}
                                </td>

                                {/* Total Quantity (Number of products in product_details array) */}
                                <td
                                  style={{ textAlign: "right", paddingLeft: 4 }}
                                >
                                  {totalQuantity}
                                </td>
                                <td
                                  style={{ textAlign: "right", paddingLeft: 4 }}
                                >
                                  <div
                                    onClick={() =>
                                      handlePrintInvoice(
                                        orderKey,
                                        orderDetails.product_details
                                      )
                                    }
                                    className="btn btn-sm printbtninv"
                                  >
                                    <i
                                      className="fa fa-print dawg"
                                      style={{
                                        color: "#17a2b8",
                                        backgroundColor: "#afe1e9",
                                        padding: 2,
                                        borderColor: "#b0d1d6",
                                        borderRadius: 5,
                                        fontSize: 12,
                                      }}
                                    ></i>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>

                <div className={classes.endded}>
                  <p>
                    Showing {startIndexx} to {endIndexx} of {totalEntries}{" "}
                    entries
                  </p>
                  <div style={{ display: "flex" }}>
                    <button
                      style={{
                        border: "none",
                        backgroundColor: "gainsboro",
                        borderRadius: 3,
                        height: "2.5rem",
                        width: "100px",
                        fontWeight: 500,
                        fontSize: 14,
                        padding: "0.5rem",
                        fontFamily: "nunito",
                        color: "#000",
                        marginRight: 10,
                        cursor: "pointer",
                      }}
                      onClick={handlePrevPage}
                      disabled={currentPage === 1}
                    >
                      Previous
                    </button>
                    {[...Array(totalPages)].map((_, page) => {
                      // Show only 5 pages or less if available
                      if (
                        page < 5 ||
                        page === currentPage - 1 ||
                        page === totalPages - 1
                      ) {
                        return (
                          <button
                            key={page + 1}
                            style={{
                              marginLeft: "0.4rem",
                              marginRight: "0.4rem",
                              fontSize: "14px",
                              fontFamily: "nunito",
                              fontWeight: 400,
                              color:
                                page + 1 === currentPage ? "#ffffff" : "#000",
                              backgroundColor:
                                page + 1 === currentPage
                                  ? "#28a745"
                                  : "gainsboro",
                              height: "2.5rem",
                              borderRadius: "89px",
                              padding: "0.5rem",
                              border: "none",
                              width: "40px",
                              cursor: "pointer",
                            }}
                            onClick={() => setCurrentPage(page + 1)}
                          >
                            {page + 1}
                          </button>
                        );
                      }
                      return null;
                    })}
                    <button
                      style={{
                        cursor: "pointer",
                        border: "none",
                        backgroundColor: "gainsboro",
                        borderRadius: 3,
                        height: "2.5rem",
                        width: "100px",
                        fontWeight: 500,
                        fontSize: 14,
                        padding: "0.5rem",
                        fontFamily: "nunito",
                        color: "#000",
                        marginLeft: 10,
                      }}
                      onClick={handleNextPage}
                      disabled={currentPage === totalPages}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
