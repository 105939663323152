import React, { useState, useEffect } from 'react';
import classes from '../Reportts/RevenewReceipt.module.css'
import { useLocation } from 'react-router-dom';

function RevenueReceipt() {


    const location = useLocation();
    const { selectedLedger} = location.state || {};
    

    console.log(selectedLedger, "Datas")

    const convertToWords = (number) => {
        const units = ['', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine'];
        const teens = [
          '',
          'Eleven',
          'Twelve',
          'Thirteen',
          'Fourteen',
          'Fifteen',
          'Sixteen',
          'Seventeen',
          'Eighteen',
          'Nineteen',
        ];
        const tens = ['', 'Ten', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];
      
        const numToWords = (num) => {
          if (num === 0) return '';
          if (num < 10) return units[num];
          if (num < 20) return teens[num - 10];
          if (num < 100) return tens[Math.floor(num / 10)] + (num % 10 !== 0 ? ' ' + units[num % 10] : '');
          if (num < 1000)
            return units[Math.floor(num / 100)] + ' Hundred' + (num % 100 !== 0 ? ' ' + numToWords(num % 100) : '');
          if (num < 1000000)
            return numToWords(Math.floor(num / 1000)) + ' Thousand' + (num % 1000 !== 0 ? ' ' + numToWords(num % 1000) : '');
          return 'Number too large to convert';
        };
      
        const [integerPart, decimalPart] = number.toFixed(2).split('.');
        const integerWords = parseInt(integerPart, 10) !== 0 ? numToWords(parseInt(integerPart, 10)) + ' Naira' : '';
        const decimalWords = parseInt(decimalPart, 10) !== 0 ? ' ' + numToWords(parseInt(decimalPart, 10)) + ' Kobo' : '';
      
        const result = (integerWords + (integerWords && decimalWords ? ',' : '') + decimalWords).trim();
      
        return result;
      };



    return (
        <div className={classes.mainBodyss}>
            <div className={classes.container} style={{width:'70'}}>
                {/* <div className={classes.bckgroundicon}>
                    <img src={'https://th.bing.com/th/id/R.d1a09df98c52144086459a9a451ba97c?rik=iN7mo4kpq8DDNg&pid=ImgRaw&r=0'} ></img>
                </div> */}
                
            
                <div className={classes.thediv}> <div className={classes.heads}>
                    <h1>The Redeemed Christian Church</h1>
                    <h2>National Secretariat</h2>
                    <h3>OFFICIAL RECEIPT</h3>
                </div>  
                <div className={classes.firstrow}>
                    <div className={classes.inputdata} style={{display:"flex", justifyContent:'space-between', alignItems:'center'}}>
                        <label className={classes.dLabel}>Date</label>
                        <p style={{width:'150px'}}>{selectedLedger?.transaction_date}</p>
                        {/* <input className={`${classes.styled_input} ${classes.styledInp}`} value={selectedLedger?.transaction_date} /> */}
                    </div>
                    <div className={classes.inputdata} style={{display:"flex", justifyContent:'space-between', alignItems:'center'}}>
                        <label className={classes.dLabel}htmlFor='number'>No.</label>
                        <p style={{width:'150px'}}>{selectedLedger?.id}</p>
                        {/* <input className={classes.styled_input} type='number1' id='number' value={selectedLedger?.id}/> */}
                    </div>
                </div>

                <div className={classes.secondRow}>
                    <div className={classes.inputdata} style={{display:"flex", justifyContent:'space-between', alignItems:'center'}}>
                        <label className={classes.cLabel}>Received From</label>
                        <p style={{width:'150px'}}>{selectedLedger?.particular}</p>
                        {/* <input className={classes.styled_input} type='text4' id='receive' value={selectedLedger?.particular} /> */}
                    </div>
                    <div className={classes.inputdata}>
                        <label htmlFor='amount'>Amount</label>
                        <p style={{width:'150px'}}>
                        {parseFloat(selectedLedger.amount).toLocaleString('en-US', {
                            minimumIntegerDigits: 1,
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          })}
                        </p>
                        
                    </div>

                </div>
                
                <div className={classes.inputD}>
                    <label className={classes.bLabel} htmlFor='amtword'>Amount in Words</label>
                    <input className={classes.styled_input} type='number5' id='amtword' value={convertToWords(parseFloat(selectedLedger?.amount))} />
                </div>
        
                <div className={classes.inputD}>
                    <label className={classes.aLabel} htmlFor='money'>For Payment of</label>
                    <input className={classes.styled_input} type='text1' id='money' value={selectedLedger?.description} />
                </div>
                <div className={classes.inputdata} style={{display:"flex", justifyContent:'space-between', alignItems:'center'}}>
                    <label className={classes.dLabel} htmlFor='teller'>Teller/ Cheque No</label>
                    <p>{selectedLedger?.teller_number}</p>
                    {/* <input className={classes.styled_input} type='text2' id='teller' value={selectedLedger?.teller_number}/>  */}

                </div>
                <div className={classes.inputdata} style={{display:"flex", justifyContent:'space-between', alignItems:'center'}}>
                    <label className={classes.aLabel} htmlFor='Rec'>Received By</label>
                    <p>{selectedLedger?.user?.name}</p>
                    {/* <input className={classes.styleinputD} type='text3' id='Rec' value={selectedLedger?.user?.name} />  */}

                </div>

                </div>
                
                



            </div>
        </div>

    )
}
export default RevenueReceipt;