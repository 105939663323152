// import React from 'react'
import classes from './UploadBal.module.css'
import Fullbullets from './BulletTable.svg';
import Backarrow from './Arrwbck.svg';
import { ProgressBar } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate, useLocation, Link } from 'react-router-dom';
import { Navbar, Nav, NavDropdown, Button, Modal, Form, Spinner, Badge } from 'react-bootstrap';
import CurrencyInput from 'react-currency-input-field';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Swal from 'sweetalert2';
import { BASE_URL } from '../api/api';
import { toast } from 'react-toastify';
import Select from 'react-select';
import Editimg from './Editimg.svg';
import Deleteimg from './Deleteimg.svg';
import Addimg from './Addimg.svg';

const UploadBalancessssss = () => {
  const navigate = useNavigate();
  const [bearer, setBearer] = useState('');
  const [tableData, setTableData] = useState([]);
  const [tableData1, setTableData1] = useState([]);
  const [tableData2, setTableData2] = useState([]);
  const [benBank, setBenBank] = useState([]);
  const [formData, setFormData] = useState([{ sn: 1, beneficiary: '', beneficiaryAccount: '', debitGlCode: '', creditGlCode: '', amount: '', }]);
  const [selectOptions, setSelectOptions] = useState([]);
  const [selectOptions1, setSelectOptions1] = useState([]);
  const [selectOptions2, setSelectOptions2] = useState([]);
  const [totalAmount, setTotalAmount] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [load, setLoad] = useState(false);
  const [loadd, setLoadd] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [selectedFile, setSelectedFile] = useState(null);
  const [user, setUser] = useState('');
  const [description, setDescription] = useState('');
  const [selectedDate, setSelectedDate] = useState('');

  const [userTypes, setUserTypes] = useState('');
  const [region, setRegion] = useState('');
  const [province, setProvince] = useState('');
  const [continent, setContinent] = useState('');
  const [admin, setAdmin] = useState('');

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem('userToken');
      const value1 = await AsyncStorage.getItem('tobi');
      const value2 = await AsyncStorage.getItem('admin');
      const admins = await AsyncStorage.getItem('admin');

      const continentName = await AsyncStorage.getItem('continent');
      const regionName = await AsyncStorage.getItem('region');
      const provinceName = await AsyncStorage.getItem('province');

      if (value !== null) {
        setBearer(value);
      }
      if (value1 !== null) {
        setUser(value1);
      }
      if (value2 !== null) {
        setAdmin(value2);
      }

      if (admins !== null) {
        setUserTypes(admins);
      }
      if (continentName !== null) {
        setContinent(continentName);
      }
      if (regionName !== null) {
        setRegion(regionName);
      }
      if (provinceName !== null) {
        setProvince(provinceName);
      }
    } catch (e) {
      alert('Failed to fetch the input from storage');
    }
  };

  useEffect(() => {
    readData();
  }, []);


  const uploadExcel = async () => {
    setUploadLoading(true);
    try {
      const formData = new FormData();
      formData.append('file', selectedFile[0]);

      // console.log(selectedFile);

      const headers = {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${bearer}`,
      };

      const response = await axios.post(
        'https://api-sme.promixaccounting.com/api/v1/payment_voucher/post-journal',
        formData,
        { headers }
      );
      handleClose();
      navigate('/pending_payment_voucher');
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: response.data.message,
      });

      console.log(response.data.message);
    } catch (error) {
      const errorStatus = error.response.data.message;

      Swal.fire({
        icon: 'error',
        title: 'Failed',
        text: errorStatus,
      });

      console.error(error);
    } finally {
      setUploadLoading(false);
    }
  };


  const handlePosting = async () => {
    setLoadd(true);
    try {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${bearer}`
      };
      const beneficiaries = formData.map((row) => row.beneficiary.value).filter((id) => id !== undefined);
      const beneficiaryAccounts = formData.map((row) => row.beneficiaryAccount.value).filter((id) => id !== undefined);
      const debits = formData.map((row) => row.debitGlCode.value).filter((id) => id !== undefined);
      const credits = formData.map((row) => row.creditGlCode.value).filter((id) => id !== undefined);
      const amounts = formData.map((row) => row.amount).filter((name) => name !== undefined);

      console.log(beneficiaries, debits, credits, amounts, "problem");
      const response = await axios.post(
        `${BASE_URL}/payment_voucher/post-bulk-journal`,
        {
          beneficiary_id: beneficiaries,
          beneficiary_account_id: beneficiaryAccounts,
          debit_gl: debits,
          credit_gl: credits,
          credit: amounts
        },
        { headers }
      );


      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: response.data.message,
      });

    } catch (error) {
      const errorStatus = error.response.data.message;

      Swal.fire({
        icon: 'error',
        title: 'Failed',
        text: errorStatus,
      });
      // navigate('/pending_payment_voucher');

      console.error(error);
    } finally {
      setLoadd(false);
    }
  };
  const fetchBenAcct = async (selectedBeneficiary) => {
    setLoading(true);

    try {
      const response = await axios.get(
        `${BASE_URL}/beneficiaryaccounts/getaccount?beneficiary_id=${selectedBeneficiary}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${bearer}`,
          },
        }
      );

      const paid = response.data?.data || [];
      const benAcc = paid.map((item) => ({
        label: item.bank_name,
        value: item.id,
      }));
      console.log(paid, 'paid');
      setBenBank(benAcc);
    } catch (error) {
      const errorStatus = error.response.data.message;
      console.error(errorStatus);
    } finally {
      setLoading(false);
    }
  };


  const handleFormChange = (selectedOption, fieldName, rowIndex) => {
    if (fieldName === 'beneficiary') {
      fetchBenAcct(selectedOption.value);
    }

    setFormData(prevFormData => {
      const updatedFormData = [...prevFormData];
      updatedFormData[rowIndex] = {
        ...updatedFormData[rowIndex],
        [fieldName]: selectedOption
      };
      return updatedFormData;
    });
  };


  const deleteRow = (index) => {
    const updatedData = formData.filter((_, i) => i !== index);
    setFormData(updatedData);
  };





  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${bearer}`
  };

  const fetchBeneficiaries = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/beneficiary`, { headers });
      const results = response.data?.data;

      const options = results.map((item) => ({
        label: item.name,
        value: item.id,
      }));

      setTableData(results);
      setSelectOptions(options);
    } catch (error) {
      const errorStatus = error.response?.data?.message;
      console.log(errorStatus);
      setTableData([]);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchDebit = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/account`, { headers });
      const debitAcct = response.data?.data;

      const options1 = debitAcct.map((item) => ({
        label: item.gl_name,
        value: item.id,
      }));

      setTableData1(debitAcct);
      setSelectOptions1(options1);
    } catch (error) {
      const errorStatus = error.response?.data?.message;
      console.log(errorStatus);
      setTableData1([]);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchAsset = async () => {
    setLoading(true);

    try {
      const response = await axios.get(
        `${BASE_URL}/accounts`,
        {

          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearer}`
          }
        }
      );
      const assetsAcc = response.data?.data;
      const options2 = assetsAcc.map((item) => ({
        label: item.gl_name,
        value: item.id,
      }));

      setTableData2(assetsAcc);
      setSelectOptions2(options2);


    } catch (error) {
      const errorStatus = error.response.data.message;
      console.error(errorStatus);
    } finally {
      setLoading(false);
    }
  };




  useEffect(() => {
    if (bearer) {
      fetchBeneficiaries();
      fetchDebit();
      fetchAsset();

    }
  }, [bearer]);




  const addRow = () => {
    const newRow = {
      sn: formData.length + 1,
      beneficiary: '',
      beneficiaryAccount: '',
      debitGlCode: '',
      creditGlCode: '',
      amount: '',
    };

    setFormData(prevFormData => [...prevFormData, newRow]);
  };

  const handleValueChange1 = (value, index) => {
    const updatedFormData = [...formData];
    updatedFormData[index] = {
      ...updatedFormData[index],
      amount: value,
    };
    setFormData(updatedFormData);
  };

  const handleFileChange = (event) => {
    const files = event.target.files;
    const fileList = Array.from(files);
    setSelectedFile(fileList);

  };

  const calculateTotalAmount = () => {
    const total = formData.reduce((total, row) => total + parseFloat(row.amount) || 0, 0);
    setTotalAmount(total);
    // console.log(totalQuantity);
  };

  useEffect(() => {
    calculateTotalAmount();
  }, [formData]);

    

  return (
    <div className={classes.maincont1234}>
      <div className={classes.sideNavContainer}>
        <h4> Set up your <br /> Charts of Account</h4>
        <p> Easily set up your chart of Account </p>
      </div>

      <div className={classes.formSection}>
        <div className={classes.textMain}>
          <p>Complete your account setup</p>
          <Link to={'/chartofAccount'} ><div className={classes.normdsfdfalp}>
            <img src={Backarrow} alt='icon' className={classes.Imageshowdfsf213} />
            <p>Back</p>
          </div></Link>
        </div>
        <div className={classes.normdfalp}>

          <img src={Fullbullets} alt='icon' className={classes.Imageshowda213} />

          <div className={classes.formSection2345}>
            <h4>Upload Initial Balances</h4>
            <p className={classes.marginbottomp}> Upload your initial balances</p>
            {/* <img src={TopImage} alt='icon' className={classes.Imageshow213} /> */}
            <div className="table-responsive">
              <table className="table display table-striped table-hover bg-white m-0 card-table">

                <thead className={classes.tableheadbackgroundcolor}>
                  <tr>
                    <th style={{ backgroundColor: "#164B2E", color: "#FFFFFF" }}>S/N</th>
                    <th style={{ width: '22%', backgroundColor: "#164B2E", color: "#FFFFFF" }}>Account Name</th>
                    <th style={{ width: '40%', backgroundColor: "#164B2E", color: "#FFFFFF" }}>Account Code</th>
                    <th style={{ width: '22%', backgroundColor: "#164B2E", color: "#FFFFFF" }}>Category</th>
                    <th style={{ width: '22%', backgroundColor: "#164B2E", color: "#FFFFFF" }}>Direction</th>
                    <th style={{ width: '30%', backgroundColor: "#164B2E", color: "#FFFFFF" }}>Action</th>

                    <th style={{ backgroundColor: "#164B2E", color: "#FFFFFF" }}><div onClick={() => addRow()}>
                      <img src={Addimg} alt='icon' className={classes.Imageshowdfsf213} />
                    </div></th>
                  </tr>
                </thead>
                <tbody style={{ whiteSpace: "nowrap", textAlign: "center", alignItems: "center" }}>
                  {formData.map((row, index) => (
                    <tr key={index}>
                      <td style={{ textAlign: 'left', fontSize: "12px" }}>{row.sn}</td>
                      <td style={{ textAlign: 'left', fontSize: "12px" }}>
                        <Form.Control type="text" placeholder="Enter account code" />
                      </td>
                      <td style={{ width: "30px", textAlign: 'left', fontSize: "12px" }}>
                        <Form.Control type="text" placeholder="Enter amount" />
                      </td>
                      <td>
                        <Select />
                      </td>
                      <td>
                        <Select
                          options={selectOptions1}
                          onChange={(selectedOption) => handleFormChange(selectedOption, 'creditGlCode', index)}
                          menuPortalTarget={document.body}
                          styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                            menu: (provided) => ({
                              ...provided,
                              maxHeight: '300px',
                              overflowY: 'auto',
                            }),
                          }}
                        />
                      </td>
                      <td>
                        <img src={Editimg} alt='icon' className={classes.Imageshowdfsf213} />
                        {/* <Select
                            options={selectOptions1}
                            onChange={(selectedOption) => handleFormChange(selectedOption, 'creditGlCode', index)}
                            menuPortalTarget={document.body}
                            styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                            menu: (provided) => ({
                                ...provided,
                                maxHeight: '300px',
                                overflowY: 'auto',
                            }),
                            }}
                        /> */}
                        {/* <CurrencyInput
                            name={`rowAmount ${index}`} // Provide a unique name for each CurrencyInput
                            decimalsLimit={2}
                            value={row.amount}
                            className="form-control"
                            onValueChange={(value) => handleValueChange1(value, index)}
                            style={{ textAlign: "right", border: "none" }}
                        /> */}
                        {/* <input
                            type="text"
                            className="form-control"
                            value={row.amount}
                            onChange={(e) => handleFormChange(index, 'amount', e.target.value)}
                            style={{ textAlign: "right" }}
                        /> */}
                      </td>

                      <td style={{ textAlign: "left" }}>
                        <div onClick={() => deleteRow(index)}>
                          <img src={Deleteimg} alt='icon' className={classes.Imageshowdfsf213} />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

            </div>
            <Link to={'/AccountNew'} ><Button variant="success" className={classes.snupbtndfa} >
              Next
            </Button></Link>
          </div>
        </div>
      </div>
    </div>

  )
};


export default UploadBalancessssss;