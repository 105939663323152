import React, { useState, useEffect } from 'react';
// import "../assets/plugins/bootstrap/css/bootstrap.min.css";
// import "../assets/plugins/metisMenu/metisMenu.min.css";
// import "../assets/plugins/fontawesome/css/all.min.css";
// import "../assets/plugins/typicons/src/typicons.min.css";
// import "../assets/plugins/themify-icons/themify-icons.min.css";
// import "../assets/plugins/datatables/dataTables.bootstrap4.min.css";
// import "../style.css";
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import { Navbar, Nav, NavDropdown, Button, Modal, Form, Spinner, Badge } from 'react-bootstrap';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Swal from 'sweetalert2';
// import { InfoFooter } from '../../InfoFooter';
// import { AdminHeaderNav } from '../AdminHeaderNav';
// import classes from './CreateSales.module.css';
import classes from '../Manage Cooperatives/Manage Members/ManageMember.module.css'
import MainDashboard from '../Main Dashboard/MainDashoard';
import { BASE_URL } from '../api/api';
import { toast } from 'react-toastify';
import CurrencyInput from 'react-currency-input-field';
import Select from 'react-select';
import JournalDash from '../Journal Dashboard/JournalDash';
import LatestDash from '../LatestDashboard/LatestDash';
// import { BASE_URL } from '../../api/api';
// import classes from './LoanRepayment.module.css'
// import favicon from '../../Images/faviconn.png'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';


function CreateJournal() {
  const navigate = useNavigate();
  const [bearer, setBearer] = useState('');
  const [tableData, setTableData] = useState([]);
  const [formData, setFormData] = useState([{ sn: 1, accountName: '', accountCode: '', DR_CR: '', amount: '', description: '', date: '' }]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectOptions, setSelectOptions] = useState([]);
  const [totalAmountCredit, setTotalAmountCredit] = useState('');
  const [totalAmountDebit, setTotalAmountDebit] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [load, setLoad] = useState(false);
  const [user, setUser] = useState(false);
  const [isEqual, setIsEqual] = useState(false);
  const [userType, setUserType] = useState('');
  const [continent, setContinent] = useState('');
  const [region, setRegion] = useState('');
  const [province, setProvince] = useState('');

  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem('userToken');
      const value1 = await AsyncStorage.getItem('tobi');
      const admins = await AsyncStorage.getItem('admin');
      const continentName = await AsyncStorage.getItem('continent');
      const regionName = await AsyncStorage.getItem('region');
      const provinceName = await AsyncStorage.getItem('province');

      if (value !== null) {
        setBearer(value);
      }
      if (value1 !== null) {
        setUser(value1);

      }
      if (admins !== null) {
        setUserType(admins);
      }
      if (continentName !== null) {
        setContinent(continentName);
      }
      if (regionName !== null) {
        setRegion(regionName);
      }
      if (provinceName !== null) {
        setProvince(provinceName);
      }
    } catch (e) {
      alert('Failed to fetch the input from storage');
    }
  };
  //   console.log("user:", user)

  useEffect(() => {
    readData();
  }, []);

  // const UserGreeting = ({ user }) => {
  // Ensure `user` is a string
  const userString = typeof user === 'string' ? user : '';


  const calculateTotalAmount = () => {
    const total = formData.reduce((acc, item) => {
      return item.DR_CR === '1' ? acc + parseFloat(item.amount || 0) : acc;
    }, 0);

    const formattedTotal1 = total.toLocaleString('en-US', {
      minimumIntegerDigits: 1,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    setTotalAmountDebit(formattedTotal1);
  };

  const calculateTotalAmountCredit = () => {
    const total = formData.reduce((acc, item) => {
      return item.DR_CR === '2' ? acc + parseFloat(item.amount || 0) : acc;
    }, 0);

    const formattedTotal = total.toLocaleString('en-US', {
      minimumIntegerDigits: 1,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    setTotalAmountCredit(formattedTotal);
  };



  useEffect(() => {
    calculateTotalAmount();
    calculateTotalAmountCredit();
  }, [formData]);

  const handleAccountChange = (selectedOption, rowIndex) => {
    setSelectedOptions((prevOptions) => {
      const updatedOptions = [...prevOptions];
      updatedOptions[rowIndex] = selectedOption;
      return updatedOptions;
    });

    const selectedChart = tableData.find((chart) => chart.id === selectedOption.value);

    if (selectedChart) {
      const updatedFormData = [...formData];
      updatedFormData[rowIndex].accountName = selectedChart.id;
      updatedFormData[rowIndex].accountCode = selectedChart.gl_code;
      setFormData(updatedFormData);
    }
  };



  const deleteRow = (index) => {
    const updatedData = formData.filter((_, i) => i !== index);
    setFormData(updatedData);
  };

  const handleFormChange = (index, field, value) => {
    let formattedValue = value;

    // Format the amount field
    if (field === 'amount' && value !== '') {
      formattedValue = (Number(value.replace(/\D/g, '')) || '').toLocaleString();
    }

    const updatedFormData = [...formData];
    updatedFormData[index][field] = formattedValue;
    setFormData(updatedFormData);
  };



  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${bearer}`
  };

  const fetchCharts = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/account`, { headers });
      const results = response.data?.data;

      const options = results.map((chart) => ({
        label: chart.gl_name,
        value: chart.id,
      }));

      setTableData(results);
      setSelectOptions(options);
    } catch (error) {
      if (error.response && error.response.status === 401) {

        navigate('/login');
      } else {
        const errorStatus = error.response?.data?.message;
        console.log(errorStatus);
        setTableData([]);
      }
    } finally {
      setIsLoading(false);
    }
  };


  useEffect(() => {
    if (bearer) {
      fetchCharts();

    }
  }, [bearer]);




  const addRow = () => {
    const newRow = {
      sn: formData.length + 1,
      accountName: '',
      DR_CR: '',
      amount: '',
      description: '',
      date: '',
    };

    setFormData([...formData, newRow]);
  };




  const createJournal = async () => {
    setLoad(true);

    try {
      const accountNames = formData.map((row) => row.accountName).filter((name) => name !== undefined);
      const directions = formData.map((row) => row.DR_CR).filter((name) => name !== undefined);
      const dates = formData.map((row) => row.date).filter((name) => name !== undefined);
      const descriptions = formData.map((row) => row.description).filter((name) => name !== undefined);
      const amounts = formData.map((row) => row.amount).filter((name) => name !== undefined);
      const creditAmount = totalAmountCredit.replace(/,/g, '').replace(/\.00$/, '');
      const debitAmount = totalAmountDebit.replace(/,/g, '').replace(/\.00$/, '');



      // console.log(accountNames, creditAmount, debitAmount, descriptions, amounts, directions, "information")
      const response = await axios.post(`${BASE_URL}/journal/post-entries`,
        {
          account_id: accountNames,
          all_credit: creditAmount,
          all_debit: debitAmount,
          description: descriptions,
          amount: amounts,
          direction: directions,
          transaction_date: dates
        },
        { headers }
      );
      // console.log(accountNames, creditAmount, debitAmount, descriptions, amounts, directions, "AFTER")
      // console.log(response.data.message, "heeee");

      setFormData([]);
      Swal.fire({
        icon: 'success',
        title: 'Successful',
        text: response.data.message,
      }).then(() => {
        window.location.reload(); 
      });
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          
          console.log(errorMessage);
        }

      }
      Swal.fire({
        icon: 'error',
        title: 'Failed',
        text: JSON.stringify(error.response.data.message),
      });
      //   console.log(error);
    } finally {
      setLoad(false);
    }
  };

  const handleValueChange1 = (value, index) => {
    const updatedFormData = [...formData];
    updatedFormData[index] = {
      ...updatedFormData[index],
      amount: value,
    };
    setFormData(updatedFormData);
  };


  useEffect(() => {
    calculateTotalAmount();
    calculateTotalAmountCredit();
  }, [formData]);

  useEffect(() => {
    // Compare the total debit and total credit values
    setIsEqual(totalAmountDebit === totalAmountCredit);
  }, [totalAmountDebit, totalAmountCredit]);


  const goBack = () => {
navigate(-1);
  }

  return (

    <div>

      <div className="wrapper">
        {/* <!-- Sidebar  --> */}


        {/* <!-- Page Content  --> */}
        <div className="content-wrapper">
          <div className="main-content">

            <LatestDash />
            <div className={classes.finishedbodyCont}>
            <div className='newBody'>
              <div className={classes.newWidth}>

              <h3 style={{fontSize: '30px', fontWeight: 700, margin:" 0 10px 0  20px" }}>Journal Entries</h3>
              <p style={{margin:" 0 10px 20px 20px" }}>Add, View, and Delete Journal Entry</p>
                
                {/* <h3 style={{ marginTop: '1rem', fontSize: 20, fontWeight: 700 }}>Journal Entries</h3> */}


                <div className={classes.topPadding}>
                  <div className={`${classes.formSecCont}`}>
                    <div className={`card-body ${classes.awrow112}`} style={{ border: 'none' }}>


                      <div className={`row ${classes.awrow1}`}>





                        <div className={`row ${classes.awrow1}`}>



                          <div style={{ marginTop: 10 }} />
                          <div className="table-responsive">
                            <table className="table display table-bordered table-striped table-hover bg-white m-0 card-table">

                              <thead style={{ whiteSpace: "nowrap", textAlign: "center", alignItems: "center" }}>
                                <tr>
                                  <th>#</th>
                                  <th style={{ width: '15%', }}>Transaction Date</th>
                                  <th style={{ width: '28%', }}>Account Name</th>
                                  <th>Description</th>
                                  <th style={{ width: '15%', }}>DR/CR</th>
                                  <th style={{ width: '15%', }}>Amount</th>

                                  <th >
                                  <OverlayTrigger
                                    placement="top"
                                    className={classes.btndf1}
                                    overlay={<Tooltip id="button-tooltip"  className={classes.btndf1}>Click here to add new row</Tooltip>}
                                  >
                                    <Button variant="primary" onClick={() => addRow()} style={{ borderRadius: "50px" }}>
                                    <i className="fas fa-plus"></i>
                                  </Button>
                                  </OverlayTrigger>
                                  </th>

                                </tr>
                              </thead>
                              <tbody style={{ whiteSpace: "nowrap", textAlign: "center", alignItems: "center" }}>
                                {formData.map((row, index) => (
                                  <tr key={index}>
                                    <td>{row.sn}</td>
                                    <td>
                                      <input
                                        value={row.date}
                                        onChange={(e) => handleFormChange(index, 'date', e.target.value)}
                                        className="form-control"
                                        type="date"
                                      />
                                    </td>
                                    <td >
                                      <Select
                                        value={selectedOptions[index]}
                                        onChange={(selectedOption) => handleAccountChange(selectedOption, index)}
                                        options={selectOptions}
                                        menuPortalTarget={document.body}
                                        styles={{
                                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                          menu: (provided) => ({
                                            ...provided,
                                            // maxHeight: '200px', 
                                            // overflowY: 'auto',
                                          }),
                                        }}
                                      />

                                    </td>
                                    <td>
                                      <textarea
                                        type="text"
                                        className="form-control"
                                        rows={1}
                                        value={row.description}
                                        onChange={(e) => handleFormChange(index, 'description', e.target.value)}
                                      />
                                    </td>
                                    <td>
                                      <Form.Select
                                        value={row.DR_CR}
                                        onChange={(e) => handleFormChange(index, 'DR_CR', e.target.value)}
                                        className="form-control"
                                      >
                                        <option value="">Select</option>
                                        <option value="1">DR</option>
                                        <option value="2">CR</option>
                                      </Form.Select>
                                    </td>
                                    <td>
                                      <CurrencyInput
                                        name={`rowAmount ${index}`} // Provide a unique name for each CurrencyInput
                                        decimalsLimit={2}
                                        value={row.amount}
                                        className="form-control"
                                        onValueChange={(value) => handleValueChange1(value, index)}
                                        style={{ textAlign: "right", border: "none" }}
                                      />
                                      {/* <input
                                              type="text"
                                              className="form-control"
                                              value={row.amount}
                                              onChange={(e) => handleFormChange(index, 'amount', e.target.value)}
                                              style={{ textAlign: "right" }}
                                            /> */}
                                    </td>


                                    <td style={{ textAlign: "left" }}>
                                    <OverlayTrigger
                                      placement="top"
                                      className={classes.btndf1}
                                      overlay={<Tooltip id="button-tooltip"  className={classes.btndf1}>Click here to delete row</Tooltip>}
                                    >
                                      <Button variant="danger" onClick={() => deleteRow(index)} style={{ borderRadius: "50px" }}>
                                        <i className="far fa-trash-alt"></i>
                                      </Button>
                                    </OverlayTrigger>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>

                          </div>

                        </div>

                        <div style={{ marginTop: 20 }} />
                        <div className={classes.form_align}>
                          <Form.Group className="mb-3">
                            <Form.Label>Total Debit:</Form.Label>
                            <Form.Control
                              type="text"
                              value={totalAmountDebit}
                              name="total"
                              readOnly
                              style={{ textAlign: 'right' }}
                            />
                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Form.Label>Total Credit:</Form.Label>
                            <Form.Control
                              type="text"
                              value={totalAmountCredit}
                              name="total"
                              readOnly
                              style={{ textAlign: 'right' }}
                            />
                          </Form.Group>
                        </div>


                        <div class={`modal-footer ${classes.overlaybtn100}`} style={{ marginTop: '10px', paddingRight: '30px', gap: 20 }}>
                          <Button
                          className={classes.longbtn}
                            variant='danger'
                            onClick={goBack}
                            
                            style={{ padding: '5px 30px' }}>
                           Go back
                          
                          </Button>
                          <Button
                          className={classes.longbtn}
                            variant='success'
                            onClick={createJournal}
                            disabled={!isEqual}
                            style={{ padding: '5px 30px' }}>
                            {load ? (
                              <>
                                <Spinner size='sm' />
                                <span style={{ marginLeft: '5px' }}>Saving, Please wait...</span>
                              </>
                            ) : (
                              "Save Entries"
                            )}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>













            </div>
            </div>
            {/* <!--/.main content--> */}
          </div>
        </div>
        {/* <InfoFooter /> */}
        {/* <!--/.footer content--> */}
        <div className="overlay"></div>
      </div>
      {/* <!--/.wrapper--> */}


    </div>
  

  );
}

export default CreateJournal;