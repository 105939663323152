import React, { useState, useEffect } from 'react';
import classes from "./CashDash.module.css";
// import RegLogo from '../../Images/RegistrationLogo.svg'
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom'
import { Tab, Tabs, Form, Spinner, Nav } from 'react-bootstrap';
import dashIcon from '../../assets/promix/dash-icon1.svg'
import dIcon2 from '../../assets/promix/dIcon2.svg'
import dIcon3 from '../../assets/promix/dIcon3.svg'
import dIcon4 from '../../assets/promix/dIcon4.svg'
import dIcon5 from '../../assets/promix/dIcon5.svg'
import dIcon6 from '../../assets/promix/dIcon6.svg'
import Arrow from '../../assets/promix/dArrow-down.svg'
import Out from '../../assets/promix/dLoginIcon.svg'
import Logo from '../../assets/promix/dLogoWhite.svg'
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useContext } from 'react';
import axios from 'axios';
import Accordion from 'react-bootstrap/Accordion';
import AccordionContext from 'react-bootstrap/AccordionContext';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import Card from 'react-bootstrap/Card';
import { BASE_URL } from '../api/api';
import { toast } from 'react-toastify';

import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { Button } from 'bootstrap';
import LogoutIcon from '../../assets/welcome/log.png'
import LogoIcon from '../../assets/promix/vuesax/patna11.png';



export default function CashDash() {
    const location = useLocation();
    const navigate = useNavigate();
    const [bearer, setBearer] = useState('');
    const [user, setUser] = useState('');
    const [company, setCompany] = useState('');
    const [activeLink, setActiveLink] = useState(null);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isMenuOpen1, setIsMenuOpen1] = useState(false);
    const [isMenuOpen2, setIsMenuOpen2] = useState(false);
    const [isMenuOpen3, setIsMenuOpen3] = useState(false);
    const [isMenuOpen4, setIsMenuOpen4] = useState(false);
    const [isMenuOpen5, setIsMenuOpen5] = useState(false);
    const [isMenuOpen6, setIsMenuOpen6] = useState(false);
    const [isMenuOpen7, setIsMenuOpen7] = useState(false);


    function ContextAwareToggle({ children, eventKey, callback }) {
        const { activeEventKey } = useContext(AccordionContext);

        const decoratedOnClick = useAccordionButton(
            eventKey,
            () => callback && callback(eventKey),
        );

        const isCurrentEventKey = activeEventKey === eventKey;

        return (
            <button
                type="button"
                // style={{ backgroundColor: isCurrentEventKey ? LIGHTGREE : DEEPGREEN }}
                onClick={decoratedOnClick}
            >
                {children}
            </button>
        );
    }

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen); // Toggle the menu open/close state
    };
    const toggleMenu1 = () => {
        setIsMenuOpen1(!isMenuOpen1); // Toggle the menu open/close state
    };
    const toggleMenu2 = () => {
        setIsMenuOpen2(!isMenuOpen2); // Toggle the menu open/close state
    };
    const toggleMenu3 = () => {
        setIsMenuOpen3(!isMenuOpen3); // Toggle the menu open/close state
    };
    const toggleMenu4 = () => {
        setIsMenuOpen4(!isMenuOpen4); // Toggle the menu open/close state
    };
    const toggleMenu5 = () => {
        setIsMenuOpen5(!isMenuOpen5); // Toggle the menu open/close state
    };
    const toggleMenu6 = () => {
        setIsMenuOpen6(!isMenuOpen6); // Toggle the menu open/close state
    };
    const toggleMenu7 = () => {
        setIsMenuOpen7(!isMenuOpen7); // Toggle the menu open/close state
    };


    const closeMenu = () => {
        setIsMenuOpen(false); // Close the menu
    };

    const readData = async () => {
        try {
            const value = await AsyncStorage.getItem('userToken');
            const value1 = await AsyncStorage.getItem('tobi');
            //   const value2 = await AsyncStorage.getItem('admin');
            //   const admins = await AsyncStorage.getItem('admin');

            //   const continentName = await AsyncStorage.getItem('continent');
            //   const regionName = await AsyncStorage.getItem('region');
            //   const provinceName = await AsyncStorage.getItem('province');

            if (value !== null) {
                setBearer(value);
            }
            if (value1 !== null) {
                setUser(value1);
            }
            //   if (value2 !== null) {
            //     setAdmin(value2);
            //   }

            //       if (admins !== null) {
            //         setUserTypes(admins);
            //     }
            //     if (continentName !== null) {
            //       setContinent(continentName);
            //   }
            //   if (regionName !== null) {
            //       setRegion(regionName);
            //   }
            //   if (provinceName !== null) {
            //       setProvince(provinceName);
            //   }
        } catch (e) {
            alert('Failed to fetch the input from storage');
        }
    };

    useEffect(() => {
        readData();

    }, []);

    useEffect(() => {
        readData();
    }, []);


    const handleLogout = async () => {
        setLoading(true);

        try {
            const response = await axios.post(
                `${BASE_URL}/logout`,
                {},
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${bearer}`
                    }
                }
            );

            navigate('/login');
            //   toast.success(response.data.message);
        } catch (error) {
            let errorMessage = 'An error occurred. Please try again.';
            if (error.response) {
                if (error.response.status === 401) {
                    navigate('/login');
                } else if (error.response.data && error.response.data.message) {
                    if (typeof error.response.data.message === 'string') {
                        errorMessage = error.response.data.message;
                    } else if (Array.isArray(error.response.data.message)) {
                        errorMessage = error.response.data.message.join('; ');
                    } else if (typeof error.response.data.message === 'object') {
                        errorMessage = JSON.stringify(error.response.data.message);
                    }
                    console.log(error);
                }
            }
            //   toast.error(errorMessage);
            console.log(errorMessage);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className={classes.sideNavBody}>
            <div className={`${classes.sideNavContainer} ${classes.overflow}`}>
                <div className={classes.logoCont}>
                    {/* <div className={classes.logoPic}> */}
                    {/* <img src={Logo} alt='Logo' className={classes.imgs}/> */}
                    {/* </div> */}
                    {/* <span>{user.charAt(0)}</span>
                    <p>{user.toLocaleUpperCase()}</p> */}
                    {/* <span>A</span> */}
                    {/* <img src={LogoIcon} alt='Logo' className={classes.imgs1}/> */}
                </div>


                <div className={`${classes.sideNav} ${isMenuOpen ? '' : classes.menuOpen}`}>
                    {/* {`${classes.mainMenu} ${isMenuOpen ? classes.menuOpen : ''}`} */}

                    <div className={`${classes.regMenu} ${isMenuOpen ? '' : classes.menuOpen}`}>
                        <Link
                            to={'/cash_management'}
                            className={activeLink === 'Dashboard' ? classes.active : ''}
                        >
                            <p>
                                <span><img src={dashIcon} alt='icon' className={classes.webshow} />
                                    <img src={dashIcon} alt='icon' className={classes.mobileshow} />
                                    Dashboard </span>
                            </p>
                        </Link>
                        <Accordion>

                            <Link
                                to={'/cash_management/income_&_revenue'}
                                className={activeLink === 'Dashboard' ? classes.active : ''}
                            >
                                <p style={{ marginTop: 15 }}>
                                    <span><img src={dIcon2} alt='icon' className={classes.webshow} />
                                        <img src={dIcon2} alt='icon' className={classes.mobileshow} />
                                        Income & Revenue </span>
                                </p>
                            </Link>

                            {/* <Card className={classes.accordionCard}>
                                <Card.Header className={classes.cardHeader} onClick={toggleMenu}>
                                    <ContextAwareToggle eventKey="23" style={{paddingTop:'0'}}>
                                        <p>
                                            <span>
                                                <img src={dIcon2} alt='icon'/> Receipts
                                            </span>
                                            {isMenuOpen ? (<i class='bx bx-chevron-down'></i>) : (<i class='bx bx-chevron-up'></i>)} 
                                        </p>
                                    </ContextAwareToggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="23" style={{backgroundColor:'#164B2E'}}>
                                    <Card.Body className={classes.cardBody}>
                                        <NavLink to={'/admin/users'}>Manage Users </NavLink><br/>
                                        <div style={{marginTop:'10px'}}/>
                                        <NavLink to={'/admin/roles_and_permissions'}>Manage Roles & Permissions </NavLink><br/>
                                        <div style={{marginTop:'10px'}}/>
                                        <NavLink to={'/admin/continent'}>Manage Continent</NavLink><br/>
                                        <div style={{marginTop:'10px'}}/>
                                        <NavLink to={'/admin/region'}>Manage Region </NavLink><br/>
                                        <div style={{marginTop:'10px'}}/>
                                        <NavLink to={'/admin/province'}>Manage Province </NavLink><br/>
                                        <div style={{marginTop:'10px'}}/>
                                        <NavLink to={'/admin/zone'}>Manage Zone </NavLink><br/>
                                        <div style={{marginTop:'10px'}}/>
                                        <NavLink to={'/admin/area'}>Manage Area </NavLink><br/>
                                        <div style={{marginTop:'10px'}}/>
                                        <NavLink to={'/admin/parish'}>Manage Parish </NavLink><br/>
                                        <div style={{marginTop:'10px'}}/>
                                        <NavLink to={''}>Manage Currency </NavLink><br/>
                                       
                                       
                                    </Card.Body>
                                    
                                </Accordion.Collapse>
                            </Card> */}

                            <Card className={classes.accordionCard}>
                                <Card.Header className={classes.cardHeader} onClick={toggleMenu5}>
                                    <ContextAwareToggle eventKey="0" style={{ paddingTop: '0' }}>
                                        <p>
                                            <span>
                                                <img src={dIcon2} alt='icon' /> Bank Lodgement
                                            </span>
                                            {isMenuOpen5 ? (<i class='bx bx-chevron-down'></i>) : (<i class='bx bx-chevron-up'></i>)}
                                        </p>
                                    </ContextAwareToggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0" style={{ backgroundColor: '#164B2E' }}>
                                    <Card.Body className={classes.cardBody}>
                                        <NavLink to={'/cash_management/bank_lodgement/pending'}>Pending </NavLink><br />
                                        <div style={{ marginTop: '10px' }} />
                                        <NavLink to={'/cash_management/bank_lodgement/completed'}>Completed </NavLink><br />

                                    </Card.Body>

                                </Accordion.Collapse>
                            </Card>

                            {/* <Link
                            to={'/cash_management/reports/lodgement_reports'}
                            className={activeLink === 'Dashboard' ? classes.active : ''}
                        >
                            <p style={{marginTop: 15}}>
                                <span><img src={dIcon2} alt='icon' className={classes.webshow} />
                                <img src={dIcon2} alt='icon' className={classes.mobileshow} />
                                Lodgement Reports </span>
                            </p>
                        </Link> */}

                            {/* <Link
                            to={'/cash_management/reports/monthly_income_summary'}
                            className={activeLink === 'Dashboard' ? classes.active : ''}
                        >
                            <p style={{marginTop: 15}}>
                                <span><img src={dIcon2} alt='icon' className={classes.webshow} />
                                <img src={dIcon2} alt='icon' className={classes.mobileshow} />
                                Monthly Income </span>
                            </p>
                        </Link> */}
                            <Link
                                to={'/cash_management/reports/revenue_by_currency'}
                                className={activeLink === 'Dashboard' ? classes.active : ''}
                            >
                                <p style={{ marginTop: 15 }}>
                                    <span><img src={dIcon2} alt='icon' className={classes.webshow} />
                                        <img src={dIcon2} alt='icon' className={classes.mobileshow} />
                                        Receipt By Currency</span>
                                </p>
                            </Link>
                            <Link
                                to={'/cash_management/reports/revenue_by_currency'}
                                className={activeLink === 'Dashboard' ? classes.active : ''}
                            >
                                <p style={{ marginTop: 15 }}>
                                    <span><img src={dIcon2} alt='icon' className={classes.webshow} />
                                        <img src={dIcon2} alt='icon' className={classes.mobileshow} />
                                        Inflow Report</span>
                                </p>
                            </Link>
                            <Link
                                to={'/cash_management/reports/currency_inflow'}
                                className={activeLink === 'Dashboard' ? classes.active : ''}
                            >
                                <p style={{ marginTop: 15 }}>
                                    <span><img src={dIcon2} alt='icon' className={classes.webshow} />
                                        <img src={dIcon2} alt='icon' className={classes.mobileshow} />
                                        Currency Inflow </span>
                                </p>
                            </Link>


                            {/* <Link
                            to={'/cash_management/reports/revenue_by_account'}
                            className={activeLink === 'Dashboard' ? classes.active : ''}
                        >
                            <p style={{marginTop: 15}}>
                                <span><img src={dIcon2} alt='icon' className={classes.webshow} />
                                <img src={dIcon2} alt='icon' className={classes.mobileshow} />
                                Revenue By Account</span>
                            </p>
                        </Link> */}

                            {/* <Card className={classes.accordionCard}>
                                <Card.Header className={classes.cardHeader} onClick={toggleMenu5}>
                                    <ContextAwareToggle eventKey="5" style={{paddingTop:'0'}}>
                                        <p>
                                            <span>
                                                <img src={dIcon2} alt='icon'/> Reports
                                            </span>
                                            {isMenuOpen5 ? (<i class='bx bx-chevron-down'></i>) : (<i class='bx bx-chevron-up'></i>)} 
                                        </p>
                                    </ContextAwareToggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="5" style={{backgroundColor:'#164B2E'}}>
                                    <Card.Body className={classes.cardBody}>
                                    <NavLink to={'/cash_management/reports/lodgement_reports'}>Lodgement Reports </NavLink><br/>
                                    <div style={{marginTop:'10px'}}/>
                                    <NavLink to={'/cash_management/reports/receipt'}>Receipt </NavLink><br/>
                                    <div style={{marginTop:'10px'}}/>
                                    <NavLink to={'/cash_management/reports/monthly_income_summary'}>Monthly Income Summary </NavLink><br/>
                                    <div style={{marginTop:'10px'}}/>
                                    <NavLink to={'/cash_management/reports/currency_inflow'}>Currency Inflow </NavLink><br/>
                                    <div style={{marginTop:'10px'}}/>
                                    <NavLink to={'/cash_management/reports/revenue_by_currency'}>Revenue By Currency</NavLink><br/>
                                    <div style={{marginTop:'10px'}}/>
                                    <NavLink to={'/cash_management/reports/revenue_by_account'}>Revenue By Account</NavLink><br/>
                                    </Card.Body>
                                    
                                </Accordion.Collapse>
                            </Card> */}

                            {/* <Card className={classes.accordionCard}>
                                <Card.Header className={classes.cardHeader} onClick={toggleMenu6}>
                                    <ContextAwareToggle eventKey="6">
                                        <p>
                                            <span>
                                                <img src={dIcon2} alt='icon'/> Trial Balance
                                            </span>
                                            {isMenuOpen ? (<i class='bx bx-chevron-down'></i>) : (<i class='bx bx-chevron-up'></i>)} 
                                        </p>
                                    </ContextAwareToggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="6" style={{backgroundColor:'#164B2E'}}>
                                    <Card.Body className={classes.cardBody}>
                                        <NavLink to={'/admin/report/trial_&_balance/continent'}>Continent </NavLink><br/>
                                        <div style={{marginTop:'10px'}}/>
                                        <NavLink to={'/admin/report/trial_&_balance/region'}>Region </NavLink><br/>
                                        <div style={{marginTop:'10px'}}/>
                                        <NavLink to={'/admin/report/trial_&_balance/province'}>Province </NavLink><br/>
                                    </Card.Body>
                                    
                                </Accordion.Collapse>
                            </Card> */}
                            {/* <Card className={classes.accordionCard}>
                                <Card.Header className={classes.cardHeader} onClick={toggleMenu1}>
                                    <ContextAwareToggle eventKey="1">
                                        <p>
                                            <span><img src={dIcon3} alt='icon' /> Income & Expend.</span>
                                            {isMenuOpen1 ? (<i class='bx bx-chevron-down'></i>) : (<i class='bx bx-chevron-up'></i>)} 
                                        </p>
                                    </ContextAwareToggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="1" style={{backgroundColor:'#164B2E'}}>
                                    <Card.Body className={classes.cardBody}>
                                        <NavLink to={'/admin/report/income_&_expenditure/continent'}>Continent </NavLink><br/>
                                        <div style={{marginTop:'10px'}}/>
                                        <NavLink to={'/admin/report/income_&_expenditure/region'}>Region </NavLink><br/>
                                        <div style={{marginTop:'10px'}}/>
                                        <NavLink to={'/admin/report/income_&_expenditure/province'}>Province </NavLink><br/>
                                    </Card.Body>
                                    
                                </Accordion.Collapse>
                            </Card> */}
                            {/* <Card className={classes.accordionCard}>
                                <Card.Header className={classes.cardHeader} onClick={toggleMenu7}>
                                    <ContextAwareToggle eventKey="7">
                                        
                                            <p>
                                                <span>
                                                    <img src={dIcon2} alt='icon'/> Monthly Income
                                                </span>
                                                {isMenuOpen ? (<i class='bx bx-chevron-down'></i>) : (<i class='bx bx-chevron-up'></i>)} 
                                            </p>
                                       
                                    </ContextAwareToggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="7" style={{backgroundColor:'#164B2E'}}>
                                    <Card.Body className={classes.cardBody}>
                                        <NavLink to={'/admin/report/monthly_income/continent'}>Continent </NavLink><br/>
                                        <div style={{marginTop:'10px'}}/>
                                        <NavLink to={'/admin/report/monthly_income/region'}>Region </NavLink><br/>
                                        <div style={{marginTop:'10px'}}/>
                                        <NavLink to={'/admin/report/monthly_income/province'}>Province </NavLink><br/>
                                    </Card.Body>
                                    
                                </Accordion.Collapse>
                            </Card> */}
                            {/* <Card className={classes.accordionCard}>
                                <Card.Header className={classes.cardHeader} onClick={toggleMenu7}>
                                    <ContextAwareToggle eventKey="20">
                                    
                                            <p>
                                                <span>
                                                    <img src={dIcon2} alt='icon'/> Balance Sheet
                                                </span>
                                                {isMenuOpen ? (<i class='bx bx-chevron-down'></i>) : (<i class='bx bx-chevron-up'></i>)} 
                                            </p>
                                        
                                    </ContextAwareToggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="20" style={{backgroundColor:'#164B2E'}}>
                                    <Card.Body className={classes.cardBody}>
                                        <NavLink to={'/admin/report/balance_sheet/continent'}>Continent </NavLink><br/>
                                        <div style={{marginTop:'10px'}}/>
                                        <NavLink to={'/admin/report/balance_sheet/region'}>Region </NavLink><br/>
                                        <div style={{marginTop:'10px'}}/>
                                        <NavLink to={'/admin/report/balance_sheet/province'}>Province </NavLink><br/>
                                    </Card.Body>
                                    
                                </Accordion.Collapse>
                            </Card> */}
                            {/* <Card className={classes.accordionCard}>
                                <Card.Header className={classes.cardHeader} onClick={toggleMenu2}>
                                    <ContextAwareToggle eventKey="2">
                                        <p> 
                                            <span><img src={dIcon4} alt='icon' /> Journal Entries</span>
                                            {isMenuOpen2 ? (<i class='bx bx-chevron-down'></i>) : (<i class='bx bx-chevron-up'></i>)} 
                                        </p>
                                    </ContextAwareToggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="2" style={{backgroundColor:'#164B2E'}}>
                                    <Card.Body className={classes.cardBody}>
                                        
                                        <Link to={'#'}>View Postings</Link><br/>
                                        <Link to={'#'}>Manage Items</Link><br/>
                                        <Link to={'#'}>Manage Order</Link><br/>
                                        <Link to={'#'}>Manage Requisition</Link><br/>
                                        <Link to={'#'}>Manage Purchase Delivery</Link><br/>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card> */}
                            {/* <Card className={classes.accordionCard}>
                                <Card.Header className={classes.cardHeader} onClick={toggleMenu2}>
                                    <ContextAwareToggle eventKey="2">
                                        <p> 
                                            <span><img src={dIcon4} alt='icon' /> Inventory</span>
                                            {isMenuOpen2 ? (<i class='bx bx-chevron-down'></i>) : (<i class='bx bx-chevron-up'></i>)} 
                                        </p>
                                    </ContextAwareToggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="2" style={{backgroundColor:'#164B2E'}}>
                                    <Card.Body className={classes.cardBody}>
                                        <Link to={'#'}>Manage Department</Link><br/>
                                        <Link to={'#'}>Manage Unit</Link><br/>
                                        <Link to={'#'}>Manage Items</Link><br/>
                                        <Link to={'#'}>Manage Order</Link><br/>
                                        <Link to={'#'}>Manage Requisition</Link><br/>
                                        <Link to={'#'}>Manage Purchase Delivery</Link><br/>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card> */}
                            {/* <Card className={classes.accordionCard}>
                                <Card.Header className={classes.cardHeader} onClick={toggleMenu3}>
                                    <ContextAwareToggle eventKey="3">
                                        <p> 
                                            <span><img src={dIcon5} alt='icon' /> Transactions</span>
                                            {isMenuOpen3 ? (<i class='bx bx-chevron-down'></i>) : (<i class='bx bx-chevron-up'></i>)} 
                                        </p>
                                    </ContextAwareToggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="3" style={{backgroundColor:'#164B2E'}}>
                                    <Card.Body className={classes.cardBody}>
                                        <Link to={'#'}>Sales</Link><br/>
                                        <Link to={'#'}>Receipt</Link><br/>
                                        <Link to={'#'}>Expenses</Link><br/>
                                        <Link to={'#'}>Journal Entries</Link><br/>
                                        <Link to={'#'}>Deposit/Lodgement</Link><br/>
                                        <Link to={'#'}>Loan Repayment</Link><br/>
                                        <Link to={'#'}>Loan Repayment Excel</Link><br/>
                                        <Link to={'#'}>Savings Withdrawal</Link>
                                        <Link to={'#'}>Income Excel Upload</Link>
                                        <Link to={'#'}>Payment Excel Upload</Link>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card> */}
                            {/* <Card className={classes.accordionCard}>
                                <Card.Header className={classes.cardHeader} onClick={toggleMenu4}>
                                    <ContextAwareToggle eventKey="4">
                                        <p> 
                                            <span><img src={dIcon4} alt='icon' /> Inflow</span>
                                            {isMenuOpen4 ? (<i class='bx bx-chevron-down'></i>) : (<i class='bx bx-chevron-up'></i>)} 
                                        </p>
                                    </ContextAwareToggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="4" style={{backgroundColor:'#164B2E'}}>
                                    <Card.Body className={classes.cardBody}>
                                        <NavLink to={'/admin/report/inflow/continent'}>Continent </NavLink><br/>
                                        <div style={{marginTop:'10px'}}/>
                                        <NavLink to={'/admin/report/inflow/region'}>Region </NavLink><br/>
                                        <div style={{marginTop:'10px'}}/>
                                        <NavLink to={'/admin/report/inflow/province'}>Province </NavLink><br/>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card> */}
                            <Card className={classes.accordionCard}>
                                <Card.Header className={classes.cardHeader} onClick={toggleMenu3}>
                                    <ContextAwareToggle eventKey="3">
                                        <p>
                                            <span><img src={dIcon4} alt='icon' /> Utility</span>
                                            {isMenuOpen3 ? (<i class='bx bx-chevron-down'></i>) : (<i class='bx bx-chevron-up'></i>)}
                                        </p>
                                    </ContextAwareToggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="3" style={{ backgroundColor: '#164B2E' }}>
                                    <Card.Body className={classes.cardBody}>
                                        <Link to={'/welcome'}>Switch Account</Link><br />

                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            {/* <Card className={classes.accordionCard}>
                                <Card.Header className={classes.cardHeader} onClick={toggleMenu5}>
                                    <ContextAwareToggle eventKey="5">
                                        <p> 
                                            <span><img src={dIcon6} alt='icon' /> Profile</span>
                                            {isMenuOpen5? (<i class='bx bx-chevron-down'></i>) : (<i class='bx bx-chevron-up'></i>)} 
                                        </p>
                                    </ContextAwareToggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="5" style={{backgroundColor:'#164B2E'}}>
                                    <Card.Body className={classes.cardBody}>
                                    
                                    <Link to={'#'}>Change Password</Link><br/>
                                    
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card> */}

                        </Accordion>


                        {/* <Link
                            to={'/#'}
                            className={activeLink === 'Loan' ? classes.active : ''}
                        >
                            
                        </Link> */}
                        {/* <Link
                            to={'/#'}
                            className={activeLink === 'Grants' ? classes.active : ''}
                        >
                            <p> <img src={dIcon4} alt='icon' /> Inventory</p>
                        </Link> */}
                        {/* <Link
                            to={'/#'}
                            className={activeLink === 'Invoices' ? classes.active : ''}
                        >
                            <p> <img src={dIcon5} alt='icon' /> Transactions</p>
                        </Link> */}
                        {/* <Link
                            to={'/#'}
                            className={activeLink === 'Invoices' ? classes.active : ''}
                        >
                            <p> <img src={dIcon4} alt='icon' /> Report</p>
                        </Link> */}
                        {/* <Link
                            to={'/#'}
                            className={activeLink === 'Invoices' ? classes.active : ''}
                        >
                            <p> <img src={dIcon6} alt='icon' /> Profile</p>
                        </Link> */}


                        {/* <Link
                            onClick={handleLogout}
                            to={'/sign_in'}
                            className={activeLink === 'Logout' ? classes.active : ''}
                        >
                            <p>
                                <img src={dashIcon} alt='icon' />{' '}
                                {loading ? (
                                    <>
                                        <Spinner size='sm' style={{ marginRight: 5 }} /> Signing out...
                                    </>
                                ) : (
                                    'Log out'
                                )}
                            </p>
                        </Link> */}
                    </div>


                </div>
                <div className={classes.harmborgers} onClick={toggleMenu}>
                    {isMenuOpen ? (
                        <span className={classes.closs}>
                            <i class='bx bx-plus-circle'></i>
                        </span>
                    ) : (
                        <span className={classes.open}>
                            <i className='bx bx-menu'></i>
                        </span>
                    )}
                </div>
                <div className={classes.dFooter}>
                    {/* <div className={classes.logoPic}>
                        <img src={Logo} alt='Logo' className={classes.imgs}/>
                    </div> */}
                    <Link className={classes.logOutLink}>
                        <button onClick={() => handleLogout()} className={classes.logout}>
                            <img src={LogoutIcon} alt='Logo' style={{ width: '40px', height: '40px' }} />
                            {loading ? "Logging out...." : "Log out"}
                        </button>
                    </Link>
                </div>
            </div>
        </div>
        // </div>
    )
}
