import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Navbar, Nav, NavDropdown, Row, Button, Modal, Form, Spinner, Badge } from 'react-bootstrap';
import axios from 'axios';
import Select from 'react-select';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Swal from 'sweetalert2';
import classes from '../../../Manage Cooperatives/Manage Members/ManageMember.module.css'
import { BASE_URL } from '../../../api/api';
import { toast, ToastContainer } from 'react-toastify';
import CurrencyInput from 'react-currency-input-field';
import { default as ReactSelect } from "react-select";
import { components } from "react-select";
// import { MultiSelect } from "react-multi-select-component";

import MainDashboard from '../../../Main Dashboard/MainDashoard';
import LatestDash from '../../../LatestDashboard/LatestDash';





function ActivityReport() {
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [load, setLoad] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [inputss, setInputss] = useState([]);
  const [tableData45, setTableData45] = useState([]);
  const [GenLedger, setGenLedger] = useState([]);
  const [isSelectAllChecked, setIsSelectAllChecked] = useState(false);

  const [selectedAccounts, setSelectedAccounts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [ledgerLoading, setLedgerLoading] = useState(false);
  const [savingsLoading, setSavingsLoading] = useState(false);
  const [generalLoading, setGeneralLoading] = useState(false);
  const [fetchingLoading, setFetchingLoading] = useState(false);
  const [bearer, setBearer] = useState('');
  const navigate = useNavigate();
  const [paidBooking, setPaidBooking] = useState([]);
  const [savingss, setSavingss] = useState([]);
  const [provinceOptions, setProvinceOptions] = useState([]);
  const [user, setUser] = useState("");
  const [selectOptions, setSelectOptions] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState('');
  const [selectedCustomerName, setSelectedCustomerName] = useState([]);
  const [selectedSavingsType, setSelectedSavingsType] = useState([]);
  const [selectedSavingsName, setSelectedSavingsName] = useState('');
  const [selectedContinent, setSelectedContinent] = useState('');
  const [tableData3, setTableData3] = useState([]);
  const [tableData4, setTableData4] = useState([]);
  const [filteredDatas, setFilteredDatas] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState('');
  const [admin, setAdmin] = useState('');
  const [selectedProvince, setSelectedProvince] = useState('');
  const [selectedAccount, setSelectedAccount] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [selectedEndDate, setSelectedEndDate] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [entriesPerPage, setEntriesPerPage] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [userType, setUserType] = useState('');
  const [consolidatedLoading, setConsolidatedLoading] = useState(false);
  const [continent, setContinent] = useState('');
  const [region, setRegion] = useState('');
  const [province, setProvince] = useState('');


  const isSelected = (id) => {
    return selectedRows.includes(id);
  };

  const handleCheckboxChange = (row) => {
    const selectedIndex = selectedRows.findIndex(item => item.id === row.id);
    let updatedSelectedRows = [];

    if (selectedIndex === -1) {
      updatedSelectedRows = [...selectedRows, row];
    } else {
      updatedSelectedRows = selectedRows.filter(item => item.id !== row.id);
    }

    setSelectedRows(updatedSelectedRows);
    setSelectAll(updatedSelectedRows.length === tableData.length);
    // console.log(updatedSelectedRows);
  };

  const handleSelectAllChange = (event) => {
    const isChecked = event.target.checked;
    setSelectAll(isChecked);
    setSelectedRows(isChecked ? [...tableData] : []);
    // console.log(isChecked ? [...tableData] : []);
  };


  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  const handleDateChange1 = (event) => {
    setSelectedEndDate(event.target.value);


  };



  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem('userToken');
      const value2 = await AsyncStorage.getItem('companyId');
      const value1 = await AsyncStorage.getItem('tobi');
      const value4 = await AsyncStorage.getItem('admin');
      const admins = await AsyncStorage.getItem('admin');
      const continentName = await AsyncStorage.getItem('continent');
      const regionName = await AsyncStorage.getItem('region');
      const provinceName = await AsyncStorage.getItem('province');

      if (value !== null) {
        setBearer(value);
      }
      if (value1 !== null) {
        setUser(value1);
      }
      if (value4 !== null) {
        setAdmin(value4);
      }
      if (admins !== null) {
        setUserType(admins);
      }
      if (continentName !== null) {
        setContinent(continentName);
      }
      if (regionName !== null) {
        setRegion(regionName);
      }
      if (provinceName !== null) {
        setProvince(provinceName);
      }


    } catch (e) {
      alert('Failed to fetch the input from storage');
    }
  };

  useEffect(() => {
    readData();
  }, []);

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${bearer}`
  };

  const goBack = () => {
    navigate(-1);
  }

  const handleAccountChange = (selected) => {
    setSelectedAccount(selected);
  };

  const fetchAccounts = async () => {
    setFetchingLoading(true);
    try {
      const selectedIds = selectedAccounts
      .filter((account) => account.value !== "select_all") // Exclude "Select All"
      .map((account) => account.value);
      // const selectedRowIds = selectedRows.map(row => row.id);
      const response = await axios.get(`${BASE_URL}/reports/activity-report`, {
        params: {
          account_id: selectedIds,
          start_date: selectedDate,
          end_date: selectedEndDate,
        },
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${bearer}`
        }
      });

      const filtData1 = response.data?.data?.input || [];
      const filtData2 = response.data?.data?.journal || [];

      if (filtData2.length === 0) {
        toast.error('No data found for the selected account.');
      } else {
        setInputss(filtData1);
        setAccounts(filtData2);
console.log(response);
        navigate('/accounting/print_activity', { state: { accounts: filtData1, inputss: filtData2 } });
      }

      console.log(response);

    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
        let errorMessage = 'An error occurred. Please try again.';
        if (error.response && error.response.data && error.response.data.message) {
          if (typeof error.response.data.message === 'string') {
            errorMessage = error.response.data.message;
          } else if (Array.isArray(error.response.data.message)) {
            errorMessage = error.response.data.message.join('; ');
          } else if (typeof error.response.data.message === 'object') {
            errorMessage = JSON.stringify(error.response.data.message);
          }
          toast.error(errorMessage);
        }
      }
    } finally {
      setFetchingLoading(false);
    }
  };


  const fetchCharts = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/account`, { headers });
      const results = response.data?.data;

      const opt1 = results.map((item) => ({
        label: item.gl_name,
        value: item.id,
      }));

      const allOptions = [
        { label: "Select All", value: "select_all" },
        ...opt1,
      ];

      setTableData(allOptions);

    } catch (error) {
      const errorStatus = error.response?.data?.message;
      console.log(errorStatus);
      setTableData([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (bearer) {
      fetchCharts();

    }
  }, [bearer]);



  // const filteredData = tableData.filter(item => item?.gl_name.toLowerCase().includes(searchTerm.toLowerCase()));

  // const totalPages = Math.ceil(filteredData.length / entriesPerPage);

  // const totalPages = Math.ceil(filteredData.length / entriesPerPage);

  // const handlePrevPage = () => {
  //   setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
  // };

  // const handleNextPage = () => {
  //   setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages));
  // };



  // const totalEntries = filteredData.length;
  // const startIndexx = (currentPage - 1) * entriesPerPage + 1;
  // const endIndexx = Math.min(startIndexx + entriesPerPage - 1, totalEntries);
  // const displayedData = filteredData.slice(startIndexx - 1, endIndexx);


  const fetchConsolidated = async () => {
    setConsolidatedLoading(true);

    try {
      const selectedRowIds = selectedRows.map(row => row.id);
      const response = await axios.get(
        `${BASE_URL}/reports/consolidated-activity`, {
        params: {
          account_id: selectedRowIds,
          start_date: selectedDate,
          end_date: selectedEndDate,


        },
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${bearer}`
        }
      });
      const filtData1 = response.data?.data?.input || [];
      const filtData2 = response.data?.data?.journal || [];

      if (filtData2.length === 0) {
        toast.error('No data found for the selected account.');
      } else {
        // setInputss(filtData1);
        setAccounts(filtData1);
        navigate('/accounting/print_activity_consolidated', { state: { document: filtData2, inputs: filtData1 } });

      }
      console.log(response, "consolidated");
      // const genLed = response.data?.data;
      // setGenLedger(genLed);
      //     const genTotal = response.data?.data?.total;
      // setTotalEntries(genTotal);
      // const genLast = response.data?.data?.last_page || 1;
      // setTotalPages(genLast);

      // console.log(genLed, "NI");
    } catch (error) {
      const errorStatus = error.response.data.message;
      console.error(errorStatus);
    } finally {
      setConsolidatedLoading(false);
    }
  };



  const fetchGeneralLedger = async () => {
    setGeneralLoading(true);

    try {
      const response = await axios.get(
        `${BASE_URL}/account/fetch-member-ledger-by-account?member_id=${selectedCustomer}&account_id=${selectedSavingsType}`,
        {

          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearer}`
          }
        }
      );
      const resultssx = response.data?.data;
      setPaidBooking(resultssx);

      // console.log(resultssx, "NI");
    } catch (error) {
      const errorStatus = error.response.data.message;
      console.error(errorStatus);
    } finally {
      setGeneralLoading(false);
    }
  };


  function formatDate(dateString) {
    const date = new Date(dateString);
    const formattedDate = `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())} ${padZero(date.getHours())}:${padZero(date.getMinutes())} ${date.getHours() >= 12 ? 'PM' : 'AM'}`;
    return formattedDate;
  }

  function padZero(num) {
    return num < 10 ? `0${num}` : num;
  }

  
  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };

  const handleSelectChange = (selectedOption) => {
    const isSelectAllSelected = selectedOption.some((option) => option.value === "select_all");
  
    // Handle "Select All" logic
    if (isSelectAllSelected) {
      if (isSelectAllChecked) {
        // If "Select All" was already selected, deselect all options
        setSelectedAccounts([]);
        setIsSelectAllChecked(false);
      } else {
        // If "Select All" is selected, select all options except itself
        const allOptionsExceptSelectAll = tableData.filter((option) => option.value !== "select_all");
        setSelectedAccounts(allOptionsExceptSelectAll);
        setIsSelectAllChecked(true);
      }
    } else {
      // Handle individual option selection
  
      // Check if all other options are selected (without "Select All")
      const allOptionsExceptSelectAll = tableData.filter((option) => option.value !== "select_all");
      const allSelectedWithoutSelectAll = selectedOption.length === allOptionsExceptSelectAll.length;
  
      if (allSelectedWithoutSelectAll) {
        // If all other options are selected, mark "Select All" as checked
        setIsSelectAllChecked(true);
      } else {
        // If any option is deselected, uncheck "Select All"
        setIsSelectAllChecked(false);
      }
  
      // Update the selected accounts
      setSelectedAccounts(selectedOption);
    }
  };



  return (

    <div>

      <div className="wrapper">
        {/* <!-- Sidebar  --> */}


        {/* <!-- Page Content  --> */}
        <div className="content-wrapper">
          <div className="main-content">
            <ToastContainer />
            {/* <MainDashboard /> */}
            <div className='newBody'>
              <div className={classes.newWidth}>

                <div >
                  <LatestDash />
                  {/* <div className={`${classes.formSecCont}`}>
                        <div className={classes.formSectionHeader}>
                        <p style={{textTransform: "capitalize", fontWeight: "bold"}}>
  {admin === "Continental Accountant" ? continent :
      admin === "Regional Accountant" ? region :
      admin === "Super Admin" ? "Super Admin" :
      admin === "Provincial Accountant" ? province : ''}
</p>
                            <h3>Activity Report</h3>
                            <small>Create and view your loan accounts...</small>
                        </div>
                        <div className={classes.formSectionHeader}>
                            <h3 style={{color:'#2D995F'}}>{user.toLocaleUpperCase()}</h3>
                            <p style={{marginTop: -10}}>{admin}</p>
                            <p style={{textTransform: "capitalize", fontWeight: "bold"}}>
  {`(${admin === "Continental Accountant" ? continent :
      admin === "Regional Accountant" ? region :
      admin === "Super Admin" ? "Super Admin" :
      admin === "Provincial Accountant" ? province : ''})`}
</p>

                        </div>
                    </div> */}
                </div>

                <div className={classes.finishedbodyCont}>
                  <h3 style={{ fontSize: '30px', fontWeight: 700, margin: " 10px 10px 0  20px" }}>Activity Report</h3>
                  <p style={{ margin: " 0 10px 20px 20px" }}>Process activity report by selecting the below required field</p>
                  <div className={classes.topSpace} style={{ backgroundColor: 'white' }}>

                    <div className={classes.topPadding} style={{ paddingTop: '0' }}>
                      <div className={`${classes.formSecCont}`}>
                        <div className="card-body" style={{ border: 'none' }}>


                          <div className={`row ${classes.lblrow}`}>
                            {/* Super Admin sees everything */}
                            {/* {admin === 'Super Admin' && (
        <>
          <div className="col-md-4">
            <div className="form-group row">
              <label htmlFor="example-text-input" className="col-sm-3 col-form-label font-weight-400">Continent:</label>
              <div className="col-sm-9">
                <Select
                  onChange={(selectedOption) => handleContinentChange(selectedOption)}
                  options={selectOptions}
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    menu: (provided) => ({
                      ...provided,
                      maxHeight: '300px',
                      maxWidth: '300px',
                      overflowY: 'auto',
                    }),
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group row">
              <label htmlFor="example-text-input" className="col-sm-3 col-form-label font-weight-400">Region:</label>
              <div className="col-sm-9">
                <Select
                  onChange={handleRegionChange}
                  options={tableData3}
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    menu: (provided) => ({
                      ...provided,
                      maxHeight: '300px',
                      maxWidth: '300px',
                      overflowY: 'auto',
                    }),
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group row">
              <label htmlFor="example-text-input" className="col-sm-3 col-form-label font-weight-400">Province:</label>
              <div className="col-sm-9">
                <Select
                  onChange={(selectedOption) => handleProvinceChange(selectedOption)}
                  options={tableData45}
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    menu: (provided) => ({
                      ...provided,
                      maxHeight: '300px',
                      overflowY: 'auto',
                    }),
                  }}
                />
              </div>
            </div>
          </div>
        </>
      )} */}

                            {/* Continental Accountant sees region and province */}
                            {/* {admin === 'Continental Accountant' && (
        <>
          <div className="col-md-4">
            <div className="form-group row">
              <label htmlFor="example-text-input" className="col-sm-3 col-form-label font-weight-400">Region:</label>
              <div className="col-sm-9">
                <Select
                  onChange={handleRegionChange}
                  options={tableData3}
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    menu: (provided) => ({
                      ...provided,
                      maxHeight: '300px',
                      maxWidth: '300px',
                      overflowY: 'auto',
                    }),
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group row">
              <label htmlFor="example-text-input" className="col-sm-3 col-form-label font-weight-400">Province:</label>
              <div className="col-sm-9">
                <Select
                  onChange={(selectedOption) => handleProvinceChange(selectedOption)}
                  options={tableData4}
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    menu: (provided) => ({
                      ...provided,
                      maxHeight: '300px',
                      overflowY: 'auto',
                    }),
                  }}
                />
              </div>
            </div>
          </div>
        </>
      )} */}

                            {/* Regional Accountant sees only province */}
                            {/* {admin === 'Regional Accountant' && (
        <div className="col-md-4">
          <div className="form-group row">
            <label htmlFor="example-text-input" className="col-sm-3 col-form-label font-weight-400">Province:</label>
            <div className="col-sm-9">
              <Select
                onChange={(selectedOption) => handleProvinceChange(selectedOption)}
                options={tableData45}
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  menu: (provided) => ({
                    ...provided,
                    maxHeight: '300px',
                    overflowY: 'auto',
                  }),
                }}
              />
            </div>
          </div>
        </div>
      )} */}

                            {/* Provincial Accountant sees only accounts and date fields */}
                            {/* {(admin === 'Super Admin' || admin === 'Continental Accountant' || admin === 'Regional Accountant' || admin === 'Provincial Accountant') && ( */}
                            <div className={`row ${classes.lblrow}`} style={{ marginTop: 10 }}>
                              {/* <div className="col-md-4">
            <div className="form-group row">
              <label htmlFor="example-text-input" className="col-sm-3 col-form-label font-weight-400">Accounts:</label>
              <div className="col-sm-9"> */}
                              {/* <MultiSelect
          options={tableData}
          value={selectedAccount}
          onChange={setSelectedAccount}
          labelledBy={"Select"}
          isCreatable={true}
        /> */}
                              {/* <pre>{JSON.stringify(selectedAccount)}</pre> */}
                              {/* <Select
                  onChange={(selectedOption) => handleAccountChange(selectedOption)}
                  isMulti
                  value={selectedAccount}
                  options={tableData}
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    menu: (provided) => ({
                      ...provided,
                      maxHeight: '300px',
                      maxWidth: '300px',
                      overflowY: 'auto',
                    }),
                  }}
                /> */}
                              {/* </div>
            </div>
          </div> */}
                              <div className="col-md-4">
                                <div className="form-group row">
                                  <label htmlFor="example-text-input" className={`col-sm-8 col-form-label font-weight-400 ${classes.lblrow}`}>Start Date:</label>
                                  <div className={`col-sm-8 ${classes.lblrow}`}>
                                    <input className="form-control" required="" type="date" onChange={handleDateChange} name="start" value={selectedDate} />
                                  </div>
                                </div>
                              </div>
                              <div className={`col-md-4 ${classes.marginLeftalign2}`}>
                                <div className="form-group row">
                                  <label htmlFor="example-text-input" className={`col-sm-8 col-form-label font-weight-400 ${classes.lblrow}`}>End Date:</label>
                                  <div className="col-sm-8">
                                    <input className="form-control" required="" type="date" onChange={handleDateChange1} name="end" value={selectedEndDate} />
                                  </div>
                                </div>
                              </div>


                            </div>

                          </div>
                        </div>
                      </div>
                      {/* <div style={{ marginTop: 20 }} /> */}



                    </div>
















                    <div style={{ backgroundColor: 'white', padding: '0 20px' }}>
                      {/* <!--Content Header (Page header)--> */}
                      <div className="content-header row align-items-center m-0">
                        {/* {(isAdmin || permittedHeaders.includes('create-savings-account')) && ( */}
                        {/* <nav aria-label="breadcrumb" className="col-sm-4 order-sm-last mb-3 mb-sm-0 p-0 ">
                    <div
                      style={{
                        marginTop: 20,
                        marginBottom: 20,
                        justifyContent: "flex-end",
                        display: "flex",
                        marginLeft: "auto",
                      }}
                      className={classes.actionBtns}
                    >
                      <Button variant="success" onClick={handleCreate}>
                        Create New Accounts
                      </Button>
                    </div>

                  </nav> */}
                        {/* )} */}

                        <div className="col-sm-8 header-title p-0">
                          <div className="media">
                            {/* <div className="header-icon text-success mr-3"><i className=""><img src={favicon} className={classes.favshi} alt="favicon" /></i></div> */}
                            <div className="media-body">
                              {/* <h4 className="font-weight-bold">Savings Account</h4> */}
                              {/* <small>Create and view your Savings Accounts...</small> */}
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* <!--/.Content Header (Page header)--> */}
                      <div className="body-content">
                        <div className="row">

                          <div className="col-lg-12 col-xl-6">
                            <div className="row">

                              <div className="col-md-6 col-lg-6">

                                {/* <!--Feedback--> */}

                              </div>
                              <div className="col-md-6 col-lg-6">

                                {/* <!--Balance indicator--> */}

                              </div>
                              <div className="col-md-6 col-lg-6">

                                {/* <!--Time on site indicator--> */}

                              </div>
                              <div className="col-md-6 col-lg-6">

                                {/* <!--Top Referrals--> */}

                              </div>
                              <div className="col-md-6 col-lg-6">

                                {/* <!--Sessions by device--> */}

                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          {/* <div className="col-md-12 col-lg-12 col-xl-3 mb-4">
                      <div className="card">


                      </div>
                    </div> */}




<div className={`col-md-4 `}>
                                <div className="form-group row">
                                  <label htmlFor="example-text-input" className={`col-sm-8 col-form-label font-weight-400 ${classes.lblrow}`}>Accounts:</label>
                                  <div className="col-sm-8">
                                    <div className={classes.selectWidth}>
                                    <Select
        onChange={handleSelectChange}
        value={selectedAccounts}
        options={tableData}
        isMulti
        components={{ Option }}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        menuPortalTarget={document.body}
        styles={{
          control: (provided) => ({
            ...provided,
            minHeight: '40px',  
            height: '40px',    
            overflow: 'hidden',  
          }),
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          menu: (provided) => ({
            ...provided,
            maxHeight: '500px',
            maxWidth: '300px',
            overflowY: 'auto',
          }),
        }}
     
      />
                                  </div>
                                  </div>
                                </div>
                              </div>
<div className={`modal-footer ${classes.overlayBtnrows1011}`}>
                        <Button
                          disabled={!selectedEndDate || selectedAccounts.length === 0}
                          className={classes.overlayBtns1} variant='success' onClick={fetchAccounts} >
                          {fetchingLoading ? (
                            <>
                              <Spinner size='sm' />
                              <span style={{ marginLeft: '5px' }}>Processing, Please wait...</span>
                            </>
                          ) : (
                            "Process"
                          )}
                        </Button>

                       
                      </div>

                         
                        </div>
                      </div>
                      {/* <!--/.body content--> */}
                    </div>
                  </div>
                </div>

                {/* <!--/.main content--> */}
              </div>
            </div>
            {/* <InfoFooter /> */}
            {/* <!--/.footer content--> */}
            <div className="overlay"></div>
          </div>
          {/* <!--/.wrapper--> */}


        </div>
      </div>
    </div>

  );
}

export default ActivityReport;