import React, { useState, useEffect } from 'react';
import classes from './PrintBalance.module.css';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useLocation } from 'react-router-dom';

export default function PrintIncomeStatement() {
    const [bearer, setBearer] = useState('');
    const [company, setCompany] = useState('');
    const location = useLocation();
    const [currentDateTime, setCurrentDateTime] = useState('');
    const [continent, setContinent] = useState('');
    const [region, setRegion] = useState('');
    const [province, setProvince] = useState('');
    const [userType, setUserType] = useState('');
    const [overallTotal, setOverallTotal] = useState(0);
    const [totalDebit, setTotalDebit] = useState('');
    const [totalCredit, setTotalCredit] = useState('');

    useEffect(() => {
        const addLeadingZero = (number) => (number < 10 ? `0${number}` : number);
    
        const currentDate = new Date();
        const day = currentDate.getDate();
        const month = addLeadingZero(currentDate.getMonth() + 1);
        const year = currentDate.getFullYear();
        const minutes = addLeadingZero(currentDate.getMinutes());
        const hours = addLeadingZero(currentDate.getHours() % 12 || 12);
        const seconds = addLeadingZero(currentDate.getSeconds());
        const ampm = currentDate.getHours() >= 12 ? 'PM' : 'AM';
    
        const formattedDateTime = `${day}/${month}/${year} `;
    
        setCurrentDateTime(formattedDateTime);
    }, []);

    const readData = async () => {
        try {
            const value = await AsyncStorage.getItem('userToken');
            const value1 = await AsyncStorage.getItem('companyName');
            const continentName = await AsyncStorage.getItem('continent');
            const regionName = await AsyncStorage.getItem('region');
            const provinceName = await AsyncStorage.getItem('province');
            const admins = await AsyncStorage.getItem('admin');

            if (value !== null) {
                setBearer(value);
            }
            if (value1 !== null) {
                setCompany(value1);
            }
            if (continentName !== null) {
                setContinent(continentName);
            }
            if (regionName !== null) {
                setRegion(regionName);
            }
            if (provinceName !== null) {
                setProvince(provinceName);
            }
            if (admins !== null) {
                setUserType(admins);
            }
        } catch (e) {
            alert('Failed to fetch the input from storage');
        }
    };

    useEffect(() => {
        readData();
    }, []);

    const { accounts, inputss, selectedDate, selectedEndDate, ressult } = location.state || {};
    // console.log(selectedDate, selectedEndDate);
    const startDate = new Date(selectedDate);
    const endDate = new Date(selectedEndDate);

    const getOrdinalSuffix = (number) => {
        const suffixes = ['th', 'st', 'nd', 'rd'];
        const v = number % 100;
        return v >= 11 && v <= 13
          ? 'th'
          : suffixes[v % 10] || 'th';
    };

    const formatDate = (date) => {
        const day = date.getDate();
        const month = date.toLocaleString('default', { month: 'long' });
        const year = date.getFullYear();
    
        return `${day}${getOrdinalSuffix(day)} ${month}, ${year}`;
    };

    useEffect(() => {
        if (accounts) {
            const firstTotal = accounts?.total || 0;
            const secondTotal = inputss?.total || 0;

            setTotalDebit(firstTotal);
            setTotalCredit(secondTotal);
            setOverallTotal(firstTotal - secondTotal); 
           
        }
    }, [accounts]);

    return (
        <div className={classes.generalbd}>
            <div className={classes.a4}>
                <div className={classes.header}>
                <h1 style={{ fontWeight: 700 }}>{company}</h1>
               
                    <h6 style={{ fontWeight: 500 }}>INCOME STATEMENT FOR THE PERIOD OF {selectedDate} TO {selectedEndDate}</h6>
                </div>
               
                <table className={classes.table}>
                <thead>
        <tr>
            <th></th>
            <th className={classes.noteDiv}>Notes</th>
            <th style={{whiteSpace: 'wrap', textAlign: "center", width: 200}}>AS AT {selectedEndDate}</th>
            <th></th>
            <th style={{whiteSpace: 'wrap', textAlign: "center", width: 200}}>AS AT {selectedEndDate}</th>
            </tr>
            <tr>
            <th></th>
            <th></th>
            <th style={{ textAlign: "center" }}>N'000</th>
            <th></th>
            <th style={{ textAlign: "center" }}>N'000</th>
            </tr>
            </thead>
                    {/* <thead>
                        <tr>
                            <th style={{ textAlign: 'left', width: '50px' }}>Account Code</th>
                            <th style={{ textAlign: 'left', width: '50px' }}>Account Name</th>
                            <th style={{ textAlign: 'right', width: '50px' }}>Current Period</th>
                            <th style={{ textAlign: 'right', width: '50px' }}>Last Month</th>
                            <th style={{ textAlign: 'right', width: '50px' }}>Difference</th>
                        </tr>
                    </thead> */}
                     <tbody>
                        {accounts?.postings?.length > 0 && (
                            <tr>
                                <td style={{textDecoration: "underline", fontWeight: 'bold', fontSize: 18}} colSpan={4}>REVENUE</td>
                            </tr>
                        )}
                        {accounts?.postings?.map((item, index) => (
                            <tr key={index}>
                                <td style={{whiteSpace: "nowrap", paddingLeft: 20}}>{item.name}</td>
                               <td></td>
                                <td style={{textAlign: "right", backgroundColor: "rgb(236, 233, 233)", padding: 5,}}>
                                    {parseFloat(item.amount).toLocaleString('en-US', {
                                        minimumIntegerDigits: 1,
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    })}
                                </td>
                                <td style={{width: 20}}></td>
                                <td style={{textAlign: "right", backgroundColor: "rgb(204, 201, 201)", padding: 5  }}>
                                    {parseFloat(item.amount).toLocaleString('en-US', {
                                        minimumIntegerDigits: 1,
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    })}
                                </td>
                            </tr>
                        ))}
                          {accounts?.postings?.length > 0 && (
                            <tr>
                                <td style={{  fontWeight: 'bold',  whiteSpace: "nowrap", fontSize: 16}} >TOTAL REVENUE</td>
                                <td></td>
                                <td style={{textAlign: "right", backgroundColor: "rgb(236, 233, 233)", padding: 5, borderTop: "2px solid black", borderBottom: "2px solid black" }} className={classes.totalTh} >
                                    {parseFloat(accounts?.total).toLocaleString('en-US', {
                                        minimumIntegerDigits: 1,
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    })}
                                </td>
                                <td style={{width: 50}}>&nbsp;&nbsp;&nbsp;</td>
                                <td style={{textAlign: "right", backgroundColor: "rgb(204, 201, 201)", padding: 5, borderTop: "2px solid black", borderBottom: "2px solid black" }} className={classes.totalTh} >
                                    {parseFloat(accounts?.total).toLocaleString('en-US', {
                                        minimumIntegerDigits: 1,
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    })}
                                </td>
                               
                            </tr>
                            
                        )}
                         <div style={{marginTop: 50}}/>
                        {accounts?.postings?.length > 0 && inputss?.postings?.length > 0 && (
                            <tr>
                                <td style={{textDecoration: "underline", fontWeight: 'bold', fontSize: 18}} colSpan={4}>EXPENSES</td>
                                
                            </tr>
                        )}
                        {inputss?.postings?.map((item, index) => (
                            <tr key={index + accounts.length}>
                               <td style={{whiteSpace: "nowrap", paddingLeft: 20}}>{item.name}</td>
                               <td></td>
                                <td style={{textAlign: "right", backgroundColor: "rgb(236, 233, 233)", padding: 5 }}>
                                    {parseFloat(item.amount).toLocaleString('en-US', {
                                        minimumIntegerDigits: 1,
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    })}
                                </td>
                                <td style={{width: 50}}></td>
                                <td style={{textAlign: "right", backgroundColor: "rgb(204, 201, 201)", padding: 5  }}>
                                    {parseFloat(item.amount).toLocaleString('en-US', {
                                        minimumIntegerDigits: 1,
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    })}
                                </td>
                            </tr>
                        ))}
                      {inputss?.postings?.length > 0 && (
                            <tr>
                                <td style={{  fontWeight: 'bold',  whiteSpace: "nowrap", fontSize: 16}} >TOTAL EXPENSES</td>
                                <td></td>                                
                                <td style={{textAlign: "right", backgroundColor: "rgb(236, 233, 233)", padding: 5, borderTop: "2px solid black", borderBottom: "2px solid black" }} className={classes.totalTh} >
                                    {parseFloat(inputss?.total).toLocaleString('en-US', {
                                        minimumIntegerDigits: 1,
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    })}
                                </td>
                                <td style={{width: 50}}></td>
                                <td style={{textAlign: "right", backgroundColor: "rgb(204, 201, 201)", padding: 5, borderTop: "2px solid black", borderBottom: "2px solid black" }} className={classes.totalTh} >
                                    {parseFloat(inputss?.total).toLocaleString('en-US', {
                                        minimumIntegerDigits: 1,
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    })}
                                </td>
                               
                            </tr>
                            
                        )}
<div style={{marginTop: 20}}/>
<tr>
                        <td colSpan={2} style={{ fontWeight: 'bold', fontSize: 16 }} className={classes.totalTh}>
                            SURPLUS BEFORE DEPRECIATION AND FINANCE
                        </td>
                        <td style={{
                            textAlign: "right",
                            fontWeight: 'bold',
                            backgroundColor: "rgb(236, 233, 233)",
                            padding: 5,
                            borderTop: "2px solid black",
                            borderBottom: "2px solid black"
                        }}>
                            {parseFloat(overallTotal).toLocaleString('en-US', {
                                minimumIntegerDigits: 1,
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                            })}
                        </td>
                        <td></td>
                        <td style={{
                            textAlign: "right",
                            fontWeight: 'bold',
                            backgroundColor: "rgb(204, 201, 201)",
                            padding: 5,
                            borderTop: "2px solid black",
                            borderBottom: "2px solid black"
                        }}>
                            {parseFloat(overallTotal).toLocaleString('en-US', {
                                minimumIntegerDigits: 1,
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                            })}
                        </td>
                    </tr>
                    <div style={{marginTop: 20}}/>
                    {inputss?.finance?.length > 0 && inputss?.finance?.length > 0 && (
                            <tr>
                                <td style={{textDecoration: "underline", fontWeight: 'bold', fontSize: 18}} colSpan={4}>DEPRECIATION AND FINANCE</td>
                                
                            </tr>
                        )}
                    {inputss?.finance?.map((item, index) => (
                            <tr key={index + accounts.length}>
                               <td style={{whiteSpace: "nowrap", paddingLeft: 20}}>{item.name}</td>
                               <td></td>
                                <td style={{textAlign: "right", backgroundColor: "rgb(236, 233, 233)", padding: 5 }}>
                                    {parseFloat(item.amount).toLocaleString('en-US', {
                                        minimumIntegerDigits: 1,
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    })}
                                </td>
                                <td style={{width: 50}}></td>
                                <td style={{textAlign: "right", backgroundColor: "rgb(204, 201, 201)", padding: 5  }}>
                                    {parseFloat(item.amount).toLocaleString('en-US', {
                                        minimumIntegerDigits: 1,
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    })}
                                </td>
                            </tr>
                        ))}
<div style={{marginTop: 20}}/>
                    <tr>
                        <td colSpan={2} style={{ fontWeight: 'bold', fontSize: 16 }} className={classes.totalTh}>
                            SURPLUS FOR THE PERIOD
                        </td>
                        <td style={{
                            textAlign: "right",
                            fontWeight: 'bold',
                            backgroundColor: "rgb(236, 233, 233)",
                            padding: 5,
                            borderTop: "2px solid black",
                            borderBottom: "2px solid black"
                        }}>
                            {parseFloat(overallTotal).toLocaleString('en-US', {
                                minimumIntegerDigits: 1,
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                            })}
                        </td>
                        <td></td>
                        <td style={{
                            textAlign: "right",
                            fontWeight: 'bold',
                            backgroundColor: "rgb(204, 201, 201)",
                            padding: 5,
                            borderTop: "2px solid black",
                            borderBottom: "2px solid black"
                        }}>
                            {parseFloat(overallTotal).toLocaleString('en-US', {
                                minimumIntegerDigits: 1,
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                            })}
                        </td>
                    </tr>
                      
                       
                    </tbody>
                </table>
            </div>
        </div>
    );
}
