import React, { useState, useEffect } from "react";
import classes from "./InventorySalesCash.module.css";
import AsyncStorage from "@react-native-async-storage/async-storage";

import { useLocation } from "react-router-dom";

export default function InventorySalesCash() {
  const [bearer, setBearer] = useState("");
  const [company, setCompany] = useState("");
  const location = useLocation();
  const [currentDateTime, setCurrentDateTime] = useState("");
  const [totalDebit, setTotalDebit] = useState("");
  const [totalCredit, setTotalCredit] = useState("");
  const [totalBalance, setTotalBalance] = useState("");
  const [continent, setContinent] = useState("");
  const [region, setRegion] = useState("");
  const [province, setProvince] = useState("");
  const [userType, setUserType] = useState("");
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalVAT, setTotalVAT] = useState(0);
  const [totalTotalAmount, setTotalTotalAmount] = useState(0);
  

  useEffect(() => {
    const addLeadingZero = (number) => (number < 10 ? `0${number}` : number);

    const currentDate = new Date();
    const day = currentDate.getDate();
    const month = addLeadingZero(currentDate.getMonth() + 1);
    const year = currentDate.getFullYear();
    const minutes = addLeadingZero(currentDate.getMinutes());
    const hours = addLeadingZero(currentDate.getHours() % 12 || 12);
    const seconds = addLeadingZero(currentDate.getSeconds());
    const ampm = currentDate.getHours() >= 12 ? "PM" : "AM";

    const formattedDateTime = `${day}/${month}/${year} `;

    setCurrentDateTime(formattedDateTime);
  }, []);

  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem("userToken");
      const value1 = await AsyncStorage.getItem("companyName");
      const continentName = await AsyncStorage.getItem("continent");
      const regionName = await AsyncStorage.getItem("region");
      const provinceName = await AsyncStorage.getItem("province");
      const admins = await AsyncStorage.getItem("admin");

      if (value !== null) {
        setBearer(value);
        // setAuthenticated(true);
      }
      if (value1 !== null) {
        setCompany(value1);
      }

      if (continentName !== null) {
        setContinent(continentName);
      }
      if (regionName !== null) {
        setRegion(regionName);
      }
      if (provinceName !== null) {
        setProvince(provinceName);
      }
      if (admins !== null) {
        setUserType(admins);
      }
    } catch (e) {
      alert("Failed to fetch the input from storage");
    }
  };

  useEffect(() => {
    readData();
  }, []);

  const {
    accounts,
    inputss,
    glAccount,
    glopening,
    glclosing,
    selectedDate,
    selectedEndDate,
  } = location.state || {};
  console.log(accounts, inputss, glAccount, glopening, selectedDate);
  // const startDate = new Date(inputss.selectedDate);
  // const endDate = new Date(inputss.end_date);
  const accountNames = glAccount;
  const accountOpening = glopening;

  // console.log(accountNames, "here");

  const getOrdinalSuffix = (number) => {
    const suffixes = ["th", "st", "nd", "rd"];
    const v = number % 100;
    return v >= 11 && v <= 13 ? "th" : suffixes[v % 10] || "th";
  };

  const formatDate = (date) => {
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();

    return `${day}${getOrdinalSuffix(day)} ${month}, ${year}`;
  };

  useEffect(() => {
    if (accounts) {
      const debitTotal = accounts.reduce(
        (total, item) => total + parseFloat(item.debit),
        0
      );
      const creditTotal = accounts.reduce(
        (total, item) => total + parseFloat(item.credit),
        0
      );
      const balanceTotal = accounts.reduce(
        (total, item) => total + parseFloat(item.balance),
        0
      );

      // Format the numbers with commas and two decimal places
      const formattedDebitTotal = debitTotal.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      const formattedCreditTotal = creditTotal.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      const formattedBalanceTotal = balanceTotal.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });

      setTotalDebit(formattedDebitTotal);
      setTotalCredit(formattedCreditTotal);
      setTotalBalance(formattedBalanceTotal);
    }
  }, [accounts]);

  function formattedDates(dateString) {
    const date = new Date(dateString);
    const formattedDate = `${date.getFullYear()}-${padZero(
      date.getMonth() + 1
    )}-${padZero(date.getDate())}`;
    return formattedDate;
  }

  function padZero(num) {
    return num < 10 ? `0${num}` : num;
  }

  useEffect(() => {
    if (accounts.length > 0) {
      const totalAmount = accounts.reduce(
        (sum, item) => sum + parseFloat(item.amount || 0),
        0
      );
      const totalVAT = accounts.reduce(
        (sum, item) => sum + parseFloat(item.vat || 0),
        0
      );
      const totalTotalAmount = accounts.reduce(
        (sum, item) => sum + parseFloat(item.total_amount || 0),
        0
      );

      // Format the totals
      setTotalAmount(totalAmount.toLocaleString("en-US", { minimumFractionDigits: 2 }));
      setTotalVAT(totalVAT.toLocaleString("en-US", { minimumFractionDigits: 2 }));
      setTotalTotalAmount(
        totalTotalAmount.toLocaleString("en-US", { minimumFractionDigits: 2 })
      );
    }
  }, [accounts]);

  return (
    <div className={classes.a4}>
      <div className={classes.report}>
        <h2 className={classes.headerTop}>{company}</h2>
        <h4 className={classes.headerTwoo}>{inputss?.name}</h4>
        <h4 className={classes.headerTwo}>
          CASHBOOK REPORT BETWEEN {selectedDate} AND {selectedEndDate}
        </h4>

        <table className={classes.tableDivF}>
          <thead>
            {/* <tr className={classes.tableDivTr}> */}
            <tr
              style={{
                borderTop: "1px solid #c5c6d0",
                borderBottom: "1px solid #c5c6d0",
                backgroundColor: "#e8e8e8",
              }}
            >
              <th
                style={{
                  color: "black",
                  fontSize: "14px",
                  textAlign: "left",
                  padding: "10px",
                  width: "80px",
                }}
              >
                POST DATE
              </th>
              <th
                style={{
                  color: "black",
                  fontSize: "14px",
                  textAlign: "left",
                  padding: "10px",
                  width: "80px",
                }}
              >
                TRANSACTION NUMBER
              </th>
              <th
                style={{
                  color: "black",
                  fontSize: "14px",
                  textAlign: "right",
                  padding: "10px",
                  width: "100px",
                }}
              >
                AMOUNT
              </th>
              <th
                style={{
                  color: "black",
                  fontSize: "14px",
                  textAlign: "right",
                  width: "150px",
                  padding: "10px",
                }}
              >
                VAT
              </th>
              <th
                style={{
                  color: "black",
                  fontSize: "14px",
                  textAlign: "right",
                  width: "50px",
                  padding: "10px",
                }}
              >
                TOTAL AMOUNT
              </th>
            </tr>
          </thead>
          <tbody className={classes.tableBodyFirst}>
            {accounts &&
              accounts.map((item, index) => (
                <tr
                  style={{
                    borderTop: "1px solid #c5c6d0",
                    borderBottom: "1px solid #c5c6d0",
                  }}
                  key={index}
                >
                  <td style={{ whiteSpace: "nowrap" }}>
                    {formattedDates(item.created_at)}
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                    }}
                  >
                    {item.transaction_number}
                  </td>
                  {/* <td>{item.particular}</td> */}
                  <td
                    style={{
                      textAlign: "right",
                    }}
                  >
                    {parseFloat(item.amount).toLocaleString("en-US", {
                      minimumIntegerDigits: 1,
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </td>
                  <td
                    style={{
                      textAlign: "right",
                    }}
                  >
                    {parseFloat(item.vat).toLocaleString("en-US", {
                      minimumIntegerDigits: 1,
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </td>
                  <td
                    style={{
                      textAlign: "right",
                    }}
                  >
                    {parseFloat(item.total_amount).toLocaleString("en-US", {
                      minimumIntegerDigits: 1,
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </td>
                </tr>
              ))}
          </tbody>

          <thead style={{ marginTop: 20 }} className={classes.bottomHeader}>
            <tr>
             
              <td
                style={{
                  backgroundColor: "#e8e8e8",
                  color: "black",
                  fontSize: "16px",
                  textAlign: "left",
                  padding: "2px",
                  fontWeight: 700,
                }}
              >
                TOTALS
              </td>
              <td  style={{
                  backgroundColor: "#e8e8e8",
                  color: "black",
                  fontSize: "16px",
                  textAlign: "right",
                  width: "100px",
                  padding: "2px",
                  fontWeight: 700,
                }}></td>
{/* Daily Sales report
Daily Purchases Report */}
              <td
                style={{
                  backgroundColor: "#e8e8e8",
                  color: "black",
                  fontSize: "16px",
                  textAlign: "right",
                  width: "100px",
                  padding: "2px",
                  fontWeight: 700,
                }}
              > {totalAmount}</td>
              <td
                style={{
                  backgroundColor: "#e8e8e8",
                  color: "black",
                  fontSize: "16px",
                  textAlign: "right",
                  width: "100px",
                  padding: "2px",
                  fontWeight: 700,
                }}
              >
              {totalVAT}
              </td>
              <td style={{  backgroundColor: "#e8e8e8",
                  color: "black",
                  fontSize: "16px",
                  textAlign: "right",
                  width: "100px",
                  padding: "2px",
                  fontWeight: 700,}}>{totalTotalAmount}</td>
             
            </tr>
          </thead>
        </table>
      </div>
    </div>
  );
}
