import React from 'react';
import { Button, Modal, Form, Spinner } from 'react-bootstrap';
// import { AdminHeaderNav } from '../AdminHeaderNav';
// import { InfoFooter } from '../../InfoFooter';
import classes from './ManageMember.module.css';
// import favicon from '../../Images/faviconn.png';
import MainDashboard from '../../Main Dashboard/MainDashoard';
import CoopDashboard from '../../Cooperative Dashboard/CoopDashboard';
import LatestCoopDash from '../../LatestDashboard/LatestCoopDash';

function MembersUi({
  show,
  user,
  show1,
  show56,
  handleShow,
  handleShow56,
  handleShow1,
  handleClose,
  handleClose56,
  handleClose1,
  createCustomer,
  editCustomer,
  isLoading,
  loading,
  searchTerm,
  setSearchTerm,
  entriesPerPage,
  setEntriesPerPage,
  currentPage,
  setCurrentPage,
  totalEntries,
  totalPages,
  startIndexx,
  benLoading,
  endIndexx,
  displayedData,
  uploadLoading,
  handlePrevPage,
  handleNextPage,
  handleEyeClick,
  handleTrashClick,
  fullName,
  setFullName,
  address,
  setAddress,
  office_address,
  setOfficeAddress,
  phone,
  setPhone,
  email,
  setEmail,
  fullName1,
  setFullName1,
  phone1,
  setPhone1,
  email1,
  setEmail1,
  tableData,
  formatDate,
  handleFileChange,
  uploadExcel,
  fetchSearch,
  setSearchedResult,
  searchedResult,
  searchLoading,
  handleEyeClick1,
  memberNumber,
  setMemberNumber
}) {
  const formattedTotalEntries = totalEntries.toLocaleString();
  return (
    <div>
      <div className="wrapper">
        {/* <!-- Sidebar  --> */}


        {/* <!-- Page Content  --> */}
        <div className="content-wrapper">
          <div className="main-content">


            <LatestCoopDash />
            <div className={classes.finishedbodyCont} >
              <div className={classes.newWidth}>
              <div style={{display: "flex", flexDirection: 'row', justifyContent: "space-between", alignItems: "center", marginBottom: 30}}>
              <div style={{display: "flex", flexDirection: 'column'}}>
              <h3 style={{ fontSize: 30, fontWeight: 700, margin: " 10px 10px 0  0" }}>Members</h3>
              <p style={{ margin: "0 10px 20px 0" }}>Upload, View, Edit and Delete Members</p>
         </div>
               
                <div style={{ zIndex: 1}} className="content-header row align-items-center m-0">

                  <nav aria-label="breadcrumb" className="col-sm-4 order-sm-last mb-3 mb-sm-0 p-0 ">
                    <div
                      style={{
                        marginTop: 20,
                        marginBottom: 20,
                        justifyContent: "flex-end",
                        display: "flex",
                        marginLeft: "auto",
                        gap: 10,
                       
                      }}
                      className={classes.formIntBtn}
                    >
                      <Button className={classes.btn2} variant="success" onClick={handleShow} >
                        Add New Member
                      </Button>
                      <Button className={classes.btn1} variant="secondary" onClick={handleShow56}>
                        Upload Member
                      </Button>
                      <a href="https://api-sme.promixaccounting.com/api/v1/download-member-template" download>
                        <Button className={classes.btn22}  variant="primary" >
                          Download Template
                        </Button>
                      </a>
                    </div>

                  </nav>
                
                  <div className="col-sm-8 header-title p-0">
                    <div className={classes.manageCust}>
                      {/* <div className="header-icon text-success mr-3"><i className=""><img src={favicon} className={classes.favshi} alt="favicon" /></i></div> */}
                      <div className="media-body">
                        {/* <h4>Manage Members</h4> */}
                        {/* <small>Create and update your Member...</small> */}
                      </div>
                    </div>
                  </div>
                </div>
                </div>
                {/* <!--/.Content Header (Page header)--> */}
                <div className="body-content">
                  <div className="row">
                    <Modal show={show56} onHide={handleClose56} animation={false}>
                      <Modal.Header closeButton>
                        <Modal.Title>Upload Member</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Form style={{ marginTop: 20 }}>
                          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Upload Excel File</Form.Label>
                            <Form.Control
                              type="file"
                              accept=".xlsx, .xls, .csv"
                              onChange={handleFileChange}
                            />
                          </Form.Group>
                        </Form>
                      </Modal.Body>







                      <Modal.Footer>
                        <Button variant="danger" onClick={handleClose56}>
                          Go back
                        </Button>
                        <Button variant="success" onClick={uploadExcel}>
                          {uploadLoading ? (
                            <>
                              <Spinner size='sm' />
                              <span style={{ marginLeft: '5px' }}>Uploading, Please wait...</span>
                            </>
                          ) : (
                            "Upload"
                          )}
                        </Button>
                      </Modal.Footer>
                    </Modal>

                    <Modal show={show} onHide={handleClose} animation={false}>
                      <Modal.Header closeButton>
                        <Modal.Title>Add Member</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Form style={{ marginTop: 20 }}>
                          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter Full Name"
                              // autoFocus
                              value={fullName}
                              onChange={(e) => setFullName(e.target.value)}
                            />
                            <div style={{ marginTop: 10 }} />
                            <Form.Label>Member Number</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter Member Number"
                              // autoFocus
                              value={memberNumber}
                              onChange={(e) => setMemberNumber(e.target.value)}
                            />
                            <div style={{ marginTop: 10 }} />
                            <Form.Label>Address</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter Address"
                              // autoFocus
                              value={address}
                              onChange={(e) => setAddress(e.target.value)}
                            />
                            <div style={{ marginTop: 10 }} />
                            <Form.Label>Office Address</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter office Address"
                              // autoFocus
                              value={office_address}
                              onChange={(e) => setOfficeAddress(e.target.value)}
                            />
                            <div style={{ marginTop: 10 }} />
                            <Form.Label>Phone Number</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter Phone Number"
                              // autoFocus
                              value={phone}
                              onChange={(e) => setPhone(e.target.value)}
                            />
                            <div style={{ marginTop: 10 }} />
                            <Form.Label>Email Address</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter Email Address"
                              // autoFocus
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </Form.Group>
                        </Form>
                      </Modal.Body>






                      <Modal.Footer>
                        <Button variant="danger" onClick={handleClose}>
                          Go back
                        </Button>
                        <Button variant="success" onClick={createCustomer}>
                          {loading ? (
                            <>
                              <Spinner size='sm' />
                              <span style={{ marginLeft: '5px' }}>Creating Member, Please wait...</span>
                            </>
                          ) : (
                            "Create Member"
                          )}
                        </Button>
                      </Modal.Footer>
                    </Modal>

                    <div className="col-lg-12">
                      <div className="card">

                        <div className="card-body">
                          <div className="table-resposive">
                            <div className={`d-flex justify-content-between align-items-center ${classes.btnStyle}`}>
                              <div className={`${classes.greenbtn} ${classes.btndivStyle}`}>
                                <div className={classes.actionsBtns}>
                                  <button>Copy</button>
                                  <button>Excel</button>
                                  <button>PDF</button>
                                  <button className={classes.diffbtn}>Column visibility</button>
                                </div>
                                <div className={classes.show}>
                                  <label className="d-flex justify-content-start align-items-center">
                                    Show
                                    <select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" className="custom-select custom-select-sm form-control form-control-sm" value={entriesPerPage}
                                      onChange={(e) => {
                                        setEntriesPerPage(parseInt(e.target.value));
                                        setCurrentPage(1);
                                      }}>
                                      <option value={10}>10</option>
                                      <option value={25}>25</option>
                                      <option value={50}>50</option>
                                      <option value={100}>100</option>
                                    </select>
                                    entries
                                  </label>
                                </div>
                              </div>
                              <div className="text-right modal-effect ">
                                <div id="DataTables_Table_0_filter" className="dataTables_filter">
                                  <div className="d-flex justify-content-start align-items-center">
                                    <div className="mr-2">Search:</div>
                                    <input
                                      type="search"
                                      value={searchTerm}
                                      className="form-control form-control-sm"
                                      placeholder=""
                                      aria-controls="DataTables_Table_0"
                                      onChange={(e) => setSearchTerm(e.target.value)}
                                    />
                                    <Button style={{ marginLeft: 10 }} variant="success" onClick={() => fetchSearch(searchTerm)}>
                                      {searchLoading ? (
                                        <>
                                          <Spinner size='sm' />

                                        </>
                                      ) : (
                                        "Search"
                                      )}
                                    </Button>
                                  </div>

                                </div>
                              </div>
                            </div>



                            <div className={classes.mainTable}>
                              {benLoading ? (
                                <p>Fetching members...</p>
                              ) : (
                                <div className="table-responsive">
                                  <table className="table display table-bordered table-striped table-hover bg-white m-0 card-table">
                                    <thead style={{ whiteSpace: 'nowrap' }}>
                                      <tr>
                                        <th>ID</th>
                                        <th>Member Number</th>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Phone Number</th>
                                        <th>Status</th>
                                        <th>Created At</th>
                                        <th>Actions</th>
                                      </tr>
                                    </thead>
                                    <tbody style={{ whiteSpace: 'nowrap' }}>
                                      {(searchTerm.trim() ? searchedResult : tableData).length === 0 ? (
                                        <tr>
                                          <td colSpan="7">No data available</td>
                                        </tr>
                                      ) : (
                                        (searchTerm.trim() ? searchedResult : tableData).map((item, index) => (
                                          <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{item.employee_no}</td>
                                            <td>{item.name}</td>
                                            <td style={{ textAlign: "left" }}>{item.email}</td>
                                            <td>{item.phone}</td>
                                            <td>{item.status === "0" ? "Inactive" : "Active"}</td>
                                            <td>{formatDate(item.created_at)}</td>
                                            <td style={{ textAlign: "left" }}>
                                              <div onClick={() => handleEyeClick(item.id)} className="btn btn-success-soft btn-sm mr-1">
                                                <i className="far fa-eye" style={{ color: "#008a4b", backgroundColor: "#28a7451a", padding: 2, borderColor: "#28a7454d", borderRadius: 5, fontSize: 12 }}></i>
                                              </div>
                                              <div onClick={() => handleTrashClick(item.id)} className="btn btn-danger-soft btn-sm">
                                                <i className="far fa-trash-alt" style={{ color: "#dc3545", backgroundColor: "#dc35451a", padding: 2, borderColor: "#dc35454d", borderRadius: 5, fontSize: 12 }}></i>
                                              </div>
                                            </td>
                                          </tr>
                                        ))
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              )}
                            </div>


                            <div className={classes.endded}>
                              <p>
                                Showing {`Page ${currentPage} to ${totalPages} pages`} of {formattedTotalEntries} entries
                              </p>
                              <div style={{ display: 'flex' }}>
                                <button
                                  style={{ border: 'none', backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'nunito', color: '#000', marginRight: 10, cursor: "pointer" }}
                                  onClick={handlePrevPage}
                                  disabled={currentPage === 1}
                                >
                                  Previous
                                </button>
                                {[...Array(totalPages)].map((_, page) => {
                                  // Show only 5 pages or less if available
                                  if (page < 5 || page === currentPage - 1 || page === totalPages - 1) {
                                    return (
                                      <button
                                        key={page + 1}
                                        style={{
                                          marginLeft: '0.4rem',
                                          marginRight: '0.4rem',
                                          fontSize: '14px',
                                          fontFamily: 'nunito',
                                          fontWeight: 400,
                                          color: page + 1 === currentPage ? '#ffffff' : '#000',
                                          backgroundColor: page + 1 === currentPage ? '#28a745' : 'gainsboro',
                                          height: '2.5rem',
                                          borderRadius: '89px',
                                          padding: '0.5rem',
                                          border: 'none',
                                          width: '40px',
                                          cursor: "pointer"
                                        }}
                                        onClick={() => setCurrentPage(page + 1)}
                                      >
                                        {page + 1}
                                      </button>
                                    );
                                  }
                                  return null;
                                })}
                                <button
                                  style={{ cursor: "pointer", border: 'none', backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'nunito', color: '#000', marginLeft: 10 }}
                                  onClick={handleNextPage}
                                  disabled={currentPage === totalPages}
                                >
                                  Next
                                </button>
                              </div>
                            </div>

                            <Modal show={show1} onHide={handleClose1} animation={false}>
                              <Modal.Header closeButton>
                                <Modal.Title>Edit User</Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                <Form style={{ marginTop: 20 }}>
                                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Full Name</Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="Enter Full Name"
                                      // autoFocus
                                      value={fullName1}
                                      onChange={(e) => setFullName1(e.target.value)}
                                    />
                                    <div style={{ marginTop: 10 }} />
                                    <Form.Label>Email Address</Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="Enter Email Address"
                                      // autoFocus
                                      value={email1}
                                      onChange={(e) => setEmail1(e.target.value)}
                                    />
                                    <div style={{ marginTop: 10 }} />
                                    <Form.Label>Phone Number</Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="Enter Phone Number"
                                      // autoFocus
                                      value={phone1}
                                      onChange={(e) => setPhone1(e.target.value)}
                                    />

                                  </Form.Group>
                                </Form>
                              </Modal.Body>






                              <Modal.Footer>
                                <Button variant="danger" onClick={handleClose1}>
                                  Go back
                                </Button>
                                <Button variant="success" onClick={editCustomer} >                             {loading ? <Spinner id="loader" animation="border" variant="warning" /> : 'Save Changes'}
                                </Button>
                              </Modal.Footer>
                            </Modal>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!--/.body content--> */}
              </div>
              {/* <!--/.main content--> */}
            </div>
          </div>
          {/* <InfoFooter /> */}
          {/* <!--/.footer content--> */}
          <div className="overlay"></div>
        </div>
        {/* <!--/.wrapper--> */}


      </div>
    </div>
  );
}

export default MembersUi;