import React, { useState, useEffect } from "react";
import classes from "./ManageStocks.module.css";
import "../../../assets/datatables/dataTables.bootstrap4.min.css";
import { Spinner, Badge, Button, Modal, Form, Alert } from "react-bootstrap";
import axios from "axios";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { Link, useNavigate, useParams } from "react-router-dom";
import Table from "react-bootstrap/Table";
import { BASE_URL } from "../../api/api";
import { toast, ToastContainer } from "react-toastify";
import Swal from "sweetalert2";
import Select from "react-select";
import CurrencyInput from "react-currency-input-field";
import { ReactBarcode } from "react-jsbarcode";
import LatestInventoryDash from "../LatestInventoryDash";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

export default function CreateProductStock() {
  const navigate = useNavigate();
  const [bearer, setBearer] = useState("");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);
  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);
  const [user, setUser] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [roleLoading, setRoleLoading] = useState(false);
  const [createLoading, setCreateLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [costGood, setCostGood] = useState([]);
  const [tableData23, setTableData23] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [admin, setAdmin] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedDisc, setSelectedDisc] = useState(null);
  const [selectedCost, setSelectedCost] = useState(null);
  const [unit, setUnit] = useState(null);
  const [roless, setRoless] = useState([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [selectedSales, setSelectedSales] = useState(null);
  const [selectedPurchase, setSelectedPurchase] = useState(null);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [tableData2, setTableData2] = useState([]);
  const [tableData3, setTableData3] = useState([]);
  const [incomes, setIncomes] = useState([]);
  const [expenses, setExpenses] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState("");
  const [receivables, setReceivables] = useState([]);
  const [payables, setPayables] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [price, setPrice] = useState("");
  const [price12, setPrice12] = useState("");
  const [glCode, setglCode] = useState("");
  const [stockBalance, setStockBalance] = useState("");
  const [reOderLevel, setReOrderLevel] = useState("");
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [selectedPayable, setSelectedPayable] = useState(null);
  const [selectedAdvance, setSelectedAdvance] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const [glname, setGlname] = useState("");
  const [balance, setBalance] = useState("");
  const [selectedDirection, setSelectedDirection] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [glcode, setGlcode] = useState("");

  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem("userToken");
      const value1 = await AsyncStorage.getItem("tobi");
      const value2 = await AsyncStorage.getItem("permissions");
      const value3 = await AsyncStorage.getItem("admin");

      if (value !== null) {
        setBearer(value);
      }
      if (value1 !== null) {
        setUser(value1);
      }
      if (value2 !== null) {
        setPermissions(value2);
      }
      if (value3 !== null) {
        setAdmin(value3);
      }
    } catch (e) {
      alert("Failed to fetch the input from storage");
    }
  };

  useEffect(() => {
    readData();
  }, []);

  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${bearer}`,
  };

  const handleDirectionChange = (e) => {
    setSelectedDirection(e.target.value);
  };

  const handleValueChange = (value, name, values) => {
    setBalance(value); // Update the balance state
    console.log(value, name, values);
  };

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  const handleSubCatChange = (event) => {
    setSelectedSubCategory(event.target.value);
  };

  const handleSingleImageChange = (event) => {
    const file = event.target.files[0];
    setSelectedImage(file);
  };

  // const fetchData = async () => {
  //   setRoleLoading(true);
  //   try {
  //     const response = await axios.get(`${BASE_URL}/items/fetch-all`, { headers });
  //     const results = response.data?.data;
  //     // console.log(results);
  //     setTableData(results);
  //   } catch (error) {
  //     if (error.response && error.response.status === 401) {
  //       // Redirect to login page if unauthorized
  //       navigate('/login');
  //     } else {
  //       const errorStatus = error.response?.data?.message;
  //       console.log(errorStatus);
  //       setTableData([]);
  //     }
  //   } finally {
  //     setRoleLoading(false);
  //   }
  // };

  const createAccount = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${BASE_URL}/account/add`,
        {
          gl_name: glname,
          opening_balance: balance,
          // direction: selectedDirection,
          transaction_date: selectedDate,
          sub_category_id: selectedSubCategory,
        },
        { headers }
      );
      console.log(response);

      handleClose();
      fetchIncomes();
      fetchCostGood();
      setGlcode("");
      setGlname("");
      setBalance("");
      setSelectedSubCategory("");
      setSelectedDate("");
      // return
      Swal.fire({
        icon: "success",
        title: "Success",
        text: response.data.message,
      });
      console.log(response.data);
    } catch (error) {
      let errorMessage = "An error occurred. Please try again.";
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        if (typeof error.response.data.message === "string") {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join("; ");
        } else if (typeof error.response.data.message === "object") {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        Swal.fire({
          icon: "error",
          title: "Failed",
          text: JSON.stringify(error.response.data.message),
        });
        console.log(error);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleImageChange = (event) => {
    const files = Array.from(event.target.files); // Convert FileList to an array
    setSelectedImages(files);
  };

  const createAccount1 = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${BASE_URL}/account/add`,
        {
          gl_name: glname,
          opening_balance: balance,
          // direction: selectedDirection,
          transaction_date: selectedDate,
          sub_category_id: selectedSubCategory,
        },
        { headers }
      );
      console.log(response);

      fetchExpenses();
      handleClose1();
      setGlcode("");
      setGlname("");
      setBalance("");
      setSelectedSubCategory("");
      setSelectedDate("");
      // return
      Swal.fire({
        icon: "success",
        title: "Success",
        text: response.data.message,
      });
      console.log(response.data);
    } catch (error) {
      let errorMessage = "An error occurred. Please try again.";
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        if (typeof error.response.data.message === "string") {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join("; ");
        } else if (typeof error.response.data.message === "object") {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        Swal.fire({
          icon: "error",
          title: "Failed",
          text: JSON.stringify(error.response.data.message),
        });
        console.log(error);
      }
    } finally {
      setLoading(false);
    }
  };

  const createAccount2 = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${BASE_URL}/account/add`,
        {
          gl_name: glname,
          opening_balance: balance,
          // direction: selectedDirection,
          transaction_date: selectedDate,
          sub_category_id: selectedSubCategory,
        },
        { headers }
      );
      console.log(response);
      fetchReceivables();
      handleClose2();
      setGlcode("");
      setGlname("");
      setBalance("");
      setSelectedSubCategory("");
      setSelectedDate("");
      // return
      Swal.fire({
        icon: "success",
        title: "Success",
        text: response.data.message,
      });
      console.log(response.data);
    } catch (error) {
      let errorMessage = "An error occurred. Please try again.";
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        if (typeof error.response.data.message === "string") {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join("; ");
        } else if (typeof error.response.data.message === "object") {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        Swal.fire({
          icon: "error",
          title: "Failed",
          text: JSON.stringify(error.response.data.message),
        });
        console.log(error);
      }
    } finally {
      setLoading(false);
    }
  };

  const fetchData11 = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/get-sub-categories`, {
        headers,
      });
      const subCat = response.data?.data;
      setTableData23(subCat);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate("/login");
      } else {
        const errorStatus = error.response?.data?.message;
        console.log(errorStatus);
        setTableData23([]);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const createAccount3 = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${BASE_URL}/account/add`,
        {
          gl_name: glname,
          opening_balance: balance,
          // direction: selectedDirection,
          transaction_date: selectedDate,
          sub_category_id: selectedSubCategory,
        },
        { headers }
      );
      console.log(response);
      fetchPayables();
      handleClose3();
      setGlcode("");
      setGlname("");
      setBalance("");
      setSelectedSubCategory("");
      setSelectedDate("");
      // return
      Swal.fire({
        icon: "success",
        title: "Success",
        text: response.data.message,
      });
      console.log(response.data);
    } catch (error) {
      let errorMessage = "An error occurred. Please try again.";
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        if (typeof error.response.data.message === "string") {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join("; ");
        } else if (typeof error.response.data.message === "object") {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        Swal.fire({
          icon: "error",
          title: "Failed",
          text: JSON.stringify(error.response.data.message),
        });
        console.log(error);
      }
    } finally {
      setLoading(false);
    }
  };

  const fetchData1 = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/units/fetch-all`, {
        headers,
      });
      const roleList = response.data?.data;
      // console.log(results);
      setRoless(roleList);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate("/login");
      } else {
        const errorStatus = error.response?.data?.message;
        console.log(errorStatus);
        setTableData([]);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const fetchData2 = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/product-categories`, {
        headers,
      });
      const ts = response.data?.data;
      // console.log(results);
      setTableData3(ts);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate("/login");
      } else {
        const errorStatus = error.response?.data?.message;
        console.log(errorStatus);
        setTableData3([]);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const fetchGl = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/account`, { headers });
      const results = response.data?.data;

      setTableData2(results);
      // console.log(results);
    } catch (error) {
      const errorStatus = error.response?.data?.message;
      console.log(errorStatus);
      setTableData2([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (bearer) {
      // fetchData();
      fetchData1();
      fetchData2();
      fetchGl();
      fetchData11();
    }
  }, [bearer]);

  const createUser12 = async () => {
    setCreateLoading(true);
    try {
      const formDatas = new FormData();
      const removeFormatting = (formattedValue) => {
        const valueWithoutCommas = formattedValue.replace(/,/g, "");
        const valueAsNumber = parseFloat(valueWithoutCommas);
        return valueAsNumber;
      };

      formDatas.append("name", name);
      formDatas.append("description", description);
      formDatas.append("unit", unit?.value || "");
      formDatas.append("gl_code", glCode);
      formDatas.append("type", "2");
      formDatas.append("re_order_level", reOderLevel || "");
      formDatas.append("price", removeFormatting(price) || "");
      formDatas.append("cost_price", removeFormatting(price12) || "");
      formDatas.append("quantity", stockBalance);
      formDatas.append("sales_gl", selectedSales || "");
      formDatas.append("payable_gl", selectedPayable || "");
      formDatas.append("purchase_gl", selectedPurchase || "");
      formDatas.append("account_receivable", selectedAccount || "");
      formDatas.append("category_id", selectedCategory?.value || "");
      formDatas.append("advance_payment_gl", selectedAdvance || "");
      formDatas.append("image", selectedImages);
      formDatas.append("display_image", selectedImage);
      formDatas.append("cost_of_good_gl", selectedCost || "");
      formDatas.append("discount_gl", selectedDisc || "");

      const response = await axios.post(
        `${BASE_URL}/items/add-new-item`,
        formDatas,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${bearer}`,
          },
        }
      );
      console.log(response);
      // fetchData();
      // handleClose();

      // return
      Swal.fire({
        icon: "success",
        title: "Success",
        text: response.data.message,
      }).then(() => {
        window.location.reload();
      });

      setName("");
      setDescription("");
      setUnit("");
      setPrice("");
      setPrice12("");
      setSelectedCategory("");
      setSelectedSales("");
      setSelectedPurchase("");
      setSelectedAccount("");
      setSelectedPayable("");
      setStockBalance("");
      setReOrderLevel("");
      setglCode("");
      setSelectedAdvance("");
      setSelectedType("");

      console.log(response.data);
    } catch (error) {
      let errorMessage = "An error occurred. Please try again.";
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        if (typeof error.response.data.message === "string") {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join("; ");
        } else if (typeof error.response.data.message === "object") {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        Swal.fire({
          icon: "error",
          title: "Failed",
          text: errorMessage,
        });
        // toast.error(JSON.stringify(error.response.data.data));
        // console.log(e);
      }
    } finally {
      setCreateLoading(false);
    }
  };


  const createUser = async () => {
    setCreateLoading(true);
    try {
      const formDatas = new FormData();
      const removeFormatting = (formattedValue) => {
        const valueWithoutCommas = formattedValue.replace(/,/g, '');
        const valueAsNumber = parseFloat(valueWithoutCommas);
        return valueAsNumber;
      };

      formDatas.append('name', name);
      formDatas.append('description', description);
      formDatas.append('unit', unit?.value || '');
      formDatas.append('gl_code', glCode);
      formDatas.append('type', '2');
      formDatas.append('re_order_level', reOderLevel || '');
      formDatas.append('price', removeFormatting(price) || '');
      formDatas.append('cost_price', removeFormatting(price12) || '');
      formDatas.append('quantity', stockBalance);
      formDatas.append('sales_gl', selectedSales || '');
      formDatas.append('payable_gl', selectedPayable || '');
      formDatas.append('purchase_gl', selectedPurchase || '');
      formDatas.append('account_receivable', selectedAccount || '');
      formDatas.append('category_id', selectedCategory?.value || '');
      formDatas.append('advance_payment_gl', selectedAdvance || '');
      formDatas.append('image', selectedImages);
      formDatas.append('display_image', selectedImage);
      formDatas.append('cost_of_good_gl', selectedCost || '');
      formDatas.append('discount_gl', selectedDisc || '');
      
      const response = await axios.post(
        `${BASE_URL}/items/add-new-item`,
       formDatas,
       {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${bearer}`
        }
      }
      );
      console.log(response)
      // fetchData();
      // handleClose();

      // return
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: response.data.message,
      });
      // .then(() => {
      //   window.location.reload();
      // });

      setName('');
      setDescription('');
      setUnit('');
      setPrice('');
      setPrice12('');
      setSelectedCategory('');
      setSelectedSales('');
      setSelectedPurchase('');
      setSelectedAccount('');
      setSelectedPayable('');
      setStockBalance('');
      setReOrderLevel('');
      setglCode('');
      setSelectedAdvance('');
      setSelectedType('');


      console.log(response.data);

    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        Swal.fire({
          icon: 'error',
          title: 'Failed',
          text: errorMessage,
        });
        // toast.error(JSON.stringify(error.response.data.data));
        // console.log(e);
      }
    } finally {
      setCreateLoading(false);
    }
  };

  // UseEffect to log changes to selectedItem
  useEffect(() => {
    // console.log(selectedItem, "selectedItem changed");
  }, [selectedItem]);

  const formatPrice = (value) => {
    let formattedValue = parseFloat(value.replace(/,/g, ""));
    if (isNaN(formattedValue)) return;

    formattedValue = formattedValue.toFixed(2);
    const parts = formattedValue.split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    const finalValue = parts.join(".");
    setPrice(finalValue);
  };

  const formatPrice12 = (value) => {
    let formattedValue = parseFloat(value.replace(/,/g, ""));
    if (isNaN(formattedValue)) return;

    formattedValue = formattedValue.toFixed(2);
    const parts = formattedValue.split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    const finalValue = parts.join(".");
    setPrice12(finalValue);
  };

  const options = roless.map((item) => ({
    label: item.name,
    value: item.id,
  }));

  const options1 = tableData3.map((item) => ({
    label: item.description,
    value: item.id,
  }));

  const fetchIncomes = async () => {
    setLoading(true);

    try {
      const response = await axios.get(
        `${BASE_URL}/get-account-by-class-id?class_id=${4}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${bearer}`,
          },
        }
      );
      const resultss = response.data?.data;
      setIncomes(resultss);

      //   console.log(results, "NI");
    } catch (error) {
      const errorStatus = error.response.data.message;
      console.error(errorStatus);
    } finally {
      setLoading(false);
    }
  };

  const fetchExpenses = async () => {
    setLoading(true);

    try {
      const response = await axios.get(
        `${BASE_URL}/get-account-by-category-id?category_id=${1}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${bearer}`,
          },
        }
      );
      const resultss = response.data?.data;
      setExpenses(resultss);

      //   console.log(results, "NI");
    } catch (error) {
      const errorStatus = error.response.data.message;
      console.error(errorStatus);
    } finally {
      setLoading(false);
    }
  };

  const fetchCostGood = async () => {
    setLoading(true);

    try {
      const response = await axios.get(
        `${BASE_URL}/get-account-by-sub-category-id?sub_category_id=${28}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${bearer}`,
          },
        }
      );
      const resultss = response.data?.data;
      setCostGood(resultss);

      //   console.log(results, "NI");
    } catch (error) {
      const errorStatus = error.response.data.message;
      console.error(errorStatus);
    } finally {
      setLoading(false);
    }
  };

  const fetchReceivables = async () => {
    setLoading(true);

    try {
      const response = await axios.get(
        `${BASE_URL}/get-account-by-sub-category-id?sub_category_id=${11}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${bearer}`,
          },
        }
      );
      const resultss = response.data?.data;
      setReceivables(resultss);

      //   console.log(results, "NI");
    } catch (error) {
      const errorStatus = error.response.data.message;
      console.error(errorStatus);
    } finally {
      setLoading(false);
    }
  };

  const fetchPayables = async () => {
    setLoading(true);

    try {
      const response = await axios.get(
        `${BASE_URL}/get-account-by-sub-category-id?sub_category_id=${17}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${bearer}`,
          },
        }
      );
      const resultss = response.data?.data;
      setPayables(resultss);

      //   console.log(results, "NI");
    } catch (error) {
      const errorStatus = error.response.data.message;
      console.error(errorStatus);
    } finally {
      setLoading(false);
    }
  };

  const salesOptions = incomes.map((item) => ({
    label: item.gl_name,
    value: item.id,
  }));

  const cost = costGood.map((item) => ({
    label: item.gl_name,
    value: item.id,
  }));

  const disc = tableData2.map((item) => ({
    label: item.gl_name,
    value: item.id,
  }));

  const purchaseOptions = expenses.map((item) => ({
    label: item.gl_name,
    value: item.id,
  }));

  const receivablesOptions = receivables.map((item) => ({
    label: item.gl_name,
    value: item.id,
  }));
  const payablesOptions = payables.map((item) => ({
    label: item.gl_name,
    value: item.id,
  }));

  const advanceOptions = payables.map((item) => ({
    label: item.gl_name,
    value: item.id,
  }));

  useEffect(() => {
    if (bearer) {
      fetchIncomes();
      fetchExpenses();
      fetchReceivables();
      fetchPayables();
      fetchCostGood();
    }
  }, [bearer]);

  const extendedSales = [
    { label: "(Add a new sales account)", value: "add-new" },
    ...salesOptions,
  ];

  const extendedCost = [
    { label: "(Add a new cost account)", value: "add-new" },
    ...cost,
  ];
  const extendedPurchase = [
    { label: "(Add a new inventory account)", value: "add-new" },
    ...purchaseOptions,
  ];
  const extendedDisc = [
    { label: "(Add a new purchase account)", value: "add-new" },
    ...disc,
  ];
  const extendedReceivables = [
    { label: "(Add a new receivables account)", value: "add-new" },
    ...receivablesOptions,
  ];
  const extendedPayables = [
    { label: "(Add a new payables account)", value: "add-new" },
    ...payablesOptions,
  ];
  const extendedAdvanced = [
    { label: "(Add a new advanced payment account)", value: "add-new" },
    ...advanceOptions,
  ];

  const handleSales = (selectedOption) => {
    if (selectedOption.value === "add-new") {
      handleShow();
    } else {
      setSelectedSales(selectedOption.value);
    }
  };
  const handleCost = (selectedOption) => {
    if (selectedOption.value === "add-new") {
      handleShow();
    } else {
      setSelectedCost(selectedOption.value);
    }
  };
  const handleDisc = (selectedOption) => {
    if (selectedOption.value === "add-new") {
      handleShow();
    } else {
      setSelectedDisc(selectedOption.value);
    }
  };

  const handlePurchase = (selectedOption) => {
    if (selectedOption.value === "add-new") {
      handleShow1();
    } else {
      setSelectedPurchase(selectedOption.value);
    }
  };
  const handleReceivables = (selectedOption) => {
    if (selectedOption.value === "add-new") {
      handleShow2();
    } else {
      setSelectedAccount(selectedOption.value);
    }
  };
  const handlePayables = (selectedOption) => {
    if (selectedOption.value === "add-new") {
      handleShow3();
    } else {
      setSelectedPayable(selectedOption.value);
    }
  };
  const handleAdvance = (selectedOption) => {
    if (selectedOption.value === "add-new") {
      handleShow3();
    } else {
      setSelectedAdvance(selectedOption.value);
    }
  };

  return (
    <div>
      <LatestInventoryDash />
      <ToastContainer />
      <div className={classes.finishedbodyCont}>
        <Modal show={show} onHide={handleClose} animation={false}>
          <Modal.Header closeButton>
            <Modal.Title>Create Sales Account</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label style={{ marginTop: 20 }}>Gl Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Gl Name"
                  value={glname}
                  onChange={(e) => setGlname(e.target.value)}
                />

                <Form.Label style={{ marginTop: 20 }}>Account Type</Form.Label>
                <Form.Select
                  className="form-control"
                  as="select"
                  value={selectedSubCategory}
                  onChange={handleSubCatChange}
                >
                  <option value="" disabled>
                    Select Type
                  </option>
                  {tableData23.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.description}
                    </option>
                  ))}
                </Form.Select>

                {/* <Form.Label style={{ marginTop: 20 }}>Direction</Form.Label>
                        <Form.Select className="form-control"
                          as="select"
                          value={selectedDirection}
                          onChange={handleDirectionChange}
                        >
                          <option value="" disabled>Select Direction</option>
                          <option value="1" >Debit</option>
                          <option value="2" >Credit</option>

                        </Form.Select> */}

                {/* <Form.Label style={{ marginTop: 20 }}>Opening Balance</Form.Label> <br />

                        <CurrencyInput
                          id="exampleForm.ControlInput1"
                          name="Balance"
                          placeholder="Enter Opening balance"
                          decimalsLimit={2}
                          value={balance} // Set the value to the balance state
                          onValueChange={handleValueChange}
                          style={{

                            minWidth: "100%",
                            height: "calc(1.8em + .75rem + 2px)",
                            border: '1px solid #e4e5e7',
                            borderRadius: 5,
                            overflow: 'hidden',
                            zIndex: 999,
                            fontSize: 14,
                            padding: ".375rem .75rem"
                          }}
                        /> */}
                <Form.Label style={{ marginTop: 20 }}>Balance as at</Form.Label>

                <input
                  className="form-control"
                  required=""
                  type="date"
                  onChange={handleDateChange}
                  name="date"
                  value={selectedDate}
                />
              </Form.Group>
            </Form>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="danger" onClick={handleClose}>
              Go back
            </Button>
            <Button variant="success" onClick={createAccount}>
              {loading ? (
                <>
                  <Spinner size="sm" />
                  <span style={{ marginLeft: "5px" }}>
                    Creating account, Please wait...
                  </span>
                </>
              ) : (
                "Create"
              )}
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={show1} onHide={handleClose1} animation={false}>
          <Modal.Header closeButton>
            <Modal.Title>Create Purchase Account</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label style={{ marginTop: 20 }}>Gl Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Gl Name"
                  value={glname}
                  onChange={(e) => setGlname(e.target.value)}
                />

                <Form.Label style={{ marginTop: 20 }}>Account Type</Form.Label>
                <Form.Select
                  className="form-control"
                  as="select"
                  value={selectedSubCategory}
                  onChange={handleSubCatChange}
                >
                  <option value="" disabled>
                    Select Type
                  </option>
                  {tableData23.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.description}
                    </option>
                  ))}
                </Form.Select>

                {/* <Form.Label style={{ marginTop: 20 }}>Direction</Form.Label>
                        <Form.Select className="form-control"
                          as="select"
                          value={selectedDirection}
                          onChange={handleDirectionChange}
                        >
                          <option value="" disabled>Select Direction</option>
                          <option value="1" >Debit</option>
                          <option value="2" >Credit</option>

                        </Form.Select> */}

                {/* <Form.Label style={{ marginTop: 20 }}>Opening Balance</Form.Label> <br />

                        <CurrencyInput
                          id="exampleForm.ControlInput1"
                          name="Balance"
                          placeholder="Enter Opening balance"
                          decimalsLimit={2}
                          value={balance} // Set the value to the balance state
                          onValueChange={handleValueChange}
                          style={{

                            minWidth: "100%",
                            height: "calc(1.8em + .75rem + 2px)",
                            border: '1px solid #e4e5e7',
                            borderRadius: 5,
                            overflow: 'hidden',
                            zIndex: 999,
                            fontSize: 14,
                            padding: ".375rem .75rem"
                          }}
                        /> */}
                <Form.Label style={{ marginTop: 20 }}>Balance as at</Form.Label>

                <input
                  className="form-control"
                  required=""
                  type="date"
                  onChange={handleDateChange}
                  name="date"
                  value={selectedDate}
                />
              </Form.Group>
            </Form>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="danger" onClick={handleClose1}>
              Go back
            </Button>
            <Button variant="success" onClick={createAccount1}>
              {loading ? (
                <>
                  <Spinner size="sm" />
                  <span style={{ marginLeft: "5px" }}>
                    Creating account, Please wait...
                  </span>
                </>
              ) : (
                "Create"
              )}
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={show2} onHide={handleClose2} animation={false}>
          <Modal.Header closeButton>
            <Modal.Title>Create A/c Receivables Account</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label style={{ marginTop: 20 }}>Gl Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Gl Name"
                  value={glname}
                  onChange={(e) => setGlname(e.target.value)}
                />

                <Form.Label style={{ marginTop: 20 }}>Account Type</Form.Label>
                <Form.Select
                  className="form-control"
                  as="select"
                  value={selectedSubCategory}
                  onChange={handleSubCatChange}
                >
                  <option value="" disabled>
                    Select Type
                  </option>
                  {tableData23.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.description}
                    </option>
                  ))}
                </Form.Select>

                {/* <Form.Label style={{ marginTop: 20 }}>Direction</Form.Label>
                        <Form.Select className="form-control"
                          as="select"
                          value={selectedDirection}
                          onChange={handleDirectionChange}
                        >
                          <option value="" disabled>Select Direction</option>
                          <option value="1" >Debit</option>
                          <option value="2" >Credit</option>

                        </Form.Select> */}

                {/* <Form.Label style={{ marginTop: 20 }}>Opening Balance</Form.Label> <br />

                        <CurrencyInput
                          id="exampleForm.ControlInput1"
                          name="Balance"
                          placeholder="Enter Opening balance"
                          decimalsLimit={2}
                          value={balance} // Set the value to the balance state
                          onValueChange={handleValueChange}
                          style={{

                            minWidth: "100%",
                            height: "calc(1.8em + .75rem + 2px)",
                            border: '1px solid #e4e5e7',
                            borderRadius: 5,
                            overflow: 'hidden',
                            zIndex: 999,
                            fontSize: 14,
                            padding: ".375rem .75rem"
                          }}
                        /> */}
                <Form.Label style={{ marginTop: 20 }}>Balance as at</Form.Label>

                <input
                  className="form-control"
                  required=""
                  type="date"
                  onChange={handleDateChange}
                  name="date"
                  value={selectedDate}
                />
              </Form.Group>
            </Form>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="danger" onClick={handleClose2}>
              Go back
            </Button>
            <Button variant="success" onClick={createAccount2}>
              {loading ? (
                <>
                  <Spinner size="sm" />
                  <span style={{ marginLeft: "5px" }}>
                    Creating account, Please wait...
                  </span>
                </>
              ) : (
                "Create"
              )}
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={show3} onHide={handleClose3} animation={false}>
          <Modal.Header closeButton>
            <Modal.Title>Create Account</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label style={{ marginTop: 20 }}>Gl Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Gl Name"
                  value={glname}
                  onChange={(e) => setGlname(e.target.value)}
                />

                <Form.Label style={{ marginTop: 20 }}>Account Type</Form.Label>
                <Form.Select
                  className="form-control"
                  as="select"
                  value={selectedSubCategory}
                  onChange={handleSubCatChange}
                >
                  <option value="" disabled>
                    Select Type
                  </option>
                  {tableData23.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.description}
                    </option>
                  ))}
                </Form.Select>

                {/* <Form.Label style={{ marginTop: 20 }}>Direction</Form.Label>
                        <Form.Select className="form-control"
                          as="select"
                          value={selectedDirection}
                          onChange={handleDirectionChange}
                        >
                          <option value="" disabled>Select Direction</option>
                          <option value="1" >Debit</option>
                          <option value="2" >Credit</option>

                        </Form.Select> */}

                {/* <Form.Label style={{ marginTop: 20 }}>Opening Balance</Form.Label> <br />

                        <CurrencyInput
                          id="exampleForm.ControlInput1"
                          name="Balance"
                          placeholder="Enter Opening balance"
                          decimalsLimit={2}
                          value={balance} // Set the value to the balance state
                          onValueChange={handleValueChange}
                          style={{

                            minWidth: "100%",
                            height: "calc(1.8em + .75rem + 2px)",
                            border: '1px solid #e4e5e7',
                            borderRadius: 5,
                            overflow: 'hidden',
                            zIndex: 999,
                            fontSize: 14,
                            padding: ".375rem .75rem"
                          }}
                        /> */}
                <Form.Label style={{ marginTop: 20 }}>Balance as at</Form.Label>

                <input
                  className="form-control"
                  required=""
                  type="date"
                  onChange={handleDateChange}
                  name="date"
                  value={selectedDate}
                />
              </Form.Group>
            </Form>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="danger" onClick={handleClose3}>
              Go back
            </Button>
            <Button variant="success" onClick={createAccount3}>
              {loading ? (
                <>
                  <Spinner size="sm" />
                  <span style={{ marginLeft: "5px" }}>
                    Creating account, Please wait...
                  </span>
                </>
              ) : (
                "Create"
              )}
            </Button>
          </Modal.Footer>
        </Modal>

        <h3
          style={{
            fontSize: "30px",
            fontWeight: 700,
            margin: "10px 10px 0  35px",
          }}
        >
          Create Product
        </h3>
        <p style={{ margin: " 0 10px 20px 35px" }}>
          Kindly fill in the below with the correct details
        </p>

        <div className={classes.mainform}>
          <div className={classes.loandgrantcards}>
            {/* <h3 className={classes.productText}>Add Products</h3> */}
            <div className={classes.formLabel}></div>
            <div className={classes.loandethead}>
              <div className={classes.formIntBtn}></div>
            </div>

            <div className="d-flex justify-content-left">
              <Form style={{ marginTop: 20, width: "100%" }}>
                <Container>
                  <Row>
                    <Col xs={12} md={4}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <div
                          className={`align-items-left form-group-custom`}
                        >
                          <Form.Label
                            style={{
                              width: "100%",
                              textAlign: "left",
                              fontWeight: "650"
                            }}
                          >
                            Name
                          </Form.Label>
                          <Form.Control
                            type="text"
                            // placeholder="0.00"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                          />
                        </div>
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={4}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <div
                          className={`align-items-left form-group-custom`}
                        >
                          <Form.Label
                            style={{
                              width: "100%",
                              textAlign: "left",
                              fontWeight: "650"
                            }}
                          >
                            Description
                          </Form.Label>
                          <Form.Control
                            type="text"
                            // placeholder="0.00"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                          />
                        </div>
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={4}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <div
                          className={`align-items-left form-group-custom`}
                        >
                          <Form.Label
                            style={{
                              width: "100%",
                              textAlign: "left",
                              fontWeight: "650"
                            }}
                          >
                            Purchase Price
                          </Form.Label>
                          <Form.Control
                            // className={classes.formControl + ' ' + classes.alignRight}
                            type="text"
                            placeholder="0.00"
                            value={price}
                            onChange={(e) => setPrice(e.target.value)}
                            onBlur={(e) => formatPrice(e.target.value)}
                          />
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                </Container>
                <Container>
                  <Row>
                    <Col xs={12} md={4}>
                      <div style={{ marginTop: -7 }} />
                      <Form.Group className="mb-3">
                        <div
                          className={`align-items-left form-group-custom`}
                        >
                          <Form.Label
                            style={{
                              width: "100%",
                              textAlign: "left",
                              fontWeight: "650"
                            }}
                          >
                            Sale Price
                          </Form.Label>
                          <Form.Control
                            // className={classes.formControl + ' ' + classes.alignRight}
                            type="text"
                            placeholder="0.00"
                            value={price12}
                            onChange={(e) => setPrice12(e.target.value)}
                            onBlur={(e) => formatPrice12(e.target.value)}
                          />
                        </div>
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={4}>
                        <div style={{ marginTop: -7 }} />
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                          <div className={`align-items-left form-group-custom`}>
                            <Form.Label style={{ width: "100%", textAlign: 'left', fontWeight: "650"}}>UOM</Form.Label>
                            <div style={{ width: '100%' }}>
                              <Select loading={isLoading} options={options} dropdownHeight='100px'
                                placeholder="Select UOM" onChange={setUnit} value={unit}
                              />
                            </div>
                          </div>
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={4}>
                        <div style={{ marginTop: -7 }} />
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                          <div className={`align-items-left form-group-custom`}>
                          <Form.Label style={{ width: "100%", textAlign: 'left', fontWeight: "650"}}>Category</Form.Label>
                            <div style={{ width: '100%' }}>
                              <Select loading={isLoading} dropdownHeight='100px' options={options1} style={{ borderRadius: 5, width: '100%' }}
                                onChange={setSelectedCategory}
                                value={selectedCategory}
                                placeholder="Select Category"                              
                              />
                            </div>
                          </div>
                        </Form.Group>
                      </Col>
                  </Row>
                </Container>
                  <Container>
                    <Row>
                      <Col xs={12} md={4}>
                        <div style={{ marginTop: -7 }} />
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                          <div className={`align-items-left form-group-custom`}>
                            <Form.Label style={{ width: "100%", textAlign: 'left', fontWeight: "650"}}> Quantity in Stock </Form.Label>
                            <Form.Control
                              type="text"
                              // placeholder="0.00"
                              value={stockBalance}
                              onChange={(e) => setStockBalance(e.target.value)}
                            />
                          </div>
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={4}>
                        <div style={{ marginTop: -7 }} />
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                          <div className="align-items-left form-group-custom">
                            <Form.Label style={{ width: "100%", textAlign: 'left', fontWeight: "650"}}>
                              Re-Order Level
                            </Form.Label>
                            <div style={{ width: '100%' }}>
                            <Form.Control
                              type="text"
                              value={reOderLevel}
                              onChange={(e) => setReOrderLevel(e.target.value)}
                            />
                          </div>
                          </div>
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={4}>
                        <div style={{ marginTop: -7 }} />
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                          <div className={`align-items-left form-group-custom`}>
                            <Form.Label style={{ width: "100%", textAlign: 'left', fontWeight: "650"}}>Sales GL</Form.Label>
                            <div style={{ width: '100%' }}>
                              <Select loading={isLoading} dropdownHeight='100px' options={extendedSales} style={{ borderRadius: 5, width: '100%' }}
                                onChange={handleSales}
                                placeholder="Select Sales GL"                              
                              />
                            </div>
                          </div>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Container>
                  <Container>
                    <Row>
                      <Col xs={12} md={4}>
                        <div style={{ marginTop: -7 }} />
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                          <div className={`align-items-left form-group-custom`}>
                            <Form.Label style={{ width: "100%", textAlign: 'left', fontWeight: "650"}}>Inventory GL</Form.Label>
                            <div style={{ width: '100%' }}>
                              <Select loading={isLoading} dropdownHeight='100px' options={extendedPurchase}
                                onChange={handlePurchase}
                                placeholder="Select Purchases "
                              />
                              </div>
                          </div>
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={4}>
                        <div style={{ marginTop: -7 }} />
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                          <div className={`align-items-left form-group-custom`}>
                            <Form.Label style={{ width: "100%", textAlign: 'left', fontWeight: "650"}}>Cost GL</Form.Label>
                            <div style={{ width: '100%' }}>
                          <Select loading={isLoading} dropdownHeight='100px' options={extendedCost} style={{ borderRadius: 5, }}
                            onChange={handleCost} placeholder="Select Cost of Good"
                            />
                          </div>
                          </div>
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={4}>
                        <div style={{ marginTop: -7 }} />
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                          <div className={`align-items-left form-group-custom`}>
                            <Form.Label style={{ width: "100%", textAlign: 'left', fontWeight: "650"}}>Discount GL</Form.Label>
                            <div style={{ width: '100%' }}>
                          <Select loading={isLoading} dropdownHeight='100px' options={extendedDisc}
                            onChange={handleDisc}
                            placeholder="Select Discount GL"
                            />
                          </div>
                          </div>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Container>
                  <Container>
                    <Row>
                      <Col xs={12} md={4}>
                        <div style={{ marginTop: -7 }} />
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                          <div className={`align-items-left form-group-custom`}>
                            <Form.Label style={{ width: "100%", textAlign: 'left', fontWeight: "650"}}>Receivables</Form.Label>
                            <div style={{ width: '100%' }}>
                              <Select loading={isLoading} dropdownHeight='100px' options={extendedReceivables} style={{ borderRadius: 5, width: '100%', marginLeft: -3 }}
                                onChange={handleReceivables} placeholder="Select Receivables"
                              />
                            </div>
                          </div>
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={4}>
                        <div style={{ marginTop: -7 }} />
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                          <div className={`align-items-left form-group-custom`}>
                            <Form.Label style={{ width: "100%", textAlign: 'left', fontWeight: "650"}}>Payables </Form.Label>
                            <div style={{ width: '100%' }}>
                              <Select loading={isLoading} dropdownHeight='100px' options={extendedPayables} style={{ borderRadius: 5, width: '100%' }}
                                onChange={handlePayables}
                                placeholder="Select Payables"                              
                              />
                            </div>
                          </div>
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={4}>
                        <div style={{ marginTop: -7 }} />
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                          <div className={`align-items-left form-group-custom`}>
                            <Form.Label style={{ width: "100%", textAlign: 'left', fontWeight: "650"}}>Advance Payment</Form.Label>
                            <div style={{ width: '100%' }}>
                              <Select
                                loading={isLoading}
                                dropdownHeight="100px"
                                options={extendedAdvanced}
                                placeholder="Select Advance"
                                onChange={handleAdvance}
                              />
                            </div>
                          </div>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Container>
                  <Container>
                    <Row>
                      <Col xs={12} md={4}>
                        <div style={{ marginTop: -7 }} />
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                          <div className={`align-items-left form-group-custom`}>
                            <Form.Label style={{ width: "100%", textAlign: 'left', fontWeight: "650"}}>Display Image</Form.Label>
                            <div style={{ width: '100%' }}>
                              <Form.Control
                              type="file"
                              placeholder="Choose file"
                              accept=".jpg, .jpeg, .png"
                              onChange={handleSingleImageChange}
                              />
                            </div>
                          </div>
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={4}>
                        <div style={{ marginTop: -7 }} />
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                          <div className={`align-items-left form-group-custom`}>
                            <Form.Label style={{ width: "100%", textAlign: 'left', fontWeight: "650"}}>Additional Image</Form.Label>
                            <div style={{ width: '100%' }}>
                              <Form.Control
                              type="file"
                              multiple
                              placeholder="Choose file"
                              accept=".jpg, .jpeg, .png"
                              onChange={handleImageChange}
                              />
                            </div>
                          </div>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Container>
              </Form>
            </div>
            <div className={classes.buttonsGroup}>
              <Button variant="success" onClick={createUser}>
                {createLoading ? (
                  <>
                    <Spinner size="sm" />
                    <span style={{ marginLeft: "5px" }}>
                      Creating Product, Please wait...
                    </span>
                  </>
                ) : (
                  "Create Product"
                )}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
