import React, { useState, useEffect } from 'react';
// import "../assets/plugins/bootstrap/css/bootstrap.min.css";
// import "../assets/plugins/metisMenu/metisMenu.min.css";
// import "../assets/plugins/fontawesome/css/all.min.css";
// import "../assets/plugins/typicons/src/typicons.min.css";
// import "../assets/plugins/themify-icons/themify-icons.min.css";
// import "../assets/plugins/datatables/dataTables.bootstrap4.min.css";
// import "../style.css";
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { Navbar, Nav, NavDropdown, Button, Modal, Form, Spinner, Badge } from 'react-bootstrap';
import axios from 'axios';
import Select from 'react-select';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Swal from 'sweetalert2';
// import { InfoFooter } from '../../InfoFooter';
// import { AdminHeaderNav } from '../AdminHeaderNav';
import classes from '../Manage Loan/Report/LoandDis.module.css';
import MainDashboard from '../../Main Dashboard/MainDashoard';
import { BASE_URL } from '../../api/api';
import { toast } from 'react-toastify';
import Arrow from '../../../assets/promix/dArrow-down.svg'
import CoopDashboard from '../../Cooperative Dashboard/CoopDashboard';
import ReceivablesDash from '../../Receivables Dashboard/ReceivablesDash';
// import favicon from '../../Images/faviconn.png'
import CurrencyInput from 'react-currency-input-field';
import LatestCoopDash from '../../LatestDashboard/LatestCoopDash';

function ManageLoansApproval() {
  const location = useLocation();
  const { selectedLoan } = location.state || [];
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [bearer, setBearer] = useState('');
  const [tellerNumber, setTellerNumber] = useState('');
  const [amount, setAmount] = useState('');
  const [selectedPaymentMode, setSelectedPaymentMode] = useState(null)
  const [isLoading, setIsLoading] = useState(false);
  const [loanLoading, setLoanLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [tableData5, setTableData5] = useState([]);
  const handleClose = () => setShow(false);
  const handleClose1 = () => setShow1(false);
  const [selectedDate, setSelectedDate] = useState('');
  const handleShow = () => setShow(true);
  const handleShow1 = () => setShow1(true);
  const [debit1, setDebit1] = useState([]);
  const [eyeClicked, setEyeClicked] = useState(false);
  const [trashClicked, setTrashClicked] = useState(false);
  const [entriesPerPage, setEntriesPerPage] = useState(100);
  const [roleLoading, setRoleLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [user, setUser] = useState("");
  const [permittedHeaders, setPermittedHeaders] = useState([]);
  const [lonn, setLonn] = useState([]);
  const [allLoans, setAllLoans] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [totalEntries, setTotalEntries] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [userType, setUserType] = useState("");
  const [interest, setInterest] = useState(selectedLoan?.loan?.interest || "");
  const [loanType, setLoanType] = useState(selectedLoan?.loan?.description || "");
  const [principalAmount, setPrincipalAmount] = useState(selectedLoan?.principal_amount || "");
  const [interestAmount, setInterestAmount] = useState(selectedLoan?.loan_interest || "");
  const [totalAmount, setTotalAmount] = useState(selectedLoan?.total_loan || "");
  const [amountToPay, setAmountToPay] = useState('');
  const [selectedBank, setSelectedBank] = useState(null);
  // const [selectedLoan, setSelectedLoan] = useState(null);
console.log(selectedLoan);

  const readData = async () => {
    try {
      const detail = await AsyncStorage.getItem('tobi');
      const details = await AsyncStorage.getItem('userToken');
      const detailss = await AsyncStorage.getItem('companyName');
      const admins = await AsyncStorage.getItem('admin');
      const continentName = await AsyncStorage.getItem('continent');
      const regionName = await AsyncStorage.getItem('region');
      const provinceName = await AsyncStorage.getItem('province');


      if (detail !== null) {
        // const firstName = detail.split(' ')[0];
        setUser(detail);

      }

      if (admins !== null) {
        setUserType(admins);
      }
      // if (continentName !== null) {
      //     setContinent(continentName);
      // }
      // if (regionName !== null) {
      //     setRegion(regionName);
      // }
      // if (provinceName !== null) {
      //     setProvince(provinceName);
      // }


      if (details !== null) {
        setBearer(details);
      }
      // if (detailss !== null) {
      //     setCompany(detailss);
      // }
    } catch (e) {
      alert('Failed to fetch the input from storage');
    }
  };

  useEffect(() => {
    readData();
  }, []);


  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${bearer}`
  };

  useEffect(() => {
    const retrieveAdminStatus = async () => {
      try {
        const permitted = await AsyncStorage.getItem('permissions');
        if (permitted) {
          setPermittedHeaders(permitted);
        }
        const adminStatus = await AsyncStorage.getItem('admin');
        setIsAdmin(adminStatus === 'true');




      } catch (error) {
        console.error('Error retrieving admin status:', error);
      }
    };

    retrieveAdminStatus();
  }, []);

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  const formattedAmount = isNaN(parseFloat(amount)) ? '0.00' : parseFloat(amount).toLocaleString('en-US', {
    minimumIntegerDigits: 1,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });

  const fetchBooking = async () => {
    setRoleLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/account/fetch-staff-loan`, { headers });
      const results = response.data?.data;
      // const resultx = response.data?.data?.total;
      // setTotalEntries(resultx);
      setTableData(results);
      console.log(response);
      // const total = response.data?.data?.last_page || 1;
      // setTotalPages(total);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
        const errorStatus = error.response?.data?.message;
        console.log(errorStatus);
        setTableData([]);
      }
    } finally {
      setRoleLoading(false);
    }
  };

  // const fetchBooking = async () => {
  //   setIsLoading(true);
  //   try {
  //     const response = await axios.get(`${BASE_URL}/account/fetch-staff-loan`, { headers });
  //     const results = response.data?.data;
  //     const resultx = response.data?.data?.total;
  //     setTableData(results);
  //     console.log(results);
  //   } catch (error) {
  //     if (error.response && error.response.status === 401) {
  //       // Redirect to login page if unauthorized
  //       navigate('/login');
  //     } else {
  //     const errorStatus = error.response?.data?.message;
  //     console.log(errorStatus);
  //     setTableData([]);
  //   }
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };



  useEffect(() => {
    if (bearer) {
      fetchBooking();

    }
  }, [bearer]);


  function formatDate(dateString) {
    const date = new Date(dateString);
    const formattedDate = `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())} ${padZero(date.getHours())}:${padZero(date.getMinutes())} ${date.getHours() >= 12 ? 'PM' : 'AM'}`;
    return formattedDate;
  }

  function padZero(num) {
    return num < 10 ? `0${num}` : num;
  }





  const handleTrashClick = async (id) => {
    try {
      const response = await axios.get(`${BASE_URL}/destroy?id=${id}`, { headers });
      fetchBooking();
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: response.data.message,
      });
      setTrashClicked(true);
    } catch (error) {
      const errorStatus = error.response?.data?.message;
      Swal.fire({
        icon: 'error',
        title: 'Failed',
        text: errorStatus,
      });
      console.log(errorStatus);
    }
  };



  const handlePrevPage = () => {
    setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages));
  };

  const handleValueChange = (value, name, values) => {
    setAmountToPay(value);

  };
  const handleValueChange9 = (value, name, values) => {
    setTotalAmount(value);

  };

  //filter function
  const filteredData = tableData.filter(item => item?.beneficiary?.name.toLowerCase().includes(searchTerm.toLowerCase()));
  const startIndexx = (currentPage - 1) * entriesPerPage + 1;
  const endIndexx = Math.min(startIndexx + entriesPerPage - 1, totalEntries);
  const displayedData = filteredData.slice(currentPage - 1, totalEntries);



  const handleCreate = () => {
    navigate('/receivables/create_loan_and_advances');
  };

  const handleEyeClick = (id) => {
    const foundLoans = tableData.find(item => item.id === id);
    navigate('/receivables/edit_loan_and_advances', { state: { selectedLoan: foundLoans } });
    setEyeClicked(true);
  };

  const fetchData4 = async () => {
    setRoleLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/income/get-payment-method`, { headers });
      const results = response.data?.data;

      const ospd = results.map((item) => ({
        label: item.name,
        value: item.id,


      }));
      // console.log(results);
      setTableData5(ospd);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
        const errorStatus = error.response?.data?.message;
        console.log(errorStatus);
        setTableData5([]);
      }
    } finally {
      setRoleLoading(false);
    }
  };


  const fetchDebit1 = async () => {
    setLoading(true);

    try {
      const response = await axios.get(`${BASE_URL}/get-cash-and-bank`, { headers });

      const debt1 = response.data?.data;
      const oopt1 = debt1.map((item) => ({
        label: item.gl_name,
        value: item.id,


      }));
      setDebit1(oopt1);



    } catch (error) {
      const errorStatus = error.response.data.message;
      console.error(errorStatus);
    } finally {
      setLoading(false);
    }
  };
  const fetchLoans = async () => {
    setLoading(true);

    try {
      const response = await axios.get(`${BASE_URL}/account/fetch-approved-staff-loan`, { headers });

      const debt1 = response.data?.data;
      setAllLoans(debt1)
      const oopt122 = debt1.map((item) => ({
        label: `${item.loan?.description} - ${item.beneficiary?.name}`,
        value: item.id,
        totalAmount: item.total_loan


      }));
      setLonn(oopt122);



    } catch (error) {
      const errorStatus = error.response.data.message;
      console.error(errorStatus);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (bearer) {
      fetchDebit1();
      fetchLoans();
      fetchData4();
    }
  }, [bearer]);

  const handleBank = (selectedOption) => {
    setSelectedBank(selectedOption);
  }
  // const handleLoanChange = (selectedOption) => {
  //   setSelectedLoan(selectedOption);
  //   const selctedTotalLoan = selectedOption.totalAmount;
  //   setTotalAmount(selctedTotalLoan === null ? "0.00" : selctedTotalLoan);
  // }

  const goBack = () => {
    navigate(-1);
  }

  const disburseLoan = async () => {
    setLoanLoading(true);

    try {
      

      const response = await axios.post(`${BASE_URL}/account/disburse-staff-loan`,
        {
          disbursed_amount: totalAmount,
          transaction_date: selectedDate,
          id: selectedLoan?.id,
          payment_mode: selectedPaymentMode.value || '',
          bank: selectedBank?.value || ''
        },
        { headers }
      );

      console.log(response.data, "heeee");

      navigate(-1);

      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: response.data.message,
      });
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          // toast.error(errorMessage)
          console.log(errorMessage);
        }
        Swal.fire({
          icon: 'error',
          title: 'Failed',
          text: JSON.stringify(error.response.data.message),
        });
      }
    } finally {
      setLoanLoading(false);
    }
  };


  return (

    <div>
      {/* <ToastContainer/> */}
      <LatestCoopDash />
      <div className='newBody'>
        <div className={classes.newWidth}>
          <div className={classes.topPadding}>

            <h3 style={{ fontSize: 30, fontWeight: 700, margin: " 10px 10px 0  0" }}>Disburse Loans</h3>

          </div>
          <div className="wrapper">



            {/* <!-- Page Content  --> */}
            <div className="content-wrapper">



              <div className="main-content">


                <div className="content-header row align-items-center m-0">

                  <div className="col-sm-12 header-title p-0">
                    <div className={classes.actionBtns}>
                      {/* <div className="header-icon text-success mr-3"><i className=""><img src={favicon} style={{ height: 30, width: 30 }} alt="favicon" /></i></div> */}
                      {/* <div className="media-body" style={{ display: 'flex', justifyContent: "space-between", alignItems: "center" }}>
                                                <div>
                                                    <h4 className="font-weight-bold">Create Savings Account </h4>
                                                    <small>Complete the respective fields ....</small>
                                                </div>
                                                <div style={{ marginBottom: 30 }}>
                                                    <Button variant='success' onClick={goBack}><i className="fa-solid fa-arrow-left"></i> Go Back</Button>
                                                </div>
                                            </div> */}

                    </div>

                  </div>
                </div>
              </div>

              <div className="body-content">



                <div className="col-lg-12">
                  <div className="card" style={{ border: 'none' }}>
                    <div className={classes.contentCont} style={{ padding: '20px' }}>
                      {/* <div>
                                                <h5 style={{marginLeft: 20}}>Personal Details</h5>
                                            </div> */}
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="card" style={{ borderLeft: 'none', borderRight: 'none', borderBottom: 'none', borderRadius: '0' }}>
                          <div className="card-body" >
                            <div className="card-body" >

                              {/* <div className={classes.formSec}> */}
                              <div className="row">
                                <div className="col-md-6">
                                  <div className={classes.formGroups}>
                                    <label for="example-text-input" >Transaction Date:</label>
                                    <div className="col-sm-9" style={{ width: '100%' }}>
                                      <input className="form-control" required="" type="date" onChange={handleDateChange} name="date" value={selectedDate} />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className={classes.formGroups}>
                                    <label for="example-text-input" >Loan Type</label>
                                    <div className="col-sm-9" style={{ width: '100%' }}>
                                      <input
                                        name="loan interest"
                                        className="form-control"
                                        value={loanType}
                                        disabled
                                        style={{ textAlign: "right", border: "1px solid #e4e4e4", backgroundColor: "none" }}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className={classes.formGroups}>
                                    <label for="example-text-input" >Interest</label>
                                    <div className="col-sm-9" style={{ width: '100%' }}>
                                      <CurrencyInput

                                        name="loan interest"
                                        decimalsLimit={2}
                                        className="form-control"
                                        value={interest}
                                        disabled
                                        style={{ textAlign: "right", border: "1px solid #e4e4e4", backgroundColor: "none" }}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6" style={{marginTop: 5}}>
                                  <div className={classes.formGroups}>
                                    <label for="example-text-input" >Principal Amount</label>
                                    <div className="col-sm-9" style={{ width: '100%' }}>
                                      <CurrencyInput

                                        name="loan interest"
                                        decimalsLimit={2}
                                        className="form-control"
                                        value={principalAmount}
                                        disabled
                                        style={{ textAlign: "right", border: "1px solid #e4e4e4", backgroundColor: "none" }}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6" style={{marginTop: 5}}>
                                  <div className={classes.formGroups}>
                                    <label for="example-text-input" >Interest Amount</label>
                                    <div className="col-sm-9" style={{ width: '100%' }}>
                                      <CurrencyInput

                                        name="loan interest"
                                        decimalsLimit={2}
                                        className="form-control"
                                        value={interestAmount}
                                        disabled
                                        style={{ textAlign: "right", border: "1px solid #e4e4e4", backgroundColor: "none" }}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6" style={{marginTop: 5}}>
                                  <div className={classes.formGroups}>
                                    <label for="example-text-input" >Total Amount</label>
                                    <div className="col-sm-9" style={{ width: '100%' }}>
                                      <CurrencyInput

                                        name="loan interest"
                                        decimalsLimit={2}
                                        className="form-control"
                                        value={totalAmount}
                                        disabled
                                        style={{ textAlign: "right", border: "1px solid #e4e4e4", backgroundColor: "none" }}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6" style={{marginTop: 5}}>
                                  <div className={classes.formGroups}>
                                    <label for="example-text-input">Mode of Payment:</label>
                                    <div className="col-sm-9" style={{ width: '100%' }}>
                                      <Select
                                        value={selectedPaymentMode}
                                        // style={inputs}
                                        className={classes.select}
                                        onChange={(selectedOption) => setSelectedPaymentMode(selectedOption)}
                                        options={tableData5}
                                        menuPortalTarget={document.body}
                                        styles={{
                                          width: '350px',
                                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                          menu: (provided) => ({
                                            ...provided,
                                            maxHeight: '300px',
                                            overflowY: 'auto',
                                          }),
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                                {/* <div className="col-md-6">
                                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <div className="d-flex align-items-center form-group-custom">
                                    <label for="example-text-input" >Payment Mode</label>
                                      <Form.Select
                                      style={{width: "100%"}}
                                        value={selectedPaymentMode}
                                        onChange={(e) => setSelectedPaymentMode(e.target.value)}
                                      >
                                        <option value="" disabled>Select Payment Mode</option>
                                        {tableData5.map((item) => (
                                          <option key={item.id} value={item.id}>
                                            {item.name}
                                          </option>
                                        ))}
                                      </Form.Select>
                                    </div>
                                  </Form.Group>
                                </div> */}
                                <div className="col-md-6" style={{marginTop: 5}}>
                                  <div className={classes.formGroups}>
                                    <label for="example-text-input">Bank:</label>
                                    <div className="col-sm-9" style={{ width: '100%' }}>
                                      <Select
                                        value={selectedBank}
                                        // style={inputs}
                                        className={classes.select}
                                        onChange={(selectedOption) => handleBank(selectedOption)}
                                        options={debit1}
                                        menuPortalTarget={document.body}
                                        styles={{
                                          width: '350px',
                                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                          menu: (provided) => ({
                                            ...provided,
                                            maxHeight: '300px',
                                            overflowY: 'auto',
                                          }),
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6" style={{marginTop: 5}}>
                                  <div className={classes.formGroups}>
                                    <label for="example-text-input" >Cheque/Teller No.</label>
                                    <div className="col-sm-9" style={{ width: '100%' }}>
                                      <input
                                        name="loan interest"
                                        className="form-control"
                                        value={tellerNumber}
                                        onChange={(e) => setTellerNumber(e.target.value)}
                                        style={{ textAlign: "left", border: "1px solid #e4e4e4", backgroundColor: "none" }}
                                      />
                                    </div>
                                  </div>
                                </div>

                              </div>

                              {/* </div> */} <div style={{ marginTop: 20 }} />
                              {/* <h5 style={{textAlign: "center"}}>Add Bank Accounts</h5> */}



                              <div style={{ marginTop: 20 }} />
                              <div className={`${classes.formIntBtn} ${classes.formIntBtn2}`}>
                                <Button variant="light" className={classes.btn1} onClick={goBack} style={{ marginRight: '5px' }}> Cancel</Button>
                                <Button style={{ borderRadius: 5 }} variant="success" onClick={disburseLoan}>
                                  {loanLoading ? (
                                    <>
                                      <Spinner size='sm' />
                                      <span style={{ marginLeft: '5px' }}>Disbursing, Please wait...</span>
                                    </>
                                  ) : (
                                    "Disburse Loan"
                                  )}
                                </Button>

                              </div>



                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      {/* <InfoFooter /> */}
    </div>

  );
}

export default ManageLoansApproval;