import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Spinner } from 'react-bootstrap';
import axios from 'axios';
import Select from 'react-select';
import { Navbar, Nav, NavDropdown,  Modal, Form,  Badge } from 'react-bootstrap';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { toast } from 'react-toastify';
import CurrencyInput from 'react-currency-input-field';
import classes from './Services.module.css';
import { BASE_URL } from '../api/api';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import StockDashboard from '../Stock Dashboard/StockDashboard';
import LatestEventDash from '../LatestDashboard/LatestEventDash';
import Swal from 'sweetalert2';


const initializeFormData = (selectedBooking) => {
  if (selectedBooking?.labors && selectedBooking.labors.length > 0) {
    return selectedBooking.labors.map(item => ({

    accountName: { value: item?.account?.id || '', label: item?.account?.gl_name || '' },
    DR_CR: item.transaction_type || '', 
    date: item.transaction_date || '',
    description: item.description || '',
    amount: item.amount || '',
    }));
  } else {
    return [{ accountName: '', DR_CR: '', date: '', description: '', amount: '' }];
  }
};

function ViewLabor() {
  const location = useLocation();
  const [selectedOptions, setSelectedOptions] = useState([]);
  const { selectedBooking } = location.state || {};
  const [user, setUser] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [load, setLoad] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [particulars, setParticulars] = useState(selectedBooking?.particulars || "");
  const [totalCharge, setTotalCharge] = useState(selectedBooking?.amount || "");
  const [selectedAssetAccount, setSelectedAssetAccount] = useState(selectedBooking?.asset_account || "");
  const [description, setDescription] = useState(selectedBooking?.description || "");
  const [selectedAccount, setSelectedAccount] = useState(selectedBooking?.booking_account || "");
  const [eventId, setEventId] = useState(selectedBooking?.booking_order || "");
  const [selectedService, setSelectedService] = useState(selectedBooking?.income_account || "");
  const [updateLoading, setUpdateLoading] = useState(false);
  const [formData, setFormData] = useState(() => initializeFormData(selectedBooking));
  const [totalAmount, setTotalAmount] = useState('');
  const [selectedDate, setSelectedDate] = useState(selectedBooking?.event_date || "");
  const [selectedTime, setSelectedTime] = useState(selectedBooking?.start_hour || "");
  const [selectedTime1, setSelectedTime1] = useState(selectedBooking?.end_hour || "");
  const [bearer, setBearer] = useState('');
  const [isEqual, setIsEqual] = useState(false);
  const [totalAmountCredit, setTotalAmountCredit] = useState('');
  const [totalAmountDebit, setTotalAmountDebit] = useState('');
  const [selectOptions, setSelectOptions] = useState([]);
  const navigate = useNavigate();

  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem('userToken');
      const value1 = await AsyncStorage.getItem('tobi');

      if (value !== null) {
        setBearer(value);
      }
      if (value1 !== null) {
        setUser(value1);
      }
    } catch (e) {
      alert('Failed to fetch the input from storage');
    }
  };

  useEffect(() => {
    readData();
  }, []);

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${bearer}`
  };

  const goBack = () => {
    navigate(-1);
  };

  const handleEvent = (event) => {
    setEventId(event.target.value);
  };

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  const calculateTotalAmount = () => {
    const total = formData.reduce((acc, item) => {
      return item.DR_CR === '1' ? acc + parseFloat(item.amount || 0) : acc;
    }, 0);

    const formattedTotal1 = total.toLocaleString('en-US', {
      minimumIntegerDigits: 1,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    setTotalAmountDebit(formattedTotal1);
  };

  const calculateTotalAmountCredit = () => {
    const total = formData.reduce((acc, item) => {
      return item.DR_CR === '2' ? acc + parseFloat(item.amount || 0) : acc;
    }, 0);

    const formattedTotal = total.toLocaleString('en-US', {
      minimumIntegerDigits: 1,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    setTotalAmountCredit(formattedTotal);
  };



  useEffect(() => {
    calculateTotalAmount();
    calculateTotalAmountCredit();
  }, [formData]);

  const handleAccountChange = (selectedOption, rowIndex) => {
    setSelectedOptions((prevOptions) => {
      const updatedOptions = [...prevOptions];
      updatedOptions[rowIndex] = selectedOption;
      return updatedOptions;
    });

    const selectedChart = tableData.find((chart) => chart.id === selectedOption.value);

    if (selectedChart) {
      const updatedFormData = [...formData];
      updatedFormData[rowIndex].accountName = selectedChart.id;
      updatedFormData[rowIndex].accountCode = selectedChart.gl_code;
      setFormData(updatedFormData);
    }
  };
  
  const handleValueChange1 = (value, index) => {
    const updatedFormData = [...formData];
    updatedFormData[index] = {
      ...updatedFormData[index],
      amount: value,
    };
    setFormData(updatedFormData);
  };


  const addRow = () => {
    const newRow = {
      sn: formData.length + 1,
      accountName: '',
      DR_CR: '',
      amount: '',
      description: '',
      date: '',
    };

    setFormData([...formData, newRow]);
  };

  const deleteRow = (index) => {
    const updatedData = formData.filter((_, i) => i !== index);
    setFormData(updatedData);
  };

  const handleFormChange = (index, field, value) => {
    let formattedValue = value;

    // Format the amount field
    if (field === 'amount' && value !== '') {
      formattedValue = (Number(value.replace(/\D/g, '')) || '').toLocaleString();
    }

    const updatedFormData = [...formData];
    updatedFormData[index][field] = formattedValue;
    setFormData(updatedFormData);
  };

  // const handleFormChange = (value, fieldName, rowIndex) => {
  //   const updatedFormData = [...formData];
  //   updatedFormData[rowIndex][fieldName] = value;
  //   setFormData(updatedFormData);
  //   updateTotalCharge(updatedFormData);
  // };

  const updateTotalCharge = (updatedFormData) => {
    const totalChargeValue = updatedFormData.reduce((acc, row) => {
      return acc + (parseFloat(row.amount) || 0);
    }, 0);
    setTotalCharge(totalChargeValue.toFixed(2));
  };

  const fetchCharts = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/account`, { headers });
      const results = response.data?.data;

      const options = results.map((chart) => ({
        label: chart.gl_name,
        value: chart.id,
      }));

      setTableData(results);
      setSelectOptions(options);
    } catch (error) {
      if (error.response && error.response.status === 401) {

        navigate('/login');
      } else {
        const errorStatus = error.response?.data?.message;
        console.log(errorStatus);
        setTableData([]);
      }
    } finally {
      setIsLoading(false);
    }
  };


  useEffect(() => {
    if (bearer) {
      fetchCharts();

    }
  }, [bearer]);

  const createBooking = async () => {
    setUpdateLoading(true);
    try {
      const descriptions = formData.map((row) => row.description).filter((id) => id !== undefined);
      const dates = formData.map((row) => row.date).filter((id) => id !== undefined);
      const accounts = formData.map((row) => row.accountName).filter((id) => id !== undefined);
      const types = formData.map((row) => row.DR_CR).filter((id) => id !== undefined);
      const amounts = formData.map((row) => row.amount).filter((id) => id !== undefined);
      const response = await axios.post(
        `${BASE_URL}/booking/add-labor-expenses`,
        {
          booking_id: selectedBooking?.id,
          description: descriptions,
          amount: amounts,
          transaction_date: dates,
          account_id: accounts,
          transaction_type: types,
          total_debit: Number(totalAmountDebit.replace(/[^0-9.-]+/g, "")),
          total_credit: Number(totalAmountCredit.replace(/[^0-9.-]+/g, ""))
        },
        { headers }
      );
      Swal.fire({
        icon: 'success',
        title: 'Successful',
        text: response.data.message,
      });
      navigate(-1);
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        errorMessage = typeof error.response.data.message === 'string' 
          ? error.response.data.message 
          : JSON.stringify(error.response.data.message);
      }
      Swal.fire({
        icon: 'error',
        title: 'Failed',
        text: JSON.stringify(error.response.data.message),
      });
    } finally {
      setUpdateLoading(false);
    }
  };

  useEffect(() => {
    // Compare the total debit and total credit values
    setIsEqual(totalAmountDebit === totalAmountCredit);
  }, [totalAmountDebit, totalAmountCredit]);

  // const addRow = () => {
  //   setFormData([...formData, { description: '', amount: '' }]);
  // };

  // const deleteRow = (index) => {
  //   const updatedData = formData.filter((_, i) => i !== index);
  //   setFormData(updatedData);
  // };

  // useEffect(() => {
  //   updateTotalCharge(formData);
  // }, [formData]);

  return (
    <div>
      <div className="wrapper">
        <div className="content-wrapper">
          <div className="main-content">
            <LatestEventDash />
            <div className='newBody'>
              <div className={classes.newWidth}>
                      <h3 className={classes.productText}>Other Cost</h3>
                
                <div className={classes.topPadding}>
                  <div className={`${classes.formSecCont}`}>
                    <div className="card-body" style={{ border: 'none' }}>
                      <div className="row">
                      <div style={{ marginTop: 20 }} />
                    <div className="col-md-6">
                          <div className="form-group row">
                            <label for="example-text-input" className="col-sm-3 col-form-label font-weight-400">Event Description:</label>
                            <div className="col-sm-9">
                              <textarea
                              disabled
                                className="form-control"
                                required=""
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                name="description"
                              />
                            </div>
                          </div>
                        </div>
                    <div className="col-md-6">
                          <div className="form-group row">
                            <label for="example-text-input" className="col-sm-3 col-form-label font-weight-400">Event ID:</label>
                            <div className="col-sm-9">
                              <input
                                className="form-control"
                                required=""
                                type="text"
                                onChange={handleEvent}
                                name="eventId"
                                value={eventId}
                                disabled
                              // min={new Date().toISOString().split('T')[0]} // Set min attribute to the current date
                              />
                            </div>
                          </div>
                        </div>
                        <div style={{ marginTop: 20 }} />
                    <div className="col-md-6">
                          <div className="form-group row">
                            <label for="example-text-input" className="col-sm-3 col-form-label font-weight-400">Event Date:</label>
                            <div className="col-sm-9">
                              <input
                                className="form-control"
                                required=""
                                type="date"
                                onChange={handleDateChange}
                                name="date"
                                value={selectedDate}
                                disabled
                              // min={new Date().toISOString().split('T')[0]} // Set min attribute to the current date
                              />
                            </div>
                          </div>
                        </div>                        
                        <div style={{ marginTop: 20 }} />

                        <hr style={{marginTop: 20, color: "#c4c4c4", borderWidth: "0.5px"}}/>
                        <h5 style={{ textAlign: "center", marginTop: 20, }}>Add Other Cost</h5>
                     
                        <div className="table-responsive">
                            <table className="table display table-bordered table-striped table-hover bg-white m-0 card-table">

                              <thead style={{ whiteSpace: "nowrap", textAlign: "center", alignItems: "center" }}>
                                <tr>
                                  {/* <th>#</th> */}
                                  <th style={{ width: '15%', }}>Transaction Date</th>
                                  <th style={{ width: '28%', }}>Account Name</th>
                                  <th>Description</th>
                                  <th style={{ width: '15%', }}>DR/CR</th>
                                  <th style={{ width: '15%', }}>Amount</th>

                                  <th >
                                  <OverlayTrigger
                                    placement="top"
                                    className={classes.btndf1}
                                    overlay={<Tooltip id="button-tooltip"  className={classes.btndf1}>Click here to add new row</Tooltip>}
                                  >
                                    <Button variant="primary" onClick={() => addRow()} style={{ borderRadius: "50px" }}>
                                    <i className="fas fa-plus"></i>
                                  </Button>
                                  </OverlayTrigger>
                                  </th>

                                </tr>
                              </thead>
                              <tbody style={{ whiteSpace: "nowrap", textAlign: "center", alignItems: "center" }}>
                                {formData.map((row, index) => (
                                  <tr key={index}>
                                    {/* <td>{row.sn}</td> */}
                                    <td>
                                      <input
                                        value={row.date}
                                        onChange={(e) => handleFormChange(index, 'date', e.target.value)}
                                        className="form-control"
                                        type="date"
                                      />
                                    </td>
                                    <td >
                                      <Select
                                        value={selectedOptions[index]}
                                        onChange={(selectedOption) => handleAccountChange(selectedOption, index)}
                                        options={selectOptions}
                                        menuPortalTarget={document.body}
                                        styles={{
                                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                          menu: (provided) => ({
                                            ...provided,
                                            // maxHeight: '200px', 
                                            // overflowY: 'auto',
                                          }),
                                        }}
                                      />

                                    </td>
                                    <td>
                                      <textarea
                                        type="text"
                                        className="form-control"
                                        rows={1}
                                        value={row.description}
                                        onChange={(e) => handleFormChange(index, 'description', e.target.value)}
                                      />
                                    </td>
                                    <td>
                                      <Form.Select
                                        value={row.DR_CR}
                                        onChange={(e) => handleFormChange(index, 'DR_CR', e.target.value)}
                                        className="form-control"
                                      >
                                        <option value="">Select</option>
                                        <option value="1">DR</option>
                                        <option value="2">CR</option>
                                      </Form.Select>
                                    </td>
                                    <td>
                                      <CurrencyInput
                                        name={`rowAmount ${index}`} // Provide a unique name for each CurrencyInput
                                        decimalsLimit={2}
                                        value={row.amount}
                                        className="form-control"
                                        onValueChange={(value) => handleValueChange1(value, index)}
                                        style={{ textAlign: "right", border: "none" }}
                                      />
                                      {/* <input
                                              type="text"
                                              className="form-control"
                                              value={row.amount}
                                              onChange={(e) => handleFormChange(index, 'amount', e.target.value)}
                                              style={{ textAlign: "right" }}
                                            /> */}
                                    </td>


                                    <td style={{ textAlign: "left" }}>
                                    <OverlayTrigger
                                      placement="top"
                                      className={classes.btndf1}
                                      overlay={<Tooltip id="button-tooltip"  className={classes.btndf1}>Click here to delete row</Tooltip>}
                                    >
                                      <Button variant="danger" onClick={() => deleteRow(index)} style={{ borderRadius: "50px" }}>
                                        <i className="far fa-trash-alt"></i>
                                      </Button>
                                    </OverlayTrigger>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>

                          </div>
                      </div>
                      <div style={{ marginTop: 20 }} />
                        <div className={classes.form_align}>
                          <Form.Group className="mb-3">
                            <Form.Label>Total Debit:</Form.Label>
                            <Form.Control
                              type="text"
                              value={totalAmountDebit}
                              name="total"
                              readOnly
                              style={{ textAlign: 'right' }}
                            />
                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Form.Label>Total Credit:</Form.Label>
                            <Form.Control
                              type="text"
                              value={totalAmountCredit}
                              name="total"
                              readOnly
                              style={{ textAlign: 'right' }}
                            />
                          </Form.Group>
                        </div>
                      <div style={{ marginTop: 20 }} />
                      <div className={`${classes.formIntBtn} ${classes.formIntBtn2}`}>
                        <Button variant="light" className={classes.btn1} onClick={goBack}>Cancel</Button>
                        <Button  disabled={!isEqual} style={{ borderRadius: 5, marginLeft: 10 }} variant="success" onClick={createBooking}>
                          {updateLoading ? (
                            <>
                              <Spinner size='sm' />
                              <span style={{ marginLeft: '5px' }}>Updating your booking, Please wait...</span>
                            </>
                          ) : (
                            "Update Booking"
                          )}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="overlay"></div>
        </div>
      </div>
    </div>
  );
}

export default ViewLabor;
