import React, { useState, useEffect } from 'react';
// import "../assets/plugins/bootstrap/css/bootstrap.min.css";
// import "../assets/plugins/metisMenu/metisMenu.min.css";
// import "../assets/plugins/fontawesome/css/all.min.css";
// import "../assets/plugins/typicons/src/typicons.min.css";
// import "../assets/plugins/themify-icons/themify-icons.min.css";
// import "../assets/plugins/datatables/dataTables.bootstrap4.min.css";
// import "../style.css";
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import { Navbar, Nav, NavDropdown, Button, Modal, Form, Spinner, Badge } from 'react-bootstrap';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Swal from 'sweetalert2';
// import { InfoFooter } from '../../InfoFooter';
// import { AdminHeaderNav } from '../AdminHeaderNav';
// import classes from './CreateSales.module.css';
import classes from '../../Manage Cooperatives/Manage Members/ManageMember.module.css'
import MainDashboard from '../../Main Dashboard/MainDashoard';
import { BASE_URL } from '../../api/api';
import { toast } from 'react-toastify';
import CurrencyInput from 'react-currency-input-field';
import Select from 'react-select';
import PayablesDash from '../../Payables Dashboard/PayablesDash';
import PaymentDash from '../../Payment Dashboard/PaymentDash';
import LatestDash from '../../LatestDashboard/LatestDash';
// import { BASE_URL } from '../../api/api';
// import classes from './LoanRepayment.module.css'
// import favicon from '../../Images/faviconn.png'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';


function AccountPayables() {
  const navigate = useNavigate();
  const [bearer, setBearer] = useState('');
  const [tableData, setTableData] = useState([]);
  const [tableData1, setTableData1] = useState([]);
  const [tableData2, setTableData2] = useState([]);
  const [benBank, setBenBank] = useState([]);
  const [formData, setFormData] = useState([{ sn: 1,  beneficiaryAccount: '', description: '' , debitGlCode: '',  amount: '', }]);
  const [selectOptions, setSelectOptions] = useState([]);
  const [selectOptions1, setSelectOptions1] = useState([]);
  const [selectOptions2, setSelectOptions2] = useState([]);
  const [totalAmount, setTotalAmount] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [load, setLoad] = useState(false);
  const [loadd, setLoadd] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [selectedFile, setSelectedFile] = useState(null);
  const [user, setUser] = useState('');
  // const [description, setDescription] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [entriesPerPage, setEntriesPerPage] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  // const [totalEntries, setTotalEntries] = useState("");
  // const [totalPages, setTotalPages] = useState(1);
  const [userTypes, setUserTypes] = useState('');
  const [region, setRegion] = useState('');
  const [province, setProvince] = useState('');
  // const [admin, setAdmin] = useState("");
  // const [userTypes, setUserTypes] = useState('');
  const [continent, setContinent] = useState('');
  const [admin, setAdmin] = useState('');
  const [selectAll, setSelectAll] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedBank, setSelectedBank] = useState('');
  const [selectedBankAc, setSelectedBankAc] = useState('');
  const [selectedBankName, setSelectedBankName] = useState('');

  const isSelected = (id) => {
    return selectedRows.includes(id);
  };

  const handleCheckboxChange = (row) => {
    const selectedIndex = selectedRows.findIndex(item => item.id === row.id);
    let updatedSelectedRows = [];
  
    if (selectedIndex === -1) {
      updatedSelectedRows = [...selectedRows, row];
    } else {
      updatedSelectedRows = selectedRows.filter(item => item.id !== row.id);
    }
  
    setSelectedRows(updatedSelectedRows);
    setSelectAll(updatedSelectedRows.length === tableData.length);
    // console.log(updatedSelectedRows);
  };

  const handlePrevPage = () => {
    setCurrentPage(Math.max(currentPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage(Math.min(currentPage + 1, totalPages));
  };

  const filteredData = tableData.filter(item => (item.supplier?.name || item.customer?.name).toLowerCase().includes(searchTerm.toLowerCase()));

  const totalPages = Math.ceil(filteredData.length / entriesPerPage);

  const totalEntries = filteredData.length;
  const startIndexx = (currentPage - 1) * entriesPerPage + 1;
  const endIndexx = Math.min(startIndexx + entriesPerPage - 1, totalEntries);
  const displayedData = filteredData.slice(startIndexx - 1, endIndexx);

  const handleSelectAllChange = (event) => {
    const isChecked = event.target.checked;
    setSelectAll(isChecked);
    setSelectedRows(isChecked ? [...tableData] : []);
    // console.log(isChecked ? [...tableData] : []);
  };

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  const handleBankChange = (selectedOption) => {
    setSelectedBank(selectedOption.value);
    setSelectedBankName(selectedOption.label);
    setSelectedBankAc(selectedOption.acNo);
  };

  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem('userToken');
      const value1 = await AsyncStorage.getItem('tobi');
      const value2 = await AsyncStorage.getItem('admin');
      const admins = await AsyncStorage.getItem('admin');

      const continentName = await AsyncStorage.getItem('continent');
      const regionName = await AsyncStorage.getItem('region');
      const provinceName = await AsyncStorage.getItem('province');

      if (value !== null) {
        setBearer(value);
      }
      if (value1 !== null) {
        setUser(value1);
      }
      if (value2 !== null) {
        setAdmin(value2);
      }

      if (admins !== null) {
        setUserTypes(admins);
      }
      if (continentName !== null) {
        setContinent(continentName);
      }
      if (regionName !== null) {
        setRegion(regionName);
      }
      if (provinceName !== null) {
        setProvince(provinceName);
      }
    } catch (e) {
      alert('Failed to fetch the input from storage');
    }
  };

  useEffect(() => {
    readData();
  }, []);


  const fetchPending = async () => {
    setIsLoading(true);
    try {
        const response = await axios.get(`${BASE_URL}/fetch-pending-payables`, { headers });
        const results = response.data?.data;

        

        setTableData(results);
        console.log("response:", response);
      
    } catch (error) {
        if (error.response && error.response.status === 401) {
            navigate('/login');
        } else {
          let errorMessage = 'An error occurred. Please try again.';
          if (error.response && error.response.data && error.response.data.message) {
              if (typeof error.response.data.message === 'string') {
                  errorMessage = error.response.data.message;
              } else if (Array.isArray(error.response.data.message)) {
                  errorMessage = error.response.data.message.join('; ');
              } else if (typeof error.response.data.message === 'object') {
                toast.error(errorMessage)
                console.log(errorMessage);
              }
          }
            setTableData([]);
        }
    } finally {
      setIsLoading(false);
    }
  };
  
  
  
  useEffect(() => {
    if (bearer) {
      fetchPending();
    }
  }, [bearer]);


  const uploadExcel = async () => {
    setUploadLoading(true);
    try {
      const formData = new FormData();
      formData.append('file', selectedFile[0]);

      // console.log(selectedFile);

      const headers = {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${bearer}`,
      };

      const response = await axios.post(
        'https://api-sme.promixaccounting.com/api/v1/payment_voucher/post-journal',
        formData,
        { headers }
      );
      handleClose();
      navigate('/pending_payment_voucher');
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: response.data.message,
      });

      console.log(response.data.message);
    } catch (error) {
      const errorStatus = error.response.data.message;

      Swal.fire({
        icon: 'error',
        title: 'Failed',
        text: errorStatus,
      });

      console.error(error);
    } finally {
      setUploadLoading(false);
    }
  };


  const handlePosting = async () => {
    setLoadd(true);
    try {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${bearer}`
      };
      const beneficiaries = formData.map((row) => row.beneficiary.value).filter((id) => id !== undefined);
      const beneficiaryAccounts = formData.map((row) => row.beneficiaryAccount.value).filter((id) => id !== undefined);
      const debits = formData.map((row) => row.debitGlCode.value).filter((id) => id !== undefined);
      const credits = formData.map((row) => row.creditGlCode.value).filter((id) => id !== undefined);
      const amounts = formData.map((row) => row.amount).filter((name) => name !== undefined);

      console.log(beneficiaries, debits, credits, amounts, "problem");
      const response = await axios.post(
        `${BASE_URL}/post-bulk-payable`,
        {
          supplier_id: beneficiaries,
          beneficiary_account_id: beneficiaryAccounts,
          debit_gl_code: debits,
          credit_gl_code: credits,
          amount: amounts,
          // description: description,
          transaction_date: selectedDate
        },
        { headers }
      );


      toast.success(response.data.message);
      formData([]);
      // setDescription('');
      setSelectedDate('');
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        toast.error(errorMessage)
        console.log(errorMessage);
      }
    } finally {
      setLoadd(false);
    }
  };
  const fetchBenAcct = async (selectedBeneficiary) => {
    setLoading(true);

    try {
      const response = await axios.get(
        `${BASE_URL}/beneficiaryaccounts/getaccount?beneficiary_id=${selectedBeneficiary}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${bearer}`,
          },
        }
      );

      const paid = response.data?.data || [];
      const benAcc = paid.map((item) => ({
        label: item.bank_name,
        value: item.id,
      }));
      // console.log(paid, 'paid');
      setBenBank(benAcc);
    } catch (error) {
      const errorStatus = error.response.data.message;
      console.error(errorStatus);
    } finally {
      setLoading(false);
    }
  };


  const handleFormChange = (value, field, index) => {
    const updatedFormData = [...formData];
    updatedFormData[index][field] = value;
    setFormData(updatedFormData);
  };


  const deleteRow = (index) => {
    const updatedData = formData.filter((_, i) => i !== index);
    setFormData(updatedData);
  };





  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${bearer}`
  };

  const fetchBeneficiaries = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/beneficiary`, { headers });
      const results = response.data?.data;

      const options = results.map((item) => ({
        label: item.name,
        value: item.id,
      }));

      // setTableData(results);
      setSelectOptions(options);
    } catch (error) {
      const errorStatus = error.response?.data?.message;
      console.log(errorStatus);
      // setTableData([]);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchDebit = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/get-account-by-sub-category-id?sub_category_id=${1}`, { headers });
      const debitAcct = response.data?.data;

      const options1 = debitAcct.map((item) => ({
        label: item.gl_name,
        value: item.id,
        acNo: item.gl_code,
      }));

      setTableData1(debitAcct);
      setSelectOptions1(options1);
    } catch (error) {
      const errorStatus = error.response?.data?.message;
      console.log(errorStatus);
      setTableData1([]);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchAsset = async () => {
    setLoading(true);

    try {
      const response = await axios.get(
        `${BASE_URL}/account`,
        {

          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearer}`
          }
        }
      );
      const assetsAcc = response.data?.data;
      const options2 = assetsAcc.map((item) => ({
        label: item.gl_name,
        value: item.id,
      }));

      setTableData2(assetsAcc);
      setSelectOptions2(options2);


    } catch (error) {
      const errorStatus = error.response.data.message;
      console.error(errorStatus);
    } finally {
      setLoading(false);
    }
  };




  useEffect(() => {
    if (bearer) {
      fetchBeneficiaries();
      fetchDebit();
      fetchAsset();

    }
  }, [bearer]);




  const addRow = () => {
    const newRow = {
      sn: formData.length + 1,
      beneficiaryAccount: '',
      description: '',
      debitGlCode: '',
      amount: '',
    };

    setFormData(prevFormData => [...prevFormData, newRow]);
  };

  const handleValueChange1 = (value, index) => {
    const updatedFormData = [...formData];
    updatedFormData[index] = {
      ...updatedFormData[index],
      amount: value,
    };
    setFormData(updatedFormData);
  };

  const handleFileChange = (event) => {
    const files = event.target.files;
    const fileList = Array.from(files);
    setSelectedFile(fileList);

  };

  const calculateTotalAmount = () => {
    const total = formData.reduce((total, row) => total + parseFloat(row.amount) || 0, 0);
    setTotalAmount(total);
    // console.log(totalQuantity);
  };

  useEffect(() => {
    calculateTotalAmount();
  }, [formData]);


  const handleNavCheque = () => {
    if (selectedRows.length !== 1) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: selectedRows.length === 0 ? 'Please select a row to generate a cheque!' : 'Please select only one row to generate a cheque!',
      });
      return;
    }
    const selectedRow = selectedRows[0]; // Get the selected row object
    const { id, description, amount, supplier, date_of_invoice, invoice_number, transaction_date } = selectedRow; // Destructure the selected row object
    const selectedData = { id, description, amount, supplier, date_of_invoice, invoice_number, transaction_date, selectedBank, selectedDate  }; // Create a new object with the required properties
    // console.log(selectedData, "pending payment");
// console.log(selectedData)
// return;
    navigate('/payment/cheque', { state: selectedData }); 
  }

  const handleGeneratePaymentInstruction = () => {
    
    const rowsToPass = selectedRows.length > 0 ? selectedRows : tableData;
    console.log(rowsToPass)
    // return;
    navigate('/print_instructn', { state: { rowsToPass, selectedBank, selectedDate, selectedBankName, selectedBankAc } });
  };

  return (

    <div>

      <div className="wrapper">
        {/* <!-- Sidebar  --> */}


        {/* <!-- Page Content  --> */}
        <div className="content-wrapper">
          <div className="main-content">

            <LatestDash />

            <div className={classes.finishedbodyCont}>
            <div className='newBody'>
              <div className={classes.newWidth}>

                <Modal show={show} onHide={handleClose} animation={false}>
                  <Modal.Header closeButton>
                    <Modal.Title>Upload Payment</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form style={{ marginTop: 20 }}>
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Upload Excel File</Form.Label>
                        <Form.Control
                          type="file"
                          accept=".xlsx, .xls, .csv"
                          onChange={handleFileChange}
                        />

                      </Form.Group>
                    </Form>
                  </Modal.Body>






                  <Modal.Footer>
                    <Button variant="danger" onClick={handleClose}>
                      Go back
                    </Button>
                    <Button variant="success" onClick={uploadExcel}>
                      {uploadLoading ? (
                        <>
                          <Spinner size='sm' />
                          <span style={{ marginLeft: '5px' }}>Uploading, Please wait...</span>
                        </>
                      ) : (
                        "Upload Payment"
                      )}
                    </Button>
                  </Modal.Footer>
                </Modal>
                <h3 style={{fontSize: '30px', fontWeight: 700, margin:" 10px 10px 0  2rem" }}>Account Payables - Bulk</h3>
                <p style={{margin:" 0 10px 20px 2rem" }}>Make Payments , Generate Payment Instructions , Print Cheques</p>

                {/* <h3 style={{marginTop: '5rem', fontSize: 20, fontWeight: 700}}>Payment Voucher - Bulk Entries</h3> */}


                <div className={classes.topPadding}>
                  <div className={`${classes.formSecCont}  ${classes.awrowRow}`}>
                    <div className={`card-body  ${classes.awrowRow}`} style={{ border: 'none' }}>


                      <div className={`row ${classes.awrowRow}`} >

                        <div className={`col-md-4 ${classes.mblrow}`}>
                          <div className="form-group row">
                            <label for="example-text-input" className={`col-sm-6 col-form-label font-weight-400 ${classes.mbllbl}`}>Select Bank:</label>
                            <div className="col-sm-6" >
                              <div className={classes.widthspace300}>
                            <Select
        
        options={selectOptions1}
        placeholder='Select Bank'
        onChange={handleBankChange}
        menuPortalTarget={document.body}
        styles={{
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          menu: (provided) => ({
            ...provided,
            maxHeight: '300px',
            // maxWidth: '400px',
            overflowY: 'auto',
          }),
        }}
      />
      </div>
                            {/* <Form.Select name="customer" className="form-control" required="" value={setSelectedBank} onChange={handleBankChange} style={{width: "300px"}}>
                                                                            <option value="">Select Bank</option>
                                                                            {tableData1.map((item) => (
                                                                            <option key={item.id} value={item.id}>
                                                                                {item.gl_name}
                                                                            </option>
                                                                            ))}
                                                                        </Form.Select> */}
                            </div>
                          </div>
                        </div>

                        
                        <div style={{ marginTop: 2 }} />
                        <div className={`row ${classes.awrowRow}`}>
                         
                        <div className={`col-md-4 ${classes.mblrow}`}>
                          <div className="form-group row">
                            <label  for="example-text-input"  className={`col-sm-6 col-form-label font-weight-400 ${classes.mbllbl}`}>Transaction Date:</label>
                            <div className="col-sm-6">
                              <input className={`form-control ${classes.widthspacemargin} `} required="" type="date" onChange={handleDateChange} name="date" value={selectedDate}  />
                            </div>
                          </div>
                        </div>
                       
                        <div style={{ marginTop: 20 }} />
                        <div className={`row ${classes.widthSpacespace}`}>
                  
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="table-resposive">
                          <div className={`d-flex justify-content-between align-items-center ${classes.btnStyle}`}>
                            <div className={`${classes.greenbtn} ${classes.btndivStyle}`}>
                              <Button style={{whiteSpace: "nowrap", width: 250}} onClick={handleGeneratePaymentInstruction} variant='success' disabled={selectedRows.length === 0}>
                                Generate Payment Instruction
                              </Button>
                              <Button style={{whiteSpace: "nowrap", width: 150}} variant='success' disabled={selectedRows.length !== 1}  onClick={handleNavCheque}>
                                Print Cheque
                              </Button>
                              {/* <div className={classes.actionsBtns1}>
                                <button>Copy</button>
                                <button>Excel</button>
                                <button>PDF</button>
                                <button className={classes.diffbtn}>Column visibility</button>
                              </div> */}
                              <div className={classes.show}>
                                <label className="d-flex justify-content-start align-items-center">
                                  Show
                                  <select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" className="custom-select custom-select-sm form-control form-control-sm" value={entriesPerPage}
                                    onChange={(e) => {
                                      setEntriesPerPage(parseInt(e.target.value));
                                      setCurrentPage(1);
                                    }}>
                                    <option value={10}>10</option>
                                    <option value={25}>25</option>
                                    <option value={50}>50</option>
                                    <option value={100}>100</option>
                                  </select>
                                  entries
                                </label>
                              </div>
                            </div>
                            <div className="text-right modal-effect ">
                              <div id="DataTables_Table_0_filter" className="dataTables_filter">
                                <div className="d-flex justify-content-start align-items-center">
                                  <div className="mr-2">Search:</div>
                                  <input
                                    type="search"
                                    value={searchTerm}
                                    className="form-control form-control-sm"
                                    placeholder=""
                                    aria-controls="DataTables_Table_0"
                                    onChange={(e) => {
                                      setSearchTerm(e.target.value);
                                      // setCurrentPage(1);
                                    }}
                                  />
                                </div>

                              </div>
                            </div>
                          </div>

                          <div className={classes.table}>
                            {isLoading ? (
                              <p>Fetching Vouchers...</p>
                            ) : (
                              <div className="table-responsive">
                                <table className="table display table-bordered table-striped table-hover bg-white m-0 card-table">

                                  <thead style={{ whiteSpace: 'nowrap' }}>
                                  <tr>
                                      <th>
                                        <input
                                          type="checkbox"
                                          checked={selectAll}
                                          onChange={handleSelectAllChange}
                                        />
                                      </th>
                                      <th>S/N</th>
                                      <th>Transaction Date</th>
                                      <th>Beneficiary's Name</th>
                                      <th>Description</th>
                                      <th>Invoice Number</th>
                                      <th>Total Amount</th>
                                      {/* <th>Contract Amount</th> */}
                                      {/* <th>Total Tax Amount</th> */}
                                      {/* <th>Action</th> */}
                                    </tr>
                                  </thead>
                                  <tbody style={{ whiteSpace: 'nowrap' }}>
                                  {displayedData.map((item, index) => (
                                    <tr key={index}>
                                      <td>
                                          <input
                                            type="checkbox"
                                            onChange={() => handleCheckboxChange(item)}
                                            checked={isSelected(item)}
                                          />
                                        </td>
                                      <td>{index + 1}</td>
                                        <td>{item.transaction_date}</td>
                                      <td>{item.supplier?.name || item.customer?.name}</td>
                                      <td>{item.description}</td>
                                      <td>{item.invoice_number}</td>
                                        {/* <td>{item.pvnumber}</td> */}
                                      {/* <td><Badge bg={item.payment_status === "0" ? "warning" : "success"}>{item.payment_status === "0" ? "Pending" : "Paid"}</Badge></td> */}
                                      {/* <td><Badge bg={item.approval_status === "0" ? "warning" : item.approval_status === "1" ? "success" : item.approval_status === "2" ? "danger" : "null"}>{item.approval_status === "0" ? "Pending" : item.approval_status === "1" ? "Approved" : item.approval_status === "2" ? "Disapproved" : "null"}</Badge></td> */}
                                      <td style={{ textAlign: "right", padding: 50 }}>{parseFloat(item.amount).toLocaleString('en-US', {
                                          minimumIntegerDigits: 1,
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2
                                        })}</td>

                                        {/* <td style={{padding: 50}}>
                                        <Button style={{height: 30, width: 100}}  variant='success' >
                                             Print Cheque
                                            </Button >
                                        </td> */}
                                       
                                        {/* <td style={{textAlign: "left"}}>
                                          <div onClick={() => handleViewVoucher(item.id)} className="btn btn-success-soft btn-sm mr-1">
                                          <i className="far fa-eye" style={{color: "#008a4b", backgroundColor: "#28a7451a", padding: 2, borderColor: "#28a7454d", borderRadius: 5, fontSize:12}}></i>
                                          </div>
                                          <div onClick={() => handleTrashClick(item.id)} className="btn btn-danger-soft btn-sm">
                                          <i className="far fa-trash-alt"  style={{color: "#dc3545", backgroundColor: "#dc35451a", padding: 2, borderColor: "#dc35454d", borderRadius: 5, fontSize:12}}></i>
                                          </div>
                                          <div className="btn btn-sm printbtninv" onClick={() => handlePrintInvoice(item.id)}>
                                            <i className="fa fa-print dawg" style={{backgroundColor:'#e9f6ec', color:'#008a4b', padding: 2, borderColor: "#dc35454d", borderRadius: 5, fontSize:12}}></i>
                                            </div>
                                        </td> */}
                                    </tr>
                                  ))}
                                </tbody>
                                </table>
                              </div>
                            )}
                          </div>
                          <div className={classes.endded}>
                            <p>
                              Showing {startIndexx} to {endIndexx} of {totalEntries} entries
                            </p>
                            <div style={{ display: 'flex' }}>
                              <button
                                style={{ border: 'none', backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'nunito', color: '#000', marginRight: 10, cursor: "pointer" }}
                                onClick={handlePrevPage}
                                disabled={currentPage === 1}
                              >
                                Previous
                              </button>
                              {[...Array(totalPages)].map((_, page) => {
                                // Show only 5 pages or less if available
                                if (page < 5 || page === currentPage - 1 || page === totalPages - 1) {
                                  return (
                                    <button
                                      key={page + 1}
                                      style={{
                                        marginLeft: '0.4rem',
                                        marginRight: '0.4rem',
                                        fontSize: '14px',
                                        fontFamily: 'nunito',
                                        fontWeight: 400,
                                        color: page + 1 === currentPage ? '#ffffff' : '#000',
                                        backgroundColor: page + 1 === currentPage ? '#28a745' : 'gainsboro',
                                        height: '2.5rem',
                                        borderRadius: '89px',
                                        padding: '0.5rem',
                                        border: 'none',
                                        width: '40px',
                                        cursor: "pointer"
                                      }}
                                      onClick={() => setCurrentPage(page + 1)}
                                    >
                                      {page + 1}
                                    </button>
                                  );
                                }
                                return null;
                              })}
                              <button
                                style={{ cursor: "pointer", border: 'none', backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'nunito', color: '#000', marginLeft: 10 }}
                                onClick={handleNextPage}
                                disabled={currentPage === totalPages}
                              >
                                Next
                              </button>
                            </div>
                          </div>

                         
                          

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                        </div>

                      
                       

                      </div>


                      
                    </div>
                  </div>
                </div>













              </div>
              {/* <!--/.main content--> */}
            </div>
            </div>
          </div>
          {/* <InfoFooter /> */}
          {/* <!--/.footer content--> */}
          <div className="overlay"></div>
        </div>
        {/* <!--/.wrapper--> */}


      </div>
    </div>

  );
}

export default AccountPayables;