import React, { useState, useEffect } from "react";
import classes from "./OrderNav.module.css";
import Setting from "../../assets/promix/setting.svg";
import Report from "../../assets/promix/report.svg";
import Contact from "../../assets/promix/contact.svg";
import Area from "../../assets/promix/areas.svg";
import Region from "../../assets/promix/region.svg";
import AReport from "../../assets/promix/areport.svg";
import DReport from "../../assets/promix/dreport.svg";
import Schedule from "../../assets/promix/schedule.svg";
import Logo from "../../assets/promix/logoGreen.png";
import { Navigate, useNavigate } from "react-router-dom";
import { Dropdown, DropdownButton } from "react-bootstrap";
import AsyncStorage from "@react-native-async-storage/async-storage";
import icon7 from "../../Pages/LatestDashboard/Dropeda.svg";
import { Link } from "react-router-dom";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import axios from "axios";
import { BASE_URL } from "../../Pages/api/api";

const OrderNav = () => {
  const navigate = useNavigate();
  const [bearer, setBearer] = useState("");
  const [user, setUser] = useState("");
  const [permits, setPermits] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleLogout = async () => {
    setLoading(true);

    try {
      const response = await axios.post(
        `${BASE_URL}/logout`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${bearer}`,
          },
        }
      );

      navigate("/login");
      //   toast.success(response.data.message);
    } catch (error) {
      let errorMessage = "An error occurred. Please try again.";
      if (error.response) {
        if (error.response.status === 401) {
          navigate("/login");
        } else if (error.response.data && error.response.data.message) {
          if (typeof error.response.data.message === "string") {
            errorMessage = error.response.data.message;
          } else if (Array.isArray(error.response.data.message)) {
            errorMessage = error.response.data.message.join("; ");
          } else if (typeof error.response.data.message === "object") {
            errorMessage = JSON.stringify(error.response.data.message);
          }
        }
      }
      //   toast.error(errorMessage);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem("userToken");
      const value1 = await AsyncStorage.getItem("tobi");
      const permitsss = await AsyncStorage.getItem("modules");

      if (value !== null && value.trim() !== "") {
        setBearer(value);
      } else {
        navigate("/login");
        return;
      }
      if (permitsss !== null) {
        const parsedPermits = JSON.parse(permitsss);
        const permitNames = parsedPermits.map((permit) => permit.name);
        setPermits(permitNames);
        // console.log(permitNames);
      }
      if (value1 !== null) {
        setUser(value1);
      }
    } catch (e) {
      alert("Failed to fetch the input from storage");
    }
  };

  useEffect(() => {
    readData();
  }, []);

  return (
    <div className={classes.topInfo}>
      <div className={classes.latestBodyContainer}>
        <div className={classes.formADFADSection}>
          <div className={classes.formSectionHeader}>
            <div className={classes.formSectionHeaderContents}>
              {/* <div className={classes.ContactInfo}>
                                        <div className={classes.inforConts}>
                                            <p>Mail Us at: <span>info@brookessoftware.com </span>  or Call Us at: <span> +2348161241827,  +2348060202011</span>  </p>
                                        </div> 
                                    </div> */}
              <div className={classes.horinfodashbrd}>
                <div className={classes.stinfo}>
                  <img src={Logo} alt="Icon" className={classes.logoStyle} />
                  <p className={classes.stinfosts}>
                    Professionally Built Accounting System
                  </p>
                  {/* <p className={classes.stinfolvl}>{user.toLocaleUpperCase()}</p> */}
                </div>
                <div className={classes.usrcnr}>
                  {/* <div className={classes.vrtlne}></div> */}
                  <div className={classes.usrinfo}>
                    <div className={classes.usrnminf}>
                      {/* <p className={classes.usrpstn}>Company Name</p> */}
                      <p className={classes.usrnme}>
                        {user.toLocaleUpperCase()}
                      </p>
                      {/* <p className={classes.usrnme}>{company.toLocaleUpperCase()}</p> */}
                      {/* <p className={classes.usrpstn}>Valid till {formatDate(validity)}</p> */}
                    </div>
                    <div className={classes.avtrds}>
                      <Dropdown>
                        <Dropdown.Toggle
                          className={classes.Dropdowns}
                          id="dropdown-receivables"
                        >
                          <span>
                            <img
                              src={icon7}
                              className={classes.avtrdfs}
                              alt="Icon"
                            />
                          </span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className={classes.DropdownMenu}>
                          {/* <Button variant="success" onClick={handleShow} className={classes.btn2}> Add New Stock</Button> */}
                          <Dropdown.Item as={Link} to="/welcome">
                            Switch Account
                          </Dropdown.Item>
                          <Dropdown.Item as={Link} to="/admin/change_password">
                            Change Password
                          </Dropdown.Item>
                          <Dropdown.Item as={Link} to="">
                            Change Working Month
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => handleLogout()}>
                            Logout
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                    {/* <div className={classes.avtrs} onClick={() => handleLogout()} >

                                                <>

                                                    {loading ? <Spinner size="sm" animation="border" variant="light" /> :
                                                        <img src={icon7} alt="Icon" className={classes.avtrs} />
                                                    }
                                                </>
                                            </div> */}
                  </div>

                  {/* <img src={bell} className={classes.bel} /> */}
                </div>
              </div>
              <div style={{border:'1px solid black',height:'2px',opacity:'0.05',width:'100%',marginTop:10}}></div>
            </div>
          </div>
        </div>
      </div>
    
      <div className={classes.divS}>
        <OverlayTrigger
          placement="top"
          className={classes.btndf1}
          overlay={
            <Tooltip id="button-tooltip" className={classes.btndf1}>
              Point Of Sales
            </Tooltip>
          }
        >
          <div
            className={classes.divInner}
            onClick={() => navigate("/sales_ord")}
          >
            <img src={Setting} alt="setting" />
          </div>
        </OverlayTrigger>

        {/* <p className={classes.pos}>Point Of Sales</p> */}
        <OverlayTrigger
          placement="top"
          className={classes.btndf1}
          overlay={
            <Tooltip id="button-tooltip" className={classes.btndf1}>
             Pending Transactions
            </Tooltip>
          }
        >
        <div
          className={`${classes.divInner}`}
          onClick={() => navigate("/failed_order")}
        >
          <img src={Report} alt="contact" />
          {/* <p>Pending Transactions</p> */}
        </div>
        </OverlayTrigger>
        <OverlayTrigger
          placement="top"
          className={classes.btndf1}
          overlay={
            <Tooltip id="button-tooltip" className={classes.btndf1}>
            Completed Transactions
            </Tooltip>
          }
        >
        <div
          className={`${classes.divInner}`}
          onClick={() => navigate("/completed_order")}
        >
          <img src={Contact} alt="report" />
          {/* <p>Completed Transactions</p> */}
        </div>
        </OverlayTrigger>
        <OverlayTrigger
          placement="top"
          className={classes.btndf1}
          overlay={
            <Tooltip id="button-tooltip" className={classes.btndf1}>
           Daily Sales Report
            </Tooltip>
          }
        >
        <div
          className={`${classes.divInner}`}
          onClick={() => navigate("/daily_report")}
        >
          <img src={Contact} alt="report" />
          {/* <p>Completed Transactions</p> */}
        </div>
        </OverlayTrigger>
       
      </div>
    </div>
  );
};

export default OrderNav;
