import React, { useState, useEffect } from 'react';
import classes from './PrintActivity.module.css';
import AsyncStorage from '@react-native-async-storage/async-storage';

import { useLocation } from 'react-router-dom';

export default function ReceivablesReport() {
  const [bearer, setBearer] = useState('');
  const [company, setCompany] = useState('');
  const location = useLocation();
  const [currentDateTime, setCurrentDateTime] = useState('');
  const [totalDebit, setTotalDebit] = useState('');
  const [totalCredit, setTotalCredit] = useState('');
  const [continent, setContinent] = useState('');
  const [region, setRegion] = useState('');
  const [province, setProvince] = useState('');
  const [userType, setUserType] = useState('');

  useEffect(() => {
    const addLeadingZero = (number) => (number < 10 ? `0${number}` : number);

    const currentDate = new Date();
    const day = currentDate.getDate();
    const month = addLeadingZero(currentDate.getMonth() + 1);
    const year = currentDate.getFullYear();
    const minutes = addLeadingZero(currentDate.getMinutes());
    const hours = addLeadingZero(currentDate.getHours() % 12 || 12);
    const seconds = addLeadingZero(currentDate.getSeconds());
    const ampm = currentDate.getHours() >= 12 ? 'PM' : 'AM';

    const formattedDateTime = `${day}/${month}/${year} `;

    setCurrentDateTime(formattedDateTime);
  }, []);

  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem('userToken');
      const value1 = await AsyncStorage.getItem('companyName');
      const continentName = await AsyncStorage.getItem('continent');
      const regionName = await AsyncStorage.getItem('region');
      const provinceName = await AsyncStorage.getItem('province');
      const admins = await AsyncStorage.getItem('admin');

      if (value !== null) {
        setBearer(value);
        // setAuthenticated(true);
      }
      if (value1 !== null) {
        setCompany(value1);

      }
      if (continentName !== null) {
        setContinent(continentName);
      }
      if (regionName !== null) {
        setRegion(regionName);
      }
      if (provinceName !== null) {
        setProvince(provinceName);
      }
      if (admins !== null) {
        setUserType(admins);
      }
    } catch (e) {
      alert('Failed to fetch the input from storage');
    }
  };

  useEffect(() => {
    readData();

  }, []);

  const { documents, selectedDate, selectedEndDate } = location.state || {};
  console.log(documents);
  // const startDate = new Date(documents.start_date);
  // const endDate = new Date(documents.end_date);

  const getOrdinalSuffix = (number) => {
    const suffixes = ['th', 'st', 'nd', 'rd'];
    const v = number % 100;
    return v >= 11 && v <= 13
      ? 'th'
      : suffixes[v % 10] || 'th';
  };

  const formatDate = (date) => {
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();

    return `${day}${getOrdinalSuffix(day)} ${month}, ${year}`;
  };




 




  let totalAmount = 0;
  let totalAmountPaid = 0;
  let totalBalance = 0;



  return (
    <div className={classes.generalbd}>
      <div className={classes.a4}>
        <div className={classes.header}>
          <h1 style={{ fontWeight: 700 }}>{company}</h1>
          <p style={{ width: "100%", fontSize: 18, textTransform: "uppercase" }}>
            {/* {documents?.name} */}
          </p>
          {/* <p style={{ width: "100%", fontSize: 18 }}>{userType === "Continental Accountant" ? continent :
 userType === "Regional Accountant" ? region :
 userType === "Super Admin" ? "Super Admin" :
 userType === "Provincial Accountant" ? province : null}
</p> */}
          <h6 style={{ fontWeight: 500 }}>SCHEDULE OF RECEIVABLES REPORT BETWEEN {selectedDate} AND {selectedEndDate}</h6>
        </div>
        <p><span style={{ fontWeight: 700, color: "#000" }}>DATE PRINTED:</span> {currentDateTime} </p>
        <table className={classes.table11}>
          <thead>
            <tr style={{borderTop: "1px solid #c5c6d0", borderBottom: "1px solid #c5c6d0", backgroundColor: "#e8e8e8"}}>
              <th style={{ textAlign: 'left', width: '30px' }}>Account</th>
              <th style={{ textAlign: 'left', width: '70px' }}>Post Date</th>
              <th style={{ textAlign: 'left', width: '70px' }}>Transaction Date</th>
              <th style={{ textAlign: 'left', width: '50px' }}>Invoice Number</th>
              <th style={{ textAlign: 'left', width: '200px' }}>Customer</th>
              <th style={{ textAlign: 'left', width: '100px' }}>Description</th>
              <th style={{ textAlign: 'right', width: '100px' }}>Amount</th>
              <th style={{ textAlign: 'right', width: '100px' }}>Amount Paid</th>
              <th style={{ textAlign: 'right', width: '100px' }}>Balance</th>
            </tr>
          </thead>
          <tbody>
  {documents.map((account) => {
    let totalAmount = 0;
    let totalAmountPaid = 0;
    let totalBalance = 0;

    return (
      <React.Fragment key={account.main_account_name}>
        {/* Main Account Name and Beginning Balance */}
        <tr>
          <td colSpan="1" style={{ fontWeight: 700,  color: "#1e90ff", }}>
            {account.gl_name}
          </td>
          <td colSpan="7" style={{ fontWeight: "bold", textAlign: "right" }}>
            Beginning Balance
          </td>
          <td colSpan="1" style={{ textAlign: "right", fontWeight: "bold" }}>
            {account.opening_balance.toLocaleString('en-US', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            })}
          </td>
        </tr>

        {/* Display each receivable entry */}
        {account.receivables && account.receivables.length > 0 ? (
          <>
            {account.receivables.map((receivable, index) => {
              totalAmount += receivable.amount;
              totalAmountPaid += receivable.amount_paid;
              totalBalance += receivable.balance;

              return (
                <tr style={{borderTop: "1px solid #c5c6d0", borderBottom: "1px solid #c5c6d0",}} key={index}>
                  <td></td>
                  <td>{new Date(receivable.created_at).toLocaleDateString()}</td>
                  <td>{new Date(receivable.transaction_date).toLocaleDateString()}</td>
                  <td>{receivable.invoice_number}</td>
                  <td>{receivable.customer?.name}</td>
                  <td>{receivable.description}</td>
                  <td style={{ textAlign: 'right' }}>
                  {parseFloat(receivable.amount).toLocaleString('en-US', {
                                          minimumIntegerDigits: 1,
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2
                                        })}
                  </td>
                  <td style={{ textAlign: 'right' }}>
                  {parseFloat(receivable.amount_paid).toLocaleString('en-US', {
                                          minimumIntegerDigits: 1,
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2
                                        })}
                  </td>
                  <td style={{ textAlign: 'right' }}>
                  {parseFloat(receivable.balance).toLocaleString('en-US', {
                                          minimumIntegerDigits: 1,
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2
                                        })}
                  </td>
                </tr>
              );
            })}

            {/* Total Row */}
            <tr style={{borderTop: "1px solid #c5c6d0", borderBottom: "1px solid #c5c6d0", backgroundColor: "#e8e8e8"}}>
              <td colSpan="1" style={{ textAlign: "left", fontWeight: "bold" }}>Total</td>
              <td colSpan="6" style={{ textAlign: "right", fontWeight: "bold" }}>
              {parseFloat(totalAmount).toLocaleString('en-US', {
                                          minimumIntegerDigits: 1,
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2
                                        })}
              </td>
              <td style={{ textAlign: "right", fontWeight: "bold" }}>
              {parseFloat(totalAmountPaid).toLocaleString('en-US', {
                                          minimumIntegerDigits: 1,
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2
                                        })}
              </td>
              <td style={{ textAlign: "right", fontWeight: "bold" }}>
              {parseFloat(totalBalance).toLocaleString('en-US', {
                                          minimumIntegerDigits: 1,
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2
                                        })}
              </td>
            </tr>

            {/* Closing Balance Row */}
            <tr style={{borderTop: "1px solid #c5c6d0", borderBottom: "1px solid #c5c6d0", backgroundColor: "#e8e8e8"}}>
              <td colSpan="1" style={{ textAlign: "left", fontWeight: "bold" }}>
                Closing Balance
              </td>
              <td colSpan="8" style={{ textAlign: "right", fontWeight: "bold" }}>
              {parseFloat(account.closing_balance).toLocaleString('en-US', {
                                          minimumIntegerDigits: 1,
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2
                                        })}
              </td>
            </tr>
              <td >&nbsp;&nbsp;&nbsp;</td>
          </>
        ) : (
          <tr>
            <td colSpan="10" style={{ textAlign: "center" }}>
              No receivables available
            </td>
          </tr>
        )}
      </React.Fragment>
    );
  })}
</tbody>



        </table>


      </div>
    </div>
  )
}