import React, { useState, useEffect } from 'react';
import classes from './CreateUser.module.css';
// import RegLogo from '../../Images/RegistrationLogo.svg'
import { Spinner, Badge, Button, Modal, Form, Row, Col } from 'react-bootstrap';
// import Folder from '../../Images/folder-2.svg';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
// import ArrowLogo from '../../Images/arrow-left.svg';
// import LoanImage from '../../Images/loan bg.svg';
import MainDashoard from '../Main Dashboard/MainDashoard';
// import Ready from '../../Images/nothing.svg'
// import Ready1 from '../../Images/review.svg';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import Table from 'react-bootstrap/Table';
import { BASE_URL } from '../api/api';
import Swal from 'sweetalert2';
import Select from 'react-select';
import ToggleSlider from './ToggleSlider';
import CoopDashboard from '../Cooperative Dashboard/CoopDashboard';
import ReportDashboard from '../ReportDashboard/ReportDashboard';
import { toast } from 'react-toastify';
import PaymentDash from '../Payment Dashboard/PaymentDash';


export default function EditBank() {
  const location = useLocation();
const { userData } = location.state || {};
console.log(userData);
  const [role, setRole] = useState('');
  const [loading, setLoading] = useState(false);
  const [createLoading, setCreateLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [permLoading, setPermLoading] = useState(false);
  const [bearer, setBearer] = useState('');
  const [user, setUser] = useState('');
  const navigate = useNavigate();
  const [permissions, setPermissions] = useState([]);
  const [toggleStates, setToggleStates] = useState({});
  const [toggleStates1, setToggleStates1] = useState({});
  const [perm, setPerm] = useState([]);
  const [permId, setPermId] = useState([]);
  const [checkAll, setCheckAll] = useState(false);
  const [roleLoading, setRoleLoading] = useState(false);
  const [role1, setRole1] = useState("");
  const [eyeClicked, setEyeClicked] = useState(false);
  const [selectedRoleId, setSelectedRoleId] = useState(null);
  const [fullName, setFullName] = useState(userData?.contact_name || "");
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState(userData?.contact_email || "");
  const [selectType, setSelectType] = useState('');
  const [userType, setUserType] = useState([]);
  const [continents, setContinents] = useState([]);
  const [tableData4, setTableData4] = useState([]);
  const [tableData5, setTableData5] = useState([]);
  const [selectedContinent, setSelectedContinent] = useState("");
  const [selectedProvince, setSelectedProvince] = useState("");
  const [selectedRegion, setSelectedRegion] = useState("");
  const [contLoading, setContLoading] = useState(false);
  const [subContloading, setSubContLoading] = useState(false);
  const [subRegloading, setSubRegLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [bankName, setBankName] = useState(userData?.bank_name || "");
  const [address, setAddress] = useState(userData?.address || "");
  const [phone, setPhone] = useState(userData?.contact_phone || "");
  const [banks, setBanks] = useState([]);
  const [bankkk, setBankkk] = useState([]);
  const [selectedBank, setSelectedBank] = useState(userData?.report_gl.id || "");
  // const [userType, setUserType] = useState([]);

  const handleTypeChange = (e) => setSelectType(e.target.value);

  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem('userToken');
      const value1 = await AsyncStorage.getItem('tobi');

      if (value !== null) {
        setBearer(value);
      }
      if (value1 !== null) {
        setUser(value1);
      }
    } catch (e) {
      alert('Failed to fetch the input from storage');
    }
  };

  useEffect(() => {
    readData();
  }, []);

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${bearer}`
  };

  const fetchData1 = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/all-usertype`, { headers });
      const typeList = response.data?.data;
     
      setUserType(typeList);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
        const errorStatus = error.response?.data?.message;
        console.log(errorStatus);
        setTableData([]);
      }
    } finally {
      setIsLoading(false);
    }
  };

  
  const handleBankChange = (selectedOption) => {
    setSelectedBank(selectedOption.value);
  }

  
 



 



 

  const goBack = () => {
    navigate(-1);
  }

  const createUser = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${BASE_URL}/payment-banks/create`,
        {
          bank_name: bankName,
          report_gl: selectedBank,
          address: address,
          contact_name: fullName,
          contact_phone: phone,
          contact_email: email
        },
        { headers }
      );
      console.log(response)
      navigate(-1);
     
      // return
    toast.success(response.data.message);
      console.log(response.data);

    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
            errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
            errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
            errorMessage = JSON.stringify(error.response.data.message);
        }
        toast.error(errorMessage);
        console.log(error);
    }
    } finally {
      setLoading(false);
    }
  };


  const fetchBanks = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/account`, { headers });
      const results = response.data?.data;
setBankkk(results);
      const options1 = results.map((item) => ({
        label: item.gl_name,
        value: item.id,
      }));
      setBanks(options1);
      console.log(results);
      // setSelectOptions(options);
    } catch (error) {
      const errorStatus = error.response?.data?.message;
      console.log(errorStatus);
      setBanks([]);
    } finally {
      setIsLoading(false);
    }
  };

  


  useEffect(() => {
    if (bearer) {
      fetchData1();
      fetchBanks();
  
    }
  }, [bearer]);

  

  



 






  return (
    <div>
    <PaymentDash />
    <div className={classes.finishedbodyCont}>
      <div className={classes.topPadding}>
        <div className={classes.formSecCont}>
          <div className={classes.formSectionHeader}>
            <h3>Update Bank</h3>
          </div>
          <div className={classes.formSectionHeader}>
            <h3 style={{ color: '#2D995F' }}>{user.toLocaleUpperCase()}</h3>
          </div>
        </div>
      </div>

      <div className={classes.mainform}>
        <div className={classes.loandgrantcards}>
          <div className={classes.loanContainer}>
            <div className={classes.loanResponsive}>
              <div className={classes.mainForm}>
                <Form>
                  <Row className="mb-3">
                    <Col md={6}>
                      <Form.Group controlId="formName">
                        <Form.Label>Name of Bank</Form.Label>
                        <Form.Control
                          type="text"
                          value={bankName}
                          onChange={(e) => setBankName(e.target.value)}
                          placeholder="Enter name"
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group controlId="formPhoneNumber">
                      <Form.Label>Contact Name</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter Address"
                              // autoFocus
                              value={fullName}
                              onChange={(e) => setFullName(e.target.value)}
                            />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col md={6}>
                      <Form.Group controlId="formEmail">
                      <Form.Label>Report GL</Form.Label>
                      <Select
                                        // value={selectedBank}
                                        onChange={(selectedOption) => handleBankChange(selectedOption)}
                                        options={banks}
                                        menuPortalTarget={document.body}
                                        styles={{
                                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                          menu: (provided) => ({
                                            ...provided,
                                            maxHeight: '200px',
                                            overflowY: 'auto',
                                          }),
                                        }}
                                      />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group controlId="formSelectType">
                      <Form.Label>Contact Phone Number</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter Phone Number"
                              // autoFocus
                              value={phone}
                              onChange={(e) => setPhone(e.target.value)}
                            />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col md={6}>
                      <Form.Group controlId="formEmail">
                      <Form.Label>Contact Address</Form.Label>
                            <Form.Control
                              as="textarea"
                              rows={3}
                              placeholder="Enter Address"
                              // autoFocus
                              value={address}
                              onChange={(e) => setAddress(e.target.value)}
                            />
                     
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group controlId="formSelectType">
                      <Form.Label>Contact Email Address</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter Email Address"
                              // autoFocus
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                      
                      </Form.Group>
                    </Col>
                  </Row>
                  
                 
                
                </Form>

                
                
              </div>
            </div>
            <div style={{marginTop: 40}}/>
            <div className={`${classes.formIntBtn} ${classes.formIntBtn2}`}>
              <Button variant="light" className={classes.btn1} onClick={goBack}>Cancel</Button>
              <Button variant="success" className={classes.btn2} onClick={createUser}>
                {loading ? (
                  <>
                    <Spinner size='sm' />
                    <span style={{ marginLeft: '5px' }}>Updating Bank, Please wait...</span>
                  </>
                ) : (
                  "Update Bank"
                )}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}