import React, { useState, useEffect } from 'react';
import classes from './Area.module.css';
// import "../../assets/datatables/dataTables.bootstrap4.min.css";
// import RegLogo from '../../Images/RegistrationLogo.svg'
import { Spinner, Badge, Button, Modal, Form } from 'react-bootstrap';
// import Folder from '../../Images/folder-2.svg';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
// import ArrowLogo from '../../Images/arrow-left.svg';
// import LoanImage from '../../Images/loan bg.svg';
import MainDashoard from '../Main Dashboard/MainDashoard';
// import Ready from '../../Images/nothing.svg'
// import Ready1 from '../../Images/review.svg';
import { Link, useNavigate, useParams } from 'react-router-dom'
import Table from 'react-bootstrap/Table';
import { BASE_URL } from '../api/api';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import CoopDashboard from '../Cooperative Dashboard/CoopDashboard';
import ReportDashboard from '../ReportDashboard/ReportDashboard';


export default function Area() {
  const navigate = useNavigate()
  const [currentTime, setCurrentTime] = useState(new Date());
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [bearer, setBearer] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [areaLoad, setAreaLoad] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);
  const [role, setRole] = useState("");
  const [role1, setRole1] = useState("");
  const [checkAll, setCheckAll] = useState(false);
const [selectedArea, setSelectedArea]=useState('')
  const [tableData, setTableData] = useState([]);
  const [tableData1, setTableData1] = useState([]);
  const [tableData2, setTableData2] = useState([]);
  const [tableData3, setTableData3] = useState([]);
  const [tableData4, setTableData4] = useState([]);
  const [tableData5, setTableData5] = useState([]);
  const [tableData6, setTableData6] = useState([]);

  const [permissions, setPermissions] = useState([]);
  const [loading, setLoading] = useState(false);

  const [department, setDepartment] = useState("");
  const [department1, setDepartment1] = useState("");
  const [deptId, setDeptId] = useState("");
  const handleClose = () => setShow(false);
  const handleClose1 = () => setShow1(false);
  const handleShow = () => setShow(true);
  const handleShow1 = () => setShow1(true);

  const [eyeClicked, setEyeClicked] = useState(false);
  const [trashClicked, setTrashClicked] = useState(false);
  const [perm, setPerm] = useState([]);
  const [permId, setPermId] = useState([]);
  const [fullName, setFullName] = useState("");
  const [fullName1, setFullName1] = useState("");
  const [email, setEmail] = useState("");
  const [email1, setEmail1] = useState("");
  const [phone1, setPhone1] = useState("");
  const [phone, setPhone] = useState("");
  const [roles, setRoles] = useState([]);
  const [address, setAddress] = useState("");
  const [office_address, setOfficeAddress] = useState("");
  const [entriesPerPage, setEntriesPerPage] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [users, setUsers] = useState('');

  const [description, setDescription] = useState('');
  const [selectedContinent, setSelectedContinent] = useState('');
  const [selectedProvince, setSelectedProvince] = useState('');
  const [selectedRegion, setSelectedRegion] = useState('');
  const [selectedZone, setSelectedZone] = useState('');

  const [description1, setDescription1] = useState("");
  const [selectedRegion1, setSelectedRegion1] = useState("");
  const [selectedContinent1, setSelectedContinent1] = useState("");
  const [selectedProvince1, setSelectedProvince1] = useState("");
  const [selectedZone1, setSelectedZone1] = useState("");
  const [user, setUser] = useState("");



  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem('userToken');
      const value1 = await AsyncStorage.getItem('tobi');
      const value2 = await AsyncStorage.getItem('permissions');
      const value3 = await AsyncStorage.getItem('admin');

      if (value !== null) {
        setBearer(value);
      }
      if (value1 !== null) {
        setUser(value1);
      }
      // if (value2 !== null) {
      //   setPermissions(value2);
      // }
      // if (value3 !== null) {
      //   setAdmin(value3);
      // }
    } catch (e) {
      alert('Failed to fetch the input from storage');
    }
  };

  useEffect(() => {
    readData();
  }, []);

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${bearer}`
  };



  const fetchArea = async () => {
    setAreaLoad(true);
    try {
      const response = await axios.get(`${BASE_URL}/areas`, { headers });

      // console.log(response);
      const areResult = response.data?.data?.areas;
      setTableData(areResult);
      // console.log(results);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      }else{
        let errorMessage = 'An error occurred. Please try again.';
        if (error.response && error.response.data && error.response.data.message) {
            if (typeof error.response.data.message === 'string') {
                errorMessage = error.response.data.message;
            } else if (Array.isArray(error.response.data.message)) {
                errorMessage = error.response.data.message.join('; ');
            } else if (typeof error.response.data.message === 'object') {
                errorMessage = JSON.stringify(error.response.data.message);
            }
        }
      setTableData([]);
      }
    } finally {
      setAreaLoad(false);
    }
  };

  const fetchZone = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/zones`, { headers });

      // console.log(response);
      const results = response.data?.data?.zones;
      setTableData(results);
      // console.log(results);
    } catch (error) {
      const errorStatus = error.response?.data?.message;
      console.log(errorStatus);
      setTableData([]);
    } finally {
      setIsLoading(false);
    }
  };


  const fetchContinents = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/continents`, { headers });

      // console.log(response);
      const resultsss = response.data?.data;
      setTableData1(resultsss);
      // console.log(results);
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
            if (error.response && error.response.data && error.response.data.message) {
                if (typeof error.response.data.message === 'string') {
                    errorMessage = error.response.data.message;
                } else if (Array.isArray(error.response.data.message)) {
                    errorMessage = error.response.data.message.join('; ');
                } else if (typeof error.response.data.message === 'object') {
                    errorMessage = JSON.stringify(error.response.data.message);
                }
            }
      setTableData1([]);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchSubContinents = async (selectedContinent) => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/continents/getcontinentregions?continent_id=${selectedContinent}`, 
      { headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${bearer}`,
    }, });

      // console.log(response);
      const subCont = response.data?.data;
      setTableData4(subCont);
      // console.log(subCont, "heeeer");
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
            if (error.response && error.response.data && error.response.data.message) {
                if (typeof error.response.data.message === 'string') {
                    errorMessage = error.response.data.message;
                } else if (Array.isArray(error.response.data.message)) {
                    errorMessage = error.response.data.message.join('; ');
                } else if (typeof error.response.data.message === 'object') {
                    errorMessage = JSON.stringify(error.response.data.message);
                }
            }
      setTableData4([]);
    } finally {
      setIsLoading(false);
    }
  };
  const fetchSubContinents1 = async (selectedContinent1) => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/continents/getcontinentregions?continent_id=${selectedContinent1}`, 
      { headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${bearer}`,
    }, });

      // console.log(response);
      const subCont = response.data?.data;
      setTableData4(subCont);
      // console.log(subCont, "heeeer");
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
            if (error.response && error.response.data && error.response.data.message) {
                if (typeof error.response.data.message === 'string') {
                    errorMessage = error.response.data.message;
                } else if (Array.isArray(error.response.data.message)) {
                    errorMessage = error.response.data.message.join('; ');
                } else if (typeof error.response.data.message === 'object') {
                    errorMessage = JSON.stringify(error.response.data.message);
                }
            }
      setTableData4([]);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchSubRegions = async (selectedRegion) => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/regions/getprovincesregion?region_id=${selectedRegion}`, 
      { headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${bearer}`,
    }, });

      // console.log(response);
      const subReg = response.data?.data;
      setTableData5(subReg);
      // console.log(subCont, "heeeer");
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
            if (error.response && error.response.data && error.response.data.message) {
                if (typeof error.response.data.message === 'string') {
                    errorMessage = error.response.data.message;
                } else if (Array.isArray(error.response.data.message)) {
                    errorMessage = error.response.data.message.join('; ');
                } else if (typeof error.response.data.message === 'object') {
                    errorMessage = JSON.stringify(error.response.data.message);
                }
            }
      setTableData5([]);
    } finally {
      setIsLoading(false);
    }
  };
  const fetchSubRegions1 = async (selectedRegion1) => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/regions/getprovincesregion?region_id=${selectedRegion1}`, 
      { headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${bearer}`,
    }, });

      // console.log(response);
      const subReg = response.data?.data;
      setTableData5(subReg);
      // console.log(subCont, "heeeer");
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
            if (error.response && error.response.data && error.response.data.message) {
                if (typeof error.response.data.message === 'string') {
                    errorMessage = error.response.data.message;
                } else if (Array.isArray(error.response.data.message)) {
                    errorMessage = error.response.data.message.join('; ');
                } else if (typeof error.response.data.message === 'object') {
                    errorMessage = JSON.stringify(error.response.data.message);
                }
            }
      setTableData5([]);
    } finally {
      setIsLoading(false);
    }
  };
  const fetchSubProvinces = async (selectedProvince) => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/regions/getzonesregion?province_id=${selectedProvince}`, 
      { headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${bearer}`,
    }, });

      // console.log(response);
      const subRegs = response.data?.data;
      setTableData6(subRegs);
      // console.log(subCont, "heeeer");
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
            if (error.response && error.response.data && error.response.data.message) {
                if (typeof error.response.data.message === 'string') {
                    errorMessage = error.response.data.message;
                } else if (Array.isArray(error.response.data.message)) {
                    errorMessage = error.response.data.message.join('; ');
                } else if (typeof error.response.data.message === 'object') {
                    errorMessage = JSON.stringify(error.response.data.message);
                }
            }
      setTableData6([]);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchSubProvinces1 = async (selectedProvince1) => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/regions/getzonesregion?province_id=${selectedProvince1}`, 
      { headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${bearer}`,
    }, });

      // console.log(response);
      const subRegs = response.data?.data;
      setTableData6(subRegs);
      // console.log(subCont, "heeeer");
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
            if (error.response && error.response.data && error.response.data.message) {
                if (typeof error.response.data.message === 'string') {
                    errorMessage = error.response.data.message;
                } else if (Array.isArray(error.response.data.message)) {
                    errorMessage = error.response.data.message.join('; ');
                } else if (typeof error.response.data.message === 'object') {
                    errorMessage = JSON.stringify(error.response.data.message);
                }
            }
      setTableData6([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (bearer && selectedContinent) {
      fetchSubContinents(selectedContinent);
    }
  }, [bearer, selectedContinent]);

  useEffect(() => {
    if (bearer && selectedContinent1) {
      fetchSubContinents1(selectedContinent1);
    }
  }, [bearer, selectedContinent1]);

  useEffect(() => {
    if (bearer && selectedRegion) {
      fetchSubRegions(selectedRegion);
    }
  }, [bearer, selectedRegion]);

  useEffect(() => {
    if (bearer && selectedRegion1) {
      fetchSubRegions1(selectedRegion1);
    }
  }, [bearer, selectedRegion1]);

  useEffect(() => {
    if (bearer && selectedProvince) {
      fetchSubProvinces(selectedProvince);
    }
  }, [bearer, selectedProvince]);

  useEffect(() => {
    if (bearer && selectedProvince1) {
      fetchSubProvinces1(selectedProvince1);
    }
  }, [bearer, selectedProvince1]);



  



  useEffect(() => {
    if (bearer) {
      fetchArea();
      fetchZone();
      fetchContinents();
      
    }
  }, [bearer]);

  //create beneficiary
  const creatArea = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${BASE_URL}/areas/create_area`,
        {
          description: description,
          continent_id: selectedContinent,
          province_id: selectedProvince,
          region_id: selectedRegion,
          zone_id: selectedZone,
        },
        { headers }
      );
      console.log(response)
      fetchArea();
      handleClose();
      // return
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: response.data.message,
      });
      console.log(response.data);

    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
            if (error.response && error.response.data && error.response.data.message) {
                if (typeof error.response.data.message === 'string') {
                    errorMessage = error.response.data.message;
                } else if (Array.isArray(error.response.data.message)) {
                    errorMessage = error.response.data.message.join('; ');
                } else if (typeof error.response.data.message === 'object') {
                    errorMessage = JSON.stringify(error.response.data.message);
                }
            }
            Swal.fire({
              icon: 'error',
              title: 'Failed',
              text: errorMessage,
            });
    } finally {
      setLoading(false);
    }
  };

  const handleContinentChange = async (event) => {
    setSelectedContinent(event.target.value);
   setSelectedRegion("");
   setSelectedProvince("");
   setSelectedZone("");
  }

  const handleRegionChange = (event) => {
    setSelectedRegion(event.target.value)
  }
  const handleProvinceChange = (event) => {
    setSelectedProvince(event.target.value)
  }
  const handleZoneChange = (event) =>{
    setSelectedZone(event.target.value)
  }

  const handleContinentChange1 = (event) =>{
    setSelectedContinent1(event.target.value)
  }
  const handleRegionChange1 = (event) =>{
    setSelectedRegion1(event.target.value)
  }
  const handleProvinceChange1 = (event) =>{
    setSelectedProvince1(event.target.value)
  }
  const handleZoneChange1 = (event) =>{
    setSelectedZone1(event.target.value)
  }

  //format date
  function formatDate(dateString) {
    const date = new Date(dateString);
    const formattedDate = `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())} ${padZero(date.getHours())}:${padZero(date.getMinutes())} ${date.getHours() >= 12 ? 'PM' : 'AM'}`;
    return formattedDate;
  }

  function padZero(num) {
    return num < 10 ? `0${num}` : num;
  }


  //view records
  const handleEyeClick = (id) => {
    // Find the selected grade from tableData using the id
    const foundArea= tableData.find(item => item.id === id);
    setSelectedArea(foundArea);
    setDescription1(foundArea?.description || '');
    // setSelectedContinent1(foundArea?.continent?.description || '');
    // setSelectedRegion1(foundArea?.region?.description || '');
    // setSelectedProvince1(foundArea?.province?.description || '');
    // setSelectedZone1(foundArea?.zone1?.description || '');

    setShow1(true);
    setEyeClicked(true);
  };


  //delete function
  const handleTrashClick = async (id) => {
    const confirmResult = await Swal.fire({
      title: 'Are you sure?',
      text: 'You are about to delete this area. This action cannot be undone.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    });

    if (confirmResult.isConfirmed) {
    try {
      const response = await axios.get(`${BASE_URL}/areas/delete_area?id=${id}`, { headers });
      fetchArea();
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: response.data.message,
      });
      setTrashClicked(true);
    } catch (error) {
      const errorStatus = error.response?.data?.message;
      Swal.fire({
        icon: 'error',
        title: 'Failed',
        text: errorStatus,
      });
      console.log(errorStatus);
    }
  } else {
     
    Swal.fire({
      icon: 'info',
      title: 'Cancelled',
      text: 'The deletion was cancelled.',
    });
  }
  };

  //update function
  const editArea = async () => {
    setLoading(true);

    try {
      const response = await axios.post(
        `${BASE_URL}/areas/update_area`,
        {
          description: description1,
          continent_id: selectedContinent1,
          province_id: selectedProvince1,
          region_id: selectedRegion1,
          zone_id: selectedZone1,
          id: selectedArea.id
        },
        { headers }
      );
handleClose1();
      fetchArea();

      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: response.data.message,
      });

      // console.log(response.data);
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
            if (error.response && error.response.data && error.response.data.message) {
                if (typeof error.response.data.message === 'string') {
                    errorMessage = error.response.data.message;
                } else if (Array.isArray(error.response.data.message)) {
                    errorMessage = error.response.data.message.join('; ');
                } else if (typeof error.response.data.message === 'object') {
                    errorMessage = JSON.stringify(error.response.data.message);
                }
            }
            Swal.fire({
              icon: 'error',
              title: 'Failed',
              text: errorMessage,
            });
    } finally {
      setLoading(false);
    }
  };


  //filter function
  const filteredData = tableData.filter(item => item.description.toLowerCase().includes(searchTerm.toLowerCase()));

  const totalPages = Math.ceil(filteredData.length / entriesPerPage);

  const handlePrevPage = () => {
    setCurrentPage(Math.max(currentPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage(Math.min(currentPage + 1, totalPages));
  };

  const totalEntries = filteredData.length;
  const startIndexx = (currentPage - 1) * entriesPerPage + 1;
  const endIndexx = Math.min(startIndexx + entriesPerPage - 1, totalEntries);
  const displayedData = filteredData.slice(startIndexx - 1, endIndexx);


  return (
    <div>
      <ReportDashboard />

      <div className={classes.finishedbodyCont}>
        <div className={classes.topPadding}>
          <div className={`${classes.formSecCont}`}>
            <div className={classes.formSectionHeader}>
              <h3>Manage Area</h3>
            </div>
            <div className={classes.formSectionHeader}>
              <h3 style={{ color: '#2D995F' }}>{user.toLocaleUpperCase()}</h3>
            </div>
          </div>
        </div>


        <div className={classes.mainform}>

        <div className={classes.loandgrantcards}>
           
          <div className={classes.loandethead}>
            <div className={classes.formLabel}>
            </div>
            <div className={classes.formIntBtn}>
              <Button variant="success" onClick={handleShow} className={classes.btn2}> Add New Area</Button>
            </div>
          </div>
          <Modal show={show} onHide={handleClose} animation={false}>
                      <Modal.Header closeButton>
                        <Modal.Title>Add Area</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Form style={{ marginTop: 20 }}>
                          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                          <Form.Label>Continent</Form.Label>
                            <Form.Select className="form-control"
                            value={selectedContinent}
                            onChange={handleContinentChange}
                            >
                              <option value="">Select</option>
                              {tableData1.map((item) =>(
                                <option key={item.id} value ={item.id}>
                                  {item.description}
                                </option>
                              ))}
                            </Form.Select>
                            <div style={{ marginTop: 10 }} />
                            <Form.Label>Region</Form.Label>
                            <Form.Select
                              className="form-control"
                              value={selectedRegion}
                              onChange={handleRegionChange}
                            >
                              <option value="">Select</option>
                            {tableData4.map((item) =>(
                              <option value={item.id} key={item.id}>
                                {item.description}
                              </option>
                            ))}
                            </Form.Select>
                            <div style={{ marginTop: 10 }} />
                            <Form.Label>Province</Form.Label>
                            <Form.Select  
                              className="form-control"
                              value={selectedProvince}
                              onChange={handleProvinceChange}
                            >
                              <option value="">Select</option>
                            {tableData5.map((item)=>(
                              <option value={item.id} key={item.id}>
                                {item.description}
                              </option>
                            ))}
                            </Form.Select>
                            <div style={{ marginTop: 10 }} />
                            <Form.Label>Zone</Form.Label>
                            <Form.Select  
                              className="form-control"
                              value={selectedZone}
                              onChange={handleZoneChange}
                            >
                              <option value="">Select Zone</option>
                            {tableData6.map((item) =>(
                              <option key={item.id} value={item.id}>
                                {item.description}
                              </option>
                            ))}
                            </Form.Select>

                            <div style={{ marginTop: 10 }} />
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter Description"
                              
                              value={description}
                              onChange={(e) => setDescription(e.target.value)}
                            />
                          </Form.Group>
                        </Form>
                      </Modal.Body>






                      <Modal.Footer>
                        <Button variant="danger" onClick={handleClose}>
                          Go back
                        </Button>
                        <Button variant="success" onClick={creatArea}>
                    {loading ? (
                      <>
                      <Spinner  size='sm' /> 
                      <span style={{ marginLeft: '5px' }}>Creating area, Please wait...</span>
    </>
  ) : (
                "Create Area"
                      )}
                    </Button>
                      </Modal.Footer>
                    </Modal>

                    <Modal show={show1} onHide={handleClose1} animation={false}>
                      <Modal.Header closeButton>
                        <Modal.Title>Edit Area</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Form style={{ marginTop: 20 }}>
                          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                          <Form.Label>Continent</Form.Label>
                            <Form.Select className="form-control"
                            value={selectedContinent1}
                            onChange={handleContinentChange1}
                            >
                              <option value="">Select</option>
                              {tableData1.map((item) =>(
                                <option key={item.id} value ={item.id}>
                                  {item.description}
                                </option>
                              ))}
                            </Form.Select>
                            <div style={{ marginTop: 10 }} />
                            <Form.Label>Region</Form.Label>
                            <Form.Select
                              className="form-control"
                              value={selectedRegion1}
                              onChange={handleRegionChange1}
                            >
                              <option value="">Select</option>
                            {tableData4.map((item) =>(
                              <option value={item.id} key={item.id}>
                                {item.description}
                              </option>
                            ))}
                            </Form.Select>
                            <div style={{ marginTop: 10 }} />
                            <Form.Label>Province</Form.Label>
                            <Form.Select  
                              className="form-control"
                              value={selectedProvince1}
                              onChange={handleProvinceChange1}
                            >
                              <option value="">Select</option>
                            {tableData5.map((item)=>(
                              <option value={item.id} key={item.id}>
                                {item.description}
                              </option>
                            ))}
                            </Form.Select>
                            <div style={{ marginTop: 10 }} />
                            <Form.Label>Zone</Form.Label>
                            <Form.Select  
                              className="form-control"
                              value={selectedZone1}
                              onChange={handleZoneChange1}
                            >
                              <option value="">Select Zone</option>
                            {tableData6.map((item) =>(
                              <option key={item.id} value={item.id}>
                                {item.description}
                              </option>
                            ))}
                            </Form.Select>

                            <div style={{ marginTop: 10 }} />
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter Description"
                              
                              value={description1}
                              onChange={(e) => setDescription1(e.target.value)}
                            />
                          </Form.Group>
                        </Form>
                      </Modal.Body>






                              <Modal.Footer>
                                <Button variant="danger" onClick={handleClose1}>
                                  Go back
                                </Button>

                                <Button variant="success" onClick={editArea} >
                                  {loading ? ( <> <Spinner  size='sm' />
                                    <span style={{ marginLeft: '5px' }}>
                                      Editing area, Please wait...
                                    </span>
                                    </>) : ("Save Changes")}
                                </Button>
                            </Modal.Footer>
                          </Modal>


           <div className="d-flex justify-content-between align-items-center" style={{ padding: '20px 0 0 0', marginBottom: 20 }}>
             <div className={classes.greenbtn} style={{ display: 'flex', gap: '20px' }}>
               <div className={classes.actionsBtns}>
                 <Button variant='success'>Copy</Button>
                 <Button variant='success'>Excel</Button>
                 <Button variant='success'>PDF</Button>
                 <Button variant='success'>Column visibility</Button>
               </div>
               <div>
                 <label className="d-flex justify-content-start align-items-center">
                   Show
                   <select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" className="custom-select custom-select-sm form-control form-control-sm"
                   //  value={entriesPerPage}
                   //     onChange={(e) => {
                   //     setEntriesPerPage(parseInt(e.target.value));
                   //     setCurrentPage(1);
                   //     }}
                   >
                     <option value={10}>10</option>
                     <option value={25}>25</option>
                     <option value={50}>50</option>
                     <option value={100}>100</option>
                   </select>
                   entries
                 </label>
               </div>
             </div>
             <div className="text-right modal-effect ">
               <div id="DataTables_Table_0_filter" className="dataTables_filter">
                 <div className="d-flex justify-content-start align-items-center">
                   <div className="mr-2">Search:</div>
                   <input
   type="search"
   value={searchTerm}
   className="form-control form-control-sm"
   placeholder=""
   aria-controls="DataTables_Table_0"
   onChange={(e) => setSearchTerm(e.target.value)}
/>
                   {/* <Button style={{marginLeft: 10}} variant="success" onClick= {() => fetchSearch(searchTerm)}>
                 {searchLoading ? (
                     <>
                     <Spinner  size='sm' /> 
                    
   </>
 ) : (
               "Search"
             )}
                 </Button> */}
                 </div>
                 

               </div>
             </div>
           </div>
           <div className={classes.mainTable}>
           {areaLoad ? (
                              <p>Fetching areas...</p>
                            ) : (
                             <div className="table-responsive">
                               <table className="table display table-bordered table-striped table-hover bg-white m-0 card-table">

                               <thead style={{ whiteSpace: 'nowrap' }}>
                                <tr>
                                  <th>ID</th>
                                  <th>Continent</th>
                                  <th>Region</th>
                                  <th>Province</th>
                                  <th>Zones</th>
                                  <th>Areas</th>
                                  <th>Created At</th>
                                  <th>Updated At</th>
                                  <th>Action</th>
                                </tr>
                         </thead>
                         <tbody style={{ whiteSpace: 'nowrap', }}>
                          
                         {displayedData.map((item, index) => (
                                      <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{item.continent?.description}</td>
                                        <td>{item.region?.description}</td>
                                        <td>{item.province?.description}</td>
                                        <td>{item.zone1?.description}</td>
                                        <td>{item.description}</td>
                                        <td>{formatDate(item.created_at)}</td>
                                        <td>{formatDate(item.updated_at)}</td>
                                        <td>
                                          <div onClick={() => handleEyeClick(item.id)} className="btn btn-success-soft btn-sm mr-1">
                                            <i className="far fa-edit"></i>
                                          </div>
                                          <div onClick={() => handleTrashClick(item.id)} className="btn btn-danger-soft btn-sm">
                                            <i className="far fa-trash-alt"></i>
                                          </div>
                                        </td>
                                      </tr>
                                    ))}
                         </tbody>
                               </table>
                             </div>
                           )}
           </div>

           <div className={classes.endded}>
             <p>
             Showing {startIndexx} to {endIndexx} of {totalEntries} entries
             </p>
             <div style={{ display: 'flex' }}>
               <button
                 style={{ border: 'none', backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'nunito', color: '#000', marginRight: 10, cursor: "pointer" }}
                 onClick={handlePrevPage}
                  disabled={currentPage === 1}
               >
                 Previous
               </button>
               {[...Array(totalPages)].map((_, page) => {
                 // Show only 5 pages or less if available
                 if (page < 5 || page === currentPage - 1 || page === totalPages - 1) {
                   return (
                    <button
                    key={page + 1}
                    style={{
                      marginLeft: '0.4rem',
                      marginRight: '0.4rem',
                      fontSize: '14px',
                      fontFamily: 'nunito',
                      fontWeight: 400,
                      color: page + 1 === currentPage ? '#ffffff' : '#000',
                      backgroundColor: page + 1 === currentPage ? '#28a745' : 'gainsboro',
                      height: '2.5rem',
                      borderRadius: '89px',
                      padding: '0.5rem',
                      border: 'none',
                      width: '40px',
                      cursor: "pointer"
                    }}
                    onClick={() => setCurrentPage(page + 1)}
                  >
                    {page + 1}
                  </button>
                   );
                 }
                 return null;
               })}
               <button
                  style={{ cursor: "pointer", border: 'none', backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'nunito', color: '#000', marginLeft: 10 }}
                  onClick={handleNextPage}
                  disabled={currentPage === totalPages}
                >
                  Next
                </button>
             </div>
           </div>

         </div>
        </div>

      </div>

   


    

    </div >
)
}