import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import AsyncStorage from '@react-native-async-storage/async-storage';
import classes from './SalesReceiptts2.module.css';

const SalesReceipttss = () => {
  const [user, setUser] = useState('');
  const [bearer, setBearer] = useState('');
  const navigate = useNavigate();
  const [company, setCompany] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');
  const [email, setEmail] = useState('');
  const [companyAddress, setCompanyAddress] = useState('');

  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem('userToken');
      const value1 = await AsyncStorage.getItem('tobi');
      const value3 = await AsyncStorage.getItem('companyName');
      const value4 = await AsyncStorage.getItem('companyEmail');
      const value5 = await AsyncStorage.getItem('companyPhone');
      const value6 = await AsyncStorage.getItem('companyAddress');

      if (value !== null) setBearer(value);
      if (value1 !== null) setUser(value1);
      if (value3 !== null) setCompany(value3);
      if (value4 !== null) setEmail(value4);
      if (value5 !== null) setPhone(value5);
      if (value6 !== null) setCompanyAddress(value6);
    } catch (e) {
      alert('Failed to fetch the input from storage');
    }
  };

  useEffect(() => {
    readData();
  }, []);

  const location = useLocation();
  const { products, sICode } = location.state || { products: [], sICode: '' };

  // Calculate sub-total by summing up (quantity * price) for each product
  const subTotal = products.reduce(
    (total, product) => total + product.quantity * parseFloat(product.price), // Use quantity from product data
    0
  );

  const VAT_RATE = 0.075; // 7.5%
  const vatAmount = subTotal * VAT_RATE;
  const grandTotal = subTotal + vatAmount;

  const truncateText = (text, maxLength) => {
    return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
  };

  return (
    <div className={classes.receipt}>
      <p>Receipt No: {sICode}</p>
      <h1 className={classes.title}>{company}</h1>
      <p className={classes.address}>{address}</p>
      <p>{companyAddress}</p>
      <p className={classes.contact}>{email}</p>
      <p className={classes.contact}>{phone}</p>
      <div className={classes.divider}></div>
      <h2 className={classes.receiptTitle}>CASH RECEIPT</h2>
      <div className={classes.divider}></div>

      <table className={classes.table}>
        <thead>
          <tr>
            <th className={classes.descriptionHeader}>S/N</th>
            <th className={classes.qtyHeader}>ITEM NAME</th>
            <th className={classes.amountHeader} style={{ textAlign: 'right' }}>
              QTY
            </th>
            <th className={classes.amountHeader} style={{ textAlign: 'right' }}>
              RATE
            </th>
            <th className={classes.amountHeader} style={{ textAlign: 'right' }}>
              AMOUNT
            </th>
          </tr>
        </thead>
        <tbody>
          {products.map((product, index) => (
            <tr key={product.id}>
              <td>{index + 1}</td>
             <td>{truncateText(product.name, 20)}</td>
              <td style={{ textAlign: 'center' }}>{product.quantity}</td> {/* Use quantity */}
              <td style={{ textAlign: 'right' }}>
                {parseFloat(product.price).toLocaleString()}
              </td>
              <td style={{ textAlign: 'right' }}>
                {(product.quantity * parseFloat(product.price)).toLocaleString()} {/* Calculate amount per product */}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className={classes.divider}></div>
      <div className={classes.totalSection}>
        <p className={classes.totalLabel}>Sub total</p>
        <p className={classes.totalAmount1}>{subTotal.toLocaleString()}</p>
      </div>

      <div className={classes.totalSection}>
        <p className={classes.totalLabel}>VAT</p>
        <p className={classes.totalAmount1}>{vatAmount.toLocaleString()}</p>
      </div>

      <div className={classes.totalSection}>
        <p className={classes.totalLabel}>Total</p>
        <p className={classes.totalAmount}>{grandTotal.toLocaleString()}</p>
      </div>
     
     

      <div className={classes.divider}></div>
      <p className={classes.thankYou}>THANK YOU!</p>
      <div className={classes.barcode}></div>
    </div>
  );
};

export default SalesReceipttss;
