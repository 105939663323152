import React, { useState, useEffect } from 'react';
// import "../assets/plugins/bootstrap/css/bootstrap.min.css";
// import "../assets/plugins/metisMenu/metisMenu.min.css";
// import "../assets/plugins/fontawesome/css/all.min.css";
// import "../assets/plugins/typicons/src/typicons.min.css";
// import "../assets/plugins/themify-icons/themify-icons.min.css";
// import "../assets/plugins/datatables/dataTables.bootstrap4.min.css";
// import "../style.css";
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import { Navbar, Nav, NavDropdown, Button, Modal, Form, Spinner, Badge } from 'react-bootstrap';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Swal from 'sweetalert2';
// import { InfoFooter } from '../../InfoFooter';
// import { AdminHeaderNav } from '../AdminHeaderNav';
// import classes from './CreateSales.module.css';
import classes from '../Manage Cooperatives/Manage Members/ManageMember.module.css'
import MainDashboard from '../Main Dashboard/MainDashoard';
import { BASE_URL } from '../api/api';
import { toast } from 'react-toastify';
import CurrencyInput from 'react-currency-input-field';
import Select from 'react-select';
import JournalDash from '../Journal Dashboard/JournalDash';
import LatestDash from '../LatestDashboard/LatestDash';
// import { BASE_URL } from '../../api/api';
// import classes from './LoanRepayment.module.css'
// import favicon from '../../Images/faviconn.png'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';


const initialState = (tableData) => {
  if (tableData && tableData.length > 0) {
    return tableData.map(item => ({
      accountCode: item.gl_code || '', 
      accountName: item.gl_name || '',
      accountType: item.category?.description || '',
      accountId: item.id,  
      DR_CR: '', 
      amount: '', 
    }));
  } else {
    return [{
      accountCode: '', accountName: '', accountType: '', DR_CR: '', amount: '', accountCategory: ''
    }];
  }
};




function CreateBalanceBulk() {
  const navigate = useNavigate();
  const [bearer, setBearer] = useState('');
  const [tableData, setTableData] = useState([]);
  const [formData, setFormData] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectOptions, setSelectOptions] = useState([]);
  const [totalAmountCredit, setTotalAmountCredit] = useState('');
  const [totalAmountDebit, setTotalAmountDebit] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [load, setLoad] = useState(false);
  const [user, setUser] = useState(false);
  const [isEqual, setIsEqual] = useState(false);
  const [userType, setUserType] = useState('');
  const [continent, setContinent] = useState('');
  const [region, setRegion] = useState('');
  const [province, setProvince] = useState('');



  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem('userToken');
      const value1 = await AsyncStorage.getItem('tobi');
      const admins = await AsyncStorage.getItem('admin');
      const continentName = await AsyncStorage.getItem('continent');
      const regionName = await AsyncStorage.getItem('region');
      const provinceName = await AsyncStorage.getItem('province');

      if (value !== null) {
        setBearer(value);
      }
      if (value1 !== null) {
        setUser(value1);

      }
      if (admins !== null) {
        setUserType(admins);
      }
      if (continentName !== null) {
        setContinent(continentName);
      }
      if (regionName !== null) {
        setRegion(regionName);
      }
      if (provinceName !== null) {
        setProvince(provinceName);
      }
    } catch (e) {
      alert('Failed to fetch the input from storage');
    }
  };
  //   console.log("user:", user)

  useEffect(() => {
    readData();
  }, []);

  // const UserGreeting = ({ user }) => {
  // Ensure `user` is a string
  const userString = typeof user === 'string' ? user : '';


  const calculateTotalAmount = () => {
    const total = formData.reduce((acc, item) => {
      return item.DR_CR === '1' ? acc + parseFloat(item.amount || 0) : acc;
    }, 0);

    const formattedTotal1 = total.toLocaleString('en-US', {
      minimumIntegerDigits: 1,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    setTotalAmountDebit(formattedTotal1);
  };

  const calculateTotalAmountCredit = () => {
    const total = formData.reduce((acc, item) => {
      return item.DR_CR === '2' ? acc + parseFloat(item.amount || 0) : acc;
    }, 0);

    const formattedTotal = total.toLocaleString('en-US', {
      minimumIntegerDigits: 1,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    setTotalAmountCredit(formattedTotal);
  };



  useEffect(() => {
    calculateTotalAmount();
    calculateTotalAmountCredit();
  }, [formData]);

  useEffect(() => {
    // Update formData whenever tableData changes
    setFormData(initialState(tableData));
  }, [tableData]);



  const deleteRow = (index) => {
    const updatedData = formData.filter((_, i) => i !== index);
    setFormData(updatedData);
  };

  const handleFormChange = (index, field, value) => {
    let formattedValue = value;

    // Format the amount field
    if (field === 'amount' && value !== '') {
      formattedValue = (Number(value.replace(/\D/g, '')) || '').toLocaleString();
    }

    const updatedFormData = [...formData];
    updatedFormData[index][field] = formattedValue;
    setFormData(updatedFormData);
  };



  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${bearer}`
  };

  const fetchCharts = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/account`, { headers });
      const results = response.data?.data;



      setTableData(results);
      console.log(response);

    } catch (error) {
      if (error.response && error.response.status === 401) {

        navigate('/login');
      } else {
        const errorStatus = error.response?.data?.message;
        console.log(errorStatus);
        setTableData([]);
      }
    } finally {
      setIsLoading(false);
    }
  };


  useEffect(() => {
    if (bearer) {
      fetchCharts();

    }
  }, [bearer]);




  const addRow = () => {
    const newRow = {
     accountCode: '', accountName: '', accountType: '', DR_CR: '', amount: '', accountCategory: ''
    };

    setFormData([...formData, newRow]);
  };




  const createJournal = async () => {
    const confirmed = await Swal.fire({
      title: 'Are you sure?',
      text: 'You are about to save this.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, save it!',
      cancelButtonText: 'No, cancel',
    });

    if (!confirmed.isConfirmed) {
      return;
    }
    
    setLoad(true);

    try {
      // Filter rows that have a valid amount before mapping
      const filteredFormData = formData.filter((row) => row.amount && row.amount.trim() !== '');

      const accountIds = filteredFormData.map((row) => row.accountId).filter((id) => id);
      const directions = filteredFormData.map((row) => row.DR_CR).filter((value) => value);
      const descriptions = filteredFormData.map((row) => row.description).filter((value) => value);
      const amounts = filteredFormData.map((row) => row.amount).filter((value) => value);

      const creditAmount = totalAmountCredit.replace(/,/g, '').replace(/\.00$/, '');
      const debitAmount = totalAmountDebit.replace(/,/g, '').replace(/\.00$/, '');

      const response = await axios.post(`${BASE_URL}/journal/opening-balance-bulk`,
        {
          account_id: accountIds,
          all_credit: creditAmount,
          all_debit: debitAmount,
          amount: amounts,
          direction: directions,
          transaction_date: selectedDate
        },
        { headers }
      );
      fetchCharts();
      setFormData([]);
      setSelectedDate('');
      Swal.fire({
        icon: 'success',
        title: 'Successful',
        text: response.data.message,
      });
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        }
      }
      Swal.fire({
        icon: 'error',
        title: 'Failed',
        text: JSON.stringify(error.response.data.message),
      });
      console.log(errorMessage);
    } finally {
      setLoad(false);
    }
  };


  const handleValueChange1 = (value, index) => {
    const updatedFormData = [...formData];
    updatedFormData[index] = {
      ...updatedFormData[index],
      amount: value,
    };
    setFormData(updatedFormData);
  };


  useEffect(() => {
    calculateTotalAmount();
    calculateTotalAmountCredit();
  }, [formData]);

  useEffect(() => {
    // Compare the total debit and total credit values
    setIsEqual(totalAmountDebit === totalAmountCredit);
  }, [totalAmountDebit, totalAmountCredit]);

  const [selectedDate, setSelectedDate] = useState('');

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  // console.log(formData, "jsklfj");


  return (

    <div>

      <div className="wrapper">
        {/* <!-- Sidebar  --> */}


        {/* <!-- Page Content  --> */}
        <div className="content-wrapper">
          <div className="main-content">

            <LatestDash />
            <div className={classes.finishedbodyCont}>
              <div className='newBody'>
                <div className={classes.newWidth}>

                  <h3 style={{ fontSize: '30px', fontWeight: 700, margin: " 10px 10px 0  20px" }}>Upload Opening Balance - Bulk</h3>
                  <p style={{ margin: " 0 10px 20px 20px" }}>Add, View, and Delete Balance</p>

                  <div className={classes.mainform}>
                    <div className={`col-md-4 ${classes.mblrow}`} style={{ marginLeft: 20 }}>
                      <div className="form-group row">
                        <label htmlFor="example-text-input" className={`col-sm-8 col-form-label font-weight-400 ${classes.lblrow} ${classes.lblrow2}`}>Transaction Date:</label>
                        <div className="col-sm-8">
                          <input className="form-control" required="" type="date" onChange={handleDateChange} name="date" value={selectedDate} style={{ width: "300px", marginLeft: 5 }} />
                        </div>
                      </div>
                    </div>
                    <div className={classes.topPadding}>
                      <div className={`${classes.formSecCont}`}>
                        <div className={`card-body ${classes.awrow112}`} style={{ border: 'none' }}>


                          <div className={`row ${classes.awrow1}`} >





                            <div className={`row ${classes.awrow1}`}>



                          {/* <div style={{ marginTop: 20 }} /> */}
                          <div className={classes.mainTab}>
                          <div className="table-responsive" style={{ maxHeight: '600px', overflowY: 'auto', }}>
                            <table className="table display table-bordered table-striped table-hover bg-white m-0 card-table">

                              <thead style={{ whiteSpace: "nowrap", textAlign: "left",  }}>
                                <tr>
                                  <th>Account Code</th>
                                  <th style={{ width: '10%', }}>Account Name</th>
                                  <th>Account Type</th>
                                  <th style={{ width: '15%', }}>DR/CR</th>
                                  <th style={{ width: '15%', }}>Amount</th>

                                        <th>
                                          <div className="btn btn-sm printbtninv" onClick={() => addRow()}>
                                            <i className="fas fa-plus" style={{ color: "#17a2b8", backgroundColor: "#afe1e9", padding: 2, borderColor: "#b0d1d6", borderRadius: 5, fontSize: 12 }}></i>
                                          </div>
                                        </th>

                                </tr>
                              </thead>
                              {isLoading ? (
                    <p>Fetching data...</p>
                  ) : (
                              <tbody style={{ whiteSpace: "nowrap",  }}>
                                {formData.map((row, index) => (
                                  <tr key={index}>
                                    <td>{row.accountCode} </td>
                                    <td>{row.accountName}</td>
                                    <td> {row.accountType} </td>
                                    <td >
                                      
                                      <Form.Select
                                        value={row.DR_CR}
                                        onChange={(e) => handleFormChange(index, 'DR_CR', e.target.value)}
                                        className="form-control"
                                        style={{height: 30, fontSize: 12, }}
                                      >
                                        <option value="">Select</option>
                                        <option value="1">DR</option>
                                        <option value="2">CR</option>
                                      </Form.Select>
                                      
                                    </td>
                                    <td>
                                      <CurrencyInput
                                        name={`rowAmount ${index}`} // Provide a unique name for each CurrencyInput
                                        decimalsLimit={2}
                                        value={row.amount}
                                        className="form-control"
                                        onValueChange={(value) => handleValueChange1(value, index)}
                                        style={{ textAlign: "right", border: "none", height: 30, fontSize: 12, }}
                                      />
                                    
                                    </td>


                                            <td >
                                              <div className="btn btn-danger-soft btn-sm" onClick={() => deleteRow(index)}>
                                                <i className="far fa-trash-alt" style={{ color: "#dc3545", backgroundColor: "#dc35451a", padding: 2, borderColor: "#dc35454d", borderRadius: 5, fontSize: 12 }}></i>
                                              </div>
                                            </td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    )}
                                  </table>

                                </div>
                              </div>
                            </div>

                            <div style={{ marginTop: 20 }} />
                            <div className={classes.form_align}>
                              <Form.Group className="mb-3">
                                <Form.Label>Total Debit:</Form.Label>
                                <Form.Control
                                  type="text"
                                  value={totalAmountDebit}
                                  name="total"
                                  readOnly
                                  style={{ textAlign: 'right' }}
                                />
                              </Form.Group>
                              <Form.Group className="mb-3">
                                <Form.Label>Total Credit:</Form.Label>
                                <Form.Control
                                  type="text"
                                  value={totalAmountCredit}
                                  name="total"
                                  readOnly
                                  style={{ textAlign: 'right' }}
                                />
                              </Form.Group>
                            </div>


                            <div class={`modal-footer ${classes.overlaybtn100}`} style={{ marginTop: '20px', paddingRight: '20px' }}>
                              <Button
                                className={classes.longbtn}
                                variant='success'
                                onClick={createJournal}
                                disabled={!isEqual}
                                style={{ padding: '5px 30px' }}>
                                {load ? (
                                  <>
                                    <Spinner size='sm' />
                                    <span style={{ marginLeft: '5px' }}>Saving, Please wait...</span>
                                  </>
                                ) : (
                                  "Save"
                                )}
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>













              </div>
            </div>
            {/* <!--/.main content--> */}
          </div>
        </div>
        {/* <InfoFooter /> */}
        {/* <!--/.footer content--> */}
        <div className="overlay"></div>
      </div>
      {/* <!--/.wrapper--> */}


    </div>


  );
}

export default CreateBalanceBulk;