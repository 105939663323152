import React, { useState, useEffect } from 'react';
import classes from './ManageRole.module.css';
import "../../assets/datatables/dataTables.bootstrap4.min.css";
// import RegLogo from '../../Images/RegistrationLogo.svg'
import { Spinner, Badge, Button, Modal, Form } from 'react-bootstrap';
// import Folder from '../../Images/folder-2.svg';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
// import ArrowLogo from '../../Images/arrow-left.svg';
// import LoanImage from '../../Images/loan bg.svg';
import MainDashoard from '../Main Dashboard/MainDashoard';
// import Ready from '../../Images/nothing.svg'
// import Ready1 from '../../Images/review.svg';
import { Link, useNavigate, useParams } from 'react-router-dom'
import Table from 'react-bootstrap/Table';
import { BASE_URL } from '../api/api';
import { toast, ToastContainer } from 'react-toastify';
import Swal from 'sweetalert2';
import CoopDashboard from '../Cooperative Dashboard/CoopDashboard';
import ReportDashboard from '../ReportDashboard/ReportDashboard';
import PaymentDash from '../Payment Dashboard/PaymentDash';


export default function ManagePaymentBank() {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const handleClose = () => setShow(false);
  const handleClose1 = () => setShow1(false);
  const handleShow = () => setShow(true);
  const handleShow1 = () => setShow1(true);
  const [bearer, setBearer] = useState('');
  const [user, setUser] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [contLoading, setContLoading] = useState(false);
  const [roleLoading, setRoleLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [subContloading, setSubContLoading] = useState(false);
  const [subRegloading, setSubRegLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [eyeClicked, setEyeClicked] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [tableData1, setTableData1] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [searchedResult, setSearchedResult] = useState([]);
  const [trashClicked, setTrashClicked] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  // const [admin, setAdmin] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [selectedRegion, setSelectedRegion] = useState("");
  const [selectedRegion1, setSelectedRegion1] = useState("");
  const [selectedContinent, setSelectedContinent] = useState("");
  const [selectedContinent1, setSelectedContinent1] = useState("");
  const [selectedProvince, setSelectedProvince] = useState("");
  const [selectedProvince1, setSelectedProvince1] = useState("");
  const [bankName, setBankName] = useState("");
  const [fullName, setFullName] = useState("");
  const [fullName1, setFullName1] = useState("");
  const [email, setEmail] = useState("");
  const [email1, setEmail1] = useState("");
  const [phone1, setPhone1] = useState("");
  const [selectedRole1, setSelectedRole1] = useState("");
  const [phone, setPhone] = useState("");
  const [userType, setUserType] = useState([]);
  const [continents, setContinents] = useState([]);
  const [tableData4, setTableData4] = useState([]);
  const [tableData5, setTableData5] = useState([]);
  const [address, setAddress] = useState("");
  const [office_address, setOfficeAddress] = useState("");
  const [selectedId, setSelectedId] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const [entriesPerPage, setEntriesPerPage] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalEntries, setTotalEntries] = useState("");
  // const [totalPages, setTotalPages] = useState(1);
  const [userTypes, setUserTypes] = useState('');
  const [region, setRegion] = useState('');
  const [province, setProvince] = useState('');
  const [admin, setAdmin] = useState("");
  // const [userTypes, setUserTypes] = useState('');
  const [continent, setContinent] = useState('');

  


  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem('userToken');
      const value1 = await AsyncStorage.getItem('tobi');
      const value2 = await AsyncStorage.getItem('permissions');
      const value3 = await AsyncStorage.getItem('admin');
      const admins = await AsyncStorage.getItem('admin');

      // const admins = await AsyncStorage.getItem('admin');
      const continentName = await AsyncStorage.getItem('continent');
      const regionName = await AsyncStorage.getItem('region');
      const provinceName = await AsyncStorage.getItem('province');

      if (value !== null) {
        setBearer(value);
      }
      if (value1 !== null) {
        setUser(value1);
      }
      if (value2 !== null) {
        setPermissions(value2);
      }
      if (value3 !== null) {
        setAdmin(value3);
      }
      if (admins !== null) {
        setUserTypes(admin);
    }
    if (continentName !== null) {
      setContinent(continentName);
  }
  if (regionName !== null) {
      setRegion(regionName);
  }
  if (provinceName !== null) {
      setProvince(provinceName);
  }
    } catch (e) {
      alert('Failed to fetch the input from storage');
    }
  };

  useEffect(() => {
    readData();
  }, []);

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${bearer}`
  };



  const fetchData = async () => {
    setRoleLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/payment-banks`, { headers });
      const results = response.data?.data;
      setTableData(results);
      console.log(results);

      toast.success(response.data.message); 
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      }
      // } catch (error) {
        let errorMessage = 'An error occurred. Please try again.';
            if (error.response && error.response.data && error.response.data.message) {
                if (typeof error.response.data.message === 'string') {
                    errorMessage = error.response.data.message;
                } else if (Array.isArray(error.response.data.message)) {
                    errorMessage = error.response.data.message.join('; ');
                } else if (typeof error.response.data.message === 'object') {
                    errorMessage = JSON.stringify(error.response.data.message);
                }
                toast.error(errorMessage)
                console.log(errorMessage);
            }
      } finally {
      setRoleLoading(false);
    }
  };



 

 

     
  useEffect(() => {
    if (bearer) {
      fetchData();
    }
  }, [bearer]);

  const createUser = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${BASE_URL}/users/create-new`,
        {
          name: fullName,
          email: email,
          phone_number: phone,
          type: selectedType,
          continent_id: selectedContinent,
          province_id: selectedProvince,
        },
        { headers }
      );
      console.log(response)
      fetchData();
      handleClose();
      setFullName('');
      setPhone('');
      setEmail('');
      setSelectedType('');
      // return
    toast.success(response.data.message);
      console.log(response.data);

    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
            errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
            errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
            errorMessage = JSON.stringify(error.response.data.message);
        }
        toast.error(errorMessage);
        console.log(error);
    }
    } finally {
      setLoading(false);
    }
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const formattedDate = `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())} ${padZero(date.getHours())}:${padZero(date.getMinutes())} ${date.getHours() >= 12 ? 'PM' : 'AM'}`;
    return formattedDate;
  }

  function padZero(num) {
    return num < 10 ? `0${num}` : num;
  }


  const handlePrevPage = () => {
    setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
  };
  
  const handleNextPage = () => {
    setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages));
  };
  
 

  //filter function
  const filteredData = tableData.filter(item => item?.bank_name.toLowerCase().includes(searchTerm.toLowerCase()));
  // console.log(filteredData, "NEW")
  const totalPages = Math.ceil(filteredData.length / entriesPerPage);
  const startIndexx = (currentPage - 1) * entriesPerPage + 1;
  const endIndexx = Math.min(startIndexx + entriesPerPage - 1, totalEntries);
  const displayedData = filteredData.slice(currentPage - 1, totalEntries);


  const handleEyeClick = (id) => {
    const foundUser = tableData.find((item) => item.id === id);
  
    if (foundUser) {
      navigate('/payment/view_bank', { state: { userData: foundUser } });
    }
  };


useEffect(() => {
}, [selectedUser]);

const editUser = async (id) => {
  setUpdateLoading(true);

  try {
    const response = await axios.post(
      `${BASE_URL}/users/update-user`,
      {
        name: fullName1,
        id: selectedId, 
        email: email1,
        phone_number: phone1,
        type: selectedRole1,
        continent_id: selectedContinent1,
        province_id: selectedProvince1,
        user_id: selectedUser
      },
      { headers }
    );

    fetchData();
handleClose1();
    toast.success(response.data.message);
    // console.log(response.data);
  } catch (error) {
    let errorMessage = 'An error occurred. Please try again.';
            if (error.response && error.response.data && error.response.data.message) {
                if (typeof error.response.data.message === 'string') {
                    errorMessage = error.response.data.message;
                } else if (Array.isArray(error.response.data.message)) {
                    errorMessage = error.response.data.message.join('; ');
                } else if (typeof error.response.data.message === 'object') {
                    errorMessage = JSON.stringify(error.response.data.message);
                }
                toast.error(errorMessage);
                console.log(error);
            }
  } finally {
    setUpdateLoading(false);
  }
};


  const handleTrashClick = async (id) => {
    const confirmed = await Swal.fire({
      title: 'Are you sure?',
      text: 'You are about to delete this user.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel',
    });

    if (!confirmed.isConfirmed) {
      return; // User canceled, do nothing
    }

    try {
      const response = await axios.get(`${BASE_URL}/users/delete?id=${id}`, { headers });
      fetchData();
     toast.success(response.data.message);
      setTrashClicked(true);
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
          if (error.response && error.response.data && error.response.data.message) {
              if (typeof error.response.data.message === 'string') {
                  errorMessage = error.response.data.message;
              } else if (Array.isArray(error.response.data.message)) {
                  errorMessage = error.response.data.message.join('; ');
              } else if (typeof error.response.data.message === 'object') {
                  errorMessage = JSON.stringify(error.response.data.message);
              }
              toast.error(errorMessage)
              console.log(errorMessage);
          }
    }
  };

  const handleTypeChange = (event) => {
    setSelectedType(event.target.value);
};
  const handleContinentChange = (event) => {
    setSelectedContinent(event.target.value);
};
  const handleContinentChange1 = (event) => {
    setSelectedContinent1(event.target.value);
};
  const handleRegionChange = (event) => {
    setSelectedRegion(event.target.value);
};
  const handleRegionChange1 = (event) => {
    setSelectedRegion1(event.target.value);
};
  const handleProvinceChange = (event) => {
    setSelectedProvince(event.target.value);
};
  const handleProvinceChange1 = (event) => {
    setSelectedProvince1(event.target.value);
};

  const handleRoleChange1 = (event) => {
    setSelectedRole1(event.target.value);
};

const handleCreateUser = () => {
  navigate('/payment/create_bank');
}

  return (
    <div>
      <ToastContainer/>
      <PaymentDash />

      <div className={classes.finishedbodyCont}>
        <div className={classes.topPadding}>
          <div className={`${classes.formSecCont}`}>
            <div className={classes.formSectionHeader}>
            {/* <p style={{textTransform: "capitalize", fontWeight: "bold"}}>
              {userTypes === "Continental Accountant" ? continent :
                  userTypes === "Regional Accountant" ? region :
                  userTypes === "Super Admin" ? "Super Admin" :
                  userTypes === "Provincial Accountant" ? province : ''}
              </p> */}
              <h3>Manage Payment Bank</h3>
            </div>
            <div className={classes.formSectionHeader}>
              <h3 style={{ color: '#2D995F' }}>{user.toLocaleUpperCase()}</h3>
              {/* <p style={{marginTop: -10}}>{userTypes}</p>  */}
            </div>
          </div>
        </div>


        <div className={classes.mainform}>

          <div className={classes.loandgrantcards}>
           
            <div className={classes.loandethead}>
              <div className={classes.formLabel}>
              </div>
              <div className={classes.formIntBtn}>
                <Button variant="success" onClick={handleCreateUser} className={classes.btn2}> Add New</Button>
              </div>
            </div>
            <Modal show={show} onHide={handleClose} animation={false} size='lg'>
                      <Modal.Header closeButton>
                        <Modal.Title>Add New Bank</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Form style={{ marginTop: 20 }}>
                          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Name of Bank</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter Name"
                              // autoFocus
                              value={bankName}
                              onChange={(e) => setBankName(e.target.value)}
                            />
                          </Form.Group>
                            <div style={{ marginTop: 10 }} />
                          <Form.Group className="mb-3">
                            <Form.Label>Contact Name</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter Address"
                              // autoFocus
                              value={fullName}
                              onChange={(e) => setFullName(e.target.value)}
                            />
                          </Form.Group>
                            <div style={{ marginTop: 10 }} />
                          <Form.Group className="mb-3">
                            <Form.Label>Contact Address</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter Address"
                              // autoFocus
                              value={address}
                              onChange={(e) => setAddress(e.target.value)}
                            />
                          </Form.Group>
                            <div style={{ marginTop: 10 }} />
                          <Form.Group className="mb-3">
                            <Form.Label>Contact Phone Number</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter Phone Number"
                              // autoFocus
                              value={phone}
                              onChange={(e) => setPhone(e.target.value)}
                            />
                          </Form.Group>
                            <div style={{ marginTop: 10 }} />
                          <Form.Group className="mb-3" >
                            <Form.Label>Contact Email Address</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter Email Address"
                              // autoFocus
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </Form.Group>
                            <div style={{ marginTop: 10 }} />
                          <Form.Group className="mb-3" >
                            <Form.Label>Report GL</Form.Label>
                            <Form.Select
                                className="form-control"
                                as="select"
                                value={selectedType}
                                onChange={handleTypeChange}
                              >
                                <option value="" disabled>Select Report GL</option>
                                {userType.map((item) => (
                                  <option key={item.id} value={item.id}>
                                    {item.type}
                                  </option>
                                ))}
                              </Form.Select>
                          </Form.Group>

                         
                        </Form>
                      </Modal.Body>






                      <Modal.Footer>
                        <Button variant="danger" onClick={handleClose}>
                          Go back
                        </Button>
                        <Button variant="success" onClick={createUser}>
                    {loading ? (
                      <>
                      <Spinner  size='sm' /> 
                      <span style={{ marginLeft: '5px' }}>Creating user, Please wait...</span>
    </>
  ) : (
                "Create User"
                      )}
                    </Button>
                      </Modal.Footer>
                    </Modal>

                    <Modal show={show1} onHide={handleClose1} animation={false}>
                              <Modal.Header closeButton>
                                <Modal.Title>Edit User</Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                <Form style={{ marginTop: 20 }}>
                                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Full Name</Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="Enter Full Name"
                                      // autoFocus
                                      value={fullName1}
                                      onChange={(e) => setFullName1(e.target.value)}
                                    />
                                  </Form.Group>
                                    <div style={{ marginTop: 10 }} />
                                  <Form.Group className="mb-3" >
                                    <Form.Label>Email Address</Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="Enter Email Address"
                                      // autoFocus
                                      value={email1}
                                      onChange={(e) => setEmail1(e.target.value)}
                                    />
                                  </Form.Group>

                                    <div style={{ marginTop: 10 }} />
                                  <Form.Group className="mb-3" >
                                    <Form.Label>Phone Number</Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="Enter Phone Number"
                                      // autoFocus
                                      value={phone1}
                                      onChange={(e) => setPhone1(e.target.value)}
                                    />       
                                  </Form.Group>                             
                                    <div style={{ marginTop: 10 }} />
                                  <Form.Group className="mb-3" >
                                    <Form.Label>Select Type</Form.Label>
                                    <Form.Select
                                      className="form-control"
                                      as="select"
                                      value={selectedRole1}
                                      onChange={handleRoleChange1}
                                    >
                                      <option value="" disabled>Select Type</option>
                                      {userType.map((item) => (
                                        <option key={item.id} value={item.id}>
                                          {item.type}
                                        </option>
                                      ))}
                                    </Form.Select>
                                  </Form.Group>
                                  {selectedRole1 === '1' && (
                                      <>
                                        <div style={{ marginTop: 10 }} />
                                      <Form.Group className="mb-3">
                                        <Form.Label>Select Continent</Form.Label>
                                        <Form.Select
                                            className="form-control"
                                            as="select"
                                            value={selectedContinent1}
                                            onChange={handleContinentChange1}
                                          >
                                            <option value="" disabled>Select Continent</option>
                                            {continents.map((item) => (
                                              <option key={item.id} value={item.id}>
                                                {item.description}
                                              </option>
                                            ))}
                                          </Form.Select>
                                        <div style={{ marginTop: 10 }} />
                                        </Form.Group>
                                      <Form.Group className="mb-3">
                                        <Form.Label>Select Region</Form.Label>
                                        <Form.Select
                                            className="form-control"
                                            as="select"
                                            value={selectedRegion1}
                                            onChange={handleRegionChange1}
                                          >
                                            <option value="" disabled>Select Region</option>
                                            {tableData4.map((item) => (
                                              <option key={item.id} value={item.id}>
                                                {item.description}
                                              </option>
                                            ))}
                                          </Form.Select>
                                      </Form.Group>
                                        <div style={{ marginTop: 10 }} />
                                      <Form.Group className="mb-3" >
                                        <Form.Label>Select Province</Form.Label>
                                        <Form.Select
                                            className="form-control"
                                            as="select"
                                            value={selectedProvince1}
                                            onChange={handleProvinceChange1}
                                          >
                                            <option value="" disabled>Select Province</option>
                                            {tableData5.map((item) => (
                                              <option key={item.id} value={item.id}>
                                                {item.description}
                                              </option>
                                            ))}
                                          </Form.Select>
                                      </Form.Group>
                                      </>
                                      )}
                                </Form>
                              </Modal.Body>






                              <Modal.Footer>
                                <Button variant="danger" onClick={handleClose1}>
                                  Go back
                                </Button>
                                <Button variant="success" onClick={editUser}>
                    {updateLoading ? (
                      <>
                      <Spinner  size='sm' /> 
                      <span style={{ marginLeft: '5px' }}>Updating user, Please wait...</span>
    </>
  ) : (
                "Update User"
                      )}
                    </Button>
                            </Modal.Footer>
                          </Modal>


            <div className={`d-flex justify-content-between align-items-center ${classes.btnStyle}`}>
              <div className={`${classes.greenbtn} ${classes.btndivStyle}`}>
                <div className={classes.actionsBtnsspec}>
                  <Button variant='success'>Copy</Button>
                  <Button variant='success'>Excel</Button>
                  <Button variant='success'>PDF</Button>
                  <Button variant='success'>Column visibility</Button>
                </div>
                <div className={classes.show}>
                  <label className="d-flex justify-content-start align-items-center">
                    Show
                    <select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" className="custom-select custom-select-sm form-control form-control-sm"
                    //  value={entriesPerPage}
                    //     onChange={(e) => {
                    //     setEntriesPerPage(parseInt(e.target.value));
                    //     setCurrentPage(1);
                    //     }}
                    >
                      <option value={10}>10</option>
                      <option value={25}>25</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                    </select>
                    entries
                  </label>
                </div>
              </div>
              <div className="text-right modal-effect ">
                <div id="DataTables_Table_0_filter" className="dataTables_filter">
                  <div className="d-flex justify-content-start align-items-center">
                    <div className="mr-2">Search:</div>
                    <input
    type="search"
    value={searchTerm}
    className="form-control form-control-sm"
    placeholder=""
    aria-controls="DataTables_Table_0"
    onChange={(e) => setSearchTerm(e.target.value)}
/>
                  
                  </div>
                  

                </div>
              </div>
            </div>
            <div className={classes.mainTable}>
            {roleLoading ? (
                              <p>Fetching banks...</p>
                            ) : (
                              <div className="table-responsive">
                                <table className="table display table-bordered table-striped table-hover bg-white m-0 card-table">

                                <thead style={{ whiteSpace: 'nowrap' }}>
                            <tr>
                            <th>S/N</th>
                                      <th>Bank Name</th>
                                      <th>Report Account</th>
                                      <th>Contact Name</th>
                                      {/* <th>Contact Address</th> */}
                                      <th>Contact Phone</th>
                                      <th>Contact Email</th>
                                      <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody style={{ whiteSpace: 'nowrap', }}>
                            {filteredData.map((item, index) => (
                             <tr key={index}>
                             <td>{index + 1}</td>
                             <td>{item.bank_name}</td>
                             <td>{item.report_gl?.gl_name}</td>
                             <td>{item.contact_name}</td>
                             {/* <td>{item.address}</td> */}
                             <td>{item.contact_phone}</td>
                             <td>{item.contact_email}</td>
                                <td>
                                
                                  <div onClick={() => handleEyeClick(item.id)} className="btn btn-success-soft btn-sm mr-1">
                                    <i className="far fa-eye" style={{color: "#008a4b", backgroundColor: "#28a7451a", padding: 2, borderColor: "#28a7454d", borderRadius: 5, fontSize: 12}}></i>
                                  </div>


                                  <div onClick={() => handleTrashClick(item.id)} className="btn btn-danger-soft btn-sm">
                                    <i className="far fa-trash-alt"  style={{color: "#dc3545", backgroundColor: "#dc35451a", padding: 2, borderColor: "#dc35454d", borderRadius: 5, fontSize: 12}}></i>
                                  </div>

                                </td>
                              </tr>
                            ))}
                          </tbody>
                                </table>
                              </div>
                            )}
            </div>

            <div className={classes.endded}>
              <p>
                Showing {startIndexx} to {endIndexx} of {totalEntries} entries
              </p>
              <div style={{ display: 'flex' }}>
                <button
                  style={{ border: 'none', backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'nunito', color: '#000', marginRight: 10, cursor: "pointer" }}
                  onClick={handlePrevPage}
                  disabled={currentPage === 1}
                >
                  Previous
                </button>
                {[...Array(totalPages)].map((_, page) => {
                  // Show only 5 pages or less if available
                  if (page < 5 || page === currentPage - 1 || page === totalPages - 1) {
                    return (
                      <button
                        key={page + 1}
                        style={{
                          marginLeft: '0.4rem',
                          marginRight: '0.4rem',
                          fontSize: '14px',
                          fontFamily: 'nunito',
                          fontWeight: 400,
                          color: page + 1 === currentPage ? '#ffffff' : '#000',
                          backgroundColor: page + 1 === currentPage ? '#28a745' : 'gainsboro',
                          height: '2.5rem',
                          borderRadius: '89px',
                          padding: '0.5rem',
                          border: 'none',
                          width: '40px',
                          cursor: "pointer"
                        }}
                        onClick={() => setCurrentPage(page + 1)}
                      >
                        {page + 1}
                      </button>
                    );
                  }
                  return null;
                })}
                <button
                  style={{ cursor: "pointer", border: 'none', backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'nunito', color: '#000', marginLeft: 10 }}
                  onClick={handleNextPage}
                  disabled={currentPage === totalPages}
                >
                  Next
                </button>
              </div>
            </div>

          </div>
        </div>

      </div>

   


    

    </div >
)
}