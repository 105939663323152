// import React from 'react';
// import classes from './Receiptss.module.css'; // Create this file for CSS styles
// // import classes from './Invoice.module.css';
// // import { Container, Card } from 'react-bootstrap';


// const Receipts = () => {
//   return (
//     <div className={classes.receiptcontainerbody}>
//     <div className={classes.receiptcontainer}>
//     <div className={classes.header}>
//         <h1 className={classes.title}>RECEIPT</h1>
//         <div className={classes.date}>Date: <b>19th July, 2024</b></div>
//         <div className={classes.number}>Reference No. <b>PRO-2024-07-0800242</b></div>
//     </div>
//     <div className={classes.receiptcontainer123}>
//         <div className={classes.receiptcontainer1234}>
//             <table className={classes.inputliner716}>
//                 <tr>
//                     <th >Received from:</th>
//                     <td >dafdadf</td>
//                     <th className={classes.inputliner616}>Amount</th>
//                     <td className={classes.inputliner6161}>adfad</td>
//                 </tr>
//                 <tr>
//                     <th >Account Amount:</th>
//                     <td >dafd</td>
//                     <th >This Payment</th>
//                     <td >dadfa</td>
//                 </tr>
//                 <tr>
//                     <th >For Payment of:</th>
//                     <td >dfadf</td>
//                     <th >This Payment</th>
//                     <td >dafd</td>
//                 </tr>
//             </table>
//             <table className={classes.inputliner716}>
//                 <tr>
//                     <th >Full Name</th>
//                     <td >dafdadf</td>
//                     <th >Account Amount</th>
//                     <td >adfad</td>
//                 </tr>
//                 <tr>
//                     <th >Phone Number</th>
//                     <td >dafd</td>
//                     <th >This Payment</th>
//                     <td >dadfa</td>
//                 </tr>
//                 <tr>
//                     <th >Address</th>
//                     <td >dfadf</td>
//                     <th >This Payment</th>
//                     <td >dafd</td>
//                 </tr>
//             </table>
//         </div>
//     </div>
//   </div>
//   </div>
//   );
// }

// export default Receipts;










import React from 'react';
import classes from './Receiptss.module.css'; // Create this file for CSS styles
// import classes from './Invoice.module.css';
// import { Container, Card } from 'react-bootstrap';


const Receipts = () => {
  return (
    <div className={classes.receiptcontainerbody}>
    <div className={classes.receiptcontainer}>
    <div className={classes.header}>
        <h1 className={classes.title}>RECEIPT</h1>
        <div className={classes.date}>Date: <b>19th July, 2024</b></div>
        <div className={classes.number}>Reference No. <b>PRO-2024-07-0800242</b></div>
    </div>
    <div className={classes.receiptcontainer123}>
        <div className={classes.receiptcontainer1234}>
        <div className={classes.receiptcontainer14}>
            <div className={classes.section}>
            <p className={classes.label1}><b className={classes.label13412}>Received From:</b> <span className={classes.inputline}>Akinloye Emmanuel Oluwatobiloba</span></p>
            </div>
            <div className={classes.sectionamount}>
              <p className={classes.amountlabel}><b>Amount: </b> <span className={classes.inputline}>$1,234,567.89</span></p>
            </div>
        </div>
            <div className={classes.label}>Amount: <b className={classes.inputline2}>One Thousand Two Hundred Thirty-Four and 23/100</b></div>
            <div className={classes.label12}><b>For Payment of:</b> <b className={classes.inputline2}>One Thousand Two Hundred Thirty-Four and 23/100</b></div>
            <div className={classes.receiptcontainer444}>
                <div className={classes.sectiondatesection}>
                    <div>From: <span className={classes.inputline3}>&nbsp;20th July, 2024</span>&nbsp;&nbsp;&nbsp;to &nbsp; &nbsp; <span className={classes.inputline3}>2nd August, 2024</span></div>
                </div>
                <div className={classes.sectiondatesection}>
                    <tr>
                        <th >Paid by:</th>
                        <td >Cash, Check No., POS</td>
                    </tr>
                </div>
            </div>
            <div className={classes.receiptcontainer14}>
                <div className={classes.sectiondatesection12}>
                    <div><b className={classes.label13412}>Received by:</b> <span className={classes.inputline313}>&nbsp;Akinloye Emmanuel Oluwatobiloba</span></div>
                </div>
            </div>
            <div className={classes.redadfptcontainer444}>
                <div className={classes.sectiondatesection}>
                    <div>Name: <span className={classes.inputline3}>&nbsp;20th July, 2024</span></div>
                </div>
                <div className={classes.sectiondatesection}>
                    <tr>
                        <td >Account Amount</td>
                        <td >Cash, Check No., POS</td>
                    </tr>
                </div>
            </div>
            <div className={classes.receiptcontainer44234}>
                <div className={classes.sectiondatesection}>
                    <div>Address: <span className={classes.inputline3}>&nbsp;20th July, 2024</span></div>
                </div>
                <div className={classes.sectiondatesection}>
                    <tr>
                        <td >This Payment</td>
                        <td >Cash, Check No., POS</td>
                    </tr>
                </div>
            </div>
            <div className={classes.receiptcontainer1223234}>
                <div className={classes.sectiondatesection}>
                    <div>Phone Number: <span className={classes.inputline3}>&nbsp;20th July, 2024</span></div>
                </div>
                <div className={classes.sectiondatesection}>
                    <tr>
                        <td >Balance Due</td>
                        <td >Cash, Check No., POS</td>
                    </tr>
                </div>
            </div>
        </div>
    </div>
  </div>
  </div>
  );
}

export default Receipts;