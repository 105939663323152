import React, {useState, useEffect} from 'react';
import classes from "./PaymentInstruction.module.css";
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { ToWords } from 'to-words';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { BASE_URL } from '../../api/api';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Button, Spinner } from 'react-bootstrap';

function PaymentInstruction() {
  const navigate = useNavigate();
  const [employeeName, setEmployeeName] = useState('');
  const [employeeNumber, setEmployeeNumber] = useState('');
  const [gender, setGender] = useState('');
  const [grade1, setGrade1] = useState('');
  const [paymentInstruction, setPaymentInstruction] = useState([]);
  const [totalNetPay, setTotalNetPay] = useState(0);
  const [totalNetPayInWords, setTotalNetPayInWords] = useState('');
  const [bankName, setBankName] = useState('');
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');
  const location = useLocation();
  const { rowsToPass, selectedBank, selectedDate, selectedBankName, selectedBankAc  } = location.state;
  const toWords = new ToWords({
    localeCode: 'en-US',
  converterOptions: {
    currency: true,
    ignoreDecimal: false,
    ignoreZeroCurrency: false,
    doNotAddOnly: false,
    currencyOptions: {
      name: 'Naira',
      plural: 'Naira',
    //   name: vouchers?.vouchers[0]?.currency?.currency,
    //   plural: vouchers?.vouchers[0]?.currency?.currency,
    //   symbol: vouchers?.vouchers[0]?.currency?.symbol,
      fractionalUnit: {
        name: 'Kobo',
        plural: 'Kobo',
        // symbol: '',
      },
    }
  }
  });
  const [company, setCompany] = useState('');
  const [bearer, setBearer] = useState('');
  const [continent, setContinent] = useState('');
    const [region, setRegion] = useState('');
    const [province, setProvince] = useState('');
    const [userType, setUserType] = useState('');
    const [instructionLoading, setInstructionLoading] = useState(false);

    const readData = async () => {
      try {
        const value = await AsyncStorage.getItem('userToken');
        const value1 = await AsyncStorage.getItem('companyName');
        const continentName = await AsyncStorage.getItem('continent');
          const regionName = await AsyncStorage.getItem('region');
          const provinceName = await AsyncStorage.getItem('province');
         const admins = await AsyncStorage.getItem('admin');
  
        if (value !== null) {
          setBearer(value);
          // setAuthenticated(true);
        }
        if (value1 !== null) {
          setCompany(value1);
       
        }
        if (continentName !== null) {
          setContinent(continentName);
      }
      if (regionName !== null) {
          setRegion(regionName);
      }
      if (provinceName !== null) {
          setProvince(provinceName);
      }
      if (admins !== null) {
        setUserType(admins);
    }
      } catch (e) {
        alert('Failed to fetch the input from storage');
      }
    };

    useEffect(() => {
      readData();

  }, []);


  // useEffect(() => {
  //   const { vouchers} = location.state;

  //   if (vouchers) {
  //     setBankName(vouchers?.vouchers?.payment_bank[0]?.bank_name);
  //     // setMonth(selectedMonth);
  //     // setYear(selectedYear);
  //     setPaymentInstruction(vouchers?.vouchers);

  //     // const total = vouchers?.vouchers.reduce((acc, item) => acc + parseFloat(item.amount), 0);
  //     // setTotalNetPay(total);

  //     // const totalInWords = convertToWords(total);
  //     // setTotalNetPayInWords(totalInWords);
  
  //   } else {
  //     console.error('No selected item data found in location state.');
  //   }
  // }, [location.state]);

  console.log(rowsToPass)
  
  const generateCurrentDate = () => {
    const currentDate = new Date();
    
    const day = currentDate.getDate().toString().padStart(2, '0');
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const year = currentDate.getFullYear();
  
    const formattedDate = `${day}-${month}-${year}`;
    
    return formattedDate;
  };

  const currentDate = generateCurrentDate();

  const convertToWords = (number) => {
    const units = ['', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine'];
    const teens = [
      '',
      'Eleven',
      'Twelve',
      'Thirteen',
      'Fourteen',
      'Fifteen',
      'Sixteen',
      'Seventeen',
      'Eighteen',
      'Nineteen',
    ];
    const tens = ['', 'Ten', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];
  
    const numToWords = (num) => {
      if (num === 0) return '';
      if (num < 10) return units[num];
      if (num < 20) return teens[num - 10];
      if (num < 100) return tens[Math.floor(num / 10)] + (num % 10 !== 0 ? ' ' + units[num % 10] : '');
      if (num < 1000)
        return units[Math.floor(num / 100)] + ' Hundred' + (num % 100 !== 0 ? ' ' + numToWords(num % 100) : '');
      if (num < 1000000)
        return numToWords(Math.floor(num / 1000)) + ' Thousand' + (num % 1000 !== 0 ? ' ' + numToWords(num % 1000) : '');
      return 'Number too large to convert';
    };
  
    const [integerPart, decimalPart] = number.toFixed(2).split('.');
    const integerWords = parseInt(integerPart, 10) !== 0 ? numToWords(parseInt(integerPart, 10)) + ' Naira' : '';
    const decimalWords = parseInt(decimalPart, 10) !== 0 ? ' ' + numToWords(parseInt(decimalPart, 10)) + ' Kobo' : '';
  
    const result = (integerWords + (integerWords && decimalWords ? ',' : '') + decimalWords).trim();
  
    return result;
  };

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${bearer}`
};
  
  
  const total = rowsToPass.reduce((acc, item) => acc + parseFloat(item.amount), 0);

  const handleInstruction = async () => {
    setInstructionLoading(true);

    const ids = rowsToPass.map(row => row.id);
 
    try {
      const response = await axios.post(`${BASE_URL}/pay-payables`,
        {
         
            payable_id: ids,
            payment_bank: selectedBank
        
        },
        { headers }
      );
      window.print();


    //  toast.success(response.data.message);
     
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
          if (typeof error.response.data.message === 'string') {
              errorMessage = error.response.data.message;
          } else if (Array.isArray(error.response.data.message)) {
              errorMessage = error.response.data.message.join('; ');
          } else if (typeof error.response.data.message === 'object') {
              errorMessage = JSON.stringify(error.response.data.message);
          }
        //   toast.error(errorMessage)
        Swal.fire({
            icon: 'error',
            title: 'Failed',
            text: errorMessage,
          });
          console.log(errorMessage);
      }
    } finally {
      setInstructionLoading(false);
    }
  };

  const handleGoBack = () => {
    navigate(-1);
}


  return (
    <div className={classes.container}>
      <div className={classes.address}>
        <p>
          <strong>To:</strong>
          <br />
          The Branch Manager, <br /> {selectedBankName}.
        </p>

        <p>
          Place: 
           <br /> Date: {currentDate}
        </p>
      </div>

      <p style={{ textAlign: "center" }}>
        Subject: <strong>{rowsToPass?.description}</strong>
      </p>

      <p>Dear Sir/Madam,</p>

      <p>
        <span style={{ marginLeft: "40px" }}> We</span>{" "}
        <strong>The {company}</strong> request you to Kindly transfer the amount to the following beneficiaries to
        their respective bank accounts by debiting from our account bearing a/c no. <strong>{selectedBankAc} </strong>.
        <strong></strong>.
      </p>
        <div className={classes.PaymentInstructionTable}>
          <table>
            <thead>
              <tr>
                <th>S No</th>
                <th>Account Name</th>
                <th>Bank Name</th>
                <th>A/C Number</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
            {rowsToPass?.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.supplier?.account_name}</td>
                  <td>{item.supplier?.bank_name}</td>
                  <td>{item.supplier?.account_number}</td>
                  <td style={{ textAlign: "right" }}>{parseFloat(item.amount).toLocaleString('en-US', {
                                          minimumIntegerDigits: 1,
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2
                                        })}
                    </td>
                </tr>
              ))}
                                    
            </tbody>

            <tfoot>
              <tr>
                <td style={{ textAlign: "right" }}>
                  <strong>Total</strong>
                </td>
                <td colSpan="3">

                {total ? toWords.convert(parseFloat(total)) : ''}
                </td>

                <td style={{ textAlign: "right", fontWeight: "bold" }}>{total.toLocaleString('en-US', {
                    minimumIntegerDigits: 1,
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}</td>
              </tr>
              <tr>
                {/* <td colSpan="5">{totalNetPayInWords}</td> */}
              </tr>
            </tfoot>
          </table>
        </div>
      

      <p style={{ textAlign: "center" }}>Thank You.</p>

      <p style={{ textAlign: "right" }}>
        For the <strong>[{company}]</strong>,
      </p>
      <p style={{ textAlign: "right" }}>Authorized Person name & Signatory.</p>
      <div className={classes.printCheque}>

<nav aria-label="breadcrumb" className="col-sm-4 order-sm-last mb-3 mb-sm-0 p-0 ">
    
    <div
        style={{
            marginTop: 20,
            marginBottom: 20,
            justifyContent: "flex-end",
            display: "flex",
            marginLeft: "auto",
            gap: 20
        }}
    >
        <Button variant='danger' onClick={handleGoBack} >Cancel</Button>
        <Button variant="success" onClick={handleInstruction}>
            {instructionLoading ? (
                <>
                    <Spinner size='sm' />
                    {/* <span style={{ marginLeft: '5px' }}>Processing, Please wait...</span> */}
                </>
            ) : (
                "Print"
            )}
        </Button>
    </div>

</nav>
</div>
    </div>
  );
}

export default PaymentInstruction;
