import React, { useState, useEffect } from 'react';
import classes from './ManageRole.module.css';
import "../../assets/datatables/dataTables.bootstrap4.min.css";
// import RegLogo from '../../Images/RegistrationLogo.svg'
import { Spinner, Badge, Button, Modal, Form, Tab, Tabs } from 'react-bootstrap';
// import Folder from '../../Images/folder-2.svg';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
// import ArrowLogo from '../../Images/arrow-left.svg';
// import LoanImage from '../../Images/loan bg.svg';
import MainDashoard from '../Main Dashboard/MainDashoard';
// import Ready from '../../Images/nothing.svg'
// import Ready1 from '../../Images/review.svg';
import { Link, useNavigate, useParams } from 'react-router-dom'
import Table from 'react-bootstrap/Table';
import { BASE_URL } from '../api/api';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import CoopDashboard from '../Cooperative Dashboard/CoopDashboard';
import ReportDashboard from '../ReportDashboard/ReportDashboard';
import LatestDash from '../LatestDashboard/LatestDash';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';



export default function ManageCompany() {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const handleClose = () => setShow(false);
  const handleClose1 = () => setShow1(false);
  const handleShow = () => setShow(true);
  const handleShow1 = () => setShow1(true);
  const [bearer, setBearer] = useState('');
  const [user, setUser] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [contLoading, setContLoading] = useState(false);
  const [roleLoading, setRoleLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [subContloading, setSubContLoading] = useState(false);
  const [subRegloading, setSubRegLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [eyeClicked, setEyeClicked] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [tableData1, setTableData1] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [searchedResult, setSearchedResult] = useState([]);
  const [trashClicked, setTrashClicked] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [admin, setAdmin] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [selectedRegion, setSelectedRegion] = useState("");
  const [selectedRegion1, setSelectedRegion1] = useState("");
  const [selectedContinent, setSelectedContinent] = useState("");
  const [selectedContinent1, setSelectedContinent1] = useState("");
  const [selectedProvince, setSelectedProvince] = useState("");
  const [selectedProvince1, setSelectedProvince1] = useState("");
  const [fullName, setFullName] = useState("");
  const [fullName1, setFullName1] = useState("");
  const [email, setEmail] = useState("");
  const [email1, setEmail1] = useState("");
  const [phone1, setPhone1] = useState("");
  const [selectedRole1, setSelectedRole1] = useState("");
  const [phone, setPhone] = useState("");
  const [userType, setUserType] = useState([]);
  const [continents, setContinents] = useState([]);
  const [tableData4, setTableData4] = useState([]);
  const [tableData5, setTableData5] = useState([]);
  const [address, setAddress] = useState("");
  const [archivedData, setArchivedData] = useState([]);
  const [office_address, setOfficeAddress] = useState("");
  const [selectedId, setSelectedId] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const [entriesPerPage, setEntriesPerPage] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalEntries, setTotalEntries] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [userTypes, setUserTypes] = useState('');

  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem('userToken');
      const value1 = await AsyncStorage.getItem('tobi');
      const value2 = await AsyncStorage.getItem('permissions');
      const value3 = await AsyncStorage.getItem('admin');
      const admins = await AsyncStorage.getItem('admin');

      if (value !== null) {
        setBearer(value);
      }
      if (value1 !== null) {
        setUser(value1);
      }
      if (value2 !== null) {
        setPermissions(value2);
      }
      if (value3 !== null) {
        setAdmin(value3);
      }
      if (admins !== null) {
        setUserTypes(admins);
      }
    } catch (e) {
      alert('Failed to fetch the input from storage');
    }
  };

  useEffect(() => {
    readData();
  }, []);

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${bearer}`
  };



  const fetchData = async () => {
    setRoleLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/companies/fetch`, { headers });
      const results = response.data?.data;
      // const resultx = response.data?.data?.total;
      // setTotalEntries(resultx);
      setTableData(results);
      console.log(response);
      // const total = response.data?.data?.last_page || 1;
      // setTotalPages(total);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
        const errorStatus = error.response?.data?.message;
        console.log(errorStatus);
        setTableData([]);
      }
    } finally {
      setRoleLoading(false);
    }
  };



  const fetchArchivedData = async () => {
    setRoleLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/companies/fetch-softdelete`, { headers });
      const results = response.data?.data;
      // const resultx = response.data?.data?.total;
      // setTotalEntries(resultx);
      setArchivedData(results);
      console.log(response, "SLFSLK");
      // const total = response.data?.data?.last_page || 1;
      // setTotalPages(total);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
        const errorStatus = error.response?.data?.message;
        console.log(errorStatus);
        setArchivedData([]);
      }
    } finally {
      setRoleLoading(false);
    }
  };








  useEffect(() => {
    if (bearer) {
      fetchData();
      fetchArchivedData();
    }
  }, [bearer]);



  function formatDate(dateString) {
    const date = new Date(dateString);
    const formattedDate = `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())} ${padZero(date.getHours())}:${padZero(date.getMinutes())} ${date.getHours() >= 12 ? 'PM' : 'AM'}`;
    return formattedDate;
  }

  function padZero(num) {
    return num < 10 ? `0${num}` : num;
  }


  const handlePrevPage = () => {
    setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages));
  };



  //filter function
  const filteredData = tableData.filter(item => item.name.toLowerCase().includes(searchTerm.toLowerCase()));
  const startIndexx = (currentPage - 1) * entriesPerPage + 1;
  const endIndexx = Math.min(startIndexx + entriesPerPage - 1, totalEntries);
  const displayedData = filteredData.slice(currentPage - 1, totalEntries);


  const handleEyeClick = (id) => {
    const foundUser = tableData.find((item) => item.id === id);

    if (foundUser) {
      navigate('/admin/edit_company', { state: { userData: foundUser } });
    }
  };
  const handleEyeClick1 = (id) => {
    const foundUser = archivedData.find((item) => item.id === id);

    if (foundUser) {
      navigate('/admin/edit_company', { state: { userData: foundUser } });
    }
  };







  const handleTrashClick = async (id) => {
    const confirmed = await Swal.fire({
      title: 'Are you sure?',
      text: 'You are about to move this company to the archive.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, move it!',
      cancelButtonText: 'No, cancel',
    });

    if (!confirmed.isConfirmed) {
      return; // User canceled, do nothing
    }

    try {
      const response = await axios.get(`${BASE_URL}/companies/delete?id=${id}`, { headers });
      fetchData();
      fetchArchivedData();
      toast.success(response.data.message);
      setTrashClicked(true);
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        toast.error(errorMessage)
        console.log(errorMessage);
      }
    }
  };
  const handleTrashClick1 = async (id) => {
    const confirmed = await Swal.fire({
      title: 'Are you sure?',
      text: 'You are about to delete this company .',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel',
    });

    if (!confirmed.isConfirmed) {
      return; // User canceled, do nothing
    }

    try {
      const response = await axios.get(`${BASE_URL}/companies/delete-single-softdelete?id=${id}`, { headers });
      fetchData();
      fetchArchivedData();
      toast.success(response.data.message);
      setTrashClicked(true);
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        toast.error(errorMessage)
        console.log(errorMessage);
      }
    }
  };
  const handleTrashClick2 = async (id) => {
    const confirmed = await Swal.fire({
      title: 'Are you sure?',
      text: 'You are about to restore this company .',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, restore it!',
      cancelButtonText: 'No, cancel',
    });

    if (!confirmed.isConfirmed) {
      return; // User canceled, do nothing
    }

    try {
      const response = await axios.get(`${BASE_URL}/companies/restore-single-softdelete?id=${id}`, { headers });
      fetchData();
      fetchArchivedData();
      toast.success(response.data.message);
      setTrashClicked(true);
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        toast.error(errorMessage)
        console.log(errorMessage);
      }
    }
  };



  const handleCreateUser = () => {
    navigate('/admin/create_company');
  }

  return (
    <div>
      <LatestDash />

      <div className={classes.finishedbodyCont}>


        <div style={{ display: "flex", justifyContent: "space-between", alignItems: 'center' }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <h3 style={{ fontSize: '30px', fontWeight: 700, margin: " 10px 10px 0  15px" }}>Manage Company</h3>
            <p style={{ margin: " 0 10px 20px 15px" }}>Add, View, Edit, and Delete Company</p>
          </div>
          <div className={`${classes.formIntBtn} ${classes.formIntBtnUnitss}`} style={{ zIndex: 1, }}>
            <OverlayTrigger
              placement="top"
              className={classes.btndf1}
              overlay={<Tooltip id="button-tooltip" className={classes.btndf1}>Click here to add new company</Tooltip>}
            >
              <Button className={classes.btn1} variant="success" onClick={handleCreateUser}>
                Add New Company
              </Button>
            </OverlayTrigger>
          </div>
        </div>
        {/* <h3 className={classes.productText}>Manage Company</h3> */}
        <div className={classes.mainform}>
          <div className={classes.loandgrantcards}>
            {/*            
            <div className={classes.loandethead}>
              <div className={classes.formLabel}>
              </div>
              <div className={classes.formIntBtn}>
                  <OverlayTrigger
                    placement="top"
                    className={classes.btndf1}
                    overlay={<Tooltip id="button-tooltip"  className={classes.btndf1}>Click here to add new company</Tooltip>}
                  >
                    <Button variant="success" onClick={handleCreateUser} className={classes.btn2}> Add New Company</Button>
                  </OverlayTrigger>

              </div>
            </div>
         */}
            <div style={{ marginTop: 10 }} />
            <Tabs
              defaultActiveKey="Company"
              id="uncontrolled-tab-example"
              className={`mb-3 complete-tabs ${classes.ikatabsika}`}
              variant="underline"
              color="#2D995F"
            >
              <Tab eventKey="Company" title="Companies" className={classes.ikatabsika11}>
                <div className={`d-flex justify-content-between align-items-center ${classes.btnStyle}`}>
                  <div className={`${classes.greenbtn} ${classes.btndivStyle}`}>
                    <div className={classes.actionsBtns}>
                      <Button variant='success'>Copy</Button>
                      <Button variant='success'>Excel</Button>
                      <Button variant='success'>PDF</Button>
                      <Button variant='success' className={classes.diffbtn}>Column visibility</Button>
                    </div>
                    <div className={classes.show}>
                      <label className="d-flex justify-content-start align-items-center">
                        Show
                        <select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" className="custom-select custom-select-sm form-control form-control-sm"
                        //  value={entriesPerPage}
                        //     onChange={(e) => {
                        //     setEntriesPerPage(parseInt(e.target.value));
                        //     setCurrentPage(1);
                        //     }}
                        >
                          <option value={10}>10</option>
                          <option value={25}>25</option>
                          <option value={50}>50</option>
                          <option value={100}>100</option>
                        </select>
                        entries
                      </label>
                    </div>
                  </div>
                  <div className="text-right modal-effect ">
                    <div id="DataTables_Table_0_filter" className="dataTables_filter">
                      <div className="d-flex justify-content-start align-items-center">
                        <div className="mr-2">Search:</div>
                        <input
                          type="search"
                          value={searchTerm}
                          className="form-control form-control-sm"
                          placeholder=""
                          aria-controls="DataTables_Table_0"
                          onChange={(e) => setSearchTerm(e.target.value)}
                        />

                      </div>


                    </div>
                  </div>
                </div>
                <div className={classes.mainTable}>
                  {roleLoading ? (
                    <p>Fetching companies...</p>
                  ) : (
                    <div className="table-responsive">
                      <table className="table display table-bordered table-striped table-hover bg-white m-0 card-table">

                        <thead style={{ whiteSpace: 'nowrap' }}>
                          <tr>
                            <th>ID</th>
                            {/* <th>Name</th> */}
                            <th>Company Name</th>
                            <th>Company Address</th>
                            {/* <th>Role</th> */}
                            {/* <th>User Type</th> */}
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody style={{ whiteSpace: 'nowrap', }}>
                          {searchedResult.map((item, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{item.name}</td>
                              <td>{item.email}</td>
                              {/* <td>{item.phone_no}</td> */}
                              {/* <td>
  {item.roles?.map((role, index) => (
    <span key={index}>{role.name}</span>
  ))}
</td> */}
                              <td>
                                <OverlayTrigger
                                  placement="top"
                                  className={classes.btndf1}
                                  overlay={<Tooltip id="button-tooltip" className={classes.btndf1}>Click to view this company</Tooltip>}
                                >
                                  <div onClick={() => handleEyeClick(item.id)} className="btn btn-success-soft btn-sm mr-1">
                                    <i className="far fa-eye" style={{ color: "#008a4b", backgroundColor: "#28a7451a", padding: 2, borderColor: "#28a7454d", borderRadius: 5, fontSize: 12 }}></i>
                                  </div>
                                </OverlayTrigger>

                                <OverlayTrigger
                                  placement="top"
                                  className={classes.btndf1}
                                  overlay={<Tooltip id="button-tooltip" className={classes.btndf1}>Click here to move your company to archive</Tooltip>}
                                >
                                  <div onClick={() => handleTrashClick(item.id)} style={{ padding: "0", marginRight: 10 }} className="btn btn-danger-soft btn-sm">
                                    <i class="fa fa-archive" style={{ color: "#000", backgroundColor: "#dc35451a", borderColor: "#dc35454d", borderRadius: 5, fontSize: 12 }}></i>
                                  </div>
                                </OverlayTrigger>

                                <OverlayTrigger
                                  placement="top"
                                  className={classes.btndf1}
                                  overlay={<Tooltip id="button-tooltip" className={classes.btndf1}>Click to permanently delete company</Tooltip>}
                                >
                                  <div onClick={() => handleTrashClick(item.id)} className="btn btn-danger-soft btn-sm">
                                    <i className="far fa-trash-alt" style={{ color: "#dc3545", backgroundColor: "#dc35451a", padding: 2, borderColor: "#dc35454d", borderRadius: 5, fontSize: 12 }}></i>
                                  </div>
                                </OverlayTrigger>

                              </td>
                            </tr>
                          ))}
                          {filteredData.map((item, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{item.name}</td>
                              <td>{item.address}</td>
                              {/* <td>{item.phone_no}</td> */}
                              {/* <td>
  {item.roles?.map((role, index) => (
    <span key={index}>{role.name}</span>
  ))}
</td> */}
                              <td>
                                <OverlayTrigger
                                  placement="top"
                                  className={classes.btndf1}
                                  overlay={<Tooltip id="button-tooltip" className={classes.btndf1}>Click to view this company</Tooltip>}
                                >
                                  <div onClick={() => handleEyeClick(item.id)} className="btn btn-success-soft btn-sm mr-1">
                                    <i className="far fa-eye" style={{ color: "#008a4b", backgroundColor: "#28a7451a", padding: 2, borderColor: "#28a7454d", borderRadius: 5, fontSize: 12 }}></i>
                                  </div>
                                </OverlayTrigger>

                                <OverlayTrigger
                                  placement="top"
                                  className={classes.btndf1}
                                  overlay={<Tooltip id="button-tooltip" className={classes.btndf1}>Click here to move your company to archive</Tooltip>}
                                >
                                  <div onClick={() => handleTrashClick(item.id)} style={{ padding: "0", marginRight: 10 }} className="btn btn-danger-soft btn-sm">
                                    <i class="fa fa-archive" style={{ color: "#000", backgroundColor: "#dc35451a", borderColor: "#dc35454d", borderRadius: 5, fontSize: 12 }}></i>
                                  </div>
                                </OverlayTrigger>

                                <OverlayTrigger
                                  placement="top"
                                  className={classes.btndf1}
                                  overlay={<Tooltip id="button-tooltip" className={classes.btndf1}>Click to permanently delete company</Tooltip>}
                                >
                                  <div onClick={() => handleTrashClick(item.id)} className="btn btn-danger-soft btn-sm">
                                    <i className="far fa-trash-alt" style={{ color: "#dc3545", backgroundColor: "#dc35451a", padding: 2, borderColor: "#dc35454d", borderRadius: 5, fontSize: 12 }}></i>
                                  </div>
                                </OverlayTrigger>

                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>

                <div className={classes.endded}>
                  <p>
                    Showing {startIndexx} to {endIndexx} of {totalEntries} entries
                  </p>
                  <div style={{ display: 'flex' }}>
                    <button
                      style={{ border: 'none', backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'nunito', color: '#000', marginRight: 10, cursor: "pointer" }}
                      onClick={handlePrevPage}
                      disabled={currentPage === 1}
                    >
                      Previous
                    </button>
                    {[...Array(totalPages)].map((_, page) => {
                      // Show only 5 pages or less if available
                      if (page < 5 || page === currentPage - 1 || page === totalPages - 1) {
                        return (
                          <button
                            key={page + 1}
                            style={{
                              marginLeft: '0.4rem',
                              marginRight: '0.4rem',
                              fontSize: '14px',
                              fontFamily: 'nunito',
                              fontWeight: 400,
                              color: page + 1 === currentPage ? '#ffffff' : '#000',
                              backgroundColor: page + 1 === currentPage ? '#28a745' : 'gainsboro',
                              height: '2.5rem',
                              borderRadius: '89px',
                              padding: '0.5rem',
                              border: 'none',
                              width: '40px',
                              cursor: "pointer"
                            }}
                            onClick={() => setCurrentPage(page + 1)}
                          >
                            {page + 1}
                          </button>
                        );
                      }
                      return null;
                    })}
                    <button
                      style={{ cursor: "pointer", border: 'none', backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'nunito', color: '#000', marginLeft: 10 }}
                      onClick={handleNextPage}
                      disabled={currentPage === totalPages}
                    >
                      Next
                    </button>
                  </div>
                </div>

              </Tab>
              <Tab eventKey="ArchivedCompany" title="Deleted or Archived" className={classes.ikatabsika11}>
                <div className={`d-flex justify-content-between align-items-center ${classes.btnStyle}`}>
                  <div className={`${classes.greenbtn} ${classes.btndivStyle}`}>
                    <div className={classes.actionsBtns}>
                      <Button variant='success'>Copy</Button>
                      <Button variant='success'>Excel</Button>
                      <Button variant='success'>PDF</Button>
                      <Button variant='success' className={classes.diffbtn}>Column visibility</Button>
                    </div>
                    <div className={classes.show}>
                      <label className="d-flex justify-content-start align-items-center">
                        Show
                        <select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" className="custom-select custom-select-sm form-control form-control-sm"
                        //  value={entriesPerPage}
                        //     onChange={(e) => {
                        //     setEntriesPerPage(parseInt(e.target.value));
                        //     setCurrentPage(1);
                        //     }}
                        >
                          <option value={10}>10</option>
                          <option value={25}>25</option>
                          <option value={50}>50</option>
                          <option value={100}>100</option>
                        </select>
                        entries
                      </label>
                    </div>
                  </div>
                  <div className="text-right modal-effect ">
                    <div id="DataTables_Table_0_filter" className="dataTables_filter">
                      <div className="d-flex justify-content-start align-items-center">
                        <div className="mr-2">Search:</div>
                        <input
                          type="search"
                          value={searchTerm}
                          className="form-control form-control-sm"
                          placeholder=""
                          aria-controls="DataTables_Table_0"
                          onChange={(e) => setSearchTerm(e.target.value)}
                        />

                      </div>


                    </div>
                  </div>
                </div>
                <div className={classes.mainTable}>
                  {roleLoading ? (
                    <p>Fetching companies...</p>
                  ) : (
                    <div className="table-responsive">
                      <table className="table display table-bordered table-striped table-hover bg-white m-0 card-table">

                        <thead style={{ whiteSpace: 'nowrap' }}>
                          <tr>
                            <th>ID</th>
                            {/* <th>Name</th> */}
                            <th>Company Name</th>
                            <th>Company Address</th>
                            {/* <th>Role</th> */}
                            {/* <th>User Type</th> */}
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody style={{ whiteSpace: 'nowrap', }}>
                          {searchedResult.map((item, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{item.name}</td>
                              <td>{item.email}</td>
                              {/* <td>{item.phone_no}</td> */}
                              {/* <td>
  {item.roles?.map((role, index) => (
    <span key={index}>{role.name}</span>
  ))}
</td> */}
                              <td>
                                <OverlayTrigger
                                  placement="top"
                                  className={classes.btndf1}
                                  overlay={<Tooltip id="button-tooltip" className={classes.btndf1}>Click here to edit/update your company</Tooltip>}
                                >
                                  <div onClick={() => handleEyeClick(item.id)} style={{ padding: "2px 10px" }} className="btn btn-success-soft btn-sm mr-1">
                                    <i class="fas fa-pencil-alt" style={{ color: "#008a4b", backgroundColor: "#28a7451a", borderColor: "#28a7454d", borderRadius: 5, fontSize: 12 }}></i>
                                  </div>
                                </OverlayTrigger>

                                <OverlayTrigger
                                  placement="top"
                                  className={classes.btndf1}
                                  overlay={<Tooltip id="button-tooltip" className={classes.btndf1}>Click here to move your company to archive</Tooltip>}
                                >
                                  <div onClick={() => handleTrashClick(item.id)} style={{ padding: "0", marginRight: 10 }} className="btn btn-danger-soft btn-sm">
                                    <i class="fa fa-archive" style={{ color: "#000", backgroundColor: "#dc35451a", borderColor: "#dc35454d", borderRadius: 5, fontSize: 12 }}></i>
                                  </div>
                                </OverlayTrigger>


                                <OverlayTrigger
                                  placement="top"
                                  className={classes.btndf1}
                                  overlay={<Tooltip id="button-tooltip" className={classes.btndf1}>Click here to permanently delete your company</Tooltip>}
                                >
                                  <div onClick={() => handleTrashClick1(item.id)} style={{ padding: "0" }} className="btn btn-danger-soft btn-sm">
                                    <i className="far fa-trash-alt" style={{ color: "#dc3545", backgroundColor: "#dc35451a", borderColor: "#dc35454d", borderRadius: 5, fontSize: 12 }}></i>
                                  </div>
                                </OverlayTrigger>
                              </td>
                            </tr>
                          ))}
                          {archivedData.map((item, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{item.name}</td>
                              <td>{item.address}</td>
                              {/* <td>{item.phone_no}</td> */}
                              {/* <td>
  {item.roles?.map((role, index) => (
    <span key={index}>{role.name}</span>
  ))}
</td> */}
                              <td>
                                <OverlayTrigger
                                  placement="top"
                                  className={classes.btndf1}
                                  overlay={<Tooltip id="button-tooltip" className={classes.btndf1}>Click here to edit/update your company</Tooltip>}
                                >
                                  <div onClick={() => handleEyeClick1(item.id)} style={{ padding: "2px 10px" }} className="btn btn-success-soft btn-sm mr-1">
                                    <i class="fas fa-pencil-alt" style={{ color: "#008a4b", backgroundColor: "#28a7451a", borderColor: "#28a7454d", borderRadius: 5, fontSize: 12 }}></i>
                                  </div>
                                </OverlayTrigger>

                                <OverlayTrigger
                                  placement="top"
                                  className={classes.btndf1}
                                  overlay={<Tooltip id="button-tooltip" className={classes.btndf1}>Click here to restore your archived company</Tooltip>}
                                >
                                  <div onClick={() => handleTrashClick2(item.id)} style={{ padding: "2px 10px" }} className="btn btn-success-soft btn-sm mr-1">
                                    <i class='fas fa-trash-restore-alt' style={{ color: "#008a4b", backgroundColor: "#28a7451a", borderColor: "#28a7454d", borderRadius: 5, fontSize: 14 }}></i>
                                  </div>
                                </OverlayTrigger>


                                <OverlayTrigger
                                  placement="top"
                                  className={classes.btndf1}
                                  overlay={<Tooltip id="button-tooltip" className={classes.btndf1}>Click here to permanently delete your company</Tooltip>}
                                >
                                  <div onClick={() => handleTrashClick1(item.id)} style={{ padding: "0" }} className="btn btn-danger-soft btn-sm">
                                    <i className="far fa-trash-alt" style={{ color: "#dc3545", backgroundColor: "#dc35451a", borderColor: "#dc35454d", borderRadius: 5, fontSize: 12 }}></i>
                                  </div>
                                </OverlayTrigger>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>

                <div className={classes.endded}>
                  <p>
                    Showing {startIndexx} to {endIndexx} of {totalEntries} entries
                  </p>
                  <div style={{ display: 'flex' }}>
                    <button
                      style={{ border: 'none', backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'nunito', color: '#000', marginRight: 10, cursor: "pointer" }}
                      onClick={handlePrevPage}
                      disabled={currentPage === 1}
                    >
                      Previous
                    </button>
                    {[...Array(totalPages)].map((_, page) => {
                      // Show only 5 pages or less if available
                      if (page < 5 || page === currentPage - 1 || page === totalPages - 1) {
                        return (
                          <button
                            key={page + 1}
                            style={{
                              marginLeft: '0.4rem',
                              marginRight: '0.4rem',
                              fontSize: '14px',
                              fontFamily: 'nunito',
                              fontWeight: 400,
                              color: page + 1 === currentPage ? '#ffffff' : '#000',
                              backgroundColor: page + 1 === currentPage ? '#28a745' : 'gainsboro',
                              height: '2.5rem',
                              borderRadius: '89px',
                              padding: '0.5rem',
                              border: 'none',
                              width: '40px',
                              cursor: "pointer"
                            }}
                            onClick={() => setCurrentPage(page + 1)}
                          >
                            {page + 1}
                          </button>
                        );
                      }
                      return null;
                    })}
                    <button
                      style={{ cursor: "pointer", border: 'none', backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'nunito', color: '#000', marginLeft: 10 }}
                      onClick={handleNextPage}
                      disabled={currentPage === totalPages}
                    >
                      Next
                    </button>
                  </div>
                </div>

              </Tab>
            </Tabs>



          </div>
        </div>

      </div>






    </div >
  )
}