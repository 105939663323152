import React, { useState, useEffect } from 'react';
import "../../../assets/datatables/dataTables.bootstrap4.min.css";

// import { Link, useNavigate, useParams } from 'react-router-dom'
import { Spinner, Badge, Button, Modal, Form, Tab, Tabs } from 'react-bootstrap';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Swal from 'sweetalert2';
import LatestDash from '../LatestDash';
import { BASE_URL } from '../../api/api';
import { toast } from 'react-toastify';
import classes from './ManageStep.module.css';
import { Link, useNavigate, useParams } from 'react-router-dom'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';


export default function Managedepartment() {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const handleClose = () => setShow(false);
  const handleClose1 = () => setShow1(false);
  const handleShow = () => setShow(true);
  const handleShow1 = () => setShow1(true);
  const [bearer, setBearer] = useState('');
  const [user, setUser] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [roleLoading, setRoleLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [eyeClicked, setEyeClicked] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [tableData1, setTableData1] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [searchedResult, setSearchedResult] = useState([]);
  const [trashClicked, setTrashClicked] = useState(false);
  const [entriesPerPage, setEntriesPerPage] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [admin, setAdmin] = useState("");
  const [selectedRole, setSelectedRole] = useState("");
  const [department, setDepartment] = useState("");
  const [department1, setDepartment1] = useState("");

  const [roless, setRoless] = useState([]);
  const [address, setAddress] = useState("");
  const [office_address, setOfficeAddress] = useState("");
  const [selectedId, setSelectedId] = useState('');
  const [selecteddepartment, setSelecteddepartment] = useState(null);

  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem('userToken');
      const value1 = await AsyncStorage.getItem('tobi');
      const value2 = await AsyncStorage.getItem('permissions');
      const value3 = await AsyncStorage.getItem('admin');

      if (value !== null) {
        setBearer(value);
      }
      if (value1 !== null) {
        setUser(value1);
      }
      if (value2 !== null) {
        setPermissions(value2);
      }
      if (value3 !== null) {
        setAdmin(value3);
      }
    } catch (e) {
      alert('Failed to fetch the input from storage');
    }
  };

  useEffect(() => {
    readData();
  }, []);

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${bearer}`
  };



  const fetchData = async () => {
    setRoleLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/payroll/designation`, { headers });
      const results = response.data?.data?.data;
      console.log(results);
      setTableData(results);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
        const errorStatus = error.response?.data?.message;
        console.log(errorStatus);
        setTableData([]);
      }
    } finally {
      setRoleLoading(false);
    }
  };



  const fetchSearch = async (searchTerm) => {
    setSearchLoading(true);
    try {
      let res;
      if (searchTerm.trim() === "") {
        res = tableData1;
      } else {
        const response = await axios.get(`${BASE_URL}/users/search`, {
          params: { variable: searchTerm },
          headers
        });
        res = response.data?.data;
      }
      setSearchedResult(res);

    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate('/login');
      } else {
        let errorMessage = 'An error occurred. Please try again.';
        if (error.response && error.response.data && error.response.data.message) {
          if (typeof error.response.data.message === 'string') {
            errorMessage = error.response.data.message;
          } else if (Array.isArray(error.response.data.message)) {
            errorMessage = error.response.data.message.join('; ');
          } else if (typeof error.response.data.message === 'object') {
            errorMessage = JSON.stringify(error.response.data.message);
          }
        }
        setSearchedResult([]);
      }
    } finally {
      setSearchLoading(false);
    }
  };

  const fetchData1 = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/role/get-roles`, { headers });
      const roleList = response.data?.data;
      // console.log(results);
      setRoless(roleList);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
        const errorStatus = error.response?.data?.message;
        console.log(errorStatus);
        setTableData([]);
      }
    } finally {
      setIsLoading(false);
    }
  };



  useEffect(() => {
    if (bearer) {
      fetchData();
      fetchData1();
    }
  }, [bearer]);

  const createUser = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${BASE_URL}/payroll/designation/create`,
        {
          name: department,


        },
        { headers }
      );
      console.log(response)
      fetchData();
      handleClose();

      // return
      toast.success(response.data.message);
      console.log(response.data);

    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        toast.error(errorMessage);
        console.log(error);
      }
    } finally {
      setLoading(false);
    }
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const formattedDate = `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())} ${padZero(date.getHours())}:${padZero(date.getMinutes())} ${date.getHours() >= 12 ? 'PM' : 'AM'}`;
    return formattedDate;
  }

  function padZero(num) {
    return num < 10 ? `0${num}` : num;
  }


  const filteredData = tableData.filter(item => item.name.toLowerCase().includes(searchTerm.toLowerCase()));

  const totalPages = Math.ceil(filteredData.length / entriesPerPage);

  const handlePrevPage = () => {
    setCurrentPage(Math.max(currentPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage(Math.min(currentPage + 1, totalPages));
  };

  const totalEntries = filteredData.length;
  const startIndexx = (currentPage - 1) * entriesPerPage + 1;
  const endIndexx = Math.min(startIndexx + entriesPerPage - 1, totalEntries);
  const displayedData = filteredData.slice(startIndexx - 1, endIndexx);


  const handleEyeClick = (id) => {
    const founddepartment = tableData.find(item => item.id === id);

    if (founddepartment) {
      const { name } = founddepartment;
      setSelecteddepartment(id);
      setDepartment1(name || '');



      setShow1(true);
      setEyeClicked(true);
    } else {
      console.error(`User with id ${id} not found`);
    }
  };


  useEffect(() => {
  }, [selecteddepartment]);

  const editUser = async (id) => {
    setUpdateLoading(true);

    try {
      const response = await axios.post(
        `${BASE_URL}/departments/update`,
        {
          name: department1,
          id: selecteddepartment,

        },
        { headers }
      );

      fetchData();
      handleClose1();
      toast.success(response.data.message);
      // console.log(response.data);
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        toast.error(errorMessage);
        console.log(error);
      }
    } finally {
      setUpdateLoading(false);
    }
  };


  const handleTrashClick = async (id) => {
    const confirmed = await Swal.fire({
      title: 'Are you sure?',
      text: 'You are about to delete this department.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel',
    });

    if (!confirmed.isConfirmed) {
      return; // User canceled, do nothing
    }

    try {
      const response = await axios.get(`${BASE_URL}/departments/delete?id=${id}`, { headers });
      fetchData();
      toast.success(response.data.message);
      setTrashClicked(true);
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        toast.error(errorMessage)
        console.log(errorMessage);
      }
    }
  };

  console.log(searchTerm);



  return (

    <div className={classes.latestBody}>

      <div className="wrapper">
        {/* <!-- Sidebar  --> */}


        {/* <!-- Page Content  --> */}
        <div className="content-wrapper">
          <div className="main-content">
            <LatestDash />

            <div className={classes.body_style}>
              <div style={{ display: "flex", flexDirection: "row", gap: 10, marginTop: "0", marginBottom: "1rem" }}>




              </div>
              <div className={classes.finishedbodyCont}>

                <div style={{ display: "flex", justifyContent: "space-between", alignItems: 'center' }}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <h3 style={{ fontSize: '30px', fontWeight: 700, margin: " 10px 10px 0  15px" }}>Manage Designation</h3>
                    <p style={{ margin: " 0 10px 20px 15px" }}>Create and manage your Designation</p>
                  </div>
                  <div className={`${classes.formIntBtn} ${classes.formIntBtnUnitss}`} style={{ zIndex: 1, }}>
                    <OverlayTrigger
                      placement="top"
                      className={classes.btndf1}
                      overlay={<Tooltip id="button-tooltip" className={classes.btndf1}>Click here to add new designation</Tooltip>}
                    >
                      <Button className={classes.btn1} variant="success" onClick={handleShow}>
                        Add New Designation
                      </Button>
                    </OverlayTrigger>
                  </div>
                </div>
                <div className={classes.mainform}>

                  <div className={classes.loandgrantcards}>

                    <Modal show={show} onHide={handleClose} animation={false}>
                      <Modal.Header closeButton>
                        <Modal.Title>Add Designation</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Form style={{ marginTop: 20 }}>
                          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter Description"
                              // autoFocus
                              value={department}
                              onChange={(e) => setDepartment(e.target.value)}
                            />


                          </Form.Group>
                        </Form>
                      </Modal.Body>






                      <Modal.Footer>
                        <Button variant="danger" onClick={handleClose}>
                          Go back
                        </Button>
                        <Button variant="success" onClick={createUser}>
                          {loading ? (
                            <>
                              <Spinner size='sm' />
                              <span style={{ marginLeft: '5px' }}>Creating, Please wait...</span>
                            </>
                          ) : (
                            "Create Designation"
                          )}
                        </Button>
                      </Modal.Footer>
                    </Modal>

                    <Modal show={show1} onHide={handleClose1} animation={false}>
                      <Modal.Header closeButton>
                        <Modal.Title>Update Designation</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Form style={{ marginTop: 20 }}>
                          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter Description"
                              // autoFocus
                              value={department1}
                              onChange={(e) => setDepartment1(e.target.value)}
                            />


                          </Form.Group>
                        </Form>
                      </Modal.Body>






                      <Modal.Footer>
                        <Button variant="danger" onClick={handleClose}>
                          Go back
                        </Button>
                        <Button variant="success" >
                          {loading ? (
                            <>
                              <Spinner size='sm' />
                              <span style={{ marginLeft: '5px' }}>Creating, Please wait...</span>
                            </>
                          ) : (
                            "Create Designation"
                          )}
                        </Button>
                      </Modal.Footer>
                    </Modal>
                    {/* <Modal show={show1} onHide={handleClose1} animation={false}>
                    <Modal.Header closeButton>
                      <Modal.Title>Update Step</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <Form style={{ marginTop: 20 }}>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                          <Form.Label>Step</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Step"
                            // autoFocus
                            value={step1}
                            onChange={(e) => setStep1(e.target.value)}
                          />


                        </Form.Group>
                      </Form>
                    </Modal.Body>






                    <Modal.Footer>
                      <Button variant="danger" onClick={handleClose1}>
                        Go back
                      </Button>
                      <Button variant="success" onClick={editUser}>
                        {updateLoading ? (
                          <>
                            <Spinner size='sm' />
                          </>
                        ) : (
                          "Update Step"
                        )}
                      </Button>
                    </Modal.Footer>
                  </Modal> */}



                    <Tabs
                      defaultActiveKey="ProductCategory"
                      id="uncontrolled-tab-example"
                      className="mb-3 complete-tabs"
                      variant="underline"
                      color="#2D995F"
                    >
                      <Tab eventKey="ProductCategory" title="Manage Designation">
                        <div className={`d-flex justify-content-between align-items-center ${classes.btnStyle}`}>
                          <div className={`${classes.greenbtn} ${classes.btndivStyle}`}>
                            <div className={classes.actionsBtns}>
                              <Button variant='success'>Copy</Button>
                              <Button variant='success'>Excel</Button>
                              <Button variant='success'>PDF</Button>
                              <Button variant='success'>Column visibility</Button>
                            </div>
                            <div className={classes.show}>
                              <label className="d-flex justify-content-start align-items-center">
                                Show
                                <select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" className="custom-select custom-select-sm form-control form-control-sm"
                                //  value={entriesPerPage}
                                //     onChange={(e) => {
                                //     setEntriesPerPage(parseInt(e.target.value));
                                //     setCurrentPage(1);
                                //     }}
                                >
                                  <option value={10}>10</option>
                                  <option value={25}>25</option>
                                  <option value={50}>50</option>
                                  <option value={100}>100</option>
                                </select>
                                entries
                              </label>
                            </div>
                          </div>
                          <div className="text-right modal-effect ">
                            <div id="DataTables_Table_0_filter" className="dataTables_filter">
                              <div className="d-flex justify-content-start align-items-center">
                                <div className="mr-2">Search:</div>
                                <input
                                  type="search"
                                  value={searchTerm}
                                  className="form-control form-control-sm"
                                  placeholder=""
                                  aria-controls="DataTables_Table_0"
                                  onChange={(e) => setSearchTerm(e.target.value)}
                                />

                              </div>


                            </div>
                          </div>
                        </div>
                        <div className={classes.mainTable}>
                          {roleLoading ? (
                            <p>Fetching Designation...</p>
                          ) : (
                            <div className="table-responsive">
                              <table className="table display table-bordered table-striped table-hover bg-white m-0 card-table">

                                <thead style={{ whiteSpace: 'nowrap' }}>
                                  <tr>
                                    <th>S/N</th>
                                    <th>Name</th>
                                    <th>Created At</th>
                                    <th>Actions</th>
                                  </tr>
                                </thead>
                                <tbody style={{ whiteSpace: 'nowrap', }}>

                                  {displayedData.map((item, index) => (
                                    <tr key={index}>
                                      <td>{index + 1}</td>
                                      <td>{item.name}</td>
                                      <td>{formatDate(item.created_at)}</td>

                                      <td>
                                        <div onClick={() => handleEyeClick(item.id)} className="btn btn-success-soft btn-sm mr-1">
                                          <i className="far fa-eye" style={{ color: "#008a4b", backgroundColor: "#28a7451a", padding: 2, borderColor: "#28a7454d", borderRadius: 5, fontSize: 12 }}></i>
                                        </div>
                                        <div onClick={() => handleTrashClick(item.id)} className="btn btn-danger-soft btn-sm">
                                          <i className="far fa-trash-alt" style={{ color: "#dc3545", backgroundColor: "#dc35451a", padding: 2, borderColor: "#dc35454d", borderRadius: 5, fontSize: 12 }}></i>
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          )}
                        </div>

                        <div className={classes.endded}>
                          <p>
                            Showing {startIndexx} to {endIndexx} of {totalEntries} entries
                          </p>
                          <div style={{ display: 'flex' }}>
                            <button
                              style={{ border: 'none', backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'nunito', color: '#000', marginRight: 10, cursor: "pointer" }}
                              onClick={handlePrevPage}
                              disabled={currentPage === 1}
                            >
                              Previous
                            </button>
                            {[...Array(totalPages)].map((_, page) => {
                              // Show only 5 pages or less if available
                              if (page < 5 || page === currentPage - 1 || page === totalPages - 1) {
                                return (
                                  <button
                                    key={page + 1}
                                    style={{
                                      marginLeft: '0.4rem',
                                      marginRight: '0.4rem',
                                      fontSize: '14px',
                                      fontFamily: 'nunito',
                                      fontWeight: 400,
                                      color: page + 1 === currentPage ? '#ffffff' : '#000',
                                      backgroundColor: page + 1 === currentPage ? '#28a745' : 'gainsboro',
                                      height: '2.5rem',
                                      borderRadius: '89px',
                                      padding: '0.5rem',
                                      border: 'none',
                                      width: '40px',
                                      cursor: "pointer"
                                    }}
                                    onClick={() => setCurrentPage(page + 1)}
                                  >
                                    {page + 1}
                                  </button>
                                );
                              }
                              return null;
                            })}
                            <button
                              style={{ cursor: "pointer", border: 'none', backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'nunito', color: '#000', marginLeft: 10 }}
                              onClick={handleNextPage}
                              disabled={currentPage === totalPages}
                            >
                              Next
                            </button>
                          </div>
                        </div>
                      </Tab>


                    </Tabs>




                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>






    </div >
  )
}