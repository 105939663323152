import React, { useState, useEffect } from 'react';
import classes from '../ExchangeRate/ExchangeRate.module.css';
import { Spinner, Badge, Form, Tab, Tabs } from 'react-bootstrap';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Link, useNavigate, useParams } from 'react-router-dom'
import { BASE_URL } from '../api/api';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import Select from 'react-select';
import { Modal, Button } from 'react-bootstrap';
import { useModal } from './GlobalContext';

const ModalComponent = () => {
  const { showModal, handleClose, modalContent } = useModal(); // Access state and functions
  const navigate = useNavigate();
  const [rates, setRates] = useState('');
  const [shortname, setShortName] = useState('');
  const [shortname1, setShortName1] = useState('');
  const [rates1, setRates1] = useState('');
  const [description, setDescription] = useState('');
  const [description1, setDescription1] = useState('');
  const [loading, setLoading] = useState(false);
  const [bearer, setBearer] = useState('');
  const [user, setUser] = useState('');
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedReport, setSelectedReport] = useState(null);

  const [tableData3, setTableData3] = useState([]);
  const [tableData2, setTableData2] = useState([]);
  const [isLoading, setIsLoading] = useState(false);







  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem('userToken');
      const value1 = await AsyncStorage.getItem('tobi');
      const value2 = await AsyncStorage.getItem('permissions');
      const value3 = await AsyncStorage.getItem('admin');

      if (value !== null) {
        setBearer(value);
      }
      if (value1 !== null) {
        setUser(value1);
      }
      // if (value2 !== null) {
      //   setPermissions(value2);
      // }
      // if (value3 !== null) {
      //   setAdmin(value3);
      // }
    } catch (e) {
      alert('Failed to fetch the input from storage');
    }
  };

  useEffect(() => {
    readData();
  }, []);

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${bearer}`
  };



  const createRate = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${BASE_URL}/taxes/create`,
        {
          description: description,
          short_name: shortname,
          rate: rates,
          report_gl: selectedReport.value || ''
        },
        { headers }
      );

      console.log(response) 
      await fetchData2();

      // Clear form fields after successful submission
      setDescription("");
      setShortName("");
      setRates("");
      setSelectedReport(null); // Set to null instead of empty string for object value
  
      // Close modal after resetting form
      handleClose();
      // return
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: response.data.message,
      }).then(() => {
        window.location.reload();
      });
      // toast.success(response.data.message);
      console.log(response.data);

      // fetchRates();
      // fetchArchivedData();
      handleClose();

      toast.success(response.data.message);
      console.log(response);

    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        toast.error(errorMessage);
        console.log(errorMessage);
      }
    } finally {
      setLoading(false);
    }
  };

  const fetchData2 = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/product-categories`, { headers });
      const ts = response.data?.data;
      // console.log(results);
      setTableData3(ts);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        // navigate('/login');
      } else {
        const errorStatus = error.response?.data?.message;
        console.log(errorStatus);
        setTableData3([]);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const fetchGl = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/account`, { headers });
      const results = response.data?.data;

      setTableData2(results);
      console.log(results);
    } catch (error) {
      const errorStatus = error.response?.data?.message;
      console.log(errorStatus);
      setTableData2([]);
    } finally {
      setIsLoading(false);
    }
  };

 useEffect(() => {
    if (bearer) {
      fetchData2();
      fetchGl();
    }
  }, [bearer]);

  const optionsire = tableData3.map(item => ({
    label: item.description,
    value: item.id
  }));

  const options1 = tableData2.map(item => ({
    label: item.gl_name,
    value: item.id
  }));
  // console.log(options1);

  return (
    // <Modal show={showModal} onHide={handleClose}>
    <Modal show={showModal} onHide={handleClose} animation={false}>
      <Modal.Header closeButton>
        <Modal.Title>Create Tax</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form style={{ marginTop: 20 }}>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Description</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Description"
              // autoFocus
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />

          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Short Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Short Name"
              // autoFocus
              value={shortname}
              onChange={(e) => setShortName(e.target.value)}
            />

          </Form.Group>

          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Rate</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Rate"
              // autoFocus
              value={rates}
              onChange={(e) => setRates(e.target.value)}
            />

          </Form.Group>

          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Report to</Form.Label>
            <Select loading={isLoading} dropdownHeight='100px' options={options1}
              placeholder="Select Account" onChange={setSelectedReport} value={selectedReport}
              className={classes.formSelect}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={handleClose}>
          Go back
        </Button>
        <Button variant="success" onClick={createRate}>
          {loading ? (
            <>
              <Spinner size='sm' />
              <span style={{ marginLeft: '5px' }}>Creating New Tax, Please wait...</span>
            </>
          ) : (
            "Create New Tax"
          )}
        </Button>
      </Modal.Footer>
    </Modal>
    // </Modal>
  );
};

export default ModalComponent;
