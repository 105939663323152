import React, { useState, useEffect, } from 'react';
// import ManageCustomerUI from './ManageCustomerUI';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
// import Swal from 'sweetalert2';
// import CustomersUi from './CustomersUi';
import { BASE_URL } from '../../api/api';
import CustomersAccountUi from './EmployeesAccountUi';



function EmployeeAccount() {
  const navigate = useNavigate();
  const [currentTime, setCurrentTime] = useState(new Date());
  const [benLoading, setBenLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [show56, setShow56] = useState(false);
  const [show1, setShow1] = useState(false);
  const [bearer, setBearer] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);
  const [level, setRole] = useState("");
  const [role1, setRole1] = useState("");
  const [checkAll, setCheckAll] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [stat, setStat] = useState([]);
  const [tableData1, setTableData1] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [statLoading, setStatLoading] = useState(false);
  const [archiveData, setArchivedData] = useState([]);
  const [department, setDepartment] = useState("");
  const [department1, setDepartment1] = useState("");
  const [deptId, setDeptId] = useState("");
  const handleClose = () => setShow(false);
  const handleClose56 = () => setShow56(false);
  const handleClose1 = () => setShow1(false);
  const handleShow = () => setShow(true);
  const handleShow56 = () => setShow56(true);
  const handleShow1 = () => setShow1(true);
  const [totalEntries, setTotalEntries] = useState("");
  const [roleLoading, setRoleLoading] = useState(false);
  const [stepLoading, setStepLoading] = useState(false);
  const [rolleLoading, setroleLoading] = useState(false);
  const [gradeLoading, setGradeLoading] = useState(false);
  const [desLoading, setDesLoading] = useState(false);
  const [eyeClicked, setEyeClicked] = useState(false);
  const [trashClicked, setTrashClicked] = useState(false);
  const [perm, setPerm] = useState([]);
  const [searchedResult, setSearchedResult] = useState([]);
  const [fullName, setFullName] = useState("");
  const [title, setTitle] = useState("");
  const [gender, setGender] = useState("");
  const [dob, setDob] = useState("");
  const [doa, setDoa] = useState("");
  const [accountnumber, setAccountnumber] = useState("");
  const [eod, setEod] = useState("");
  const [pay, setPay] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [number, setNumber] = useState("");
  const [qualification, setQualification] = useState("");
  const [marital, setMarital] = useState("");
  const [bank, setBank] = useState("");
  const [lga, setLga] = useState("");
  const [medical, setMedical] = useState("");
  const [name, setfullName] = useState("");
  const [fullName1, setFullName1] = useState("");
  const [email, setEmail] = useState("");
  const [email1, setEmail1] = useState("");
  const [phone1, setPhone1] = useState("");
  const [phone, setPhone] = useState("");
  const [roles, setRoles] = useState([]);
  const [address, setAddress] = useState("");
  const [office_address, setOfficeAddress] = useState("");
  const [entriesPerPage, setEntriesPerPage] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [customers, setCustomers] = useState('');
  const [user, setUser] = useState('');
  const [uploadLoading, setUploadLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [totalPages, setTotalPages] = useState(1);
  // const AddEmployeeModal = ({ show, handleClose }) => {
    const [departments, setDepartments] = useState([]);
    const [grade, setGrades] = useState([]);
    const [step, setStep] = useState([]);
    const [designation, setDesignations] = useState([]);
    const [selectedDepartment, setSelectedDepartment] = useState('');
    const [selectedRole, setSelectedRole] = useState('');
    const [selectedDesignation, setSelectedDesignation] = useState('');
    const [selectedStep, setSelectedStep] = useState('');
    const [selectedGrade, setSelectedGrade] = useState('');
    const [employeeName, setEmployeeName] = useState('');

  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem('userToken');
      const value1 = await AsyncStorage.getItem('tobi');

      if (value !== null) {
        setBearer(value);
      }
      if (value1 !== null) {
        setUser(value1);
      }
    } catch (e) {
      alert('Failed to fetch the input from storage');
    }
  };

  useEffect(() => {
    readData();
  }, []);


  
    const fetchDepartments = async () => {
      setRoleLoading(true);
      try {
        const response = await axios.get(`${BASE_URL}/payroll/department`, { headers });
        const data = response.data?.data?.data;
        // console.log(results);
        setDepartments(data);
      } catch (error) {
        console.error('Error fetching departments:', error);
      }
    };

    useEffect(() => {
      if (bearer) {
    fetchDepartments();
      }
  }, [bearer]);

    const fetchGrades = async () => {
      setGradeLoading(true);
      try {
        const response = await axios.get(`${BASE_URL}/payroll/grade`, { headers });
        const data = response.data?.data?.data;
        console.log(data);
        setGrades(data);
      } catch (error) {
        console.error('Error fetching grades:', error);
      }
    };

    useEffect(() => {
      if (bearer) {
    fetchGrades();
      }
  }, [bearer]);

    const fetchStep = async () => {
      setStepLoading(true);
      try {
        const response = await axios.get(`${BASE_URL}/payroll/step`, { headers });
        const data = response.data?.Steps?.data;
        console.log(data);
        setStep(data);
      } catch (error) {
        console.error('Error fetching steps:', error);
      }
    };

    useEffect(() => {
      if (bearer) {
        fetchStep();
      }
  }, [bearer]);


    const fetchRole = async () => {
      setroleLoading(true);
      try {
        const response = await axios.get(`${BASE_URL}/payroll/level`, { headers });
        const data = response.data?.data?.data;
        console.log(data);
        setRole(data);
      } catch (error) {
        console.error('Error fetching role:', error);
      }
    };

    useEffect(() => {
      if (bearer) {
    fetchRole();
      }
  }, [bearer]);


    const fetchDesignations = async () => {
      setDesLoading(true);
      try {
        const response = await axios.get(`${BASE_URL}/payroll/designation`, { headers });
        const data = response.data?.data?.data;
        // console.log(results);
        setDesignations(data);
      } catch (error) {
        console.error('Error fetching designations:', error);
      }
    };

    useEffect(() => {
      if (bearer) {
    fetchDesignations();
      }
  }, [bearer]);

  // Function to handle department selection
  // const handleDepartmentChange = (e) => {
  //   setSelectedDepartment(e.target.value);
  // };

  // Function to handle form submission
  // const handleSubmit = () => {
  //   const newEmployee = {
  //     name: employeeName,
  //     designation: designation,
  //     department: selectedDepartment,
  //   };

  // specify header
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${bearer}`
  };

  const fetchBeneficiaries = async () => {
    setBenLoading(true);
    setTableData([]);
    try {
      const response = await axios.get(`${BASE_URL}/payroll/staff`, { headers });
      const results = response.data?.data?.employees;
      
 // Log the results to understand its structure
 console.log('API results:', response);

 // Check if the results are an array before setting the table data
 if (Array.isArray(results)) {
   setTableData(results);
 } else {
   // If not an array, fallback to an empty array and show a warning
   setTableData([]);
   console.warn('Expected an array but received:', results);
 }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate('/login');
      } else {
        let errorMessage = 'An error occurred. Please try again.';
        if (error.response && error.response.data && error.response.data.message) {
          if (typeof error.response.data.message === 'string') {
            errorMessage = error.response.data.message;
          } else if (Array.isArray(error.response.data.message)) {
            errorMessage = error.response.data.message.join('; ');
          } else if (typeof error.response.data.message === 'object') {
            toast.error(errorMessage)
            console.log(errorMessage);
          }
        }
        setTableData([]);
      }
    } finally {
      setBenLoading(false);
    }
  };

  const fetchArchivedData = async () => {
    setRoleLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/customer/fetch-softdelete`, { headers });
      const results = response.data?.data;
      // console.log(results);
      setArchivedData(results);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
        const errorStatus = error.response?.data?.message;
        console.log(errorStatus);
        setArchivedData([]);
      }
    } finally {
      setRoleLoading(false);
    }
  };

  const fetchStat = async () => {
    setStatLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/customer/total-customers`, { headers });
      const stas = response.data?.data;

      setStat(stas);

    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate('/login');
      } else {
        let errorMessage = 'An error occurred. Please try again.';
        if (error.response && error.response.data && error.response.data.message) {
          if (typeof error.response.data.message === 'string') {
            errorMessage = error.response.data.message;
          } else if (Array.isArray(error.response.data.message)) {
            errorMessage = error.response.data.message.join('; ');
          } else if (typeof error.response.data.message === 'object') {
            toast.error(errorMessage)
            console.log(errorMessage);
          }
        }
        setTableData([]);
      }
    } finally {
      setStatLoading(false);
    }
  };



  useEffect(() => {
    if (bearer) {
      fetchBeneficiaries();


    }
  }, [bearer, currentPage]);


  useEffect(() => {
    if (bearer) {
      fetchStat();
      fetchArchivedData();

    }
  }, [bearer]);


 // Handle department change
 const handleDepartmentChange = (e) => {
  setSelectedDepartment(e.target.value);

};

 // Handle designation change
 const handleDesignationChange = (e) => {
  setSelectedDesignation(e.target.value);
};

 // Handle role change
 const handleRoleChange = (e) => {
  setSelectedRole(e.target.value);
};

 // Handle role change
 const handleStepChange = (e) => {
  setSelectedStep(e.target.value);
};

 // Handle Grade change
 const handleGradeChange = (e) => {
  setSelectedGrade(e.target.value);
};




  const fetchSearch = async (searchTerm) => {
    setSearchLoading(true);
    try {
      let res;
      if (searchTerm.trim() === "") {
        res = tableData;
      } else {
        const response = await axios.get(`${BASE_URL}/customer/search`, {
          params: { variable: searchTerm },
          headers
        });
        res = response.data?.data;
      }
      setSearchedResult(res);
      console.log(res, "searched");

    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate('/login');
      } else {
        let errorMessage = 'An error occurred. Please try again.';
        if (error.response && error.response.data && error.response.data.message) {
          if (typeof error.response.data.message === 'string') {
            errorMessage = error.response.data.message;
          } else if (Array.isArray(error.response.data.message)) {
            errorMessage = error.response.data.message.join('; ');
          } else if (typeof error.response.data.message === 'object') {
            errorMessage = JSON.stringify(error.response.data.message);
          }
        }
        setSearchedResult([]);
      }
    } finally {
      setSearchLoading(false);
    }
  };
  //create Employee
  const createCustomer = async () => {
    setLoading(true);
    try {
      const response = await axios.post(`${BASE_URL}/payroll/staff/create`,
        {
          name: name,
          title: title,
          gender: gender,
          rsa_number: number,
          dob: dob,
          date_of_appointment: doa,
          employment_date: eod,
          qualification: qualification,
          marital_status: marital,
          account_bank: bank,
          account_number: accountnumber,
          medical_condition: medical,
          email: email,
          state: state,
          net_pay: pay,
          city: city,
          phone_number: phone,
          address: address,
          country: country,
          lga: lga,
          // office_address: office_address,
          grade: selectedGrade,
          step: selectedStep,
          designation: selectedDesignation,
          dept_id: selectedDepartment,
          level: selectedRole,
        },
        { headers }
      );
      console.log(response)
      fetchDepartments();
      fetchGrades();
      fetchStep();
      fetchRole();
      fetchDesignations();
      handleClose();
      // return
      toast.success(response.data.message);
      console.log(response.data);

    } catch (error) {
      const errorStatus = error.response.data.message;
      toast.error(JSON.stringify(error.response.data.message));
      Swal.fire({
        icon: 'error',
        title: 'Failed',
        text: errorStatus,
      });
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  //format date
  function formatDate(dateString) {
    const date = new Date(dateString);
    const formattedDate = `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())} ${padZero(date.getHours())}:${padZero(date.getMinutes())} ${date.getHours() >= 12 ? 'PM' : 'AM'}`;
    return formattedDate;
  }

  function padZero(num) {
    return num < 10 ? `0${num}` : num;
  }


  //view records
  const handleEyeClick = (id) => {
    const foundCustomer = tableData.find(item => item.id === id);
    navigate('/update_employee', { state: { selectedCustomer: foundCustomer } });
    console.log(foundCustomer, "table");
    setEyeClicked(true);
  };

  const handleEyeClick1 = (id) => {
    const foundCustomer = searchedResult.find(item => item.id === id);
    navigate('/inventory/update_customer', { state: { selectedCustomer: foundCustomer } });
    console.log(foundCustomer, "searched");
    setEyeClicked(true);
  };
  const handleEyeClick2 = (id) => {
    const foundCustomer = searchedResult.find(item => item.id === id);
    navigate('/inventory/update_customer', { state: { selectedCustomer: foundCustomer } });
    console.log(foundCustomer, "searched");
    setEyeClicked(true);
  };

  const handleTrashClick = async (id) => {
    const confirmed = await Swal.fire({
      title: 'Are you sure?',
      text: 'You are about to archive this member.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, archive it!',
      cancelButtonText: 'No, cancel',
    });

    if (!confirmed.isConfirmed) {
      return; // User canceled, do nothing
    }

    try {
      const response = await axios.get(`${BASE_URL}/payroll/staff/delete?id=${id}`, { headers });
      fetchBeneficiaries();
      fetchStat();
      fetchArchivedData();
      toast.success(response.data.message);
      setTrashClicked(true);
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        toast.error(errorMessage)
        console.log(errorMessage);
      }
    }
  };
  const handleTrashClick1 = async (id) => {
    const confirmed = await Swal.fire({
      title: 'Are you sure?',
      text: 'You are about to delete this member.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel',
    });

    if (!confirmed.isConfirmed) {
      return; // User canceled, do nothing
    }

    try {
      const response = await axios.get(`${BASE_URL}/payroll/staff/delete?id=${id}`, { headers });
      fetchBeneficiaries();
      fetchStat();
      fetchArchivedData();
      toast.success(response.data.message);
      setTrashClicked(true);
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        toast.error(errorMessage)
        console.log(errorMessage);
      }
    }
  };
  const handleTrashClick2 = async (id) => {
    const confirmed = await Swal.fire({
      title: 'Are you sure?',
      text: 'You are about to restore this member.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, restore it!',
      cancelButtonText: 'No, cancel',
    });

    if (!confirmed.isConfirmed) {
      return; // User canceled, do nothing
    }

    try {
      const response = await axios.get(`${BASE_URL}/payroll/staff/delete?id=${id}`, { headers });
      fetchBeneficiaries();
      fetchStat();
      fetchArchivedData();
      toast.success(response.data.message);
      setTrashClicked(true);
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        toast.error(errorMessage)
        console.log(errorMessage);
      }
    }
  };

  //update function
  const editCustomer = async () => {
    setLoading(true);

    try {
      const response = await axios.post(`${BASE_URL}/admin/users/update`,
        {
          name: fullName1,
          id: deptId,
          email: email1,
          phone: phone1,

        },
        { headers }
      );

      fetchBeneficiaries();
      fetchStat();
      fetchArchivedData();
      toast.success(response.data.message);

      // console.log(response.data);
    } catch (error) {
      const errorStatus = error.response?.data?.message || 'An error occurred';

      Swal.fire({
        icon: 'error',
        title: 'Failed',
        text: errorStatus,
      });

      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleFileChange = (event) => {
    const files = event.target.files;
    const fileList = Array.from(files);
    setSelectedFile(fileList);

  };

  const uploadExcel = async () => {
    setUploadLoading(true);
    try {
      const formData = new FormData();
      formData.append('file', selectedFile[0]);
      // formData.append('transaction_date', selectedDate);

      // console.log(selectedFile);

      const headers = {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${bearer}`,
      };

      const response = await axios.post(`${BASE_URL}/customer/import`,
        formData,
        { headers }
      );
      handleClose56();
      fetchBeneficiaries();
      fetchStat();
      fetchArchivedData();
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: response.data.message,
      });

      console.log(response.data.message);
    } catch (error) {
      const errorStatus = error.response.data.message;

      Swal.fire({
        icon: 'error',
        title: 'Failed',
        text: errorStatus,
      });

      console.error(error);
    } finally {
      setUploadLoading(false);
    }
  };


  //filter function
  // const filteredData = tableData.filter(item => item.name.toLowerCase().includes(searchTerm.toLowerCase()));

  // const totalPages = Math.ceil(filteredData.length / entriesPerPage);

  const handlePrevPage = () => {
    setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages));
  };

  // const filteredData = tableData.filter(item => item.name.includes(searchTerm));
  const startIndexx = (currentPage - 1) * entriesPerPage + 1;
  const endIndexx = Math.min(startIndexx + entriesPerPage - 1, totalEntries);
  // const displayedData = filteredData.slice(currentPage - 1, totalEntries);




  return (
    <CustomersAccountUi
      // Pass necessary props from the logic to the UI component
      show={show}
      show56={show56}
      show1={show1}
      handleShow={handleShow}
      handleShow56={handleShow56}
      handleShow1={handleShow1}
      handleClose={handleClose}
      handleClose56={handleClose56}
      handleClose1={handleClose1}
      createCustomer={createCustomer}
      editCustomer={editCustomer}
      isLoading={isLoading}
      loading={loading}
      searchTerm={searchTerm}
      setSearchTerm={setSearchTerm}
      entriesPerPage={entriesPerPage}
      setEntriesPerPage={setEntriesPerPage}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      totalEntries={totalEntries}
      totalPages={totalPages}
      startIndexx={startIndexx}
      endIndexx={endIndexx}
      stat={stat}
      statLoading={statLoading}
      roleLoading={roleLoading}
      stepLoading={stepLoading}
      gradeLoading={gradeLoading}
      setGradeLoading={setGradeLoading}
      handlePrevPage={handlePrevPage}
      handleNextPage={handleNextPage}
      handleEyeClick={handleEyeClick}
      handleEyeClick1={handleEyeClick1}
      handleEyeClick2={handleEyeClick2}
      handleTrashClick={handleTrashClick}
      handleTrashClick1={handleTrashClick1}
      handleTrashClick2={handleTrashClick2}
      fullName={fullName}
      setFullName={setFullName}
      address={address}
      setAddress={setAddress}
      office_address={office_address}
      setOfficeAddress={setOfficeAddress}
      phone={phone}
      setPhone={setPhone}
      email={email}
      name={name}
      title={title}
      gender={gender}
     number={number}
     medical={medical}
     pay={pay}
     dob={dob}
     doa={doa}
     accountnumber={accountnumber}
     city={city}
     country={country}
     state={state}
     lga={lga}
     eod={eod}
     bank={bank}
     qualification={qualification}
      marital={marital}
      setfullName={setfullName}
      setGender={setGender}
      setDob={setDob}
      setDoa={setDoa}
      setAccountnumber={setAccountnumber}
      setEod={setEod}
      setNumber={setNumber}
      setMarital={setMarital}
      setBank={setBank}
      setLga={setLga}
      setPay={setPay}
      setCountry={setCountry}
      setCity={setCity}
      setState={setState}
      setMedical={setMedical}
      setQualification={setQualification}
      setTitle={setTitle}
      setEmail={setEmail}
      fullName1={fullName1}
      setFullName1={setFullName1}
      phone1={phone1}
      setPhone1={setPhone1}
      email1={email1}
      setEmail1={setEmail1}
      tableData={tableData}
      // department={department}
      formatDate={formatDate}
      uploadExcel={uploadExcel}
      handleFileChange={handleFileChange}
      uploadLoading={uploadLoading}
      benLoading={benLoading}
      user={user}
      archiveData={archiveData}
      fetchSearch={fetchSearch}
      setSearchedResult={setSearchedResult}
      searchedResult={searchedResult}
      searchLoading={searchLoading}
      departments={departments}
      grade={grade}
      step={step}
      designation={designation}
      level={level}
      selectedDepartment={selectedDepartment}
      setSelectedDepartment={setSelectedDepartment}
      selectedStep={selectedStep}
      selectedGrade={selectedGrade}
      setSelectedStep={setSelectedStep}
      selectedRole={selectedRole}
      setSelectedRole={setSelectedRole}
      selectedDesignation={selectedDesignation}
      setSelectedDesignation={setSelectedDesignation}
      handleDepartmentChange={handleDepartmentChange}
      handleDesignationChange={handleDesignationChange}
      handleRoleChange={handleRoleChange}
      handleStepChange={handleStepChange}
      handleGradeChange={handleGradeChange}
      employeeName={employeeName}
      setEmployeeName={setEmployeeName}
      // designations={designations}
      // setDesignation={setDesignation}
      
    />
  )
}

export default EmployeeAccount;