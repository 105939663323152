import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Navbar, Nav, NavDropdown, Row, Button, Modal, Form, Spinner, Badge } from 'react-bootstrap';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Swal from 'sweetalert2';
import classes from '../../../Manage Cooperatives/Manage Members/ManageMember.module.css'
import { BASE_URL } from '../../../api/api';
import { toast, ToastContainer } from 'react-toastify';
import CurrencyInput from 'react-currency-input-field';
import Select from 'react-select';

import MainDashboard from '../../../Main Dashboard/MainDashoard';
import LatestDash from '../../../LatestDashboard/LatestDash';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import jsPDF from 'jspdf';
import 'jspdf-autotable';




function MonthlyIncomeStatement() {
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [load, setLoad] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [inputss, setInputss] = useState([]);
  const [tableData45, setTableData45] = useState([]);
  const [GenLedger, setGenLedger] = useState([]);
  const [sales, setSales] = useState([]);
  const [otherIncome, setOtherIncome] = useState([]);
  const [costSales, setCostSales] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [ledgerLoading, setLedgerLoading] = useState(false);
  const [savingsLoading, setSavingsLoading] = useState(false);
  const [generalLoading, setGeneralLoading] = useState(false);
  const [fetchingLoading, setFetchingLoading] = useState(false);
  const [bearer, setBearer] = useState('');
  const navigate = useNavigate();
  const [paidBooking, setPaidBooking] = useState([]);
  const [savingss, setSavingss] = useState([]);
  const [ressult, setRessult] = useState([]);
  const [provinceOptions, setProvinceOptions] = useState([]);
  const [user, setUser] = useState("");
  const [selectOptions, setSelectOptions] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState('');
  const [selectedCustomerName, setSelectedCustomerName] = useState([]);
  const [selectedSavingsType, setSelectedSavingsType] = useState([]);
  const [selectedSavingsName, setSelectedSavingsName] = useState('');
  const [selectedContinent, setSelectedContinent] = useState('');
  const [tableData3, setTableData3] = useState([]);
  const [tableData4, setTableData4] = useState([]);
  const [filteredDatas, setFilteredDatas] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState('');
  const [admin, setAdmin] = useState('');
  const [selectedProvince, setSelectedProvince] = useState('');
  const [selectedAccount, setSelectedAccount] = useState('');
  const [tableData, setTableData] = useState([]);
  const [selectedEndDate, setSelectedEndDate] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [entriesPerPage, setEntriesPerPage] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);
  const [consolidatedLoading, setConsolidatedLoading] = useState(false);
  const [continent, setContinent] = useState('');
  const [region, setRegion] = useState('');
  const [province, setProvince] = useState('');
  const [totalPages, setTotalPages] = useState('');
  const [totalEntries, setTotalEntries] = useState('');


  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  const handleDateChange1 = (event) => {
    setSelectedEndDate(event.target.value);


  };



  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem('userToken');
      const value2 = await AsyncStorage.getItem('companyId');
      const value1 = await AsyncStorage.getItem('tobi');
      const value4 = await AsyncStorage.getItem('admin');
      const continentName = await AsyncStorage.getItem('continent');
      const regionName = await AsyncStorage.getItem('region');
      const provinceName = await AsyncStorage.getItem('province');

      if (value !== null) {
        setBearer(value);
      }
      if (value1 !== null) {
        setUser(value1);
      }
      if (value4 !== null) {
        setAdmin(value4);
      }
      if (continentName !== null) {
        setContinent(continentName);
      }
      if (regionName !== null) {
        setRegion(regionName);
      }
      if (provinceName !== null) {
        setProvince(provinceName);
      }

    } catch (e) {
      alert('Failed to fetch the input from storage');
    }
  };

  useEffect(() => {
    readData();
  }, []);

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${bearer}`
  };

  const goBack = () => {
    navigate(-1);
  }

  const handleAccountChange = (selectedOption) => {
    setSelectedAccount(selectedOption.value);
  };

  const fetchAccounts = async () => {
    setFetchingLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/reports/profit-loss-build`, {
      // const response = await axios.get(`${BASE_URL}/reports/summary-profit-loss-exclusive`, {
        params: {
          // gl_code: selectedAccount,
          start_date: selectedDate,
          end_date: selectedEndDate,
        },
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${bearer}`
        }
      });
      const apiResponse = response.data?.data || [];

      const sales = apiResponse.sales ? [apiResponse.sales] : [];
      const othr = apiResponse.other_income ? [apiResponse.other_income] : [];
      const costOfSales = apiResponse.cost_of_sales ? [apiResponse.cost_of_sales] : [];
      
const expenses = apiResponse.expenses || [];

setRessult(response.data?.data || []); // Setting all the data
setAccounts(apiResponse); // Setting the first object
setInputss(expenses);
setSales(sales);
setOtherIncome(othr);
setCostSales(costOfSales);

console.log(othr);

      // const resultsss = response.data?.data[0] || [];
      // const ress = response.data?.data || [];
      // setRessult(ress);
      // setAccounts(resultsss);

      // const resultssx = response.data?.data?.expenses || [];
      // setInputss(resultssx);
      // console.log(response);
      // const resultssx = response.data?.data[1] || [];
      // setInputss(resultssx);
      // console.log(response);

    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
        const errorStatus = error.response?.data?.message;
        console.log(errorStatus);

      }
    } finally {
      setFetchingLoading(false);
    }
  };

  const fetchCharts = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/account`, { headers });
      const results = response.data?.data;

      const opt1 = results.map((item) => ({
        label: item.gl_name,
        value: item.id,
      }));

      setTableData(opt1);

    } catch (error) {
      const errorStatus = error.response?.data?.message;
      console.log(errorStatus);
      setTableData([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (bearer) {
      fetchCharts();

    }
  }, [bearer]);



  // const filteredData = inputss.filter(item => item.gl_name.toLowerCase().includes(searchTerm.toLowerCase()));

  // const totalPages = Math.ceil(filteredData.length / entriesPerPage);


  // const totalPages = Math.ceil(filteredData.length / entriesPerPage);

  const handlePrevPage = () => {
    setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages));
  };



  // const totalEntries = filteredData.length;
  const startIndexx = (currentPage - 1) * entriesPerPage + 1;
  const endIndexx = Math.min(startIndexx + entriesPerPage - 1, totalEntries);
  // const displayedData = filteredData.slice(startIndexx - 1, endIndexx);



  const fetchPaidBookings = async () => {
    setLedgerLoading(true);

    try {
      const response = await axios.get(
        `${BASE_URL}/reports/general-ledger`,
        {

          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearer}`
          }
        }
      );
      const genLed = response.data?.data;
      setGenLedger(genLed);
      //     const genTotal = response.data?.data?.total;
      // setTotalEntries(genTotal);
      // const genLast = response.data?.data?.last_page || 1;
      // setTotalPages(genLast);

      console.log(response, "NI");
    } catch (error) {
      const errorStatus = error.response.data.message;
      console.error(errorStatus);
    } finally {
      setLedgerLoading(false);
    }
  };

  useEffect(() => {
    if (bearer) {
      fetchPaidBookings();

    }
  }, [bearer]);

  const fetchGeneralLedger = async () => {
    setGeneralLoading(true);

    try {
      const response = await axios.get(
        `${BASE_URL}/account/fetch-member-ledger-by-account?member_id=${selectedCustomer}&account_id=${selectedSavingsType}`,
        {

          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearer}`
          }
        }
      );
      const resultssx = response.data?.data;
      setPaidBooking(resultssx);

      console.log(resultssx, "NI");
    } catch (error) {
      const errorStatus = error.response.data.message;
      console.error(errorStatus);
    } finally {
      setGeneralLoading(false);
    }
  };




  function formatDate(dateString) {
    const date = new Date(dateString);
    const formattedDate = `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())} ${padZero(date.getHours())}:${padZero(date.getMinutes())} ${date.getHours() >= 12 ? 'PM' : 'AM'}`;
    return formattedDate;
  }

  function padZero(num) {
    return num < 10 ? `0${num}` : num;
  }

  // const totalIncome = accounts.reduce((acc, item) => acc + parseFloat(item.amount), 0);
  // const totalExpenses = inputss.reduce((acc, item) => acc + parseFloat(item.amount), 0);
 // Clipboard
const handleCopyToClipboard = () => {
  const headers = ['Name', 'Amount'];

  let tableString = headers.join('\t') + '\n';

  // Add SALES section
  if (sales?.length > 0) {
    tableString += 'SALES\n';
    sales.forEach((item) => {
      const row = [
        item.name,
        parseFloat(item.amount).toLocaleString('en-US', {
          minimumIntegerDigits: 1,
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      ];
      tableString += row.join('\t') + '\n';
    });
  }

  // Add COST OF SALES section
  if (costSales?.length > 0) {
    tableString += 'COST OF SALES\n';
    costSales.forEach((item) => {
      const row = [
        item.name,
        parseFloat(item.amount).toLocaleString('en-US', {
          minimumIntegerDigits: 1,
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      ];
      tableString += row.join('\t') + '\n';
    });
  }

  // Add OTHER INCOME section
  if (otherIncome?.length > 0) {
    tableString += 'OTHER INCOME\n';
    otherIncome.forEach((item) => {
      const row = [
        item.name,
        parseFloat(item.amount).toLocaleString('en-US', {
          minimumIntegerDigits: 1,
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      ];
      tableString += row.join('\t') + '\n';
    });
  }

  navigator.clipboard.writeText(tableString)
    .then(() => alert('Copied to clipboard!'))
    .catch((err) => console.error('Failed to copy:', err));
};

// Excel Export
const handleExcelExport = async () => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('Report');

  worksheet.columns = [
    { header: 'Name', key: 'name', width: 50 },
    { header: 'Amount', key: 'amount', width: 20 }
  ];

  // Add SALES section
  if (sales?.length > 0) {
    worksheet.addRow(['SALES']);
    sales.forEach((item) => {
      worksheet.addRow({
        name: item.name,
        amount: parseFloat(item.amount).toLocaleString('en-US', {
          minimumIntegerDigits: 1,
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })
      });
    });
  }

  // Add COST OF SALES section
  if (costSales?.length > 0) {
    worksheet.addRow(['COST OF SALES']);
    costSales.forEach((item) => {
      worksheet.addRow({
        name: item.name,
        amount: parseFloat(item.amount).toLocaleString('en-US', {
          minimumIntegerDigits: 1,
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })
      });
    });
  }

  // Add OTHER INCOME section
  if (otherIncome?.length > 0) {
    worksheet.addRow(['OTHER INCOME']);
    otherIncome.forEach((item) => {
      worksheet.addRow({
        name: item.name,
        amount: parseFloat(item.amount).toLocaleString('en-US', {
          minimumIntegerDigits: 1,
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })
      });
    });
  }

  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  const url = window.URL.createObjectURL(blob);
  const anchor = document.createElement('a');
  anchor.href = url;
  anchor.download = 'monthly income statement-summary.xlsx';
  anchor.click();
  window.URL.revokeObjectURL(url);
};

// PDF Generation
const handlePdfExport = () => {
  const doc = new jsPDF();
  doc.setFontSize(12);

  // Headers
  const headers = [['Name', 'Amount']];

  // Prepare sales data for PDF
  const salesData = sales.map(item => [
    item.name,
    parseFloat(item.amount).toLocaleString('en-US', {
      minimumIntegerDigits: 1,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })
  ]);

  const costSalesData = costSales.map(item => [
    item.name,
    parseFloat(item.amount).toLocaleString('en-US', {
      minimumIntegerDigits: 1,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })
  ]);

  const otherIncomeData = otherIncome.map(item => [
    item.name,
    parseFloat(item.amount).toLocaleString('en-US', {
      minimumIntegerDigits: 1,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })
  ]);

  doc.text('SALES', 14, 20);
  doc.autoTable({
    startY: 25,
    head: headers,
    body: salesData
  });

  doc.text('COST OF SALES', 14, doc.lastAutoTable.finalY + 10);
  doc.autoTable({
    startY: doc.lastAutoTable.finalY + 15,
    head: headers,
    body: costSalesData
  });

  doc.text('OTHER INCOME', 14, doc.lastAutoTable.finalY + 10);
  doc.autoTable({
    startY: doc.lastAutoTable.finalY + 15,
    head: headers,
    body: otherIncomeData
  });

  doc.save('monthly income statement-summary.pdf');
};

  


  return (

    <div>

      <div className="wrapper">
        {/* <!-- Sidebar  --> */}


        {/* <!-- Page Content  --> */}
        <div className="content-wrapper">
          <div className="main-content">
            <ToastContainer />
            {/* <MainDashboard /> */}
            <div className='newBody'>
              <div className={classes.newWidth}>

                <div>
                  <LatestDash />

                </div>

                <div className={classes.finishedbodyCont}>
                <h3 style={{fontSize: '30px', fontWeight: 700, margin:" 10px 10px 0  20px" }}>Monthly Income Statement - Summary</h3>
                <p style={{margin:" 0 10px 20px 20px" }}>Process monthly income statement details by selecting the below required field</p>
                  {/* <h3 style={{ fontSize: 20, fontWeight: 700, marginLeft: "2rem" }}>Income Statement</h3> */}
                  {/* <h3 style={{marginLeft:'2rem'}}>Cash Book</h3> */}
                  <div className={classes.topSpace} style={{ backgroundColor: 'white' }}>
                    <div className={classes.topPadding}>
                      <div className={`${classes.formSecCont}`}>
                        <div className="card-body" style={{ border: 'none' }}>


                          <div className={`row ${classes.lblrow}`}>

                            <div className={`row ${classes.lblrow}`} style={{}}>
                              <div className="col-md-4" >
                                <div className="form-group row">
                                  <label htmlFor="example-text-input" className={`col-sm-5 col-form-label font-weight-400 ${classes.lblrow}`}>Start Date:</label>
                                  <div className={`col-sm-9 ${classes.lblrow}`}>
                                    <input className="form-control" required="" type="date" onChange={handleDateChange} name="start" value={selectedDate} />
                                  </div>
                                </div>
                              </div>
                              <div className={`col-md-4 `} style={{marginLeft: -105}}>
                                <div className="form-group row">
                                  <label htmlFor="example-text-input" className={`col-sm-5 col-form-label font-weight-400 ${classes.lblrow}`}>End Date:</label>
                                  <div className="col-sm-9">
                                    <input className="form-control" required="" type="date" onChange={handleDateChange1} name="end" value={selectedEndDate} />
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* )} */}<div className={`modal-footer ${classes.overlayBtnrows001}`}>
                            <OverlayTrigger
                                placement="top"
                                className={classes.btndf1}
                                overlay={<Tooltip id="button-tooltip"  className={classes.btndf1}>Click here to process income statement</Tooltip>}
                              >
                                <Button
                                style={{ width: 100, height: "30px", padding: "0", borderRadius: 5, marginTop: 10 }}
                                  disabled={!selectedEndDate || !selectedDate}
                                  className={classes.overlayBtns11} variant='success' onClick={fetchAccounts}>
                                  {fetchingLoading ? (
                                    <>
                                      <Spinner size='sm' />
                                      {/* <span style={{ marginLeft: '5px' }}>Processing, Please wait...</span> */}
                                    </>
                                  ) : (
                                    "Process"
                                  )}
                                </Button>
                              </OverlayTrigger>
                              
                              




                      </div>
                          </div>
                        </div>
                      </div>
                      {/* <div style={{ marginTop: 20 }} /> */}


                      
                    </div>















                    <div style={{ backgroundColor: 'white', padding: '0px 20px' }}>
                      {/* <!--Content Header (Page header)--> */}
                      <div className="content-header row align-items-center m-0">
                        {/* {(isAdmin || permittedHeaders.includes('create-savings-account')) && ( */}
                        {/* <nav aria-label="breadcrumb" className="col-sm-4 order-sm-last mb-3 mb-sm-0 p-0 ">
                  <div
                    style={{
                      marginTop: 20,
                      marginBottom: 20,
                      justifyContent: "flex-end",
                      display: "flex",
                      marginLeft: "auto",
                    }}
                    className={classes.actionBtns}
                  >
                    <Button variant="success" onClick={handleCreate}>
                      Create New Accounts
                    </Button>
                  </div>

                </nav> */}
                        {/* )} */}

                        <div className="col-sm-8 header-title p-0">
                          <div className="media">
                            {/* <div className="header-icon text-success mr-3"><i className=""><img src={favicon} className={classes.favshi} alt="favicon" /></i></div> */}
                            <div className="media-body">
                              {/* <h4 className="font-weight-bold">Savings Account</h4> */}
                              {/* <small>Create and view your Savings Accounts...</small> */}
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* <!--/.Content Header (Page header)--> */}
                      <div className="body-content">
                        <div className="row">

                          <div className="col-lg-12 col-xl-6">
                            <div className="row">

                              <div className="col-md-6 col-lg-6">

                                {/* <!--Feedback--> */}

                              </div>
                              <div className="col-md-6 col-lg-6">

                                {/* <!--Balance indicator--> */}

                              </div>
                              <div className="col-md-6 col-lg-6">

                                {/* <!--Time on site indicator--> */}

                              </div>
                              <div className="col-md-6 col-lg-6">

                                {/* <!--Top Referrals--> */}

                              </div>
                              <div className="col-md-6 col-lg-6">

                                {/* <!--Sessions by device--> */}

                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          {/* <div className="col-md-12 col-lg-12 col-xl-3 mb-4">
                    <div className="card">


                    </div>
                  </div> */}






                          <div className="col-lg-12">
                            <div>
                              <div>
                              {(inputss?.length > 0) && (
                                  <Button
                                    variant='success'
                                    onClick={() => navigate('/accounting/print_monthly_income_statement', { state: { accounts, inputss, selectedDate, selectedEndDate, ressult, sales, costSales, otherIncome } })}
                                    style={{ height: 30, width: 150, borderRadius: 0, marginTop: 10, marginLeft: 20, justifyContent: "center", alignItems: "center", display: 'flex' }}
                                  >
                                    Print Report
                                  </Button>
                                )}

                              </div>
                              <div className="card-body">
                                <div className="table-resposive">
                                  <div className={`d-flex justify-content-between align-items-center ${classes.btnStyle}`} style={{ padding: '20px 0 0 0', marginBottom: 20 }}>
                                    <div className={`${classes.greenbtn} ${classes.btndivStyle}`}>
                                      <div className={classes.actionsBtns}>
                                      <button disabled={ressult.length === 0} onClick={handleCopyToClipboard}>Copy</button>
<button disabled={ressult.length === 0} onClick={handleExcelExport}>Excel</button>
<button disabled={ressult.length === 0} onClick={handlePdfExport}>PDF</button>
                                        <button className={classes.diffbtn}>Column visibility</button>
                                      </div>
                                      <div className={classes.show}>
                                        <label className="d-flex justify-content-start align-items-center">
                                          Show
                                          <select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" className="custom-select custom-select-sm form-control form-control-sm" value={entriesPerPage}
                                            onChange={(e) => {
                                              setEntriesPerPage(parseInt(e.target.value));
                                              setCurrentPage(1);
                                            }}>
                                            <option value={10}>10</option>
                                            <option value={25}>25</option>
                                            <option value={50}>50</option>
                                            <option value={100}>100</option>
                                          </select>
                                          entries
                                        </label>
                                      </div>
                                    </div>
                                    <div className="text-right modal-effect ">
                                      <div id="DataTables_Table_0_filter" className="dataTables_filter">
                                        <div className="d-flex justify-content-start align-items-center">
                                          <div className="mr-2">Search:</div>
                                          <input
                                            type="search"
                                            value={searchTerm}
                                            className="form-control form-control-sm"
                                            placeholder=""
                                            aria-controls="DataTables_Table_0"
                                            onChange={(e) => {
                                              setSearchTerm(e.target.value);
                                              // setCurrentPage(1);
                                            }}
                                          />
                                        </div>

                                      </div>
                                    </div>
                                  </div>


                                  {fetchingLoading ? (
                                    <p>Fetching accounts...</p>
                                  ) : (
                                    <div className="table-responsive" id="table-to-pdf">
                                      <table className="table display table-bordered table-striped table-hover bg-white m-0 card-table">
                                        <thead style={{ whiteSpace: 'nowrap' }}>
                                          <tr>
                                            {/* <th>S/N</th> */}
                                            {/* <th>Account Code</th> */}
                                            <th>Name</th>
                                            <th>Amount</th>
                                          </tr>
                                        </thead>
                                        <tbody style={{ whiteSpace: 'nowrap' }}>
                                          {sales?.length > 0 && (
                                            <tr>
                                              <td colSpan="4" style={{ fontWeight: 'bold', textAlign: 'left' }}>SALES</td>
                                            </tr>
                                          )}
                                          {sales?.map((item, index) => (
                                            <tr key={index}>
                                              {/* <td>{item.gl_code}</td> */}
                                              <td>{item.name}</td>
                                              <td style={{textAlign: 'right'}}>{parseFloat(item.amount).toLocaleString('en-US', {
  minimumIntegerDigits: 1,
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
})}</td>
                                            </tr>
                                            
                                             
                                         
                                          ))}
                                          {sales?.length > 0 && (
                                          <tr>
      <td style={{ fontWeight: 'bold', textAlign: 'left' }}>TOTAL FOR SALES</td>
      <td style={{ fontWeight: 'bold', textAlign: 'right' }}>
      {parseFloat(
          sales.reduce((total, item) => total + (item.amount || 0), 0)
        ).toLocaleString('en-US', {
          minimumIntegerDigits: 1,
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })}

      </td>
    </tr>
                                          )}
                                          
                                          {costSales?.length > 0 && (
                                            <tr>
                                              <td colSpan="4" style={{ fontWeight: 'bold', textAlign: 'left' }}>COST OF SALES</td>
                                            </tr>
                                          )}
                                          {costSales?.map((item, index) => (
                                            <tr key={index}>
                                              {/* <td>{item.gl_code}</td> */}
                                              <td>{item.name}</td>
                                              <td style={{textAlign: 'right'}}>{parseFloat(item.amount).toLocaleString('en-US', {
  minimumIntegerDigits: 1,
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
})}</td>
                                            </tr>
                                            
                                             
                                         
                                          ))}
                                          {costSales?.length > 0 && (
                                          <tr>
      <td style={{ fontWeight: 'bold', textAlign: 'left' }}>TOTAL FOR COST OF SALES</td>
      <td style={{ fontWeight: 'bold', textAlign: 'right' }}>
      {parseFloat(
          costSales.reduce((total, item) => total + (item.amount || 0), 0)
        ).toLocaleString('en-US', {
          minimumIntegerDigits: 1,
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })}


      </td>
    </tr>
                                          )}
                                          {sales?.length > 0 && (
                                          <tr>
      <td style={{ fontWeight: 'bold', textAlign: 'left' }}>GROSS PROFIT</td>
      <td style={{ fontWeight: 'bold', textAlign: 'right' }}>
      {parseFloat(
      sales.reduce((total, item) => total + (item.amount || 0), 0) -
      costSales.reduce((total, item) => total + (item.amount || 0), 0)
    ).toLocaleString('en-US', {
      minimumIntegerDigits: 1,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })}

      </td>
    </tr>
                                          )}
                                          {otherIncome?.length > 0 && (
                                            <tr>
                                              <td colSpan="4" style={{ fontWeight: 'bold', textAlign: 'left' }}>OTHER INCOME</td>
                                            </tr>
                                          )}
                                          {otherIncome?.map((item, index) => (
                                            <tr key={index}>
                                              {/* <td>{item.gl_code}</td> */}
                                              <td>{item.name}</td>
                                              <td style={{textAlign: 'right'}}>{parseFloat(item.amount).toLocaleString('en-US', {
  minimumIntegerDigits: 1,
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
})}</td>
                                            </tr>
                                            
                                             
                                         
                                          ))}
                                          {otherIncome?.length > 0 && (
                                          <tr>
      <td style={{ fontWeight: 'bold', textAlign: 'left' }}>TOTAL FOR OTHER INCOME</td>
      <td style={{ fontWeight: 'bold', textAlign: 'right' }}>
      {parseFloat(
          otherIncome.reduce((total, item) => total + (item.amount || 0), 0)
        ).toLocaleString('en-US', {
          minimumIntegerDigits: 1,
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })}


      </td>
    </tr>
                                          )}
                                          {inputss?.length > 0 && (
                                            <tr>
                                              <td colSpan="4" style={{ fontWeight: 'bold', textAlign: 'left' }}>EXPENSES</td>
                                            </tr>
                                          )}
                                          {inputss?.map((item, index) => (
                                            <tr key={index + accounts.length}>
                                             <td>{item.name}</td>
                                             <td style={{textAlign: 'right'}}>{parseFloat(item.amount).toLocaleString('en-US', {
  minimumIntegerDigits: 1,
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
})}</td>
                                            </tr>
                                          ))}
                                          {inputss?.length > 0 && (
                                          <tr>
      <td style={{ fontWeight: 'bold', textAlign: 'left' }}>TOTAL EXPENSES</td>
      <td style={{ fontWeight: 'bold', textAlign: 'right' }}>
      {parseFloat(
          inputss.reduce((total, item) => total + (item.amount || 0), 0)
        ).toLocaleString('en-US', {
          minimumIntegerDigits: 1,
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })}

      </td>
    </tr>
                                          )}
                                          {inputss?.length > 0 && (
                                          <tr>
      <td style={{ fontWeight: 'bold', textAlign: 'left' }}>NET PROFIT OR LOSS BEFORE TAX</td>
      <td style={{ fontWeight: 'bold', textAlign: 'right' }}>
      {parseFloat(
      // Calculate Gross Profit
      sales.reduce((total, item) => total + (item.amount || 0), 0) -
      costSales.reduce((total, item) => total + (item.amount || 0), 0) -
      
      // Subtract the result of (Total Expenses - Other Income)
      (inputss.reduce((total, item) => total + (item.amount || 0), 0) -
      otherIncome.reduce((total, item) => total + (item.amount || 0), 0))
    ).toLocaleString('en-US', {
      minimumIntegerDigits: 1,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })}

      </td>
    </tr>
                                          )}
                                          {inputss?.length > 0 && (
                                          <tr>
      <td style={{ fontWeight: 'bold', textAlign: 'left' }}>NET PROFIT OR LOSS AFTER TAX</td>
      <td style={{ fontWeight: 'bold', textAlign: 'right' }}>
      {parseFloat(
      // Calculate Gross Profit
      sales.reduce((total, item) => total + (item.amount || 0), 0) -
      costSales.reduce((total, item) => total + (item.amount || 0), 0) -
      
      // Subtract the result of (Total Expenses - Other Income)
      (inputss.reduce((total, item) => total + (item.amount || 0), 0) -
      otherIncome.reduce((total, item) => total + (item.amount || 0), 0))
    ).toLocaleString('en-US', {
      minimumIntegerDigits: 1,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })}

      </td>
    </tr>
                                          )}
                                          
                                          {/* {inputss?.postings?.length > 0 && (
    <tr>
      <td colSpan="4" style={{ fontWeight: 'bold', textAlign: 'left' }}>DEPRECIATION AND FINANCES</td>
    </tr>
                                          )}
                                           {inputss?.finance?.map((item, index) => (
                                            <tr key={index + accounts.length}>
                                             <td>{item.name}</td>
                                             <td style={{textAlign: 'right'}}>{parseFloat(item.amount).toLocaleString('en-US', {
  minimumIntegerDigits: 1,
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
})}</td>
                                            </tr>
                                          ))} */}
                                        </tbody>
                                        
                                      </table>
                                    </div>
                                  )}
                                  <div className={classes.endded}>
                                    <p>
                                      Showing {startIndexx} to {endIndexx} of {totalEntries} entries
                                    </p>
                                    <div style={{ display: 'flex' }}>
                                      <button
                                        style={{ border: 'none', backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'nunito', color: '#000', marginRight: 10, cursor: "pointer" }}
                                        onClick={handlePrevPage}
                                        disabled={currentPage === 1}
                                      >
                                        Previous
                                      </button>
                                      {[...Array(totalPages)].map((_, page) => {
                                        // Show only 5 pages or less if available
                                        if (page < 5 || page === currentPage - 1 || page === totalPages - 1) {
                                          return (
                                            <button
                                              key={page + 1}
                                              style={{
                                                marginLeft: '0.4rem',
                                                marginRight: '0.4rem',
                                                fontSize: '14px',
                                                fontFamily: 'nunito',
                                                fontWeight: 400,
                                                color: page + 1 === currentPage ? '#ffffff' : '#000',
                                                backgroundColor: page + 1 === currentPage ? '#28a745' : 'gainsboro',
                                                height: '2.5rem',
                                                borderRadius: '89px',
                                                padding: '0.5rem',
                                                border: 'none',
                                                width: '40px',
                                                cursor: "pointer"
                                              }}
                                              onClick={() => setCurrentPage(page + 1)}
                                            >
                                              {page + 1}
                                            </button>
                                          );
                                        }
                                        return null;
                                      })}
                                      <button
                                        style={{ cursor: "pointer", border: 'none', backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'nunito', color: '#000', marginLeft: 10 }}
                                        onClick={handleNextPage}
                                        disabled={currentPage === totalPages}
                                      >
                                        Next
                                      </button>
                                    </div>
                                  </div>



                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <!--/.body content--> */}
                    </div>
                  </div>
                </div>
                {/* <!--/.main content--> */}
              </div>
            </div>
            {/* <InfoFooter /> */}
            {/* <!--/.footer content--> */}
            <div className="overlay"></div>
          </div>
          {/* <!--/.wrapper--> */}

        </div>
      </div>
    </div>

  );
}

export default MonthlyIncomeStatement;