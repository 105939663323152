import React from 'react';
import { Chart } from 'react-charts';

const PieChart = () => {
  const data = [
    {
      label: 'Expenses',
      data: [
        { primary: 'Rent', secondary: 1000 },
        { primary: 'Salaries', secondary: 5000 },
        { primary: 'Utilities', secondary: 800 },
        { primary: 'Materials', secondary: 2000 },
      ],
    },
  ];

  const chartOptions = {
    data,
    primaryAxis: {
      getValue: datum => datum.primary,
    },
    secondaryAxes: [
      {
        getValue: datum => datum.secondary,
        elementType: 'arc',
      },
    ],
    getDatumStyle: datum => ({
      fill: datum.originalDatum.primary === 'Rent' ? 'blue' :
            datum.originalDatum.primary === 'Salaries' ? 'green' :
            datum.originalDatum.primary === 'Utilities' ? 'red' :
            'orange',
    }),
  };

  const Legend = ({ data }) => (
    <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '20px' }}>
      {data[0].data.map(item => (
        <div key={item.primary} style={{ display: 'flex', alignItems: 'center', margin: '5px 0' }}>
          <div
            style={{
              width: '20px',
              height: '20px',
              backgroundColor: item.primary === 'Rent' ? 'blue' :
                              item.primary === 'Salaries' ? 'green' :
                              item.primary === 'Utilities' ? 'red' :
                              'orange',
              marginRight: '10px',
            }}
          ></div>
          <span>{item.primary}</span>
        </div>
      ))}
    </div>
  );

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '500px' }}>
      <div style={{ width: '400px', height: '400px' }}>
        <Chart options={chartOptions} />
      </div>
      <Legend data={data} />
    </div>
  );
};

export default PieChart;
