import React, { useState, useEffect } from 'react';
// import "../assets/plugins/bootstrap/css/bootstrap.min.css";
// import "../assets/plugins/metisMenu/metisMenu.min.css";
// import "../assets/plugins/fontawesome/css/all.min.css";
// import "../assets/plugins/typicons/src/typicons.min.css";
// import "../assets/plugins/themify-icons/themify-icons.min.css";
// import "../assets/plugins/datatables/dataTables.bootstrap4.min.css";
// import "../style.css";
import { NavLink, useNavigate } from 'react-router-dom';
import { Navbar, Nav, NavDropdown, Button, Modal, Form, Spinner, Badge } from 'react-bootstrap';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Swal from 'sweetalert2';
import classes from '../../Manage Cooperatives/Manage Members/ManageMember.module.css'
import MainDashboard from '../../Main Dashboard/MainDashoard';
import { BASE_URL } from '../../api/api';
import { toast } from 'react-toastify';
import Arrow from '../../../assets/promix/dArrow-down.svg'
import PaymentDash from '../../Payment Dashboard/PaymentDash';
import Select from 'react-select';



function Schedule() {
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [load, setLoad] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [inputss, setInputss] = useState([]);
  const [tableData45, setTableData45] = useState([]);
  const [GenLedger, setGenLedger] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [ledgerLoading, setLedgerLoading] = useState(false);
  const [savingsLoading, setSavingsLoading] = useState(false);
  const [generalLoading, setGeneralLoading] = useState(false);
  const [fetchingLoading, setFetchingLoading] = useState(false);
    const [bearer, setBearer] = useState('');
    const navigate = useNavigate();
    const [paidBooking, setPaidBooking] = useState([]);
    const [savingss, setSavingss] = useState([]);
    const [provinceOptions, setProvinceOptions] = useState([]);
    const [user, setUser] = useState("");
    const [selectOptions, setSelectOptions] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState('');
    const [selectedCustomerName, setSelectedCustomerName] = useState([]);
    const [selectedSavingsType, setSelectedSavingsType] = useState([]);
    const [selectedSavingsName, setSelectedSavingsName] = useState('');
    const [selectedContinent, setSelectedContinent] = useState('');
    const [tableData3, setTableData3] = useState([]);
    const [tableData4, setTableData4] = useState([]);
    const [filteredDatas, setFilteredDatas] = useState([]);
    const [selectedRegion, setSelectedRegion] = useState('');
    const [admin, setAdmin] = useState('');
    const [selectedProvince, setSelectedProvince] = useState('');
    const [selectedAccount, setSelectedAccount] = useState('');
    const [tableData, setTableData] = useState([]);
    const [selectedEndDate, setSelectedEndDate] = useState('');
    const [selectedDate, setSelectedDate] = useState('');
    const [entriesPerPage, setEntriesPerPage] = useState(100);
    const [currentPage, setCurrentPage] = useState(1);
    const [continent, setContinent] = useState('');
    const [region, setRegion] = useState('');
    const [province, setProvince] = useState('');


    const handleDateChange = (event) => {
      setSelectedDate(event.target.value);
  };

  const handleDateChange1 = (event) => {
    setSelectedEndDate(event.target.value);

   
};



    const readData = async () => {
        try {
          const value = await AsyncStorage.getItem('userToken');
          const value2 = await AsyncStorage.getItem('companyId');
          const value1 = await AsyncStorage.getItem('tobi');
          const value4 = await AsyncStorage.getItem('admin');
          const continentName = await AsyncStorage.getItem('continent');
          const regionName = await AsyncStorage.getItem('region');
          const provinceName = await AsyncStorage.getItem('province');
    
          if (value !== null) {
            setBearer(value);
          }
          if (value1 !== null) {
              setUser(value1);
            }
          if (value4 !== null) {
              setAdmin(value4);
            }
            if (continentName !== null) {
              setContinent(continentName);
          }
          if (regionName !== null) {
              setRegion(regionName);
          }
          if (provinceName !== null) {
              setProvince(provinceName);
          }
      
        } catch (e) {
          alert('Failed to fetch the input from storage');
        }
      };
    
      useEffect(() => {
        readData();
      }, []);

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${bearer}`
    };

    const goBack = () => {
        navigate(-1);
    }

    const handleAccountChange = (selectedOption) => {
      setSelectedAccount(selectedOption.value);
  };

    const scheduleOfPayables = async () => {
      setFetchingLoading(true);
      try {
          const response = await axios.get(`${BASE_URL}/reports/schedule-of-payable`, {
              params: {
                  // gl_code: selectedAccount,
                  start_date: selectedDate,
                  end_date: selectedEndDate,
                  
              },
              headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${bearer}`
              }
          });
          // const resultsss = response.data?.data?.transaction || [];
          const resultsss = response.data?.data;
          setAccounts(resultsss);

      // const resultssx = response.data?.data?.journals || [];
      // setInputss(resultssx);
      console.log("scheduleOfPayables", resultsss);
         
      } catch (error) {
          if (error.response && error.response.status === 401) {
              // Redirect to login page if unauthorized
              navigate('/login');
          } else {
              const errorStatus = error.response?.data?.message;
              // console.log(errorStatus);

          }
      } finally {
          setFetchingLoading(false);
      }
  };

  const fetchCharts = async () => {
    setIsLoading(true);
    try {
        const response = await axios.get(`${BASE_URL}/account`, { headers });
        const results = response.data?.data;

        const opt1 = results.map((item) => ({
          label: item.gl_name,
          value: item.id,
        }));

        setTableData(opt1);
        
    } catch (error) {
        const errorStatus = error.response?.data?.message;
        console.log(errorStatus);
        setTableData([]);
    } finally {
        setIsLoading(false);
    }
};

useEffect(() => {
    if (bearer) {
        fetchCharts();

    }
}, [bearer]);



const filteredData = inputss.filter(item => item.gl_name.toLowerCase().includes(searchTerm.toLowerCase()));

    const totalPages = Math.ceil(filteredData.length / entriesPerPage);


    // const totalPages = Math.ceil(filteredData.length / entriesPerPage);

    const handlePrevPage = () => {
      setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
    };
    
    const handleNextPage = () => {
      setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages));
    };

  

    const totalEntries = filteredData.length;
    const startIndexx = (currentPage - 1) * entriesPerPage + 1;
    const endIndexx = Math.min(startIndexx + entriesPerPage - 1, totalEntries);
    // const displayedData = filteredData.slice(startIndexx - 1, endIndexx);



  const fetchPaidBookings = async () => {
      setLedgerLoading(true);

      try {
          const response = await axios.get(
              `${BASE_URL}/reports/general-ledger`,
              {

                  headers: {
                      'Content-Type': 'application/json',
                      'Authorization': `Bearer ${bearer}`
                  }
              }
          );
          const genLed = response.data?.data;
          setGenLedger(genLed);
      //     const genTotal = response.data?.data?.total;
      // setTotalEntries(genTotal);
      // const genLast = response.data?.data?.last_page || 1;
      // setTotalPages(genLast);

            console.log(genLed, "NI");
      } catch (error) {
          const errorStatus = error.response.data.message;
          console.error(errorStatus);
      } finally {
        setLedgerLoading(false);
      }
  };

  useEffect(() => {
    if (bearer) {
      fetchPaidBookings();

    }
}, [bearer]);

  const fetchGeneralLedger = async () => {
      setGeneralLoading(true);

      try {
          const response = await axios.get(
              `${BASE_URL}/account/fetch-member-ledger-by-account?member_id=${selectedCustomer}&account_id=${selectedSavingsType}`,
              {

                  headers: {
                      'Content-Type': 'application/json',
                      'Authorization': `Bearer ${bearer}`
                  }
              }
          );
          const resultssx = response.data?.data;
          setPaidBooking(resultssx);

            console.log(resultssx, "NI");
      } catch (error) {
          const errorStatus = error.response.data.message;
          console.error(errorStatus);
      } finally {
        setGeneralLoading(false);
      }
  };


  


 
 


  

 

  const fetchContinents = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/continents`, { headers });
      const results = response.data?.data;

      const options = results.map((item) => ({
        label: item.description,
        value: item.id,
      }));
      // setCustomers(results);
      setSelectOptions(options);
    } catch (error) {
      const errorStatus = error.response?.data?.message;
      console.log(errorStatus);
      setCustomers([]);
    } finally {
      setIsLoading(false);
    }
  };
    
 
useEffect(() => {
  if (bearer) {
    fetchContinents();
  }
}, [bearer]);



const fetchSubContinents = async () => {
  setLoad(true);
  try {
    const response = await axios.get(`${BASE_URL}/regions`, { headers });

    // console.log(response);
    const subCont = response.data?.data?.regions;
    const options1 = subCont.map((item) => ({
      label: item.description,
      value: item.id,
    }));
    setTableData3(options1);
    // console.log(results);
  } catch (error) {
    if (error.response && error.response.status === 401) {
      // Redirect to login page if unauthorized
      navigate('/login');
    }else{
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
          if (typeof error.response.data.message === 'string') {
              errorMessage = error.response.data.message;
          } else if (Array.isArray(error.response.data.message)) {
              errorMessage = error.response.data.message.join('; ');
          } else if (typeof error.response.data.message === 'object') {
              errorMessage = JSON.stringify(error.response.data.message);
          }
      }
    setTableData3([]);
    }
  } finally {
    setLoad(false);
  }
};

const fetchSubRegion = async () => {
  setLoad(true);
  try {
    const response = await axios.get(`${BASE_URL}/regions/getprovincesregion?region_id=${selectedRegion}`, { headers });

    // console.log(response);
    const subCont1 = response.data?.data;
    const options2 = subCont1.map((item) => ({
      label: item.description,
      value: item.id,
    }));
    setTableData4(options2);
    console.log(options2);
  } catch (error) {
    if (error.response && error.response.status === 401) {
      // Redirect to login page if unauthorized
      navigate('/login');
    }else{
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
          if (typeof error.response.data.message === 'string') {
              errorMessage = error.response.data.message;
          } else if (Array.isArray(error.response.data.message)) {
              errorMessage = error.response.data.message.join('; ');
          } else if (typeof error.response.data.message === 'object') {
              errorMessage = JSON.stringify(error.response.data.message);
          }
      }
    setTableData3([]);
    }
  } finally {
    setLoad(false);
  }
};
const fetchProvince = async () => {
  setLoad(true);
  try {
    const response = await axios.get(`${BASE_URL}/provinces`, { headers });

    // console.log(response);
    const subCont15 = response.data?.data.provinces;
    const options25 = subCont15.map((item) => ({
      label: item.description,
      value: item.id,
    }));
    setTableData45(options25);
    // console.log(results);
  } catch (error) {
    if (error.response && error.response.status === 401) {
      // Redirect to login page if unauthorized
      navigate('/login');
    }else{
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
          if (typeof error.response.data.message === 'string') {
              errorMessage = error.response.data.message;
          } else if (Array.isArray(error.response.data.message)) {
              errorMessage = error.response.data.message.join('; ');
          } else if (typeof error.response.data.message === 'object') {
              errorMessage = JSON.stringify(error.response.data.message);
          }
      }
    setTableData3([]);
    }
  } finally {
    setLoad(false);
  }
};

useEffect(() => {
  if (bearer) {
    fetchSubContinents();
  }
}, [bearer]);

useEffect(() => {
  if (bearer && selectedRegion) {
    fetchSubRegion(selectedRegion);
  }
}, [bearer, selectedRegion]);

useEffect(() => {
  if (bearer) {
    fetchProvince();
  }
}, [bearer]);

const handleContinentChange = (selectedOption) => {
  setSelectedContinent(selectedOption.value); 
  setSelectedRegion(''); 
  setSelectedProvince(''); 
};


const handleRegionChange = (selectedOption) =>{
  setSelectedRegion(selectedOption.value)
}

const handleProvinceChange = (selectedOption) =>{
  setSelectedProvince(selectedOption.value)
  if (admin === 'Continental Accountant') {
    setProvinceOptions(tableData4);
  }
}


function formatDate(dateString) {
  const date = new Date(dateString);
  const formattedDate = `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())} ${padZero(date.getHours())}:${padZero(date.getMinutes())} ${date.getHours() >= 12 ? 'PM' : 'AM'}`;
  return formattedDate;
}

function padZero(num) {
  return num < 10 ? `0${num}` : num;
}

const totalIncome = accounts.reduce((acc, item) => acc + parseFloat(item.amount), 0);
  const totalExpenses = inputss.reduce((acc, item) => acc + parseFloat(item.amount), 0);
  
  

  return (

    <div>

<div className="wrapper">
        {/* <!-- Sidebar  --> */}


        {/* <!-- Page Content  --> */}
        <div className="content-wrapper">
          <div className="main-content">
          <MainDashboard/>
            <div className='newBody'>
            <div className={classes.newWidth}>

            <div className={classes.topPadding}>
                    <div className={`${classes.formSecCont}`}>
                        <div className={classes.formSectionHeader}>
                        {/* <p style={{textTransform: "capitalize", fontWeight: "bold"}}>
  {admin === "Continental Accountant" ? continent :
      admin === "Regional Accountant" ? region :
      admin === "Super Admin" ? "Super Admin" :
      admin === "Provincial Accountant" ? province : ''}
</p> */}
                            <h3>Schedule Of Payable</h3>
                            {/* <small>Create and view your loan accounts...</small> */}
                        </div>
                        <div className={classes.formSectionHeader}>
                            <h3 style={{color:'#2D995F'}}>{user.toLocaleUpperCase()}</h3>
                            {/* <p style={{marginTop: -10}}>{admin}</p> */}
                            {/* <p style={{textTransform: "capitalize", fontWeight: "bold"}}>
  {`(${admin === "Continental Accountant" ? continent :
      admin === "Regional Accountant" ? region :
      admin === "Super Admin" ? "Super Admin" :
      admin === "Provincial Accountant" ? province : ''})`}
</p> */}

                        </div>
                    </div>
                    </div>

                    

            <div className={classes.topPadding}>
                    <div className={`${classes.formSecCont}`}>
                    <div className="card-body" style={{border:'none'}}>


                    <div className="row">
    {/* Super Admin sees everything */}
    {/* {admin === 'Super Admin' && (
      <>
        <div className="col-md-4">
          <div className="form-group row">
            <label htmlFor="example-text-input" className="col-sm-3 col-form-label font-weight-400">Continent:</label>
            <div className="col-sm-9">
              <Select
                onChange={(selectedOption) => handleContinentChange(selectedOption)}
                options={selectOptions}
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  menu: (provided) => ({
                    ...provided,
                    maxHeight: '300px',
                    maxWidth: '300px',
                    overflowY: 'auto',
                  }),
                }}
              />
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group row">
            <label htmlFor="example-text-input" className="col-sm-3 col-form-label font-weight-400">Region:</label>
            <div className="col-sm-9">
              <Select
                onChange={handleRegionChange}
                options={tableData3}
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  menu: (provided) => ({
                    ...provided,
                    maxHeight: '300px',
                    maxWidth: '300px',
                    overflowY: 'auto',
                  }),
                }}
              />
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group row">
            <label htmlFor="example-text-input" className="col-sm-3 col-form-label font-weight-400">Province:</label>
            <div className="col-sm-9">
              <Select
                onChange={(selectedOption) => handleProvinceChange(selectedOption)}
                options={tableData45}
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  menu: (provided) => ({
                    ...provided,
                    maxHeight: '300px',
                    overflowY: 'auto',
                  }),
                }}
              />
            </div>
          </div>
        </div>
      </>
     )} */}

    {/* Continental Accountant sees region and province */}
    {/* {admin === 'Continental Accountant' && (
      <>
        <div className="col-md-4">
          <div className="form-group row">
            <label htmlFor="example-text-input" className="col-sm-3 col-form-label font-weight-400">Region:</label>
            <div className="col-sm-9">
              <Select
                onChange={handleRegionChange}
                options={tableData3}
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  menu: (provided) => ({
                    ...provided,
                    maxHeight: '300px',
                    maxWidth: '300px',
                    overflowY: 'auto',
                  }),
                }}
              />
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group row">
            <label htmlFor="example-text-input" className="col-sm-3 col-form-label font-weight-400">Province:</label>
            <div className="col-sm-9">
              <Select
                onChange={(selectedOption) => handleProvinceChange(selectedOption)}
                options={tableData4}
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  menu: (provided) => ({
                    ...provided,
                    maxHeight: '300px',
                    overflowY: 'auto',
                  }),
                }}
              />
            </div>
          </div>
        </div>
      </>
     )}  */}

    {/* Regional Accountant sees only province */}
    {/* {admin === 'Regional Accountant' && (
      <div className="col-md-4">
        <div className="form-group row">
          <label htmlFor="example-text-input" className="col-sm-3 col-form-label font-weight-400">Province:</label>
          <div className="col-sm-9">
            <Select
              onChange={(selectedOption) => handleProvinceChange(selectedOption)}
              options={tableData45}
              menuPortalTarget={document.body}
              styles={{
                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                menu: (provided) => ({
                  ...provided,
                  maxHeight: '300px',
                  overflowY: 'auto',
                }),
              }}
            />
          </div>
        </div>
      </div> 
    )}  */}

    {/* Provincial Accountant sees only accounts and date fields */}
    {/* {(admin === 'Super Admin' || admin === 'Continental Accountant' || admin === 'Regional Accountant' || admin === 'Provincial Accountant') && ( */}
      <div className="row" style={{ marginTop: 30 }}>
        {/* <div className="col-md-4">
          <div className="form-group row">
            <label htmlFor="example-text-input" className="col-sm-3 col-form-label font-weight-400">Accounts:</label>
            <div className="col-sm-9">
              <Select
                onChange={(selectedOption) => handleAccountChange(selectedOption)}
                options={tableData}
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  menu: (provided) => ({
                    ...provided,
                    maxHeight: '300px',
                    maxWidth: '300px',
                    overflowY: 'auto',
                  }),
                }}
              />
            </div>
          </div>
        </div> */}
        <div className="col-md-4">
          <div className="form-group row">
            <label htmlFor="example-text-input" className="col-sm-4 col-form-label font-weight-400">Start Date:</label>
            <div className="col-sm-8">
              <input className="form-control" required="" type="date" onChange={handleDateChange} name="start" value={selectedDate} />
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group row">
            <label htmlFor="example-text-input" className="col-sm-4 col-form-label font-weight-400">End Date:</label>
            <div className="col-sm-8">
              <input className="form-control" required="" type="date" onChange={handleDateChange1} name="end" value={selectedEndDate} />
            </div>
          </div>
        </div>
      </div>
      {/* )}  */}
  </div>
  </div>
                                            </div>
                                            <div style={{ marginTop: 20 }} />


<div class="modal-footer" style={{ display: 'flex', justifyContent: 'flex-start', gap: 10 }}>
  <Button
disabled={!selectedEndDate}
    style={{ borderRadius: 0 }} variant='success' onClick={scheduleOfPayables}>
    {fetchingLoading ? (
      <>
        <Spinner size='sm' />
        <span style={{ marginLeft: '5px' }}>Processing, Please wait...</span>
      </>
    ) : (
      "Process"
    )}
  </Button>

</div>
            </div>


          

            


           







            <div style={{backgroundColor:'white', padding:'10px 20px'}}>
              {/* <!--Content Header (Page header)--> */}
              <div className="content-header row align-items-center m-0">
              {/* {(isAdmin || permittedHeaders.includes('create-savings-account')) && ( */}
                {/* <nav aria-label="breadcrumb" className="col-sm-4 order-sm-last mb-3 mb-sm-0 p-0 ">
                  <div
                    style={{
                      marginTop: 20,
                      marginBottom: 20,
                      justifyContent: "flex-end",
                      display: "flex",
                      marginLeft: "auto",
                    }}
                    className={classes.actionBtns}
                  >
                    <Button variant="success" onClick={handleCreate}>
                      Create New Accounts
                    </Button>
                  </div>

                </nav> */}
              {/* )} */}
              
                <div className="col-sm-8 header-title p-0">
                  <div className="media">
                    {/* <div className="header-icon text-success mr-3"><i className=""><img src={favicon} className={classes.favshi} alt="favicon" /></i></div> */}
                    <div className="media-body">
                      {/* <h4 className="font-weight-bold">Savings Account</h4> */}
                      {/* <small>Create and view your Savings Accounts...</small> */}
                    </div>
                  </div>
                </div>
              </div>

              {/* <!--/.Content Header (Page header)--> */}
              <div className="body-content">
                <div className="row">

                  <div className="col-lg-12 col-xl-6">
                    <div className="row">

                      <div className="col-md-6 col-lg-6">

                        {/* <!--Feedback--> */}

                      </div>
                      <div className="col-md-6 col-lg-6">

                        {/* <!--Balance indicator--> */}

                      </div>
                      <div className="col-md-6 col-lg-6">

                        {/* <!--Time on site indicator--> */}

                      </div>
                      <div className="col-md-6 col-lg-6">

                        {/* <!--Top Referrals--> */}

                      </div>
                      <div className="col-md-6 col-lg-6">

                        {/* <!--Sessions by device--> */}

                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  {/* <div className="col-md-12 col-lg-12 col-xl-3 mb-4">
                    <div className="card">


                    </div>
                  </div> */}






                  <div className="col-lg-12">
                    <div className="card">
                    <div>
                              {accounts.length > 0 && (
                <Button variant='success' onClick={() => navigate('/payment/print_schedule_of_payment', { state: { accounts, selectedDate, selectedEndDate } })} style={{ height: 30, width: 100, borderRadius: 0, marginTop: 10, marginLeft: 20, justifyContent: "center", alignItems:"center", display: 'flex'}}>PRINT</Button>
                              )}
                              </div>
                      <div className="card-body">
                        <div className="table-resposive">
                          <div className="d-flex justify-content-between align-items-center" style={{ padding: '20px 0 0 0', marginBottom: 20 }}>
                            <div className={classes.greenbtn} style={{ display: 'flex', }}>
                              <div>
                                <button>Copy</button>
                                <button>Excel</button>
                                <button>PDF</button>
                                <button className={classes.diffbtn}>Column visibility</button>
                              </div>
                              <div>
                                <label className="d-flex justify-content-start align-items-center">
                                  Show
                                  <select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" className="custom-select custom-select-sm form-control form-control-sm" value={entriesPerPage}
                                    onChange={(e) => {
                                      setEntriesPerPage(parseInt(e.target.value));
                                      setCurrentPage(1);
                                    }}>
                                    <option value={10}>10</option>
                                    <option value={25}>25</option>
                                    <option value={50}>50</option>
                                    <option value={100}>100</option>
                                  </select>
                                  entries
                                </label>
                              </div>
                            </div>
                            <div className="text-right modal-effect ">
                              <div id="DataTables_Table_0_filter" className="dataTables_filter">
                                <div className="d-flex justify-content-start align-items-center">
                                  <div className="mr-2">Search:</div>
                                  <input
                                    type="search"
                                    value={searchTerm}
                                    className="form-control form-control-sm"
                                    placeholder=""
                                    aria-controls="DataTables_Table_0"
                                    onChange={(e) => {
                                      setSearchTerm(e.target.value);
                                      // setCurrentPage(1);
                                    }}
                                  />
                                </div>

                              </div>
                            </div>
                          </div>


                          {fetchingLoading ? (
                                <p>Fetching accounts...</p>
                            ) : (
                            <div className="table-responsive">
                              <table className="table display table-bordered table-striped table-hover bg-white m-0 card-table">
      <thead style={{ whiteSpace: 'nowrap' }}>
        <tr>
          {/* <th>S/N</th> */}
          <th>ID</th>
          <th>Account Name</th>
          {/* <th>Type</th> */}
        </tr>
      </thead>
      <tbody style={{ whiteSpace: 'nowrap' }}>
        {/* {accounts.length > 0 && (
          <tr>
            <td colSpan="4" style={{ fontWeight: 'bold', textAlign: 'left' }}>INCOME</td>
          </tr>
        )} */}
        {accounts.map((item, index) => (
          <tr key={index}>
            <td>{index + 1}</td>
            <td>{item.gl_name}</td>
            {/* <td style={{ textAlign: "right" }}>{parseFloat(item.transaction?.amount).toLocaleString('en-US', {
                                    minimumIntegerDigits: 1,
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                })}</td> */}
            {/* <td>{item.direction === "2" ? 'CR' : 'DB'}</td> */}
          </tr>
        ))}
        
        {/* {accounts.length > 0 && inputss.length > 0 && (
          <tr>
            <td colSpan="4" style={{ fontWeight: 'bold', textAlign: 'left' }}>EXPENSES</td>
          </tr>
        )}
        {inputss.map((item, index) => (
          <tr key={index + accounts.length}>
            <td>{item.gl_code}</td>
            <td>{item.gl_name}</td>
            <td>{item.direction === "2" ? 'CR' : 'DB'}</td>
          </tr>
        ))} */}
      </tbody>
    </table>
                            </div>
                          )}
                          <div className={classes.endded}>
              <p>
                Showing {startIndexx} to {endIndexx} of {totalEntries} entries
              </p>
              <div style={{ display: 'flex' }}>
                <button
                  style={{ border: 'none', backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'nunito', color: '#000', marginRight: 10, cursor: "pointer" }}
                  onClick={handlePrevPage}
                  disabled={currentPage === 1}
                >
                  Previous
                </button>
                {[...Array(totalPages)].map((_, page) => {
                  // Show only 5 pages or less if available
                  if (page < 5 || page === currentPage - 1 || page === totalPages - 1) {
                    return (
                      <button
                        key={page + 1}
                        style={{
                          marginLeft: '0.4rem',
                          marginRight: '0.4rem',
                          fontSize: '14px',
                          fontFamily: 'nunito',
                          fontWeight: 400,
                          color: page + 1 === currentPage ? '#ffffff' : '#000',
                          backgroundColor: page + 1 === currentPage ? '#28a745' : 'gainsboro',
                          height: '2.5rem',
                          borderRadius: '89px',
                          padding: '0.5rem',
                          border: 'none',
                          width: '40px',
                          cursor: "pointer"
                        }}
                        onClick={() => setCurrentPage(page + 1)}
                      >
                        {page + 1}
                      </button>
                    );
                  }
                  return null;
                })}
                <button
                  style={{ cursor: "pointer", border: 'none', backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'nunito', color: '#000', marginLeft: 10 }}
                  onClick={handleNextPage}
                  disabled={currentPage === totalPages}
                >
                  Next
                </button>
              </div>
            </div>

                          

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!--/.body content--> */}
            </div>
          </div>
          {/* <!--/.main content--> */}
          </div>
          </div>
          {/* <InfoFooter /> */}
          {/* <!--/.footer content--> */}
          <div className="overlay"></div>
        </div>
        {/* <!--/.wrapper--> */}


      </div>
    </div>

  );
}

export default Schedule;