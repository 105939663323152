import classes from './AddTeamMember.module.css'
import Fullbullets from './Fullfeatures.svg';
import stuprcs from './hrtakant.svg';
import Backarrow from './Arrwbck.svg';
import { ProgressBar } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate, useLocation, Link } from 'react-router-dom';
import { Navbar, Nav, NavDropdown, Button, Modal, Form, Spinner, Badge } from 'react-bootstrap';
import CurrencyInput from 'react-currency-input-field';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Swal from 'sweetalert2';
import { BASE_URL } from '../api/api';
import { toast } from 'react-toastify';
import Select from 'react-select';
import Editimg from './Editimg.svg';
import Deleteimg from './Deleteimg.svg';
import Addimg from './Addimg.svg';
import Profilessss from './Profile.svg';



const initialState = () => {
    return [{
        accountName: '', accountCode: '', category: '', direction: '', openingBalance: '', date: ''
    }]
}

const ConfirmChart = () => {
    const [bearer, setBearer] = useState('');
    const [user, setUser] = useState('');
    const [permissions, setPermissions] = useState([]);
    const [Loading12, setSkipLoading12] = useState(false);
    const [admin, setAdmin] = useState("");
    const navigate = useNavigate();
    const [tableData, setTableData] = useState([]);
    const [roleLoading, setRoleLoading] = useState(false);

    const readData = async () => {
        try {
          const value = await AsyncStorage.getItem('userToken');
          const value1 = await AsyncStorage.getItem('tobi');
          const value2 = await AsyncStorage.getItem('permissions');
          const value3 = await AsyncStorage.getItem('admin');
    
          if (value !== null) {
            setBearer(value);
          }
          if (value1 !== null) {
            setUser(value1);
          }
          if (value2 !== null) {
            setPermissions(value2);
          }
          if (value3 !== null) {
            setAdmin(value3);
          }
        } catch (e) {
          alert('Failed to fetch the input from storage');
        }
      };
    
      useEffect(() => {
        readData();
      }, []);

      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${bearer}`
      };

      const fetchData = async () => {
        setRoleLoading(true);
        try {
          const response = await axios.get(`${BASE_URL}/users/fetch-all`, { headers });
          const results = response.data?.data;
          // const resultx = response.data?.data?.total;
          // setTotalEntries(resultx);
          setTableData(results);
          console.log(response);
          // const total = response.data?.data?.last_page || 1;
          // setTotalPages(total);
        } catch (error) {
          if (error.response && error.response.status === 401) {
            // Redirect to login page if unauthorized
            navigate('/login');
          } else {
            const errorStatus = error.response?.data?.message;
            console.log(errorStatus);
            setTableData([]);
          }
        } finally {
          setRoleLoading(false);
        }
      };

      useEffect(() => {
        if (bearer) {
          fetchData();
        }
      }, [bearer]);

      const handleSkip = async () => {
        setSkipLoading12(true);
        
        try {
          const response = await axios.post(
            `${BASE_URL}/authorize`,
                { usertype: 1 },
                {
                    headers: {
                      'Content-Type': 'application/json',
                      'Authorization': `Bearer ${bearer}`
                    },
                }
            );
            console.log('User switched:', response.data);

            Swal.fire({
              icon: 'success',
              title: 'Success',
              text: response.data.message,
          });

          navigate('/welcome');
        
          } catch (error) {
            let errorMessage = 'An error occurred. Please try again.';
            if (error.response && error.response.data && error.response.data.message) {
              if (typeof error.response.data.message === 'string') {
                  errorMessage = error.response.data.message;
              } else if (Array.isArray(error.response.data.message)) {
                  errorMessage = error.response.data.message.join('; ');
              } else if (typeof error.response.data.message === 'object') {
                  errorMessage = JSON.stringify(error.response.data.message);
              }
              Swal.fire({
                  icon: 'error',
                  title: 'Failed',
                  text: JSON.stringify(error.response.data.data),
              });
                toast.error(errorMessage);
                console.log(error);
              }
          } finally {
            setSkipLoading12(false);
          }
        };
      

    return (
        <div className={classes.maincont1234}>
            {/* <div className={classes.sideNavContainer}>
                <h4> Add your Team <br /> members seamlessly</h4>
                <p> Add as many team members as you want </p>
            </div> */}

            <div className={classes.formSection}>
                <div className={classes.textMain}>
                    <p>Complete your account setup</p>
                 {/* <Link to={'/TeamMember'} >   <div className={classes.normdsfdfalp}>
                        <img src={Backarrow} alt='icon' className={classes.Imageshowdfsf213} />
                        <p>Back</p>
                    </div></Link> */}
                </div>
                <div className={classes.normdfalp}>

                    <img src={Fullbullets} alt='icon' className={classes.Imageshowda213} />

                    <div className={classes.formSection2345d}>
                        <p style={{ textAlign: "right", paddingRight: "20px" }}>Skip</p>
                        {/* <img src={TopImage} alt='icon' className={classes.Imageshow213} /> */}
                        <div className={classes.formSection2345ds}>
                        <h4>Set up Chart of Accounts</h4>
                        <p>Add your chart of account</p>

                        <div className={classes.formSection23fa45ds}>
                                <h5> Do you have an existing chart of account</h5>
                        </div>

                        <Link to={'/UploadAccounts'} >  <Button disabled={roleLoading} variant="success" className={classes.snupbtn} >
                        Yes, I have an existing chart of account
                        </Button></Link>

                        <Link to={'/setup_chart_account'} >  <Button disabled={roleLoading} variant="success" className={classes.snupbtndfa} >
                            No, I do not have an existing chart of account
                        </Button></Link>
                        <Button disabled={roleLoading} variant="danger" className={classes.snupbtnreddfa} onClick={handleSkip}>
                        {Loading12 ? (
                                <>
                                    <Spinner size='sm' />
                                    <span style={{ marginLeft: '5px' }}>Processing, Please wait...</span>
                                </>
                                ) : (
                                "Skip"
                                )}
                        </Button>                    
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
};


export default ConfirmChart;