import React, { useState, useEffect } from 'react';
// import "../assets/plugins/bootstrap/css/bootstrap.min.css";
// import "../assets/plugins/metisMenu/metisMenu.min.css";
// import "../assets/plugins/fontawesome/css/all.min.css";
// import "../assets/plugins/typicons/src/typicons.min.css";
// import "../assets/plugins/themify-icons/themify-icons.min.css";
// import "../assets/plugins/datatables/dataTables.bootstrap4.min.css";
// import "../style.css";
import { NavLink, useNavigate } from 'react-router-dom';
import { Navbar, Nav, NavDropdown, Button, Modal, Form, Spinner, Badge } from 'react-bootstrap';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Swal from 'sweetalert2';
// import { InfoFooter } from '../../InfoFooter';
// import { AdminHeaderNav } from '../AdminHeaderNav';
import classes from '../Manage Members/ManageMember.module.css';
import MainDashboard from '../../Main Dashboard/MainDashoard';
import { BASE_URL } from '../../api/api';
import { toast } from 'react-toastify';
import Arrow from '../../../assets/promix/dArrow-down.svg'
import CoopDashboard from '../../Cooperative Dashboard/CoopDashboard';
import ReceivablesDash from '../../Receivables Dashboard/ReceivablesDash';
import Select from 'react-select';
import CurrencyInput from 'react-currency-input-field';
// import favicon from '../../Images/faviconn.png'

function UploadRepayment() {
  const navigate = useNavigate();
  const [selectedDate, setSelectedDate] = useState('');
  const [bearer, setBearer] = useState('');
  const [tableData, setTableData] = useState([]);
  const [tableData1, setTableData1] = useState([]);
  const [tableData2, setTableData2] = useState([]);
  const [postData, setPostData] = useState([]);
  const [benBank, setBenBank] = useState([]);
  const [formData, setFormData] = useState([{ sn: 1, beneficiary: '', beneficiaryAccount: '', debitGlCode: '', creditGlCode: '', amount: '', }]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectOptions, setSelectOptions] = useState([]);
  const [selectOptions1, setSelectOptions1] = useState([]);
  const [selectOptions2, setSelectOptions2] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [totalAmountCredit, setTotalAmountCredit] = useState('');
  const [totalAmountDebit, setTotalAmountDebit] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [iisLoading, setIisLoading] = useState(false);
  const [load, setLoad] = useState(false);
  const [loadd, setLoadd] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [selectedFile, setSelectedFile] = useState(null);
  const [user, setUser] = useState("");
  const [userType, setUserType] = useState("");
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [amountToPay, setAmountToPay] = useState('');
  // const [selectedLoan, setSelectedLoan] = useState('');

  const readData = async () => {
    try {
        const detail = await AsyncStorage.getItem('tobi');
        const details = await AsyncStorage.getItem('userToken');
        const detailss = await AsyncStorage.getItem('companyName');
        const admins = await AsyncStorage.getItem('admin');
        const continentName = await AsyncStorage.getItem('continent');
        const regionName = await AsyncStorage.getItem('region');
        const provinceName = await AsyncStorage.getItem('province');
        

        if (detail !== null) {
            // const firstName = detail.split(' ')[0];
            setUser(detail);
         
        }

        if (admins !== null) {
            setUserType(admins);
        }
        // if (continentName !== null) {
        //     setContinent(continentName);
        // }
        // if (regionName !== null) {
        //     setRegion(regionName);
        // }
        // if (provinceName !== null) {
        //     setProvince(provinceName);
        // }


        if (details !== null) {
            setBearer(details);
        }
        // if (detailss !== null) {
        //     setCompany(detailss);
        // }
    } catch (e) {
        alert('Failed to fetch the input from storage');
    }
};

  useEffect(() => {
    readData();
  }, []);


  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${bearer}`
  };

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
};

const uploadExcel = async () => {
  setUploadLoading(true);
  console.log("Amount:", amountToPay, "Selected File:", selectedFile[0], "selected customer", selectedCustomer)
  try {
    const formData = new FormData();
    formData.append('file', selectedFile[0]);
    formData.append('transaction_date', selectedDate);
    formData.append('amount', amountToPay,);
    formData.append('cusomer_id', selectedCustomer);


    // Debugging FormData
    for (let pair of formData.entries()) {
      console.log(pair[0] + ': ' + pair[1]);
    }

    const response = await axios.post(
      `${BASE_URL}/customer/upload-excel`, 
      formData, 
      { headers: { 'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${bearer}`
       } } // This line can be omitted to let Axios set it
    );

    handleClose();
    const postExcel = response.data?.data;
    setPostData(postExcel);

    console.log(response.data.message);
    toast.success(response.data.message);
  } catch (error) {
    let errorMessage = 'An error occurred. Please try again.';
    if (error.response) {
      console.log('Error Response:', error.response);
      if (error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
      }
      toast.error(errorMessage);
    } else {
      console.log('Error:', error);
    }
  } finally {
    setUploadLoading(false);
  }
};


  const handlePosting = async () => {
    setLoadd(true);
    try {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${bearer}`
      };
      // const uuids = postData.map((item) => item.uuid);
      
      

  const response = await axios.post(`${BASE_URL}/payment_voucher/post-bulk-journal-excel`,
   {
   uuid: postData[0].uuid
   },
    { headers }
  );

  setPostData([]);
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: response.data.message,
      });
  
    } catch (error) {
      const errorStatus = error.response.data.message;
  
      Swal.fire({
        icon: 'error',
        title: 'Failed',
        text: errorStatus,
      });
      // navigate('/pending_payment_voucher');
  
      console.error(error);
    } finally {
      setLoadd(false);
    }
  };
  const fetchBenAcct = async (selectedBeneficiary) => {
    setLoading(true);

    try {
        const response = await axios.get(`${BASE_URL}/beneficiaryaccounts/getaccount?beneficiary_id=${selectedBeneficiary}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${bearer}`,
                },
            }
        );

        const paid = response.data?.data || [];
        const benAcc = paid.map((item) => ({
          label: item.bank_name,
          value: item.id,
        }));
        // console.log(paid, 'paid');
        setBenBank(benAcc);
    } catch (error) {
        const errorStatus = error.response.data.message;
        console.error(errorStatus);
    } finally {
        setLoading(false);
    }
};
  
  
const handleFormChange = (selectedOption, fieldName, rowIndex) => {
  if (fieldName === 'beneficiary') {
    fetchBenAcct(selectedOption.value);
  }

  setFormData(prevFormData => {
    const updatedFormData = [...prevFormData];
    updatedFormData[rowIndex] = {
      ...updatedFormData[rowIndex],
      [fieldName]: selectedOption
    };
    return updatedFormData;
  });
};


  const deleteRow = (index) => {
    const updatedData = formData.filter((_, i) => i !== index);
    setFormData(updatedData);
  };

 



  // const headers = {
  //   'Content-Type': 'application/json',
  //   'Authorization': `Bearer ${bearer}`
  // };

  const fetchBeneficiaries = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/beneficiary`, { headers });
      const results = response.data?.data;

      const options = results.map((item) => ({
        label: item.name,
        value: item.id,
      }));

      setTableData(results);
      setSelectOptions(options);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        
        navigate('/login');
      } else {
      const errorStatus = error.response?.data?.message;
      console.log(errorStatus);
      setTableData([]);
    }
    } finally {
      setIsLoading(false);
    }
  };

  const fetchDebit = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/account`, { headers });
      const debitAcct = response.data?.data;

      const options1 = debitAcct.map((item) => ({
        label: item.gl_name,
        value: item.id,
      }));

      setTableData1(debitAcct);
      setSelectOptions1(options1);
    } catch (error) {
      const errorStatus = error.response?.data?.message;
      console.log(errorStatus);
      setTableData1([]);
    } finally {
      setIisLoading(false);
    }
  };

  const fetchCustomers = async () => {
    setIisLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/customer/no-pagination`, { headers });
      const results = response.data?.data;

      const options1 = results.map((item) => ({
        label: item.name,
        value: item.id,
        // interest: item.interest
      }));
      setCustomers(options1);
      // setSelectOptions(options);
    } catch (error) {
      const errorStatus = error.response?.data?.message;
      console.log(errorStatus);
      // setCustomers([]);
    } finally {
      setIisLoading(false);
    }
  };


  const fetchAsset = async () => {
    setLoading(true);

    try {
      const response = await axios.get(
        `https://api-sme.promixaccounting.com/api/v1/get-account-by-class-id?class_id=${1}`,
        {
         
          headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearer}`
          }
        }
      );
      const assetsAcc = response.data?.data;
      const options2 = assetsAcc.map((item) => ({
        label: item.gl_name,
        value: item.id,
      }));

      setTableData2(assetsAcc);
      setSelectOptions2(options2);

  
    } catch (error) {
      const errorStatus = error.response.data.message;
      console.error(errorStatus);
    } finally {
      setLoading(false);
    }
  };

 


  useEffect(() => {
    if (bearer) {
      fetchBeneficiaries();
      fetchDebit();
      fetchAsset();
      fetchCustomers();
    }
  }, [bearer]);




  const addRow = () => {
    const newRow = {
      sn: formData.length + 1,
      beneficiary: '', 
      beneficiaryAccount: '',
      debitGlCode: '', 
      creditGlCode: '', 
      amount: '',
    };
  
    setFormData(prevFormData => [...prevFormData, newRow]);
  };

   const handleValueChange1 = (value, index) => {
    const updatedFormData = [...formData];
    updatedFormData[index] = {
        ...updatedFormData[index],
        amount: value,
    };
    setFormData(updatedFormData);
};

const handleFileChange = (event) => {
  const files = event.target.files;
  const fileList = Array.from(files);
  setSelectedFile(fileList);
};

const handleValueChange = (value, name, values) => {
  setAmountToPay(value); // Update the balance state
  console.log(value, name, values);
};



const handleCustomerChnage = (selectedOption) => {
  setSelectedCustomer(selectedOption?.value);
}

  return (

    <div>

      <div className="wrapper">
        {/* <!-- Sidebar  --> */}


        {/* <!-- Page Content  --> */}
        <div className="content-wrapper">
          <div className="main-content">

          <ReceivablesDash />
            <div className='newBody'>
            <div className={classes.newWidth}>

            <div className={classes.topPadding}>
                    <div className={`${classes.formSecCont}`}>
                        <div className={classes.formSectionHeader}>
                            <h3>Upload Repayments</h3>
                            {/* <small>Create and view your loan accounts...</small> */}
                        </div>
                        <div className={classes.formSectionHeader}>
                            <h3 style={{color:'#2D995F', marginBottom:'0'}}>{user.toLocaleUpperCase()}</h3>
                            {/* <p style={{marginTop:'0'}}>{userType}</p> */}
                        </div>
                    </div>

                    {/* <div className={classes.analysis}>
                    <div className={classes.analysisCont}>
                        <p style={{paddingBottom:'5px'}}>TOTAL LOANS</p>
                        <h5>N0.00</h5>
                        
                    </div>
                    <div className={classes.analysisCont}>
                        <p style={{paddingBottom:'5px'}}>TOTAL LODGE</p>
                        <h5>N0.00</h5>
                        
                    </div>
                    <div className={classes.analysisCont}>
                        <p style={{paddingBottom:'5px'}}>TOTAL OUTSTANDING</p>
                        <h5>N0.00</h5>
                        
                    </div>
                </div> */}
            </div>

            <div style={{backgroundColor:'white', padding:'10px 20px'}}>
              {/* <!--Content Header (Page header)--> */}
              <div className="content-header row align-items-center m-0">
             
                {/* <nav aria-label="breadcrumb" style={{width:'100%', textAlign:'right'}}> */}
                  {/* <div
                    style={{
                      marginTop: 10,
                      marginBottom: 10,
                      justifyContent: "flex-end",
                      display: "flex",
                      marginLeft: "auto",
                    }}
                    className={classes.actionBtns}
                  >
                    <a href="https://api-sme.promixaccounting.com/api/v1/customer/download-excel" download>
                    <Button variant="secondary" style={{marginLeft: 10}}>
                      Download Excel Template
                    </Button>
                  </a>
                  </div> */}

                {/* </nav> */}
                <Modal show={show} onHide={handleClose} animation={false}>
                      <Modal.Header closeButton>
                        <Modal.Title>Upload Repayment</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>

                      <Form style={{ marginTop: 20 }}>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                          <Form.Label>Member:</Form.Label>
                          <Select
                            className={classes.select}
                            onChange={(selectedOption) => handleCustomerChnage(selectedOption)}
                            options={customers}
                            menuPortalTarget={document.body}
                            styles={{
                              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                              menu: (provided) => ({
                                ...provided,
                                maxHeight: '200px',
                                // overflowY: 'auto',
                              }),
                            }}
                          />
                          <div style={{ marginTop: 10 }}></div>
                          <Form.Label>Transaction Date</Form.Label>
                          <Form.Control
                            type="date"
                            value={selectedDate}
                            onChange={handleDateChange}
                            name="date"
                          />
                          <div style={{ marginTop: 10 }}></div>
                          <Form.Label>Amount To Pay:</Form.Label>
                          <CurrencyInput
                                name="principal amount"
                                decimalsLimit={2}
                                className="form-control"
                                value={amountToPay}
                                onValueChange={handleValueChange}
                                style={{ textAlign: "right", border: "1px solid #e4e4e4", backgroundColor: "none", width: '100%', }}
                              />
                                {/* </div> */}
                                <div style={{ marginTop: 10 }}></div>
                              <Form.Control
                                type="file"
                                accept=".xlsx, .xls, .csv"
                                onChange={handleFileChange}
                              />
                        </Form.Group>
                      </Form>
                    </Modal.Body>







                      <Modal.Footer>
                        <Button variant="danger" onClick={handleClose}>
                          Go back
                        </Button>
                        <Button variant="success" onClick={uploadExcel}>
                    {uploadLoading ? (
                      <>
                      <Spinner  size='sm' /> 
                      <span style={{ marginLeft: '5px' }}>Uploading, Please wait...</span>
    </>
  ) : (
                "Upload Payment"
                      )}
                    </Button>
                      </Modal.Footer>
                </Modal>

              
                {/* <div className="col-sm-8 header-title p-0">
                    <div className="media">
                      <div className="header-icon text-success mr-3"><i className=""><img src={favicon} className={classes.favshi} alt="favicon" /></i></div>
                      <div className="media-body">
                        <h4 className="font-weight-bold">Receivable Excel</h4>
                        <small>Upload your receivables in excel ...</small>
                      </div>
                    </div>
                  </div> */}
              </div>

              {/* <!--/.Content Header (Page header)--> */}
              <div className="body-content">
                
              <div className="row" style={{padding:'0'}}>
                  
                <div className="col-lg-12" style={{padding:5}}>
                    <div className="card" style={{border:'none'}}>
                      <div className="card-body" style={{padding:'0'}}>
                      <div className="table-resposive" style={{padding:'20px 10px'}}>
                        <Button className={classes.btnstylefrMobile} style={{borderRadius: 0}} variant="success" onClick={handleShow}>
                        Upload Excel Payment
                        </Button>

                        <a href="https://account-api.patna.ng/api/v1/customer/download-excel" download>
                          <Button variant="Primary" style={{marginLeft: 10, borderRadius:'0', backgroundColor:'blue', color:'white'}}>
                            Download Excel Template
                          </Button>
                        </a>
                      
                    </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!--/.body content--> */}
            </div>
          </div>
          {/* <!--/.main content--> */}
          </div>
          </div>
          {/* <InfoFooter /> */}
          {/* <!--/.footer content--> */}
          <div className="overlay"></div>
        </div>
        {/* <!--/.wrapper--> */}


      </div>
    </div>

  );
}

export default UploadRepayment;