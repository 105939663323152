import React, { useState, useEffect } from 'react';
import classes from './Receipt.module.css';
import logo from '../../assets/nw/logo.png';
import { Container, Card } from 'react-bootstrap';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import AsyncStorage from '@react-native-async-storage/async-storage';



const Receipt = () => {
    const [user, setUser] = useState("");
    const [bearer, setBearer] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const [company, setCompany] = useState('');
    const [phone, setPhone] = useState('');
    const [address, setAddress] = useState('');
    const [email, setEmail] = useState('');
    const [currentDateTime, setCurrentDateTime] = useState('');
  
    const readData = async () => {
        try {
          const value = await AsyncStorage.getItem('userToken');
          const value2 = await AsyncStorage.getItem('companyId');
          const value1 = await AsyncStorage.getItem('tobi');
          const value3 = await AsyncStorage.getItem('companyName');
          const value4 = await AsyncStorage.getItem('companyEmail');
          const value5 = await AsyncStorage.getItem('companyPhone');
    
          if (value !== null) {
            setBearer(value);
            // setAuthenticated(true);
          }
          if (value1 !== null) {
              setUser(value1);
            }
          if (value3 !== null) {
            setCompany(value3);
          }
          if (value4 !== null) {
            setEmail(value4);
          }
          if (value5 !== null) {
            setPhone(value5);
          }
    
        } catch (e) {
          alert('Failed to fetch the input from storage');
        }
      };
    
      useEffect(() => {
        readData();
      }, []);
  
      useEffect(() => {
        const addLeadingZero = (number) => (number < 10 ? `0${number}` : number);
      
        const currentDate = new Date();
        const day = currentDate.getDate();
        const month = addLeadingZero(currentDate.getMonth() + 1);
        const year = currentDate.getFullYear();
        const minutes = addLeadingZero(currentDate.getMinutes());
        const hours = addLeadingZero(currentDate.getHours() % 12 || 12);
        const seconds = addLeadingZero(currentDate.getSeconds());
        const ampm = currentDate.getHours() >= 12 ? 'PM' : 'AM';
      
        const formattedDateTime = `${day}/${month}/${year} ${hours}:${minutes}:${seconds} ${ampm}`;
      
        setCurrentDateTime(formattedDateTime);
      }, []);
      
      const { selectedInvoice } = location.state || {};
     
      console.log(selectedInvoice);
      const handlePrint = () => {
        window.print();
      };


      const calculateTotals = (items) => {
        let totalTax = 0;
        let totalDiscounts = 0;
        let initialAmount = 0;
      
        items.forEach(item => {
          const price = parseFloat(item.item.price);
          const quantity = parseFloat(item.item.quantity);
          const discount = parseFloat(item.discount);
          const taxRate = parseFloat(item.tax.rate);
          
          const itemTotal = price * quantity;
          console.log(itemTotal)
          const discountAmount = itemTotal * (discount / 100);
          const taxAmount = (itemTotal - discountAmount) * (taxRate / 100);
      
          totalTax += taxAmount;
          totalDiscounts += discountAmount;
          initialAmount += itemTotal;
        });
      
        return {
          totalTax: totalTax.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
          totalDiscounts: totalDiscounts.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
          initialAmount: initialAmount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
        };
      };
      
      const totals = selectedInvoice?.items ? calculateTotals(selectedInvoice.items) : { totalTax: '0.00', totalDiscounts: '0.00', initialAmount: '0.00' };


      const getSubtotal = (items) => {
        if (!Array.isArray(items) || items.length === 0) {
          return 0;
        }
      
        return items.reduce((total, item) => {
          const quantity = item.item?.quantity || 0;
          const price = parseFloat(item.item?.price) || 0;
          return total + (quantity * price);
        }, 0).toLocaleString('en-US', {
          minimumIntegerDigits: 1,
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      };
      
      
      const subtotal = getSubtotal(selectedInvoice?.salesinvoice?.items);

    return (
        <div className={classes.receipt}>
            <div className={classes.container}>
                <h3>CUSTOMER'S RECEIPT</h3>
                <div className={classes.logo}>
                <img src={logo} style={{ height: 70, width: 70 }} />
                </div>
            </div>
            <div className={classes.second}>
          
                <div className={classes.fifth}>
                    <h5 style={{fontSize: 15, fontWeight: 900, color: "#00000"}}>Receipt To:</h5>  
                    <h5 className={classes.truncate}>{selectedInvoice?.salesinvoice?.customer?.name}</h5>  
                    {/* <p style={{fontSize: 14, color: "#00000"}}>{selectedInvoice?.customer?.office_address}</p>
                    <p style={{fontSize: 14, color: "#00000"}}>{selectedInvoice?.customer?.email}</p> */}
                    <p style={{fontSize: 14, color: "#00000"}}>{selectedInvoice?.salesinvoice?.customer?.phone}</p>
                </div>
                <div className={classes.last}>
                    <div className={classes.third}>
                        <h5>Receipt No.: </h5>
                        <h5>Date: </h5>
                        {/* <h5>Amount:</h5>
                        <h5>Balance:</h5>
                        <h5>Payment Method:</h5> */}
                    </div>
                    <div className={classes.fourth}>
                        <h5 className={classes.textright22}>{selectedInvoice.invoice_number}</h5>
                        <h5 className={classes.textright22}>{selectedInvoice.transaction_date.toString()}</h5>
                        {/* <h5 className={classes.textright22}>{parseFloat(selectedInvoice.amount).toLocaleString('en-US', {
                                            minimumIntegerDigits: 1,
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                          })}</h5>
                        <h5 className={classes.textright22}>{parseFloat(selectedInvoice.balance).toLocaleString('en-US', {
                                            minimumIntegerDigits: 1,
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                          })}</h5>
                       
                        <h5>Cash</h5> */}
                    </div>
                </div>
            </div>
            <table className={classes.thTable}>
                        <thead>
                            <tr className={classes.tableHeadRow}>
                                <th>Descrption</th>
                                <th>Quantity</th>
                                <th>Unit Price</th>
                                <th>Discount</th>
                                <th>VAT</th>
                                <th style={{textAlign: "right"}}>Total</th>
                                
                                {/* <th>Incl. Total</th> */}
                            </tr>
                        </thead>
                        <tbody>
                        {Array.isArray(selectedInvoice?.salesinvoice?.items) && selectedInvoice?.salesinvoice?.items?.length > 0 ? (
  selectedInvoice?.salesinvoice?.items?.map((item, index) => (
    <tr key={index} className={classes.tablebodyCnt}>
      <td>{item.item?.name}</td>
      <td>{item.item?.quantity}</td>
      <td>{parseFloat(item.item?.price).toLocaleString('en-US', {
                                            minimumIntegerDigits: 1,
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                          })}</td>
      <td>{parseFloat(item.discount).toLocaleString('en-US', {
                                            minimumIntegerDigits: 1,
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                          })}</td>
      <td>{item.tax?.rate}</td>
   
      <td className={classes.boldtable}>{parseFloat(item.amount).toLocaleString('en-US', {
                                            minimumIntegerDigits: 1,
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                          })}</td>
    </tr>
  ))
) : (
  <tr>
    <td colSpan="6">No item found.</td>
  </tr>
)}

                        </tbody>


                    </table>
            <hr />
            <div className={classes.lastDiv}>
                <div className={classes.para}>
                    <p>Thank you {selectedInvoice?.customer?.name} for your patronage</p>
                </div>
                    <div className={classes.rightText}>
                        {/* <div className={classes.pair}>
                            <span className={classes.parag}>Total VAT: </span>
                            <span className={classes.paragg}>{totals.totalTax}</span>
                        </div> */}
                        {/* <div className={classes.pair}>
                            <span className={classes.parag}>Amount Received:</span>
                            <span className={classes.paragg}>#{parseFloat(selectedInvoice.amount).toLocaleString('en-US', {
                    })}</span>
                        </div> */}
                    </div>
            </div>
           
              <div className={classes.footerfirstdiv}>
                        <div className={classes.spantxt}><p>Amount Paid:</p> <p> {parseFloat(selectedInvoice?.amount).toLocaleString('en-US', {
                                            minimumIntegerDigits: 1,
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                          })}</p></div>
                        <div className={classes.spantxt}><p>Total VAT:</p> <p> {parseFloat(selectedInvoice?.salesinvoice?.total_vat).toLocaleString('en-US', {
                                            minimumIntegerDigits: 1,
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                          })}</p></div>
                        <div className={classes.spantxt}><p>Total Discount:</p> <p> {parseFloat(selectedInvoice?.salesinvoice?.total_discount).toLocaleString('en-US', {
                                            minimumIntegerDigits: 1,
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                          })}</p></div>
                        {/* <div className={classes.spantxt}><p>Total Exclusive:</p> <p> R105,000.00</p></div> */}
                        <div className={classes.spantxt}><p>Sub Total:</p> <p> {subtotal}</p></div>
                        <div className={classes.spantxt1}><p>Grand Total:</p> <p> {parseFloat(selectedInvoice?.salesinvoice?.amount).toLocaleString('en-US', {
                                            minimumIntegerDigits: 1,
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                          })}</p></div>
                        <p className={classes.balanceTxt}>BALANCE DUE</p>
                        <p className={classes.balanceTxtNum}>#{parseFloat(selectedInvoice?.salesinvoice?.balance).toLocaleString('en-US', {
                                            minimumIntegerDigits: 1,
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                          })}</p>
                    </div>
                    <div className={classes.toolbarhiddenprint}>
              <div className={classes.textend}>
                <button onClick={handlePrint} type="button" className="btn btn-success"><i className="fa fa-print" ></i> Print</button>
                {/* <button type="button" className="btn btn-danger"><i className="fa fa-file-pdf-o"></i> Export as PDF</button> */}
              </div>
            </div>
        </div>
    );
}

export default Receipt;