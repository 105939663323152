import React, { useState, useEffect } from 'react';
import classes from './ManageStocks.module.css';
import "../../assets/datatables/dataTables.bootstrap4.min.css";
import { Spinner, Badge, Button, Modal, Form, Alert } from 'react-bootstrap';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import MainDashoard from '../Main Dashboard/MainDashoard';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import Table from 'react-bootstrap/Table';
import { BASE_URL } from '../api/api';
import { toast, ToastContainer } from 'react-toastify';
import Swal from 'sweetalert2';
import LatestDash from '../LatestDashboard/LatestDash';
import Select from "react-select";
import CurrencyInput from 'react-currency-input-field';
import { ReactBarcode } from 'react-jsbarcode';
import Purchase from '../Inventory/Purchase';



export default function EditService() {
  const location = useLocation();
  const { selectedProducts } = location.state || {};
  const optionss = [
    { value: '', label: 'Select Type' },
    { value: '1', label: 'Services' },
    { value: '2', label: 'Products' }
  ];
  const getInitialLabel = (type) => {
    const selectedOption = optionss.find(option => option.value === selectedProducts?.type);
   
    return selectedOption ? selectedOption.value : '';
  };
 console.log(selectedProducts)
  const navigate = useNavigate();
  const [bearer, setBearer] = useState('');
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);
  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);
  const [user, setUser] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [roleLoading, setRoleLoading] = useState(false);
  const [createLoading, setCreateLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [tableData4, setTableData4] = useState([]);
  const [tableData23, setTableData23] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [admin, setAdmin] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedDisc, setSelectedDisc] = useState(null);
  const [selectedCost, setSelectedCost] = useState(null);
  const [unit, setUnit] = useState(null);
  const [roless, setRoless] = useState([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState('');
  const [selectedSales, setSelectedSales] = useState(null);
  const [selectedPurchase, setSelectedPurchase] = useState(null);
  const [name, setName] = useState(selectedProducts.name || '');
  const [description, setDescription] = useState(selectedProducts.description || '');
  const [tableData2, setTableData2] = useState([]);
  const [tableData3, setTableData3] = useState([]);
  const [incomes, setIncomes] = useState([]);
  const [expenses, setExpenses] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState('');
  const [receivables, setReceivables] = useState([]);
  const [payables, setPayables] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [price, setPrice] = useState(selectedProducts.price || '');
  const [price12, setPrice12] = useState(selectedProducts.cost_price || '');
  const [glCode, setglCode] = useState("");
  const [stockBalance, setStockBalance] = useState(selectedProducts.quantity || '');
  const [reOderLevel, setReOrderLevel] = useState(selectedProducts.re_order_level || '');
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [selectedPayable, setSelectedPayable] = useState(null);
  const [selectedAdvance, setSelectedAdvance] = useState(null);
  const [selectedType, setSelectedType] = useState(getInitialLabel(selectedProducts?.type || null));
  const [glname, setGlname] = useState("");
  const [balance, setBalance] = useState("");
  const [selectedDirection, setSelectedDirection] = useState("");
  const [selectedDate, setSelectedDate] = useState('');
  const [glcode, setGlcode] = useState("");



  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem('userToken');
      const value1 = await AsyncStorage.getItem('tobi');
      const value2 = await AsyncStorage.getItem('permissions');
      const value3 = await AsyncStorage.getItem('admin');

      if (value !== null) {
        setBearer(value);
      }
      if (value1 !== null) {
        setUser(value1);
      }
      if (value2 !== null) {
        setPermissions(value2);
      }
      if (value3 !== null) {
        setAdmin(value3);
      }
    } catch (e) {
      alert('Failed to fetch the input from storage');
    }
  };

  useEffect(() => {
    readData();
  }, []);

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${bearer}`
  };

  const handleDirectionChange = (e) => {
    setSelectedDirection(e.target.value);
  };

  const handleValueChange = (value, name, values) => {
    setBalance(value); // Update the balance state
    console.log(value, name, values);
  };

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  const handleSubCatChange = (event) => {
    setSelectedSubCategory(event.target.value);
  };

  const handleSingleImageChange = (event) => {
    const file = event.target.files[0];
    setSelectedImage(file);
  };

  // const fetchData = async () => {
  //   setRoleLoading(true);
  //   try {
  //     const response = await axios.get(`${BASE_URL}/items/fetch-all`, { headers });
  //     const results = response.data?.data;
  //     // console.log(results);
  //     setTableData(results);
  //   } catch (error) {
  //     if (error.response && error.response.status === 401) {
  //       // Redirect to login page if unauthorized
  //       navigate('/login');
  //     } else {
  //       const errorStatus = error.response?.data?.message;
  //       console.log(errorStatus);
  //       setTableData([]);
  //     }
  //   } finally {
  //     setRoleLoading(false);
  //   }
  // };


  const createAccount = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${BASE_URL}/account/add`,
        {
          gl_name: glname,
          opening_balance: balance,
          // direction: selectedDirection,
          transaction_date: selectedDate,
          sub_category_id: selectedSubCategory,

        },
        { headers }
      );
      console.log(response)

      handleClose();
      fetchIncomes();
      setGlcode('');
      setGlname('');
      setBalance('');
      setSelectedSubCategory('');
      setSelectedDate('');
      // return
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: response.data.message,
      });
      console.log(response.data);

    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        Swal.fire({
          icon: 'error',
          title: 'Failed',
          text: JSON.stringify(error.response.data.message),
        });
        console.log(error);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleImageChange = (event) => {
    const files = Array.from(event.target.files); // Convert FileList to an array
    setSelectedImages(files);
  };

  const createAccount1 = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${BASE_URL}/account/add`,
        {
          gl_name: glname,
          opening_balance: balance,
          // direction: selectedDirection,
          transaction_date: selectedDate,
          sub_category_id: selectedSubCategory,

        },
        { headers }
      );
      console.log(response)

      fetchExpenses();
      handleClose1();
      setGlcode('');
      setGlname('');
      setBalance('');
      setSelectedSubCategory('');
      setSelectedDate('');
      // return
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: response.data.message,
      });
      console.log(response.data);

    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        Swal.fire({
          icon: 'error',
          title: 'Failed',
          text: JSON.stringify(error.response.data.message),
        });
        console.log(error);
      }
    } finally {
      setLoading(false);
    }
  };

  const createAccount2 = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${BASE_URL}/account/add`,
        {
          gl_name: glname,
          opening_balance: balance,
          // direction: selectedDirection,
          transaction_date: selectedDate,
          sub_category_id: selectedSubCategory,

        },
        { headers }
      );
      console.log(response)
      fetchReceivables();
      handleClose2();
      setGlcode('');
      setGlname('');
      setBalance('');
      setSelectedSubCategory('');
      setSelectedDate('');
      // return
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: response.data.message,
      });
      console.log(response.data);

    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        Swal.fire({
          icon: 'error',
          title: 'Failed',
          text: JSON.stringify(error.response.data.message),
        });
        console.log(error);
      }
    } finally {
      setLoading(false);
    }
  };

  const fetchData11 = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/get-sub-categories`, { headers });
      const subCat = response.data?.data;
      setTableData23(subCat);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
        const errorStatus = error.response?.data?.message;
        console.log(errorStatus);
        setTableData23([]);
      }
    } finally {
      setIsLoading(false);
    }
  };


  const createAccount3 = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${BASE_URL}/account/add`,
        {
          gl_name: glname,
          opening_balance: balance,
          // direction: selectedDirection,
          transaction_date: selectedDate,
          sub_category_id: selectedSubCategory,

        },
        { headers }
      );
      console.log(response)
      fetchPayables();
      handleClose3();
      setGlcode('');
      setGlname('');
      setBalance('');
      setSelectedSubCategory('');
      setSelectedDate('');
      // return
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: response.data.message,
      });
      console.log(response.data);

    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        Swal.fire({
          icon: 'error',
          title: 'Failed',
          text: JSON.stringify(error.response.data.message),
        });
        console.log(error);
      }
    } finally {
      setLoading(false);
    }
  };

  const fetchData1 = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/units/fetch-all`, { headers });
      const roleList = response.data?.data;
      // console.log(results);
      const options = roleList.map(item => ({
        label: item.name,
        value: item.id
      }));
      setRoless(options);
      if (selectedProducts && selectedProducts.measurement) {
        const slctedUnit = options.find(item => item.value === selectedProducts.measurement.id);
        console.log(options)
        setUnit(slctedUnit);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
        const errorStatus = error.response?.data?.message;
        console.log(errorStatus);
        setTableData([]);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const fetchData2 = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/product-categories`, { headers });
      const ts = response.data?.data;
      console.log(ts);

      const options1 = ts.map(item => ({
        label: item.description,
        value: item.id
      }));
      // console.log(results);
      setTableData3(options1);
      if (selectedProducts && selectedProducts) {
        const slctedCat = options1.find(item => item.value === selectedProducts.product_categories?.id);
        console.log(options1)
        setSelectedCategory(slctedCat);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
        const errorStatus = error.response?.data?.message;
        console.log(errorStatus);
        setTableData3([]);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const fetchGl = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/account`, { headers });
      const rsclt = response.data?.data;

      const cost = rsclt.map(item => ({
        label: item.gl_name,
        value: item.id
      }));
    
   

    
      setTableData2(cost);

      // if (selectedProducts && selectedProducts) {
      //   const slctedCost = cost.find(item => item.value === selectedProducts.cost_of_good_gl.id);
      //   setSelectedCost(slctedCost);
      // }
      
     

      
      console.log(rsclt);
    } catch (error) {
      const errorStatus = error.response?.data?.message;
      console.log(errorStatus);
      setTableData2([]);
    } finally {
      setIsLoading(false);
    }
  };
  const fetchDiscount = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/account`, { headers });
      const rslt = response.data?.data;

      const disc = rslt.map(item => ({
        label: item.gl_name,
        value: item.id
      }));

    
      setTableData4(disc);

  
      // if (selectedProducts && selectedProducts) {
      //   const slctedDisc = disc.find(item => item.value === selectedProducts.discount_gl.id);
      //   setSelectedDisc(slctedDisc);
      // }
     

      
      console.log(rslt);
    } catch (error) {
      const errorStatus = error.response?.data?.message;
      console.log(errorStatus);
      setTableData4([]);
    } finally {
      setIsLoading(false);
    }
  };



  useEffect(() => {
    if (bearer) {
      // fetchData();
      fetchData1();
      fetchData2();
      fetchGl();
      fetchData11();
      fetchDiscount();
    }
  }, [bearer]);

  console.log(selectedType)



  const createUser = async () => {
    setCreateLoading(true);
    try {
      const formDatas = new FormData();
      const removeFormatting = (formattedValue) => {
        const valueWithoutCommas = formattedValue.replace(/,/g, '');
        const valueAsNumber = parseFloat(valueWithoutCommas);
        return valueAsNumber;
      };

      formDatas.append('name', name);
      formDatas.append('description', description);
      formDatas.append('unit', unit?.value || '');
      formDatas.append('gl_code', glCode);
      formDatas.append('type', selectedType?.value || '');
      formDatas.append('re_order_level', reOderLevel || '');
      formDatas.append('price', removeFormatting(price) || '');
      formDatas.append('cost_price', removeFormatting(price12) || '');
      formDatas.append('quantity', stockBalance);
      formDatas.append('sales_gl', selectedSales?.value || '');
      formDatas.append('payable_gl', selectedPayable?.value || '');
      formDatas.append('purchase_gl', selectedPurchase?.value || '');
      formDatas.append('account_receivable', selectedAccount?.value || '');
      formDatas.append('category_id', selectedCategory?.value || '');
      formDatas.append('advance_payment_gl', selectedAdvance?.value || '');
      formDatas.append('image', selectedImages);
      formDatas.append('display_image', selectedImage);
      formDatas.append('cost_of_good_gl', selectedCost?.value || '');
      formDatas.append('discount_gl', selectedDisc?.value || '');
      formDatas.append('id', selectedProducts.id || '');
      
      console.log(selectedSales, selectedPayable, selectedPurchase, selectedAccount, selectedAdvance, selectedCost, selectedDisc);

      const response = await axios.post(
        `${BASE_URL}/items/update-item`,
       formDatas,
       {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${bearer}`
        }
      }
      );
      console.log(response)
      // fetchData();
      // handleClose();

      // return
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: response.data.message,
      })
    navigate(-1);


      console.log(response.data);

    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        Swal.fire({
          icon: 'error',
          title: 'Failed',
          text: JSON.stringify(error.response.data.message),
        });
        // toast.error(JSON.stringify(error.response.data.data));
        // console.log(e);
      }
    } finally {
      setCreateLoading(false);
    }
  };







  // UseEffect to log changes to selectedItem
  useEffect(() => {
    // console.log(selectedItem, "selectedItem changed");
  }, [selectedItem]);





  const formatPrice = (value) => {
    let formattedValue = parseFloat(value.replace(/,/g, ''));
    if (isNaN(formattedValue)) return;

    formattedValue = formattedValue.toFixed(2);
    const parts = formattedValue.split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    const finalValue = parts.join('.');
    setPrice(finalValue);
  };

  const formatPrice12 = (value) => {
    let formattedValue = parseFloat(value.replace(/,/g, ''));
    if (isNaN(formattedValue)) return;

    formattedValue = formattedValue.toFixed(2);
    const parts = formattedValue.split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    const finalValue = parts.join('.');
    setPrice12(finalValue);
  };

  // const options = roless.map(item => ({
  //   label: item.name,
  //   value: item.id
  // }));

  // const options1 = tableData3.map(item => ({
  //   label: item.description,
  //   value: item.id
  // }));

  const fetchIncomes = async () => {
    setLoading(true);


    try {
      const response = await axios.get(
        `${BASE_URL}/get-account-by-class-id?class_id=${4}`,
        {

          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearer}`
          }
        }
      );
      const resultss = response.data?.data;

      const salesOptions = resultss.map(item => ({
        label: item.gl_name,
        value: item.id
      }));
      setIncomes(salesOptions);
      if (selectedProducts && selectedProducts) {
        const slctedSales = salesOptions.find(item => item.value === selectedProducts.sales.id);
        console.log(resultss);
        setSelectedSales(slctedSales);
      }

      //   console.log(results, "NI");
    } catch (error) {
      const errorStatus = error.response.data.message;
      console.error(errorStatus);
    } finally {
      setLoading(false);
    }
  };

  const fetchExpenses = async () => {
    setLoading(true);


    try {
      const response = await axios.get(
        `${BASE_URL}/get-account-by-class-id?class_id=${5}`,
        {

          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearer}`
          }
        }
      );
      const resultss = response.data?.data;

      const purchaseOptions = resultss.map(item => ({
        label: item.gl_name,
        value: item.id
      }));
      setExpenses(purchaseOptions);
      if (selectedProducts && selectedProducts) {
        const slctedPurchase = purchaseOptions.find(item => item.value === selectedProducts.purchase_gl.id);
        setSelectedPurchase(slctedPurchase);
      }

      //   console.log(results, "NI");
    } catch (error) {
      const errorStatus = error.response.data.message;
      console.error(errorStatus);
    } finally {
      setLoading(false);
    }
  };

  const fetchReceivables = async () => {
    setLoading(true);


    try {
      const response = await axios.get(
        `${BASE_URL}/get-account-by-category-id?category_id=${1}`,
        {

          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearer}`
          }
        }
      );
      const resultss = response.data?.data;

      const receivablesOptions = resultss.map(item => ({
        label: item.gl_name,
        value: item.id
      }));
      setReceivables(receivablesOptions);
      if (selectedProducts && selectedProducts) {
        const slctedReceivables = receivablesOptions.find(item => item.value === selectedProducts.account_receivables.id);
        setSelectedAccount(slctedReceivables);
      }

      //   console.log(results, "NI");
    } catch (error) {
      const errorStatus = error.response.data.message;
      console.error(errorStatus);
    } finally {
      setLoading(false);
    }
  };

  const fetchPayables = async () => {
    setLoading(true);


    try {
      const response = await axios.get(
        `${BASE_URL}/get-account-by-category-id?category_id=${3}`,
        {

          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearer}`
          }
        }
      );
      const resultss = response.data?.data;

      const payablesOptions = resultss.map(item => ({
        label: item.gl_name,
        value: item.id
      }));
      setPayables(payablesOptions);
      if (selectedProducts && selectedProducts) {
        const slctedPayabls = payablesOptions.find(item => item.value === selectedProducts.account_payables.id);
        setSelectedPayable(slctedPayabls);
      }

      if (selectedProducts && selectedProducts) {
        const slctedPayments = payablesOptions.find(item => item.value === selectedProducts.advance_payments.id);
        setSelectedAdvance(slctedPayments);
      }

      //   console.log(results, "NI");
    } catch (error) {
      const errorStatus = error.response.data.message;
      console.error(errorStatus);
    } finally {
      setLoading(false);
    }
  };






  

  


  // const advanceOptions = payables.map(item => ({
  //   label: item.gl_name,
  //   value: item.id
  // }));


  useEffect(() => {
    if (bearer) {
      fetchIncomes();
      fetchExpenses();
      fetchReceivables();
      fetchPayables();
    }
  }, [bearer]);

  // const extendedSales = [
  //   { label: '(Add a new sales account)', value: 'add-new' },
  //   ...salesOptions
  // ];

  // const extendedCost = [
  //   { label: '(Add a new sales account)', value: 'add-new' },
  //   ...cost
  // ];
  // const extendedPurchase = [
  //   { label: '(Add a new purchase account)', value: 'add-new' },
  //   ...purchaseOptions
  // ];
  // const extendedDisc = [
  //   { label: '(Add a new purchase account)', value: 'add-new' },
  //   ...disc
  // ];
  // const extendedReceivables = [
  //   { label: '(Add a new receivables account)', value: 'add-new' },
  //   ...receivablesOptions
  // ];
  // const extendedPayables = [
  //   { label: '(Add a new payables account)', value: 'add-new' },
  //   ...payablesOptions
  // ];
  // const extendedAdvanced = [
  //   { label: '(Add a new advanced payment account)', value: 'add-new' },
  //   ...advanceOptions
  // ];

  const handleSales = (selectedOption) => {
    if (selectedOption.value === 'add-new') {
      handleShow();
    } else {
      setSelectedSales(selectedOption.value);
    }
  };
  const handleCost = (selectedOption) => {
    if (selectedOption.value === 'add-new') {
      handleShow();
    } else {
      setSelectedCost(selectedOption.value);
    }
  };
  const handleDisc = (selectedOption) => {
    if (selectedOption.value === 'add-new') {
      handleShow();
    } else {
      setSelectedDisc(selectedOption.value);
    }
  };

  const handlePurchase = (selectedOption) => {
    if (selectedOption.value === 'add-new') {
      handleShow1();
    } else {
      setSelectedPurchase(selectedOption.value);
    }
  };
  const handleReceivables = (selectedOption) => {
    if (selectedOption.value === 'add-new') {
      handleShow2();
    } else {
      setSelectedAccount(selectedOption.value);
    }
  };
  const handlePayables = (selectedOption) => {
    if (selectedOption.value === 'add-new') {
      handleShow3();
    } else {
      setSelectedPayable(selectedOption.value);
    }
  };
  const handleAdvance = (selectedOption) => {
    if (selectedOption.value === 'add-new') {
      handleShow3();
    } else {
      setSelectedAdvance(selectedOption.value);
    }
  };



  return (
    <div>
      <LatestDash />
      <ToastContainer />
      <div className={classes.finishedbodyCont}>

        <Modal show={show} onHide={handleClose} animation={false}>
          <Modal.Header closeButton>
            <Modal.Title>Create Sales Account</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form >
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label style={{ marginTop: 20 }}>Gl Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Gl Name"
                  value={glname}
                  onChange={(e) => setGlname(e.target.value)}
                />

                <Form.Label style={{ marginTop: 20 }}>Account Type</Form.Label>
                <Form.Select className="form-control"
                  as="select"
                  value={selectedSubCategory}
                  onChange={handleSubCatChange}
                >
                  <option value="" disabled>Select Type</option>
                  {tableData23.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.description}
                    </option>
                  ))}
                </Form.Select>

                {/* <Form.Label style={{ marginTop: 20 }}>Direction</Form.Label>
                        <Form.Select className="form-control"
                          as="select"
                          value={selectedDirection}
                          onChange={handleDirectionChange}
                        >
                          <option value="" disabled>Select Direction</option>
                          <option value="1" >Debit</option>
                          <option value="2" >Credit</option>

                        </Form.Select> */}

                {/* <Form.Label style={{ marginTop: 20 }}>Opening Balance</Form.Label> <br />

                        <CurrencyInput
                          id="exampleForm.ControlInput1"
                          name="Balance"
                          placeholder="Enter Opening balance"
                          decimalsLimit={2}
                          value={balance} // Set the value to the balance state
                          onValueChange={handleValueChange}
                          style={{

                            minWidth: "100%",
                            height: "calc(1.8em + .75rem + 2px)",
                            border: '1px solid #e4e5e7',
                            borderRadius: 5,
                            overflow: 'hidden',
                            zIndex: 999,
                            fontSize: 14,
                            padding: ".375rem .75rem"
                          }}
                        /> */}
                <Form.Label style={{ marginTop: 20 }}>Balance as at</Form.Label>

                <input
                  className="form-control"
                  required=""
                  type="date"
                  onChange={handleDateChange}
                  name="date"
                  value={selectedDate}
                />


              </Form.Group>
            </Form>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="danger" onClick={handleClose}>
              Go back
            </Button>
            <Button variant="success" onClick={createAccount}>
              {loading ? (
                <>
                  <Spinner size='sm' />
                  <span style={{ marginLeft: '5px' }}>Creating account, Please wait...</span>
                </>
              ) : (
                "Create"
              )}
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={show1} onHide={handleClose1} animation={false}>
          <Modal.Header closeButton>
            <Modal.Title>Create Purchase Account</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form >
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label style={{ marginTop: 20 }}>Gl Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Gl Name"
                  value={glname}
                  onChange={(e) => setGlname(e.target.value)}
                />

                <Form.Label style={{ marginTop: 20 }}>Account Type</Form.Label>
                <Form.Select className="form-control"
                  as="select"
                  value={selectedSubCategory}
                  onChange={handleSubCatChange}
                >
                  <option value="" disabled>Select Type</option>
                  {tableData23.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.description}
                    </option>
                  ))}
                </Form.Select>

                {/* <Form.Label style={{ marginTop: 20 }}>Direction</Form.Label>
                        <Form.Select className="form-control"
                          as="select"
                          value={selectedDirection}
                          onChange={handleDirectionChange}
                        >
                          <option value="" disabled>Select Direction</option>
                          <option value="1" >Debit</option>
                          <option value="2" >Credit</option>

                        </Form.Select> */}

                {/* <Form.Label style={{ marginTop: 20 }}>Opening Balance</Form.Label> <br />

                        <CurrencyInput
                          id="exampleForm.ControlInput1"
                          name="Balance"
                          placeholder="Enter Opening balance"
                          decimalsLimit={2}
                          value={balance} // Set the value to the balance state
                          onValueChange={handleValueChange}
                          style={{

                            minWidth: "100%",
                            height: "calc(1.8em + .75rem + 2px)",
                            border: '1px solid #e4e5e7',
                            borderRadius: 5,
                            overflow: 'hidden',
                            zIndex: 999,
                            fontSize: 14,
                            padding: ".375rem .75rem"
                          }}
                        /> */}
                <Form.Label style={{ marginTop: 20 }}>Balance as at</Form.Label>

                <input
                  className="form-control"
                  required=""
                  type="date"
                  onChange={handleDateChange}
                  name="date"
                  value={selectedDate}
                />


              </Form.Group>
            </Form>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="danger" onClick={handleClose1}>
              Go back
            </Button>
            <Button variant="success" onClick={createAccount1}>
              {loading ? (
                <>
                  <Spinner size='sm' />
                  <span style={{ marginLeft: '5px' }}>Creating account, Please wait...</span>
                </>
              ) : (
                "Create"
              )}
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={show2} onHide={handleClose2} animation={false}>
          <Modal.Header closeButton>
            <Modal.Title>Create A/c Receivables Account</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form >
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label style={{ marginTop: 20 }}>Gl Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Gl Name"
                  value={glname}
                  onChange={(e) => setGlname(e.target.value)}
                />

                <Form.Label style={{ marginTop: 20 }}>Account Type</Form.Label>
                <Form.Select className="form-control"
                  as="select"
                  value={selectedSubCategory}
                  onChange={handleSubCatChange}
                >
                  <option value="" disabled>Select Type</option>
                  {tableData23.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.description}
                    </option>
                  ))}
                </Form.Select>

                {/* <Form.Label style={{ marginTop: 20 }}>Direction</Form.Label>
                        <Form.Select className="form-control"
                          as="select"
                          value={selectedDirection}
                          onChange={handleDirectionChange}
                        >
                          <option value="" disabled>Select Direction</option>
                          <option value="1" >Debit</option>
                          <option value="2" >Credit</option>

                        </Form.Select> */}

                {/* <Form.Label style={{ marginTop: 20 }}>Opening Balance</Form.Label> <br />

                        <CurrencyInput
                          id="exampleForm.ControlInput1"
                          name="Balance"
                          placeholder="Enter Opening balance"
                          decimalsLimit={2}
                          value={balance} // Set the value to the balance state
                          onValueChange={handleValueChange}
                          style={{

                            minWidth: "100%",
                            height: "calc(1.8em + .75rem + 2px)",
                            border: '1px solid #e4e5e7',
                            borderRadius: 5,
                            overflow: 'hidden',
                            zIndex: 999,
                            fontSize: 14,
                            padding: ".375rem .75rem"
                          }}
                        /> */}
                <Form.Label style={{ marginTop: 20 }}>Balance as at</Form.Label>

                <input
                  className="form-control"
                  required=""
                  type="date"
                  onChange={handleDateChange}
                  name="date"
                  value={selectedDate}
                />


              </Form.Group>
            </Form>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="danger" onClick={handleClose2}>
              Go back
            </Button>
            <Button variant="success" onClick={createAccount2}>
              {loading ? (
                <>
                  <Spinner size='sm' />
                  <span style={{ marginLeft: '5px' }}>Creating account, Please wait...</span>
                </>
              ) : (
                "Create"
              )}
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={show3} onHide={handleClose3} animation={false}>
          <Modal.Header closeButton>
            <Modal.Title>Create Account</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form >
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label style={{ marginTop: 20 }}>Gl Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Gl Name"
                  value={glname}
                  onChange={(e) => setGlname(e.target.value)}
                />

                <Form.Label style={{ marginTop: 20 }}>Account Type</Form.Label>
                <Form.Select className="form-control"
                  as="select"
                  value={selectedSubCategory}
                  onChange={handleSubCatChange}
                >
                  <option value="" disabled>Select Type</option>
                  {tableData23.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.description}
                    </option>
                  ))}
                </Form.Select>

                {/* <Form.Label style={{ marginTop: 20 }}>Direction</Form.Label>
                        <Form.Select className="form-control"
                          as="select"
                          value={selectedDirection}
                          onChange={handleDirectionChange}
                        >
                          <option value="" disabled>Select Direction</option>
                          <option value="1" >Debit</option>
                          <option value="2" >Credit</option>

                        </Form.Select> */}

                {/* <Form.Label style={{ marginTop: 20 }}>Opening Balance</Form.Label> <br />

                        <CurrencyInput
                          id="exampleForm.ControlInput1"
                          name="Balance"
                          placeholder="Enter Opening balance"
                          decimalsLimit={2}
                          value={balance} // Set the value to the balance state
                          onValueChange={handleValueChange}
                          style={{

                            minWidth: "100%",
                            height: "calc(1.8em + .75rem + 2px)",
                            border: '1px solid #e4e5e7',
                            borderRadius: 5,
                            overflow: 'hidden',
                            zIndex: 999,
                            fontSize: 14,
                            padding: ".375rem .75rem"
                          }}
                        /> */}
                <Form.Label style={{ marginTop: 20 }}>Balance as at</Form.Label>

                <input
                  className="form-control"
                  required=""
                  type="date"
                  onChange={handleDateChange}
                  name="date"
                  value={selectedDate}
                />


              </Form.Group>
            </Form>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="danger" onClick={handleClose3}>
              Go back
            </Button>
            <Button variant="success" onClick={createAccount3}>
              {loading ? (
                <>
                  <Spinner size='sm' />
                  <span style={{ marginLeft: '5px' }}>Creating account, Please wait...</span>
                </>
              ) : (
                "Create"
              )}
            </Button>
          </Modal.Footer>
        </Modal>

        <h3 style={{ fontSize: '30px', fontWeight: 700, margin: " 10px 10px 0  10px" }}>Update Services</h3>
        <p style={{ margin: " 0 10px 20px 10px" }}>Kindly fill in the below with the correct details</p>

        <div className={classes.mainform}>

          <div className={classes.loandgrantcards}>

            {/* <h3 className={classes.productText}>Add Products</h3> */}
            <div className={classes.formLabel}>
            </div>
            <div className={classes.loandethead}>
              <div className={classes.formIntBtn}>
              </div>
            </div>

            <div className="d-flex justify-content-left">

              <Form style={{ marginTop: 20, width: '70%' }}>
                {/* <div className="row"> */}
                {/* <div className="col-md-6"> */}
               


                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                {/* <div style={{height: 150, width: 150, borderRadius: 8, border: "1px solid #c4c4c4", marginBottom: 30, marginLeft: 90}}>
  {selectedProducts?.image ? (
    <img
      src={selectedProducts.image}
      alt="Product"
      className={classes.productImage}
    />
  ) : (
    <span style={{textAlign: "center"}}>No display image</span>
  )}
</div> */}
                  {/* <div className={`d-flex align-items-left form-group-custom ${classes.align1234}`}>
                    <Form.Label className={classes.formLabelCustom} >Type</Form.Label>
                    <div className={classes.formControl}>
                      <Select loading={isLoading} options={optionss}
                        placeholder="Select Type" onChange={setSelectedType}
                        value={optionss.find(option => option.value === selectedType)}
                      />
                    </div>
                  </div> */}
                </Form.Group>

                <Form.Group className={`mb-3 ${classes.removeMarginadf}`}>
                  <div className={`d-flex align-items-left form-group-custom ${classes.align1234}`}>
                    <Form.Label className={classes.formLabelCustom}>Name</Form.Label>
                    <Form.Control
                      className={classes.formControl}
                      type="text"
                      // placeholder="Enter Product's Name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                </Form.Group>
                {/* </div> */}
                {/* <div className="col-md-6"> */}
                <Form.Group className={`mb-3 ${classes.removeMargin}`}>
                  <div className={`d-flex align-items-left form-group-custom ${classes.align1234}`}>
                    <Form.Label className={classes.formLabelCustom}>Description</Form.Label>
                    <Form.Control
                      className={classes.formControl}
                      type="text"
                      // placeholder="Enter Description of the Product"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </div>
                </Form.Group>
                {/* </div>
                </div> */}


                <div style={{ marginTop: -10 }} />
                {/* <div className="row">
                  <div className="col-md-6">
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <div className={`d-flex align-items-left form-group-custom ${classes.align1234}`}>
                        <Form.Label className={classes.formLabelCustomsdf} >Purchase Price</Form.Label>
                        <Form.Control
                          className={classes.formControl + ' ' + classes.alignRight}
                          type="text"
                          placeholder="0.00"
                          value={price}
                          onChange={(e) => setPrice(e.target.value)}
                          onBlur={(e) => formatPrice(e.target.value)}
                          style={{ width: "230px" }}
                        />
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <div className={`d-flex align-items-left form-group-custom ${classes.align1234}`}>
                        <Form.Label className={classes.formLabeldadCustom}>Sale Price</Form.Label>
                        <Form.Control
                          className={classes.form124Control + ' ' + classes.alignRight}
                          type="text"
                          placeholder="0.00"
                          value={price12}
                          onChange={(e) => setPrice12(e.target.value)}
                          onBlur={(e) => formatPrice12(e.target.value)}
                        />
                      </div>
                    </Form.Group>
                  </div>
                </div> */}

                {/* <Form.Group className={`mb-3 ${classes.removeMargin}`}>
                      <div className={`d-flex align-items-left form-group-custom ${classes.align1234}`}>
                        <Form.Label className={classes.formLabelCustom}>Unit Price</Form.Label>
                        <Form.Control
                        className={classes.formControl + ' ' + classes.alignRight}
                          type="text"
                          placeholder="0.00"
                          value={price}
                          onChange={(e) => setPrice(e.target.value)}
                          onBlur={(e) => formatPrice(e.target.value)}
                        />
                      </div>
                    </Form.Group> */}
                <div style={{ marginTop: -10 }} />

                {/* {selectedType?.value !== '1' && (
                  <> */}
                    {/* <div className="row">
                      <div className="col-md-6">
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                          <div className={`d-flex align-items-left form-group-custom ${classes.align1234}`}>
                            <Form.Label className={classes.formLabelCustom} style={{ marginLeft: 30 }}>UOM</Form.Label>
                            <div className={classes.formControl}>
                              <Select loading={isLoading} options={roless} dropdownHeight='100px'
                                placeholder="Select UOM" onChange={setUnit} value={unit}
                                style={{ width: "190px" }}

                              />
                            </div>
                          </div>
                        </Form.Group>
                      </div>
                      <div className="col-md-6" >
                        <Form.Group className="mb-3">
                          <div className={`d-flex align-items-left form-group-custom ${classes.align1234}`}>
                            <Form.Label className={classes.formLabelCustom} >Category</Form.Label>
                            <div style={{ width: '100%' }}>
                              <Select loading={isLoading} dropdownHeight='100px' options={tableData3}
                                placeholder="Select Category" onChange={setSelectedCategory} value={selectedCategory}
                                className={classes.formSelect}
                              />
                            </div>
                          </div>
                        </Form.Group>
                      </div>
                    </div> */}
                    <div style={{ marginTop: -5, marginLeft: 12 }} />
                    <div className="row">
                    <div className="col-md-6">
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                          <div className={`d-flex align-items-left form-group-custom ${classes.align112234}`}>
                            <Form.Label className={classes.formLabddsadelCustom}>Re-Order Level</Form.Label>
                            <Form.Control
                              className={classes.formCsdfontrol}
                              type="text"
                              // placeholder="0.00"
                              value={reOderLevel}
                              onChange={(e) => setReOrderLevel(e.target.value)}
                            />
                          </div>
                        </Form.Group>
                      </div>
                    
                    </div>
                  {/* </>
                )} */}
                <div style={{ marginTop: -10 }} />
                <div className="row">
                  <div className="col-md-6">
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <div className={`d-flex align-items-left form-group-custom ${classes.align1234}`}>
                        <Form.Label className={classes.formLabelCustom} style={{ marginLeft: 25 }}>Sales GL</Form.Label>
                        <div style={{ width: "300px", marginLeft: 15 }}>
                          <Select loading={isLoading} dropdownHeight='100px' options={incomes} style={{ borderRadius: 5, }}
                            onChange={setSelectedSales} placeholder="Select Sales GL" value={selectedSales}
                          />
                        </div>
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group className="mb-3">
                      <div className={`d-flex align-items-left form-group-custom ${classes.align1234}`} style={{ width: '100%' }}>
                        <Form.Label className={classes.formLabelCustom}>Inventory GL</Form.Label>
                        <div style={{ width: '100%' }}>
                          <Select loading={isLoading} dropdownHeight='100px' options={expenses} style={{ borderRadius: 5, width: '90%' }}
                            className={classes.formSelect}
                            value={selectedPurchase}
                            onChange={setSelectedPurchase}
                            placeholder="Select Purchases "
                          />
                        </div>
                      </div>
                    </Form.Group>
                  </div>
                </div>
                <div style={{ marginTop: -10 }} />
                <div className="row">
                  <div className="col-md-6">
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <div className={`d-flex align-items-left form-group-custom ${classes.align1234}`}>
                        <Form.Label className={classes.formLabelCustom} style={{ marginLeft: 25 }}>Cost of Good GL</Form.Label>
                        <div style={{ width: "300px", marginLeft: 15 }}>
                          <Select loading={isLoading} dropdownHeight='100px' options={tableData4} style={{ borderRadius: 5, }}
                            onChange={setSelectedCost} value={selectedCost} placeholder="Select Cost of Good"
                          />
                        </div>
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group className="mb-3">
                      <div className={`d-flex align-items-left form-group-custom ${classes.align1234}`} style={{ width: '100%' }}>
                        <Form.Label className={classes.formLabelCustom}>Discount GL</Form.Label>
                        <div style={{ width: '100%' }}>
                          <Select loading={isLoading} dropdownHeight='100px' options={tableData4} style={{ borderRadius: 5, width: '90%' }}
                            className={classes.formSelect}
                            value={selectedDisc}
                            onChange={setSelectedDisc}
                            placeholder="Select Discount GL "
                          />
                        </div>
                      </div>
                    </Form.Group>
                  </div>
                </div>
                <div style={{ marginTop: -20 }} />
                <div className="row">
                  <div className="col-md-6">
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <div className={`d-flex align-items-left form-group-custom ${classes.align1234}`} >
                        <Form.Label className={classes.formLabelCustom}>A/c Receivables</Form.Label>
                        <div style={{ width: "240px", marginLeft: 15 }}>
                          <Select loading={isLoading} dropdownHeight='100px' options={receivables} style={{ borderRadius: 5, width: '100%', marginLeft: -3 }}
                            onChange={setSelectedAccount} value={selectedAccount} placeholder="Select Receivables"
                          />
                        </div>
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group className="mb-3">
                      <div className={`d-flex align-items-left form-group-custom ${classes.align1234}`} style={{ width: '100%' }}>
                        <Form.Label className={classes.formLabelCustom}>A/c Payables</Form.Label>
                        <div style={{ width: '100%' }}>
                          <Select loading={isLoading} dropdownHeight='100px' options={payables} style={{ borderRadius: 5, width: '100%' }}
                            className={classes.formSelect}
                            onChange={setSelectedPayable}
                            value={selectedPayable}
                            placeholder="Select Payables"
                          />
                        </div>
                      </div>
                    </Form.Group>
                  </div>
                </div>
                <div style={{ marginTop: -10 }} />
                <div className="row">
                      <div className="col-md-6">
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                          <div className={`d-flex align-items-left form-group-custom ${classes.align1234}`}>
                            <Form.Label className={classes.formLabelCustom} style={{ marginLeft: 30 }}>Advance Payment</Form.Label>
                            <div className={classes.formControl}>
                            <Select loading={isLoading} dropdownHeight='100px' options={payables} style={{ borderRadius: 5, }}
                        onChange={setSelectedAdvance} value={selectedAdvance} placeholder="Select Advance"
                      />
                            </div>
                          </div>
                        </Form.Group>
                      </div>
                      <div className="col-md-6" >
                        <Form.Group className="mb-3">
                          <div className={`d-flex align-items-left form-group-custom ${classes.align1234}`}>
                            <Form.Label className={classes.formLabelCustom} >Display Image</Form.Label>
                            <div style={{ width: '100%' }}>
                            <Form.Control
                            type="file"
                            placeholder="Choose file"
                            accept=".jpg, .jpeg, .png"
                            onChange={handleSingleImageChange}
                            className={classes.form124Control1}
                            // style={{ width: 349, display: "flex", justifyContent: "left" }}
                          />
                            </div>
                          </div>
                        </Form.Group>
                      </div>
                    </div>
                    <div style={{ marginTop: -20 }} />
                    <div className="col-md-6" >
                        <Form.Group className="mb-3">
                          <div className={`d-flex align-items-left form-group-custom ${classes.align1234}`}>
                            <Form.Label className={classes.formLabelCustom} >Additional Images</Form.Label>
                            <div style={{ width: '100%' }}>
                            <Form.Control
                            type="file"
                            multiple
                            placeholder="Choose file"
                            accept=".jpg, .jpeg, .png"
                            onChange={handleImageChange}
                            className={classes.form124Control12}
                            // style={{ width: 349, display: "flex", justifyContent: "left" }}
                          />
                            </div>
                          </div>
                        </Form.Group>
                      </div>
                      <div className={classes.productContainer}>
                      

{selectedProducts && (
  <div style={{width: 10}}>
        <ReactBarcode
          value={JSON.stringify(selectedProducts?.description)}
          options={{
            format: "CODE128",
            width: 2,
            height: 100,
            // width: 100,
            displayValue: false
          }}
        />
        </div>
      )}
</div>

              </Form>
            </div>
            <div style={{marginTop: 50}} />
            <div className={classes.buttonsGroup}>
              <Button variant="success" onClick={createUser}>
                {createLoading ? (
                  <>
                    <Spinner size='sm' />
                    <span style={{ marginLeft: '5px' }}>Updating Product, Please wait...</span>
                  </>
                ) : (
                  "Update Product/Services"
                )}
              </Button>
            </div>








          </div>
        </div>

      </div>






    </div >
  )
}