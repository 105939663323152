import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import classes from './AccountNew.module.css';
import { Button, ProgressBar } from 'react-bootstrap';
import CashDash from '../Cash Dash/CashDash';
import Leftback from './LeftBack.svg';
import TopImage from './TopImage.svg';
import { Chart } from 'react-charts';
import { NavLink,  Link } from 'react-router-dom';


const CashDashboard = () => {
   
    return (
        <div className={classes.maincont1234}>
            <div className={classes.sideNavContainer}>
            {/* <img src={Leftback} alt='icon' className={classes.mobileshow} /> */}
            <h4> Welcome to <br /> Promix Accounting </h4>
            <p> Proceed to your dashboard </p>
            </div>

            
            <div className={classes.formSection}>
                    <div className={classes.formSection2345}>
                        <img src={TopImage} alt='icon' className={classes.Imageshow213} />
                        <h4>Registration Complete</h4>
                        <p>Your onboarding process is complete, please proceed to your
                        dashboard to manage your profile.</p>
                      <Link to={'/welcome'} >  <Button variant="success" className={classes.snupbtn} >
                        Proceed
                    </Button></Link>
                    </div>
            </div>

        </div>

    );
}

export default CashDashboard;