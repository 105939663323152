import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import classes from "./MobileMenu.module.css";
import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "axios";
import { IoMdClose } from "react-icons/io";
import { NavLink, Link } from "react-router-dom";
import { MdArrowDropDown } from "react-icons/md";
import { BASE_URL } from "../../../src/Pages/api/api";

const MobileMenu = ({ setOpenNav, openNav }) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [bearer, setBearer] = useState("");
  const [permits, setPermits] = useState([]);
  const [user, setUser] = useState("");
  const [validity, setValidity] = useState("");
  const [admin, setAdmin] = useState("");
  const [company, setCompany] = useState("");
  const [currMonth, setCurrMonth] = useState("");
  const [openDropdown, setOpenDropdown] = useState(null);

  const toggleDropdown = (dropdown) => {
    setOpenDropdown(openDropdown === dropdown ? null : dropdown);
  };

  const handleNavigation = (path) => {
    navigate(path);
    setOpenNav(false); // Close menu after navigation
  };

  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem("userToken");
      const value1 = await AsyncStorage.getItem("tobi");
      const value3 = await AsyncStorage.getItem("admin");
      const value7 = await AsyncStorage.getItem("companyName");
      const value8 = await AsyncStorage.getItem("validity");
      const curr = await AsyncStorage.getItem("currentMonth");
      const permitsss = await AsyncStorage.getItem("modules");

      if (value !== null && value.trim() !== "") {
        setBearer(value);
      } else {
        navigate("/login");
        return;
      }
      if (permitsss !== null) {
        const parsedPermits = JSON.parse(permitsss);
        const permitNames = parsedPermits.map((permit) => permit.name);
        setPermits(permitNames);
      }
      if (value1 !== null) setUser(value1);
      if (value8 !== null) setValidity(value8);
      if (value3 !== null) setAdmin(value3);
      if (value7 !== null) setCompany(value7);
      if (curr !== null) setCurrMonth(curr);
    } catch (e) {
      alert("Failed to fetch the input from storage");
    }
  };

  useEffect(() => {
    readData();
  }, []);

  const handleLogout = async () => {
    setLoading(true);
    try {
      await axios.post(
        `${BASE_URL}/logout`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${bearer}`,
          },
        }
      );
      navigate("/login");
    } catch (error) {
      let errorMessage = "An error occurred. Please try again.";
      if (error.response?.status === 401) {
        navigate("/login");
      }
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={classes.mobileMenu}>
      <div className={classes.mobileBgr}>
        <div className={classes.closeMenu}>
          <button onClick={() => setOpenNav(false)}>
            <IoMdClose size={24} />
          </button>
        </div>
        <ul className={classes.menuMobile}>
          <li className={classes.menuItem}>
            <Link
              to="/accounting"
              onClick={() => handleNavigation("/accounting")}
            >
              Dashboard
            </Link>
          </li>

          {/* Setup Dropdown */}
          <li>
            <button
              className={classes.dropdownButton}
              onClick={() => toggleDropdown("setup")}
            >
              <span>Setup</span>
              <MdArrowDropDown />
            </button>
            {openDropdown === "setup" && (
              <div className={classes.dropdownContent}>
                <Link
                  to="/accounting/users"
                  onClick={() => handleNavigation("/accounting/users")}
                  className={classes.dropdownItem}
                >
                  Manage User
                </Link>
                <Link
                  to="/accounting/loan_account"
                  onClick={() => handleNavigation("/accounting/loan_account")}
                  className={classes.dropdownItem}
                >
                  Manage Loan Account
                </Link>
              </div>
            )}
          </li>

          {/* Receivables Dropdown */}
          <li>
            <button
              className={classes.dropdownButton}
              onClick={() => toggleDropdown("receivables")}
            >
              <span>Receivables</span>
              <MdArrowDropDown />
            </button>
            {openDropdown === "receivables" && (
              <div className={classes.dropdownContent}>
                {/* Add your receivables links here with handleNavigation */}
                <Link
                  to="/accounting/customers"
                  onClick={() => handleNavigation("/accounting/customers")}
                  className={classes.dropdownItem}
                >
                  Manage Customers
                </Link>
                <Link
                  to="/accounting/remitances"
                  onClick={() => handleNavigation("/accounting/remitances")}
                  className={classes.dropdownItem}
                >
                  Monthly Remitances
                </Link>
                <Link
                  to="/accounting/upload_remitance_excel"
                  onClick={() =>
                    handleNavigation("/accounting/upload_remitance_excel")
                  }
                  className={classes.dropdownItem}
                >
                  Monthly Remitance Upload - Excel
                </Link>
                <Link
                  to="/accounting/loan"
                  onClick={() => handleNavigation("/accounting/loan")}
                  className={classes.dropdownItem}
                >
                  Loan Beneficiaries
                </Link>
                <Link
                  to="/accounting/receivables/staff_loan"
                  onClick={() =>
                    handleNavigation("/accounting/receivables/staff_loan")
                  }
                  className={classes.dropdownItem}
                >
                  Loan and Advances
                </Link>
                <Link
                  to="/accounting/customers_receipts"
                  onClick={() =>
                    handleNavigation("/accounting/customers_receipts")
                  }
                  className={classes.dropdownItem}
                >
                  Customers' Receipt
                </Link>
                <Link
                  to="/accounting/personal_ledger"
                  onClick={() =>
                    handleNavigation("/accounting/personal_ledger")
                  }
                  className={classes.dropdownItem}
                >
                  Customers' Personal Ledger
                </Link>
                <Link
                  to="/accounting/schedule_of_receivable"
                  onClick={() =>
                    handleNavigation("/accounting/schedule_of_receivable")
                  }
                  className={classes.dropdownItem}
                >
                  Schedule of Receivables
                </Link>
                {/* Add other receivables menu items similarly */}
              </div>
            )}
          </li>

          {/* Add other dropdowns similarly */}
          <li>
            <button
              className={classes.dropdownButton}
              onClick={() => toggleDropdown("payables")}
            >
              <span> Payables</span>
              <MdArrowDropDown />
            </button>
            {openDropdown === "payables" && (
              <div className={classes.dropdownContent}>
                {/* Add your receivables links here with handleNavigation */}
                <Link
                  to="/accounting/supplier"
                  onClick={() => handleNavigation("/accounting/supplier")}
                  className={classes.dropdownItem}
                >
                  Manage Suppliers/Beneficiary
                </Link>
                <Link
                  to="/accounting/purchase_invoices"
                  onClick={() =>
                    handleNavigation("/accounting/purchase_invoices")
                  }
                  className={classes.dropdownItem}
                >
                  Purchases Invoice
                </Link>
                <Link
                  to="/accounting/suppliers_receipt"
                  onClick={() =>
                    handleNavigation("/accounting/suppliers_receipt")
                  }
                  className={classes.dropdownItem}
                >
                  Suppliers' Payment
                </Link>
                <Link
                  to="/accounting/suppliers_personal_ledger"
                  onClick={() =>
                    handleNavigation("/accounting/suppliers_personal_ledger")
                  }
                  className={classes.dropdownItem}
                >
                  Suppliers' Personal Ledger
                </Link>
                <Link
                  to="/account_payables_bulk"
                  onClick={() => handleNavigation("/account_payables_bulk")}
                  className={classes.dropdownItem}
                >
                  Account Payables - Bulk
                </Link>
                <Link
                  to="/accounting/schedule_of_payable"
                  onClick={() =>
                    handleNavigation("/accounting/schedule_of_payable")
                  }
                  className={classes.dropdownItem}
                >
                  Schedule of Payables
                </Link>
              </div>
            )}
          </li>

          <li>
            <button
              className={classes.dropdownButton}
              onClick={() => toggleDropdown("banking")}
            >
              <span> Banking</span>
              <MdArrowDropDown />
            </button>
            {openDropdown === "banking" && (
              <div className={classes.dropdownContent}>
                {/* Add your receivables links here with handleNavigation */}
                <Link
                  to="/accounting/statement_of_account"
                  onClick={() =>
                    handleNavigation("/accounting/statement_of_account")
                  }
                  className={classes.dropdownItem}
                >
                  Upload Statement of Account
                </Link>
                <Link
                  to="/payment/expenses"
                  onClick={() => handleNavigation("/payment/expenses")}
                  className={classes.dropdownItem}
                >
                  Expenses
                </Link>
                <Link
                  to="/payment/payables"
                  onClick={() => handleNavigation("/payment/payables")}
                  className={classes.dropdownItem}
                >
                  Payables
                </Link>
                <Link
                  to="/payment/bulk_payment"
                  onClick={() => handleNavigation("/payment/bulk_payment")}
                  className={classes.dropdownItem}
                >
                  Bulk Expenses Postings
                </Link>
                <Link
                  to="/account_payment_voucher"
                  onClick={() => handleNavigation("/account_payment_voucher")}
                  className={classes.dropdownItem}
                >
                  Payment Voucher
                </Link>
                <Link
                  to="/accounting/cash_management"
                  onClick={() =>
                    handleNavigation("/accounting/cash_management")
                  }
                  className={classes.dropdownItem}
                >
                  Cash Management
                </Link>
                <Link
                  to="/cash_management/bank_lodgement/pending"
                  onClick={() =>
                    handleNavigation("/cash_management/bank_lodgement/pending")
                  }
                  className={classes.dropdownItem}
                >
                  Bank Lodgement
                </Link>
                <Link
                  to="/cash_management/lodged_transactions"
                  onClick={() =>
                    handleNavigation("/cash_management/lodged_transactions")
                  }
                  className={classes.dropdownItem}
                >
                  Lodged Transactions
                </Link>
                <Link
                  to="/accounting/bank_reconciliation"
                  onClick={() =>
                    handleNavigation("/accounting/bank_reconciliation")
                  }
                  className={classes.dropdownItem}
                >
                  Bank Reconciliation
                </Link>
              </div>
            )}
          </li>

          <li>
            <button
              className={classes.dropdownButton}
              onClick={() => toggleDropdown("ledger")}
            >
              <span>General Ledger</span>
              <MdArrowDropDown />
            </button>
            {openDropdown === "ledger" && (
              <div className={classes.dropdownContent}>
                {/* Add your receivables links here with handleNavigation */}
                <Link
                  to="/accounting/view_journal_entries"
                  onClick={() =>
                    handleNavigation("/accounting/view_journal_entries")
                  }
                  className={classes.dropdownItem}
                >
                  Journal Entries
                </Link>
                <Link
                  to="/accounting/upload_opening_balance"
                  onClick={() =>
                    handleNavigation("/accounting/upload_opening_balance")
                  }
                  className={classes.dropdownItem}
                >
                  Upload Opening Balance - Excel
                </Link>
                <Link
                  to="/accounting/upload_opening_balance_bulk"
                  onClick={() =>
                    handleNavigation("/accounting/upload_opening_balance_bulk")
                  }
                  className={classes.dropdownItem}
                >
                  Upload Opening Balance - Bulk
                </Link>
                <Link
                  to="/accounting/upload_journal_entry"
                  onClick={() =>
                    handleNavigation("/accounting/upload_journal_entry")
                  }
                  className={classes.dropdownItem}
                >
                  Upload Journal Entry
                </Link>
                <Link
                  to="/accounting/charts_of_account/account"
                  onClick={() =>
                    handleNavigation("/accounting/charts_of_account/account")
                  }
                  className={classes.dropdownItem}
                >
                  Charts of Account
                </Link>
              </div>
            )}
          </li>

          <li>
            <button
              className={classes.dropdownButton}
              onClick={() => toggleDropdown("report")}
            >
              <span>Reports</span>
              <MdArrowDropDown />
            </button>
            {openDropdown === "report" && (
              <div className={classes.dropdownContent}>
                {/* Add your receivables links here with handleNavigation */}
                <Link
                  to="/accounting/cashbook"
                  onClick={() => handleNavigation("/accounting/cashbook")}
                  className={classes.dropdownItem}
                >
                  Cashbook
                </Link>
                <Link
                  to="/accounting/trial_balance"
                  onClick={() => handleNavigation("/accounting/trial_balance")}
                  className={classes.dropdownItem}
                >
                  Trial Balance
                </Link>
                <Link
                  to="/accounting/general_ledger/postings"
                  onClick={() =>
                    handleNavigation("/accounting/general_ledger/postings")
                  }
                  className={classes.dropdownItem}
                >
                  General Ledger
                </Link>
                <Link
                  to="/accounting/income_statement_summary"
                  onClick={() =>
                    handleNavigation("/accounting/income_statement_summary")
                  }
                  className={classes.dropdownItem}
                >
                  Income Statement - Summary
                </Link>
                <Link
                  to="/accounting/income_statement_detailed"
                  onClick={() =>
                    handleNavigation("/accounting/income_statement_detailed")
                  }
                  className={classes.dropdownItem}
                >
                  Income Statement - Detailed
                </Link>
                <Link
                  to="/accounting/activity_report_acct"
                  onClick={() =>
                    handleNavigation("/accounting/activity_report_acct")
                  }
                  className={classes.dropdownItem}
                >
                  Account Activity Report
                </Link>

                <Link
                  to="/accounting/aged_analysis_payables"
                  onClick={() =>
                    handleNavigation("/accounting/aged_analysis_payables")
                  }
                  className={classes.dropdownItem}
                >
                  Age Analysis - Payables
                </Link>

                <Link
                  to="/accounting/aged_analysis_receivables"
                  onClick={() =>
                    handleNavigation("/accounting/aged_analysis_receivables")
                  }
                  className={classes.dropdownItem}
                >
                  Age Analysis - Receivables
                </Link>

                <Link
                  to="/financial_position"
                  onClick={() => handleNavigation("/financial_position")}
                  className={classes.dropdownItem}
                >
                  Statement of Financial Position - Summary
                </Link>

                <Link
                  to="/accounting/statement_of_financial_position_admin"
                  onClick={() =>
                    handleNavigation(
                      "/accounting/statement_of_financial_position_admin"
                    )
                  }
                  className={classes.dropdownItem}
                >
                  Statement of Financial Position - Detailed
                </Link>

                <Link
                  to="/accounting/bank_report"
                  onClick={() => handleNavigation("/accounting/bank_report")}
                  className={classes.dropdownItem}
                >
                  Bank Position Summary
                </Link>

                <Link
                  to="/statement_of_cash_flow"
                  onClick={() => handleNavigation("/statement_of_cash_flow")}
                  className={classes.dropdownItem}
                >
                  Statement of Cash Flow
                </Link>

                <Link
                  to="/accounting/sales_report"
                  onClick={() => handleNavigation("/accounting/sales_report")}
                  className={classes.dropdownItem}
                >
                  Sales Report
                </Link>

                <Link
                  to="/accounting/purchase_report"
                  onClick={() =>
                    handleNavigation("/accounting/purchase_report")
                  }
                  className={classes.dropdownItem}
                >
                  Purchases Report
                </Link>
                <Link
                  to="/accounting/reconciliation_summary_report"
                  onClick={() =>
                    handleNavigation(
                      "/accounting/reconciliation_summary_report"
                    )
                  }
                  className={classes.dropdownItem}
                >
                  Reconciliation Summary Report
                </Link>
              </div>
            )}
          </li>

          {/* Account & Logout Links */}
          <li className={classes.menuItem}>
            <Link to="/welcome" onClick={() => handleNavigation("/welcome")}>
              Switch Account
            </Link>
          </li>
          <li className={classes.menuItem}>
            <Link
              to="/accounting"
              onClick={() => handleNavigation("/accounting")}
            >
              Change Password
            </Link>
          </li>
          <li className={classes.menuItem}>
            <Link
              to="/accounting"
              onClick={(e) => {
                e.preventDefault();
                handleLogout();
              }}
            >
              {loading ? "Logging out..." : "Logout"}
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default MobileMenu;
