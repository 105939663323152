import React, { useState, useEffect } from 'react';
import classes from './ConsolidateActivity.module.css';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useLocation } from 'react-router-dom';

export default function ConsolidateReportActivity() {
  const [bearer, setBearer] = useState('');
  const [company, setCompany] = useState('');
  const location = useLocation();
  const [currentDateTime, setCurrentDateTime] = useState('');
  const [totalDebit, setTotalDebit] = useState('');
  const [totalCredit, setTotalCredit] = useState('');
  const [continent, setContinent] = useState('');
  const [region, setRegion] = useState('');
  const [province, setProvince] = useState('');
  const [userType, setUserType] = useState('');

  useEffect(() => {
    const addLeadingZero = (number) => (number < 10 ? `0${number}` : number);

    const currentDate = new Date();
    const day = currentDate.getDate();
    const month = addLeadingZero(currentDate.getMonth() + 1);
    const year = currentDate.getFullYear();
    const minutes = addLeadingZero(currentDate.getMinutes());
    const hours = addLeadingZero(currentDate.getHours() % 12 || 12);
    const seconds = addLeadingZero(currentDate.getSeconds());
    const ampm = currentDate.getHours() >= 12 ? 'PM' : 'AM';

    const formattedDateTime = `${day}/${month}/${year} `;

    setCurrentDateTime(formattedDateTime);
  }, []);

  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem('userToken');
      const value1 = await AsyncStorage.getItem('companyName');
      const admins = await AsyncStorage.getItem('admin');
      const continentName = await AsyncStorage.getItem('continent');
            const regionName = await AsyncStorage.getItem('region');
            const provinceName = await AsyncStorage.getItem('province');

      if (value !== null) {
        setBearer(value);
      }
      if (value1 !== null) {
        setCompany(value1);
        console.log(company);
      }
      if (admins !== null) {
        setUserType(admins);
      }
      if (continentName !== null) {
        setContinent(continentName);
      }
      if (regionName !== null) {
        setRegion(regionName);
      }
      if (provinceName !== null) {
        setProvince(provinceName);
      }
    } catch (e) {
      alert('Failed to fetch the input from storage');
    }
  };

  useEffect(() => {
    readData();
  }, []);

  const { document, inputs } = location.state || {};
  console.log(inputs, document, "printing");
  const startDate = new Date(inputs.start_date);
  const endDate = new Date(inputs.end_date);

  const getOrdinalSuffix = (number) => {
    const suffixes = ['th', 'st', 'nd', 'rd'];
    const v = number % 100;
    return v >= 11 && v <= 13
      ? 'th'
      : suffixes[v % 10] || 'th';
  };

  function formattedDates(dateString) {
    const date = new Date(dateString);
    const formattedDate = `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())}`;
    return formattedDate;
  }

  function padZero(num) {
    return num < 10 ? `0${num}` : num;
  }

  useEffect(() => {
    if (document) {
        const creditTotal = document
            .filter((item) => !isNaN(item.nCredit) && item.nCredit !== "")
            .reduce((total, item) => total + parseFloat(item.nCredit), 0);

        const debitTotal = document
            .filter((item) => !isNaN(item.nDebit) && item.nDebit !== "")
            .reduce((total, item) => total + parseFloat(item.nDebit), 0);

        // Format the numbers with commas and two decimal places
        const formattedDebitTotal = debitTotal.toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });

        const formattedCreditTotal = creditTotal.toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });

        setTotalDebit(formattedDebitTotal);
        setTotalCredit(formattedCreditTotal);
    }
}, [document]);


const groupedData = document.reduce((acc, item) => {
    if (!acc[item.gl_code]) {
      acc[item.gl_code] = [];
    }
    acc[item.gl_code].push(item);
    return acc;
  }, {});

  // Calculate totals for each group
  const calculateTotals = (group) => {
    return group.reduce(
      (totals, item) => {
        totals.debit += parseFloat(item.debit) || 0;
        totals.credit += parseFloat(item.credit) || 0;
        return totals;
      },
      { debit: 0, credit: 0 }
    );
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const formattedDate = `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())} `;
    return formattedDate;
  }

  const allNamesSame = document.every(doc => doc.account?.gl_name === document[0].account?.gl_name);
  const displayName = allNamesSame ? document[0].account?.gl_name : "All Accounts";

  

  return (
    <div className={classes.generalbd}>
      <div className={classes.a4}>
        <div className={classes.header}>
        <h1>THE REDEEMED CHRISTIAN CHURCH OF GOD</h1>
                <p style={{ width: "100%", fontSize: 18, textTransform: "uppercase" }}>
                  {inputs?.name}
                  </p>
        {/* <p style={{ width: "100%", fontSize: 18 }}>{userType === "Continental Accountant" ? continent :
 userType === "Regional Accountant" ? region :
 userType === "Super Admin" ? "Super Admin" :
 userType === "Provincial Accountant" ? province : null}
</p> */}
          <h6 style={{fontWeight: 900}}>CONSOLIDATED ACCOUNT ACTIVITY REPORT FROM {inputs.start_date} TO {inputs.end_date}</h6>
        </div>
        <p><span style={{fontWeight: 700, color: "#000"}}>DATE PRINTED:</span> {currentDateTime} </p>
        <table className={classes.table}>
  <thead>
    <tr>
      <th style={{ textAlign: 'left', width: '30px' }}>Account</th>
      <th style={{ textAlign: 'left', width: '10px' }}>Name</th>
      <th style={{ textAlign: 'left', width: '2px' }}>Month</th>
      <th style={{ textAlign: 'left', width: '70px' }}>Date</th>
      <th style={{ textAlign: 'left', width: '200px' }}>Transaction Description</th>
      {/* <th style={{ textAlign: 'left', width: '50px' }}>Type</th> */}
      <th style={{ textAlign: 'right', width: '100px' }}>Debit</th>
      <th style={{ textAlign: 'right', width: '100px' }}>Credit</th>
    </tr>
  </thead>
  <tbody>
    {Object.keys(groupedData).map((gl_code) => {
      const group = groupedData[gl_code];
      const totals = calculateTotals(group);
      return (
        <React.Fragment key={gl_code}>
          <tr>
            <td colSpan="1" style={{ fontWeight: "bold" }}>{group[0]?.account?.gl_name}</td>
            <td colSpan="5" style={{ fontWeight: "bold", textAlign: "right" }}>Beginning Balance</td>
            <td colSpan="1" style={{ textAlign: "right", fontWeight: "bold" }}>{group[0]?.opening_balance.toLocaleString('en-US', {
                minimumIntegerDigits: 1,
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })}</td>
          </tr>
          {group.map((item, index) => (
            <tr key={index}>
              <td>{item.account?.gl_code}</td>
              <td>{item.account?.name}</td>
              <td style={{ whiteSpace: "nowrap" }}>{new Date(item.transaction_date).getMonth() + 1}</td>
              <td>{item.transaction_date}</td>
              <td>{item.details}</td>
              <td style={{ textAlign: "right" }}>
                {item.debit === "" ? '0.00' :
                  (isNaN(item.debit)
                    ? '0.00'
                    : parseFloat(item.debit).toLocaleString('en-US', {
                      minimumIntegerDigits: 1,
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    }))}
              </td>
              <td style={{ textAlign: "right" }}>
                {item.credit === "" ? '0.00' :
                  (isNaN(item.credit)
                    ? '0.00'
                    : parseFloat(item.credit).toLocaleString('en-US', {
                      minimumIntegerDigits: 1,
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    }))}
              </td>
            </tr>
          ))}
          {/* <tr>
            <td colSpan="6" style={{ borderBottom: "2px solid #000" }}></td>
          </tr> */}
          <tr>
            <td colSpan="5" style={{ textAlign: "right", fontWeight: "bold" }}>Total</td>
            
            <td style={{ textAlign: "right", fontWeight: "bold" }}>
              {totals.debit.toLocaleString('en-US', {
                minimumIntegerDigits: 1,
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })}
            </td>
            <td style={{ textAlign: "right", fontWeight: "bold" }}>
              {totals.credit.toLocaleString('en-US', {
                minimumIntegerDigits: 1,
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })}
            </td>
          </tr>
          <tr>
            <td colSpan="6" style={{textAlign: "right",  fontWeight: "bold"}}>Closing Balance</td>
            
            <td style={{ textAlign: "right", fontWeight: "bold" }}>
  {(
    totals.debit - 
    totals.credit - 
    (group[0]?.opening_balance || 0)
  ).toLocaleString('en-US', {
    minimumIntegerDigits: 1,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  })}
</td>

            
          </tr>
        <div  style={{marginTop: 30}}/>
          {/* <tr>
            <td colSpan="6" style={{ borderBottom: "2px solid #000" }}></td>
          </tr> */}
        </React.Fragment>
      );
     
    })}
  </tbody>
</table>


      </div>
    </div>
  );
}
