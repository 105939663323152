import React, { useState, useRef } from "react";
import classes from './Login.module.css'
import backgroundImg from '../../assets/promix/LogIn.png'
import Form from 'react-bootstrap/Form';
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Button, Spinner } from "react-bootstrap";
import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import crossedEyeIcon from '../../assets/promix/eye-slash.png';
import { BASE_URL } from "../../Pages/api/api";

function OTP() {
const navigate = useNavigate();
    const [otp, setOtp] = useState(new Array(6).fill(""));
  const inputRefs = useRef([]);

  const handleChange = (element, index) => {
    const value = element.value;
    if (isNaN(value)) return;

    let newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    // Move to the next input field if not the last input
    if (value && index < 5) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (event, index) => {
    if (event.key === 'Backspace' && !otp[index] && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  const handleContinue = () => {
    navigate('/welcome');
  }


    return (

        <div className={classes.background}>
            <ToastContainer />
            <div className={classes.mainContainerx}>
                <div className={classes.thecntnt}>
                    <div className={classes.head}>
                        <p className={classes.SignUptxt1}>Enter Code</p>
                        <p className={classes.SignUptxtsub1}>Enter the six digit code sent to you to continue</p>
                    </div>
                    <Form>
      <Form.Group className={classes.otpGroup}>
        <div className={classes.otpInputs}>
          {otp.map((data, index) => (
            <Form.Control
              key={index}
              type="text"
              name="otp"
              maxLength="1"
              className={classes.otpInput}
              value={data}
              onChange={e => handleChange(e.target, index)}
              onKeyDown={e => handleKeyDown(e, index)}
              ref={el => inputRefs.current[index] = el}
              autoFocus={index === 0}
              style={{ backgroundColor: 'rgba(0, 0, 0, 0.8)', color: "#fff" }}
            />
          ))}
        </div>
      </Form.Group>
    </Form>
                    <Button onClick={handleContinue}  variant="success" className={classes.snupbtn} >
                     Log in
                    </Button>
                    {/* <p className={classes.lgin}>Don't have an account? <Link to={'/signup'} style={{ textDecoration: 'none' }}><span>Sign Up</span></Link></p> */}
                </div>
            </div>
        </div>
    );

}
export default OTP;