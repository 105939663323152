export const COUNTRIES = [
	"Afghanistan",
	"Albania",
	"Algeria",
	"American Samoa",
	"Andorra",
	"Angola",
	"Anguilla",
	"Antarctica",
	"Antigua and Barbuda",
	"Argentina",
	"Armenia",
	"Aruba",
	"Australia",
	"Austria",
	"Azerbaijan",
	"Bahamas",
	"Bahrain",
	"Bangladesh",
	"Barbados",
	"Belarus",
	"Belgium",
	"Belize",
	"Benin",
	"Bermuda",
	"Bhutan",
	"Bolivia",
	"Bonaire, Sint Eustatius and Saba",
	"Bosnia and Herzegovina",
	"Botswana",
	"Bouvet Island",
	"Brazil",
	"British Indian Ocean Territory",
	"Brunei Darussalam",
	"Bulgaria",
	"Burkina Faso",
	"Burundi",
	"Cabo Verde",
	"Cambodia",
	"Cameroon",
	"Canada",
	"Cayman Islands",
	"Central African Republic",
	"Chad",
	"Chile",
	"China",
	"Christmas Island",
	"Cocos (Keeling) Islands",
	"Colombia",
	"Comoros",
	"Congo",
	"Congo",
	"Cook Islands",
	"Costa Rica",
	"Croatia",
	"Cuba",
	"Curaçao",
	"Cyprus",
	"Czechia",
	"Côte d'Ivoire",
	"Denmark",
	"Djibouti",
	"Dominica",
	"Dominican Republic",
	"Ecuador",
	"Egypt",
	"El Salvador",
	"Equatorial Guinea",
	"Eritrea",
	"Estonia",
	"Eswatini",
	"Ethiopia",
	"Falkland Islands [Malvinas]",
	"Faroe Islands",
	"Fiji",
	"Finland",
	"France",
	"French Guiana",
	"French Polynesia",
	"French Southern Territories",
	"Gabon",
	"Gambia",
	"Georgia",
	"Germany",
	"Ghana",
	"Gibraltar",
	"Greece",
	"Greenland",
	"Grenada",
	"Guadeloupe",
	"Guam",
	"Guatemala",
	"Guernsey",
	"Guinea",
	"Guinea-Bissau",
	"Guyana",
	"Haiti",
	"Heard Island and McDonald Islands",
	"Holy See",
	"Honduras",
	"Hong Kong",
	"Hungary",
	"Iceland",
	"India",
	"Indonesia",
	"Iran (Islamic Republic of)",
	"Iraq",
	"Ireland",
	"Isle of Man",
	"Israel",
	"Italy",
	"Jamaica",
	"Japan",
	"Jersey",
	"Jordan",
	"Kazakhstan",
	"Kenya",
	"Kiribati",
	"Korea (the Democratic People's Republic of)",
	"Korea (the Republic of)",
	"Kuwait",
	"Kyrgyzstan",
	"Lao People's Democratic Republic",
	"Latvia",
	"Lebanon",
	"Lesotho",
	"Liberia",
	"Libya",
	"Liechtenstein",
	"Lithuania",
	"Luxembourg",
	"Macao",
	"Madagascar",
	"Malawi",
	"Malaysia",
	"Maldives",
	"Mali",
	"Malta",
	"Marshall Islands",
	"Martinique",
	"Mauritania",
	"Mauritius",
	"Mayotte",
	"Mexico",
	"Micronesia (Federated States of)",
	"Moldova (the Republic of)",
	"Monaco",
	"Mongolia",
	"Montenegro",
	"Montserrat",
	"Morocco",
	"Mozambique",
	"Myanmar",
	"Namibia",
	"Nauru",
	"Nepal",
	"Netherlands",
	"New Caledonia",
	"New Zealand",
	"Nicaragua",
	"Niger",
	"Nigeria",
	"Niue",
	"Norfolk Island",
	"Northern Mariana Islands",
	"Norway",
	"Oman",
	"Pakistan",
	"Palau",
	"Palestine, State of",
	"Panama",
	"Papua New Guinea",
	"Paraguay",
	"Peru",
	"Philippines",
	"Pitcairn",
	"Poland",
	"Portugal",
	"Puerto Rico",
	"Qatar",
	"Republic of North Macedonia",
	"Romania",
	"Russian Federation",
	"Rwanda",
	"Réunion",
	"Saint Barthélemy",
	"Saint Helena, Ascension and Tristan da Cunha",
	"Saint Kitts and Nevis",
	"Saint Lucia",
	"Saint Martin (French part)",
	"Saint Pierre and Miquelon",
	"Saint Vincent and the Grenadines",
	"Samoa",
	"San Marino",
	"Sao Tome and Principe",
	"Saudi Arabia",
	"Senegal",
	"Serbia",
	"Seychelles",
	"Sierra Leone",
	"Singapore",
	"Sint Maarten (Dutch part)",
	"Slovakia",
	"Slovenia",
	"Solomon Islands",
	"Somalia",
	"South Africa",
	"South Georgia and the South Sandwich Islands",
	"South Sudan",
	"Spain",
	"Sri Lanka",
	"Sudan",
	"Suriname",
	"Svalbard and Jan Mayen",
	"Sweden",
	"Switzerland",
	"Syrian Arab Republic",
	"Taiwan",
	"Tajikistan",
	"Tanzania, United Republic of",
	"Thailand",
	"Timor-Leste",
	"Togo",
	"Tokelau",
	"Tonga",
	"Trinidad and Tobago",
	"Tunisia",
	"Turkey",
	"Turkmenistan",
	"Turks and Caicos Islands",
	"Tuvalu",
	"Uganda",
	"Ukraine",
	"United Arab Emirates",
	"United Kingdom of Great Britain and Northern Ireland",
	"United States Minor Outlying Islands",
	"United States of America",
	"Uruguay",
	"Uzbekistan",
	"Vanuatu",
	"Venezuela (Bolivarian Republic of)",
	"Viet Nam",
	"Virgin Islands (British)",
	"Virgin Islands (U.S.)",
	"Wallis and Futuna",
	"Western Sahara",
	"Yemen",
	"Zambia",
	"Zimbabwe",
	"Åland Islands"
];

export const STATES = [
        "Ogun",
    "Lagos" ,
    "Abia" ,
       "Adamawa" ,
       "Akwa Ibom" ,
         "Anambra" ,
         "Bauchi" ,
         "Bayelsa" ,
         "Benue" ,
         "Borno" ,
         "Cross River" ,
         "Delta" ,
         "Ebonyi" ,
         "Edo" ,
         "Ekiti" ,
         "Enugu" ,
         "FCT" ,
         "Gombe" ,
         "Imo" ,
         "Jigawa" ,
         "Kaduna" ,
         "Kano" ,
         "Katsina" ,
         "Kebbi" ,
         "Kogi" ,
         "Kwara" ,
        
         "Nasarawa" ,
         "Niger" ,
       
         "Ondo" ,
         "Osun" ,
         "Oyo" ,
         "Plateau" ,
         "Rivers" ,
         "Sokoto" ,
         "Taraba" ,
         "Yobe" ,
         "Zamfara" 
       
  
]

export const BANKS = [
	
		"9 payment service Bank",
		"AB MICROFINANCE BANK",
		"ABBEY MORTGAGE BANK",
		"ABOVE ONLY MICROFINANCE BANK",
		"ABU MICROFINANCE BANK",
		"ACCESS BANK",
		"ACCESSMONEY",
		"ACCION MFB",
		"ADDOSSER MFBB",
		"ADEYEMI COLLEGE STAFF MICROFINANCE BANK",
		"ADVANS LA FAYETTE MFB",
		"AG MORTGAGE BANK PLC",
		"AGOSASA MICROFINANCE BANK",
		"AL-BARKAH MFB",
		"ALEKUN MICROFINANCE BANK",
		"ALERT MFB",
		"ALHAYAT MFB",
		"ALLWORKERS MFB",
		"ALPHAKAPITAL MFB",
		"AMJU MFB",
		"AMML MFB",
		"APEKS MICROFINANCE BANK",
		"APPLE  MICROFINANCE BANK",
		"ARISE MFB",
		"ASOSAVINGS",
		"ASSETS Microfinance Bank",
		"ASTRAPOLARIS MFB",
		"ATBU  Microfinance Bank",
		"AUCHI MICROFINANCE BANK",
		"Abucoop  Microfinance Bank",
		"Ada MFB",
		"Afekhafe MFB",
		"Afemai Microfinance Bank",
		"Alvana Microfinance Bank",
		"Amac Microfinance Bank",
		"Ampersand Microfinance bank",
		"Anchorage MFB",
		"Aniocha MFB",
		"Arca Payments",
		"Assets Matrix MFB",
		"Avuenegbe MFB",
		"BAINES CREDIT MFB",
		"BALOGUN GAMBARI MFB",
		"BAYERO MICROFINANCE BANK",
		"BC KASH MFB",
		"BETA-ACCESS YELLO",
		"BIPC MICROFINANCE BANK",
		"BOCTRUST MICROFINANCE BANK",
		"BOI MF Bank",
		"BOSAK MFB",
		"BOWEN MFB",
		"BRENT MORTGAGE BANK",
		"BRETHREN MICROFINANCE BANK",
		"BRIGHTWAY MFB",
		"BUBAYERO Microfinance Bank",
		"Balogun Fulani  Microfinance Bank",
		"Banex Microfinance Bank",
		"Benysta Microfinance Bank",
		"Bluewhales  Microfinance Bank",
		"Boji Boji Microfinance Bank",
		"Bonghe Microfinance Bank",
		"Borgu MFB",
		"Borno Renaissance Microfinance bank",
		"Boromu Microfinance bank",
		"Borstal Microfinance Bank",
		"Bridgeway Microfinance Bank",
		"Business Support Microfinance Bank",
		"CAPITALMETRIQ SWIFT MICROFINANCE BANK",
		"CARBON",
		"CARETAKER Microfinance Bank",
		"CASHCONNECT   MICROFINANCE BANK",
		"CELLULANT",
		"CEMCS MFB",
		"CHIKUM MICROFINANCE BANK",
		"CIT MICROFINANCE BANK",
		"CITI BANK",
		"COASTLINE MICROFINANCE BANK",
		"CONSUMER  MFB",
		"CONTEC GLOBAL",
		"COOP Mortgage Bank",
		"CORONATION",
		"COVENANT MFB",
		"CREDIT AFRIQUE MFB",
		"CROWDFORCE",
		"Calabar Microfinance Bank",
		"Capricorn Digital",
		"Capstone MF Bank",
		"Catland Microfinance Bank",
		"Chanelle Bank",
		"Changan RTS Microfinance Bank",
		"Chase Microfinance bank",
		"Cherish Microfinance Bank",
		"Chibueze Microfinance Bank",
		"Chukwunenye  Microfinance Bank",
		"Cintrust MFB",
		"Citizen Trust Microfinance Bank Ltd",
		"Cloverleaf  MFB",
		"CoalCamp Microfinance Bank",
		"Corestep MICROFINANCE BANK",
		"Crescent Microfinance bank",
		"CrossRiver  Microfinance Bank",
		"Crutech  Microfinance Bank",
		"DAYLIGHT MICROFINANCE BANK",
		"DIAMOND BANK",
		"Davodani  Microfinance Bank",
		"Delta Trust Mortgage bank",
		"E-BARCS MFB",
		"EAGLE FLIGHT MFB",
		"EBSU MICROFINANCE Bank",
		"ECOBANK BANK",
		"ECOBANK XPRESS ACCOUNT",
		"EDFIN MFB",
		"EK-Reliable Microfinance Bank",
		"EKONDO MFB",
		"EMERALDS MFB",
		"EMPIRETRUST MICROFINANCE BANK",
		"ESAN MFB",
		"ESO-E MICROFINANCE BANK",
		"ETRANZACT",
		"EVANGEL MFB",
		"EVERGREEN MICROFINANCE BANK",
		"EYOWO MICROFINANCE BANK",
		"FAIRMONEY",
		"FAME Microfinance Bank",
		"FAST Microfinance Bank",
		"FBNQUEST MERCHANT BANK",
		"FCMB",
		"FCMB EASY ACCOUNT",
		"FCMB MFB",
		"FCT MFB",
		"FEDERAL UNIVERSITY DUTSE  MICROFINANCE BANK",
		"FEDERALPOLY NASARAWAMFB",
		"FETS",
		"FFS MICROFINANCE BANK",
		"FIDELITY BANK",
		"FIDELITY MOBILE",
		"FIDFUND MFB",
		"FIMS MFB",
		"FINATRUST MICROFINANCE BANK",
		"FINEX MFB",
		"FIRST BANK OF NIGERIA",
		"FIRST GENERATION MORTGAGE BANK",
		"FIRST MULTIPLE MFB",
		"FIRST OPTION MFB",
		"FIRST ROYAL MICROFINANCE BANK",
		"FIRSTMONIE WALLET",
		"FIRSTTRUST MORGAGES LIMITED",
		"FORTISMOBILE",
		"FSDH",
		"FULL RANGE MFB",
		"FUTO MFB",
		"Federal Polytechnic Nekede Microfinance Bank",
		"Fedeth MFB",
		"Finca Microfinance Bank",
		"Firmus MICROFINANCE BANK",
		"First Heritage MFB",
		"Foresight Microfinance bank",
		"Fortress Microfinance Bank",
		"Futminna Microfinance Bank",
		"GASHUA MICROFINANCE BANK",
		"GATEWAY MORTGAGE BANK",
		"GLOBUS BANK",
		"GLORY MFB ",
		"GMB Microfinance Bank",
		"GOMONEY",
		"GOWANS MFB",
		"GREENBANK MFB",
		"GREENVILLE MICROFINANCE BANK",
		"GROOMING MICROFINANCE BANK",
		"GTBANK PLC",
		"GTI  Microfinance Bank",
		"Garki MFB",
		"GiGinya Microfinance Bank",
		"Giant Stride MFB",
		"Girei MFB",
		"Giwa Microfinance Bank",
		"Good Neighbours Microfinance Bank",
		"Goodnews Microfinance Bank",
		"Grant Microfinance Bank",
		"Greenwich Merchant Bank",
		"Gwong Microfinance bank",
		"HACKMAN MICROFINANCE BANK",
		"HAGGAI MORTGAGE BANK",
		"HALA MFB",
		"HASAL MFB",
		"HERITAGE",
		"Headway MFB",
		"Highland Microfinance Bank",
		"HomeBase Mortgage",
		"HopePSB",
		"IBETO  Microfinance Bank",
		"IBILE MICROFINANCE BANK",
		"IC GLOBALMicrofinance bank",
		"IKENNE MFB",
		"IKIRE MFB",
		"ILASAN MICROFINANCE BANK",
		"IMO MICROFINANCE BANK",
		"IMPERIAL HOMES MORTGAGE BANK",
		"INFINITY MFB",
		"INFINITY TRUST  MORTGAGE BANK",
		"INTELLIFIN",
		"IRL MICROFINANCE BANK",
		"ISALEOYO MICROFINANCE BANK",
		"Ibom fadama Microfinance Bank",
		"Illorin Microfinance Bank",
		"Ilora Microfinance Bank",
		"Imowo Microfinance Bank",
		"Insight Microfinance Bank",
		"Interland MFB",
		"Iperu Microfinance Bank",
		"Ishie  Microfinance Bank",
		"Isuofia MFB",
		"Izon Microfinance Bank",
		"JAIZ BANK",
		"JUBILEELIFE",
		"Jessefield Microfinance Bank",
		"KADPOLY MICROFINANCE BANK",
		"KCMB MFB",
		"KEGOW(CHAMSMOBILE)",
		"KEYSTONE BANK",
		"KONGAPAY",
		"KONTAGORA MFB",
		"KREDI MONEY MICROFINANCE BANK",
		"KUDA MICROFINANCE BANK",
		"Kingdom College  Microfinance Bank",
		"Koraypay",
		"Kwasu MF Bank",
		"LAPO MFB",
		"LAVENDER MICROFINANCE BANK",
		"LBIC MORTGAGE BANK",
		"LEGEND MICROFINANCE BANK",
		"LETSHEGO MICROFINANCE BANK",
		"LIVINGTRUST MORTGAGE BANK PLC",
		"LOVONUS MICROFINANCE BANK",
		"Landgold  Microfinance Bank",
		"Light Microfinance Bank",
		"Links  Microfinance Bank",
		"Lotus Bank",
		"M36",
		"MAINLAND MICROFINANCE BANK",
		"MAINSTREET MFB",
		"MALACHY MFB",
		"MANNY MICROFINANCE BANK",
		"MAYFAIR  MFB",
		"MAYFRESH MORTGAGE BANK",
		"MEGAPRAISE MICROFINANCE BANK",
		"MERIDIAN MFB",
		"MICROCRED MICROFINANCE BANK",
		"MICROVIS MICROFINANCE BANK",
		"MOLUSI MICROFINANCE BANK",
		"MONEYTRUST MFB",
		"MONIEPOINT MICROFINANCE BANK",
		"MUTUAL BENEFITS MFB",
		"MUTUAL TRUST MICROFINANCE BANK",
		"Maintrust MFB",
		"Maritime Microfinance Bank",
		"Mautech  Microfinance Bank",
		"Memphis Microfinance Bank",
		"Microsystems Investment and Development Limited",
		"Midland MFB",
		"MoMo PSB",
		"Monarch Microfinance Bank",
		"Money Master PSB",
		"Moyofade MF Bank",
		"Mozfin Microfinance Bank",
		"NARGATA MFB",
		"NAVY MICROFINANCE BANK",
		"NDIORAH MFB",
		"NEPTUNE MICROFINANCE BANK",
		"NEW GOLDEN PASTURES MICROFINANCE BANK",
		"NEWDAWN MICROFINANCE BANK",
		"NIBSSUSSD PAYMENTS",
		"NIRSAL NATIONAL MICROFINANCE BANK",
		"NNEW WOMEN MFB",
		"NOVA MB",
		"NPF MICROFINANCE BANK",
		"NUTURE MFB",
		"Nasarawa Microfinance bank",
		"NetApps Technology Limited",
		"Nice Microfinance Bank",
		"Nigeria PrisonsMicrofinance bank",
		"Nomba financial services Limited",
		"Nsuk  Microfinance Bank",
		"Numo Microfinance bank",
		"Nwannegadi MFB",
		"OCHE MFB",
		"OHAFIA MFB",
		"OKPOGA MFB",
		"OLABISI ONABANJO UNIVERSITY MICROFINANCE BANK",
		"OLOFIN OWENA Microfinance Bank",
		"OLUCHUKWU Microfinance Bank",
		"OLUYOLE MICROFINANCE BANK",
		"OMIYE MFB",
		"OPAY",
		"Oakland Microfinance Bank",
		"Ojokoro Mfb",
		"Olowolagba Microfinance Bank",
		"Oraukwu  Microfinance Bank",
		"Oscotech MFB",
		"Ospoly Microfinance Bank",
		"PAGA",
		"PAGE FINANCIALS",
		"PALMPAY",
		"PARKWAY-READYCASH",
		"PARRALEX",
		"PATRICK GOLD",
		"PECAN TRUST MICROFINANCE BANK",
		"PENNYWISE MICROFINANCE BANK",
		"PERSONAL TRUST MICROFINANCE BANK",
		"PETRA MICROFINANCE BANK",
		"PILLAR MFB",
		"PLATINUM MORTGAGE BANK",
		"POLARIS BANK",
		"POLYUWANNA MFB",
		"PRESTIGE MICROFINANCE BANK",
		"PROVIDUS BANK ",
		"PURPLEMONEY MFB",
		"Palmcoast Microfinance bank",
		"Parallex Bank",
		"Parkway MF Bank",
		"Peace Microfinance Bank",
		"Preeminent Microfinance Bank",
		"Premium Trust bank",
		"Prisco  Microfinance Bank",
		"Pristine Divitis Microfinance Bank",
		"Projects Microfinance bank",
		"QUICKFUND MICROFINANCE BANK",
		"RAHAMA MFB",
		"RAND MERCHANT BANK",
		"REFUGE MORTGAGE BANK",
		"REGENT MFB",
		"RELIANCE MFB",
		"RENMONEY MICROFINANCE BANK",
		"REPHIDIM MICROFINANCE BANK",
		"RICHWAY MFB",
		"RIGO Microfinance Bank",
		"RIMA Microfinance Bank",
		"ROYAL EXCHANGE MICROFINANCE BANK",
		"Radalpha Microfinance bank",
		"Rehoboth Microfinance Bank",
		"Resident Fintech Limited",
		"Rima Growth pathway Microfinance Bank ",
		"SAFETRUST",
		"SAGAMU MICROFINANCE BANK",
		"SEAP Microfinance Bank",
		"SEED CAPITAL MICROFINANCE BANK",
		"SEEDVEST MICROFINANCE BANK",
		"SLS  MF Bank",
		"SPARKLE MICROFINANCE BANK",
		"SPAY business",
		"STANBIC IBTC @EASE WALLET",
		"STANBICIBTC BANK",
		"STANDARDCHARTERED",
		"STANFORD MFB",
		"STB Mortgage Bank",
		"STELLAS MICROFINANCE BANK",
		"STERLING BANK",
		"SULSAP MFB",
		"SUNTRUST BANK",
		"SafeHaven Microfinance Bank",
		"Safegate Microfinance Bank",
		"Shalom Microfinance Bank",
		"Shepherd Trust Microfinance Bank",
		"SmartCash Payment Service bank",
		"Solid Allianze MFB",
		"Solidrock Microfinance bank",
		"Spectrum Microfinance Bank",
		"Standard MFB",
		"Stockcorp  Microfinance Bank",
		"Sunbeam Microfinance Bank",
		"Support MF Bank",
		"TAGPAY",
		"TAJ BANK",
		"TCF",
		"TEASYMOBILE",
		"TF MICROFINANCE BANK",
		"TITAN TRUST BANK",
		"TITAN-PAYSTACK MICROFINANCE BANK",
		"TRIDENT MICROFINANCE BANK",
		"TRUST MFB",
		"TRUSTBANC J6 MICROFINANCE BANK LIMITED",
		"TRUSTBOND",
		"TRUSTFUND MICROFINANCE BANK",
		"TajWallet",
		"Tangerine Money MFB",
		"Triple A Microfinance bank",
		"U AND C MFB",
		"UNAAB MFB",
		"UNIBEN MICROFINANCE BANK",
		"UNICAL MFB",
		"UNIIBADAN Microfinance Bank",
		"UNION BANK",
		"UNITED BANK FOR AFRICA",
		"UNITY BANK",
		"UNN MFB",
		"Uhuru Microfinance bank",
		"Umuchinemere Procredit Microfinance Bank",
		"Umunnachi Microfinance Bank",
		"UniUyo Microfinance Bank",
		"Unilag  Microfinance Bank",
		"Unimaid Microfinance Bank",
		"Uzondu MF Bank",
		"VFD MFB",
		"VIRTUE MFB",
		"VISA MICROFINANCE BANK",
		"VTNetwork",
		"Vas2nets Limited",
		"Verdant Microfinance Bank",
		"WEMA BANK",
		"WETLAND MFB",
		"WinView Bank",
		"XSLNCE MICROFINANCE BANK",
		"YCT Microfinance Bank",
		"YES MFB",
		"Yello Digital financial services",
		"Yobe MFB",
		"ZENITH BANK PLC",
		"ZWallet",
		"Zikora Microfinance bank",
		"Unilorin Microfinance Bank"
	
]