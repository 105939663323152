import React, { useState, useEffect } from 'react';
import newClass from './NewSignup.module.css'
import NewLogo from "../../assets/NewSignupImg/NewSignupLogo.svg"
import Form from 'react-bootstrap/Form';
import promix from "../../assets/NewSignupImg/promiximg.png"
import { NavLink, Link, useNavigate, useLocation } from "react-router-dom";
import { Button, Spinner } from "react-bootstrap";
import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import crossedEyeIcon from '../../assets/promix/eye-slash.png';
import { BASE_URL } from "../../Pages/api/api";
import Swal from 'sweetalert2';


export default function NewSignup() {
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [country, setCountry] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhoneNo] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleNavlog = () => {
    navigate('/login');
  };

  const handleSignup = async () => {
    setLoading(true);
    try {

      // console.log('Sending data to API:', {
      //   first_name: name,
      //   last_name: lastName,
      //   company_name: companyName,
      //   country: country,
      //   email: email,
      //   phone_no: phone
      // });

      const response = await axios.post(`${BASE_URL}/register`, {
        first_name: name,
        last_name: lastName,
        company_name: companyName,
        country: country,
        email: email,
        phone_no: phone
      });

      console.log('API response:', response);
      const result = response.data?.data?.user?.name;
      const addresses = response.data?.data?.user?.company?.address;
      const phones = response.data?.data?.user?.company?.phone_number;
      const emails = response.data?.data?.user?.company?.email;
      const resultx = response.data?.data?.user?.email;
      const results = response.data?.data?.token;
      const permit = response.data?.data?.permissions;
      const continent = response.data?.data?.user?.continent?.description;
      const region = response.data?.data?.user?.region?.description;
      const province = response.data?.data?.user?.province?.description;
      const isAdmin = response.data?.data?.user?.type_name;
      const companyame = response.data?.data?.company_name;
      const validity = response.data?.data?.user?.company?.expiry_date;
      const companyId = response.data?.data?.user?.company?.id;

      console.log(response);
      await AsyncStorage.setItem('modules', JSON.stringify(permit));
      await AsyncStorage.setItem('admin', isAdmin);
      await AsyncStorage.setItem('companyName', companyName);
      await AsyncStorage.setItem('tobi', result);
      await AsyncStorage.setItem('userToken', results);
      await AsyncStorage.setItem('userEmail', resultx);
      await AsyncStorage.setItem('companyEmail', emails);
      await AsyncStorage.setItem('companyPhone', phones);
      await AsyncStorage.setItem('companyAddress', addresses);
      await AsyncStorage.setItem('companyId', companyId);
      await AsyncStorage.setItem('permits', permit);
      await AsyncStorage.setItem('continent', continent);
      await AsyncStorage.setItem('region', region);
      await AsyncStorage.setItem('province', province);
      await AsyncStorage.setItem('validity', validity);

      // toast.success("Thank you for registering, kindly check your email for the login password");

      if (response.status === 201) {
        Swal.fire({
          icon: 'success',
          title: 'Successful',
          text: 'Signup successful! Kindly check your email for the login password',
          // text: response.data.message,
        });
        // toast.success('Signup successful! Kindly check your email for the login password', {
        //     autoClose: 5000
        // });
    
        setTimeout(() => {
            navigate('/login');
        }, 5000);
      }
      else {
        throw new Error('Signup failed');
      }

    } catch (error) {
      console.error('Error during signup:', error);
      let errorMessage = error.response?.data?.message || 'An error occurred';
      if (error.message === 'Network Error') {
        errorMessage = 'Connection error. Please check your internet connection.';
      }
      toast.error(JSON.stringify(error.response.data.message));
    } finally {
      setLoading(false);
    }
  };


  return (
    <div className={`${newClass.newSignupBody} ${newClass.signInNewSignupBody}`}>
      <ToastContainer />
        <div className={newClass.newSignContainer}>
            <div className={newClass.newSignHeader}>
              <div className={newClass.logo}>
                 <NavLink to={'/'} > <img src={NewLogo} className={newClass.img}/> </NavLink>
              </div>
            </div>

            <div className={newClass.newSignSection}>

              <div className={newClass.newSignEndToEnd}>
                <h1>
                  End-to-End Software to Streamline your Accounting processes
                </h1>
                <div className={newClass.newSignObject}>
                    <img src={promix} alt="promix"/>
                </div>
              </div>

              <div className={newClass.newSignFormSection}>
                <div className={newClass.formHead}>
                  <h1>Sign Up</h1>
                  <p>Enter your Information and let’s get you started</p>
                </div>
                <div className={newClass.mainForm}>
                  <Form>
                    <div className={newClass.flexSect}>
                      <Form.Group>
                        <Form.Label>First Name</Form.Label>
                        <Form.Control
                        type="name" 
                        name="name" 
                        className={newClass.inputs} 
                        placeholder="Kindly enter your first name" 
                        onChange={(e) => setName(e.target.value)}                      
                        />
                      </Form.Group>

                      <Form.Group>
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control 
                        className={newClass.inputs} 
                        type="name" 
                        placeholder="Kindly enter your last name"
                        name="last_name"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)} 
                        />
                      </Form.Group>
                    </div>
                    <div className={newClass.flexSect}>
                      <Form.Group>
                        <Form.Label>Work email</Form.Label>
                        <Form.Control 
                        className={newClass.inputs} 
                        type="email"
                        placeholder="Kindly enter your email address" 
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      </Form.Group>

                      <Form.Group>
                        <Form.Label>Phone number</Form.Label>
                        <Form.Control 
                        className={newClass.inputs} 
                        type="number" 
                        name="phone_no"
                        placeholder="Kindly enter your phone number" 
                        onChange={(e) => setPhoneNo(e.target.value)}
                      />
                      </Form.Group>
                    </div>

                    <Form.Group>
                      <Form.Label>Company name</Form.Label>
                      <Form.Control 
                      className={newClass.inputs} 
                      placeholder="Company Name" 
                      name="company_name"
                      value={companyName}
                      onChange={(e) => setCompanyName(e.target.value)}
                    />
                    </Form.Group>

                    <Form.Group>
                      <Form.Label>Country</Form.Label>
                      <Form.Control 
                      className={newClass.inputs} 
                      placeholder="Country" 
                      name="company_name"
                      value={country}
                      onChange={(e) => setCountry(e.target.value)}
                    />
                      {/* <Form.Select aria-label="Default select example" className={newClass.inputs}>
                      <option>Nigeria</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </Form.Select> */}
                    </Form.Group>

                    <Form.Group>
                      <Form.Label>Which of our products are you interested in?</Form.Label>
                      <Form.Control className={newClass.inputs} type="text" placeholder="Accounting" />
                    </Form.Group>
                    <div className={newClass.newbtns}>
                    <Button  onClick={handleSignup} variant='light'>
                      {loading ? (
                            <>
                                <Spinner size='sm' />
                                <span style={{ marginLeft: '5px' }}>Signing up...</span>
                            </>
                        ) : (
                            "Sign up"
                        )}                        
                      </Button>
                    </div>
                    <div className={newClass.newbtns}>
                      <div>
                            Already have an Account? <a className={newClass.loginlinkclick} onClick={handleNavlog}>Login!</a>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
        </div>
    </div>
  )
}