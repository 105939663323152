import React, { useState, useEffect } from 'react';
// import "../assets/plugins/bootstrap/css/bootstrap.min.css";
// import "../assets/plugins/metisMenu/metisMenu.min.css";
// import "../assets/plugins/fontawesome/css/all.min.css";
// import "../assets/plugins/typicons/src/typicons.min.css";
// import "../assets/plugins/themify-icons/themify-icons.min.css";
// import "../assets/plugins/datatables/dataTables.bootstrap4.min.css";
// import "../style.css";
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { Navbar, Nav, NavDropdown, Button, Modal, Form, Spinner, Badge } from 'react-bootstrap';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Swal from 'sweetalert2';
import classes from '../../Manage Cooperatives/Manage Members/ManageMember.module.css'
import MainDashboard from '../../Main Dashboard/MainDashoard';
import { BASE_URL } from '../../api/api';
import { toast } from 'react-toastify';
import Select from 'react-select';
import CurrencyInput from 'react-currency-input-field';
import Arrow from '../../../assets/promix/dArrow-down.svg'
import PaymentDash from '../../Payment Dashboard/PaymentDash';



function PendingPaymentFinal() {
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [bearer, setBearer] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
const navigate = useNavigate();
  const handleClose = () => setShow(false);
  const [sICode, setSICode] = useState('');
  const handleClose1 = () => setShow1(false);
  const handleShow = () => setShow(true);
  const handleShow1 = () => setShow1(true);
  const [selectedBank, setSelectedBank] = useState('');
  const [eyeClicked, setEyeClicked] = useState(false);
  const [trashClicked, setTrashClicked] = useState(false);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [chequeLoading, setChequeLoading] = useState(false);
  const [fullName, setFullName] = useState("");
  const [fullName1, setFullName1] = useState("");
  const [email, setEmail] = useState("");
  const [email1, setEmail1] = useState("");
  const [phone1, setPhone1] = useState("");
  const [phone, setPhone] = useState("");
  const [roles, setRoles] = useState([]);
  const [voucherData, setVoucherData] = useState([]);
  const [selectedRole, setSelectedRole] = useState('');
  const [selectedRoleId, setSelectedRoleId] = useState(null);
  const [entriesPerPage, setEntriesPerPage] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [user, setUser] = useState("");
  const [totalAmount, setTotalAmount] = useState("");
  const [banks, setBanks] = useState([]);
  const [bankkk, setBankkk] = useState([]);
  const [totalEntries, setTotalEntries] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const location = useLocation();
  const voucher = location.state || {};
  console.log(voucher?.voucher);

  


  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem('userToken');
      const value1 = await AsyncStorage.getItem('tobi');

      if (value !== null) {
        setBearer(value);
      }
      if (value1 !== null) {
        setUser(value1);
      }
    } catch (e) {
      alert('Failed to fetch the input from storage');
    }
  };    

useEffect(() => {
readData();
}, []);

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${bearer}`
  };





  function formatDate(dateString) {
    const date = new Date(dateString);
    const formattedDate = `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())} ${padZero(date.getHours())}:${padZero(date.getMinutes())} ${date.getHours() >= 12 ? 'PM' : 'AM'}`;
    return formattedDate;
  }

  function padZero(num) {
    return num < 10 ? `0${num}` : num;
  }


  

 

 
 

  // const filteredData = tableData.filter(item => item.description.toLowerCase().includes(searchTerm.toLowerCase()));

  // const totalPages = Math.ceil(filteredData.length / entriesPerPage);

  const handlePrevPage = () => {
    setCurrentPage(Math.max(currentPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage(Math.min(currentPage + 1, totalPages));
  };

  // const totalEntries = filteredData.length;
  // const startIndexx = (currentPage - 1) * entriesPerPage + 1;
  // const endIndexx = Math.min(startIndexx + entriesPerPage - 1, totalEntries);
  // const displayedData = filteredData.slice(startIndexx - 1, endIndexx);

  // const totalEntries = filteredData.length;
  const startIndexx = (currentPage - 1) * entriesPerPage + 1;
  const endIndexx = Math.min(startIndexx + entriesPerPage - 1, totalEntries);
  // const displayedData = filteredData.slice(startIndexx - 1, endIndexx);


  const handleRoleChange = (e) => {
    setSelectedRole(e.target.value);
  };

  const handleCreate = () => {
    if (selectedRows.length === 0) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please select at least a row to generate a payment voucher!',
      });
      return;
    }
    setShow(true);
    setTotalAmount(calculateTotalAmount(selectedRows));
  };
  
  const calculateTotalAmount = (rows) => {
    const total = rows.reduce((total, row) => {
      const amount = parseFloat(row.amount);
      return total + (isNaN(amount) ? 0 : amount);
    }, 0);
    
    return total.toFixed(2); // Returns the total amount as a string with 2 decimal places
  };
  



  const handlePrintInvoice = (id) => {
    const selectedVoucher = tableData.find(item => item.id === id);
  
  
    navigate('/print_payment', { state: { selectedVoucher } });
  };

  const handleViewVoucher = (id) => {
    const selectedVoucher = tableData.find(item => item.id === id);
  
  
    navigate('/payment/cheque', { state: { selectedVoucher } });
  };

  const handleCheckboxChange = (row) => {
    const selectedIndex = selectedRows.findIndex(item => item.id === row.id);
    let updatedSelectedRows = [];
  
    if (selectedIndex === -1) {
      updatedSelectedRows = [...selectedRows, row];
    } else {
      updatedSelectedRows = selectedRows.filter(item => item.id !== row.id);
    }
  
    setSelectedRows(updatedSelectedRows);
    setSelectAll(updatedSelectedRows.length === tableData.length);
    // console.log(updatedSelectedRows);
  };
  
  const handleSelectAllChange = (event) => {
    const isChecked = event.target.checked;
    setSelectAll(isChecked);
    setSelectedRows(isChecked ? [...tableData] : []);
    // console.log(isChecked ? [...tableData] : []);
  };

  const fetchInvoiceCode = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${BASE_URL}/generate-sales-invoice-code`,
        {
          headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearer}`
          }
        }
      );
      const resultss = response.data?.data;
    //   console.log(resultss);
      setSICode(resultss);
        // console.log(invoiceData)
    //   console.log(results, "NI");
    } catch (error) {
      const errorStatus = error.response.data.message;
      console.error(errorStatus);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (bearer) {
        fetchInvoiceCode();
    }
  }, [bearer]);

  const handleValueChange = (value, name, values) => {
    setTotalAmount(value);
  };

  const handleGoBack = () => {
    navigate(-1);
  }
  

  return (

    <div>

      <div className="wrapper">
        {/* <!-- Sidebar  --> */}


        {/* <!-- Page Content  --> */}
        <div className="content-wrapper">
          <div className="main-content">

          <PaymentDash/>
            <div className='newBody'>
            <div className={classes.newWidth}>

            <div className={classes.topPadding}>
                    <div className={`${classes.formSecCont}`}>
                        <div className={classes.formSectionHeader}>
                            <h3>Pending Payment Voucher</h3>
                            {/* <small>Create and view your loan accounts...</small> */}
                        </div>
                        <div className={classes.formSectionHeader}>
                            <h3 style={{color:'#2D995F'}}>{user.toLocaleUpperCase()}</h3>
                        </div>
                    </div>

                    {/* <div className={classes.analysis} style={{marginBottom:"30px"}}>
                        <div className={classes.analysisCont}>
                            <p style={{paddingBottom:'5px'}}>TOTAL INCOME</p>
                            <h5>N0.00</h5>
                            
                        </div>
                        <div className={classes.analysisCont}>
                            <p style={{paddingBottom:'5px'}}>TOTAL LODGE</p>
                            <h5>N0.00</h5>
                            
                        </div>
                        <div className={classes.analysisCont}>
                            <p style={{paddingBottom:'5px'}}>TOTAL OUTSTANDING</p>
                            <h5>N0.00</h5>
                            
                        </div>
                    </div> */}
            </div>

            <div style={{backgroundColor:'white', padding:'10px 20px'}}>
              {/* <!--Content Header (Page header)--> */}
              <div className="content-header row align-items-center m-0">
              {/* {(isAdmin || permittedHeaders.includes('create-savings-account')) && ( */}
                {/* <nav aria-label="breadcrumb" className="col-sm-4 order-sm-last mb-3 mb-sm-0 p-0 ">
                  <div
                    style={{
                      marginTop: 20,
                      marginBottom: 20,
                      justifyContent: "flex-end",
                      display: "flex",
                      marginLeft: "auto",
                    }}
                    className={classes.actionBtns}
                  >
                    <Button variant="success" onClick={handleCreate}>
                      Create New Accounts
                    </Button>
                  </div>

                </nav> */}
                <nav aria-label="breadcrumb" className="col-sm-4 order-sm-last mb-3 mb-sm-0 p-0 ">
                <div
                  style={{
                    marginTop: 20,
                    marginBottom: 20,
                    justifyContent: "flex-end",
                    display: "flex",
                    marginLeft: "auto",
                    gap: 10
                  }}
                >
                  <Button className={classes.btn2} variant="secondary" onClick={handleGoBack}>
                  Back
                  </Button>
                  {/* <Button variant="success" >
                    Generate Cheque
                  </Button> */}
                </div>

              </nav>
              {/* )} */}
              
                <div className="col-sm-8 header-title p-0">
                  <div className="media">
                    {/* <div className="header-icon text-success mr-3"><i className=""><img src={favicon} className={classes.favshi} alt="favicon" /></i></div> */}
                    <div className="media-body">
                      {/* <h4 className="font-weight-bold">Savings Account</h4> */}
                      {/* <small>Create and view your Savings Accounts...</small> */}
                    </div>
                  </div>
                </div>
              </div>
           
              {/* <!--/.Content Header (Page header)--> */}
              <div className="body-content">
                
              
                
                <div className="row">
                  
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="table-resposive">
                          <div className="d-flex justify-content-between align-items-center" style={{ padding: '20px 0 0 0', marginBottom: 20 }}>
                            <div className={classes.greenbtn} style={{ display: 'flex', }}>
                              <div>
                                <button>Copy</button>
                                <button>Excel</button>
                                <button>PDF</button>
                                <button className={classes.diffbtn}>Column visibility</button>
                              </div>
                              <div>
                                <label className="d-flex justify-content-start align-items-center">
                                  Show
                                  <select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" className="custom-select custom-select-sm form-control form-control-sm" value={entriesPerPage}
                                    onChange={(e) => {
                                      setEntriesPerPage(parseInt(e.target.value));
                                      setCurrentPage(1);
                                    }}>
                                    <option value={10}>10</option>
                                    <option value={25}>25</option>
                                    <option value={50}>50</option>
                                    <option value={100}>100</option>
                                  </select>
                                  entries
                                </label>
                              </div>
                            </div>
                            <div className="text-right modal-effect ">
                              <div id="DataTables_Table_0_filter" className="dataTables_filter">
                                <div className="d-flex justify-content-start align-items-center">
                                  <div className="mr-2">Search:</div>
                                  <input
                                    type="search"
                                    value={searchTerm}
                                    className="form-control form-control-sm"
                                    placeholder=""
                                    aria-controls="DataTables_Table_0"
                                    onChange={(e) => {
                                      setSearchTerm(e.target.value);
                                      // setCurrentPage(1);
                                    }}
                                  />
                                </div>

                              </div>
                            </div>
                          </div>

                          <div className={classes.table}>
                            {/* {isLoading ? (
                              <p>Fetching Vouchers...</p>
                            ) : ( */}
                              <div className="table-responsive">
                              {Array.isArray(voucher?.voucher) && voucher?.voucher.length > 0 ? (
                                <table className="table display table-bordered table-striped table-hover bg-white m-0 card-table">

                                  <thead style={{ whiteSpace: 'nowrap' }}>
                                  <tr>
                                      
                                      <th>S/N</th>
                                      <th>Date</th>
                                      <th>Description</th>
                                      <th>PV Number</th>
                                      <th>Supplier</th>
                                      <th>Total Amount</th>
                                      <th>Payment Bank</th>
                                      <th>Action</th>
                                    </tr>
                                  </thead>
                                  <tbody style={{ whiteSpace: 'nowrap' }}>
                                  {voucher?.voucher?.map((item, index) => (
                                    <tr key={index}>
                                      <td>{index + 1}</td>
                                        <td>{item.voucher_date}</td>
                                      <td>{item.payment_description}</td>
                                      <td>{item.pv_number}</td>
                                      <td>{item.supplier?.name}</td>
                                       <td style={{ textAlign: "right", padding: 50 }}>{parseFloat(item.amount).toLocaleString('en-US', {
                                          minimumIntegerDigits: 1,
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2
                                        })}</td>
                                        <td>{item.payment_bank?.bank_name}</td>
                                        <td style={{ textAlign: 'left' }}>
            {voucher?.voucher.length === 1 ? (
              <div
                className="btn btn-success-soft btn-sm mr-1"
                onClick={() => navigate('/payment/cheque', { state: { vouchers: voucher.voucher } })}
              >
                <i
                  style={{
                    color: '#008a4b',
                    backgroundColor: '#28a7451a',
                    padding: 2,
                    borderColor: '#28a7454d',
                    borderRadius: 5,
                    fontSize: 12,
                  }}
                >
                  Print Cheque
                </i>
              </div>
            ) : null}
          </td>
        </tr>
      ))}
      {voucher?.voucher.length > 1 && (
        <tr>
          <td colSpan="8" style={{ textAlign: 'center' }}>
            <Button
              variant='success'
              onClick={() => navigate('/payment/instruction', { state: { vouchers: voucher?.voucher } })}
            >
              <i
                style={{
                  color: '#fff',
                  // backgroundColor: '#28a7451a',
                  padding: 2,
                  // borderColor: '#28a7454d',
                  borderRadius: 5,
                  fontSize: 12,
                }}
              >
                Generate Payment Instruction
              </i>
            </Button>
          </td>
          </tr>
      )}

</tbody>
  </table>
) : (
  <p>No data available</p>
)}
                              </div>
                            {/* )} */}
                          </div>
                          <div className={classes.endded}>
                            <p>
                              Showing {startIndexx} to {endIndexx} of {totalEntries} entries
                            </p>
                            <div style={{ display: 'flex' }}>
                              <button
                                style={{ border: 'none', backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'nunito', color: '#000', marginRight: 10, cursor: "pointer" }}
                                onClick={handlePrevPage}
                                disabled={currentPage === 1}
                              >
                                Previous
                              </button>
                              {[...Array(totalPages)].map((_, page) => {
                                // Show only 5 pages or less if available
                                if (page < 5 || page === currentPage - 1 || page === totalPages - 1) {
                                  return (
                                    <button
                                      key={page + 1}
                                      style={{
                                        marginLeft: '0.4rem',
                                        marginRight: '0.4rem',
                                        fontSize: '14px',
                                        fontFamily: 'nunito',
                                        fontWeight: 400,
                                        color: page + 1 === currentPage ? '#ffffff' : '#000',
                                        backgroundColor: page + 1 === currentPage ? '#28a745' : 'gainsboro',
                                        height: '2.5rem',
                                        borderRadius: '89px',
                                        padding: '0.5rem',
                                        border: 'none',
                                        width: '40px',
                                        cursor: "pointer"
                                      }}
                                      onClick={() => setCurrentPage(page + 1)}
                                    >
                                      {page + 1}
                                    </button>
                                  );
                                }
                                return null;
                              })}
                              <button
                                style={{ cursor: "pointer", border: 'none', backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'nunito', color: '#000', marginLeft: 10 }}
                                onClick={handleNextPage}
                                disabled={currentPage === totalPages}
                              >
                                Next
                              </button>
                            </div>
                          </div>

                         
                          

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!--/.body content--> */}
            </div>
          </div>
          {/* <!--/.main content--> */}
          </div>
          </div>
          {/* <InfoFooter /> */}
          {/* <!--/.footer content--> */}
          <div className="overlay"></div>
        </div>
        {/* <!--/.wrapper--> */}


      </div>
    </div>

  );
}

export default PendingPaymentFinal;