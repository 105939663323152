import React, { useState, useEffect } from 'react';
import classes from './CreateUser.module.css';
// import RegLogo from '../../Images/RegistrationLogo.svg'
import { Spinner, Badge, Button, Modal, Form, Row, Col } from 'react-bootstrap';
// import Folder from '../../Images/folder-2.svg';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
// import ArrowLogo from '../../Images/arrow-left.svg';
// import LoanImage from '../../Images/loan bg.svg';
import MainDashoard from '../Main Dashboard/MainDashoard';
// import Ready from '../../Images/nothing.svg'
// import Ready1 from '../../Images/review.svg';
import { Link, useNavigate, useParams } from 'react-router-dom'
import Table from 'react-bootstrap/Table';
import { BASE_URL } from '../api/api';
import Swal from 'sweetalert2';
import ToggleSlider from './ToggleSlider';
import CoopDashboard from '../Cooperative Dashboard/CoopDashboard';
import ReportDashboard from '../ReportDashboard/ReportDashboard';
import { toast, ToastContainer } from 'react-toastify';
import LatestDash from '../LatestDashboard/LatestDash';


export default function CreateUser() {
  const [role, setRole] = useState('');
  const [loading, setLoading] = useState(false);
  const [createLoading, setCreateLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [permLoading, setPermLoading] = useState(false);
  const [bearer, setBearer] = useState('');
  const [user, setUser] = useState('');
  const navigate = useNavigate();
  const [permissions, setPermissions] = useState([]);
  const [toggleStates, setToggleStates] = useState({});
  const [toggleStates1, setToggleStates1] = useState({});
  const [perm, setPerm] = useState([]);
  const [permId, setPermId] = useState([]);
  const [checkAll, setCheckAll] = useState(false);
  const [roleLoading, setRoleLoading] = useState(false);
  const [role1, setRole1] = useState("");
  const [eyeClicked, setEyeClicked] = useState(false);
  const [selectedRoleId, setSelectedRoleId] = useState(null);
  const [fullName, setFullName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [selectType, setSelectType] = useState('');
  const [userType, setUserType] = useState([]);
  const [continents, setContinents] = useState([]);
  const [tableData4, setTableData4] = useState([]);
  const [tableData5, setTableData5] = useState([]);
  const [selectedContinent, setSelectedContinent] = useState("");
  const [selectedProvince, setSelectedProvince] = useState("");
  const [selectedRegion, setSelectedRegion] = useState("");
  const [contLoading, setContLoading] = useState(false);
  const [subContloading, setSubContLoading] = useState(false);
  const [subRegloading, setSubRegLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [admin, setAdmin] = useState('');

  const handleTypeChange = (e) => setSelectType(e.target.value);

  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem('userToken');
      const value1 = await AsyncStorage.getItem('tobi');
      const adminn = await AsyncStorage.getItem('admin');

      if (value !== null) {
        setBearer(value);
      }
      if (adminn !== null) {
        setAdmin(adminn);
    }
      if (value1 !== null) {
        setUser(value1);
      }
    } catch (e) {
      alert('Failed to fetch the input from storage');
    }
  };

  useEffect(() => {
    readData();
  }, []);

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${bearer}`
  };

  const fetchPermission = async () => {
    setPermLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/role/permissions`, { headers });
      const data = response.data?.data;
      const permissionId = data.map(item => item.id);
      setPermId(permissionId);
      // console.log(data);
      const initialToggleStates = Object.fromEntries(permissions.map(id => [id, false]));

      // const initialToggleStates = false; 


      setPermissions(data);
      setToggleStates(initialToggleStates);

    } catch (error) {
      const errorStatus = error.response?.data?.message;
      console.error(errorStatus);
      setPermissions([]);
    } finally {
      setPermLoading(false);
    }
  };

  useEffect(() => {
    if (bearer) {
      fetchPermission();
    }
  }, [bearer]);



  const handleToggleChange = (itemId) => {
    setToggleStates(prevToggleStates => ({
      ...prevToggleStates,
      [itemId]: !prevToggleStates[itemId],
    }));
  };

  const handleCheckAllToggle = () => {
    const checkAllValue = !checkAll;
    setCheckAll(checkAllValue);

    // Set all toggle states to the determined value
    const updatedToggleStates = Object.fromEntries(permId.map(id => [id, checkAllValue]));
    setToggleStates(updatedToggleStates);
  };

  const handleToggleChange1 = (itemId) => {
    setToggleStates1((prevToggleStates) => ({
      ...prevToggleStates,
      [itemId]: !prevToggleStates[itemId],
    }));
  };





  const goBack = () => {
    navigate(-1);
  }






  const createRole = async () => {
    setRoleLoading(true);

    try {
      const selectedToggle = Object.entries(toggleStates)
        .filter(([_, value]) => value)
        .map(([key, _]) => parseInt(key));

      const response = await axios.post(
        `${BASE_URL}/role/create`,
        {
          name: role,
          permission: selectedToggle

        },
        { headers }
      );



      navigate(-1);

      toast.success(response.data.message)


    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        toast.error(errorMessage);
        console.log(error);
      }

    } finally {
      setRoleLoading(false);
    }
  };


  const handleContinentChange = (event) => {
    setSelectedContinent(event.target.value);
};

const handleRegionChange = (event) => {
  setSelectedRegion(event.target.value);
};

const handleProvinceChange = (event) => {
  setSelectedProvince(event.target.value);
};


const createUser = async () => {
  setCreateLoading(true);
  try {
    const selectedToggle = permissions
      .filter((item) => toggleStates[item.id])
      .map((item) => item.name);

      console.log(fullName, email, phoneNumber, selectType, selectedToggle);

    const response = await axios.post(
      `${BASE_URL}/users/create-new`,
      {
        name: fullName,
        email: email,
        phone_no: phoneNumber,
        type: selectType,
        module: selectedToggle,
        
      },
      { headers }
    );
    console.log(response)
    
    navigate(-1);
    setFullName('');
    setPhoneNumber('');
    setEmail('');
    setSelectType('');
    // return
    Swal.fire({
      icon: 'success',
      title: 'Success',
      text: response.data.message,
    });
  // toast.success(response.data.message);
    console.log(response.data);

  } catch (error) {
    let errorMessage = 'An error occurred. Please try again.';
    if (error.response && error.response.data && error.response.data.message) {
      if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
      } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
      } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
          // toast.error(JSON.stringify(error.response.data.data));
        }
        Swal.fire({
          icon: 'error',
          title: 'Failed',
          text: JSON.stringify(error.response.data.data),
        });
      console.log(error);
  }
  } finally {
    setCreateLoading(false);
  }
};


  return (
    <div>
      <ToastContainer />
    <LatestDash />
    <div className={classes.finishedbodyCont}>
      
    <h3 className={classes.productText}>Create User</h3>
      <div className={classes.mainform}>
        <div className={classes.loandgrantcards}>
          <div className={classes.loanContainer}>
            <div className={classes.loanResponsive}>
              <div className={classes.mainForm}>
                <Form>
                  <Row className="mb-3">
                    <Col md={6}>
                      <Form.Group controlId="formName">
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                          type="text"
                          value={fullName}
                          onChange={(e) => setFullName(e.target.value)}
                          placeholder="Enter Full name"
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group controlId="formPhoneNumber">
                        <Form.Label>Phone Number</Form.Label>
                        <Form.Control
                          type="text"
                          value={phoneNumber}
                          onChange={(e) => setPhoneNumber(e.target.value)}
                          placeholder="Enter Phone Number"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col md={6}>
                      <Form.Group controlId="formEmail">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          type="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          placeholder="Enter Email"
                        />
                      </Form.Group>
                    </Col>
                    {/* {admin === "Super Admin" && (
     <>
                    <Col md={6}>
                      <Form.Group controlId="formSelectType">
                        <Form.Label>Select Type</Form.Label>
                        <Form.Select
                          value={selectType}
                          onChange={handleTypeChange}
                        >
                          <option value="">Select Type</option>
                          {userType.map((item) => (
                                  <option key={item.id} value={item.id}>
                                    {item.type}
                                  </option>
                                ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    </>
                    )} */}
                  </Row>
                  
                  {/* {selectType !== '1' && selectType && (
        <>
                 
  <Row className="mb-3">
    <Col md={6}>
      <Form.Group controlId="formContinent">
        <Form.Label>Select Continent</Form.Label>
        <Form.Select
          className="form-control"
          value={selectedContinent}
          onChange={handleContinentChange}
        >
          <option value="" disabled>
            Select Continent
          </option>
          {continents.map((item) => (
            <option key={item.id} value={item.id}>
              {item.description}
            </option>
          ))}
        </Form.Select>
      </Form.Group>
    </Col>

 
      <Col md={6}>
        <Form.Group controlId="formRegion">
          <Form.Label>Select Region</Form.Label>
          <Form.Select
            className="form-control"
            value={selectedRegion}
            onChange={handleRegionChange}
          >
            <option value="" disabled>
              Select Region
            </option>
            {tableData4.map((item) => (
              <option key={item.id} value={item.id}>
                {item.description}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      </Col>
 
  </Row>


  <Row className="mb-3">
    <Col md={6}>
      <Form.Group controlId="formProvince">
        <Form.Label>Select Province</Form.Label>
        <Form.Select
          className="form-control"
          value={selectedProvince}
          onChange={handleProvinceChange}
        >
          <option value="" disabled>
            Select Province
          </option>
          {tableData5.map((item) => (
            <option key={item.id} value={item.id}>
              {item.description}
            </option>
          ))}
        </Form.Select>
      </Form.Group>
    </Col>
  </Row>
  </>
                  )} */}

                
                </Form>

                <div className='modal-footer' style={{ marginTop: 40 }} />
                <div style={{ display: "flex", gap: 5 }}>
                  <ToggleSlider checked={checkAll} onChange={handleCheckAllToggle} />
                  <p>Check All</p>
                </div>
                <div className='modal-footer' style={{ marginTop: 20 }} />
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: 5, alignItems: "center", justifyContent: "center" }}>
                  {permLoading ? (
                    <Spinner size='sm' animation="border" role="status" />
                  ) : (
                    permissions.map((item, index) => (
                      <div
                        key={index}
                        style={{
                          width: '130px',
                          height: '130px',
                          margin: '5px',
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: '8px',
                          backgroundColor: '#fff',
                          border: '1px solid rgba(0, 0, 0, 0.2)',
                          boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.2)',
                          boxSizing: 'border-box', // Include padding and border in the width
                          marginBottom: 20,
                          overflow: 'hidden'
                        }}
                      
                      >
                        <p style={{ fontSize: 13.5, margin: '5px 0', textAlign: "center", whiteSpace: "normal", wordWrap: "break-word", overflowWrap: "break-word", maxWidth: "100%" }}>{item.name}</p>
                        <ToggleSlider
                          checked={toggleStates[item.id]}
                          onChange={() => handleToggleChange(item.id)}
                        />
                      </div>
                    ))
                  )}
                </div>
              </div>
            </div>
            <div className={`${classes.formIntBtn} ${classes.formIntBtn2}`}>
              <Button variant="light" className={classes.btn1} onClick={goBack}>Cancel</Button>
              <Button variant="success" className={classes.btn2} onClick={createUser}>
                {createLoading ? (
                  <>
                    <Spinner size='sm' />
                    <span style={{ marginLeft: '5px' }}>Creating user, Please wait...</span>
                  </>
                ) : (
                  "Create User"
                )}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}