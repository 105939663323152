import React, { useState, useEffect } from 'react';
import classes from './ManageStocks.module.css';
import "../../assets/datatables/dataTables.bootstrap4.min.css";
// import RegLogo from '../../Images/RegistrationLogo.svg'
import { Spinner, Badge, Button, Modal, Form } from 'react-bootstrap';
// import Folder from '../../Images/folder-2.svg';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Link, useNavigate, useParams } from 'react-router-dom'
import Table from 'react-bootstrap/Table';
import { BASE_URL } from '../api/api';
import { toast } from 'react-toastify';
import Select from "react-select";
import Swal from 'sweetalert2';
import LatestAssetsDash from './LatestAssetsDash';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';



export default function ManageAssetsList() {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const handleClose = () => setShow(false);
  const handleClose1 = () => setShow1(false);
  const handleShow = () => setShow(true);
  const handleShow1 = () => setShow1(true);
  const [bearer, setBearer] = useState('');
  const [user, setUser] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [roleLoading, setRoleLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [newLoading, setNewLoading] = useState(false);
  const [eyeClicked, setEyeClicked] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [tableData1, setTableData1] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [searchedResult, setSearchedResult] = useState([]);
  const [trashClicked, setTrashClicked] = useState(false);
  const [entriesPerPage, setEntriesPerPage] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [admin, setAdmin] = useState("");
  const [selectedRole, setSelectedRole] = useState("");
  const [unit, setUnit] = useState("");
  const [unit1, setUnit1] = useState("");

  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedDepreciation, setSelectedDepreciation] = useState(null);
  const [selectedAssetgl, setSelectedAssetgl] = useState(null);
  const [deprexpAcct, setDeprExpAcct] = useState(null);
  const [accumDepre, setAccumDepre] = useState(null);
  const [description, setDescription] = useState('');
  const [description1, setDescription1] = useState("");
  const [purchasedDate, setPurchaseDate] = useState('');
  const [location, setLocation] = useState('');
  const [price, setPrice] = useState("");
  const [residualValue, setResidualValue] = useState("");
  const [usefulLife, setUsefulLife] = useState("");
  const [fiscalYear, setFiscalYear] = useState("");
  const [price12, setPrice12] = useState("");
  const [glCode, setglCode] = useState("");
  const [assetCode, setAssetCode] = useState("");
  const [assetName, setAssetName] = useState("");
  const [deprePeriod, setDeprePeriod] = useState("");
  const [selectedType, setSelectedType] = useState(null);
  const [tableData3, setTableData3] = useState([]);
  const [expenses, setExpenses] = useState([]);
  const [assetssss, setAssetssss] = useState([]);
  const [tableData23, setTableData23] = useState([]);
  const [createLoading, setCreateLoading] = useState(false);



  const [selectedCategory1, setSelectedCategory1] = useState("");
  const [selectedDepreciation1, setSelectedDepreciation1] = useState("");
  const [selectedAssetgl1, setSelectedAssetgl1] = useState("");
  const [deprexpAcct1, setDeprExpAcct1] = useState("");
  const [accumDepre1, setAccumDepre1] = useState("");
  const [description11, setDescription11] = useState("");
  const [purchasedDate1, setPurchaseDate1] = useState('');
  const [location1, setLocation1] = useState('');
  const [price11, setPrice11] = useState("");
  const [residualValue1, setResidualValue1] = useState("");
  const [price121, setPrice121] = useState("");
  const [glCode11, setglCode11] = useState("");
  const [assetCode1, setAssetCode1] = useState("");
  const [assetName1, setAssetName1] = useState("");
  const [deprePeriod1, setDeprePeriod1] = useState("");
  const [tableData31, setTableData31] = useState([]);
  const [expenses1, setExpenses1] = useState([]);
  const [assetssss1, setAssetssss1] = useState([]);
  const [tableData231, setTableData231] = useState([]);
  const [createLoading1, setCreateLoading1] = useState(false);





  const [roless, setRoless] = useState([]);
  const [name, setName] = useState('');
  const [name1, setName1] = useState("");
  const [tableData2, setTableData2] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [price1, setPrice1] = useState("");
  const [stockBalance, setStockBalance] = useState("");
  const [stockBalance1, setStockBalance1] = useState("");
  const [reOderLevel, setReOrderLevel] = useState("");
  const [glCode1, setglCode1] = useState("");
  const [reOderLevel1, setReOrderLevel1] = useState("");

  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem('userToken');
      const value1 = await AsyncStorage.getItem('tobi');
      const value2 = await AsyncStorage.getItem('permissions');
      const value3 = await AsyncStorage.getItem('admin');

      if (value !== null) {
        setBearer(value);
      }
      if (value1 !== null) {
        setUser(value1);
      }
      if (value2 !== null) {
        setPermissions(value2);
      }
      if (value3 !== null) {
        setAdmin(value3);
      }
    } catch (e) {
      alert('Failed to fetch the input from storage');
    }
  };

  useEffect(() => {
    readData();
  }, []);

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${bearer}`
  };



  const fetchData = async () => {
    setRoleLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/fixedassets`, { headers });
      const results = response.data?.data;
      console.log(results);
      setTableData(results);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
        const errorStatus = error.response?.data?.message;
        console.log(errorStatus);
        setTableData([]);
      }
    } finally {
      setRoleLoading(false);
    }
  };


  const fetchSearch = async (searchTerm) => {
    setSearchLoading(true);
    try {
      let res;
      if (searchTerm.trim() === "") {
        res = tableData1;
      } else {
        const response = await axios.get(`${BASE_URL}/users/search`, {
          params: { variable: searchTerm },
          headers
        });
        res = response.data?.data;
      }
      setSearchedResult(res);

    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate('/login');
      } else {
        let errorMessage = 'An error occurred. Please try again.';
        if (error.response && error.response.data && error.response.data.message) {
          if (typeof error.response.data.message === 'string') {
            errorMessage = error.response.data.message;
          } else if (Array.isArray(error.response.data.message)) {
            errorMessage = error.response.data.message.join('; ');
          } else if (typeof error.response.data.message === 'object') {
            errorMessage = JSON.stringify(error.response.data.message);
          }
        }
        setSearchedResult([]);
      }
    } finally {
      setSearchLoading(false);
    }
  };


  useEffect(() => {
    if (bearer) {
      fetchData();
      // fetchData131();
      fetchGl();
    }
  }, [bearer]);

  const createUser = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${BASE_URL}/items/add-new-item`,
        {
          name: name,
          description: description,
          unit: unit,
          gl_code: glCode,
          re_order_level: reOderLevel,
          price: price,
          quantity: stockBalance


        },
        { headers }
      );
      console.log(response)
      fetchData();
      handleClose();

      // return
      toast.success(response.data.message);
      console.log(response.data);

    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        toast.error(errorMessage);
        console.log(error);
      }
    } finally {
      setLoading(false);
    }
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const formattedDate = `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())} ${padZero(date.getHours())}:${padZero(date.getMinutes())} ${date.getHours() >= 12 ? 'PM' : 'AM'}`;
    return formattedDate;
  }

  function padZero(num) {
    return num < 10 ? `0${num}` : num;
  }


  const filteredData = tableData.filter(item => item.description.toLowerCase().includes(searchTerm.toLowerCase()));

  const totalPages = Math.ceil(filteredData.length / entriesPerPage);

  const handlePrevPage = () => {
    setCurrentPage(Math.max(currentPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage(Math.min(currentPage + 1, totalPages));
  };

  const totalEntries = filteredData.length;
  const startIndexx = (currentPage - 1) * entriesPerPage + 1;
  const endIndexx = Math.min(startIndexx + entriesPerPage - 1, totalEntries);
  const displayedData = filteredData.slice(startIndexx - 1, endIndexx);


  //   const slctedServices = opt.find(item => item.value === selectedBooking.services.id);
  //   console.log(slctedServices);
  //   setSelectedService(slctedServices);
  // }





  const handleEyeClick = (id) => {
    const foundItems = tableData.find(item => item.id === id);
    const itemId = foundItems.id;
    setSelectedItem(itemId);


    const { identification_number, description, category_id, asset_category, amount_purchased, asset_code, date_purchased, location, lifetime_in_years, remarks, depreciation_rate, depre_cal_period, depre_expense_account, accumulated_depreciation, asset_gl, depre_method } = foundItems;

    const selectedAssetGlOption = purchaseOptions.find(item => item.value === asset_gl?.id);
    setSelectedAssetgl1(selectedAssetGlOption);

    const selectedAssetGlOption2 = options1.find(item => item.value === asset_category?.id);
    setSelectedCategory1(selectedAssetGlOption2);
    // console.log(selectedAssetGlOption2, "ads" );

    const selectedAssetGlOption21 = assetOptions.find(item => item.value === depre_expense_account?.id);
    setAccumDepre1(selectedAssetGlOption21);

    const selectedAssetGlOption22 = options.find(item => item.value === depre_method?.id);
    setSelectedDepreciation1(selectedAssetGlOption22);

    const selectedAssetGlOption23 = assetOptions.find(item => item.value === depre_expense_account?.id);
    setDeprExpAcct1(selectedAssetGlOption23);

    setAssetCode1(identification_number || '');
    setAssetName1(asset_code || '');
    setDescription1(description || '');
    setLocation1(location || '');
    setPrice11(amount_purchased || '');
    setPrice121(depreciation_rate || '');
    setDeprePeriod1(depre_cal_period || '');
    setResidualValue1(lifetime_in_years || '');
    setPurchaseDate1(date_purchased || '');

    setShow1(true);
    setEyeClicked(true);
  };

  // UseEffect to log changes to selectedItem
  useEffect(() => {
    // console.log(selectedItem, "selectedItem changed");
  }, [selectedItem]);


  const updateAsset = async (id) => {
    setUpdateLoading(true);

    try {
      const removeFormatting = (formattedValue) => {
        const valueWithoutCommas = formattedValue.replace(/,/g, '');
        const valueAsNumber = parseFloat(valueWithoutCommas);
        return valueAsNumber;
      };

      const response = await axios.post(
        `${BASE_URL}/fixedassets/update_fixed_asset`,
        {
          // name: name1,
          // description: description1,
          // unit: unit1,
          // gl_code: glCode1,
          // re_order_level: reOderLevel1,
          // id: selectedItem,
          // price: price1,
          // quantity: stockBalance1

          id: selectedItem,
          identification_number: assetCode1,
          description: description1,
          category_id: selectedCategory1?.value || '',
          amount_purchased: removeFormatting(price11),
          asset_code: assetName1,
          date_purchased: purchasedDate1,
          location: location1,
          lifetime_in_years: residualValue1,
          remarks: description1,
          depreciation_rate: removeFormatting(price121),
          depre_cal_period: deprePeriod1,
          depre_expenses_account: deprexpAcct1?.value || '',
          accumulated_depreciation: accumDepre1?.value || '',
          asset_gl: selectedAssetgl1?.value || '',
          depre_method: selectedDepreciation1?.value || '',

        },
        { headers }
      );

      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: response.data.message,
      })
      fetchData();
      handleClose1();

      // .then(() => {
      //   window.location.reload();
      // });
      // console.log(response.data);
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        toast.error(errorMessage);
        console.log(error);
      }
    } finally {
      setUpdateLoading(false);
    }
  };


  const handleTrashClick = async (id) => {
    const confirmed = await Swal.fire({
      title: 'Are you sure?',
      text: 'You are about to delete this asset.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel',
    });

    if (!confirmed.isConfirmed) {
      return; // User canceled, do nothing
    }

    try {
      // const response = await axios.get(`${BASE_URL}/items/delete-item?id=${id}`, { headers });
      const response = await axios.get(`${BASE_URL}/fixedassets/delete_fixed_asset?id=${id}`, { headers });
      toast.success(response.data.message);
      fetchData();
      setTrashClicked(true);
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        toast.error(errorMessage)
        console.log(errorMessage);
      }
    }
  };

  const formatPrice = (value) => {
    let formattedValue = parseFloat(value.replace(/,/g, ''));
    if (isNaN(formattedValue)) return;

    formattedValue = formattedValue.toFixed(2);
    const parts = formattedValue.split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    const finalValue = parts.join('.');
    setPrice(finalValue);
  };

  const formatPrice12 = (value) => {
    let formattedValue = parseFloat(value.replace(/,/g, ''));
    if (isNaN(formattedValue)) return;

    formattedValue = formattedValue.toFixed(2);
    const parts = formattedValue.split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    const finalValue = parts.join('.');
    setPrice12(finalValue);
  };


  const fetchExpenses = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${BASE_URL}/get-account-by-class-id?class_id=${5}`,
        {

          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearer}`
          }
        }
      );
      const resultss = response.data?.data;
      setExpenses(resultss);

      //   console.log(resultss, "NI");
    } catch (error) {
      const errorStatus = error.response.data.message;
      console.error(errorStatus);
    } finally {
      setLoading(false);
    }
  };


  const fetchAssetsAccount = async () => {
    setLoading(true);


    try {
      const response = await axios.get(
        `${BASE_URL}/get-account-by-class-id?class_id=${1}`,
        {

          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearer}`
          }
        }
      );
      const resultss = response.data?.data;
      setAssetssss(resultss);

      //   console.log(results, "NI");
    } catch (error) {
      const errorStatus = error.response.data.message;
      console.error(errorStatus);
    } finally {
      setLoading(false);
    }
  };



  const options = roless.map(item => ({
    label: item.name,
    value: item.id
  }));

  const options1 = tableData3.map(item => ({
    label: item.description,
    value: item.id
  }));


  const purchaseOptions = expenses.map(item => ({
    label: item.gl_name,
    value: item.id
  }));


  const assetOptions = assetssss.map(item => ({
    label: item.gl_name,
    value: item.id
  }));


  const fetchData2 = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/assetcategories`, { headers });
      const ts = response.data?.data;
      // console.log(results);
      setTableData3(ts);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
        const errorStatus = error.response?.data?.message;
        console.log(errorStatus);
        setTableData3([]);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const fetchGl = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/account`, { headers });
      const results = response.data?.data;

      setTableData2(results);
      // console.log(results);
    } catch (error) {
      const errorStatus = error.response?.data?.message;
      console.log(errorStatus);
      setTableData2([]);
    } finally {
      setIsLoading(false);
    }
  };


  const fetchData1 = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/fetch-depreciation-method`, { headers });
      const roleList = response.data?.data;
      // console.log(results);
      setRoless(roleList);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
        const errorStatus = error.response?.data?.message;
        console.log(errorStatus);
        setRoless([]);
      }
    } finally {
      setIsLoading(false);
    }
  };


  const fetchData11 = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/get-sub-categories`, { headers });
      const subCat = response.data?.data;
      setTableData23(subCat);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
        const errorStatus = error.response?.data?.message;
        console.log(errorStatus);
        setTableData23([]);
      }
    } finally {
      setIsLoading(false);
    }
  };


  useEffect(() => {
    if (bearer) {
      // fetchData();
      fetchData1();
      fetchData2();
      fetchGl();
      fetchData11();
      fetchExpenses();
      fetchAssetsAccount();
    }
  }, [bearer]);


  const createAsset = async () => {
    setCreateLoading(true);

    try {
      const removeFormatting = (formattedValue) => {
        const valueWithoutCommas = formattedValue.replace(/,/g, '');
        const valueAsNumber = parseFloat(valueWithoutCommas);
        return valueAsNumber;
      };
      const response = await axios.post(
        `${BASE_URL}/fixedassets/create_fixed_asset`,
        {
          identification_number: assetCode,
          description: description,
          category_id: selectedCategory?.value || '',
          amount_purchased: removeFormatting(price),
          asset_code: assetCode,
          asset_name: assetName,
          date_purchased: purchasedDate,
          location: location,
          lifetime_in_years: usefulLife,
          residual_value: residualValue,
          fiscal_year: fiscalYear,
          remarks: description,
          depreciation_rate: removeFormatting(price12),
          depre_cal_period: deprePeriod,
          depre_expenses_account: deprexpAcct?.value || '',
          accumulated_depreciation: accumDepre?.value || '',
          asset_gl: selectedAssetgl?.value || '',
          depre_method: selectedDepreciation?.value || '',
        },
        { headers }
      );
      console.log(response)
      // fetchData();
      // handleClose();

      // return
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: response.data.message,
      }).then(() => {
        window.location.reload();
      });

      setAssetCode('');
      setAssetName('');
      setDescription('');
      setLocation('');
      setPrice('');
      setPrice12('');
      setSelectedDepreciation('');
      setSelectedCategory('');
      setSelectedAssetgl('');
      setDeprExpAcct('');
      setAccumDepre('');
      setDeprePeriod('');
      setResidualValue('');
      setUsefulLife('');
      setFiscalYear('');
      setPurchaseDate('');


      console.log(response.data);

    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        Swal.fire({
          icon: 'error',
          title: 'Failed',
          text: JSON.stringify(error.response.data.data),
        });
        // toast.error(JSON.stringify(error.response.data.data));
        // console.log(e);
      }
    } finally {
      setCreateLoading(false);
    }
  };


  const handleUnitChange = (event) => {
    setUnit(event.target.value)
  }
  const handleGlChange = (event) => {
    setglCode(event.target.value)
  }

  const handleUnitChange1 = (event) => {
    setUnit1(event.target.value)
  }
  const handleGlChange1 = (event) => {
    setglCode1(event.target.value)
  }



  return (
    <div>
      <LatestAssetsDash />

      <div className={classes.finishedbodyCont}>
        <div className={classes.topPadding}>
          <div className={`${classes.formSecCont}`}>
            <div className={classes.formSectionHeader}>
              <h3 style={{ fontSize: '30px', fontWeight: 700, }}>Assets</h3>
            </div>
            <div className={classes.formSectionHeader}>
              <div className={classes.formIntBtn}>
                <Button variant="success" onClick={handleShow} className={classes.btn2}> Add New Asset</Button>
                {/* <Button variant="success"  className={classes.btn1}> Upload Stock</Button> */}
              </div>
            </div>
          </div>
        </div>


        <div className={classes.mainform}>

          <div className={classes.loandgrantcards}>


            <Modal size="lg" show={show} onHide={handleClose} animation={false}>
              <Modal.Header closeButton>
                <Modal.Title>Add Assets </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Modal.Body className="grid-example">
                  <Container>
                    <Row>
                      <Col xs={12} md={6}>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1" >
                          <div className={`d-flex align-items-left form-group-custom`} >
                            <Form.Label style={{ width: '40%', textAlign: 'right', paddingRight: '15px' }}>Asset Code</Form.Label>
                            <Form.Control
                              type="text"
                              // placeholder="0.00"
                              value={assetCode}
                              onChange={(e) => setAssetCode(e.target.value)}

                            />
                          </div>
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={6}>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                          <div className={`d-flex align-items-left form-group-custom`}>
                            <Form.Label style={{ width: '40%', textAlign: 'right', paddingRight: '15px' }}>Asset Name</Form.Label>
                            <Form.Control
                              type="text"
                              // placeholder="0.00"
                              value={assetName}
                              onChange={(e) => setAssetName(e.target.value)}
                            />
                          </div>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Container>
                  <Container>
                    <Row>
                      <Col xs={12} md={6}>
                        <div style={{ marginTop: -7 }} />
                        <Form.Group className="mb-3">
                          <div className={`d-flex align-items-left form-group-custom`}>
                            <Form.Label style={{ width: '40%', textAlign: 'right', paddingRight: '15px' }}>Description</Form.Label>
                            <Form.Control

                              type="text"
                              // placeholder="Enter Description of the Product"
                              value={description}
                              onChange={(e) => setDescription(e.target.value)}
                            />
                          </div>
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={6}>
                        <div style={{ marginTop: -7 }} />
                        <Form.Group className="mb-3">
                          <div className={`d-flex align-items-left form-group-custom`}>
                            <Form.Label style={{ width: '40%', textAlign: 'right', paddingRight: '15px' }}>Location</Form.Label>
                            <Form.Control

                              type="text"
                              // placeholder="Enter Description of the Product"
                              value={location}
                              onChange={(e) => setLocation(e.target.value)}
                            />
                          </div>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Container>
                  <Container>
                    <Row>
                      <Col xs={12} md={6}>
                        <div style={{ marginTop: -7 }} />
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                          <div className={`d-flex align-items-left form-group-custom`}>
                            <Form.Label style={{ width: '40%', textAlign: 'right', paddingRight: '15px' }}>Category</Form.Label>
                            <div style={{ width: '100%' }}>
                              <Select loading={isLoading} dropdownHeight='100px' options={options1}
                                placeholder="Select Category" onChange={setSelectedCategory} value={selectedCategory}
                              />
                            </div>
                          </div>
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={6}>
                        <div style={{ marginTop: -7 }} />
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                          <div className={`d-flex align-items-left form-group-custom`}>
                            <Form.Label style={{ width: '40%', textAlign: 'right', paddingRight: '15px' }}> Amount </Form.Label>
                            <Form.Control
                              // className={classes.formControl + ' ' + classes.alignRight}
                              type="text"
                              placeholder="0.00"
                              value={price}
                              onChange={(e) => setPrice(e.target.value)}
                              onBlur={(e) => formatPrice(e.target.value)}
                            />
                          </div>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Container>
                  <Container>
                    <Row>
                      <Col xs={12} md={6}>
                        <div style={{ marginTop: -7 }} />
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                          <div className={`d-flex align-items-left form-group-custom`}>
                            <Form.Label style={{ width: '40%', textAlign: 'right', paddingRight: '15px' }}> Depre. Rate </Form.Label>
                            <Form.Control
                              // className={classes.form124Control + ' ' + classes.alignRight}
                              type="text"
                              // placeholder="0.00"
                              value={price12}
                              onChange={(e) => setPrice12(e.target.value)}
                              onBlur={(e) => formatPrice12(e.target.value)}
                            />
                          </div>
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={6}>
                        <div style={{ marginTop: -7 }} />
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                          <div className="d-flex align-items-left form-group-custom">
                            <Form.Label style={{ width: '40%', textAlign: 'right', paddingRight: '15px' }}>
                              Period
                            </Form.Label>
                            <Form.Select
                              value={deprePeriod}
                              onChange={(e) => setDeprePeriod(e.target.value)}
                            >
                              <option value="">Select Depre. Period</option>
                              <option value="1">Monthly</option>
                              <option value="2">Yearly</option>
                            </Form.Select>
                          </div>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Container>
                  <Container>
                    <Row>
                      <Col xs={12} md={6}>
                        <div style={{ marginTop: -7 }} />
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                          <div className={`d-flex align-items-left form-group-custom`}>
                            <Form.Label style={{ width: '40%', textAlign: 'right', paddingRight: '15px' }}>Useful Life</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter useful life span"
                              value={usefulLife}
                              onChange={(e) => setUsefulLife(e.target.value)}
                            // onBlur={(e) => formatPrice(e.target.value)}
                            // style={{ width: "195px" }}
                            />
                          </div>
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={6}>
                        <div style={{ marginTop: -7 }} />
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                          <div className={`d-flex align-items-left form-group-custom`}>
                            <Form.Label style={{ width: '40%', textAlign: 'right', paddingRight: '15px' }}>Fiscal Year</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter Value"
                              value={fiscalYear}
                              onChange={(e) => setFiscalYear(e.target.value)}
                            // onBlur={(e) => formatPrice(e.target.value)}
                            // style={{ width: "195px" }}
                            />
                          </div>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Container>
                  <Container>
                    <Row>
                      <Col xs={12} md={6}>
                        <div style={{ marginTop: -7 }} />
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                          <div className={`d-flex align-items-left form-group-custom`}>
                            <Form.Label style={{ width: '40%', textAlign: 'right', paddingRight: '15px' }}>Res. Value</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter Residual Value"
                              value={residualValue}
                              onChange={(e) => setResidualValue(e.target.value)}
                            // onBlur={(e) => formatPrice(e.target.value)}
                            // style={{ width: "195px" }}
                            />
                          </div>
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={6}>
                        <div style={{ marginTop: -7 }} />
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                          <div className={`d-flex align-items-left form-group-custom`}>
                            <Form.Label style={{ width: '40%', textAlign: 'right', paddingRight: '15px' }}>Date</Form.Label>
                            <Form.Control
                              type="date"
                              // placeholder="Enter Value"
                              value={purchasedDate}
                              onChange={(e) => setPurchaseDate(e.target.value)}
                            />
                          </div>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Container>
                  <Container>
                    <Row>
                      <Col xs={12} md={6}>
                        <div style={{ marginTop: -7 }} />
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                          <div className={`d-flex align-items-left form-group-custom`}>
                            <Form.Label style={{ width: '40%', textAlign: 'right', paddingRight: '15px' }}>Accum. Dpr.</Form.Label>
                            <div style={{ width: '100%' }}>
                              <Select loading={isLoading} dropdownHeight='100px' options={assetOptions}
                                placeholder="Select Accum." onChange={setAccumDepre} value={accumDepre}
                              //  className={classes.formSelect}
                              />
                            </div>
                          </div>
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={6}>
                        <div style={{ marginTop: -7 }} />
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                          <div className={`d-flex align-items-left form-group-custom`}>
                            <Form.Label style={{ width: '40%', textAlign: 'right', paddingRight: '15px' }}>Depreciation </Form.Label>
                            <div style={{ width: '100%' }}>
                              <Select loading={isLoading} options={options} dropdownHeight='100px'
                                placeholder="Select Method" onChange={setSelectedDepreciation} value={selectedDepreciation}
                              />
                            </div>
                          </div>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Container>
                  <Container>
                    <Row>
                      <Col xs={12} md={6}>
                        <div style={{ marginTop: -7 }} />
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                          <div className={`d-flex align-items-left form-group-custom`}>
                            <Form.Label style={{ width: '40%', textAlign: 'right', paddingRight: '15px' }}>Asset GL</Form.Label>
                            <div style={{ width: '100%' }}>
                              <Select
                                loading={isLoading}
                                dropdownHeight="100px"
                                options={purchaseOptions}
                                placeholder="Select Asset GL"
                                onChange={setSelectedAssetgl}
                                value={selectedAssetgl}
                              />
                            </div>
                          </div>
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={6}>
                        <div style={{ marginTop: -7 }} />
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                          <div className={`d-flex align-items-left form-group-custom`}>
                            <Form.Label style={{ width: '40%', textAlign: 'right', paddingRight: '15px' }}>Depre. Acct.</Form.Label>
                            <div style={{ width: '100%' }}>
                              <Select loading={isLoading} dropdownHeight='100px' options={assetOptions}
                                placeholder="Select Exp. Acct" onChange={setDeprExpAcct} value={deprexpAcct}
                              />
                            </div>
                          </div>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Container>
                  <Modal.Footer>
                    <Button variant="danger" onClick={handleClose}>
                      Go back
                    </Button>
                    <Button variant="success" onClick={createAsset}>
                      {createLoading ? (
                        <>
                          <Spinner size='sm' />
                          <span style={{ marginLeft: '5px' }}>Creating Asset, please wait...</span>
                        </>
                      ) : (
                        "Create Asset"
                      )}
                    </Button>
                  </Modal.Footer>

                </Modal.Body>
                {/* <Form style={{ marginTop: 20 }}>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <div className={`d-flex align-items-left form-group-custom`}>
                      <Form.Label style={{ width: '40%', textAlign: 'right', paddingRight: '15px' }}>Asset Code</Form.Label>
                      <Form.Control
                        type="text"
                        // placeholder="0.00"
                        value={assetCode}
                        onChange={(e) => setAssetCode(e.target.value)}

                      />
                    </div>
                  </Form.Group>
                  <div style={{ marginTop: -7 }} />
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <div className={`d-flex align-items-left form-group-custom`}>
                      <Form.Label style={{ width: '40%', textAlign: 'right', paddingRight: '15px' }}>Asset Name</Form.Label>
                      <Form.Control
                        type="text"
                        // placeholder="0.00"
                        value={assetName}
                        onChange={(e) => setAssetName(e.target.value)}
                      />
                    </div>
                  </Form.Group>
                  <div style={{ marginTop: -7 }} />
                  <Form.Group className="mb-3">
                    <div className={`d-flex align-items-left form-group-custom`}>
                      <Form.Label style={{ width: '40%', textAlign: 'right', paddingRight: '15px' }}>Description</Form.Label>
                      <Form.Control

                        type="text"
                        // placeholder="Enter Description of the Product"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                      />
                    </div>
                  </Form.Group>
                  <div style={{ marginTop: -7 }} />
                  <Form.Group className="mb-3">
                    <div className={`d-flex align-items-left form-group-custom`}>
                      <Form.Label style={{ width: '40%', textAlign: 'right', paddingRight: '15px' }}>Location</Form.Label>
                      <Form.Control

                        type="text"
                        // placeholder="Enter Description of the Product"
                        value={location}
                        onChange={(e) => setLocation(e.target.value)}
                      />
                    </div>
                  </Form.Group>
                  <div style={{ marginTop: -7 }} />
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <div className={`d-flex align-items-left form-group-custom`}>
                      <Form.Label style={{ width: '40%', textAlign: 'right', paddingRight: '15px' }}>Category</Form.Label>
                      <div style={{ width: '100%' }}>
                        <Select loading={isLoading} dropdownHeight='100px' options={options1}
                          placeholder="Select Category" onChange={setSelectedCategory} value={selectedCategory}
                        />
                      </div>
                    </div>
                  </Form.Group>
                  <div style={{ marginTop: -7 }} />
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <div className={`d-flex align-items-left form-group-custom`}>
                      <Form.Label style={{ width: '40%', textAlign: 'right', paddingRight: '15px' }}> Asset Amount </Form.Label>
                      <Form.Control
                        // className={classes.formControl + ' ' + classes.alignRight}
                        type="text"
                        placeholder="0.00"
                        value={price}
                        onChange={(e) => setPrice(e.target.value)}
                        onBlur={(e) => formatPrice(e.target.value)}
                      />
                    </div>
                  </Form.Group>
                  <div style={{ marginTop: -7 }} />
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <div className={`d-flex align-items-left form-group-custom`}>
                      <Form.Label style={{ width: '40%', textAlign: 'right', paddingRight: '15px' }}> Depre. Rate </Form.Label>
                      <Form.Control
                        // className={classes.form124Control + ' ' + classes.alignRight}
                        type="text"
                        // placeholder="0.00"
                        value={price12}
                        onChange={(e) => setPrice12(e.target.value)}
                        onBlur={(e) => formatPrice12(e.target.value)}
                      />
                    </div>
                  </Form.Group>
                  <div style={{ marginTop: -7 }} />
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <div className="d-flex align-items-left form-group-custom">
                      <Form.Label style={{ width: '40%', textAlign: 'right', paddingRight: '15px' }}>
                        Depre. Period
                      </Form.Label>
                      <Form.Select
                        value={deprePeriod}
                        onChange={(e) => setDeprePeriod(e.target.value)}
                      >
                        <option value="">Select Period</option>
                        <option value="1">Monthly</option>
                        <option value="2">Yearly</option>
                      </Form.Select>
                    </div>
                  </Form.Group>
                  <div style={{ marginTop: -7 }} />
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <div className={`d-flex align-items-left form-group-custom`}>
                      <Form.Label style={{ width: '40%', textAlign: 'right', paddingRight: '15px' }}>Useful Life</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Value"
                        value={usefulLife}
                        onChange={(e) => setUsefulLife(e.target.value)}
                      // onBlur={(e) => formatPrice(e.target.value)}
                      // style={{ width: "195px" }}
                      />
                    </div>
                  </Form.Group>
                  <div style={{ marginTop: -7 }} />
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <div className={`d-flex align-items-left form-group-custom`}>
                      <Form.Label style={{ width: '40%', textAlign: 'right', paddingRight: '15px' }}>Fiscal Year</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Value"
                        value={fiscalYear}
                        onChange={(e) => setFiscalYear(e.target.value)}
                      // onBlur={(e) => formatPrice(e.target.value)}
                      // style={{ width: "195px" }}
                      />
                    </div>
                  </Form.Group>
                  <div style={{ marginTop: -7 }} />
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <div className={`d-flex align-items-left form-group-custom`}>
                      <Form.Label style={{ width: '40%', textAlign: 'right', paddingRight: '15px' }}>Residual Value</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Value"
                        value={residualValue}
                        onChange={(e) => setResidualValue(e.target.value)}
                      // onBlur={(e) => formatPrice(e.target.value)}
                      // style={{ width: "195px" }}
                      />
                    </div>
                  </Form.Group>
                  <div style={{ marginTop: -7 }} />
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <div className={`d-flex align-items-left form-group-custom`}>
                      <Form.Label style={{ width: '40%', textAlign: 'right', paddingRight: '15px' }}>Date Purchased</Form.Label>
                      <Form.Control
                        type="date"
                        // placeholder="Enter Value"
                        value={purchasedDate}
                        onChange={(e) => setPurchaseDate(e.target.value)}
                      />
                    </div>
                  </Form.Group>
                  <div style={{ marginTop: -7 }} />
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <div className={`d-flex align-items-left form-group-custom`}>
                      <Form.Label style={{ width: '40%', textAlign: 'right', paddingRight: '15px' }}>Accum. Depre.</Form.Label>
                      <div style={{ width: '100%' }}>
                        <Select loading={isLoading} dropdownHeight='100px' options={assetOptions}
                          placeholder="Select Accum." onChange={setAccumDepre} value={accumDepre}
                        //  className={classes.formSelect}
                        />
                      </div>
                    </div>
                  </Form.Group>
                  <div style={{ marginTop: -7 }} />
                  {selectedType?.value !== '1' && (
                    <>
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <div className={`d-flex align-items-left form-group-custom`}>
                          <Form.Label style={{ width: '40%', textAlign: 'right', paddingRight: '15px' }}>Depreciation </Form.Label>
                          <div style={{ width: '100%' }}>
                            <Select loading={isLoading} options={options} dropdownHeight='100px'
                              placeholder="Select Method" onChange={setSelectedDepreciation} value={selectedDepreciation}
                            />
                          </div>
                        </div>
                      </Form.Group>
                      <div style={{ marginTop: -7 }} />
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <div className={`d-flex align-items-left form-group-custom`}>
                          <Form.Label style={{ width: '40%', textAlign: 'right', paddingRight: '15px' }}>Asset GL</Form.Label>
                          <div style={{ width: '100%' }}>
                            <Select
                              loading={isLoading}
                              dropdownHeight="100px"
                              options={purchaseOptions}
                              placeholder="Select Asset GL"
                              onChange={setSelectedAssetgl}
                              value={selectedAssetgl}
                            />
                          </div>
                        </div>
                      </Form.Group>
                      <div style={{ marginTop: -7 }} />
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <div className={`d-flex align-items-left form-group-custom`}>
                          <Form.Label style={{ width: '40%', textAlign: 'right', paddingRight: '15px' }}>Depre. Acct.</Form.Label>
                          <div style={{ width: '100%' }}>
                            <Select loading={isLoading} dropdownHeight='100px' options={assetOptions}
                              placeholder="Select Exp. Acct" onChange={setDeprExpAcct} value={deprexpAcct}
                            />
                          </div>
                        </div>
                      </Form.Group>
                    </>
                  )}
                </Form> */}
              </Modal.Body>
            </Modal>



            <Modal show={show1} onHide={handleClose1} animation={false}>
              <Modal.Header closeButton>
                <Modal.Title> Edit Assets</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form style={{ marginTop: 20 }}>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <div className={`d-flex align-items-left form-group-custom`}>
                      <Form.Label style={{ width: '40%', textAlign: 'right', paddingRight: '15px' }}>Asset Code</Form.Label>
                      <Form.Control
                        type="text"
                        // placeholder="0.00"
                        value={assetCode1}
                        onChange={(e) => setAssetCode1(e.target.value)}

                      />
                    </div>
                  </Form.Group>
                  <div style={{ marginTop: -7 }} />
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <div className={`d-flex align-items-left form-group-custom`}>
                      <Form.Label style={{ width: '40%', textAlign: 'right', paddingRight: '15px' }}>Asset Name</Form.Label>
                      <Form.Control
                        type="text"
                        // placeholder="0.00"
                        value={assetName1}
                        onChange={(e) => setAssetName1(e.target.value)}
                      />
                    </div>
                  </Form.Group>
                  <div style={{ marginTop: -7 }} />
                  <Form.Group className="mb-3">
                    <div className={`d-flex align-items-left form-group-custom`}>
                      <Form.Label style={{ width: '40%', textAlign: 'right', paddingRight: '15px' }}>Description</Form.Label>
                      <Form.Control
                        type="text"
                        // placeholder="Enter Description of the Product"
                        value={description1}
                        onChange={(e) => setDescription1(e.target.value)}
                      />
                    </div>
                  </Form.Group>
                  <div style={{ marginTop: -7 }} />
                  <Form.Group className="mb-3">
                    <div className={`d-flex align-items-left form-group-custom`}>
                      <Form.Label style={{ width: '40%', textAlign: 'right', paddingRight: '15px' }}>Location</Form.Label>
                      <Form.Control
                        type="text"
                        // placeholder="Enter Description of the Product"
                        value={location1}
                        onChange={(e) => setLocation1(e.target.value)}
                      />
                    </div>
                  </Form.Group>
                  <div style={{ marginTop: -7 }} />
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <div className={`d-flex align-items-left form-group-custom`}>
                      <Form.Label style={{ width: '40%', textAlign: 'right', paddingRight: '15px' }}>Asset Amount</Form.Label>
                      <Form.Control
                        // className={classes.formControl + ' ' + classes.alignRight}
                        type="text"
                        placeholder="0.00"
                        value={price11}
                        onChange={(e) => setPrice11(e.target.value)}
                        onBlur={(e) => formatPrice(e.target.value)}
                      />
                    </div>
                  </Form.Group>
                  <div style={{ marginTop: -7 }} />
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <div className={`d-flex align-items-left form-group-custom`}>
                      <Form.Label style={{ width: '40%', textAlign: 'right', paddingRight: '15px' }}>Depre. Rate</Form.Label>
                      <Form.Control
                        // className={classes.form124Control + ' ' + classes.alignRight}
                        type="text"
                        // placeholder="0.00"
                        value={price121}
                        onChange={(e) => setPrice121(e.target.value)}
                        onBlur={(e) => formatPrice12(e.target.value)}
                      />
                    </div>
                  </Form.Group>
                  <div style={{ marginTop: -7 }} />
                  {selectedType?.value !== '1' && (
                    <>
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <div className={`d-flex align-items-left form-group-custom`}>
                          <Form.Label style={{ width: '40%', textAlign: 'right', paddingRight: '15px' }}>Depreciation </Form.Label>
                          <div style={{ width: '100%' }}>
                            <Select loading={isLoading} options={options} dropdownHeight='100px'
                              placeholder="Select Method" onChange={setSelectedDepreciation1} value={selectedDepreciation1}
                            />
                          </div>
                        </div>
                      </Form.Group>
                      <div style={{ marginTop: -7 }} />
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <div className={`d-flex align-items-left form-group-custom`}>
                          <Form.Label style={{ width: '40%', textAlign: 'right', paddingRight: '15px' }}>Category</Form.Label>
                          <div style={{ width: '100%' }}>
                            <Select loading={isLoading} dropdownHeight='100px' options={options1}
                              placeholder="Select Category"
                              onChange={setSelectedCategory1}
                              value={selectedCategory1}
                            />
                          </div>
                        </div>
                      </Form.Group>
                      <div style={{ marginTop: -7 }} />
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <div className={`d-flex align-items-left form-group-custom`}>
                          <Form.Label style={{ width: '40%', textAlign: 'right', paddingRight: '15px' }}>Asset GL</Form.Label>
                          <div style={{ width: '100%' }}>
                            <Select
                              loading={isLoading}
                              dropdownHeight="100px"
                              options={purchaseOptions}
                              placeholder="Select Asset GL"
                              onChange={setSelectedAssetgl1}
                              value={selectedAssetgl1}
                            />
                          </div>
                        </div>
                      </Form.Group>
                      <div style={{ marginTop: -7 }} />
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <div className={`d-flex align-items-left form-group-custom`}>
                          <Form.Label style={{ width: '40%', textAlign: 'right', paddingRight: '15px' }}>Depre. Acct.</Form.Label>
                          <div style={{ width: '100%' }}>
                            <Select loading={isLoading} dropdownHeight='100px' options={assetOptions}
                              placeholder="Select Exp. Acct"
                              onChange={setDeprExpAcct1}
                              value={deprexpAcct1}
                            />
                          </div>
                        </div>
                      </Form.Group>
                      <div style={{ marginTop: -7 }} />
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <div className={`d-flex align-items-left form-group-custom`}>
                          <Form.Label style={{ width: '40%', textAlign: 'right', paddingRight: '15px' }}>Accum. Depre.</Form.Label>
                          <div style={{ width: '100%' }}>
                            <Select loading={isLoading} dropdownHeight='100px' options={assetOptions}
                              placeholder="Select Accum." onChange={setAccumDepre1} value={accumDepre1}
                            //  className={classes.formSelect}
                            />
                          </div>
                        </div>
                      </Form.Group>
                      <div style={{ marginTop: -7 }} />
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <div className={`d-flex align-items-left form-group-custom`}>
                          <Form.Label style={{ width: '40%', textAlign: 'right', paddingRight: '15px' }}>Depre. Period</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Monthly/Yearly"
                            value={deprePeriod1}
                            onChange={(e) => setDeprePeriod1(e.target.value)}
                          />
                        </div>
                      </Form.Group>
                    </>
                  )}
                  <div style={{ marginTop: -7 }} />
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <div className={`d-flex align-items-left form-group-custom`}>
                      <Form.Label style={{ width: '40%', textAlign: 'right', paddingRight: '15px' }}>Residual Value</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Value"
                        value={residualValue1}
                        onChange={(e) => setResidualValue1(e.target.value)}
                      // onBlur={(e) => formatPrice(e.target.value)}
                      // style={{ width: "195px" }}
                      />
                    </div>
                  </Form.Group>
                  <div style={{ marginTop: -7 }} />
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <div className={`d-flex align-items-left form-group-custom`}>
                      <Form.Label style={{ width: '40%', textAlign: 'right', paddingRight: '15px' }}>Date Purchased</Form.Label>
                      <Form.Control
                        type="date"
                        // placeholder="Enter Value"
                        value={purchasedDate1}
                        onChange={(e) => setPurchaseDate1(e.target.value)}
                      />
                    </div>
                  </Form.Group>
                </Form>
                {/* <Form style={{ marginTop: 20 }}>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label style={{ width: '40%', textAlign: 'right', paddingRight: '15px' }}>Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Stock Name"
                      // autoFocus
                      value={name1}
                      onChange={(e) => setName1(e.target.value)}
                    />
                    <div style={{ marginTop: 10 }} />
                    <Form.Label style={{ width: '40%', textAlign: 'right', paddingRight: '15px' }}>Description</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Description"
                      // autoFocus
                      value={description1}
                      onChange={(e) => setDescription1(e.target.value)}
                    />
                    <div style={{ marginTop: 10 }} />
                    <Form.Label style={{ width: '40%', textAlign: 'right', paddingRight: '15px' }}>Unit of Measurement</Form.Label>
                    <Form.Select
                      type="text"
                      placeholder="Enter Description"
                      // autoFocus
                      value={unit1}
                      onChange={handleUnitChange1}
                    >
                      <option value="" disabled>Select Unit of Measurement</option>
                      {roless.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </Form.Select>

                    <div style={{ marginTop: 10 }} />
                    <Form.Label style={{ width: '40%', textAlign: 'right', paddingRight: '15px' }}>Unit Price</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter unit price"
                      // autoFocus
                      value={price1}
                      onChange={(e) => setPrice1(e.target.value)}
                    />

                    <div style={{ marginTop: 10 }} />
                    <Form.Label style={{ width: '40%', textAlign: 'right', paddingRight: '15px' }}>Opening Stock Balance</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Opening Stock Balance"
                      // autoFocus
                      value={stockBalance1}
                      onChange={(e) => setStockBalance1(e.target.value)}
                    />

                    <div style={{ marginTop: 10 }} />
                    <Form.Label style={{ width: '40%', textAlign: 'right', paddingRight: '15px' }}>Re-Order Level</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Input Re-Order Level"
                      // autoFocus
                      value={reOderLevel1}
                      onChange={(e) => setReOrderLevel1(e.target.value)}
                    />

                    <div style={{ marginTop: 10 }} />
                    <Form.Label style={{ width: '40%', textAlign: 'right', paddingRight: '15px' }}>GL</Form.Label>
                    <Form.Select
                      type="text"
                      placeholder="Enter Price"
                      value={glCode1}
                      onChange={handleGlChange1}
                    >
                      <option value="" disabled>Select GL</option>
                      {tableData2.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.gl_name}
                        </option>
                      ))}


                    </Form.Select>

                    <div style={{ marginTop: 10 }} />

                  </Form.Group>
                </Form> */}
              </Modal.Body>

              <Modal.Footer>
                <Button variant="danger" onClick={handleClose1}>
                  Go back
                </Button>

                <Button variant="success" onClick={updateAsset} >
                  {updateLoading ? (<> <Spinner size='sm' />
                    <span style={{ marginLeft: '5px' }}>
                      Updating asset, Please wait...
                    </span>
                  </>) : ("Save Changes")}
                </Button>
              </Modal.Footer>
            </Modal>


            <div className={`d-flex justify-content-between align-items-center ${classes.btnStyle}`}>
              <div className={`${classes.greenbtn} ${classes.btndivStyle}`}>
                <div className={classes.actionsBtns}>
                  <Button variant='success'>Copy</Button>
                  <Button variant='success'>Excel</Button>
                  <Button variant='success'>PDF</Button>
                  <Button variant='success'>Column visibility</Button>
                </div>
                <div className={classes.show}>
                  <label className="d-flex justify-content-start align-items-center">
                    Show
                    <select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" className="custom-select custom-select-sm form-control form-control-sm"
                    //  value={entriesPerPage}
                    //     onChange={(e) => {
                    //     setEntriesPerPage(parseInt(e.target.value));
                    //     setCurrentPage(1);
                    //     }}
                    >
                      <option value={10}>10</option>
                      <option value={25}>25</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                    </select>
                    entries
                  </label>
                </div>
              </div>
              <div className="text-right modal-effect ">
                <div id="DataTables_Table_0_filter" className="dataTables_filter">
                  <div className="d-flex justify-content-start align-items-center">
                    <div className="mr-2">Search:</div>
                    <input
                      type="search"
                      value={searchTerm}
                      className="form-control form-control-sm"
                      placeholder=""
                      aria-controls="DataTables_Table_0"
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    {/* <Button style={{marginLeft: 10}} variant="success" onClick= {() => fetchSearch(searchTerm)}>
                  {searchLoading ? (
                      <>
                      <Spinner  size='sm' /> 
                     
    </>
  ) : (
                "Search"
              )}
                  </Button> */}
                  </div>


                </div>
              </div>
            </div>
            <div className={classes.mainTable}>
              {roleLoading ? (
                <p>Fetching assets...</p>
              ) : (
                <div className="table-responsive">
                  <table className="table display table-bordered table-striped table-hover bg-white m-0 card-table">

                    <thead style={{ whiteSpace: 'nowrap' }}>
                      <tr>
                        <th>S/N</th>
                        <th>Identification Number</th>
                        <th>Asset Name</th>
                        <th>Category</th>
                        {/* <th>Quantity</th> */}
                        <th>Date Purchased</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody style={{ whiteSpace: 'nowrap', }}>

                      {displayedData.map((item, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td style={{ whiteSpace: 'wrap', }}>{item.identification_number}</td>
                          <td style={{ textAlign: "left", whiteSpace: 'wrap', }}>{item.asset_name}</td>
                          <td style={{ textAlign: "left", whiteSpace: 'wrap', }}>{item.asset_category?.description}</td>
                          {/* <td style={{ textAlign: "left" }}>{item.quantity}</td> */}
                          <td>{item.date_purchased}</td>
                          {/* <td>{item.re_order_level}</td> */}
                          <td>
                            <div onClick={() => handleEyeClick(item.id)} className="btn btn-success-soft btn-sm mr-1">
                              <i className="far fa-eye" style={{ color: "#008a4b", backgroundColor: "#28a7451a", padding: 2, borderColor: "#28a7454d", borderRadius: 5, fontSize: 12 }}></i>
                            </div>
                            <div onClick={() => handleTrashClick(item.id)} className="btn btn-danger-soft btn-sm">
                              <i className="far fa-trash-alt" style={{ color: "#dc3545", backgroundColor: "#dc35451a", padding: 2, borderColor: "#dc35454d", borderRadius: 5, fontSize: 12 }}></i>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>

            <div className={classes.endded}>
              <p>
                Showing {startIndexx} to {endIndexx} of {totalEntries} entries
              </p>
              <div style={{ display: 'flex' }}>
                <button
                  style={{ border: 'none', backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'nunito', color: '#000', marginRight: 10, cursor: "pointer" }}
                  onClick={handlePrevPage}
                  disabled={currentPage === 1}
                >
                  Previous
                </button>
                {[...Array(totalPages)].map((_, page) => {
                  // Show only 5 pages or less if available
                  if (page < 5 || page === currentPage - 1 || page === totalPages - 1) {
                    return (
                      <button
                        key={page + 1}
                        style={{
                          marginLeft: '0.4rem',
                          marginRight: '0.4rem',
                          fontSize: '14px',
                          fontFamily: 'nunito',
                          fontWeight: 400,
                          color: page + 1 === currentPage ? '#ffffff' : '#000',
                          backgroundColor: page + 1 === currentPage ? '#28a745' : 'gainsboro',
                          height: '2.5rem',
                          borderRadius: '89px',
                          padding: '0.5rem',
                          border: 'none',
                          width: '40px',
                          cursor: "pointer"
                        }}
                        onClick={() => setCurrentPage(page + 1)}
                      >
                        {page + 1}
                      </button>
                    );
                  }
                  return null;
                })}
                <button
                  style={{ cursor: "pointer", border: 'none', backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'nunito', color: '#000', marginLeft: 10 }}
                  onClick={handleNextPage}
                  disabled={currentPage === totalPages}
                >
                  Next
                </button>
              </div>
            </div>

          </div>
        </div>

      </div>






    </div >
  )
}