import React, { useState, useEffect } from 'react';
import { Button, Modal, Form, Spinner, Tab, Tabs } from 'react-bootstrap';
// import { AdminHeaderNav } from '../AdminHeaderNav';
// import { InfoFooter } from '../../InfoFooter';
import classes from './Customers.module.css';
// import favicon from '../../Images/faviconn.png';
import MainDashoard from '../../Main Dashboard/MainDashoard';
import LatestDash from '../LatestDash';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { ToastContainer } from 'react-toastify';
import Select from 'react-dropdown-select';
import { BANKS, COUNTRIES, STATES } from '../../api/country';

function EmployeesAccountUi({
  show,
  user,
  stat,
  roleLoading,
  handleEyeClick2,
  handleTrashClick1,
  handleTrashClick2,
  archiveData,
  show1,
  show56,
  handleShow,
  handleShow56,
  handleShow1,
  handleClose,
  handleClose56,
  statLoading,
  handleClose1,
  createCustomer,
  editCustomer,
  isLoading,
  loading,
  searchTerm,
  setSearchTerm,
  entriesPerPage,
  setEntriesPerPage,
  currentPage,
  setCurrentPage,
  totalEntries,
  totalPages,
  startIndexx,
  benLoading,
  endIndexx,
  displayedData,
  uploadLoading,
  handlePrevPage,
  handleNextPage,
  handleEyeClick,
  handleTrashClick,
  fullName,
  setFullName,
  address,
  setAddress,
  office_address,
  setOfficeAddress,
  phone,
  setPhone,
  email,
  setEmail,
  fullName1,
  setFullName1,
  phone1,
  setPhone1,
  email1,
  setEmail1,
  tableData,
  formatDate,
  name,
  title,
  gender,
  number,
  marital,
  bank,
  medical,
  dob,
  doa,
  pay,
  state,
  city,
  eod,
  lga,
  country,
  qualification,
  setfullName,
  setPay,
  setMarital,
  setBank,
  setGender,
  setNumber,
  setQualification,
  setCountry,
  setCity,
  setState,
  setDob,
  setLga,
  accountnumber,
  setEod,
  setDoa,
  setAccountnumber,
  setMedical,
  setTitle,
  handleFileChange,
  uploadExcel,
  fetchSearch,
  setSearchedResult,
  searchedResult,
  searchLoading,
  handleEyeClick1,
  departments,
  designation,
  level,
  step,
  grade,
  handleDepartmentChange,
  handleDesignationChange,
  handleRoleChange,
  handleStepChange,
  handleGradeChange,
  selectedStep,
  selectedRole,
  selectedDesignation,
  selectedDepartment,
  selectedGrade

}) {
  const formattedTotalEntries = totalEntries.toLocaleString();
  const [stateDisabled, setStateDisabled] = useState(true);

 
  const handleCountryChange = (e) => {
    const selectedCountry = e.target.value;
    setCountry(selectedCountry);
    setStateDisabled(!(selectedCountry === 'Nigeria'));
    setState('');
  }

  return (
    <div className={classes.latestBody}>
      <ToastContainer />
      <LatestDash />


      <div className={classes.finishedbodyCont}>
        {/* <div className={classes.formLabel}> */}
        {/* <h3 className={classes.productText1}>Customers</h3> */}
        <div style={{ display: "flex", flexDirection: 'row', justifyContent: "space-between", alignItems: "center", marginBottom: 30 }}>
          <div style={{ display: "flex", flexDirection: 'column' }}>
            <h3 style={{ fontSize: 30, fontWeight: 700, margin: " 10px 10px 0  0" }}>Employees</h3>
            <p style={{ margin: "0 10px 20px 0" }}>Upload, View, Edit and Delete Employees</p>
          </div>
          <div className={classes.laondetheadTopCards}>
            <div className={classes.card}>
              <p>Total Number of Employees</p>
              <h4> {statLoading ? <Spinner size='sm' /> : stat?.total_number_of_customers}</h4>
            </div>

          </div>
        </div>
        <div className={classes.mainform}>

          <div className={classes.loandgrantcards}>

            <div className={classes.loandethead1}>




              <div className={classes.formIntBtn}>
                <OverlayTrigger
                  placement="top"
                  className={classes.btndf1}
                  overlay={<Tooltip id="button-tooltip" className={classes.btndf1}>Click here to add new Employee</Tooltip>}
                >
                  <Button className={classes.btn2} variant="success" onClick={handleShow} >
                    Add New Employee
                  </Button>
                </OverlayTrigger>

                <OverlayTrigger
                  placement="top"
                  className={classes.btndf1}
                  overlay={<Tooltip id="button-tooltip" className={classes.btndf1}>Click here to upload customers in excel format</Tooltip>}
                >
                  <Button className={classes.btn1} variant="secondary" onClick={handleShow56}>
                    Upload Employee
                  </Button>
                </OverlayTrigger>

                <a style={{ marginLeft: 10 }} href="https://api-sme.promixaccounting.com/api/v1/download-member-template" download>
                  <OverlayTrigger
                    placement="top"
                    className={classes.btndf1}
                    overlay={<Tooltip id="button-tooltip" className={classes.btndf1}>Click here to download excel template for uploading use</Tooltip>}
                  >

                    <Button variant="success" className={classes.btn22}>
                      Download Template
                    </Button>
                  </OverlayTrigger>
                </a>

              </div>
            </div>
            <Modal show={show56} onHide={handleClose56} animation={false}>
              <Modal.Header closeButton>
                <Modal.Title>Upload Employee</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form style={{ marginTop: 20 }}>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Upload Excel File</Form.Label>
                    <Form.Control
                      type="file"
                      accept=".xlsx, .xls, .csv"
                      onChange={handleFileChange}
                    />
                  </Form.Group>
                </Form>
              </Modal.Body>







              <Modal.Footer>
                <Button variant="danger" onClick={handleClose56}>
                  Go back
                </Button>
                <Button variant="success" onClick={uploadExcel}>
                  {uploadLoading ? (
                    <>
                      <Spinner size='sm' />
                      <span style={{ marginLeft: '5px' }}>Uploading, Please wait...</span>
                    </>
                  ) : (
                    "Upload"
                  )}
                </Button>
              </Modal.Footer>
            </Modal>

            <Modal show={show} onHide={handleClose} animation={false}>
              <Modal.Header closeButton>
                <Modal.Title>Add Employee</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form style={{ marginTop: 20 }}>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">

                    <Form.Label>Title</Form.Label>
                    <Form.Select className="form-control"
                      as="select"
                      // placeholder="Select title"
                      // autoFocus
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                    >
                      <option value="" disabled>Select Title</option>
                      <option value="Mr">Mr</option>
                      <option value="Mrs">Mrs</option>
                      <option value="Miss">Miss</option>
                      <option value="Dr">Dr</option>
                      <option value="Prof">Prof</option>
                    </Form.Select>
                    <div style={{ marginTop: 10 }} />
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Full Name"
                      // autoFocus
                      value={name}
                      onChange={(e) => setfullName(e.target.value)}
                    />

                    <div style={{ marginTop: 10 }} />
                    <Form.Label>Employee Number</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Number"
                      // autoFocus
                      value={number}
                      onChange={(e) => setNumber(e.target.value)}
                    />
                    <div style={{ marginTop: 10 }} />
                    <Form.Label>Date of Birth</Form.Label>
                    <Form.Control
                      type="date"
                      placeholder="Select date of birth"
                      // autoFocus
                      value={dob}
                      onChange={(e) => setDob(e.target.value)}
                    />
                    <div style={{ marginTop: 10 }} />
                    <Form.Label>Gender</Form.Label>
                    <Form.Select className="form-control"
                      as="select"
                      // placeholder="Select title"
                      // autoFocus
                      value={gender}
                      onChange={(e) => setGender(e.target.value)}
                    >
                      <option value="" disabled>Select Gender</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>

                    </Form.Select>
                    <div style={{ marginTop: 10 }} />
                    <Form.Label>Marital Status</Form.Label>
                    <Form.Select className="form-control"
                      as="select"
                      // placeholder="Select title"
                      // autoFocus
                      value={marital}
                      onChange={(e) => setMarital(e.target.value)}
                    >
                      <option value="" disabled>Select Marital Status</option>
                      <option value="Single">Single</option>
                      <option value="Married">Married</option>

                    </Form.Select>
                    <div style={{ marginTop: 10 }} />
                    <Form.Label>Address</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Address"
                      // autoFocus
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                    />
                     <div style={{ marginTop: 10 }} />
                    <Form.Label>Country</Form.Label>
                    <Form.Select
  className="form-control"
  as="select"
  value={country}
  onChange={handleCountryChange}
>
  <option value="" disabled>Select Country</option>
  {COUNTRIES.map((countryName, index) => (
    <option key={index} value={countryName}>
      {countryName}
    </option>
  ))}
</Form.Select>
                    <div style={{ marginTop: 10 }} />
                    <Form.Label>State</Form.Label>
                    <Form.Select className="form-control"
                      as="select"
                      // placeholder="Select title"
                      // autoFocus
                      value={state}
                      onChange={(e) => setState(e.target.value)}
                      disabled={stateDisabled}
                    >
                      <option value="" disabled>Select State</option>
                      {STATES.map((countryName, index) => (
    <option key={index} value={countryName}>
      {countryName}
    </option>
  ))}

                    </Form.Select>
                    {/* <div style={{ marginTop: 10 }} /> */}
                    {/* <Form.Label>City</Form.Label>
                    <Form.Select className="form-control"
                      as="select"
                      // placeholder="Select title"
                      // autoFocus
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                    >
                      <option value="" disabled>Select City</option>
                      <option value="Ikeja">Ikeja</option>
                      <option value="Ojodu">Ojodu</option>

                    </Form.Select> */}
                   
                    {/* <div style={{ marginTop: 10 }} />
                    <Form.Label>Local Government</Form.Label>
                    <Form.Select className="form-control"
                      as="select"
                    
                      value={lga}
                      onChange={(e) => setLga(e.target.value)}
                    >
                      <option value="" disabled>Select local government</option>
                      <option value="Odeda">Odeda</option>
                      <option value="Abeokuta North">Abeokuta North</option>

                    </Form.Select> */}
                    
                    <div style={{ marginTop: 10 }} />
                    <Form.Label>Medical Condition</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Medical Condition"
                      // autoFocus
                      value={medical}
                      onChange={(e) => setMedical(e.target.value)}
                    />
                    <div style={{ marginTop: 10 }} />
                    <Form.Label>Net Pay </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter pay"
                      // autoFocus
                      value={pay}
                      onChange={(e) => setPay(e.target.value)}
                    />
                    
                    <div style={{ marginTop: 10 }} />
                    <Form.Label>Employment Date</Form.Label>
                    <Form.Control
                      type="date"
                      placeholder="Select employment date"
                      // autoFocus
                      value={eod}
                      onChange={(e) => setEod(e.target.value)}
                    />
                    {/* <div style={{ marginTop: 10 }} />
                    <Form.Label>Date of Appointment</Form.Label>
                    <Form.Control
                      type="date"
                      placeholder="Select appointment date"
                      // autoFocus
                      value={doa}
                      onChange={(e) => setDoa(e.target.value)}
                    /> */}
                  
                    <div style={{ marginTop: 10 }} />
                    <Form.Label>Qualifiation</Form.Label>
                    <Form.Select className="form-control"
                      as="select"
                      // placeholder="Select title"
                      // autoFocus
                      value={qualification}
                      onChange={(e) => setQualification(e.target.value)}
                    >
                      <option value="" disabled>Select qualifiation</option>
                      <option value="SSCE">Senior School Certificate (SSCE)</option>
                      <option value="ND">National Diploma (ND)</option>
                      <option value="HND">Higher National Diploma (HND)</option>
                      <option value="Bsc">Bachelors Degree</option>
                      <option value="Masters">Masters Degree</option>
                      <option value="Phd">PHD</option>

                    </Form.Select>
                    <div style={{ marginTop: 10 }} />
                    <Form.Label>Bank</Form.Label>
                    <Form.Select className="form-control"
                      as="select"
                      // placeholder="Select title"
                      // autoFocus
                      value={bank}
                      onChange={(e) => setBank(e.target.value)}
                    >
                      <option value="" disabled>Select Bank</option>
                      {BANKS.map((countryName, index) => (
    <option key={index} value={countryName}>
      {countryName}
    </option>
  ))}

                    </Form.Select>
                    <div style={{ marginTop: 10 }} />
                    <Form.Label>Account Number</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Enter Account Number"
                      // autoFocus
                      value={accountnumber}
                      onChange={(e) => setAccountnumber(e.target.value)}
                    />
                    
                    <div style={{ marginTop: 10 }} />
                    <Form.Label>Phone Number</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Enter Phone Number"
                      // autoFocus
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                    <div style={{ marginTop: 10 }} />
                    <Form.Label>Email Address</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Email Address"
                      // autoFocus
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <div style={{ marginTop: 10 }} />
                    <Form.Label>Department</Form.Label>
                    <Form.Select className="form-control"
                      as="select"
                      value={selectedDepartment}
                      onChange={handleDepartmentChange}
                    >
                      <option value="" disabled>Select Type</option>
                      {departments.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </Form.Select>
                    <div style={{ marginTop: 10 }} />
                    <Form.Label>Designation</Form.Label>
                    <Form.Select className="form-control"
                      as="select"
                      value={selectedDesignation}
                      onChange={handleDesignationChange}
                    >
                      <option value="" disabled>Select Type</option>
                      {designation.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </Form.Select>
                    <div style={{ marginTop: 10 }} />
                    <Form.Label>Level</Form.Label>
                    <Form.Select className="form-control"
                      as="select"
                      value={selectedRole}
                      onChange={handleRoleChange}
                    >
                      <option value="" disabled>Select Type</option>
                      {Array.isArray(level) && level.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.description}
                        </option>
                      ))}
                    </Form.Select>
                    <div style={{ marginTop: 10 }} />
                    <Form.Label>Step</Form.Label>
                    <Form.Select className="form-control"
                      as="select"
                      value={selectedStep}
                      onChange={handleStepChange}
                    >
                      <option value="" disabled>Select Type</option>
                      {Array.isArray(step) && step.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.description}
                        </option>
                      ))}
                    </Form.Select>
                    <div style={{ marginTop: 10 }} />
                    <Form.Label>Grade</Form.Label>
                    <Form.Select className="form-control"
                      as="select"
                      value={selectedGrade}
                      onChange={handleGradeChange}
                    >
                      <option value="" disabled>Select Type</option>
                      {grade.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.description}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Form>
              </Modal.Body>

              <Modal.Footer>
                <Button variant="danger" onClick={handleClose}>
                  Go back
                </Button>
                <Button variant="success" onClick={createCustomer}>
                  {loading ? (
                    <>
                      <Spinner size='sm' />
                      <span style={{ marginLeft: '5px' }}>Creating employee, Please wait...</span>
                    </>
                  ) : (
                    "Create Employee"
                  )}
                </Button>
              </Modal.Footer>
            </Modal>

            <Tabs
              defaultActiveKey="Employee"
              id="uncontrolled-tab-example"
              className="mb-3 complete-tabs"
              variant="underline"
              color="#2D995F"
            >
              <Tab eventKey="Employee" title="Employee">

                <div className={`d-flex justify-content-between align-items-center ${classes.btnStyle}`}>
                  <div className={`${classes.greenbtn} ${classes.btndivStyle}`}>
                    <div className={classes.actionsBtns}>
                      <Button variant='success'>Copy</Button>
                      <Button variant='success'>Excel</Button>
                      <Button variant='success'>PDF</Button>
                      <Button variant='success'>Column visibility</Button>
                    </div>
                    <div className={classes.show}>
                      <label className="d-flex justify-content-start align-items-center">
                        Show
                        <select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" className="custom-select custom-select-sm form-control form-control-sm"
                        //  value={entriesPerPage}
                        //     onChange={(e) => {
                        //     setEntriesPerPage(parseInt(e.target.value));
                        //     setCurrentPage(1);
                        //     }}
                        >
                          <option value={10}>10</option>
                          <option value={25}>25</option>
                          <option value={50}>50</option>
                          <option value={100}>100</option>
                        </select>
                        entries
                      </label>
                    </div>
                  </div>
                  <div className="text-right modal-effect ">
                    <div id="DataTables_Table_0_filter" className="dataTables_filter">
                      <div className="d-flex justify-content-start align-items-center">
                        <div className="mr-2">Search:</div>
                        <input
                          type="search"
                          value={searchTerm}
                          className="form-control form-control-sm"
                          placeholder=""
                          aria-controls="DataTables_Table_0"
                          onChange={(e) => setSearchTerm(e.target.value)}
                        />
                        <Button style={{ marginLeft: 10 }} variant="success" onClick={() => fetchSearch(searchTerm)}>
                          {searchLoading ? (
                            <>
                              <Spinner size='sm' />

                            </>
                          ) : (
                            "Search"
                          )}
                        </Button>
                      </div>


                    </div>
                  </div>
                </div>
                <div className={classes.mainTable}>
                  {benLoading ? (
                    <p>Fetching Employees...</p>
                  ) : (
                    <div className="table-responsive">
                      <table className="table display table-bordered table-striped table-hover bg-white m-0 card-table">
                        <thead style={{ whiteSpace: 'nowrap' }}>
                          <tr>
                            <th>S/N</th>
                            <th>Number</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Phone Number</th>
                            <th>Created At</th>
                            {/* <th>Outstanding Balance</th> */}
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody style={{ whiteSpace: 'nowrap' }}>
                          {(searchTerm.trim() ? searchedResult : tableData).length === 0 ? (
                            <tr>
                              <td colSpan="7">No data available</td>
                            </tr>
                          ) : (
                            (searchTerm.trim() ? searchedResult : tableData).map((item, index) => (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{item.rsa_number}</td>
                                <td style={{ width: 300, whiteSpace: 'wrap' }}>{item.name}</td>
                                <td style={{ textAlign: "left", width: 200, whiteSpace: 'wrap' }}>{item.email}</td>
                                <td>{item.phone_number}</td>
                                <td>{formatDate(item.created_at)}</td>
                                {/* <td style={{ textAlign: "right" }}> {parseFloat(item.balance).toLocaleString('en-US', {
                                  minimumIntegerDigits: 1,
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2
                                })}
                                </td> */}
                                <td>
                                  <OverlayTrigger
                                    placement="top"
                                    className={classes.btndf1}
                                    overlay={<Tooltip id="button-tooltip" className={classes.btndf1}>Click here to edit/update your employees</Tooltip>}
                                  >
                                    <div onClick={() => handleEyeClick(item.id)} style={{ padding: "2px 10px" }} className="btn btn-success-soft btn-sm mr-1">
                                      <i class="fas fa-pencil-alt" style={{ color: "#008a4b", backgroundColor: "#28a7451a", borderColor: "#28a7454d", borderRadius: 5, fontSize: 12 }}></i>
                                    </div>
                                  </OverlayTrigger>

                                  <OverlayTrigger
                                    placement="top"
                                    className={classes.btndf1}
                                    overlay={<Tooltip id="button-tooltip" className={classes.btndf1}>Click here to move your employees to archive</Tooltip>}
                                  >
                                    <div onClick={() => handleTrashClick(item.id)} style={{ padding: "0", marginRight: 10 }} className="btn btn-danger-soft btn-sm">
                                      <i class="fa fa-archive" style={{ color: "#000", backgroundColor: "#dc35451a", borderColor: "#dc35454d", borderRadius: 5, fontSize: 12 }}></i>
                                    </div>
                                  </OverlayTrigger>


                                  <OverlayTrigger
                                    placement="top"
                                    className={classes.btndf1}
                                    overlay={<Tooltip id="button-tooltip" className={classes.btndf1}>Click here to permanently delete your employees</Tooltip>}
                                  >
                                    <div onClick={() => handleTrashClick1(item.id)} style={{ padding: "0" }} className="btn btn-danger-soft btn-sm">
                                      <i className="far fa-trash-alt" style={{ color: "#dc3545", backgroundColor: "#dc35451a", borderColor: "#dc35454d", borderRadius: 5, fontSize: 12 }}></i>
                                    </div>
                                  </OverlayTrigger>
                                </td>
                              </tr>
                            ))
                          )}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>

                <div className={classes.endded}>
                  <p>
                    Showing {`Page ${currentPage} to ${totalPages} pages`} of {formattedTotalEntries} entries
                  </p>
                  <div style={{ display: 'flex' }}>
                    <button
                      style={{ border: 'none', backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'nunito', color: '#000', marginRight: 10, cursor: "pointer" }}
                      onClick={handlePrevPage}
                      disabled={currentPage === 1}
                    >
                      Previous
                    </button>
                    {[...Array(totalPages)].map((_, page) => {
                      // Show only 5 pages or less if available
                      if (page < 5 || page === currentPage - 1 || page === totalPages - 1) {
                        return (
                          <button
                            key={page + 1}
                            style={{
                              marginLeft: '0.4rem',
                              marginRight: '0.4rem',
                              fontSize: '14px',
                              fontFamily: 'nunito',
                              fontWeight: 400,
                              color: page + 1 === currentPage ? '#ffffff' : '#000',
                              backgroundColor: page + 1 === currentPage ? '#28a745' : 'gainsboro',
                              height: '2.5rem',
                              borderRadius: '89px',
                              padding: '0.5rem',
                              border: 'none',
                              width: '40px',
                              cursor: "pointer"
                            }}
                            onClick={() => setCurrentPage(page + 1)}
                          >
                            {page + 1}
                          </button>
                        );
                      }
                      return null;
                    })}
                    <button
                      style={{ cursor: "pointer", border: 'none', backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'nunito', color: '#000', marginLeft: 10 }}
                      onClick={handleNextPage}
                      disabled={currentPage === totalPages}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </Tab>

            </Tabs>
            <Modal show={show1} onHide={handleClose1} animation={false}>
              <Modal.Header closeButton>
                <Modal.Title>Edit User</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form style={{ marginTop: 20 }}>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Full Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Full Name"
                      // autoFocus
                      value={fullName1}
                      onChange={(e) => setFullName1(e.target.value)}
                    />
                    <div style={{ marginTop: 10 }} />
                    <Form.Label>Email Address</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Email Address"
                      // autoFocus
                      value={email1}
                      onChange={(e) => setEmail1(e.target.value)}
                    />
                    <div style={{ marginTop: 10 }} />
                    <Form.Label>Phone Number</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Phone Number"
                      // autoFocus
                      value={phone1}
                      onChange={(e) => setPhone1(e.target.value)}
                    />

                  </Form.Group>
                </Form>
              </Modal.Body>






              <Modal.Footer>
                <Button variant="danger" onClick={handleClose1}>
                  Go back
                </Button>
                <Button variant="success" onClick={editCustomer} >                             {loading ? <Spinner id="loader" animation="border" variant="warning" /> : 'Save Changes'}
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>

      </div>






    </div >
  );
}

export default EmployeesAccountUi;