import React, { useState, useEffect } from 'react';
import classes from "./TopNavBar.module.css";
// import home from '../../Assets/Home.svg'
import home from "../../assets/welc/Home.svg";
import notification from "../../assets/welc/notification.svg";
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { BASE_URL } from '../api/api';
import { Dropdown, Spinner } from 'react-bootstrap';
// import { Dropdown, DropdownButton } from "react-bootstrap";
// import { AuthContext } from "../../Pages/utils/AuthContext";
// import Dropdown from '../Select/Select'

const TopNavBar = () => {
  const navigate = useNavigate();
  const [bearer, setBearer] = useState('');
  const [admin, setAdmin] = useState("");
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState('');
  const [company, setCompany] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [createLoading, setCreateLoading] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const [validity, setValidity] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const [entriesPerPage, setEntriesPerPage] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalEntries, setTotalEntries] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [isUpdateMode, setIsUpdateMode] = useState(false);
  const [moduleLoading, setModuleLoading] = useState(false);
  const [permId, setPermId] = useState([]);
  const [checkAll, setCheckAll] = useState(false);
  const [permits, setPermits] = useState([]);
  const [error, setError] = useState(null);

  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem('userToken');
      const value1 = await AsyncStorage.getItem('tobi');
      // const value2 = await AsyncStorage.getItem('permissions');
      const value3 = await AsyncStorage.getItem('admin');
      const value7 = await AsyncStorage.getItem('companyName');
      const value8 = await AsyncStorage.getItem('validity');
      const permitsss = await AsyncStorage.getItem('modules');

      if (value !== null && value.trim() !== "") {
        setBearer(value);
      } else {
        navigate('/login');
        return;
      }
      if (permitsss !== null) {
        const parsedPermits = JSON.parse(permitsss);
        const permitNames = parsedPermits.map(permit => permit.name);
        setPermits(permitNames);
        // console.log(permitNames);
      }
      if (value1 !== null) {
        setUser(value1);
      }
      if (value8 !== null) {
        setValidity(value8);
      }
      // if (value2 !== null) {
      //   setPermissions(value2);
      // }
      if (value3 !== null) {
        setAdmin(value3);
      }
      if (value7 !== null) {
        setCompany(value7);
      }
    } catch (e) {
      alert('Failed to fetch the input from storage');
    }
  };

  useEffect(() => {
    readData();
  }, []);

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${bearer}`
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const formattedDate = `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())} `;
    return formattedDate;
  }

  function padZero(num) {
    return num < 10 ? `0${num}` : num;
  }

  const [isDropdownVisible, setDropdownVisible] = useState(false);

  const toggleDropdown = () => {
    setDropdownVisible(!isDropdownVisible);
  };


  const handleLogout = async () => {
    setLoading(true);

    try {
      const response = await axios.post(
        `${BASE_URL}/logout`,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearer}`
          }
        }
      );

      navigate('/login');
      //   toast.success(response.data.message);
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response) {
        if (error.response.status === 401) {
          navigate('/login');
        } else if (error.response.data && error.response.data.message) {
          if (typeof error.response.data.message === 'string') {
            errorMessage = error.response.data.message;
          } else if (Array.isArray(error.response.data.message)) {
            errorMessage = error.response.data.message.join('; ');
          } else if (typeof error.response.data.message === 'object') {
            errorMessage = JSON.stringify(error.response.data.message);
          }
        }
      }
      //   toast.error(errorMessage);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };



  return (
    <div>
      <div className={classes.topTopNav}>
        <div className={classes.top2}>
          <div className={classes.homeDiv}>
            <Link to="/">
              {" "}
              <img src={home} alt="home" />
              <p className={classes.home}>Home</p>
            </Link>
          </div>
          <div className={classes.secondSTyle}>
            <div className={classes.styledDiv}>
              <p className={classes.company}>COMPANY</p>
              <p className={classes.companyName}>{company.toLocaleUpperCase()}</p>
              <p className={classes.companyName1}>Valid Till {formatDate(validity)}</p>
            </div>
            <Dropdown>
              <Dropdown.Toggle id="dropdown-report1" className="transparent-dropdown">
                <span>
                {loading ? (
           <Spinner size="lg" animation="border" variant="success" />
          ) : (
            <img src={notification} alt="notification" />
          )}
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>
    </div>
    // </div>
  );
};

export default TopNavBar;
