import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
// import OnbImg from '../../Images/image bg.png';
import classes from './LatestDashboard.module.css';
// import loanicon from '../../Images/moneys.png'
// import loaniconblue from '../../Images/moneysblue.png'
// import loanicongreen from '../../Images/receipt-2.png'
// import axios from 'axios';
// import AsyncStorage from '@react-native-async-storage/async-storage';
// import { Button,  } from 'react-bootstrap';
// import RegLogo from '../../Images/RegistrationLogo.svg'
import { Link } from 'react-router-dom'
import MainDashboard from '../Main Dashboard/MainDashoard';
import dChart1 from '../../assets/promix/dShart1.svg'
import dChart2 from '../../assets/promix/dShart2.svg'
import dChart3 from '../../assets/promix/dShart3.svg'
import dChart4 from '../../assets/promix/dShart4.svg'
import dChart5 from '../../assets/promix/dShart5.svg'
import dChart6 from '../../assets/promix/dShart6.svg'
import dChart7 from '../../assets/promix/dShart7.svg'
import dChart8 from '../../assets/promix/dShart8.svg'
import Arrow from '../../assets/promix/dArrow-down.svg'
import AsyncStorage from '@react-native-async-storage/async-storage';
import CoopDashboard from '../Cooperative Dashboard/CoopDashboard';
import ReportDashboard from '../ReportDashboard/ReportDashboard';
import CashDash from '../Cash Dash/CashDash';
import ImageIcon from '../../assets/promix/Group 1.png';
import { Chart } from 'react-charts'
import LatestMenu from './LatestMenu';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Button, Modal, Form, Spinner, ProgressBar} from 'react-bootstrap';
// import {  Spinner } from 'react-bootstrap';
// import AsyncStorage from '@react-native-async-storage/async-storage';
import { BASE_URL } from '../api/api';
import icon7 from '../../assets/promix/vuesax/log.png';
import { toast } from 'react-toastify';


const LatestDashboard = () => {
    const navigate = useNavigate();
    const [bearer, setBearer] = useState('');
    const [user, setUser] = useState('');
    const [company, setCompany] = useState('');
    const [userType, setUserType] = useState('');
    const [continent, setContinent] = useState('');
    const [region, setRegion] = useState('');
    const [province, setProvince] = useState('');

    const [validity, setValidity] = useState('');
    const [permits, setPermits] = useState([]);
    const [admin, setAdmin] = useState("");
    const [loading, setLoading] = useState(false);


    
  const [currentTime, setCurrentTime] = useState(new Date());
  const [benLoading, setBenLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [show56, setShow56] = useState(false);
  const [show1, setShow1] = useState(false);
//   const [bearer, setBearer] = useState('');/
  const [isLoading, setIsLoading] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);
  const [role, setRole] = useState("");
  const [role1, setRole1] = useState("");
  const [checkAll, setCheckAll] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [loadings, setLoadings] = useState(false);
  const [loadingss, setLoadingss] = useState(false);

  const [department, setDepartment] = useState("");
  const [department1, setDepartment1] = useState("");
  const [deptId, setDeptId] = useState("");
  const handleClose = () => setShow(false);
  const handleClose56 = () => setShow56(false);
  const handleClose1 = () => setShow1(false);
  const handleShow = () => setShow(true);
  const handleShow56 = () => setShow56(true);
  const handleShow1 = () => setShow1(true);
  const [totalEntries, setTotalEntries] = useState("");

  const [eyeClicked, setEyeClicked] = useState(false);
  const [trashClicked, setTrashClicked] = useState(false);
  const [perm, setPerm] = useState([]);
  const [permId, setPermId] = useState([]);
  const [fullName, setFullName] = useState("");
  const [fullName1, setFullName1] = useState("");
  const [email, setEmail] = useState("");
  const [email1, setEmail1] = useState("");
  const [phone1, setPhone1] = useState("");
  const [phone, setPhone] = useState("");
  const [roles, setRoles] = useState([]);
  const [address, setAddress] = useState("");
  const [office_address, setOfficeAddress] = useState("");
  const [entriesPerPage, setEntriesPerPage] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [customers, setCustomers] = useState('');
  
  const [uploadLoading, setUploadLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [totalPages, setTotalPages] = useState(1);
  
    

    const readData = async () => {
        try {
          const value = await AsyncStorage.getItem('userToken');
          const value1 = await AsyncStorage.getItem('tobi');
          // const value2 = await AsyncStorage.getItem('permissions');
          const value3 = await AsyncStorage.getItem('admin');
          const value7 = await AsyncStorage.getItem('companyName');
          const value8 = await AsyncStorage.getItem('validity');
          const permitsss = await AsyncStorage.getItem('modules');
    
          if (value !== null) {
            setBearer(value);
          }
          if (permitsss !== null) {
              const parsedPermits = JSON.parse(permitsss);
              const permitNames = parsedPermits.map(permit => permit.name);
              setPermits(permitNames);
              // console.log(permitNames);
          }
          if (value1 !== null) {
            setUser(value1);
          }
          if (value8 !== null) {
            setValidity(value8);
          }
          // if (value2 !== null) {
          //   setPermissions(value2);
          // }
          if (value3 !== null) {
            setAdmin(value3);
          }
          if (value7 !== null) {
            setCompany(value7);
        }
        } catch (e) {
          alert('Failed to fetch the input from storage');
        }
      };
    
      useEffect(() => {
        readData();
      }, []);
    

      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${bearer}`
      };

    const data = [
        {
            label: 'Money In',
            data: [
                { primary: 'March 2024', secondary: 3_200_000 },
                { primary: 'April 2024', secondary: 2_800_000 },
                { primary: 'May 2024', secondary: 3_600_000 },
                { primary: 'June 2024', secondary: 4_000_000 },
            ],
        },
        {
            label: 'Money Out',
            data: [
                { primary: 'March 2024', secondary: 1_500_000 },
                { primary: 'April 2024', secondary: 2_000_000 },
                { primary: 'May 2024', secondary: 2_500_000 },
                { primary: 'June 2024', secondary: 3_000_000 },
            ],
        },
    ];

    const chartOptions = {
        primaryAxis: {
            getValue: datum => datum.primary,
            type: 'ordinal',
        },
        secondaryAxes: [
            {
                getValue: datum => datum.secondary,
                elementType: 'bar',
                min: 1_000_000,
                max: 4_000_000,
                tickFormat: d => `${(d / 1_000_000).toFixed(1)}M`,
            },
        ],
        getSeriesStyle: series => ({
            color: series.label === 'Money In' ? '#2D995F' : '#FFBA08',
        }),
    };

    const Legend = ({ data }) => (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
            {data.map(series => (
                <div key={series.label} style={{ display: 'flex', alignItems: 'center', margin: '0 10px' }}>
                    <div
                        style={{
                            width: '10px',
                            height: '10px',
                            backgroundColor: series.label === 'Money In' ? '#2D995F' : '#FFBA08',
                            marginRight: '10px',
                        }}
                    ></div>
                    <span>{series.label}</span>
                </div>
            ))}
        </div>
    );

    function formatDate(dateString) {
        const date = new Date(dateString);
        const formattedDate = `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())} `;
        return formattedDate;
      }

      const handleLogout = async () => {
        setLoading(true);

        try {
            const response = await axios.post(
                `${BASE_URL}/logout`,
                {},
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${bearer}`
                    }
                }
            );

            navigate('/login');
            //   toast.success(response.data.message);
        } catch (error) {
            let errorMessage = 'An error occurred. Please try again.';
            if (error.response) {
                if (error.response.status === 401) {
                    navigate('/login');
                } else if (error.response.data && error.response.data.message) {
                    if (typeof error.response.data.message === 'string') {
                        errorMessage = error.response.data.message;
                    } else if (Array.isArray(error.response.data.message)) {
                        errorMessage = error.response.data.message.join('; ');
                    } else if (typeof error.response.data.message === 'object') {
                        errorMessage = JSON.stringify(error.response.data.message);
                    }
                }
            }
            //   toast.error(errorMessage);
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    function formatDate(dateString) {
        const date = new Date(dateString);
        const formattedDate = `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())} `;
        return formattedDate;
      }
    
      function padZero(num) {
        return num < 10 ? `0${num}` : num;
      }


      const fetchBeneficiaries = async () => {
        setBenLoading(true);
        try {
            const response = await axios.get(`${BASE_URL}/customer?page=${currentPage}`, { headers });
            const results = response.data?.data?.data;
            const resultx = response.data?.data?.total;
            setTotalEntries(resultx);
            setTableData(results);
            const total = response.data?.data?.last_page || 1;
            setTotalPages(total);
            console.log(total);
          
        } catch (error) {
            if (error.response && error.response.status === 401) {
                navigate('/login');
            } else {
              let errorMessage = 'An error occurred. Please try again.';
              if (error.response && error.response.data && error.response.data.message) {
                  if (typeof error.response.data.message === 'string') {
                      errorMessage = error.response.data.message;
                  } else if (Array.isArray(error.response.data.message)) {
                      errorMessage = error.response.data.message.join('; ');
                  } else if (typeof error.response.data.message === 'object') {
                    toast.error(errorMessage)
                    console.log(errorMessage);
                  }
              }
                setTableData([]);
            }
        } finally {
            setBenLoading(false);
        }
    };
    
      
    
      useEffect(() => {
        if (bearer) {
          fetchBeneficiaries();
    
        }
      }, [bearer, currentPage]);
    
      //create beneficiary
      const createCustomer = async () => {
        setLoadings(true);
        try {
          const response = await axios.post(`${BASE_URL}/customer/add`,
            {
              name: fullName,
              email: email,
              phone: phone,
              address: address,
              office_address: office_address,
            },
            { headers }
          );
          console.log(response.data.message)
          fetchBeneficiaries();
          handleClose();
          // return
         toast.success(response.data.message);
          console.log(response.data);
    
        } catch (error) {
          let errorMessage = 'An error occurred. Please try again.';
          if (error.response && error.response.data && error.response.data.message) {
              if (typeof error.response.data.message === 'string') {
                  errorMessage = error.response.data.message;
              } else if (Array.isArray(error.response.data.message)) {
                  errorMessage = error.response.data.message.join('; ');
              } else if (typeof error.response.data.message === 'object') {
                  errorMessage = JSON.stringify(error.response.data.message);
              }
              toast.error(errorMessage);
            }
        } finally {
          setLoadings(false);
        }
      };
    
      //format date
      function formatDate(dateString) {
        const date = new Date(dateString);
        const formattedDate = `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())} ${padZero(date.getHours())}:${padZero(date.getMinutes())} ${date.getHours() >= 12 ? 'PM' : 'AM'}`;
        return formattedDate;
      }
    
      function padZero(num) {
        return num < 10 ? `0${num}` : num;
      }
    
    
      //view records
      const handleEyeClick = (id) => {
        const foundCustomer = tableData.find(item => item.id === id);
            navigate('/receivables/edit_member', { state: { selectedCustomer: foundCustomer } });
            console.log(foundCustomer, "hdfhds");
            setEyeClicked(true);
          };
    
      //delete function
      const handleTrashClick = async (id) => {
        const confirmed = await Swal.fire({
          title: 'Are you sure?',
          text: 'You are about to delete this member.',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!',
          cancelButtonText: 'No, cancel',
        });
    
        if (!confirmed.isConfirmed) {
          return; // User canceled, do nothing
        }
    
        try {
          const response = await axios.get(`${BASE_URL}/customer/delete?id=${id}`, { headers });
          fetchBeneficiaries();
         toast.success(response.data.message);
          setTrashClicked(true);
        } catch (error) {
          let errorMessage = 'An error occurred. Please try again.';
              if (error.response && error.response.data && error.response.data.message) {
                  if (typeof error.response.data.message === 'string') {
                      errorMessage = error.response.data.message;
                  } else if (Array.isArray(error.response.data.message)) {
                      errorMessage = error.response.data.message.join('; ');
                  } else if (typeof error.response.data.message === 'object') {
                      errorMessage = JSON.stringify(error.response.data.message);
                  }
                  toast.error(errorMessage)
                  console.log(errorMessage);
              }
        }
      };
    
      //update function
      const editCustomer = async () => {
        setLoadingss(true);
    
        try {
            const response = await axios.post(`${BASE_URL}/admin/users/update`,
            {
              name: fullName1,
              // id: deptId, 
              email: email1,
              phone: phone1,
              
            },
            { headers }
          );
    
          fetchBeneficiaries();
    
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: response.data.message,
          });
    
          // console.log(response.data);
        } catch (error) {
          const errorStatus = error.response?.data?.message || 'An error occurred';
    
          Swal.fire({
            icon: 'error',
            title: 'Failed',
            text: errorStatus,
          });
    
          console.error(error);
        } finally {
          setLoadingss(false);
        }
      };
    
      const handleFileChange = (event) => {
        const files = event.target.files;
        const fileList = Array.from(files);
        setSelectedFile(fileList);
        
      };
    
      const uploadExcel = async () => {
        setUploadLoading(true);
          try {
            const formData = new FormData();
          formData.append('file', selectedFile[0]);
          // formData.append('transaction_date', selectedDate);
         
          // console.log(selectedFile);
      
          const headers = {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${bearer}`,
          };
      
          const response = await axios.post(`${BASE_URL}/customer/import`,
            formData,
            { headers }
          );
      handleClose56();
      fetchBeneficiaries();
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: response.data.message,
          });
      
          console.log(response.data.message);
        } catch (error) {
          const errorStatus = error.response.data.message;
      
          Swal.fire({
            icon: 'error',
            title: 'Failed',
            text: errorStatus,
          });
      
          console.error(error);
        } finally {
          setUploadLoading(false);
          }
        };
    
    
      //filter function
      // const filteredData = tableData.filter(item => item.name.toLowerCase().includes(searchTerm.toLowerCase()));
    
      // const totalPages = Math.ceil(filteredData.length / entriesPerPage);
    
      const handlePrevPage = () => {
        setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
      };
      
      const handleNextPage = () => {
        setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages));
      };
    
      // const filteredData = tableData.filter(item => item.name.includes(searchTerm));
      const startIndexx = (currentPage - 1) * entriesPerPage + 1;
      const endIndexx = Math.min(startIndexx + entriesPerPage - 1, totalEntries);
      // const displayedData = filteredData.slice(currentPage - 1, totalEntries);

      const formattedTotalEntries = totalEntries.toLocaleString();

    
    return (
        <div className={classes.latestBody}>
            {/* <LatestMenu/> */}
            <div className={classes.latestBodyContainer}>
                <div className={classes.formSection}>
                     <div className={classes.formSectionHeader}>
                        <div className={classes.formSectionHeaderContents}>
                            {/* <div className={classes.formSectionHeader1}>
                                <div className={classes.dashboardName}>
                                    <div style={{marginLeft: 20,  width: "100%"}}>
                                        <p style={{color:'#000000', fontSize: 20, fontWeight: 700}}>{company}</p>
                                    </div>
                                
                                </div>
                                <div className={classes.userSide} >
                                
                                    <h3>
                                        {user.toLocaleUpperCase()}
                                    </h3>
                                
                                </div>
                            </div> */}
                            
                            <div className={classes.ayz}>
                                <div className={classes.horinfodashbrd}>
                                    <div className={classes.stinfo}>
                                        <p className={classes.stinfosts}>Welcome</p>
                                        <p className={classes.stinfolvl}>{user.toLocaleUpperCase()}</p>
                                    </div>

                                    <div className={classes.usrcnr}>

                                        
                                        <div className={classes.usrinfo}>
                                            <div className={classes.usrnminf}>

                                                <p className={classes.usrpstn}>Company Name</p>
                                                <p className={classes.usrnme}>{company.toLocaleUpperCase()}</p>
                                                <p className={classes.usrpstn}>Valid till {formatDate(validity)}</p>
                                            </div>
                                            <div className={classes.avtrs} onClick={() => handleLogout()} >

                                                <>

                                                    {loading ? <Spinner size="sm" animation="border" variant="light" /> :
                                                        <img src={icon7} alt="Icon" className={classes.avtrs} />
                                                    }
                                                </>
                                            </div>

                                        </div>
                                        
                                    </div>

                                </div>
                            </div>
                            
                            {/* <div> */}
                                <div className={classes.formSectionHeader2}>
                                    <LatestMenu/>
                                </div>
                                
                                
                            {/* </div> */}

                            {/* <div className={classes.formSectionHeader3}>
                                <div>
                                    <small>TOTAL INCOME</small>
                                    <h4>N524,068,010.95</h4>
                                    <small><i class='bx bx-up-arrow-alt'></i>  7%  vs average</small>
                                </div>
                                <div>
                                    <small>TOTAL LODGE</small>
                                    <h4>N232,096,635.05</h4>
                                    <small><i class='bx bx-down-arrow-alt'></i>  5% vs average</small>
                                </div>
                                <div>
                                    <small>TOTAL OUTSTANDING</small>
                                    <h4>N9,768,641.55</h4>
                                    <small><i class='bx bx-down-arrow-alt'></i>  5% vs average</small>
                                </div>
                                
                            </div> */}
                        </div>
                    </div>
                    {/* <div className={classes.chartCont}>
                        <div className={classes.chartBox}>
                            <div className={classes.dashBox}>
                                <div className={classes.cashFlow}>
                                    <p>CASH FLOW</p>
                                    <h1>N0.00</h1>
                                    <h6>Current Cash Balance</h6>
                                </div>
                                <div style={{ width: '100%', height: '180px' }}>
                                    <Chart options={{ ...chartOptions, data }} />
                                    
                                </div>
                            </div>
                            <div className={classes.dashBox}>
                                <div className={classes.cashFlow}>
                                    <p>Expenses</p>
                                    <h1>N0.00</h1>
                                    <h6>Business Spending</h6>
                                </div>
                                <div className={classes.pieContainer}>
                                    <img src={ImageIcon} style={{ objectFit: "contain", height: 140, width: '100%' }} />
                                    <div className={classes.dashPie}>
                                        <div className={classes.detCont}>
                                            <div className={classes.smallBox1} />
                                            <div className={classes.detailsssss}>
                                                <p className={classes.acctMoney}>N0.00</p>
                                                <p className={classes.acctMoney1}>Rent</p>
                                            </div>
                                        </div>
                                        <div className={classes.detCont}>
                                            <div className={classes.smallBox2} />
                                            <div className={classes.detailsssss}>
                                                <p className={classes.acctMoney}>N0.00</p>
                                                <p className={classes.acctMoney1}>Rent</p>
                                            </div>
                                        </div>
                                        <div className={classes.detCont}>
                                            <div className={classes.smallBox3} />
                                            <div className={classes.detailsssss}>
                                                <p className={classes.acctMoney}>N0.00</p>
                                                <p className={classes.acctMoney1}>Rent</p>
                                            </div>
                                        </div>
                                        
                                        
                                    </div>
                                </div>
                            </div>
                            <div className={classes.dashBox}>
                                <div className={classes.cashFlow}>
                                    <p>PROFIT AND LOSS</p>
                                    <h1>N0.00</h1>
                                    <h6>Net Income for April</h6>
                                </div>
                                <div className={classes.progress}>
                                    <p>Income</p>
                                    <h1>N0.00</h1>
                                    <ProgressBar variant="success" now={70} />
                                </div>
                                <div className={classes.progress1}>
                                    <p>Expenses</p>
                                    <h1>N0.00</h1>
                                    <ProgressBar variant="warning" now={60} />
                                </div>
                            </div>
                            
                        </div>
                
                    </div> */}

                    <div className={classes.tableBodym}>
                        <div className="content-header row align-items-center m-0">

                        <nav aria-label="breadcrumb" style={{width:'100% ', zIndex:'-1'}} >
                        <div
                            style={{
                            marginTop: 20,
                            marginBottom: 20,
                            justifyContent: "flex-end",
                            display: "flex",
                            // marginLeft: "auto",
                            gap: 10,
                            width:'100%'
                            }}
                            className={classes.actionBtns}
                        >
                            <Button variant="success" onClick={handleShow} >
                            Add New Customer
                            </Button>
                            <Button variant="secondary"  onClick={handleShow56}>
                            Upload Customer
                            </Button>
                            <a href="https://account-api.patna.ng/api/v1/download-member-template" download>
                            <Button variant="success">
                                Download Template
                            </Button>
                            </a>
                            
                        </div>

                        </nav>
                        <div className="col-sm-8 header-title p-0">
                        <div className={classes.manageCust}>
                            {/* <div className="header-icon text-success mr-3"><i className=""><img src={favicon} className={classes.favshi} alt="favicon" /></i></div> */}
                            <div className="media-body">
                            {/* <h4>Manage Members</h4> */}
                            {/* <small>Create and update your Member...</small> */}
                            </div>
                        </div>
                        </div>
                        </div>
                        <div className="body-content">
                        <div className="row">
                        <Modal show={show56} onHide={handleClose56} animation={false}>
                            <Modal.Header closeButton>
                            <Modal.Title>Upload Employee</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                        <Form style={{ marginTop: 20 }}>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Upload Excel File</Form.Label>
                        <Form.Control
                        type="file"
                        accept=".xlsx, .xls, .csv"
                        onChange={handleFileChange}
                        />
                        </Form.Group>
                        </Form>
                        </Modal.Body>







                            <Modal.Footer>
                            <Button variant="danger" onClick={handleClose56}>
                                Go back
                            </Button>
                            <Button variant="success" onClick={uploadExcel}>
                        {uploadLoading ? (
                            <>
                            <Spinner  size='sm' /> 
                            <span style={{ marginLeft: '5px' }}>Uploading, Please wait...</span>
                        </>
                        ) : (
                        "Upload"
                            )}
                        </Button>
                            </Modal.Footer>
                        </Modal>
                        
                        <Modal show={show} onHide={handleClose} animation={false}>
                            <Modal.Header closeButton>
                            <Modal.Title>Add Member</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                            <Form style={{ marginTop: 20 }}>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter Full Name"
                                    // autoFocus
                                    value={fullName}
                                    onChange={(e) => setFullName(e.target.value)}
                                />
                                <div style={{ marginTop: 10 }} />
                                <Form.Label>Address</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter Address"
                                    // autoFocus
                                    value={address}
                                    onChange={(e) => setAddress(e.target.value)}
                                />
                                <div style={{ marginTop: 10 }} />
                                <Form.Label>Office Address</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter office Address"
                                    // autoFocus
                                    value={office_address}
                                    onChange={(e) => setOfficeAddress(e.target.value)}
                                />
                                <div style={{ marginTop: 10 }} />
                                <Form.Label>Phone Number</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter Phone Number"
                                    // autoFocus
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                />
                                <div style={{ marginTop: 10 }} />
                                <Form.Label>Email Address</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter Email Address"
                                    // autoFocus
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                </Form.Group>
                            </Form>
                            </Modal.Body>






                            <Modal.Footer>
                            <Button variant="danger" onClick={handleClose}>
                                Go back
                            </Button>
                            <Button variant="success" onClick={createCustomer}>
                        {loading ? (
                            <>
                            <Spinner  size='sm' /> 
                            <span style={{ marginLeft: '5px' }}>Creating, Please wait...</span>
                        </>
                        ) : (
                        "Create Member"
                            )}
                        </Button>
                            </Modal.Footer>
                        </Modal>

                        <div className="col-lg-12">
                            <div className="card">
                            
                            <div className="card-body">
                                <div className="table-resposive">
                                <div className={`d-flex justify-content-between align-items-center ${classes.btnStyle}`}>
                                    <div className={`${classes.greenbtn} ${classes.btndivStyle}`}>
                                    <div > 
                                        <button>Copy</button>
                                        <button>Excel</button>
                                        <button>PDF</button>
                                        <button className={classes.diffbtn}>Column visibility</button>
                                    </div>
                                    <div className={classes.show}>
                                        <label className="d-flex justify-content-start align-items-center">
                                        Show
                                        <select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" className="custom-select custom-select-sm form-control form-control-sm" value={entriesPerPage}
                                            onChange={(e) => {
                                            setEntriesPerPage(parseInt(e.target.value));
                                            setCurrentPage(1);
                                            }}>
                                            <option value={10}>10</option>
                                            <option value={25}>25</option>
                                            <option value={50}>50</option>
                                            <option value={100}>100</option>
                                        </select>
                                        entries
                                        </label>
                                    </div>
                                    </div>
                                    <div className="text-right modal-effect ">
                                    <div id="DataTables_Table_0_filter" className="dataTables_filter">
                                        <div className="d-flex justify-content-start align-items-center">
                                        <div className="mr-2">Search:</div>
                                        <input
                                            type="search"
                                            value={searchTerm}
                                            className="form-control form-control-sm"
                                            placeholder=""
                                            aria-controls="DataTables_Table_0"
                                            onChange={(e) => {
                                            setSearchTerm(e.target.value);
                                            // setCurrentPage(1);
                                            }}
                                        />
                                        </div>

                                    </div>
                                    </div>
                                </div>


                                
                                    <div className={classes.mainTable}>
                                    {benLoading ? (
                                    <p>Fetching members...</p>
                                ) : (
                                        <div className="table-responsive">
                                            <table className="table display table-bordered table-striped table-hover bg-white m-0 card-table">

                                            <thead style={{ whiteSpace: 'nowrap' }}>
                                                <tr>
                                                <th>ID</th>
                                                <th>Employee Number</th>
                                                <th>Name</th>
                                                <th>Email</th>
                                                <th>Phone Number</th>
                                                <th>Created At</th>
                                                <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody style={{ whiteSpace: 'nowrap' }}>
                                                {tableData.map((item, index) => (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{item.employee_no}</td>
                                                    <td>{item.name}</td>
                                                    <td style={{textAlign: "left"}}>{item.email}</td>
                                                    <td>{item.phone}</td>
                                                    <td>{formatDate(item.created_at)}</td>
                                                    <td style={{textAlign: "left"}}>
                                                    <div onClick={() => handleEyeClick(item.id)} className="btn btn-success-soft btn-sm mr-1">
                                                        <i className="far fa-eye" style={{backgroundColor:'#e9f6ec', color:'#008a4b', border:'1px solid #afdeba', padding:'5px', borderRadius:'3px'}}></i>
                                                    </div>
                                                    <div onClick={() => handleTrashClick(item.id)} className="btn btn-danger-soft btn-sm">
                                                        <i className="far fa-trash-alt" style={{backgroundColor:'#fbeaec', color:'#e28e80', border:'1px solid #f1b3ba', padding:'5px',  borderRadius:'3px'}}></i>
                                                    </div>
                                                    </td>
                                                </tr>
                                                ))}
                                            </tbody>
                                            </table>
                                        </div>
                                    )}
                                    </div>
                                
                                <div className={classes.endded}>
                                    <p>
                                    Showing {`Page ${currentPage} to ${totalPages} pages`} of {formattedTotalEntries} entries
                                    </p>
                                    <div style={{ display: 'flex' }}>
                                    <button
                                        style={{ border: 'none', backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'nunito', color: '#000', marginRight: 10, cursor: "pointer" }}
                                        onClick={handlePrevPage}
                                        disabled={currentPage === 1}
                                    >
                                        Previous
                                    </button>
                                    {[...Array(totalPages)].map((_, page) => {
                                        // Show only 5 pages or less if available
                                        if (page < 5 || page === currentPage - 1 || page === totalPages - 1) {
                                        return (
                                            <button
                                            key={page + 1}
                                            style={{
                                                marginLeft: '0.4rem',
                                                marginRight: '0.4rem',
                                                fontSize: '14px',
                                                fontFamily: 'nunito',
                                                fontWeight: 400,
                                                color: page + 1 === currentPage ? '#ffffff' : '#000',
                                                backgroundColor: page + 1 === currentPage ? '#28a745' : 'gainsboro',
                                                height: '2.5rem',
                                                borderRadius: '89px',
                                                padding: '0.5rem',
                                                border: 'none',
                                                width: '40px',
                                                cursor: "pointer"
                                            }}
                                            onClick={() => setCurrentPage(page + 1)}
                                            >
                                            {page + 1}
                                            </button>
                                        );
                                        }
                                        return null;
                                    })}
                                    <button
                                        style={{ cursor: "pointer", border: 'none', backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'nunito', color: '#000', marginLeft: 10 }}
                                        onClick={handleNextPage}
                                        disabled={currentPage === totalPages}
                                    >
                                        Next
                                    </button>
                                    </div>
                                </div>

                                <Modal show={show1} onHide={handleClose1} animation={false}>
                                    <Modal.Header closeButton>
                                    <Modal.Title>Edit Member</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                    <Form style={{ marginTop: 20 }}>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Full Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter Full Name"
                                            // autoFocus
                                            value={fullName1}
                                            onChange={(e) => setFullName1(e.target.value)}
                                        />
                                        <div style={{ marginTop: 10 }} />
                                        <Form.Label>Email Address</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter Email Address"
                                            // autoFocus
                                            value={email1}
                                            onChange={(e) => setEmail1(e.target.value)}
                                        />
                                        <div style={{ marginTop: 10 }} />
                                        <Form.Label>Phone Number</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter Phone Number"
                                            // autoFocus
                                            value={phone1}
                                            onChange={(e) => setPhone1(e.target.value)}
                                        />                                    
                                        
                                        </Form.Group>
                                    </Form>
                                    </Modal.Body>



                                    <Modal.Footer>
                                    <Button variant="danger" onClick={handleClose1}>
                                        Go back
                                    </Button>
                                    <Button variant="success" onClick={editCustomer} >                             {loading ? <Spinner id="loader" animation="border" variant="warning" /> : 'Save Changes'}
                                    </Button>
                                </Modal.Footer>
                                </Modal>

                            </div>
                            </div>
                        </div>
                        </div>
                        </div>
                        </div>
                    </div>
                {/* <div className={classes.chartCont1}>
                    <div className={`${classes.chartBox} ${classes.chartBoxx}`}>
                        <div className={classes.receivableBoard}>
                            <div className={classes.cashFlow}>
                                <p>Total Receivables</p>
                                
                            </div>
                            <div className={classes.progress}>
                                <p>TOTAL UNPAID INVOICES</p>
                                <h1>N0.00</h1>
                                <ProgressBar variant="success" now={100} />
                            </div>
                        </div>
                        <div className={classes.receivableBoard}>
                            <div className={classes.cashFlow}>
                                <p>Total Payables</p>
                                
                            </div>
                            <div className={classes.progress}>
                                <p>TOTAL UNPAID INVOICES</p>
                                <h1>N0.00</h1>
                                <ProgressBar variant="success" now={100} />
                            </div>
                            
                        </div>
                    
                        
                        
                    </div>
                    
                    
                </div> */}
                

        
                </div>

            </div>
            

        </div>

    );
}

export default LatestDashboard;