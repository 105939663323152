import React, { useState, useEffect } from 'react';
// import "../assets/plugins/bootstrap/css/bootstrap.min.css";
// import "../assets/plugins/metisMenu/metisMenu.min.css";
// import "../assets/plugins/fontawesome/css/all.min.css";
// import "../assets/plugins/typicons/src/typicons.min.css";
// import "../assets/plugins/themify-icons/themify-icons.min.css";
// import "../assets/plugins/datatables/dataTables.bootstrap4.min.css";
// import "../style.css";
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { Navbar, Nav, NavDropdown, Button, Modal, Form, Spinner, Badge } from 'react-bootstrap';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Swal from 'sweetalert2';
import Logo from '../../assets/nw/logo.png';
// import { InfoFooter } from '../../InfoFooter';
// import { AdminHeaderNav } from '../AdminHeaderNav';
import classes from './Purchase.module.css';

import { BASE_URL } from '../api/api';
import { toast } from 'react-toastify';
import CurrencyInput from 'react-currency-input-field';
import Select from 'react-select';
import LatestInventoryDash from './LatestInventoryDash';


function Purchase() {
    const [user, setUser] = useState("");
    const [bearer, setBearer] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const [company, setCompany] = useState('');
    const [phone, setPhone] = useState('');
    const [address, setAddress] = useState('');
    const [email, setEmail] = useState('');
    const [currentDateTime, setCurrentDateTime] = useState('');
    const [tableData4, setTableData4] = useState([]);


    const readData = async () => {
        try {
            const value = await AsyncStorage.getItem('userToken');
            const value2 = await AsyncStorage.getItem('companyId');
            const value1 = await AsyncStorage.getItem('tobi');
            const value3 = await AsyncStorage.getItem('companyName');
            const value4 = await AsyncStorage.getItem('companyEmail');
            const value5 = await AsyncStorage.getItem('companyPhone');

            if (value !== null) {
                setBearer(value);
                // setAuthenticated(true);
            }
            if (value1 !== null) {
                setUser(value1);
            }
            if (value3 !== null) {
                setCompany(value3);
            }
            if (value4 !== null) {
                setEmail(value4);
            }
            if (value5 !== null) {
                setPhone(value5);
            }

        } catch (e) {
            alert('Failed to fetch the input from storage');
        }
    };

    useEffect(() => {
        readData();
    }, []);

    useEffect(() => {
        const addLeadingZero = (number) => (number < 10 ? `0${number}` : number);

        const currentDate = new Date();
        const day = currentDate.getDate();
        const month = addLeadingZero(currentDate.getMonth() + 1);
        const year = currentDate.getFullYear();
        const minutes = addLeadingZero(currentDate.getMinutes());
        const hours = addLeadingZero(currentDate.getHours() % 12 || 12);
        const seconds = addLeadingZero(currentDate.getSeconds());
        const ampm = currentDate.getHours() >= 12 ? 'PM' : 'AM';

        const formattedDateTime = `${day}/${month}/${year} ${hours}:${minutes}:${seconds} ${ampm}`;

        setCurrentDateTime(formattedDateTime);
    }, []);

    const { selectedInvoice } = location.state || {};

    // useEffect(() => {
    //   setTableData4(selectedInvoice);
    // }, [selectedInvoice]);

    // const getSelectedInvoice = () => {
    //     return location.state?.selectedInvoice || [];
    //   };
    // console.log(tableData4);
    console.log(selectedInvoice);
    const handlePrint = () => {
        window.print();
    };



    return (

        <div>

            <div className="wrapper">
                {/* <!-- Sidebar  --> */}


                {/* <!-- Page Content  --> */}
                <div className="content-wrapper">
                    <div className="main-content">

                        <div className="solversss">
                            <LatestInventoryDash />
                        </div>                        
                        <div className='newBody'>
                            <div className={classes.newWidth}>

                                <div className={classes.topPadding}>
                                    <div className={`${classes.formSecCont}`}>
                                        <div className={classes.formSectionHeader}>
                                            <h3 style={{ fontSize: '30px', fontWeight: 700 }}>Official Purchase Invoice</h3>
                                            {/* <small>Create and view your loan accounts...</small> */}
                                        </div>
                                        <div className={classes.formSectionHeader}>
                                            <div className={classes.printHide}>
                                                <Button variant="success" onClick={handlePrint}>
                                                    Print Receipt
                                                </Button>
                                            </div>
                                            {/* <h3 style={{color:'#2D995F'}}>{user.toLocaleUpperCase()}</h3> */}
                                        </div>
                                    </div>
                                </div>



                                <div className={classes.topPadding}>
                                    <div className={`${classes.formSecCosdnt}`}>
                                        <div className="card-body" style={{ border: 'none' }}>
                                            <body className={classes.officialBody}>

                                                <div className={classes.a4}>
                                                    <div className={classes.invoiceContainer}>
                                                        <header className={classes.header}>
                                                            <div className={classes.logo}>
                                                                <img src={Logo} alt="Icon" className={classes.logoStyle} />
                                                            </div>
                                                            <div className={classes.companyInfo}>
                                                                <p className={classes.promixHead}>{company}</p>
                                                                <div className={classes.companyT}>
                                                                    <p>{address}</p>
                                                                    <p>{email}</p>
                                                                    <p>{phone}</p>
                                                                </div>
                                                            </div>
                                                        </header>

                                                        <h2 className={classes.title}>PURCHASE RECEIPT</h2>

                                                        <div className={classes.billDiv}>
                                                            <section className={classes.billTo}>
                                                                <div className={classes.billText12}>
                                                                    <p>Bill to:</p>
                                                                </div>
                                                                <div className={classes.billText}>
                                                                    <p>{selectedInvoice?.supplier?.name}</p>
                                                                    <p>{selectedInvoice?.supplier?.address}</p>
                                                                    <p>{selectedInvoice?.supplier?.phone_number}</p>
                                                                </div>
                                                            </section>
                                                            <section className={classes.billTo}>
                                                                <div className={classes.billText22}>
                                                                    <p>Date Issued: <b>{selectedInvoice?.transaction_date}</b></p>
                                                                    <p>PV No.: <b>{selectedInvoice?.invoice_number}</b></p>
                                                                </div>
                                                            </section>
                                                        </div>

                                                        <table className={classes.table}>
                                                            <thead>
                                                                <tr>
                                                                    <th>S/N</th>
                                                                    <th>ITEM NAME</th>
                                                                    <th style={{ textAlign: 'center' }}>QTY</th>
                                                                    <th style={{ textAlign: 'right' }}>RATE</th>
                                                                    <th style={{ textAlign: 'right' }}>AMOUNT</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody className={classes.tableSy}>
                                                                {/* {selectedInvoice && selectedInvoice.map((item, index) => ( */}
                                                                {selectedInvoice?.items?.length > 0 ? (
                                                                    selectedInvoice?.items.map((item, index) => (
                                                                        <tr key={index}>
                                                                            <td>{index + 1}</td>
                                                                            <td>{item?.item?.name}</td>
                                                                            <td style={{ textAlign: 'center' }}>
                                                                                {item.quantity}                                                                            </td>
                                                                            <td style={{ textAlign: 'right' }}>
                                                                                {parseFloat(item?.item?.cost_price).toLocaleString('en-US', {
                                                                                    minimumIntegerDigits: 1,
                                                                                    minimumFractionDigits: 2,
                                                                                    maximumFractionDigits: 2,
                                                                                })}
                                                                            </td>
                                                                            <td style={{ textAlign: 'right' }}>
                                                                                {parseFloat(item.amount).toLocaleString('en-US', {
                                                                                    minimumIntegerDigits: 1,
                                                                                    minimumFractionDigits: 2,
                                                                                    maximumFractionDigits: 2,
                                                                                })}
                                                                            </td>
                                                                        </tr>
                                                                    ))
                                                                ) :
                                                                    <tr>
                                                                        <td colSpan="6">No item found.</td>
                                                                    </tr>
                                                                }
                                                                {/* ))} */}
                                                            </tbody>
                                                        </table>

                                                        <section className={classes.totals}>
                                                            <p>Sub total:
                                                                <b> {parseFloat(selectedInvoice?.amount).toLocaleString('en-US', {
                                                                    minimumIntegerDigits: 1,
                                                                    minimumFractionDigits: 2,
                                                                    maximumFractionDigits: 2,
                                                                })}</b>
                                                            </p>
                                                            <p>VAT: <b>{parseFloat(selectedInvoice?.total_vat).toLocaleString('en-US', {
                                                                minimumIntegerDigits: 1,
                                                                minimumFractionDigits: 2,
                                                                maximumFractionDigits: 2,
                                                            })}</b>
                                                            </p>
                                                            <p>Total: <b>{parseFloat(selectedInvoice?.amount).toLocaleString('en-US', {
                                                                minimumIntegerDigits: 1,
                                                                minimumFractionDigits: 2,
                                                                maximumFractionDigits: 2,
                                                            })}</b>
                                                            </p>
                                                        </section>

                                                        <hr />
                                                        <div className={classes.notes}>
                                                            {/* <h4>Notes</h4> */}
                                                            {/* <p> */}
                                                            <div className={classes.span1}>
                                                                For more info please contact <strong>{company}</strong> on <strong>{phone}</strong>, <br />or email us at <strong>{email}</strong>.
                                                            </div>
                                                            {/* </p> */}
                                                        </div>
                                                    </div>
                                                    {/* <h4 className={classes.fontBold} >{company}</h4>
                                                    <p className={classes.fontSmall}>{address}</p>
                                                    <p className={classes.fontSmall}>{phone}</p>
                                                    <p className={classes.fontSmall}>{email}</p>
                                                    <h4 className={classes.salesInv} >PURCHASE INVOICE</h4> */}
                                                    {/* <div className={classes.headerContainer}>
                                                        <div className={classes.flex1}>
                                                            <table className={classes.headerTable1}>
                                                                <tr>
                                                                    <td>SUPPLIER NAME:</td>
                                                                    <td className={classes.tdWidth}>{selectedInvoice?.supplier?.name}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>SUPPLIER NO.:</td>
                                                                    <td>{selectedInvoice?.supplier?.phone_number}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>SUPPLIER ADD.:</td>
                                                                    <td>{selectedInvoice?.supplier?.address}</td>
                                                                </tr>
                                                            </table>
                                                        </div>

                                                        <div className={classes.flex2}>
                                                            <table className={classes.headerTable2}>
                                                                <tr>
                                                                    <td style={{ borderBottom: '1px solid gray', }}>PAYMENT DATE:</td>
                                                                    <td>{selectedInvoice?.transaction_date}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>PV. NO.:</td>
                                                                    <td>{selectedInvoice?.invoice_number}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>SALESPERSON:</td>
                                                                    <td></td>
                                                                </tr>
                                                            </table>

                                                        </div>
                                                    </div> */}
                                                    {/* <table className={classes.bodyTable}>
                                                        <tr>
                                                            <th>ITEM NO</th>
                                                            <th style={{ width: '300px' }}>DESCRIPTION</th>
                                                            <th>QTY</th>
                                                            <th>UNIT PRICE</th>
                                                            <th>TOTAL</th>
                                                        </tr>
                                                        <tr>
                                                            <td>1</td>
                                                            <td >{selectedInvoice?.description}</td>
                                                            <td>1</td>
                                                            <td style={{ textAlign: "right" }}>{parseFloat(selectedInvoice.amount).toLocaleString('en-US', {
                                                                minimumIntegerDigits: 1,
                                                                minimumFractionDigits: 2,
                                                                maximumFractionDigits: 2
                                                            })}</td>
                                                            <td style={{ textAlign: "right" }}>{parseFloat(selectedInvoice.amount).toLocaleString('en-US', {
                                                                minimumIntegerDigits: 1,
                                                                minimumFractionDigits: 2,
                                                                maximumFractionDigits: 2
                                                            })}</td>
                                                        </tr>
                                                        <tr>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td rowSpan={5} colSpan={2} className={classes.spaned}>
                                                                <span style={{ fontWeight: 700 }}>Remarks/Instructions:</span>
                                                                <div style={{ textAlign: 'center', marginTop: '20px', }}>
                                                                    <span>Please make cheque payable to Your Company Name.</span><br />
                                                                    <div style={{ fontSize: '23px', paddingTop: '10px', fontWeight: 700 }}>THANK YOU</div>
                                                                </div>

                                                            </td>
                                                            <td style={{ fontWeight: 900 }} colSpan={2}>SUBTOTAL</td>
                                                            <td style={{ textAlign: 'right', }}>{parseFloat(selectedInvoice.amount).toLocaleString('en-US', {
                                                                minimumIntegerDigits: 1,
                                                                minimumFractionDigits: 2,
                                                                maximumFractionDigits: 2
                                                            })}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ fontWeight: 900 }} colSpan={2}>TAX</td>
                                                            <td style={{ textAlign: 'right', }}></td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ fontWeight: 900 }} colSpan={2}>SHIPPING / HANDLING</td>
                                                            <td style={{ textAlign: 'right', }}></td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ fontWeight: 900 }} colSpan={2}>OTHER</td>
                                                            <td style={{ textAlign: 'right', }}></td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ fontWeight: 900 }} colSpan={2}>TOTAL</td>
                                                            <td style={{ textAlign: 'right', fontWeight: 900 }}>{parseFloat(selectedInvoice.amount).toLocaleString('en-US', {
                                                                minimumIntegerDigits: 1,
                                                                minimumFractionDigits: 2,
                                                                maximumFractionDigits: 2
                                                            })}</td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={5} className={classes.footerCont} >
                                                                <div className={classes.div1}>
                                                                    <div className={classes.div2}>
                                                                        <div>
                                                                            <span>CASH</span>
                                                                            <span>CREDIT CARD</span>
                                                                            <span>MONEY ORDER</span>
                                                                        </div>
                                                                        <span className={classes.span1}>
                                                                            For questions concerning this payment, please contact <strong>{company}</strong> on <strong>{phone}</strong>, or email us at <strong>{email}</strong>.
                                                                        </span>


                                                                    </div>

                                                                </div>
                                                            </td>
                                                        </tr>


                                                    </table> */}
                                                </div>
                                            </body>






                                        </div>
                                    </div>
                                </div>













                            </div>
                            {/* <!--/.main content--> */}
                        </div>
                    </div>
                    {/* <InfoFooter /> */}
                    {/* <!--/.footer content--> */}
                    <div className="overlay"></div>
                </div>
                {/* <!--/.wrapper--> */}


            </div>
        </div>

    );
}

export default Purchase;