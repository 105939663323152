import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
// import OnbImg from '../../Images/image bg.png';
import classes from './Dashboard.module.css';
// import loanicon from '../../Images/moneys.png'
// import loaniconblue from '../../Images/moneysblue.png'
// import loanicongreen from '../../Images/receipt-2.png'
// import axios from 'axios';
// import AsyncStorage from '@react-native-async-storage/async-storage';
import { Button, ProgressBar } from 'react-bootstrap';
// import RegLogo from '../../Images/RegistrationLogo.svg'
import { Link } from 'react-router-dom'
import MainDashboard from '../Main Dashboard/MainDashoard';
import dChart1 from '../../assets/promix/dShart1.svg'
import dChart2 from '../../assets/promix/dShart2.svg'
import dChart3 from '../../assets/promix/dShart3.svg'
import dChart4 from '../../assets/promix/dShart4.svg'
import dChart5 from '../../assets/promix/dShart5.svg'
import dChart6 from '../../assets/promix/dShart6.svg'
import dChart7 from '../../assets/promix/dShart7.svg'
import dChart8 from '../../assets/promix/dShart8.svg'
import Arrow from '../../assets/promix/dArrow-down.svg'
import AsyncStorage from '@react-native-async-storage/async-storage';
import CoopDashboard from '../Cooperative Dashboard/CoopDashboard';
import ReportDashboard from '../ReportDashboard/ReportDashboard';
import { Chart } from 'react-charts';
import PieChart from './Pie';
import ImageIcon from '../../assets/promix/Group 1.png';


const NewDashboard = () => {
    const [bearer, setBearer] = useState('');
    const [user, setUser] = useState('');
    const [company, setCompany] = useState('');
    const [userType, setUserType] = useState('');
    const [continent, setContinent] = useState('');
    const [region, setRegion] = useState('');
    const [province, setProvince] = useState('');

    const readData = async () => {
        try {
            const detail = await AsyncStorage.getItem('tobi');
            const details = await AsyncStorage.getItem('userToken');
            const detailss = await AsyncStorage.getItem('companyName');
            const admins = await AsyncStorage.getItem('admin');
            const continentName = await AsyncStorage.getItem('continent');
            const regionName = await AsyncStorage.getItem('region');
            const provinceName = await AsyncStorage.getItem('province');

            if (detail !== null) {
                // const firstName = detail.split(' ')[0];
                setUser(detail);

            }


            if (details !== null) {
                setBearer(details);
            }
            if (admins !== null) {
                setUserType(admins);
            }
            if (continentName !== null) {
                setContinent(continentName);
            }
            if (regionName !== null) {
                setRegion(regionName);
            }
            if (provinceName !== null) {
                setProvince(provinceName);
            }

            if (detailss !== null) {
                setCompany(detailss);
            }
        } catch (e) {
            alert('Failed to fetch the input from storage');
        }
    };

    useEffect(() => {
        readData();
    }, []);

    const data = [
        {
            label: 'Money In',
            data: [
                { primary: 'March 2024', secondary: 3_200_000 },
                { primary: 'April 2024', secondary: 2_800_000 },
                { primary: 'May 2024', secondary: 3_600_000 },
                { primary: 'June 2024', secondary: 4_000_000 },
            ],
        },
        {
            label: 'Money Out',
            data: [
                { primary: 'March 2024', secondary: 1_500_000 },
                { primary: 'April 2024', secondary: 2_000_000 },
                { primary: 'May 2024', secondary: 2_500_000 },
                { primary: 'June 2024', secondary: 3_000_000 },
            ],
        },
    ];

    const chartOptions = {
        primaryAxis: {
            getValue: datum => datum.primary,
            type: 'ordinal',
        },
        secondaryAxes: [
            {
                getValue: datum => datum.secondary,
                elementType: 'bar',
                min: 1_000_000,
                max: 4_000_000,
                tickFormat: d => `${(d / 1_000_000).toFixed(1)}M`,
            },
        ],
        getSeriesStyle: series => ({
            color: series.label === 'Money In' ? '#2D995F' : '#FFBA08',
        }),
    };

    const Legend = ({ data }) => (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
            {data.map(series => (
                <div key={series.label} style={{ display: 'flex', alignItems: 'center', margin: '0 10px' }}>
                    <div
                        style={{
                            width: '10px',
                            height: '10px',
                            backgroundColor: series.label === 'Money In' ? '#2D995F' : '#FFBA08',
                            marginRight: '10px',
                        }}
                    ></div>
                    <span>{series.label}</span>
                </div>
            ))}
        </div>
    );



    return (
        <div>
            <ReportDashboard />

            <div className={classes.formSection}>
                <div className={classes.formSectionHeader}>
                    <div className={classes.dashboardName}>
                        <div style={{ marginLeft: 20, width: "100%" }}>
                            <p><span style={{ fontWeight: 700, color: "#000" }}>CONTINENT: </span>{continent === "undefined" ? "" : continent} | <span style={{ fontWeight: 700, color: "#000" }}>REGION:</span>  {region === "undefined" ? "" : region} | <span style={{ fontWeight: 700, color: "#000" }}>PROVINCE:</span> {province === "undefined" ? "" : province}</p>
                        </div>
                    </div>
                    <div className={classes.userSide} >
                        {/* <p style={{margin:'0'}}>Welcome</p> */}
                        <h3>
                            {user.toLocaleUpperCase()}
                        </h3>
                        <p>{userType}</p>

                    </div>
                </div>
                {/* <div className={classes.analysis}>
                    <div className={classes.analysisCont}>
                        <p style={{paddingBottom:'5px'}}>TOTAL INCOME</p>
                        <h5>N0.00</h5>
                        <div className={classes.perceCont}>
                            <p className={classes.percent}><img src={Arrow} alt="arrowDown"/> 5%</p>
                            <p>vs average</p>
                        </div>
                    </div>
                    <div className={classes.analysisCont}>
                        <p style={{paddingBottom:'5px'}}>TOTAL LODGE</p>
                        <h5>N0.00</h5>
                        <div className={classes.perceCont}>
                            <p className={classes.percent}><img src={Arrow} alt="arrowDown"/> 5%</p>
                            <p>vs average</p>
                        </div>
                    </div>
                    <div className={classes.analysisCont}>
                        <p style={{paddingBottom:'5px'}}>TOTAL OUTSTANDING</p>
                        <h5>N0.00</h5>
                        <div className={classes.perceCont}>
                            <p className={classes.percent}><img src={Arrow} alt="arrowDown"/> 5%</p>
                            <p>vs average</p>
                        </div>
                    </div>
                </div> */}


            </div>

            <div className={classes.chartCont}>
                <div className={classes.chartBox}>
                    <div className={classes.dashBox}>
                        <div className={classes.cashFlow}>
                            <p>CASH FLOW</p>
                            <h1>N154,780,510.95</h1>
                            <h6>Current Cash Balance</h6>
                        </div>
                        <div style={{ width: '100%', height: '180px' }}>
                            <Chart options={{ ...chartOptions, data }} />
                            {/* <div>
                    <Legend data={data} />
                    </div> */}
                        </div>
                    </div>
                    <div className={classes.dashBox}>
                        <div className={classes.cashFlow}>
                            <p>Expenses</p>
                            <h1>N35,500,510.00</h1>
                            <h6>Business Spending</h6>
                        </div>
                        <div className={classes.pieContainer}>
                            <img src={ImageIcon} style={{ objectFit: "contain", height: 140, width: '100%' }} />
                            <div className={classes.dashPie}>
                                <div className={classes.detCont}>
                                    <div className={classes.smallBox1} />
                                    <div className={classes.detailsssss}>
                                        <p className={classes.acctMoney}>N15,700,450.00</p>
                                        <p className={classes.acctMoney1}>Rent</p>
                                    </div>
                                </div>
                                <div className={classes.detCont}>
                                    <div className={classes.smallBox2} />
                                    <div className={classes.detailsssss}>
                                        <p className={classes.acctMoney}>N15,700,450.00</p>
                                        <p className={classes.acctMoney1}>Rent</p>
                                    </div>
                                </div>
                                <div className={classes.detCont}>
                                    <div className={classes.smallBox3} />
                                    <div className={classes.detailsssss}>
                                        <p className={classes.acctMoney}>N15,700,450.00</p>
                                        <p className={classes.acctMoney1}>Rent</p>
                                    </div>
                                </div>
                                <div className={classes.detCont}>
                                    <div className={classes.smallBox4} />
                                    <div className={classes.detailsssss}>
                                        <p className={classes.acctMoney}>N15,700,450.00</p>
                                        <p className={classes.acctMoney1}>Rent</p>
                                    </div>
                                </div>
                                <div className={classes.detCont}>
                                    <div className={classes.smallBox5} />
                                    <div className={classes.detailsssss}>
                                        <p className={classes.acctMoney}>N15,700,450.00</p>
                                        <p className={classes.acctMoney1}>Rent</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={classes.dashBox}>
                        <div className={classes.cashFlow}>
                            <p>PROFIT AND LOSS</p>
                            <h1>N78,000,500.00</h1>
                            <h6>Net Income for April</h6>
                        </div>
                        <div className={classes.progress}>
                            <p>Income</p>
                            <h1>N40,000,500.00</h1>
                            <ProgressBar variant="success" now={70} />
                        </div>
                        <div className={classes.progress1}>
                            <p>Expenses</p>
                            <h1>N38,000,500.00</h1>
                            <ProgressBar variant="warning" now={60} />
                        </div>
                    </div>
                    {/* <img src={dChart1} alt='chart' className={classes.imgs}/> */}
                </div>
             
            </div>
            <div className={classes.chartCont}>
                <div className={classes.chartBox}>
                    <div className={classes.receivableBoard}>
                        <div className={classes.cashFlow}>
                            <p>Total Receivables</p>
                            {/* <h1>N154,780,510.95</h1>
                            <h6>Current Cash Balance</h6> */}
                        </div>
                        <div className={classes.progress}>
                            <p>TOTAL UNPAID INVOICES</p>
                            <h1>N505,200.35</h1>
                            <ProgressBar variant="success" now={100} />
                        </div>
                    </div>
                    <div className={classes.receivableBoard}>
                        <div className={classes.cashFlow}>
                            <p>Total Payables</p>
                            {/* <h1>N154,780,510.95</h1>
                            <h6>Current Cash Balance</h6> */}
                        </div>
                        <div className={classes.progress}>
                            <p>TOTAL UNPAID INVOICES</p>
                            <h1>N505,200.35</h1>
                            <ProgressBar variant="success" now={100} />
                        </div>
                        {/* <div style={{ width: '100%', height: '180px' }}>
                            <Chart options={{ ...chartOptions, data }} />
                            
                        </div> */}
                    </div>
                   
                    
                    {/* <img src={dChart1} alt='chart' className={classes.imgs}/> */}
                </div>
                
                
            </div>
        </div>

    );
}

export default NewDashboard;