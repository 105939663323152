import React, { useState, useEffect } from 'react';
import classes from './ReceiptsSuppliers.module.css';
import logo from '../../assets/nw/logo.png';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import AsyncStorage from '@react-native-async-storage/async-storage';


function ReceiptSuppliers() {
    const [user, setUser] = useState("");
  const [bearer, setBearer] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const [company, setCompany] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');
  const [email, setEmail] = useState('');
  const [currentDateTime, setCurrentDateTime] = useState('');
  const [companyAddress,setCompanyAddress] = useState('')

  const readData = async () => {
      try {
        const value = await AsyncStorage.getItem('userToken');
        const value2 = await AsyncStorage.getItem('companyId');
        const value1 = await AsyncStorage.getItem('tobi');
        const value3 = await AsyncStorage.getItem('companyName');
        const value4 = await AsyncStorage.getItem('companyEmail');
        const value5 = await AsyncStorage.getItem('companyPhone');
        const value6 = await AsyncStorage.getItem('companyAddress');
  
        if (value !== null) {
          setBearer(value);
          // setAuthenticated(true);
        }
        if (value1 !== null) {
            setUser(value1);
          }
        if (value3 !== null) {
          setCompany(value3);
        }
        if (value4 !== null) {
          setEmail(value4);
        }
        if (value5 !== null) {
          setPhone(value5);
        }
        if(value6 !== null) {
            setCompanyAddress(value6)
        }
  
      } catch (e) {
        alert('Failed to fetch the input from storage');
      }
    };
  
    useEffect(() => {
      readData();
    }, []);

    useEffect(() => {
      const addLeadingZero = (number) => (number < 10 ? `0${number}` : number);
    
      const currentDate = new Date();
      const day = currentDate.getDate();
      const month = addLeadingZero(currentDate.getMonth() + 1);
      const year = currentDate.getFullYear();
      const minutes = addLeadingZero(currentDate.getMinutes());
      const hours = addLeadingZero(currentDate.getHours() % 12 || 12);
      const seconds = addLeadingZero(currentDate.getSeconds());
      const ampm = currentDate.getHours() >= 12 ? 'PM' : 'AM';
    
      const formattedDateTime = `${day}/${month}/${year} ${hours}:${minutes}:${seconds} ${ampm}`;
    
      setCurrentDateTime(formattedDateTime);
    }, []);
    
    const { selectedInvoice } = location.state || {};
   
    console.log(selectedInvoice);
    const handlePrint = () => {
      window.print();
    };

    const getSubtotal = (items) => {
        if (!Array.isArray(items) || items.length === 0) {
          return '0.00'; // Return as a formatted string
        }
      
        return items.reduce((total, item) => {
          const quantity = item.item?.quantity || 0;
          // Check if price exists, else use service_amount
          const price = parseFloat(item.item?.price) || parseFloat(item.service_amount) || 0;
          return total + (quantity * price);
      
        }, 0).toLocaleString('en-US', {
          minimumIntegerDigits: 1,
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      };
      
      console.log(selectedInvoice)
      
      const subtotal = getSubtotal(selectedInvoice?.general_invoice);

    //   const subTotal = products.reduce(
    //     (total, product) => total + product.selectedQuantity * parseFloat(product.price),
    //     0
    //   );
    


    return (
        <div className={classes.invoiceContainer}>
        <header className={classes.header}>
          <div className={classes.logo}>
          <p style={{fontWeight: 700, fontSize: 20, color: "#000"}}>{selectedInvoice?.invoice_number}</p>
          <img src={logo} alt="Icon" className={classes.logoStyle} />
          </div>
          <div className={classes.companyInfo}>
            <p className={classes.promixHead}>{company}</p>
            <div className={classes.companyT}>
              <p>{address}</p>
              <p>{companyAddress}</p>
              <p>{email}</p>
              <p>{phone}</p>
            </div>
          </div>
        </header>
  
        <h2 className={classes.title}>SUPPLIER'S RECEIPT</h2>
  
        <div className={classes.billDiv}>
          <section className={classes.billTo}>
            <p className={classes.billT}>Bill to:</p>
            <div className={classes.billText}>
              <p> {selectedInvoice?.purchaseinvoice?.supplier?.account_name}</p>
              <p>{selectedInvoice?.purchaseinvoice?.supplier?.address}</p>
              <p>{selectedInvoice?.purchaseinvoice?.supplier?.email}</p>
              <p>{selectedInvoice?.purchaseinvoice?.supplier?.office_address}</p>
              <p>{selectedInvoice?.purchaseinvoice?.supplier?.phone || selectedInvoice?.purchaseinvoice?.supplier?.phone_no}</p>
            </div>
          </section>
  
          <p className={classes.dateIssued}>Date issued: {selectedInvoice?.transaction_date}</p>
        </div>
  
        <table className={classes.table}>
          <thead>
            <tr>
              <th>Description</th>
              <th>Quantity</th>
              <th style={{textAlign:'center'}}>	Unit Price</th>
              <th style={{textAlign:'right'}}>Discount</th>
              <th style={{textAlign:'right'}}>VAT</th>
              <th style={{textAlign:'right'}}>Total</th>
            </tr>
          </thead>
          <tbody className={classes.tableSy}>
          {selectedInvoice?.purchaseinvoice?.items?.length > 0 ? (
 selectedInvoice?.purchaseinvoice?.items.map((item, index) => (
    <tr key={index}>
      <td>{item.item?.name}</td>
      <td>{item?.item?.quantity === '0' || item?.item?.quantity === '' || item?.item?.quantity === null || item?.item?.quantity === undefined ? '-' : item?.item?.quantity}</td>
      <td style={{ textAlign: 'center' }}>
      {item?.item?.price === "0.00" || item?.item?.price === null || item?.item?.price === undefined
    ?   parseFloat(item.service_amount).toLocaleString('en-US', {
        minimumIntegerDigits: 1,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    : parseFloat(item.item.price).toLocaleString('en-US', {
        minimumIntegerDigits: 1,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}
      </td>
      <td style={{ textAlign: 'right' }}>
      {item.discount === "0.00" || item.discount === null || item.discount === undefined
    ? '-' 
    : parseFloat(item.discount).toLocaleString('en-US', {
        minimumIntegerDigits: 1,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}
      </td>
      <td style={{ textAlign: 'right' }}>
      {item?.total_vat}
      </td>
      <td style={{fontSize: 13}} className={classes.boldtable}>{parseFloat(item.amount).toLocaleString('en-US', {
                                            minimumIntegerDigits: 1,
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                          })}</td>
    </tr>
  ))
) : 
<tr>
<td colSpan="6">No item found.</td>
</tr>
}
          </tbody>
        </table>
  
        <section className={classes.totals}>
         
          <p>VAT: {parseFloat(selectedInvoice?.purchaseinvoice?.total_vat).toLocaleString('en-US', {
                                            minimumIntegerDigits: 1,
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                          })}</p>
            <p>Total Discount: {parseFloat(selectedInvoice?.purchaseinvoice?.total_discount).toLocaleString('en-US', {
                                            minimumIntegerDigits: 1,
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                          })}</p>
          <p>Total: {parseFloat(selectedInvoice?.amount).toLocaleString('en-US', {
                                            minimumIntegerDigits: 1,
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                          })}</p>
        </section>
  
        <section className={classes.notes}>
          {/* <h4>Notes</h4> */}
          <p>
          Thank you for purchasing from {company}
          </p>
        </section>
      </div>
    )
}
export default ReceiptSuppliers ;