import React, { useState, useEffect } from 'react';
import './Invoice.module.css'; // Create this file for CSS styles
import classes from './Invoice.module.css';
import { Container, Card } from 'react-bootstrap';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import AsyncStorage from '@react-native-async-storage/async-storage';


const Invoice = () => {
  const [user, setUser] = useState("");
  const [bearer, setBearer] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const [company, setCompany] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');
  const [email, setEmail] = useState('');
  const [currentDateTime, setCurrentDateTime] = useState('');

  const readData = async () => {
      try {
        const value = await AsyncStorage.getItem('userToken');
        const value2 = await AsyncStorage.getItem('companyId');
        const value1 = await AsyncStorage.getItem('tobi');
        const value3 = await AsyncStorage.getItem('companyName');
        const value4 = await AsyncStorage.getItem('companyEmail');
        const value5 = await AsyncStorage.getItem('companyPhone');
  
        if (value !== null) {
          setBearer(value);
          // setAuthenticated(true);
        }
        if (value1 !== null) {
            setUser(value1);
          }
        if (value3 !== null) {
          setCompany(value3);
        }
        if (value4 !== null) {
          setEmail(value4);
        }
        if (value5 !== null) {
          setPhone(value5);
        }
  
      } catch (e) {
        alert('Failed to fetch the input from storage');
      }
    };
  
    useEffect(() => {
      readData();
    }, []);

    useEffect(() => {
      const addLeadingZero = (number) => (number < 10 ? `0${number}` : number);
    
      const currentDate = new Date();
      const day = currentDate.getDate();
      const month = addLeadingZero(currentDate.getMonth() + 1);
      const year = currentDate.getFullYear();
      const minutes = addLeadingZero(currentDate.getMinutes());
      const hours = addLeadingZero(currentDate.getHours() % 12 || 12);
      const seconds = addLeadingZero(currentDate.getSeconds());
      const ampm = currentDate.getHours() >= 12 ? 'PM' : 'AM';
    
      const formattedDateTime = `${day}/${month}/${year} ${hours}:${minutes}:${seconds} ${ampm}`;
    
      setCurrentDateTime(formattedDateTime);
    }, []);
    
    const { selectedInvoice } = location.state || {};
   
    console.log(selectedInvoice);
    const handlePrint = () => {
      window.print();
    };
  return (
    // className={classes.head}
    <div className={classes.container}>
      <div className={classes.card}>
        <div className={classes.cardbody}>
          <div id="invoice">
            <div className={classes.toolbarhiddenprint}>
              <div className={classes.textend}>
                <button onClick={handlePrint} type="button" className="btn btn-success"><i className="fa fa-print" ></i> Print</button>
                {/* <button type="button" className="btn btn-danger"><i className="fa fa-file-pdf-o"></i> Export as PDF</button> */}
              </div>
              <hr />
            </div>
            <div className="{classes.invoice overflow-auto}">
              <div style={{ minWidth: '600px' }}>
                <header>
                  <div className="row">
                    <div className="col">
                      <a href="javascript:;">
                        {/* <img src="assets/images/logo-icon.png" width="80" alt="Logo" /> */}
                      </a>
                    </div>
                    <div className={classes.colcompanydetails}>
                      <h2 className={classes.colorgreenh2}>
                        <a target="_blank" href="javascript:;">
                        {company}
                        </a>
                      </h2>
                      <p>{address}</p>
                      <p>{phone}</p>                      
                      <div><a href="/cdn-cgi/l/email-protection" className="__cf_email__" data-cfemail="cdaea2a0bdaca3b48da8b5aca0bda1a8e3aea2a0">{email}</a></div>
                    </div>
                  </div>
                </header>
                <hr />
                <main className={classes.fullmargin}>
                  <div className={classes.rowcontacts}>
                    <div className={classes.colinvoiceto}>
                      <div>INVOICE TO:</div>
                      <h2 >{selectedInvoice?.customer?.name}</h2>
                      <div >{selectedInvoice?.customer?.address}</div>
                      <div >
                        <a href="/cdn-cgi/l/email-protection#016b6e696f416479606c716d642f626e6c"><span className="__cf_email__" data-cfemail="dbb1b4b3b59bbea3bab6abb7bef5b8b4b6">{selectedInvoice?.customer?.phone}</span></a>
                      </div>
                    </div>
                    <div className={classes.colinvoicedetails}>
                      <div className={classes.headercolor}>INVOICE</div>
                      <div>Date of Invoice: {selectedInvoice?.transaction_date}</div>
                      {/* <div >Due Date: 30/10/2018</div> */}
                    </div>
                  </div>
                  <table className={classes.tableeepd}>
                    <thead>
                      <tr>
                        <th className={classes.textcent1213}>#</th>
                        <th className={classes.textleft}>DESCRIPTION</th>
                        <th className={classes.textright}>QTY</th>
                        <th className={classes.textright}> UNIT PRICE</th>
                        <th className={classes.textright}>TOTAL</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className={classes.textcent}>1</td>
                        <td className={classes.textbackc}>
                              <p className={classes.textupp}>{selectedInvoice?.description.toLocaleUpperCase()}</p>
                            {/* <p className={classes.textdwn}>Useful videos to improve your Javascript skills. Subscribe and stay tuned :)</p> */}
                        </td>
                        <td className={classes.textright1}>1</td>
                        <td className={classes.textright}>{parseFloat(selectedInvoice.amount).toLocaleString('en-US', {
                        minimumIntegerDigits: 1,
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    })}</td>
                        <td className={classes.textright22}>{parseFloat(selectedInvoice.amount).toLocaleString('en-US', {
                        minimumIntegerDigits: 1,
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    })}</td>
                      </tr>
                      <tr>
                        <td className={classes.textcent}></td>
                        <td className={classes.textbackc}>
                        <p className={classes.textupp}></p>
                            <p className={classes.textdwn}></p>
                        </td>
                        <td className={classes.textright1}></td>
                        <td className={classes.textright}></td>
                        <td className={classes.textright22}></td>
                      </tr>
                      <tr>
                        <td className={classes.textcent}></td>
                        <td className={classes.textbackc}>
                            <p className={classes.textupp}></p>
                            <p className={classes.textdwn}></p>
                        </td>
                        <td className={classes.textright1}></td>
                        <td className={classes.textright}></td>
                        <td className={classes.textright22}></td>
                      </tr>
                      <tr>
                        <td className={classes.textcent}></td>
                        <td className={classes.textbackc}>
                            <p className={classes.textupp}></p>
                            <p className={classes.textdwn}></p>
                        </td>
                        <td className={classes.textright1}></td>
                        <td className={classes.textright}></td>
                        <td className={classes.textright22}></td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colSpan="2"></td>
                        <td colSpan="2" className={classes.textleft222}>SUBTOTAL</td>
                        <td className={classes.textleft222}>{parseFloat(selectedInvoice.amount).toLocaleString('en-US', {
                        minimumIntegerDigits: 1,
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    })}</td>
                      </tr>
                      <tr>
                        <td colSpan="2"></td>
                        <td colSpan="2" className={classes.textleft222ggg}>TAX</td>
                        <td className={classes.textleft222ggg}></td>
                      </tr>
                      <tr>
                        <td colSpan="2" className={classes.thanktext}><b>Thank you!</b></td>
                        <td colSpan="2" className={classes.textleft22212}>GRAND TOTAL</td>
                        <td className={classes.textleft22212}>{parseFloat(selectedInvoice.amount).toLocaleString('en-US', {
                        minimumIntegerDigits: 1,
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    })}</td>
                      </tr>
                    </tfoot>
                  </table>
                  {/* <div className="thanks">Thank you!</div> */}
                  <div className={classes.noticetext}>
                    <div>NOTICE:</div>
                    <div className="notice">Please make cheque payable to Your Company Name.</div>
                  </div>
                </main>
                {/* <hr /> */}
                    <div className={classes.footertextsss}>
                        <footer>Invoice was created on a computer and is valid without the signature and seal.</footer>
                    </div>
              </div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Invoice;