import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Navbar, Nav, NavDropdown, Row, Button, Modal, Form, Spinner, Badge } from 'react-bootstrap';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Swal from 'sweetalert2';
import classes from './ContinentLedger.module.css';
import { BASE_URL } from '../api/api';
import { toast } from 'react-toastify';
import CurrencyInput from 'react-currency-input-field';
import Select from 'react-select';
import ReportDashboard from '../ReportDashboard/ReportDashboard';




function ContinentLedger() {
  const [entriesPerPage, setEntriesPerPage] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [load, setLoad] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [inputss, setInputss] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [ledgerLoading, setLedgerLoading] = useState(false);
  const [savingsLoading, setSavingsLoading] = useState(false);
    const [bearer, setBearer] = useState('');
    const navigate = useNavigate();
    const [paidBooking, setPaidBooking] = useState([]);
    const [savingss, setSavingss] = useState([]);
    const [user, setUser] = useState("");
    const [selectOptions, setSelectOptions] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState('');
    const [selectedCustomerName, setSelectedCustomerName] = useState([]);
    const [selectedSavingsType, setSelectedSavingsType] = useState([]);
    const [selectedSavingsName, setSelectedSavingsName] = useState('');
    const [selectedContinent, setSelectedContinent] = useState('');
    const [tableData3, setTableData3] = useState([]);
    const [tableData4, setTableData4] = useState([]);
    const [selectedRegion, setSelectedRegion] = useState('');
    const [selectedProvince, setSelectedProvince] = useState('');
    const [selectedAccount, setSelectedAccount] = useState('');
    const [tableData, setTableData] = useState([]);
    const [selectedEndDate, setSelectedEndDate] = useState('');
    const [selectedDate, setSelectedDate] = useState('');

    const handleDateChange = (event) => {
      setSelectedDate(event.target.value);
  };

  const handleDateChange1 = (event) => {
    setSelectedEndDate(event.target.value);

   
};



    const readData = async () => {
        try {
          const value = await AsyncStorage.getItem('userToken');
          const value2 = await AsyncStorage.getItem('companyId');
          const value1 = await AsyncStorage.getItem('tobi');
    
          if (value !== null) {
            setBearer(value);
          }
          if (value1 !== null) {
              setUser(value1);
            }
      
        } catch (e) {
          alert('Failed to fetch the input from storage');
        }
      };
    
      useEffect(() => {
        readData();
      }, []);

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${bearer}`
    };

    const goBack = () => {
        navigate(-1);
    }

    const handleAccountChange = (selectedOption) => {
      setSelectedAccount(selectedOption.value);
      // Set the start date to the first date of the current month
      const currentDate = new Date();
      const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
      setSelectedDate(firstDayOfMonth.toISOString().split('T')[0]);
      // Set the end date to the current date
      setSelectedEndDate(currentDate.toISOString().split('T')[0]);
      // Fetch accounts
      fetchAccounts();
  };

    const fetchAccounts = async () => {
      setLoad(true);
      try {
          const response = await axios.get(`${BASE_URL}/reports/general-ledger-filter`, {
              params: {
                  gl_code: selectedAccount,
                  start_date: selectedDate,
                  end_date: selectedEndDate
              },
              headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${bearer}`
              }
          });
          const resultsss = response.data?.data?.journal;
          const opt = resultsss.map((item) => ({
            label: item.gl_name,
            value: item.id,
          }));
          

          setAccounts(opt);

          const resultssx = response.data?.data?.input;
          setInputss(resultssx);
      } catch (error) {
          if (error.response && error.response.status === 401) {
              // Redirect to login page if unauthorized
              navigate('/login');
          } else {
              const errorStatus = error.response?.data?.message;
              console.log(errorStatus);

          }
      } finally {
          setLoad(false);
      }
  };

  const fetchCharts = async () => {
    setIsLoading(true);
    try {
        const response = await axios.get(`${BASE_URL}/account`, { headers });
        const results = response.data?.data;

        const opt1 = results.map((item) => ({
          label: item.gl_name,
          value: item.id,
        }));

        setTableData(opt1);
        console.log(results);
    } catch (error) {
        const errorStatus = error.response?.data?.message;
        console.log(errorStatus);
        setTableData([]);
    } finally {
        setIsLoading(false);
    }
};

useEffect(() => {
    if (bearer) {
        fetchCharts();

    }
}, [bearer]);

    const filteredData = paidBooking.filter(item => item.description.toLowerCase().includes(searchTerm.toLowerCase()));

    const totalPages = Math.ceil(filteredData.length / entriesPerPage);

    const handlePrevPage = () => {
        setCurrentPage(Math.max(currentPage - 1, 1));
    };

    const handleNextPage = () => {
        setCurrentPage(Math.min(currentPage + 1, totalPages));
    };

  

    const totalEntries = filteredData.length;
    const startIndexx = (currentPage - 1) * entriesPerPage + 1;
    const endIndexx = Math.min(startIndexx + entriesPerPage - 1, totalEntries);
    const displayedData = filteredData.slice(startIndexx - 1, endIndexx);




  const fetchPaidBookings = async () => {
      setLedgerLoading(true);

      try {
          const response = await axios.get(
              `${BASE_URL}/account/fetch-member-ledger-by-account?member_id=${selectedCustomer}&account_id=${selectedSavingsType}`,
              {

                  headers: {
                      'Content-Type': 'application/json',
                      'Authorization': `Bearer ${bearer}`
                  }
              }
          );
          const resultssx = response.data?.data;
          setPaidBooking(resultssx);

            console.log(resultssx, "NI");
      } catch (error) {
          const errorStatus = error.response.data.message;
          console.error(errorStatus);
      } finally {
        setLedgerLoading(false);
      }
  };


  


 
 


  

 

  const fetchContinents = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/continents`, { headers });
      const results = response.data?.data;

      const options = results.map((item) => ({
        label: item.description,
        value: item.id,
      }));
      // setCustomers(results);
      setSelectOptions(options);
    } catch (error) {
      const errorStatus = error.response?.data?.message;
      console.log(errorStatus);
      setCustomers([]);
    } finally {
      setIsLoading(false);
    }
  };
    
 
useEffect(() => {
  if (bearer) {
    fetchContinents();
  }
}, [bearer]);



const fetchSubContinents = async (selectedContinent) => {
  setLoad(true);
  try {
    const response = await axios.get(`${BASE_URL}/continents/getcontinentregions?continent_id=${selectedContinent}`, { headers });

    // console.log(response);
    const subCont = response.data?.data;
    const options1 = subCont.map((item) => ({
      label: item.description,
      value: item.id,
    }));
    setTableData3(options1);
    // console.log(results);
  } catch (error) {
    if (error.response && error.response.status === 401) {
      // Redirect to login page if unauthorized
      navigate('/login');
    }else{
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
          if (typeof error.response.data.message === 'string') {
              errorMessage = error.response.data.message;
          } else if (Array.isArray(error.response.data.message)) {
              errorMessage = error.response.data.message.join('; ');
          } else if (typeof error.response.data.message === 'object') {
              errorMessage = JSON.stringify(error.response.data.message);
          }
      }
    setTableData3([]);
    }
  } finally {
    setLoad(false);
  }
};

const fetchSubRegion = async (selectedRegion) => {
  setLoad(true);
  try {
    const response = await axios.get(`${BASE_URL}/regions/getprovincesregion?region_id=${selectedRegion}`, { headers });

    // console.log(response);
    const subCont1 = response.data?.data;
    const options2 = subCont1.map((item) => ({
      label: item.description,
      value: item.id,
    }));
    setTableData4(options2);
    // console.log(results);
  } catch (error) {
    if (error.response && error.response.status === 401) {
      // Redirect to login page if unauthorized
      navigate('/login');
    }else{
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
          if (typeof error.response.data.message === 'string') {
              errorMessage = error.response.data.message;
          } else if (Array.isArray(error.response.data.message)) {
              errorMessage = error.response.data.message.join('; ');
          } else if (typeof error.response.data.message === 'object') {
              errorMessage = JSON.stringify(error.response.data.message);
          }
      }
    setTableData3([]);
    }
  } finally {
    setLoad(false);
  }
};

useEffect(() => {
  if (bearer && selectedContinent) {
    fetchSubContinents(selectedContinent);
  }
}, [bearer, selectedContinent]);

useEffect(() => {
  if (bearer && selectedRegion) {
    fetchSubRegion(selectedRegion);
  }
}, [bearer, selectedRegion]);

const handleContinentChange = (selectedOption) => {
  setSelectedContinent(selectedOption.value); 
  setSelectedRegion(''); 
  setSelectedProvince(''); 
};


const handleRegionChange = (selectedOption) =>{
  setSelectedRegion(selectedOption.value)
}

const handleProvinceChange = (selectedOption) =>{
  setSelectedProvince(selectedOption.value)
}



  return (

    <div>

      <div className="wrapper">
        {/* <!-- Sidebar  --> */}


        {/* <!-- Page Content  --> */}
        <div className="content-wrapper">
          <div className="main-content">

          <ReportDashboard />
            <div className='newBody'>
            <div className={classes.newWidth}>

            <div className={classes.topPadding}>
                    <div className={`${classes.formSecCont}`}>
                        <div className={classes.formSectionHeader}>
                            <h3>General Ledger</h3>
                            {/* <small>Create and view your loan accounts...</small> */}
                        </div>
                        <div className={classes.formSectionHeader}>
                            <h3 style={{color:'#2D995F'}}>{user.toLocaleUpperCase()}</h3>
                        </div>
                    </div>
                    </div>

                    

            <div className={classes.topPadding}>
                    <div className={`${classes.formSecCont}`}>
                    <div className="card-body" style={{border:'none'}}>


                    <div className="row">
                        <div className="col-md-4">
                            <div className="form-group row">
                                <label for="example-text-input" className="col-sm-3 col-form-label font-weight-400">Continent:</label>
                                <div className="col-sm-9">
                                <Select
                                onChange={(selectedOption) => handleContinentChange(selectedOption)}
                                options={selectOptions}
                                menuPortalTarget={document.body}
                                styles={{
                                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                    menu: (provided) => ({
                                    ...provided,
                                    maxHeight: '300px',
                                    maxWidth: '300px',
                                    overflowY: 'auto',
                                    }),
                                }}
                                />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group row">
                                <label for="example-text-input" className="col-sm-3 col-form-label font-weight-400">Region:</label>
                                <div className="col-sm-9">
                                <Select
                                onChange={(selectedOption) => handleRegionChange(selectedOption)}
                                options={tableData3}
                                menuPortalTarget={document.body}
                                styles={{
                                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                    menu: (provided) => ({
                                    ...provided,
                                    maxHeight: '300px',
                                    maxWidth: '300px',
                                    overflowY: 'auto',
                                    }),
                                }}
                                />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group row">
                                <label for="example-text-input" className="col-sm-3 col-form-label font-weight-400">Province:</label>
                                <div className="col-sm-9">
                                <Select
                                onChange={(selectedOption) => handleProvinceChange(selectedOption)}
                                options={tableData4}
                                menuPortalTarget={document.body}
                                styles={{
                                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                    menu: (provided) => ({
                                    ...provided,
                                    maxHeight: '300px',
                                    overflowY: 'auto',
                                    }),
                                }}
                                />
                                </div>
                            </div>
                        </div>
                    <div className="row" style={{marginTop: 30}}>
                        <div className="col-md-4">
                            <div className="form-group row">
                                <label for="example-text-input" className="col-sm-3 col-form-label font-weight-400">Accounts:</label>
                                <div className="col-sm-9">
                                <Select
                                onChange={(selectedOption) => handleAccountChange(selectedOption)}
                                options={tableData}
                                menuPortalTarget={document.body}
                                styles={{
                                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                    menu: (provided) => ({
                                    ...provided,
                                    maxHeight: '300px',
                                    maxWidth: '300px',
                                    overflowY: 'auto',
                                    }),
                                }}
                                />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group row">
                                <label for="example-text-input" className="col-sm-3 col-form-label font-weight-400">Start:</label>
                                <div className="col-sm-9">
                                <input className="form-control" required="" type="date" onChange={handleDateChange} name="start" value={selectedDate} />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group row">
                                <label for="example-text-input" className="col-sm-3 col-form-label font-weight-400">End:</label>
                                <div className="col-sm-9">
                                <input className="form-control" required="" type="date" onChange={handleDateChange1} name="end" value={selectedEndDate} />
                                </div>
                            </div>
                        </div>
                        </div>
                        
                        
                       


                      











  

</div>

<div style={{ marginTop: 20 }} />


<div class="modal-footer" style={{ display: 'flex', justifyContent: 'flex-start', gap: 10 }}>
    <Button
     disabled={!selectedContinent}
    style={{ borderRadius: 0 }} variant='success' onClick={fetchAccounts}>
    {load ? (
                                                                        <>
                                                                            <Spinner size='sm' />
                                                                            <span style={{ marginLeft: '5px' }}>Processing, Please wait...</span>
                                                                        </>
                                                                    ) : (
                                                                        "Process"
                                                                    )}
    </Button>
    <Button
     
    style={{ borderRadius: 0 }} variant='primary' >
    {ledgerLoading ? (
                                                                        <>
                                                                            <Spinner size='sm' />
                                                                            <span style={{ marginLeft: '5px' }}>Processing, Please wait...</span>
                                                                        </>
                                                                    ) : (
                                                                        "View all"
                                                                    )}
    </Button>

</div>

</div>
                                            </div>
            </div>




           







            <div style={{backgroundColor:'white', padding:'10px 20px'}}>
              {/* <!--Content Header (Page header)--> */}
              <div className="content-header row align-items-center m-0">
              {/* {(isAdmin || permittedHeaders.includes('create-savings-account')) && ( */}
                {/* <nav aria-label="breadcrumb" className="col-sm-4 order-sm-last mb-3 mb-sm-0 p-0 ">
                  <div
                    style={{
                      marginTop: 20,
                      marginBottom: 20,
                      justifyContent: "flex-end",
                      display: "flex",
                      marginLeft: "auto",
                    }}
                    className={classes.actionBtns}
                  >
                    <Button variant="success" onClick={handleCreate}>
                      Create New Accounts
                    </Button>
                  </div>

                </nav> */}
              {/* )} */}
              
                <div className="col-sm-8 header-title p-0">
                  <div className="media">
                    {/* <div className="header-icon text-success mr-3"><i className=""><img src={favicon} className={classes.favshi} alt="favicon" /></i></div> */}
                    <div className="media-body">
                      {/* <h4 className="font-weight-bold">Savings Account</h4> */}
                      {/* <small>Create and view your Savings Accounts...</small> */}
                    </div>
                  </div>
                </div>
              </div>

              {/* <!--/.Content Header (Page header)--> */}
              <div className="body-content">
                <div className="row">

                  <div className="col-lg-12 col-xl-6">
                    <div className="row">

                      <div className="col-md-6 col-lg-6">

                        {/* <!--Feedback--> */}

                      </div>
                      <div className="col-md-6 col-lg-6">

                        {/* <!--Balance indicator--> */}

                      </div>
                      <div className="col-md-6 col-lg-6">

                        {/* <!--Time on site indicator--> */}

                      </div>
                      <div className="col-md-6 col-lg-6">

                        {/* <!--Top Referrals--> */}

                      </div>
                      <div className="col-md-6 col-lg-6">

                        {/* <!--Sessions by device--> */}

                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  {/* <div className="col-md-12 col-lg-12 col-xl-3 mb-4">
                    <div className="card">


                    </div>
                  </div> */}




<div>
                              {paidBooking.length > 0 && (
                <button onClick={() => navigate('/cooperative/report_ledger', { state: { document: paidBooking, customer: selectedCustomerName, saving: selectedSavingsName, type: "LOANS" } })} style={{ height: 30, width: 130, borderRadius: 0,  backgroundColor: "#198754", color: "#fff", marginBottom: 10, marginTop: 10 }}>Print Report</button>
            )}
                              </div>

                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="table-resposive">
                          <div className="d-flex justify-content-between align-items-center" style={{ padding: '20px 0 0 0', marginBottom: 20 }}>
                            <div className={classes.greenbtn} style={{ display: 'flex', }}>
                              <div>
                                <button>Copy</button>
                                <button>Excel</button>
                                <button>PDF</button>
                                <button className={classes.diffbtn}>Column visibility</button>
                              </div>
                              <div>
                                <label className="d-flex justify-content-start align-items-center">
                                  Show
                                  <select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" className="custom-select custom-select-sm form-control form-control-sm" value={entriesPerPage}
                                    onChange={(e) => {
                                      setEntriesPerPage(parseInt(e.target.value));
                                      setCurrentPage(1);
                                    }}>
                                    <option value={10}>10</option>
                                    <option value={25}>25</option>
                                    <option value={50}>50</option>
                                    <option value={100}>100</option>
                                  </select>
                                  entries
                                </label>
                              </div>
                            </div>
                            <div className="text-right modal-effect ">
                              <div id="DataTables_Table_0_filter" className="dataTables_filter">
                                <div className="d-flex justify-content-start align-items-center">
                                  <div className="mr-2">Search:</div>
                                  <input
                                    type="search"
                                    value={searchTerm}
                                    className="form-control form-control-sm"
                                    placeholder=""
                                    aria-controls="DataTables_Table_0"
                                    onChange={(e) => {
                                      setSearchTerm(e.target.value);
                                      // setCurrentPage(1);
                                    }}
                                  />
                                </div>

                              </div>
                            </div>
                          </div>


                          {loading ? (
                                <p>Fetching ledger...</p>
                            ) : (
                            <div className="table-responsive">
                              <table className="table display table-bordered table-striped table-hover bg-white m-0 card-table">

                                <thead style={{ whiteSpace: 'nowrap' }}>
                                  <tr>
                                  <th>S/N</th>
                                                            <th>Post Date</th>
                                                            <th>Value Date</th>
                                                            <th>Description</th>
                                                            <th>Debit</th>
                                                            <th>Credit</th>
                                  </tr>
                                </thead>
                                <tbody style={{ whiteSpace: 'nowrap' }}>
                                {displayedData.length > 0 ? (
      displayedData.map((item, index) => (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{item.transaction_date}</td>
                                            <td>{item?.description}</td>
                                            <td style={{ textAlign: "right" }}>{parseFloat(item.debit).toLocaleString('en-US', {
                                                minimumIntegerDigits: 1,
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2
                                            })}
                                            </td>
                                            <td style={{ textAlign: "right" }}>{parseFloat(item.credit).toLocaleString('en-US', {
                                                minimumIntegerDigits: 1,
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2
                                            })}
                                            </td>
                                            <td style={{ textAlign: "right" }}>{parseFloat(item.balance).toLocaleString('en-US', {
                                                minimumIntegerDigits: 1,
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2
                                            })}
                                            </td>
                                           

                                        </tr>
                                   ))
                                  ) : (
                                    <tr>
                                      <td colSpan="6" style={{ textAlign: 'center' }}>No data available</td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          )}
                          <div className={classes.endded}>
                            <p>
                              Showing {startIndexx} to {endIndexx} of {totalEntries} entries
                            </p>
                            <div style={{ display: 'flex' }}>
                              <button
                                style={{ border: 'none', backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'nunito', color: '#000', marginRight: 10, cursor: "pointer" }}
                                onClick={handlePrevPage}
                                disabled={currentPage === 1}
                              >
                                Previous
                              </button>
                              {[...Array(totalPages)].map((_, page) => {
                                // Show only 5 pages or less if available
                                if (page < 5 || page === currentPage - 1 || page === totalPages - 1) {
                                  return (
                                    <button
                                      key={page + 1}
                                      style={{
                                        marginLeft: '0.4rem',
                                        marginRight: '0.4rem',
                                        fontSize: '14px',
                                        fontFamily: 'nunito',
                                        fontWeight: 400,
                                        color: page + 1 === currentPage ? '#ffffff' : '#000',
                                        backgroundColor: page + 1 === currentPage ? '#28a745' : 'gainsboro',
                                        height: '2.5rem',
                                        borderRadius: '89px',
                                        padding: '0.5rem',
                                        border: 'none',
                                        width: '40px',
                                        cursor: "pointer"
                                      }}
                                      onClick={() => setCurrentPage(page + 1)}
                                    >
                                      {page + 1}
                                    </button>
                                  );
                                }
                                return null;
                              })}
                              <button
                                style={{ cursor: "pointer", border: 'none', backgroundColor: 'gainsboro', borderRadius: 3, height: '2.5rem', width: '100px', fontWeight: 500, fontSize: 14, padding: '0.5rem', fontFamily: 'nunito', color: '#000', marginLeft: 10 }}
                                onClick={handleNextPage}
                                disabled={currentPage === totalPages}
                              >
                                Next
                              </button>
                            </div>
                          </div>

                          

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!--/.body content--> */}
            </div>
          </div>
          {/* <!--/.main content--> */}
          </div>
          </div>
          {/* <InfoFooter /> */}
          {/* <!--/.footer content--> */}
          <div className="overlay"></div>
        </div>
        {/* <!--/.wrapper--> */}


      </div>
    </div>

  );
}

export default ContinentLedger;