import React, { useState } from "react";
import newClass from './NewSignup.module.css'
import NewLogo from "../../assets/NewSignupImg/NewSignupLogo.svg"
import Form from 'react-bootstrap/Form';
import promix from "../../assets/NewSignupImg/promiximg.png"
// import { Button } from 'react-bootstrap';
import { NavLink, Link, useNavigate, useLocation } from "react-router-dom";
import { Button, Spinner } from "react-bootstrap";
import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import crossedEyeIcon from '../../assets/promix/eye-slash.png';
import { BASE_URL } from "../../Pages/api/api";


export default function NewLogin() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
      setShowPassword(!showPassword);
  };

  const handleNavSignup = () => {
    navigate('/signup'); // Updated navigation
  };


  const handleLogin = async () => {
    setLoading(true);
    try {
        const response = await axios.post(`${BASE_URL}/login`,
            {
                email: email,
                password: password
            }
        );
        
        const result = response.data?.data?.user?.name;
        const addresses = response.data?.data?.user?.company?.address;
        const phones = response.data?.data?.user?.company?.phone_number;
        const emails = response.data?.data?.user?.company?.email;
        const resultx = response.data?.data?.user?.email;
        const results = response.data?.data?.token;
        const permit = response.data?.data?.permissions;
        const continent = response.data?.data?.user?.continent?.description;
        const region = response.data?.data?.user?.region?.description;
        const province = response.data?.data?.user?.province?.description;
        const isAdmin = response.data?.data?.user?.user_type;
        const companyName = response.data?.data?.company_name;
        const validity = response.data?.data?.user?.company?.expiry_date;
        const companyId = response.data?.data?.user?.company?.id;
        const is_first = response.data?.data?.user?.is_first;

        // console.log('is_first:', is_first);
        
        console.log(response);


    
        await AsyncStorage.setItem('modules', JSON.stringify(permit));
        AsyncStorage.setItem('admin', isAdmin);
        AsyncStorage.setItem('companyName', companyName);
        AsyncStorage.setItem('tobi', result);
        AsyncStorage.setItem('userToken', results);
        AsyncStorage.setItem('userEmail', resultx);
        AsyncStorage.setItem('companyEmail', emails);
        AsyncStorage.setItem('companyPhone', phones);
        AsyncStorage.setItem('companyAddress', addresses);
        AsyncStorage.setItem('companyId', companyId);
        AsyncStorage.setItem('permits', permit);
        AsyncStorage.setItem('continent', continent);
        AsyncStorage.setItem('region', region);
        AsyncStorage.setItem('province', province);
        AsyncStorage.setItem('validity', validity);
       
        

        if (is_first === "0") {
          // console.log("user first")
          navigate('/TeamMember');
        }
        else {
          navigate ('/welcome')
        }


    } catch (error) {
        let errorMessage = error.response?.data?.message || 'An error occurred';
        if (error.message === 'Network Error') {
            errorMessage = 'Connection error. Please check your internet connection.';
        }
        toast.error(errorMessage);
    } finally {
        setLoading(false);
    }
}

const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !isButtonDisabled) {
        handleLogin();
    }
};

const isButtonDisabled = !email || !password;


  return (
    
    <div className={`${newClass.newSignupBody} ${newClass.newLoginBody}`}>
      <ToastContainer />
        <div className={`${newClass.newSignContainer} ${newClass.loginNewSignContainer}`}>
            <div className={newClass.newSignHeader}>
              <div className={newClass.logo}>
                <NavLink to={'/'} > <img src={NewLogo} className={newClass.img}/> </NavLink>
              </div>
            </div>

            <div className={newClass.newSignSection}>

              <div className={newClass.newSignEndToEnd}>
                <h1>
                  End-to-End Software to Streamline your Accounting processes
                </h1>
                <div className={newClass.newSignObject}>
                    <img src={promix} alt="promix"/>
                </div>
              </div>

              <div className={newClass.newSignFormSection}>
                <div className={`${newClass.formHead} ${newClass.loginFormHead}`}>
                  <h1>Login</h1>
                  <p>Log into your portal</p>
                </div>
                <div className={newClass.mainForm}>
                  <Form className={newClass.loginForm}>
                    {/* <div className={newClass.flexSect}> */}
                      <Form.Group style={{width:'100%'}}>
                        <Form.Label>Email address</Form.Label>
                        <Form.Control type="name"  onChange={(e) => setEmail(e.target.value)} placeholder="Enter your email address" className={newClass.inputs} />
                      </Form.Group>

                      <Form.Group style={{width:'100%'}}>
                        <Form.Label>Password</Form.Label>
                        <Form.Control onChange={(e) => setPassword(e.target.value)} type={showPassword ? 'text' : 'password'} className={newClass.inputs}  placeholder="Enter your password" onKeyPress={handleKeyPress} />
                          <button
                                type="button"
                                style={{
                                    background: 'none',
                                    border: 'none',
                                    cursor: 'pointer',
                                    float: 'right',
                                    left: "-10px",
                                    marginTop: '-30px',
                                    position: 'relative',
                                    zIndex: 2
                                }}
                                onClick={togglePasswordVisibility}
                            >
                                {showPassword ? (
                                    <img src={crossedEyeIcon} alt="Hide Password" style={{ height: "20px", width: "20px" }} />
                                ) : (
                                    '👁️'
                                )}
                          </button>
                      </Form.Group>
                    {/* </div> */}
                    {/* <div className={newClass.flexSect}>
                      <Form.Group>
                        <Form.Label>Work email</Form.Label>
                        <Form.Control className={newClass.inputs} type="email" placeholder="Enter your email address" />
                      </Form.Group>

                      <Form.Group>
                        <Form.Label>Phone number</Form.Label>
                        <Form.Control className={newClass.inputs} type="number" placeholder="Enter your phone number" />
                      </Form.Group>
                    </div>

                    <Form.Group>
                      <Form.Label>Company name</Form.Label>
                      <Form.Control className={newClass.inputs} type="text" placeholder="Agritech" />
                    </Form.Group>

                    <Form.Group>
                      <Form.Label>Country</Form.Label>
                      <Form.Select aria-label="Default select example" className={newClass.inputs}>
                      <option>Nigeria</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </Form.Select>
                    </Form.Group>

                    <Form.Group>
                      <Form.Label>Which of our products are you interested in?</Form.Label>
                      <Form.Control className={newClass.inputs} type="number" placeholder="Accounting" />
                    </Form.Group> */}
                    <div className={newClass.newbtns}>
                      <Button  onClick={handleLogin} variant='light' disabled={isButtonDisabled}>
                      {loading ? (
                            <>
                                <Spinner size='sm' />
                                <span style={{ marginLeft: '5px' }}>Signing in...</span>
                            </>
                        ) : (
                            "Login"
                        )}                        
                      </Button>
                    </div>
                    <div className={newClass.newbtns}>
                      <div>
                            Don't have an Account yet? <a className={newClass.loginlinkclick} onClick={handleNavSignup}>Sign up here</a>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
        </div>
    </div>
  )
}
