import React, { useState, useEffect } from 'react';
import classes from './Failed.module.css';
import { Spinner, Badge, Button, Modal, Form, Alert, Tab, Tabs } from 'react-bootstrap';
import axios from 'axios';
import Arrow from '../../../assets/promix/dArrow-down.svg'
import AsyncStorage from '@react-native-async-storage/async-storage';
import CurrencyInput from 'react-currency-input-field';
import { Link, useNavigate, useParams } from 'react-router-dom'
import Table from 'react-bootstrap/Table';
import { BASE_URL } from '../../api/api';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import Select from 'react-select';
import { MdDirectionsBike } from "react-icons/md";
// import MainDashoard from '../../Main Dashboard/MainDashoard';
import LatestDash from '../../LatestDashboard/LatestDash';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import OrderNav from '../../../Component/OrderNav/OrderNav';
import trash from "../../../assets/promix/trash.png";


export default function FailedOrder() {
  const navigate = useNavigate();
  const [bearer, setBearer] = useState('');
  const [user, setUser] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [entriesPerPage, setEntriesPerPage] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [admin, setAdmin] = useState("");
  const [archiveData, setArchivedData] = useState([]);
  const [totalAmountPaid, setTotalAmountPaid] = useState(0);  
  const [totalAmountOuts, setTotalAmountOuts] = useState(0);  
  const [createLoading,setCreateLoading] = useState(false)
    const [products, setProducts] = useState([]);
    const [opt, setOpt] = useState([]);
  const [sICode, setSICode] = useState('')
  const [loading, setLoading] = useState(false); 
  const [showPrint,setShowPrint] = useState(true)

  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem('userToken');
      const value1 = await AsyncStorage.getItem('tobi');
      const value2 = await AsyncStorage.getItem('permissions');
      const value3 = await AsyncStorage.getItem('admin');

      if (value !== null) {
        setBearer(value);
      }
      if (value1 !== null) {
        setUser(value1);
      }
      if (value2 !== null) {
        setPermissions(value2);
      }
      if (value3 !== null) {
        setAdmin(value3);
      }
    } catch (e) {
      alert('Failed to fetch the input from storage');
    }
  };

  useEffect(() => {
    readData();
  }, []);

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${bearer}`
  };

console.log(bearer);


  const filteredData = tableData?.filter(item => item.customer?.name.toLowerCase().includes(searchTerm.toLowerCase()));

  const totalEntries = filteredData.length;
  const startIndexx = (currentPage - 1) * entriesPerPage + 1;
  const endIndexx = Math.min(startIndexx + entriesPerPage - 1, totalEntries);
  const displayedData = filteredData.slice(startIndexx - 1, endIndexx);


useEffect(() => {
  // Calculate the total amount paid
  const total = displayedData.reduce((sum, item) => sum + parseFloat(item.amount_paid || 0), 0);
  setTotalAmountPaid(total);
  
}, [displayedData]);

useEffect(() => {
  // Calculate the total amount paid
  const total12 = displayedData.reduce((sum, item) => sum + parseFloat(item.balance || 0), 0);
  setTotalAmountOuts(total12);
  
}, [displayedData]);

const createProduct = async () => {
  setCreateLoading(true);
  // console.log(products)

  try {
    const productIds = products.map(item => item.id);
  const quantities = products.map(item => item.selectedQuantity);
  const amounts = products.map(item => parseFloat(item.price * item.selectedQuantity)); // Ensure these are numbers
  const totalAmount = products.reduce(
    (acc, item) => acc + item.selectedQuantity * parseFloat(item.price),
    0
  );// Ensure these are numbers


    const payload = {
      transaction_number: sICode, // Ensure sICode is not empty
      product_id: productIds,
      quantity: quantities,
      amount: amounts,
      total_amount: totalAmount,
    };

  
    const response = await axios.post(
      `${BASE_URL}/sale_inventory/create?status=1`,
      payload,
      { headers }
    );
    console.log(response)

    toast.success(response.data.message);
  
  } catch (error) {
    console.log(error)
    let errorMessage = "An error occurred. Please try again.";
    if (
      error.response &&
      error.response.data &&
      error.response.data.message
    ) {
      if (typeof error.response.data.message === "string") {
        errorMessage = error.response.data.message;
      } else if (Array.isArray(error.response.data.message)) {
        errorMessage = error.response.data.message.join("; ");
      } else if (typeof error.response.data.message === "object") {
        errorMessage = JSON.stringify(error.response.data.message);
      }
      toast.error(errorMessage);
      // console.log(errorMessage);
    }
  } finally {
    setCreateLoading(false);
    fetchInvoiceCode()
    // setProducts([])
  }

}

const fetchInvoiceCode = async () => {
  setLoading(true);
 
  try {
    const response = await axios.get(
      `${BASE_URL}/generate-sales-invoice-code`,
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${bearer}`
        }
      }
    );
    const resultss = response.data?.data;
    //   console.log(resultss);
    setSICode(resultss);
    // console.log(invoiceData)
    //   console.log(results, "NI");
  } catch (error) {
    const errorStatus = error.response.data.message;
    console.error(errorStatus);
  } finally {
    setLoading(false);
  }
};

useEffect(() => {
  if (bearer) {
    fetchInvoiceCode();
  }
}, [bearer]);

const handleQuantityChange = (id, increment) => {
  setProducts((prevProducts) =>
    prevProducts.map((product) =>
      product.id === id
        ? {
            ...product,
            selectedQuantity: Math.min(
              product.selectedQuantity + increment,
              product.quantity // Limit to available stock
            ),
          }
        : product
    )
  );
};

const fetchData1 = async () => {
  setIsLoading(true);
  try {
    const response = await axios.get(`${BASE_URL}/sale_inventory/get-all-suspended`, {
      headers,
    });
    const roleList = response.data?.data;
    setOpt(roleList);
    // setRoless(roleList);
  } catch (error) {
    if (error.response && error.response.status === 401) {
      // Redirect to login page if unauthorized
      navigate("/login");
    } else {
      const errorStatus = error.response?.data?.message;
      console.log(errorStatus);
      setTableData([]);
    }
  } finally {
    setIsLoading(false);
  }
};

const approveProduct = async () => {
  setCreateLoading(true);
  try {
    // Prepare data to be sent to the 'get-approved' API
    const productIds = products.map(item => item.id);
    const quantities = products.map(item => item.selectedQuantity);
    const amounts = products.map(item => parseFloat(item.price * item.selectedQuantity));
    const totalAmount = products.reduce(
      (acc, item) => acc + item.selectedQuantity * parseFloat(item.price),
      0
    );

    // Ensure the transaction number is available from the selected product
    const payload = {
      transaction_number: sICode,  // Transaction number from selected product
      product_id: productIds,
      quantity: quantities,
      amount: amounts,
      total_amount: totalAmount,
      status: "1"  // Assuming '1' means 'approved'
    };

    // Make the API call to approve the products
    const response = await axios.post(
      `${BASE_URL}/sale_inventory/approve-transaction`,
      payload,
      { headers }
    );

    console.log(response);
    fetchInvoiceCode();  // You might want to fetch the invoice code again after approval
    toast.success(response.data.message);

    // Navigate to print receipt or another relevant page
    navigate("/print_receipts", { state: { products } });

  
  } catch (error) {

    console.log(error?.response?.data?.message);
    toast.error(error?.response?.data?.message || 'An error occurred.');
  } finally {
    setCreateLoading(false);
  }
};



const options = opt.map((obj) => {
  const key = Object.keys(obj)[0]; // Get the key (e.g., 999999)
  const { total_amount, product_details } = obj[key]; // Destructure to get total_amount and total_quantity

  return {
    value: key, // Using the key as the value for the select option
    label: `Transaction #${key} - Amount: #${Number(total_amount).toLocaleString()} - Items: ${product_details.length}`,
    data: obj[key], // Attach full item data if needed
  };
});

const handleSelectChange = (selectedOption) => {
  console.log("Selected Option:", selectedOption.value); // Debug log

  if (selectedOption?.data?.product_details?.length > 0) {
    // Prefill products with quantities from the selected suspended order
    const productsWithQuantity = selectedOption.data.product_details.map((product) => ({
      ...product,
      selectedQuantity: parseInt(product.quantity, 10), // Prefill with quantity from API
      transactionId: selectedOption.value, // Use transaction number as transactionId
    }));

    setProducts(productsWithQuantity); // Update products state
    setSICode(selectedOption.value); // Save the selected transaction number
  } else {
    // Clear products if no product details are available
    setProducts([]);
    setSICode(''); // Reset transaction number if no products
  }
};




const handleRemoveProduct = (id) => {
  setProducts((prevProducts) =>
    prevProducts.filter((product) => product.id !== id)
  );
};


const calculateTotalAmount = () => {
  return products.reduce(
    (total, product) =>
      total + product.selectedQuantity * parseFloat(product.price),
    0
  );
};

const handleClearItems = () => {
  setProducts([]);
};

useEffect(() => {
  if (bearer) {
    // fetchData();
    fetchData1();
  }
}, [bearer]);

const handlePrintReceipt = () => {
  navigate("/print_receipts", { state: { products } });
};

const calculateTotal = () => {
  return products.reduce((total, product) => 
    total + (product.selectedQuantity * parseFloat(product.price)), 0
  );
};




  return (
    <div style={{ backgroundColor: "white" }}>
      <OrderNav />

      <div className={classes.finishedbodyCont}>
      <div className={classes.formSection} style={{ backgroundColor: "white" }}>
              <div className={classes.formSectionHeader}>
                <div className={classes.serchsection}>
                  {/* <h5 className={classes.payText}>Pending Order</h5> */}
                  <div style={{ width: "850px" }}>
                    {" "}
                    <Select
                      loading={isLoading}
                      dropdownHeight="100px"
                      options={options}
                      onChange={handleSelectChange}
                      style={{ borderRadius: 5 }}
                      placeholder="Select Transaction"
                    />
                  </div>
                </div>
              </div>
            
              <div className={classes.tableCont}>
                <div className={classes.table}>
                  <Table striped hover>
                    <thead className={classes.tableHead}>
                      <tr>
                        {/* <th className={classes.tableHeadh}>Product</th> */}
                        <th className={classes.tableHeadh}>Name</th>
                        <th className={classes.tableHeadh}>Quantity</th>
                        <th className={classes.tableHeadh}>Amount</th>
                        <th className={classes.tableHeadh}>Total Amount</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody className={classes.bodyH}>
                      {products.length === 0 ? (
                        <p className={classes.addText}>Add New Product</p>
                      ) : (
                        products.map((product) => (
                          <tr key={product.id}>
                            <td>{product.name}</td>
                            <td>
                              <span className={classes.btnSpan}>
                                {/* <button
                                  className={classes.minusBtn}
                                  onClick={() =>
                                    handleQuantityChange(product.id, -1)
                                  }
                                  disabled={product.selectedQuantity <= 1}
                                >
                                  -
                                </button> */}
                                <span> {product.selectedQuantity}</span>
                                {/* <button
                                  className={classes.plusBtn}
                                  onClick={() =>
                                    handleQuantityChange(product.id, 1)
                                  }
                                  disabled={
                                    product.selectedQuantity >= product.quantity
                                  }
                                >
                                  +
                                </button> */}
                              </span>
                            </td>
                            {/* <td className={classes.incrmntbtns}><button className={classes.dcrmnt}>-</button>2<button className={classes.incrmnt} >+</button></td> */}
                            <td>
                              {parseFloat(product.price).toLocaleString('en-US', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
})}
                            </td>
                            <td>
                              {(
                                product.selectedQuantity *
                                parseFloat(product.price)
                              ).toLocaleString('en-US', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
})}
                            </td>
                            <td>
                              <button
                                onClick={() => handleRemoveProduct(product.id)}
                              >
                                <img
                                  src={trash}
                                  alt="product1"
                                  className={classes.trash}
                                />
                              </button>
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </Table>
                </div>

                <div className={classes.sub}>
                <div className={classes.trsDiv}>
                  <h3 className={classes.sTal}>Transaction No</h3>
                  <p>{sICode}</p>
                  </div>
                 
                  <div
                    style={{
                      height: "2px",
                      border: "1px solid #E9ECEF",
                      marginLeft: -20,
                      marginRight: -20,
                    }}
                  ></div>
                   <div className={classes.ites}>
                    <span className={classes.name}>Sub Total</span>
                    <sapn className={classes.name}>{(calculateTotal()).toLocaleString('en-US', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
})}</sapn>
                  </div>
                 
                  <div className={classes.ites}>
                    <span className={classes.name}>VAT</span>
                    <span className={classes.name}>{(calculateTotal() * 0.075).toLocaleString('en-US', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
})}</span>
                  </div>
                 

                  <div
                    style={{
                      height: "2px",
                      border: "1px solid #E9ECEF",
                      marginLeft: -20,
                      marginRight: -20,
                      marginTop: 100,
                    }}
                  ></div>
                  <div className={classes.itess}>
                    <span className={classes.ttl}>Total</span>
                    <sapn className={classes.ttl}>
                    {(
                        calculateTotalAmount() +
                        calculateTotalAmount() * 0.075
                      ).toLocaleString('en-US', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
})}
                    </sapn>
                  </div>
                </div>
              </div>
              <div className={classes.btnBtn}>
                <button
                  className={classes.printBtn}
                  onClick={approveProduct}
                  disabled={products.length <= 0}
                >
                  {
                    createLoading ? 'Transaction in progress...' : ' Approve Transaction'
                  }
                
                
                </button>
                {/* <button
                  className={classes.printBtn1}
                  onClick={printProduct}
                  disabled={showPrint }
                >
                Print Receipt
                
                </button> */}
                <button className={classes.clearBtn} onClick={handleClearItems}>
                  Clear Items
                </button>
              </div>
            </div>
      </div>
    </div >
)
}