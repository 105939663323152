import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
// import OnbImg from '../../Images/image bg.png';
import classes from './LatestDashboard.module.css';
import One from '../../assets/dashimg/1.png'
import Two from '../../assets/dashimg/2.png'
import Three from '../../assets/dashimg/3.png'
import Four from '../../assets/dashimg/4.png'
import { Button, ProgressBar } from 'react-bootstrap';
// import RegLogo from '../../Images/RegistrationLogo.svg'
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Chart } from 'react-charts'
import axios from 'axios';
import { Spinner } from 'react-bootstrap';
// import AsyncStorage from '@react-native-async-storage/async-storage';
import { BASE_URL } from '../api/api';
import LatestAssetsDash from './LatestAssetsDash';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
// import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'react-chartjs-2';
import { Pie } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

const AssetsDashboard = () => {
    const navigate = useNavigate();
    const [bearer, setBearer] = useState('');
    const [user, setUser] = useState('');
    const [company, setCompany] = useState('');
    const [userType, setUserType] = useState('');
    const [continent, setContinent] = useState('');
    const [region, setRegion] = useState('');
    const [province, setProvince] = useState('');
    const [firstName, setFirstName] = useState('Sade Okoya');


    const [validity, setValidity] = useState('');
    const [permits, setPermits] = useState([]);
    const [admin, setAdmin] = useState("");
    const [loading, setLoading] = useState(false);


    const readData = async () => {
        try {
            const value = await AsyncStorage.getItem('userToken');
            const value1 = await AsyncStorage.getItem('tobi');
            // const value2 = await AsyncStorage.getItem('permissions');
            const value3 = await AsyncStorage.getItem('admin');
            const value7 = await AsyncStorage.getItem('companyName');
            const value8 = await AsyncStorage.getItem('validity');
            const permitsss = await AsyncStorage.getItem('modules');

            if (value !== null) {
                setBearer(value);
            }
            if (permitsss !== null) {
                const parsedPermits = JSON.parse(permitsss);
                const permitNames = parsedPermits.map(permit => permit.name);
                setPermits(permitNames);
                // console.log(permitNames);
            }
            if (value1 !== null) {
                setUser(value1);
            }
            if (value8 !== null) {
                setValidity(value8);
            }
            // if (value2 !== null) {
            //   setPermissions(value2);
            // }
            if (value3 !== null) {
                setAdmin(value3);
            }
            if (value7 !== null) {
                setCompany(value7);
            }
        } catch (e) {
            alert('Failed to fetch the input from storage');
        }
    };

    useEffect(() => {
        readData();
    }, []);


    const pieData = {
        labels: ['Chair', 'Furniture', 'Office Equipment', 'Paints', 'Plastic Chairs', 'Instruments'],
        datasets: [
            {
                label: 'Assets Distribution',
                data: [52, 10, 8, 6, 12, 12],
                backgroundColor: [
                    'rgba(144, 238, 144, 0.5)',  // Light Green (lowest %)
                    'rgba(124, 252, 0, 0.5)',    // Lime Green
                    'rgba(0, 255, 0, 0.5)',      // Bright Green
                    'rgba(50, 205, 50, 0.5)',    // Lime Green
                    'rgba(34, 139, 34, 0.5)',    // Forest Green
                    'rgba(0, 100, 0, 0.5)',      // Dark Green (highest %)                    
                ],
                borderColor: [
                    'rgba(144, 238, 144, 0.5)',  // Light Green (lowest %)
                    'rgba(124, 252, 0, 0.5)',    // Lime Green
                    'rgba(0, 255, 0, 0.5)',      // Bright Green
                    'rgba(50, 205, 50, 0.5)',    // Lime Green
                    'rgba(34, 139, 34, 0.5)',    // Forest Green
                    'rgba(0, 100, 0, 0.5)',      // Dark Green (highest %)
                ],
                borderWidth: 1,
            },
        ],
    };



    const data = [
        {
            label: 'Money In',
            data: [
                { primary: 'March 2024', secondary: 3_200_000 },
                { primary: 'April 2024', secondary: 2_800_000 },
                { primary: 'May 2024', secondary: 3_600_000 },
                { primary: 'June 2024', secondary: 4_000_000 },
            ],
        },
        {
            label: 'Money Out',
            data: [
                { primary: 'March 2024', secondary: 1_500_000 },
                { primary: 'April 2024', secondary: 2_000_000 },
                { primary: 'May 2024', secondary: 2_500_000 },
                { primary: 'June 2024', secondary: 3_000_000 },
            ],
        },
    ];

    const chartOptions = {
        primaryAxis: {
            getValue: datum => datum.primary,
            type: 'ordinal',
        },
        secondaryAxes: [
            {
                getValue: datum => datum.secondary,
                elementType: 'bar',
                min: 1_000_000,
                max: 4_000_000,
                tickFormat: d => `${(d / 1_000_000).toFixed(1)}M`,
            },
        ],
        getSeriesStyle: series => ({
            color: series.label === 'Money In' ? '#2D995F' : '#FFBA08',
        }),
    };

    const Legend = ({ data }) => (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
            {data.map(series => (
                <div key={series.label} style={{ display: 'flex', alignItems: 'center', margin: '0 10px' }}>
                    <div
                        style={{
                            width: '10px',
                            height: '10px',
                            backgroundColor: series.label === 'Money In' ? '#2D995F' : '#FFBA08',
                            marginRight: '10px',
                        }}
                    ></div>
                    <span>{series.label}</span>
                </div>
            ))}
        </div>
    );

    function formatDate(dateString) {
        const date = new Date(dateString);
        const formattedDate = `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())} `;
        return formattedDate;
    }

    const handleLogout = async () => {
        setLoading(true);

        try {
            const response = await axios.post(
                `${BASE_URL}/logout`,
                {},
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${bearer}`
                    }
                }
            );

            navigate('/login');
            //   toast.success(response.data.message);
        } catch (error) {
            let errorMessage = 'An error occurred. Please try again.';
            if (error.response) {
                if (error.response.status === 401) {
                    navigate('/login');
                } else if (error.response.data && error.response.data.message) {
                    if (typeof error.response.data.message === 'string') {
                        errorMessage = error.response.data.message;
                    } else if (Array.isArray(error.response.data.message)) {
                        errorMessage = error.response.data.message.join('; ');
                    } else if (typeof error.response.data.message === 'object') {
                        errorMessage = JSON.stringify(error.response.data.message);
                    }
                }
            }
            //   toast.error(errorMessage);
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    function formatDate(dateString) {
        const date = new Date(dateString);
        const formattedDate = `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())} `;
        return formattedDate;
    }

    function padZero(num) {
        return num < 10 ? `0${num}` : num;
    }

    const changeName = () => {
        setFirstName('Ibrahim Awonuga');
    }


    return (
        <div className={classes.latestBody}>
            <div className={classes.latestBodyContainer}>
                <div className={classes.formSection}>
                    <LatestAssetsDash />
                    <div style={{ width: "100%", minWidth: "1000px" }}>
                        <div className={classes.chartCont1}>
                            <Container>
                                <Row>
                                    <Col md={3} style={{ padding: '20px', marginBottom: '20px' }}>
                                        <div className={classes.dashBox1212}>
                                            <h5>
                                                660
                                            </h5>
                                            <p>
                                                Fixed Assets
                                            </p>
                                        </div>
                                    </Col>
                                    <Col md={3} style={{ padding: '20px', marginBottom: '20px' }}>
                                        <div className={classes.dashBox1212jd}>
                                            <h5>
                                                $120,000,000
                                            </h5>
                                            <p>
                                                Value of Fixed Assets
                                            </p>
                                        </div>
                                    </Col>
                                    <Col md={3} style={{ padding: '20px', marginBottom: '20px' }}>
                                        <div className={classes.dashBox1212}>
                                            <h5>
                                                $150,892,000
                                            </h5>
                                            <p>
                                                Net Assets Value
                                            </p>
                                        </div>
                                    </Col>
                                    <Col md={3} style={{ padding: '20px', marginBottom: '20px' }}>
                                        <div className={classes.dashBox1212jd}>
                                            <h5>
                                                $84,300,000
                                            </h5>
                                            <p>
                                                Purchases in Fiscal Year
                                            </p>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={4}>
                                        <h3>
                                            Fixed Assets Chart
                                        </h3>
                                    </Col>
                                    <Col md={8}>
                                        <div style={{ width: '60%', margin: '0 auto' }}>
                                            {pieData && pieData.labels && pieData.datasets ? (
                                                <Pie data={pieData} />
                                            ) : (
                                                <p>No data available for the chart</p>
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AssetsDashboard;