import React, { useState, useEffect } from 'react';
import classes from '../Process-General-ledger/ProcessGeneral.module.css';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useLocation } from 'react-router-dom';

export default function ConsolidateReportCashbook() {
  const [bearer, setBearer] = useState('');
  const [company, setCompany] = useState('');
  const location = useLocation();
  const [currentDateTime, setCurrentDateTime] = useState('');
  const [totalDebit, setTotalDebit] = useState('');
  const [totalCredit, setTotalCredit] = useState('');
  const [continent, setContinent] = useState('');
  const [region, setRegion] = useState('');
  const [province, setProvince] = useState('');
  const [userType, setUserType] = useState('');

  useEffect(() => {
    const addLeadingZero = (number) => (number < 10 ? `0${number}` : number);

    const currentDate = new Date();
    const day = currentDate.getDate();
    const month = addLeadingZero(currentDate.getMonth() + 1);
    const year = currentDate.getFullYear();
    const minutes = addLeadingZero(currentDate.getMinutes());
    const hours = addLeadingZero(currentDate.getHours() % 12 || 12);
    const seconds = addLeadingZero(currentDate.getSeconds());
    const ampm = currentDate.getHours() >= 12 ? 'PM' : 'AM';

    const formattedDateTime = `${day}/${month}/${year} `;

    setCurrentDateTime(formattedDateTime);
  }, []);

  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem('userToken');
      const value1 = await AsyncStorage.getItem('companyName');
      const admins = await AsyncStorage.getItem('admin');
      const continentName = await AsyncStorage.getItem('continent');
            const regionName = await AsyncStorage.getItem('region');
            const provinceName = await AsyncStorage.getItem('province');

      if (value !== null) {
        setBearer(value);
      }
      if (value1 !== null) {
        setCompany(value1);
        console.log(company);
      }
      if (admins !== null) {
        setUserType(admins);
      }
      if (continentName !== null) {
        setContinent(continentName);
      }
      if (regionName !== null) {
        setRegion(regionName);
      }
      if (provinceName !== null) {
        setProvince(provinceName);
      }
    } catch (e) {
      alert('Failed to fetch the input from storage');
    }
  };

  useEffect(() => {
    readData();
  }, []);

  const { document, inputs } = location.state || {};
  console.log(inputs, document, "printing");
  const startDate = new Date(inputs.start_date);
  const endDate = new Date(inputs.end_date);

  const getOrdinalSuffix = (number) => {
    const suffixes = ['th', 'st', 'nd', 'rd'];
    const v = number % 100;
    return v >= 11 && v <= 13
      ? 'th'
      : suffixes[v % 10] || 'th';
  };

  function formattedDates(dateString) {
    const date = new Date(dateString);
    const formattedDate = `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())}`;
    return formattedDate;
  }

  function padZero(num) {
    return num < 10 ? `0${num}` : num;
  }

  useEffect(() => {
    if (document) {
      const debitTotal = document.reduce((total, item) => total + parseFloat(item.debit), 0);
      const creditTotal = document.reduce((total, item) => total + parseFloat(item.credit), 0);

      const formattedDebitTotal = debitTotal.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
      const formattedCreditTotal = creditTotal.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });

      setTotalDebit(formattedDebitTotal);
      setTotalCredit(formattedCreditTotal);
    }
  }, [document]);

  function formatDate(dateString) {
    const date = new Date(dateString);
    const formattedDate = `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())} `;
    return formattedDate;
  }

  const allNamesSame = document.every(doc => doc.account?.gl_name === document[0].account?.gl_name);
  const displayName = allNamesSame ? document[0].account?.gl_name : "All Accounts";

  return (
    <div>
      <div className='newBody'>
        <div className='newWidth'>
          <div className="wrapper">
            <div className={classes.a4}>
              <div className={classes.header}>
                <h1>THE REDEEMED CHRISTIAN CHURCH OF GOD</h1>
                {/* <p style={{ width: "100%", fontSize: 18 }}>
                  {userType === "Continental Accountant" ? continent :
                    userType === "Regional Accountant" ? region :
                    userType === "Super Admin" ? "Super Admin" :
                    userType === "Provincial Accountant" ? province : null}
                </p> */}
                  <p style={{ width: "100%", fontSize: 18, textTransform: "uppercase" }}>{inputs?.name}</p>
                <h6>
                {/* {userType === "Continental Accountant" ? continent :
                    userType === "Regional Accountant" ? region :
                    userType === "Super Admin" ? "Super Admin" :
                    userType === "Provincial Accountant" ? province : null}  */}
                 CONSOLIDATED CASHBOOK REPORT FROM {formatDate(startDate)} TO {formatDate(endDate)}
                 
                </h6>
                <h6 style={{ fontWeight: 700 }}>{displayName}</h6>
              </div>
              <p><span style={{ fontWeight: 700, color: "#000" }}>DATE PRINTED:</span> {currentDateTime} </p>
              <table className={`${classes.table} print-table`}>
                <thead>
                  <tr>
                    <th style={{ width: '200px' }}>Post Date</th>
                    <th style={{ width: '200px' }}>Value Date</th>
                    <th style={{ width: '500px' }}>Details</th>
                    <th style={{ textAlign: "right", width: '200px' }}>Debit</th>
                    <th style={{ textAlign: "right", width: '200px' }}>Credit</th>
                  </tr>
                </thead>
                <tbody>
                  {document && document.map((item, index) => (
                    <tr key={index}>
                      <td>{formattedDates(item.created_at)}</td>
                      <td>{item.transaction_date}</td>
                      <td>{item.details}</td>
                      <td style={{ textAlign: "right", width: '200px' }}>{parseFloat(item.debit).toLocaleString('en-US', {
                        minimumIntegerDigits: 1,
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })}</td>
                      <td style={{ textAlign: "right", width: '200px' }}>{parseFloat(item.credit).toLocaleString('en-US', {
                        minimumIntegerDigits: 1,
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })}</td>
                    </tr>
                  ))}
                </tbody>
                
                  <tr>
                    <td colSpan="5" style={{ borderBottom: "2px solid #000" }}></td>
                  </tr>
                  <tr>
                    <td colSpan="3">Total</td>
                    <td style={{ textAlign: "right", fontWeight: "bold", width: '150px' }}>{totalDebit}</td>
                    <td style={{ textAlign: "right", fontWeight: "bold", width: '150px' }}>{totalCredit}</td>
                  </tr>
                  <tr>
                    <td colSpan="5" style={{ borderBottom: "2px solid #000" }}></td>
                  </tr>
               
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
