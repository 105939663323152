import React, { useState, useEffect } from 'react';
import classes from './PrintIncomeStatement.module.css';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useLocation } from 'react-router-dom';

export default function PrintIncomeStatementConsolidated() {
    const [bearer, setBearer] = useState('');
    const [company, setCompany] = useState('');
    const location = useLocation();
    const [currentDateTime, setCurrentDateTime] = useState('');
    const [continent, setContinent] = useState('');
    const [region, setRegion] = useState('');
    const [province, setProvince] = useState('');
    const [userType, setUserType] = useState('');

    useEffect(() => {
        const addLeadingZero = (number) => (number < 10 ? `0${number}` : number);
    
        const currentDate = new Date();
        const day = currentDate.getDate();
        const month = addLeadingZero(currentDate.getMonth() + 1);
        const year = currentDate.getFullYear();
        const minutes = addLeadingZero(currentDate.getMinutes());
        const hours = addLeadingZero(currentDate.getHours() % 12 || 12);
        const seconds = addLeadingZero(currentDate.getSeconds());
        const ampm = currentDate.getHours() >= 12 ? 'PM' : 'AM';
    
        const formattedDateTime = `${day}/${month}/${year} `;
    
        setCurrentDateTime(formattedDateTime);
    }, []);

    const readData = async () => {
        try {
            const value = await AsyncStorage.getItem('userToken');
            const value1 = await AsyncStorage.getItem('companyName');
            const continentName = await AsyncStorage.getItem('continent');
            const regionName = await AsyncStorage.getItem('region');
            const provinceName = await AsyncStorage.getItem('province');
            const admins = await AsyncStorage.getItem('admin');

            if (value !== null) {
                setBearer(value);
            }
            if (value1 !== null) {
                setCompany(value1);
            }
            if (continentName !== null) {
                setContinent(continentName);
            }
            if (regionName !== null) {
                setRegion(regionName);
            }
            if (provinceName !== null) {
                setProvince(provinceName);
            }
            if (admins !== null) {
                setUserType(admins);
            }
        } catch (e) {
            alert('Failed to fetch the input from storage');
        }
    };

    useEffect(() => {
        readData();
    }, []);

    const { document, inputs, filtData3 } = location.state || {};
    // console.log(selectedDate, selectedEndDate);
    // const startDate = new Date(selectedDate);
    // const endDate = new Date(selectedEndDate);

    const getOrdinalSuffix = (number) => {
        const suffixes = ['th', 'st', 'nd', 'rd'];
        const v = number % 100;
        return v >= 11 && v <= 13
          ? 'th'
          : suffixes[v % 10] || 'th';
    };

    const formatDate = (date) => {
        const day = date.getDate();
        const month = date.toLocaleString('default', { month: 'long' });
        const year = date.getFullYear();
    
        return `${day}${getOrdinalSuffix(day)} ${month}, ${year}`;
    };

    const calculateTotals = (data) => {
        return data.reduce((totals, item) => {
            totals.current += parseFloat(item.current) || 0;
            totals.opening_balance += parseFloat(item.opening_balance) || 0;
            totals.total += parseFloat(item.total) || 0;
            return totals;
        }, { current: 0, opening_balance: 0, total: 0 });
    };

    const incomeTotals = calculateTotals(document);
    const expensesTotals = calculateTotals(inputs);

    const netIncome = incomeTotals.total - expensesTotals.total;
    const netIncomeCurrent = incomeTotals.current - expensesTotals.current;
    const netIncomeOpeningBalance = incomeTotals.opening_balance - expensesTotals.opening_balance;

    return (
        <div className={classes.generalbd}>
            <div className={classes.a4}>
                <div className={classes.header}>
                <h1>THE REDEEMED CHRISTIAN CHURCH OF GOD</h1>
                <p style={{ width: "100%", fontSize: 18, textTransform: "uppercase" }}>
                  {filtData3?.name}</p>
                    {/* <p style={{ width: "100%", fontSize: 18 }}>
                        {userType === "Continental Accountant" ? continent :
                         userType === "Regional Accountant" ? region :
                         userType === "Super Admin" ? "Super Admin" :
                         userType === "Provincial Accountant" ? province : null}
                    </p> */}
                    <h6 style={{ fontWeight: 900 }}>CONSOLIDATED INCOME STATEMENT FOR THE PERIOD OF {filtData3?.start_date} TO {filtData3?.end_date}</h6>
                </div>
                <p><span style={{ fontWeight: 700, color: "#000" }}>DATE PRINTED:</span> {currentDateTime}</p>
                <table className={classes.table}>
                    {/* <thead>
                        <tr>
                            <th style={{ textAlign: 'left', width: '50px' }}>Account Code</th>
                            <th style={{ textAlign: 'left', width: '50px' }}>Account Name</th>
                            <th style={{ textAlign: 'right', width: '50px' }}>Current Period</th>
                            <th style={{ textAlign: 'right', width: '50px' }}>Last Month</th>
                            <th style={{ textAlign: 'right', width: '50px' }}>Difference</th>
                        </tr>
                    </thead> */}
                    <tbody>
                        {document.length > 0 && (
                            <tr>
                                <td colSpan="3" style={{ fontWeight: 'bold', textAlign: 'left',  }}>INCOME</td>
                                <td colSpan="1" style={{ fontWeight: 'bold', textAlign: 'left', whiteSpace: "nowrap",  }}>Current Period</td>
                                <td colSpan="1" style={{ fontWeight: 'bold', textAlign: 'left', whiteSpace: "nowrap", }}>Last Month</td>
                                <td colSpan="1" style={{ fontWeight: 'bold', textAlign: 'left', whiteSpace: "nowrap", }}>Difference</td>
                            </tr>
                        )}
                        {document.map((item, index) => (
                            <tr key={index}>
                                <td style={{width: "100%", whiteSpace: "nowrap", paddingLeft: 20}}>{item.gl_code} {item.gl_name}</td>
                               
                                <td colSpan="3" style={{ textAlign: "right",  }}>
                                    {parseFloat(item.current).toLocaleString('en-US', {
                                        minimumIntegerDigits: 1,
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    })}
                                </td>
                                <td  style={{ textAlign: "right",  }}>
                                    {parseFloat(item.opening_balance).toLocaleString('en-US', {
                                        minimumIntegerDigits: 1,
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    })}
                                </td>
                                <td style={{ textAlign: "right",  }}>
                                    {parseFloat(item.total).toLocaleString('en-US', {
                                        minimumIntegerDigits: 1,
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    })}
                                </td>
                            </tr>
                        ))}
                          {document.length > 0 && (
                            <tr>
                                <td colSpan="3" style={{ textAlign: 'left', fontWeight: 'bold', borderTop: '1px solid #000' }}>TOTAL INCOME</td>
                                <td style={{ textAlign: "right", fontWeight: "bold",borderTop: '1px solid #000' }}>
                                    {incomeTotals.current.toLocaleString('en-US', {
                                        minimumIntegerDigits: 1,
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    })}
                                </td>
                                <td style={{ textAlign: "right", fontWeight: "bold", borderTop: '1px solid #000'}}>
                                    {incomeTotals.opening_balance.toLocaleString('en-US', {
                                        minimumIntegerDigits: 1,
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    })}
                                </td>
                                <td style={{ textAlign: "right", fontWeight: "bold", borderTop: '1px solid #000'}}>
                                    {incomeTotals.total.toLocaleString('en-US', {
                                        minimumIntegerDigits: 1,
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    })}
                                </td>
                            </tr>
                        )}
                        <div style={{marginTop: 30}}/>
                        {document.length > 0 && inputs.length > 0 && (
                            <tr>
                                <td colSpan="3" style={{ fontWeight: 'bold', textAlign: 'left' }}>EXPENSES</td>
                                <td colSpan="1" style={{ fontWeight: 'bold', textAlign: 'left', whiteSpace: "nowrap", }}>Current Period</td>
                                <td colSpan="1" style={{ fontWeight: 'bold', textAlign: 'left', whiteSpace: "nowrap", }}>Last Month</td>
                                <td colSpan="1" style={{ fontWeight: 'bold', textAlign: 'left', whiteSpace: "nowrap", }}>Difference</td>
                            </tr>
                        )}
                        {inputs.map((item, index) => (
                            <tr key={index + document.length}>
                                <td style={{width: "100%", whiteSpace: "nowrap", paddingLeft: 20}}>{item.gl_code} {item.gl_name}</td>
                               
                                <td colSpan="3" style={{ textAlign: "right",  }}>
                                    {parseFloat(item.current).toLocaleString('en-US', {
                                        minimumIntegerDigits: 1,
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    })}
                                </td>
                                <td style={{ textAlign: "right",  }}>
                                    {parseFloat(item.opening_balance).toLocaleString('en-US', {
                                        minimumIntegerDigits: 1,
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    })}
                                </td>
                                <td style={{ textAlign: "right",  }}>
                                    {parseFloat(item.total).toLocaleString('en-US', {
                                        minimumIntegerDigits: 1,
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    })}
                                </td>
                            </tr>
                        ))}
                      
                        {inputs.length > 0 && (
                            <tr>
                                <td colSpan="3" style={{ textAlign: 'left', borderTop: '1px solid #000', fontWeight: 'bold' }}>TOTAL EXPENSES</td>
                                <td style={{ textAlign: "right", fontWeight: "bold", borderTop: '1px solid #000' }}>
                                    {expensesTotals.current.toLocaleString('en-US', {
                                        minimumIntegerDigits: 1,
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    })}
                                </td>
                                <td style={{ textAlign: "right", fontWeight: "bold", borderTop: '1px solid #000' }}>
                                    {expensesTotals.opening_balance.toLocaleString('en-US', {
                                        minimumIntegerDigits: 1,
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    })}
                                </td>
                                <td style={{ textAlign: "right", fontWeight: "bold", borderTop: '1px solid #000' }}>
                                    {expensesTotals.total.toLocaleString('en-US', {
                                        minimumIntegerDigits: 1,
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    })}
                                </td>
                            </tr>
                        )}
                        <div style={{marginTop: 30}}/>
                        {inputs.length > 0 && (
                            <tr>
                                <td colSpan="3" style={{ textAlign: 'left', borderTop: '1px solid #000', fontWeight: 'bold', borderBottom: '1px solid #000' }}>NET INCOME</td>
                                <td style={{ textAlign: "right", fontWeight: "bold", borderTop: '1px solid #000', borderBottom: '1px solid #000' }}>
                                {netIncomeCurrent.toLocaleString('en-US', {
                                        minimumIntegerDigits: 1,
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    })}
                                </td>
                                <td style={{ textAlign: "right", fontWeight: "bold", borderTop: '1px solid #000', borderBottom: '1px solid #000' }}>
                                    {netIncomeOpeningBalance.toLocaleString('en-US', {
                                        minimumIntegerDigits: 1,
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    })}
                                </td>
                                <td style={{ textAlign: "right", fontWeight: "bold", borderTop: '1px solid #000', borderBottom: '1px solid #000' }}>
                                    {netIncome.toLocaleString('en-US', {
                                        minimumIntegerDigits: 1,
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    })}
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
}
