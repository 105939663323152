import React, { useEffect, useState } from 'react'
import classes from './Redeem.module.css'
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useLocation, useNavigate } from 'react-router-dom';

const Redeem = () => {
  const [user, setUser] = useState("");
    const [bearer, setBearer] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const [company, setCompany] = useState('');
    const [phone, setPhone] = useState('');
    const [address, setAddress] = useState('');
    const [email, setEmail] = useState('');
    const [currentDateTime, setCurrentDateTime] = useState('');
  
    const readData = async () => {
        try {
          const value = await AsyncStorage.getItem('userToken');
          const value2 = await AsyncStorage.getItem('companyId');
          const value1 = await AsyncStorage.getItem('tobi');
          const value3 = await AsyncStorage.getItem('companyName');
          const value4 = await AsyncStorage.getItem('companyEmail');
          const value5 = await AsyncStorage.getItem('companyPhone');
    
          if (value !== null) {
            setBearer(value);
            // setAuthenticated(true);
          }
          if (value1 !== null) {
              setUser(value1);
            }
          if (value3 !== null) {
            setCompany(value3);
          }
          if (value4 !== null) {
            setEmail(value4);
          }
          if (value5 !== null) {
            setPhone(value5);
          }
    
        } catch (e) {
          alert('Failed to fetch the input from storage');
        }
      };
    
      useEffect(() => {
        readData();
      }, []);


  return (
    <div className={classes.redeemTops}>
        
        <div className={classes.report}>
  <header className={classes.header}>
   
    <div className={classes.title}>
      <h1 className={classes.titleTop}>{company}</h1>
      <h2 className={classes.subTitle}>RECONCILIATION SUMMARY REPORT AS AT </h2>
    </div>
  </header>
  
  <div className={classes.details}>
    <div className={classes.row}>
      <span className={classes.region}>REGION: REGION 10 HQ</span>
      <span className={classes.region}>PROVINCE:</span>
      <span className={classes.region}>CURRENCY: NGN</span>
    </div>
    <div className={classes.rowB}>
      <span className={classes.region}>RECONCILIATION OF: Default Account</span>
    </div>
  </div>
  
  <table className={classes.tableData}>
    <tr className={classes.openTop}>
      <td className={classes.ledgerBal}>Ledger Opening Balance:</td>
      <td className={classes.td}></td>
      <td className={classes.ledgerAmount}>0.00</td>
    </tr>
    
    <tr>
      <td colspan="2" className={classes.addT}>ADD :</td>
    </tr>
    <tr>
      <td className={classes.ledgerOt}>Outstanding Credits in LEDGER:</td>
      <td className={classes.amount}>2,000.00</td>
    </tr>
    <tr>
      <td className={classes.ledgerOtC}>Outstanding Credits in STATEMENT:</td>
      <td className={classes.amountC}>0.00</td>
      <td className={classes.totalC}>2,000.00</td>
    </tr>
    <tr>
      <td colspan="2" className={classes.addT}>LESS:</td>
    </tr>
    <tr>
      <td className={classes.ledgerOt}>Outstanding Debits in LEDGER:</td>
      <td className={classes.amount}>2,000.00</td>
    </tr>
    <tr>
      <td className={classes.ledgerOtC}>Outstanding Debits in STATEMENT:</td>
      <td className={classes.amountC}>0.00</td>
      <td className={classes.totalC}>2,000.00</td>
    </tr>
    <tr>
      <td className={classes.addStatment}>Statement Balance:</td>
      <td className={classes.td}></td>
      <td className={classes.ledgerAmount}>0.00</td>
    </tr>
    <tr>
      <td className={classes.addStatment}>DIFFERENCE:</td>
      <td className={classes.td}></td>
      <td className={classes.ledgerAmount}>0.00</td>
    </tr>
  </table>
  
  <div className={classes.footer}>
    <div className={classes.rowFooter}>
      <span className={classes.addStatment}>PREPARED BY:</span>
      <span className={classes.addT}>Date:</span>
      <span></span>
    </div>
    <div className={classes.rowFooter}>
      <span className={classes.addStatment}>CHECKED BY:</span>
      <span className={classes.addT}>Date:</span>
      <span></span>
    </div>
    <div className={classes.rowFooter}>
      <span className={classes.addStatment}>APPROVED BY:</span>
      <span className={classes.addT}>Date:</span>
      <span></span>
    </div>
  </div>
</div>
<div className={classes.redeemTops}>
        
        <div className={classes.report}>
  
  
  <div className={classes.detailsB}>
    <div className={classes.row}>
      <span className={classes.regions}>Outstanding Debits in Ledger</span>
    </div>
    <table className={classes.tableR2}>
    <tr className={classes.tableHead}>
        <th className={classes.tableSubHead}>Post Date</th>
        <th className={classes.tableSubHead}>Val Date</th>
        <th className={classes.tableSubHead}>Details</th>
        <th className={classes.tableSub2Head}>Amount</th>
        <th className={classes.tableSub2Head}>Remark</th>
    </tr>
    <tr className={classes.twoTr}>
        <td className={classes.twoTD}>02-Apr-2020</td>
        <td className={classes.twoTD}>02-Apr-2020</td>
        <td className={classes.twoTD}>inc to pro john</td>
        <td className={classes.twoTDAmount}>-2,000.00</td>
        <td className={classes.twoTDAmount}>-2,000.00</td>
    </tr>
  </table>
  </div>
  <div className={classes.detailsB}>
    <div className={classes.row}>
      <span className={classes.regions}>Outstanding Debits in Ledger</span>
    </div>
    <table className={classes.tableR2}>
    <tr className={classes.tableHead}>
        <th className={classes.tableSubHead}>Post Date</th>
        <th className={classes.tableSubHead}>Val Date</th>
        <th className={classes.tableSubHead}>Details</th>
        <th className={classes.tableSub2Head}>Amount</th>
        <th className={classes.tableSub2Head}>Remark</th>
    </tr>
    <tr className={classes.twoTr}>
        <td className={classes.twoTD}>02-Apr-2020</td>
        <td className={classes.twoTD}>02-Apr-2020</td>
        <td className={classes.twoTD}>inc to pro john</td>
        <td className={classes.twoTDAmount}>-2,000.00</td>
        <td className={classes.twoTDAmount}>-2,000.00</td>
    </tr>
  </table>
  </div>
  <div className={classes.detailsB}>
    <div className={classes.row}>
      <span className={classes.regions}>Outstanding Debits in Ledger</span>
    </div>
    <table className={classes.tableR2}>
    <tr className={classes.tableHead}>
        <th className={classes.tableSubHead}>Post Date</th>
        <th className={classes.tableSubHead}>Val Date</th>
        <th className={classes.tableSubHead}>Details</th>
        <th className={classes.tableSub2Head}>Amount</th>
        <th className={classes.tableSub2Head}>Remark</th>
    </tr>
    <tr className={classes.twoTr}>
        <td className={classes.twoTD}>02-Apr-2020</td>
        <td className={classes.twoTD}>02-Apr-2020</td>
        <td className={classes.twoTD}>inc to pro john</td>
        <td className={classes.twoTDAmount}>-2,000.00</td>
        <td className={classes.twoTDAmount}>-2,000.00</td>
    </tr>
  </table>
  </div>
  <div className={classes.detailsB}>
    <div className={classes.row}>
      <span className={classes.regions}>Outstanding Debits in Ledger</span>
    </div>
    <table className={classes.tableR2}>
    <tr className={classes.tableHead}>
        <th className={classes.tableSubHead}>Post Date</th>
        <th className={classes.tableSubHead}>Val Date</th>
        <th className={classes.tableSubHead}>Details</th>
        <th className={classes.tableSub2Head}>Amount</th>
        <th className={classes.tableSub2Head}>Remark</th>
    </tr>
    <tr className={classes.twoTr}>
        <td className={classes.twoTD}>02-Apr-2020</td>
        <td className={classes.twoTD}>02-Apr-2020</td>
        <td className={classes.twoTD}>inc to pro john</td>
        <td className={classes.twoTDAmount}>-2,000.00</td>
        <td className={classes.twoTDAmount}>-2,000.00</td>
    </tr>
  </table>
  </div>
</div>
    </div>

    </div>
  )
}

export default Redeem